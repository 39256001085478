import styled, { css } from 'styled-components'

import { ReactComponent as LevelFile } from '../../../assets/icons/menu.svg'
import { ReactComponent as ClockFile } from '../../../assets/icons/clock.svg'
import { ReactComponent as FolderFile } from '../../../assets/icons/folder.svg'
import { ReactComponent as LessonsFile } from '../../../assets/icons/file.svg'
import { ReactComponent as CircleProfileFile } from '../../../assets/icons/circleProfile.svg'
import { ReactComponent as BackFile } from '../../../assets/icons/filledChevronLeft.svg'

export const BackIcon = styled(BackFile)`
  ${({ theme }) => css`
    transform: translateX(-5%);
    fill: #39383a;
    transition: 0.3s ease;
    &:hover {
      fill: black;
      transform: translateX(-7%) scale(1.1);
    }
  `}
`

export const PushBackBox = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.stack.xxs};
    left: ${theme.spacing.stack.xxs};
    z-index: 888;
    display: flex;
    width: max-content;
    align-items: center;
    justify-content: center;
    background: white;
    padding: ${theme.spacing.stack.small};
    border-radius: ${theme.border.radius.circle};
    cursor: pointer;
  `}
`

export const CircleProfileIcon = styled(CircleProfileFile)``

export const Levelicon = styled(LevelFile)`
  fill: purple;
  transform: rotate(270deg);
  width: 24px;
  height: 24px;
  margin-right: 1rem;
`

export const ClockIcon = styled(ClockFile)`
  fill: purple;
  transform: rotate(270deg);
  width: 24px;
  height: 24px;
  margin-right: 1rem;
`
export const LessonIcon = styled(LessonsFile)`
  fill: purple;
  width: 24px;
  height: 24px;
  margin-right: 1rem;
`

export const FolderIcon = styled(FolderFile)`
  fill: purple;
  transform: rotate(270deg);
  width: 24px;
  height: 24px;
  margin-right: 1rem;
`

export const Container = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  position: relative;
  .subtitle {
    color: #808080;

    @media (max-width: 767px) {
      margin: 1rem 0;
      font-size: 27px;
      font-style: normal;
      font-weight: 700;
      line-height: 41px;
      letter-spacing: 0em;
      text-align: left;
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      font-size: 27px;
      font-style: normal;
      font-weight: 700;
      line-height: 41px;
      letter-spacing: 0em;
      text-align: left;
      margin: 3rem 0;
    }

    @media (min-width: 1171px) {
      margin: 3rem 0;
      font-size: 27px;
      font-style: normal;
      font-weight: 700;
      line-height: 41px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`

export const Spacing = styled.div`
  ${({ theme }) => css`
    @media (min-width: 1171px) {
      height: 130px;
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      height: ${theme.spacing.stack.sm};
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: 1366px;
    width: 100%;
    margin: 0 auto;
    height: max-content;
    @media (max-width: 767px) {
      position: relative;
    }
    @media (min-width: 1171px) {
      padding: 0 ${theme.spacing.stack.xxl};
      margin-bottom: 10rem;
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: 0 ${theme.spacing.stack.md};
    }
  `}
`

export const Banner = styled.img`
  ${({ theme }) => css`
    width: 100%;
  `}
`

export const CourseInfoBox = styled.div`
  ${({ theme }) => css`
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2rem;
      justify-self: end;
      align-items: center;
      margin: ${theme.spacing.stack.sm} 0;
    }
  `}
`

export const PriceCard = styled.div`
  ${({ theme }) => css`
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing.stack.md} ${theme.spacing.stack.xxxl};
    border-radius: ${theme.border.radius.large};

    @media (min-width: 768px) and (max-width: 1170px) {
      padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.lg};
    }
    .subscribe-button {
      background: ${theme.color.alert.success.nth1};
      margin-top: ${theme.spacing.stack.xxs};
    }
    > p {
      white-space: nowrap;
    }
  `}
`

export const CourseInfo = styled.div`
  ${({ theme }) => css`
    .description {
      text-align: justify;
    }
    .title {
      @media (max-width: 767px) {
        //styleName: Bold/H4;
        font-size: 27px;
        font-style: normal;
        font-weight: 700;
        line-height: 41px;
        letter-spacing: 0em;
        text-align: left;
      }
      color: #6c01a3;
    }
    @media (max-width: 767px) {
      position: absolute;
      top: 90%;
      background: white;
      width: 100%;
      height: max-content;
      padding: ${theme.spacing.stack.xxs};
      border-top-left-radius: ${theme.border.radius.big};
      border-top-right-radius: ${theme.border.radius.big};
      margin-bottom: 74px;
    }
  `}
`

export const CourseDetailsBox = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin: ${theme.spacing.stack.xxs} 0;
  `}
`

export const CourseDetails = styled.div`
  display: flex;
  align-items: center;
  > div {
    display: flex;
    flex-direction: column;
  }
`
export const CreatorSection = styled.section`
  ${({ theme }) => css`
    .creatorDescription {
      text-align: justify;
    }
    margin-bottom: ${theme.spacing.stack.xxs};
  `}
`

export const CreatorInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    .infoBox {
      margin-left: ${theme.spacing.inline.xxxs};
    }
    margin: ${theme.spacing.stack.small} 0;
  `}
`

export const FaqSection = styled.section`
  ${({ theme }) => css`
    margin-bottom: 80px;

    @media (min-width: 768px) and (max-width: 1170px) {
      margin-bottom: 200px;
    }
    > div {
      padding: ${theme.spacing.stack.xxs} 0;
      border-top: 2px solid #f4f4f4;
      border-bottom: 2px solid #f4f4f4;
    }
  `}
`

export const TicketBox = styled.section`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    filter: drop-shadow(0px -1px 2px rgba(0, 0, 0, 0.2));
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    align-items: center;
    > button {
      background: ${theme.color.alert.success.nth1};
    }
    z-index: 100;
    @media (min-width: 768px) {
      display: none;
    }
  `}
`

export const PricingInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    > p:last-of-type {
      color: rebeccapurple;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      > p {
        font-size: ${theme.font.size.md};
        font-weight: ${theme.font.weight.bold};
      }
      > p:first-of-type {
        margin-right: ${theme.spacing.inline.xxxs};
      }
    }
  `}
`
