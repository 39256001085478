import { AxiosResponse } from 'axios'
import moment from 'moment'
import { apiUrl, publicApi } from '../../config/api'
import { ListEventResponse } from './listEvents'

type Request = {
  name: string | null
  initialDate?: string
  finalDate?: string
  initialPrice?: number
  finalPrice?: number
  category?: string
  city?: string
  cashback?: string
}

export const filterEventService = async (request: Request) => {
  try {
    const url = new URL(`${apiUrl}/api/events/filters/search`)

    if (request.category) {
      url.searchParams.append('category', request.category)
    }

    if (request.name) {
      url.searchParams.append('name', request.name)
    }

    if (request.city) {
      url.searchParams.append('city', request.city)
    }

    if (request.initialDate) {
      url.searchParams.append(
        'initialDate',
        moment(request.initialDate).format('YYYY-MM-DD')
      )
    }

    if (request.finalDate) {
      url.searchParams.append(
        'finalDate',
        moment(request.finalDate).format('YYYY-MM-DD')
      )
    }

    if (request.initialPrice !== undefined) {
      url.searchParams.append('initialPrice', `${request.initialPrice * 100}`)
    }

    if (request.finalPrice !== undefined) {
      url.searchParams.append('finalPrice', `${request.finalPrice * 100}`)
    }

    if (request.cashback) {
      url.searchParams.append('withCashBack', request.cashback)
    }

    const { data }: AxiosResponse<ListEventResponse> = await publicApi.get(
      url.toString()
    )

    return data
  } catch (e) {}
}
