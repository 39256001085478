import styled, { css } from 'styled-components'

import { ReactComponent as Clock } from 'assets/icons/clock2.svg'
import { ReactComponent as Location } from 'assets/icons/map-marker2.svg'

import { ReactComponent as RemoveFile } from 'assets/icons/remove.svg'

export const RemoveIcon = styled(RemoveFile)`
  cursor: pointer;
  @media (min-width: 1171px) {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    padding: ${theme.spacing.stack.lg};
    background: #ffffff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: ${theme.spacing.stack.xxxs};
  `}
`

export const TitleSummary = styled.h1`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.spacing.inline.xxs};
    line-height: ${theme.spacing.stack.sm};
    color: #6c01a3;
  `}
`

export const ContentInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    div {
      margin-bottom: ${theme.spacing.stack.small};
    }
    margin-bottom: ${theme.spacing.stack.xxs};
  `}
`

export const TitleInfo = styled.h1`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.size.xl};
    line-height: ${theme.font.size.bg};
    word-break: break-word;
    color: #39383a;
  `}
`

export const DataBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: ${theme.spacing.stack.xxs};
  `}
`

export const IconData = styled(Clock)`
  ${({ theme }) => css`
    width: ${theme.spacing.inline.sm};
    height: ${theme.spacing.inline.sm};
  `}
`

export const TitleData = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
  `}
`

export const LocationBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: ${theme.spacing.stack.xxs};
  `}
`

export const IconLocation = styled(Location)`
  ${({ theme }) => css`
    width: ${theme.spacing.inline.sm};
    height: ${theme.spacing.inline.sm};
  `}
`

export const TitleLocation = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
  `}
`

export const TicketsBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    div {
      margin-bottom: ${theme.spacing.stack.xxxs};
    }
    overflow-y: auto;
    max-height: 272px;
  `}
`

export const Total = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    display: flex;
    background: #f4f4f4;
    margin-top: ${theme.spacing.stack.xxxs};
    border-radius: ${theme.border.radius.medium};
  `}
`

export const TextTotal = styled.p`
  ${({ theme }) => css`
    margin-left: auto;
    font-weight: ${theme.font.weight.semiBold};
    font-size: ${theme.font.size.sm};
    line-height: 28px;
  `}
`

export const CouponCard = styled.div`
  ${({ theme }) => css`
    position: relative;
    background: ${theme.color.base.nth4};
    border-radius: 6px;
    display: flex;
    padding: ${theme.spacing.stack.xs} ${theme.spacing.stack.xxs};
    justify-content: space-between;
    align-items: center;
    margin: ${theme.spacing.stack.xxxs} 0;
    .coupon-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      text-align: left;
    }
    .coupon-code {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
    .coupon-discount {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      text-align: left;
      color: ${theme.color.alert.success.nth1};
    }
  `}
`
