import * as S from './OrderProcess.styles'
import { Button } from 'applaus-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import history from '../../../../../routes/services/history'
import { setCheckoutState } from '../../../../../redux/Checkout/actions'
import { clearEvent } from '../../../../../redux/Event/actions'
import { ReduxState } from '../../../../../redux/rootReducer'

export const OrderProcess = () => {
  const dispatch = useDispatch()

  const { purchaseStatus, eventData, purchaseId } = useSelector(
    (state: ReduxState) => state.CheckoutReducer.data
  )

  return (
    <S.Container>
      <S.CoverUrl src={eventData?.banners[0].mobileUrl} />

      <S.Wrapper>
        <S.Title>
          {purchaseStatus === 'SUCCESS'
            ? 'Pedido efetuado com sucesso!'
            : 'Seu pedido está em análise.'}
        </S.Title>

        <Button
          color="primary"
          size="large"
          variant="contained"
          fullWidth
          onClick={() => {
            history.push(`/tickets/${purchaseId}`)
            dispatch(clearEvent())

            dispatch(
              setCheckoutState({
                data: {
                  step: 'defaultCheckout'
                }
              })
            )
          }}>
          Ver Ingressos
        </Button>
      </S.Wrapper>
    </S.Container>
  )
}
