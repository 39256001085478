import { useEffect, useState } from 'react'
import moment from 'moment'
import _ from 'lodash'
import ReactPixel from 'react-facebook-pixel'
import { Hidden } from 'applaus-ui-kit'

import history from 'routes/services/history'

import { ticketPurchase } from 'api/services/checkout/ticketPurchase'
import { purchaseOrder } from 'api/services/checkout/purchaseOrder'

import * as S from './PaymentMethod.styles'
import { AddCoupon } from '../components/addCoupon/AddCoupon'
import { AppBar } from 'components/appBar/AppBar'
import { ButtonBack } from 'components/buttonBack/buttonBack'
import { ButtonCard } from 'components/buttonCard/ButtonCard'
import { DescriptionCard } from 'components/descriptionCard/DescriptionCard'
import { PurchaseSummary } from '../components/purchaseSummary/PurchaseSummary'
import {
  Container as GridContainer,
  Column,
  Row
} from 'components/grid/GridSystem'
import { motion } from 'framer-motion'

import { ReduxState } from 'redux/rootReducer'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearCheckoutState,
  creditCard,
  pix,
  orderProcess,
  purchaseStatus,
  redeemCouponRequestAction
} from 'redux/Checkout/actions'
import { clearLoading, setLoading } from 'redux/Loading/actions'
import {
  clearModalState,
  setModalState
} from 'redux/Modal/Actions/actionsModal'
import { clearEvent, profileEventAction } from 'redux/Event/actions'

import { useWindowSize } from 'hooks/useWindowSize'

import { messageHelper } from 'utils/messageHelper'
import { sleep } from 'utils/sleep'
import { calculateTotalPrice } from 'utils/calculateTotalPrice'

export const PaymentMethod = () => {
  const dispatch = useDispatch()
  const { isDesktop } = useWindowSize()

  const { CheckoutReducer, UserReducer } = useSelector(
    (state: ReduxState) => state
  )

  const { data, coupon } = CheckoutReducer
  const { ticketState, eventData } = data

  const [checkedType, setCheckedType] = useState<
    'creditCard' | 'cashback' | 'pix'
  >('creditCard')

  useEffect(() => {
    window.scrollTo(0, 0)
    if (_.isEmpty(eventData) && _.isEmpty(ticketState)) {
      history.push('/')
    }
  }, [])

  const handlePurchaseOrder = async (purchaseOrderId: string) => {
    dispatch(setLoading(true))

    const purchaseOrderData = await purchaseOrder(
      purchaseOrderId,
      Number(process.env.REACT_APP_REQUEST_INTERVAL)
    )
    if (purchaseOrderData?.status === 'SUCCESS') {
      dispatch(clearLoading())
      dispatch(purchaseStatus('SUCCESS'))
      dispatch(orderProcess(purchaseOrderId))
      dispatch(clearEvent())
    } else if (purchaseOrderData?.status === 'WAITING') {
      dispatch(clearLoading())
      dispatch(purchaseStatus('WAITING'))
      dispatch(orderProcess(purchaseOrderId))
      dispatch(clearEvent())
    } else {
      dispatch(clearLoading())
      switch (purchaseOrderData?.failedCode.code) {
        case 'TICKET_UNAVAILABLE':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.ticketUnavailable,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.ticketUnavailable
            })
          )

        case 'PAYMENT_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.reviewData,
              open: true,
              variant: 'error',
              buttonText: 'Revisar dados',
              description: messageHelper.modal.error.reviewData
            })
          )

        case 'INTEGRATION_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError
            })
          )

        case 'BOOKING_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError
            })
          )

        case 'BAD_REQUEST_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError
            })
          )

        case 'PURCHASE_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError
            })
          )
      }
    }

    dispatch(clearLoading())
  }
  const buyEventFree = async () => {
    dispatch(setLoading())
    try {
      const { data } = await ticketPurchase({
        paymentMethod: {
          type: 'FREE',
          cashBackValueCents: null,
          customerCouponId: coupon?.customerCouponId
        },
        tickets: ticketState!.map(
          ({ ticketInfo, id, eventItemId, seatKey }) => ({
            ticketInfo,
            ticketId: id,
            eventItemId,
            seatKey: eventData?.type === 'MAPPED' ? seatKey : undefined
          })
        )
      })

      if (data.status === 'WAITING' || data.status === 'SUCCESS') {
        dispatch(clearLoading())
        await handlePurchaseOrder(data.id)
      }
    } catch (err: any) {
      await sleep(3000)
      dispatch(clearLoading())
      if (err) {
        switch (err.response.data.code) {
          case 'TICKET_UNAVAILABLE':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.ticketUnavailable,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.ticketUnavailable,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'PAYMENT_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.reviewData,
                open: true,
                variant: 'error',
                buttonText: 'Revisar dados',
                description: messageHelper.modal.error.reviewData,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'INTEGRATION_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.integrationError,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.integrationError,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'BOOKING_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.integrationError,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.integrationError,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'BAD_REQUEST_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.integrationError,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.integrationError,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'PURCHASE_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.integrationError,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.integrationError,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )
        }
      }
    }
  }

  const handlePaymentMethod = async () => {
    if (calculateTotalPrice(ticketState!, coupon) === 0) {
      buyEventFree()
    } else if (checkedType === 'creditCard') {
      dispatch(creditCard())
    } else if (checkedType === 'pix') {
      dispatch(pix())
    } else {
      try {
        if (ticketState && eventData) {
          const { data, status } = await ticketPurchase({
            paymentMethod: {
              type: 'CASH_BACK',
              cashBackValueCents: null
            },
            tickets: ticketState.map(
              ({ ticketInfo, id, eventItemId, seatKey }) => ({
                ticketInfo,
                ticketId: id,
                eventItemId,
                seatKey: eventData.type === 'MAPPED' ? seatKey : undefined
              })
            )
          })

          if (status === 201) {
            sessionStorage.removeItem('seatsio')
            await handlePurchaseOrder(data.id)
          }
        }
      } catch (e) {}
    }
  }

  const handleRedeemCoupon = (couponCode: string) => {
    dispatch(redeemCouponRequestAction(couponCode))
  }

  useEffect(() => {
    if (eventData?.pixels) {
      ReactPixel.init(eventData?.pixels.facebookpixel, undefined, {
        autoConfig: true,
        debug: true
      })
      if (
        localStorage.getItem(
          `initiateCheckout${CheckoutReducer.data.eventData?.id}`
        ) === null
      ) {
        ReactPixel.track('InitiateCheckout')
        localStorage.setItem(
          `initiateCheckout${CheckoutReducer.data.eventData?.id}`,
          'started'
        )
      }
    }
  }, [eventData?.pixels])

  return (
    <S.Container>
      <Hidden mobile>
        <AppBar />
      </Hidden>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <GridContainer className="gridContainer">
          <Row>
            <Hidden mobile tablet>
              <Column
                desktop={6}
                spacing={isDesktop ? 2 : 0}
                className="pucharseSummary">
                <PurchaseSummary
                  eventAddress={eventData?.address.street ?? ''}
                  eventDate={
                    moment(eventData?.eventItems[0].dates[0])
                      .locale('pt-br')
                      .format('ll [ás] HH[h]mm') ?? ''
                  }
                  tickets={ticketState ?? []}
                  titleTicket={eventData?.name ?? ''}
                />
              </Column>
            </Hidden>

            <Column
              mobile={4}
              desktop={6}
              spacing={isDesktop ? 3 : 0}
              tablet={8}
              className="content">
              <Hidden tablet desktop className="mobileButtonBack">
                <ButtonBack
                  title="Pagamento"
                  click={() => {
                    dispatch(clearCheckoutState())
                    history.push(`/event/${eventData?.id}`)
                  }}
                />
              </Hidden>
              <S.Wrapper>
                <div className="rowGap">
                  <DescriptionCard title="Opções de pagamento" />
                </div>

                <div className="payment-buttons">
                  <ButtonCard
                    title="Cartão de crédito"
                    type="creditCard"
                    click={() => setCheckedType('creditCard')}
                    isChecked={checkedType === 'creditCard'}
                    radioButton
                  />

                  <ButtonCard
                    title="Pix"
                    type="pix"
                    click={() => setCheckedType('pix')}
                    isChecked={checkedType === 'pix'}
                    radioButton
                  />
                  {UserReducer.cashBackBalanceCents > 0 &&
                    UserReducer.cashBackBalanceCents / 100 >=
                      ticketState!.reduce(
                        (acc, val) => acc + val.totalPrice,
                        0
                      ) && (
                      <ButtonCard
                        title="Cashback"
                        type="cashback"
                        cashBackValue={UserReducer.cashBackBalanceCents / 100}
                        click={() => setCheckedType('cashback')}
                        isChecked={checkedType === 'cashback'}
                        radioButton
                      />
                    )}
                </div>

                <Hidden tablet mobile>
                  <AddCoupon handleRedeemCoupon={handleRedeemCoupon} />
                </Hidden>

                <S.ActionsBox>
                  <Hidden mobile>
                    <ButtonBack
                      title="Pagamento"
                      click={() => {
                        dispatch(clearCheckoutState())
                        history.push(`/event/${eventData?.id}`)
                      }}
                    />
                  </Hidden>

                  <S.StyledButton
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth={false}
                    onClick={handlePaymentMethod}>
                    Continuar
                  </S.StyledButton>
                </S.ActionsBox>
              </S.Wrapper>
            </Column>
          </Row>
        </GridContainer>
      </motion.div>
    </S.Container>
  )
}
