import styled, { css } from 'styled-components'

import { ReactComponent as LocationFile } from 'assets/icons/map-marker.svg'
import { ReactComponent as BackButtonFile } from 'assets/icons/lightBackButton.svg'
import { ReactComponent as AgeFreeFile } from 'assets/icons/ageGroup/ageFree.svg'
import { ReactComponent as Age10File } from 'assets/icons/ageGroup/age10.svg'
import { ReactComponent as Age12File } from 'assets/icons/ageGroup/age12.svg'
import { ReactComponent as Age14File } from 'assets/icons/ageGroup/age14.svg'
import { ReactComponent as Age16File } from 'assets/icons/ageGroup/age16.svg'
import { ReactComponent as Age18File } from 'assets/icons/ageGroup/age18.svg'

import { ReactComponent as FacebookFile } from 'assets/icons/facebookPurple.svg'

import { ReactComponent as InstagramFile } from 'assets/icons/instagramPurple.svg'
import { ReactComponent as YoutubeFile } from 'assets/icons/youtubePurple.svg'
import { ReactComponent as TiktokFile } from 'assets/icons/tiktokPurple.svg'

import { ReactComponent as ShareFile } from 'assets/icons/shareIcon.svg'

export const FacebookIcon = styled(FacebookFile)`
  ${({ theme }) => css`
    cursor: pointer;
    @media (min-width: 1366px) {
      height: 32px;
      width: 32px;
    }
  `}
`
export const InstagramIcon = styled(InstagramFile)`
  ${({ theme }) => css`
    cursor: pointer;
    @media (min-width: 1366px) {
      height: 32px;
      width: 32px;
    }
  `}
`
export const YoutubeIcon = styled(YoutubeFile)`
  ${({ theme }) => css`
    cursor: pointer;
    @media (min-width: 1366px) {
      height: 32px;
      width: 40px;
    }
  `}
`
export const TiktokIcon = styled(TiktokFile)`
  ${({ theme }) => css`
    cursor: pointer;
    @media (min-width: 1366px) {
      height: 32px;
      width: 32px;
    }
  `}
`

export const ShareIcon = styled(ShareFile)`
  ${({ theme }) => css`
    @media (min-width: 1366px) {
      height: 16px;
      width: 16px;
    }
  `}
`

export const AgeFreeIcon = styled(AgeFreeFile)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};

    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age10Icon = styled(Age10File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};

    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age12Icon = styled(Age12File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};

    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age14Icon = styled(Age14File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};

    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age16Icon = styled(Age16File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};

    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age18Icon = styled(Age18File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};

    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const AgeBox = styled.div`
  display: flex;
  align-items: center;
`

export const BackButtonIcon = styled(BackButtonFile)`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.stack.xs};
    left: ${theme.spacing.stack.xs};
    cursor: pointer;
    margin-right: ${theme.spacing.inline.xxxs};
    @media (min-width: 768px) {
      height: 32px;
      width: 32px;
    }
  `}
`

export const LocationIcon = styled(LocationFile)`
  ${({ theme }) => css`
    fill: #39383a;
    margin-right: ${theme.spacing.inline.xxxs};
    @media (min-width: 768px) {
      height: 32px;
      width: 32px;
    }
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    .event-info {
      margin: ${theme.spacing.stack.xs} 0;
    }
    .image-column {
      position: relative;
    }
  `}
`

export const Wrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    @media (min-width: 1171px) {
      max-width: 1070px;
    }
  `}
`

export const EventImage = styled.img`
  width: 100%;
  max-height: 600px;
  border-radius: 6px;
  @media (min-width: 768px) and (max-width: 1170px) {
    align-self: center;
    max-height: 500px;
  }
`

export const EventInfo = styled.section`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.xs} 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${theme.spacing.stack.xs};
  `}
`

export const EventData = styled.div`
  ${() => css``}
`

export const LocationBox = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.xs} 0 ${theme.spacing.stack.xs};

    display: flex;
    align-items: center;
  `}
`

export const EventDetails = styled.div`
  ${({ theme }) => css`
    .header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > h5 {
        font-size: ${theme.font.size.md};
        color: #6c01a3;
      }
      .buttons-wrapper {
        display: flex;
        align-items: center;
        gap: ${theme.spacing.stack.xs};

        > button {
          border: 1px solid ${theme.color.brand.primary.nth1};
          outline: 0;
          background: transparent;
          padding: 8px 24px;
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          text-align: center;
          color: ${theme.color.brand.primary.nth1};
          border-radius: 24px;
        }
      }
    }

    margin-bottom: ${theme.spacing.stack.xl};
  `}
`

export const EventTickets = styled.div`
  ${({ theme }) => css`
    .title {
      font-size: 27px;
      font-style: normal;
      font-weight: 700;
      line-height: 41px;
      text-align: left;

      color: #6c01a3;
    }
    margin-bottom: ${theme.spacing.stack.xl};
  `}
`

export const EventLocation = styled.div`
  ${({ theme }) => css`
    > h5 {
      font-size: ${theme.font.size.md};
      color: #6c01a3;
    }
    margin-bottom: ${theme.spacing.stack.xl};
  `}
`

export const LocationIframe = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.stack.xxxs};
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    border-radius: ${theme.border.radius.large};

    .responsive-iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  `}
`

export const Description = styled.div``

export const TicketBox = styled.section`
  ${({ theme }) => css`
    position: fixed;
    bottom: ${theme.spacing.stack.xxxs};
    left: ${theme.spacing.stack.xxxl};
    right: ${theme.spacing.stack.xxxl};
    border-radius: ${theme.border.radius.large};
    background: #f4f4f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing.stack.xs} ${theme.spacing.stack.sm};
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    > h4 {
      font-size: ${theme.font.size.md};
      line-height: ${theme.font.size.md};
    }

    > button {
      padding: 8px 24px;
      font-size: 16px;
      background: ${theme.color.alert.success.nth1};
      border-radius: 8px;
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      bottom: ${theme.spacing.stack.md};
      left: ${theme.spacing.stack.md};
      right: ${theme.spacing.stack.md};
    }
  `}
`

export const Dates = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: ${theme.spacing.inline.xxxs};
    > h4 {
      font-size: ${theme.font.size.md};
      color: #6c01a3;
    }
    .eventItemsBox {
      display: flex;
      flex-wrap: wrap;
      row-gap: ${theme.spacing.stack.xxxs};
      margin: ${theme.spacing.stack.xxs} 0;
    }
  `}
`

export const DatesInfo = styled.div<{ isActive: boolean; disabled: boolean }>`
  ${({ theme, isActive, disabled }) => css`
    ${disabled
      ? css`
          background: ${theme.color.base.nth3};
          pointer-events: none;
        `
      : css`
          border: 2px solid #8601c9;
          cursor: pointer;
          transition: background ease-in-out 0.5s;
          ${isActive &&
          css`
            background: #8601c9;
            > p {
              color: white;
            }
          `}
          :hover {
            background: #8601c9;
            > p {
              color: white;
            }
          }
        `}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing.inline.nano} ${theme.spacing.inline.xs};
    border-radius: ${theme.border.radius.medium};
    margin-right: ${theme.spacing.inline.xxxs};
    > p {
      white-space: nowrap;
    }
  `}
`

export const Paragraph = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.semiBold};
  `}
`
