import { AxiosResponse } from 'axios'
import api from '../config/api'

export type Banners = {
  id: string
  image: string
  name: string
  local: string
  data: string
}

export const listAllBannersService = async () => {
  const { data }: AxiosResponse<Banners[]> = await api.get('api/banners')
  return data
}
