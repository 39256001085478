import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { ReactComponent as ChevronDownFile } from '../../assets/icons/chevron-down.svg'

export const ChevronIcon = styled(ChevronDownFile)`
  ${({ theme }) => css`
    fill: gray;
    transition: 0.3s ease;
    width: 34px;
    height: 34px;
    padding: ${theme.spacing.stack.small};
    &:hover {
      fill: black;
      transform: translateY(10%) scale(1.2);
    }
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.stack.xxs};
  `}
`

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
  `}
`

export const IconBox = styled(motion.div)`
  cursor: pointer;
`

export const AccordionInfo = styled(motion.div)`
  ${({ theme }) => css`
    > p {
      margin: ${theme.spacing.stack.small} 0 ${theme.spacing.stack.small}
        ${theme.spacing.stack.small};
    }
  `}
`
