import React from 'react'
import SlickSlider, { Settings } from 'react-slick'

import * as S from './Slick.styles'

export type SliderSettings = Settings

export type SliderProps = {
  children: any
  settings: Settings
}

export const Slick = ({ children, settings }: SliderProps) => {
  return (
    <div>
      <S.Wrapper>
        <SlickSlider {...settings}>{children}</SlickSlider>
      </S.Wrapper>
    </div>
  )
}
