import { Button } from 'applaus-ui-kit'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.xxs} 0;
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: 767px) {
      margin-top: ${theme.spacing.stack.xxs};
    }
  `}
`

export const Form = styled.form`
  ${({ theme }) =>
    css`
      margin-top: ${theme.spacing.stack.xxxs};
    `}
`

export const ButtonBox = styled.div`
  ${({ theme }) => css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: ${theme.spacing.stack.xxs};
      @media (max-width: 767px){
        justify-content: end;
      }
    }
  `}
`

export const ButtonStyled = styled(Button)`
  background: #2ecc71;
`

export const customStyles = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '8px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}

export const Label = styled.p`
  ${({ theme }) => css`
    color: #39383a;
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: 24px;
    margin-bottom: 0.5rem;
  `}
`

export const Error = styled.p`
  ${({ theme }) => css`
    color: #fc3e3e;
    font-size: ${theme.font.size.xxs};
    min-height: ${theme.spacing.stack.xxs};
  `}
`
