import styled, { css } from 'styled-components'

import { ReactComponent as HomeFile } from '../../../../../assets/icons/home.svg'
import { ReactComponent as TicketFile } from '../../../../../assets/icons/coupon.svg'
import { ReactComponent as DataFile } from '../../../../../assets/icons/file.svg'
import { ReactComponent as SettingsFile } from '../../../../../assets/icons/lock.svg'
import { ReactComponent as SignoutFile } from '../../../../../assets/icons/signout.svg'
import { ReactComponent as CashBackFile } from '../../../../../assets/icons/cashback.svg'
import { ReactComponent as ChevronRightFile } from '../../../../../assets/icons/chevronRight.svg'
import { ReactComponent as FolderFile } from '../../../../../assets/icons/folder.svg'

import { ReactComponent as TermsFile } from '../../../../../assets/icons/copy.svg'

import { motion } from 'framer-motion'

export const Container = styled.div`
  ${() => css`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    @media (min-width: 1171px) {
      display: grid;
      grid-template-columns: 335px auto;
    }
  `}
`

export const Header = styled.section`
  ${({ theme }) => css`
    background: #f4f4f4;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.sm} ${theme.spacing.inline.xxs}
      ${theme.spacing.stack.xxs};
    border-bottom-left-radius: ${theme.border.radius.big};
    border-bottom-right-radius: ${theme.border.radius.big};
    margin-bottom: ${theme.spacing.stack.xxxs};
  `}
`

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`

export const UserBox = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    .userInfo {
      color: #6c01a3;
    }
  `}
`

export const CashBackWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${theme.spacing.stack.xxxs};
    > p {
      margin-left: ${theme.spacing.inline.small};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${theme.color.brand.primary.nth1};
    }
  `}
`

export const Logo = styled.img`
  ${({ theme }) => css`
    width: auto;
    margin: ${theme.spacing.stack.xl};
    cursor: pointer;
  `}
`

export const MenuButtonsBox = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 0 ${theme.spacing.inline.xxs};
    > div {
      border-bottom: 1px solid #dfdfdf;
    }
    div:last-child {
      border-bottom: 1px solid transparent;
    }
  `}
`

export const MenuButton = styled(motion.div)<{ active: boolean }>`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.stack.xxxs} 0;
    .content {
      display: flex;
      align-self: center;
    }
    .text {
      align-items: center;
      margin-left: ${theme.spacing.inline.xxxs};
    }
    transition: 0.6s ease;
    &:hover {
      transform: translateX(5%);
    }
  `}
`

export const HomeIcon = styled(HomeFile)`
  fill: #39383a;
`

export const SignoutIcon = styled(SignoutFile)`
  fill: #39383a;
`

export const TicketIcon = styled(TicketFile)`
  fill: #39383a;
  width: 30px;
  height: 30px;
`
export const DataIcon = styled(DataFile)`
  fill: #39383a;
`

export const ChevronRightIcon = styled(ChevronRightFile)`
  fill: #39383a;
`
export const SettingsIcon = styled(SettingsFile)`
  fill: #39383a;
`

export const FolderIcon = styled(FolderFile)`
  fill: #39383a;
`

export const CashBackIcon = styled(CashBackFile)`
  ${({ theme }) => css`
    fill: ${theme.color.brand.primary.nth1};
  `}
`

export const TermsIcon = styled(TermsFile)`
  fill: #39383a;
`
