import styled, { css } from 'styled-components'

import { ReactComponent as BackSvg } from '../../assets/icons/chevron-circle-left.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`

export const ButtonBack = styled.button`
  ${() => css`
    border: none;
    background: #39383a;
    border-radius: 100%;
    height: 32px;
    width: 32px;
    margin-bottom: 32px;
    position: relative;
  `}
`

export const BackIcon = styled(BackSvg)`
  ${({ theme }) => css`
    position: absolute;
    font-size: ${theme.font.size.md};
    fill: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`

export const Title = styled.p`
  ${() => css`
    font-weight: 900;
    font-size: 27px;
    color: #39383a;
  `}
`
