import { Hidden, Paragraph } from 'applaus-ui-kit'
import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { timeConvert } from '../../../../../utils/timeConverter'
import * as S from './Accordion.styles'

type Props = {
  index: number
  title: string
  lessons: {
    title: string
    duration: number
  }[]
}

export const CustomAccordion = ({ index, title, lessons }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <S.Container>
      <S.Header>
        <Paragraph variant="regular" type="semiBold" className="module-title">
          {index + 1}. {title}
        </Paragraph>
        <S.ModuleInfo>
          <Hidden mobile>
            <Paragraph variant="small" type="semiBold" className="lessons-info">
              {`${lessons.length} aula${lessons.length > 1 ? 's' : ''} - `}
              {timeConvert(lessons.reduce((acc, val) => acc + val.duration, 0))}
            </Paragraph>
          </Hidden>
          <S.IconBox
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
            onClick={() => setIsOpen((prev) => !prev)}>
            <S.ChevronIcon />
          </S.IconBox>
        </S.ModuleInfo>
      </S.Header>
      <AnimatePresence>
        {isOpen ? (
          <S.AccordionInfo
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            exit={{ opacity: 0, height: 0 }}>
            {lessons.map((lesson, index) => (
              <Paragraph variant="small" type="semiBold" key={index}>
                <S.FilesIcon /> {lesson.title}
              </Paragraph>
            ))}
          </S.AccordionInfo>
        ) : undefined}
      </AnimatePresence>
    </S.Container>
  )
}
