import styled, { css } from 'styled-components'

import { ReactComponent as CashbackFile } from 'assets/icons/cashback.svg'

import { ReactComponent as DownloadFile } from 'assets/icons/download.svg'

export const DownloadIcon = styled(DownloadFile)``

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxs};
    min-width: 320px;
  `}
`

export const CoverUrl = styled.img`
  ${({ theme }) => css`
    width: 100%;
    min-height: 30vh;
    max-height: 30vh;
    border-radius: ${theme.border.radius.medium};
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.stack.xxxs};
  `}
`

export const PaymentStatus = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.xxxs};
    margin-top: ${theme.spacing.stack.xxxs};
  `}
`

export const TextStatus = styled.h1<{ status: string }>`
  ${({ theme, status }) => css`
    display: flex;
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    .approvedText {
      margin-left: ${theme.spacing.stack.small};
      font-weight: ${theme.font.weight.bold};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
      color: ${status === 'SUCCESS' ? '#25a35a' : '#03AFCC'};
    }
  `}
`

export const PaymentData = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    font-weight: ${theme.font.weight.regular};
    line-height: ${theme.spacing.stack.xxxs};
  `}
`

export const PaymentValue = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.xxxs};
    margin: ${theme.spacing.stack.xxxs} 0 ${theme.spacing.stack.xxxs};
  `}
`

export const TextValue = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
  `}
`

export const IconCreditCard = styled.img`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.stack.nano};
  `}
`

export const CreditCard = styled.p`
  ${({ theme }) => css``}
`

export const CashBackReceived = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.xxxs};
    margin-bottom: ${theme.spacing.stack.xxxs};

    .textCashback {
      font-weight: ${theme.font.weight.bold};
      margin-bottom: ${theme.spacing.stack.small};
    }

    > p {
      color: ${theme.color.brand.primary.nth1};
    }
  `}
`

export const CashBackPaid = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.xxxs};
    margin-bottom: ${theme.spacing.stack.xxxs};
    p {
      font-size: ${theme.font.size.sm};
      color: #39383a;
    }
    .bold {
      font-weight: ${theme.font.weight.bold};
    }
    .divider {
      border: 1px solid #5c5c5c;
      margin: ${theme.spacing.stack.small} 0;
    }
    .cashbackBox {
      display: flex;
      align-items: center;
      margin-bottom: ${theme.spacing.stack.small};
      > div {
        display: flex;
        align-items: center;
      }
    }

    .paymentBox {
      display: flex;
      align-items: center;
      > div:nth-child(2) {
        img {
          width: 24px;
          height: 24px;
          margin: 0 ${theme.spacing.stack.nano} 0 ${theme.spacing.stack.small};
        }
      }
    }
  `}
`

export const IconCashback = styled(CashbackFile)`
  ${({ theme }) => css`
    fill: ${theme.color.brand.primary.nth1};
    margin: 0 ${theme.spacing.stack.small};
  `}
`

export const DownloadButton = styled.button`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.stack.xxxs};
    outline: 0;
    border: 1px solid ${theme.color.brand.primary.nth1};
    background: none;
    display: flex;
    align-items: center;
    gap: ${theme.spacing.inline.small};
    width: 100%;
    justify-content: center;
    border-radius: ${theme.border.radius.large};
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;

    color: ${theme.color.brand.primary.nth1};
    padding: ${theme.spacing.stack.small} 0;
    transition: 0.3s ease;
    &:hover {
      transform: scale(1.02);
    }
  `}
`
