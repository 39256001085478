import { ButtonBack } from '../../../../components/buttonBack/buttonBack'
import { ButtonCard } from '../../../../components/buttonCard/ButtonCard'
import { DescriptionCard } from '../../../../components/descriptionCard/DescriptionCard'
import * as S from './Pix.styles'
import { Button, Hidden, RadioButton } from 'applaus-ui-kit'
import { InputCopy } from '../../../../components/inputCopy/InputCopy'
import { useDispatch, useSelector } from 'react-redux'

import { AppBar } from '../../../../components/appBar/AppBar'
import {
  Container as GridContainer,
  Column,
  Row
} from '../../../../components/grid/GridSystem'
import { PurchaseSummary } from '../components/purchaseSummary/PurchaseSummary'
import { ReduxState } from '../../../../redux/rootReducer'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { ticketPurchase } from '../../../../api/services/checkout/ticketPurchase'
import { clearLoading, setLoading } from '../../../../redux/Loading/actions'
import {
  clearModalState,
  setModalState
} from '../../../../redux/Modal/Actions/actionsModal'
import { messageHelper } from '../../../../utils/messageHelper'
import { profileEventAction } from '../../../../redux/Event/actions'
import history from '../../../../routes/services/history'
import { purchaseOrderPix } from '../../../../api/services/checkout/purchaseOrderPix'
import { purchaseOrderGeneric } from '../../../../api/services/checkout/purchaseOrderGeneric'
import {
  orderProcess,
  purchaseStatus
} from '../../../../redux/Checkout/actions'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { formatPrice } from '../../../../utils/formatPrice'
import CurrencyInput from 'react-currency-input-field'
import { AnimatePresence, motion } from 'framer-motion'

export const Pix = () => {
  const dispatch = useDispatch()
  const attempts = useRef(0)
  const cancelRetry = useRef(false)
  const { isDesktop } = useWindowSize()

  const { isMobile } = useWindowSize()

  const { CheckoutReducer, UserReducer } = useSelector(
    (state: ReduxState) => state
  )

  const { ticketState, eventData } = CheckoutReducer.data
  const { coupon } = CheckoutReducer

  const [showPixData, setShowPixData] = useState(
    !UserReducer.cashBackBalanceCents
  )

  const [cashbackState, setCashbackState] = useState({
    isAvailable: false,
    edit: false,
    value: 0
  })

  const [qrCodeImage, setQrCodeImage] = useState<{
    pixQrCode: string
    pixQrCodeText: string
  }>({ pixQrCode: '', pixQrCodeText: '' })

  function sleep() {
    return new Promise((resolve) =>
      setTimeout(resolve, Number(process.env.REACT_APP_REQUEST_INTERVAL_PIX))
    )
  }

  const handlePurchaseOrderStatus = async (purchaseOrderId: string) => {
    try {
      const purchaseOrderStatus = await purchaseOrderGeneric(purchaseOrderId)

      if (
        purchaseOrderStatus.status === 'WAITING' &&
        attempts.current === Number(process.env.REACT_APP_ATTEMPTS_PIX)
      ) {
        dispatch(purchaseStatus('WAITING'))
        dispatch(orderProcess(purchaseOrderId))
      }

      if (purchaseOrderStatus.status === 'SUCCESS') {
        dispatch(purchaseStatus('SUCCESS'))
        dispatch(orderProcess(purchaseOrderId))
      } else {
        if (
          attempts.current < Number(process.env.REACT_APP_ATTEMPTS_PIX) &&
          !cancelRetry.current
        ) {
          attempts.current += 1
          await sleep()
          await handlePurchaseOrderStatus(purchaseOrderId)
        } else if (cancelRetry.current) {
          dispatch(clearLoading())
          dispatch(profileEventAction())
          history.push(`/event/${eventData?.id}`)
        } else if (purchaseOrderStatus.status === 'FAILED') {
          switch (purchaseOrderStatus?.failedCode.code) {
            case 'TICKET_UNAVAILABLE':
              return dispatch(
                setModalState({
                  message: messageHelper.modal.message.ticketUnavailable,
                  open: true,
                  variant: 'error',
                  description: messageHelper.modal.error.ticketUnavailable,
                  click: () => {
                    dispatch(profileEventAction())
                    dispatch(clearModalState())
                    history.push(`/event/${eventData?.id}`)
                  }
                })
              )
            case 'PAYMENT_ERROR':
              return dispatch(
                setModalState({
                  message: messageHelper.modal.message.reviewData,
                  open: true,
                  variant: 'error',
                  buttonText: 'Revisar dados',
                  description: messageHelper.modal.error.reviewData,
                  click: () => {
                    dispatch(profileEventAction())
                    dispatch(clearModalState())
                    history.push(`/event/${eventData?.id}`)
                  }
                })
              )
            case 'INTEGRATION_ERROR':
              return dispatch(
                setModalState({
                  message: messageHelper.modal.message.integrationError,
                  open: true,
                  variant: 'error',
                  description: messageHelper.modal.error.integrationError,
                  click: () => {
                    dispatch(profileEventAction())
                    dispatch(clearModalState())
                    history.push(`/event/${eventData?.id}`)
                  }
                })
              )

            case 'BOOKING_ERROR':
              return dispatch(
                setModalState({
                  message: messageHelper.modal.message.integrationError,
                  open: true,
                  variant: 'error',
                  description: messageHelper.modal.error.integrationError,
                  click: () => {
                    dispatch(profileEventAction())
                    dispatch(clearModalState())
                    history.push(`/event/${eventData?.id}`)
                  }
                })
              )

            case 'BAD_REQUEST_ERROR':
              return dispatch(
                setModalState({
                  message: messageHelper.modal.message.integrationError,
                  open: true,
                  variant: 'error',
                  description: messageHelper.modal.error.integrationError,
                  click: () => {
                    dispatch(profileEventAction())
                    dispatch(clearModalState())
                    history.push(`/event/${eventData?.id}`)
                  }
                })
              )

            case 'PURCHASE_ERROR':
              return dispatch(
                setModalState({
                  message: messageHelper.modal.message.integrationError,
                  open: true,
                  variant: 'error',
                  description: messageHelper.modal.error.integrationError,
                  click: () => {
                    dispatch(profileEventAction())
                    dispatch(clearModalState())
                    history.push(`/event/${eventData?.id}`)
                  }
                })
              )
          }
        }
      }
    } catch {}

    await sleep()
  }

  const handlePurchaseOrder = async (purchaseOrderId: string) => {
    try {
      const purchaseOrderData = await purchaseOrderPix(
        purchaseOrderId,
        Number(process.env.REACT_APP_REQUEST_QR_CODE_INTERVAL)
      )

      if (
        purchaseOrderData?.pixQrCode !== null &&
        purchaseOrderData?.pixQrCodeText !== null
      ) {
        dispatch(clearLoading())
        setQrCodeImage({
          pixQrCode: purchaseOrderData!.pixQrCode,
          pixQrCodeText: purchaseOrderData!.pixQrCodeText
        })
        handlePurchaseOrderStatus(purchaseOrderId)
      }

      if (purchaseOrderData?.status === 'FAILED') {
        dispatch(clearLoading())
        dispatch(
          setModalState({
            message: messageHelper.modal.message.integrationError,
            open: true,
            variant: 'error',
            description: messageHelper.modal.error.integrationError,
            click: () => {
              dispatch(profileEventAction())
              dispatch(clearModalState())
              history.push(`/event/${eventData?.id}`)
            }
          })
        )
      }
    } catch {}
  }

  const handlePaymentPix = async () => {
    try {
      dispatch(setLoading(true))
      const { data, status } = await ticketPurchase({
        paymentMethod: {
          type: 'PIX',
          cashBackValueCents:
            cashbackState.value > 0 ? cashbackState.value * 100 : null,
          customerCouponId: coupon?.customerCouponId
        },
        tickets: ticketState!!.map(
          ({ ticketInfo, id, eventItemId, seatKey }) => ({
            ticketInfo,
            ticketId: id,
            eventItemId,
            seatKey: eventData!!.type === 'MAPPED' ? seatKey : undefined
          })
        )
      })

      if (status === 201) {
        sessionStorage.removeItem('seatsio')
        await handlePurchaseOrder(data.id)
      }
    } catch (err: any) {
      dispatch(clearLoading())
      if (err) {
        dispatch(
          setModalState({
            message: messageHelper.modal.message.ticketUnavailable,
            open: true,
            variant: 'error',
            description: messageHelper.modal.error.ticketUnavailable,
            click: () => {
              dispatch(clearModalState())
              dispatch(profileEventAction())
              history.push(`/event/${eventData?.id}`)
            }
          })
        )
      }
    }
  }

  useEffect(() => {
    if (UserReducer.cashBackBalanceCents === 0) {
      handlePaymentPix()
    }
  }, [])

  const handleCopyText = () => {
    const inputCopy: any = document.getElementById('inputCopy')

    inputCopy?.select()

    document.execCommand('copy')
  }

  const handleCashBackRadioButtonClick = () => {
    if (cashbackState.isAvailable) {
      setCashbackState({
        isAvailable: false,
        edit: false,
        value: 0
      })
    } else {
      setCashbackState((prev) => ({
        ...prev,
        isAvailable: !prev.isAvailable
      }))
    }
  }

  const price =
    ticketState?.reduce((acc, ticket) => acc + ticket.totalPrice, 0) ?? 0

  const handleCashBackInput = (value: any) => {
    if (
      value !== undefined &&
      Number(value.replace(',', '.')) <=
        UserReducer.cashBackBalanceCents! / 100 &&
      Number(value.replace(',', '.')) <= price - 5
    ) {
      setCashbackState((prev) => ({
        ...prev,
        value: Number(value.replace(',', '.'))
      }))
    } else if (value !== undefined && value.length === 0) {
      setCashbackState((prev) => ({
        ...prev,
        value: 0
      }))
    } else if (value === undefined) {
      setCashbackState((prev) => ({
        ...prev,
        value: 0
      }))
    }
  }

  const handleContinueButton = async () => {
    await handlePaymentPix()
    setShowPixData(true)
  }

  return (
    <S.Container>
      <Hidden mobile>
        <AppBar />
      </Hidden>

      <S.Wrapper>
        <GridContainer>
          <Row>
            <Hidden mobile tablet>
              <Column
                desktop={6}
                spacing={isDesktop ? 3 : 0}
                className="pucharseSummary">
                <PurchaseSummary
                  eventAddress={eventData?.address.street ?? ''}
                  eventDate={
                    moment(eventData?.eventItems[0].dates[0])
                      .locale('pt-br')
                      .format('ll [ás] HH[h]mm') ?? ''
                  }
                  tickets={ticketState ?? []}
                  titleTicket={eventData?.name ?? ''}
                />
              </Column>
            </Hidden>

            <Column
              mobile={4}
              spacing={isDesktop ? 3 : 0}
              desktop={6}
              tablet={8}>
              <Hidden tablet desktop>
                <ButtonBack
                  title="Voltar"
                  click={() => {
                    cancelRetry.current = true
                    dispatch(setLoading())
                  }}
                />
              </Hidden>
              <div className="descriptionCard">
                <DescriptionCard title="Opções de pagamento" />
              </div>
              <div className="buttonCard">
                <ButtonCard title="Pix" type="pix" />
              </div>
              {UserReducer.cashBackBalanceCents > 0 ? (
                <S.CashBackComponent>
                  <div>
                    <S.CashBackIcon />
                    <p>Cashback</p>
                  </div>
                  {!isMobile && (
                    <span>
                      {formatPrice(UserReducer.cashBackBalanceCents / 100)}
                    </span>
                  )}
                  <RadioButton
                    readOnly
                    checked={cashbackState.isAvailable}
                    onClick={handleCashBackRadioButtonClick}
                  />
                </S.CashBackComponent>
              ) : undefined}

              {cashbackState.isAvailable && (
                <AnimatePresence>
                  <motion.div
                    key="cashbackValue"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    exit={{ opacity: 0 }}>
                    <S.CashbackValueWrapper>
                      {cashbackState.edit ? (
                        <>
                          <p className="cashback-title">
                            Digite o valor que deseja usar do seu saldo:
                          </p>
                          <S.InputWrapper>
                            <CurrencyInput
                              id="input-example"
                              name="input-name"
                              decimalsLimit={2}
                              allowNegativeValue={false}
                              prefix="R$ "
                              decimalSeparator=","
                              groupSeparator="."
                              onValueChange={handleCashBackInput}
                            />
                            <Hidden mobile tablet>
                              <Button
                                className="desktop-button"
                                color="primary"
                                variant="contained"
                                size="small"
                                fullWidth={false}
                                onClick={() =>
                                  setCashbackState((prev) => ({
                                    ...prev,
                                    edit: false
                                  }))
                                }>
                                Salvar
                              </Button>
                            </Hidden>
                          </S.InputWrapper>

                          <div className="available-box">
                            <p className="available-text">Disponível: </p>
                            <span className="available-value">
                              {formatPrice(
                                UserReducer.cashBackBalanceCents! / 100
                              )}
                            </span>
                          </div>
                          <Hidden desktop>
                            <Button
                              color="primary"
                              variant="contained"
                              size="small"
                              fullWidth={false}
                              onClick={() =>
                                setCashbackState((prev) => ({
                                  ...prev,
                                  edit: false
                                }))
                              }>
                              Salvar
                            </Button>
                          </Hidden>
                        </>
                      ) : (
                        <>
                          <p className="cashback-title">
                            Quanto usar do saldo:
                          </p>
                          <span>{formatPrice(cashbackState.value)}</span>
                          <p
                            className="edit-cashback-value"
                            onClick={() =>
                              setCashbackState((prev) => ({
                                ...prev,
                                edit: true
                              }))
                            }>
                            Editar valor
                          </p>
                        </>
                      )}
                    </S.CashbackValueWrapper>
                  </motion.div>
                </AnimatePresence>
              )}

              {showPixData ? (
                <S.ContentPix>
                  <S.TextWaitingPayment>
                    Pedido aguardando pagamento
                  </S.TextWaitingPayment>

                  <S.QrCode src={qrCodeImage.pixQrCode} />

                  <InputCopy
                    id="inputCopy"
                    name="qrCode"
                    value={qrCodeImage.pixQrCodeText}
                    fullWidth
                    readOnly
                  />

                  <S.TextDescription>
                    <h1>
                      Copie o código ou use a câmera do seu celular para ler o
                      QR code.
                    </h1>
                    <p>
                      Você tem 5 minutos para realizar o pagamento após esse
                      tempo, o pedido será cancelado.
                    </p>
                  </S.TextDescription>

                  <Hidden tablet desktop style={{ width: '100%' }}>
                    <Button
                      type="button"
                      color="primary"
                      size="large"
                      variant="text"
                      fullWidth
                      onClick={handleCopyText}>
                      Copiar código
                    </Button>
                  </Hidden>

                  <Hidden mobile style={{ width: '100%' }}>
                    <S.ButtonBox>
                      <ButtonBack
                        title="Voltar"
                        click={() => {
                          cancelRetry.current = true
                          dispatch(setLoading())
                        }}
                      />
                      <div>
                        <Button
                          type="button"
                          color="primary"
                          size="large"
                          variant="text"
                          fullWidth
                          onClick={handleCopyText}>
                          Copiar código
                        </Button>
                      </div>
                    </S.ButtonBox>
                  </Hidden>
                </S.ContentPix>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  fullWidth={false}
                  style={{ marginTop: 'auto', marginLeft: 'auto' }}
                  onClick={handleContinueButton}>
                  Continuar
                </Button>
              )}
            </Column>
          </Row>
        </GridContainer>
      </S.Wrapper>
    </S.Container>
  )
}
