import produce from 'immer'
import { Reducer } from 'redux'
import { EventsAction, EventState } from './types'
import history from '../../routes/services/history'

const INITIAL_STATE: EventState = {
  step: 'showEvent',
  tickets: []
}

export const EventReducer: Reducer<EventState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case EventsAction.prevStep:
      return produce(state, (draft) => {
        draft.step = action.payload
        if (action.payload === 'home') {
          history.push('/')
          draft.eventData = undefined
        }
        if (action.payload === 'chooseTicket') {
          if (
            draft.eventData &&
            draft.eventData.eventItems.length === 1 &&
            draft.eventData.eventItems[0].dates.length === 1
          ) {
            draft.step = 'showEvent'
            draft.tickets = []
          } else {
            draft.tickets = []
            draft.selectedEvent = undefined
            draft.step = 'chooseTicket'
          }
        }
      })
    case EventsAction.loadEventSuccess:
      return produce(state, (draft) => {
        draft.eventData = action.payload
        if (draft.eventData && draft.eventData.eventItems.length === 1) {
          draft.selectedEvent = draft.eventData.eventItems[0]
        }
      })
    case EventsAction.profileEvent:
      return produce(state, (draft) => {
        draft.step = 'showEvent'
        draft.tickets = []
      })
    case EventsAction.chooseTicket:
      return produce(state, (draft) => {
        draft.step = 'chooseTicket'
        if (draft.eventData && draft.eventData.eventItems.length === 1) {
          draft.selectedEvent = draft.eventData.eventItems[0]
        }
      })
    case EventsAction.selectEvent:
      return produce(state, (draft) => {
        draft.selectedEvent = action.payload
        draft.tickets = []
      })
    case EventsAction.selectTickets:
      return produce(state, (draft) => {
        draft.tickets = action.payload
      })
    case EventsAction.addTicketInfo:
      return produce(state, (draft) => {
        draft.tickets.forEach(
          (ticket, index) => (ticket.ticketInfo = action.payload[index])
        )
      })
    case EventsAction.purchaseSummary:
      return produce(state, (draft) => {
        draft.step = 'purchaseSummary'
      })
    case EventsAction.addTicket:
      return produce(state, (draft) => {
        const findTicket = draft.selectedEvent!.tickets.findIndex(
          (ticket) => ticket.id === action.payload
        )
        if (findTicket !== -1) {
          const { id, name, totalPrice, category } =
            draft.selectedEvent!.tickets[findTicket]

          draft.tickets.push({
            id,
            name,
            totalPrice,
            category,
            eventItemId: draft.selectedEvent!.id
          })
        }
      })
    case EventsAction.removeTicket:
      return produce(state, (draft) => {
        const findIndex = draft.tickets.findIndex(
          (ticket) => ticket.id === action.payload
        )
        if (findIndex !== -1) {
          draft.tickets.splice(findIndex, 1)
        }
      })
    case EventsAction.clearSelectedEvent:
      return produce(state, (draft) => {
        draft.selectedEvent = undefined
      })
    case EventsAction.clearEvent:
      return INITIAL_STATE
    default:
      return state
  }
}
