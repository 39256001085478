import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    .buttonBox {
      > button {
        width: 100%;
      }
    }
    .buttonBack {
      padding: ${theme.spacing.stack.xxxs};
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
  max-width: 1366px; 
   
  @media (min-width: 768px) and (max-width: 1170px){
    padding ${theme.spacing.stack.xs};
   }

    @media (min-width: 1171px) {
      padding ${theme.spacing.stack.xxl} ${theme.spacing.stack.xxxl}; 
    }
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.font.size.xl};
    color: #39383a;
    margin-bottom: ${theme.spacing.stack.xs};

    @media (min-width: 768px) and (max-width: 1170px) {
      text-align: center;
    }
  `}
`
