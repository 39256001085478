import styled, { css } from 'styled-components'
import { ReactComponent as LocationFile } from '../../../assets/icons/location.svg'

import { ReactComponent as ChevronDownFile } from '../../../assets/icons/chevron-down.svg'
import { ReactComponent as LogoutFile } from '../../../assets/icons/signout.svg'
import { ReactComponent as UserFile } from '../../../assets/icons/user.svg'
import { ReactComponent as SearchFile } from '../../../assets/icons/search.svg'

import { ReactComponent as CashBackFile } from '../../../assets/icons/cashback.svg'

export const CashBackIcon = styled(CashBackFile)`
  fill: #6c01a3;
  cursor: pointer;
  margin-right: 12px;
`

export const ChevronDownIcon = styled(ChevronDownFile)`
  fill: #6c01a3;
  cursor: pointer;
`

export const SearchIcon = styled(SearchFile)`
  ${({ theme }) => css`
    fill: #6c01a3;
    position: absolute;
    top: 50%;
    left: ${theme.spacing.stack.xxxs};
    transform: translateY(-50%);
  `}
`

export const UserIcon = styled(UserFile)`
  fill: #8601c9;
`

export const LogoutIcon = styled(LogoutFile)`
  fill: #8601c9;
`

export const Logo = styled.img`
  height: 60px;
  cursor: pointer;
`

export const DropDown = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
  `}
`

export const DropDownContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      align-items: center;
      margin-bottom: ${theme.spacing.stack.small};

      .chevronIcon {
        cursor: pointer;
      }

      .userName {
        white-space: nowrap;
        margin-right: ${theme.spacing.stack.small};
        color: #8601c9;
        text-transform: capitalize;
        cursor: pointer;
      }

      .cashback-value {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: ${theme.color.brand.primary.nth2};
      }
    }
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: ${theme.spacing.stack.xxxs};
    padding: ${theme.spacing.inline.xxs} 0 0;
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: ${theme.spacing.inline.md};
  `}
`

export const InputWrapper = styled.div`
  position: relative;
`

export const StyledInput = styled.input`
  ${({ theme }) => css`
    width: max-content;
    height: 60px;
    border-radius: ${theme.border.radius.medium};
    outline: 0;
    border: 0;
    background: #f3f4f5;
    font-size: ${theme.font.size.sm};
    padding-left: ${theme.spacing.stack.md};
    min-width: 420px;
    &:focus {
      border: ${theme.border.width.line} solid #8601c9;
      background: #ffffff;
    }
    &::placeholder {
      font-size: ${theme.font.size.sm};
      color: #39383a;
      padding-left: ${theme.spacing.stack.small};
    }
    &:not(:placeholder-shown) {
      border: ${theme.border.width.line} solid #8601c9;
      background: #ffffff;
    }
  `}
`

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.inline.md};
    > button {
      white-space: nowrap;
    }
  `}
`

export const LocationWrapper = styled.div`
  ${({ theme }) => css`
    width: max-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    > p {
      white-space: nowrap;
      margin-right: ${theme.spacing.stack.small};
    }
  `}
`

export const LocationIcon = styled(LocationFile)`
  fill: #8601c9;
  width: 24px;
`
export const UserName = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    width: 100%;
    text-align: center;
  `}
`
