import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import { ReactComponent as SignoutFile } from '../../../assets/icons/signout.svg'
import { ReactComponent as ProfileFile } from '../../../assets/icons/profile.svg'
import { ReactComponent as ChevronRightFile } from '../../../assets/icons/chevronRight.svg'
import { ReactComponent as HomeFile } from '../../../assets/icons/home.svg'
import { ReactComponent as TicketFile } from '../../../assets/icons/coupon.svg'
import { ReactComponent as DataFile } from '../../../assets/icons/file.svg'
import { ReactComponent as SettingsFile } from '../../../assets/icons/lock.svg'
import { ReactComponent as FolderFile } from '../../../assets/icons/folder.svg'
import { ReactComponent as Cashback } from '../../../assets/icons/cashback.svg'

export const DataIcon = styled(DataFile)`
  fill: #39383a;
`

export const CashbackIcon = styled(Cashback)`
  fill: #39383a;
`

export const HomeIcon = styled(HomeFile)`
  fill: #39383a;
`

export const SignoutIcon = styled(SignoutFile)`
  fill: #39383a;
`

export const ProfileIcon = styled(ProfileFile)`
  fill: #6c01a3;
`

export const ChevronRightIcon = styled(ChevronRightFile)`
  fill: #39383a;
`

export const TicketIcon = styled(TicketFile)`
  fill: #39383a;
  width: 30px;
  height: 30px;
`

export const SettingsIcon = styled(SettingsFile)`
  fill: #39383a;
`

export const FolderIcon = styled(FolderFile)`
  fill: #39383a;
`

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    min-width: 320px;
    @media (min-width: 1171px) {
      display: grid;
      grid-template-columns: 335px auto;
    }
  `}
`

export const Wrapper = styled.section`
  ${({ theme }) => css`
    @media (min-width: 1171px) {
      display: flex;
      flex-direction: column;
      background: #f4f4f4;
      margin: auto 0;
      height: 100%;
    }
  `}
`

export const Logo = styled.img`
  ${({ theme }) => css`
    width: auto;
    margin: ${theme.spacing.stack.xl};
    cursor: pointer;
  `}
`

export const Header = styled.section`
  ${({ theme }) => css`
    background: #f4f4f4;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.sm} ${theme.spacing.inline.xxs}
      ${theme.spacing.stack.xxs};
    border-bottom-left-radius: ${theme.border.radius.big};
    border-bottom-right-radius: ${theme.border.radius.big};
    margin-bottom: ${theme.spacing.stack.xxxs};
  `}
`

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`

export const UserBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-around;
    .userInfo {
      color: #6c01a3;
    }
  `}
`

export const MenuButtonsBox = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      padding: 0 ${theme.spacing.inline.xxs};
      > div {
        border-bottom: 1px solid #dfdfdf;
      }
      div:last-child {
        border-bottom: 1px solid transparent;
      }
    }
  `}
`

export const MenuButton = styled(motion.div)<{ active: boolean }>`
  ${({ theme, active }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.stack.xxxs} 0 ${theme.spacing.stack.xxxs}
      ${theme.spacing.stack.xs};
    .content {
      display: flex;
      align-self: center;
    }
    .text {
      align-items: center;
      margin-left: ${theme.spacing.inline.xs};
    }
    @media (min-width: 1171px) {
      background: ${active ? '#DFDFDF' : 'transparent'};
      cursor: pointer;
      transition: background-color ease-in-out 0.3s;
      &:hover {
        background-color: #dfdfdf;
      }
    }
  `}
`
