import produce from 'immer'
import { Reducer } from 'redux'
import { TicketActions, TicketState } from './types'

const INITIAL_STATE: TicketState = {
  state: []
}

export const TicketReducer: Reducer<TicketState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TicketActions.addTicket:
      return produce(state, (draftState) => {
        draftState.state.push({
          objectId: action.payload.objectId,
          ticketType: action.payload.ticketType,
          price: action.payload.price,
          own: action.payload.own,
          parent: action.payload.parent
        })
      })
    case TicketActions.removeTicket:
      return produce(state, (draftState) => {
        const ticketIndex = draftState.state.findIndex(
          ({ objectId }) => objectId === action.payload
        )
        if (ticketIndex !== -1) {
          draftState.state.splice(ticketIndex, 1)
        }
      })
    case TicketActions.clearTicket:
      return produce(state, (draftState) => {
        draftState.state = []
      })
    default:
      return state
  }
}
