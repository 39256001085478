import {
  Hidden,
  Input,
  InputMask,
  Paragraph,
  RadioButton,
  Button
} from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import { purchaseOrder } from '../../../../api/services/checkout/purchaseOrder'
import { ticketPurchase } from '../../../../api/services/checkout/ticketPurchase'
import { AppBar } from '../../../../components/appBar/AppBar'
import { ButtonBack } from '../../../../components/buttonBack/buttonBack'
import { ButtonCard } from '../../../../components/buttonCard/ButtonCard'
import { DescriptionCard } from '../../../../components/descriptionCard/DescriptionCard'
import {
  defaultCheckout,
  orderProcess,
  purchaseStatus
} from '../../../../redux/Checkout/actions'
import { clearEvent, profileEventAction } from '../../../../redux/Event/actions'
import { clearLoading, setLoading } from '../../../../redux/Loading/actions'
import {
  clearModalState,
  setModalState
} from '../../../../redux/Modal/Actions/actionsModal'
import { ReduxState } from '../../../../redux/rootReducer'
import history from '../../../../routes/services/history'
import { formatPrice } from '../../../../utils/formatPrice'
import { messageHelper } from '../../../../utils/messageHelper'
import * as S from './CreditCard.styles'
import { paymentSchema } from './validationSchema'
import {
  Container as GridContainer,
  Column,
  Row
} from '../../../../components/grid/GridSystem'
import { PurchaseSummary } from '../components/purchaseSummary/PurchaseSummary'
import moment from 'moment'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import CurrencyInput from 'react-currency-input-field'
import { calculateTotalPrice } from '../../../../utils/calculateTotalPrice'

type Window = {
  [key: string]: any
}

const Iugu = (window as Window).Iugu

type IuguResponse = {
  id: string
  errors: any
}

Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID)
Iugu.setTestMode(process.env.REACT_APP_IUGU_TEST_MODE)

export const CreditCard = () => {
  const dispatch = useDispatch()
  const { isDesktop, isMobile } = useWindowSize()

  const { CheckoutReducer, UserReducer } = useSelector(
    (state: ReduxState) => state
  )

  const { cashBackBalanceCents } = UserReducer
  const { coupon, data } = CheckoutReducer
  const { eventData, ticketState } = data

  const price = calculateTotalPrice(ticketState ?? [], coupon)

  const [cashbackState, setCashbackState] = useState({
    isAvailable: false,
    edit: false,
    value: 0
  })
  const [numberInstallment, setNumberInstallment] = useState(1)
  const [installmentOptions, setInstallmentsOptions] = useState<
    { label: string; value: number }[]
  >([])

  const handlePurchaseOrder = async (purchaseOrderId: string) => {
    dispatch(setLoading(true))

    const purchaseOrderData = await purchaseOrder(
      purchaseOrderId,
      Number(process.env.REACT_APP_REQUEST_INTERVAL)
    )
    if (purchaseOrderData?.status === 'SUCCESS') {
      dispatch(clearLoading())
      dispatch(purchaseStatus('SUCCESS'))
      dispatch(orderProcess(purchaseOrderId))
      dispatch(clearEvent())
    } else if (purchaseOrderData?.status === 'WAITING') {
      dispatch(clearLoading())
      dispatch(purchaseStatus('WAITING'))
      dispatch(orderProcess(purchaseOrderId))
      dispatch(clearEvent())
    } else {
      dispatch(clearLoading())
      switch (purchaseOrderData?.failedCode.code) {
        case 'TICKET_UNAVAILABLE':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.ticketUnavailable,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.ticketUnavailable
            })
          )

        case 'PAYMENT_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.reviewData,
              open: true,
              variant: 'error',
              buttonText: 'Revisar dados',
              description: messageHelper.modal.error.reviewData
            })
          )

        case 'INTEGRATION_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError
            })
          )

        case 'BOOKING_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError
            })
          )

        case 'BAD_REQUEST_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError
            })
          )

        case 'PURCHASE_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError
            })
          )
      }
    }

    dispatch(clearLoading())
  }

  const formik = useFormik({
    initialValues: {
      creditCardOwner: '',
      creditCardNumber: '',
      creditCardValidity: '',
      creditCardCVV: ''
    },
    validationSchema: paymentSchema,
    onSubmit: (values) => {
      const creditCardOwnerNameList = values.creditCardOwner.split(' ')
      const cc = Iugu.CreditCard(
        values.creditCardNumber.replace(/[._ ]/g, ''),
        values.creditCardValidity.substring(0, 2),
        moment(values.creditCardValidity, 'MM/YY').year().toString(),
        creditCardOwnerNameList[0],
        creditCardOwnerNameList[1],
        values.creditCardCVV.trim()
      )

      const responseIugu = async (response: IuguResponse) => {
        if (response.id && data.ticketState && data.eventData) {
          try {
            const { status, data } = await ticketPurchase({
              paymentMethod: {
                type: 'CREDIT_CARD',
                token: response.id,
                installments: numberInstallment > 1 ? numberInstallment : null,
                cashBackValueCents:
                  cashbackState.value > 0 ? cashbackState.value * 100 : null,
                customerCouponId: coupon?.customerCouponId
              },
              tickets: ticketState!.map(
                ({ ticketInfo, id, eventItemId, seatKey }) => ({
                  ticketInfo,
                  ticketId: id,
                  eventItemId,
                  seatKey: eventData!.type === 'MAPPED' ? seatKey : undefined
                })
              )
            })

            if (status === 201) {
              sessionStorage.removeItem('seatsio')
              await handlePurchaseOrder(data.id)
            }
          } catch (err) {
            dispatch(clearLoading())

            if (err) {
              dispatch(
                setModalState({
                  message: messageHelper.modal.message.ticketUnavailable,
                  open: true,
                  variant: 'error',
                  description: messageHelper.modal.error.ticketUnavailable,
                  click: () => {
                    dispatch(clearModalState())
                    dispatch(profileEventAction())
                    history.push(`/event/${eventData?.id}`)
                  }
                })
              )
            }
          }
        } else {
          dispatch(
            setModalState({
              message: 'Por favor, revise seus dados de pagamento',
              open: true,
              variant: 'error',
              buttonText: 'Revisar dados',
              description: messageHelper.modal.error.reviewData
            })
          )
        }
      }
      Iugu.createPaymentToken(cc, responseIugu)
    }
  })

  const variant = { opacity: [0, 1], transition: { duration: 1 } }

  const handleCashBackInput = (value: any) => {
    if (
      value !== undefined &&
      Number(value.replace(',', '.')) <= cashBackBalanceCents! / 100 &&
      Number(value.replace(',', '.')) <= price - 5
    ) {
      setCashbackState((prev) => ({
        ...prev,
        value: Number(value.replace(',', '.'))
      }))
    } else if (value !== undefined && value.length === 0) {
      setCashbackState((prev) => ({
        ...prev,
        value: 0
      }))
    } else if (value === undefined) {
      setCashbackState((prev) => ({
        ...prev,
        value: 0
      }))
    }
  }

  const handleCashBackRadioButtonClick = () => {
    if (cashbackState.isAvailable) {
      setCashbackState({
        isAvailable: false,
        edit: false,
        value: 0
      })
    } else {
      setCashbackState((prev) => ({
        ...prev,
        isAvailable: !prev.isAvailable
      }))
    }
  }

  useEffect(() => {
    const initialState = []
    for (let i = 1; i <= eventData!.maxInstallments; i++) {
      if ((price - cashbackState.value) / i < 5) {
        break
      }
      initialState.push({
        value: i,
        label: `${formatPrice((price - cashbackState.value) / i)}`
      })
    }

    setInstallmentsOptions(initialState)
  }, [cashbackState])

  const Option = (props: any) => {
    const { innerRef, innerProps, ...data } = props
    return (
      <S.Option ref={innerRef} {...innerProps}>
        <span>{data.value}x de</span>
        <p>{data.label}</p>
      </S.Option>
    )
  }

  const CustomInput = (props: any) => {
    const { selectProps } = props
    if (selectProps.value && selectProps.value[0]) {
      const { label, value } = selectProps.value[0]
      return (
        <S.Option>
          <span>{value}x de</span>
          <p>{label}</p>
        </S.Option>
      )
    } else {
      return <></>
    }
  }

  return (
    <S.Container>
      <Hidden mobile>
        <AppBar />
      </Hidden>
      <GridContainer className="gridContainer">
        <S.Wrapper>
          <Hidden tablet desktop>
            <ButtonBack
              className="button-back"
              title="Voltar"
              click={() => {
                dispatch(defaultCheckout())
              }}
            />
          </Hidden>

          <motion.div animate={variant}>
            <Row marginBottom="xxs">
              <Hidden mobile tablet>
                <Column desktop={6} spacing={isDesktop ? 3 : 0}>
                  <PurchaseSummary
                    eventAddress={eventData?.address.street ?? ''}
                    eventDate={
                      moment(eventData?.eventItems[0].dates[0])
                        .locale('pt-br')
                        .format('ll [ás] HH[h]mm') ?? ''
                    }
                    tickets={ticketState ?? []}
                    titleTicket={eventData?.name ?? ''}
                  />
                </Column>
              </Hidden>

              <Column
                mobile={4}
                desktop={6}
                tablet={8}
                spacing={isDesktop ? 3 : 0}>
                <S.ContentForm>
                  <DescriptionCard title="Opções de pagamento" />
                  <div className="spacing" />
                  <ButtonCard title="Cartão de crédito" type="creditCard" />
                  {cashBackBalanceCents > 0 ? (
                    <S.CashBackComponent>
                      <div>
                        <S.CashBackIcon />
                        <p>Cashback</p>
                      </div>
                      {!isMobile && (
                        <span>{formatPrice(cashBackBalanceCents / 100)}</span>
                      )}
                      <RadioButton
                        readOnly
                        checked={cashbackState.isAvailable}
                        onClick={handleCashBackRadioButtonClick}
                      />
                    </S.CashBackComponent>
                  ) : undefined}

                  {cashbackState.isAvailable && (
                    <AnimatePresence>
                      <motion.div
                        key="cashbackValue"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        exit={{ opacity: 0 }}>
                        <S.CashbackValueWrapper>
                          {cashbackState.edit ? (
                            <>
                              <p className="cashback-title">
                                Digite o valor que deseja usar do seu saldo:
                              </p>
                              <S.InputWrapper>
                                <CurrencyInput
                                  id="input-example"
                                  name="input-name"
                                  decimalsLimit={2}
                                  allowNegativeValue={false}
                                  prefix="R$ "
                                  decimalSeparator=","
                                  groupSeparator="."
                                  onValueChange={handleCashBackInput}
                                />
                                <Hidden mobile tablet>
                                  <Button
                                    className="desktop-button"
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    fullWidth={false}
                                    onClick={() =>
                                      setCashbackState((prev) => ({
                                        ...prev,
                                        edit: false
                                      }))
                                    }>
                                    Salvar
                                  </Button>
                                </Hidden>
                              </S.InputWrapper>

                              <div className="available-box">
                                <p className="available-text">Disponível: </p>
                                <span className="available-value">
                                  {formatPrice(cashBackBalanceCents! / 100)}
                                </span>
                              </div>
                              <Hidden desktop>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  fullWidth={false}
                                  onClick={() =>
                                    setCashbackState((prev) => ({
                                      ...prev,
                                      edit: false
                                    }))
                                  }>
                                  Salvar
                                </Button>
                              </Hidden>
                            </>
                          ) : (
                            <>
                              <p className="cashback-title">
                                Quanto usar do saldo:
                              </p>
                              <span>{formatPrice(cashbackState.value)}</span>
                              <p
                                className="edit-cashback-value"
                                onClick={() =>
                                  setCashbackState((prev) => ({
                                    ...prev,
                                    edit: true
                                  }))
                                }>
                                Editar valor
                              </p>
                            </>
                          )}
                        </S.CashbackValueWrapper>
                      </motion.div>
                    </AnimatePresence>
                  )}

                  <div className="installments-options">
                    <Paragraph variant="large" type="semiBold">
                      Parcelamento
                    </Paragraph>
                    <ReactSelect
                      name="parcelamento"
                      placeholder="Escolha o parcelamento"
                      value={installmentOptions.filter(
                        ({ value }) => value === numberInstallment
                      )}
                      onChange={(e) => {
                        setNumberInstallment(e!.value)
                      }}
                      components={{ Option: Option, SingleValue: CustomInput }}
                      options={installmentOptions}
                      styles={S.customStyles}
                    />
                  </div>

                  <S.Form onSubmit={formik.handleSubmit}>
                    <Input
                      name="creditCardOwner"
                      label="*Nome do titular do cartão"
                      placeholder="Digite o nome"
                      texterror={formik.errors.creditCardOwner}
                      error={
                        formik.touched.creditCardOwner &&
                        Boolean(formik.errors.creditCardOwner)
                      }
                      onChange={formik.handleChange}
                      value={formik.values.creditCardOwner}
                      fullWidth
                    />
                    <InputMask
                      mask="9999 9999 9999 9999"
                      name="creditCardNumber"
                      label="*Número do cartão"
                      placeholder="0000 0000 0000 0000"
                      texterror={formik.errors.creditCardNumber}
                      error={
                        formik.touched.creditCardNumber &&
                        Boolean(formik.errors.creditCardNumber)
                      }
                      onChange={formik.handleChange}
                      value={formik.values.creditCardNumber}
                      fullWidth
                    />
                    <div className="creditCardRow">
                      <InputMask
                        mask="99/99"
                        name="creditCardValidity"
                        label="*Data de validade"
                        placeholder="00/00"
                        texterror={formik.errors.creditCardValidity}
                        error={
                          formik.touched.creditCardValidity &&
                          Boolean(formik.errors.creditCardValidity)
                        }
                        onChange={formik.handleChange}
                        value={formik.values.creditCardValidity}
                        fullWidth
                      />
                      <InputMask
                        mask="9999"
                        name="creditCardCVV"
                        label="*CVV"
                        placeholder="0000"
                        texterror={formik.errors.creditCardCVV}
                        error={
                          formik.touched.creditCardCVV &&
                          Boolean(formik.errors.creditCardCVV)
                        }
                        onChange={formik.handleChange}
                        value={formik.values.creditCardCVV}
                        fullWidth
                      />
                    </div>

                    <S.TextTermsOfUse>
                      Ao prosseguir, você declara estar ciente dos
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={process.env.REACT_APP_AUTH_WEB_URL + '/terms'}>
                        {' '}
                        Termos e Políticas
                      </a>{' '}
                      da Applaus.
                    </S.TextTermsOfUse>

                    <S.ContentBack>
                      <Hidden mobile>
                        <ButtonBack
                          title="Voltar"
                          click={() => {
                            dispatch(defaultCheckout())
                          }}
                        />
                      </Hidden>
                      <div>
                        <S.ButtonStyled
                          color="primary"
                          fullWidth
                          size="large"
                          variant="contained">
                          Comprar
                        </S.ButtonStyled>
                      </div>
                    </S.ContentBack>
                  </S.Form>
                </S.ContentForm>
              </Column>
            </Row>
          </motion.div>
        </S.Wrapper>
      </GridContainer>
    </S.Container>
  )
}
