import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/rootReducer'
import { Redirect } from 'react-router-dom'
import { ChooseEventItem } from './ChooseEventItem/ChooseEventItem'
import { MappedEvent } from './MappedEvent/Mobile/MappedEvent'
import { MobileTabulatedEvent } from './TabulatedEvent/Mobile/MobileTabulatedEvent'

export const ChooseTicket = () => {
  const { eventData } = useSelector((state: ReduxState) => state.EventReducer)

  if (eventData) {
    if (
      eventData.eventItems.length > 1 ||
      eventData.eventItems[0].dates.length > 1
    ) {
      return <ChooseEventItem />
    } else if (eventData.type === 'MAPPED') {
      return <MappedEvent />
    } else {
      return <MobileTabulatedEvent />
    }
  } else return <Redirect to="/" />
}
