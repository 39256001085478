import api from '../../config/api'
import { AxiosResponse } from 'axios'

export type EditUserRequest = {
  firstName?: string
  lastName?: string
  email?: string
  document?: string
  password?: string
  phone?: string
  address?: {
    country?: string
    city?: string
    state?: string
    street?: string
    number?: number
    zipCode?: string
    complement?: string | null
  }
}

export type CustomerResponse = {
  id: string
  userId: string
  firstName: string
  lastName: string
  email: string
  document: string
  phone: string
  address?: {
    country: string
    city: string
    state: string
    street: string
    number: number
    zipCode?: string
    complement?: string
  }
  createdAt: string
  updatedAt?: string
}

export const editProfileService = async (body: EditUserRequest) => {
  if (body.address) {
    if (body.address.number) {
      body.address.number = Number(body.address.number)
    }
  }

  if (body.phone) {
    body.phone = body.phone.replace(/[^0-9]/g, '').trim()
  }

  const { data }: AxiosResponse<CustomerResponse> = await api.put(
    '/api/customers',
    body
  )

  return data
}
