import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
`

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 767px) {
    div:first-of-type > label {
      font-size: 12px;
    }
    div:last-of-type > button {
      padding: 13px;
      font-size: 9px;
      line-height: 16px;
      font-weight: 600;
    }
  }
`

export const ExpandCouponText = styled.p`
  ${({ theme }) => css`
    cursor: pointer;
    margin: 0 auto;
    color: ${theme.color.brand.primary.nth1};
    @media (max-width: 767px) {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
    }
    @media (min-width: 1171px) {
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
      text-align: center;
    }
  `}
`
