import * as S from './DescriptionCard.styles'

type DescriptionCardProps = {
  title: string
}

export const DescriptionCard = ({ title }: DescriptionCardProps) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
    </S.Container>
  )
}
