import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { ReactComponent as ChevronDownFile } from '../../../../../assets/icons/chevron-down.svg'
import { ReactComponent as FilesFile } from '../../../../../assets/icons/files.svg'

export const FilesIcon = styled(FilesFile)`
  fill: black;
`

export const ChevronIcon = styled(ChevronDownFile)`
  ${({ theme }) => css`
    fill: gray;
    transition: 0.3s ease;
    width: 34px;
    height: 34px;
    padding: ${theme.spacing.stack.small};
    &:hover {
      fill: black;
      transform: translateY(10%) scale(1.2);
    }
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing.stack.small} 0;
  `}
`

export const ModuleInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    p:first-of-type {
      margin-right: ${theme.spacing.inline.xs};
      color: #808080;
      white-space: nowrap;
    }
  `}
`

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    @media (min-width: 1171px) {
      background: #f4f4f4;
      padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.sm};
      border-radius: ${theme.border.radius.medium};
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      background: #f4f4f4;
      padding: ${theme.spacing.stack.xxs};
      border-radius: ${theme.border.radius.medium};
    }
    .module-title {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      margin: 0;
    }
  `}
`

export const IconBox = styled(motion.div)`
  cursor: pointer;
`

export const AccordionInfo = styled(motion.div)`
  ${({ theme }) => css`
    padding-left: ${theme.spacing.stack.xxxs} > p {
      margin: ${theme.spacing.stack.small} 0 ${theme.spacing.stack.small}
        ${theme.spacing.stack.small};
    }
  `}
`
