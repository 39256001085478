import { AxiosResponse } from 'axios'
import { Course } from '../../../redux/Content/types'
import { publicApi } from '../../config/api'

export const listOneCourseService = async (courseId: string) => {
  const { data }: AxiosResponse<Course> = await publicApi.get(
    `api/courses/${courseId}`
  )

  return data
}
