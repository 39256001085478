import * as S from './MobileTabulatedEvent.styles'
import { TabulatedEvent } from '../TabulatedEvent'
import { Button, Heading, Paragraph } from 'applaus-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/rootReducer'
import { formatPrice } from '../../../../../utils/formatPrice'
import { purchaseSummaryAction } from '../../../../../redux/Event/actions'

export const MobileTabulatedEvent = () => {
  const { tickets } = useSelector((state: ReduxState) => state.EventReducer)
  const { email } = useSelector((state: ReduxState) => state.UserReducer)
  const dispatch = useDispatch()

  return (
    <S.Container>
      <TabulatedEvent />
      <div style={{ height: '74px' }} />
      {tickets && tickets?.length > 0 && (
        <S.TicketBox>
          <S.TotalPrice>
            <Paragraph variant="small" type="semiBold">
              Total
            </Paragraph>
            <Heading variant="h5">
              {formatPrice(
                tickets?.reduce((acc, val) => {
                  return acc + val.totalPrice
                }, 0) ?? 0
              )}
            </Heading>
          </S.TotalPrice>

          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="button"
            size="small"
            style={{ whiteSpace: 'nowrap' }}
            onClick={() => {
              if (!email) {
                window.location.href = process.env.REACT_APP_AUTH_WEB_URL ?? ''
              } else {
                dispatch(purchaseSummaryAction())
              }
            }}>
            Comprar
          </Button>
        </S.TicketBox>
      )}
    </S.Container>
  )
}
