import * as S from './ParticipantData.styles'
import { Dispatch, SetStateAction, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../../../../redux/rootReducer'
import { Button, Heading, Input, Paragraph } from 'applaus-ui-kit'
import { formatPrice } from '../../../../../../../utils/formatPrice'
import produce from 'immer'
import { setModalState } from '../../../../../../../redux/Modal/Actions/actionsModal'
import {
  addTicketInfo,
  prevStepAction
} from '../../../../../../../redux/Event/actions'
import BackButton from '../../../../../../../components/backButton/BackButton'

type Props = {
  setSuccess: Dispatch<SetStateAction<boolean>>
}

export const ParticipantData = ({ setSuccess }: Props) => {
  const dispatch = useDispatch()
  const { selectedEvent, tickets } = useSelector(
    (state: ReduxState) => state.EventReducer
  )
  const initialState = tickets.map(() =>
    selectedEvent!.ticketInfo?.map((ticketInfo) => ({
      name: ticketInfo.value,
      value: '',
      error: ticketInfo.isRequired
    }))
  )

  const [ticketInfos, setTicketInfos] = useState(initialState)
  const [submit, setSubmit] = useState(false)

  const handleAddTicketInfo = (value: string, index: number, name: string) => {
    setTicketInfos(
      produce((draft) => {
        draft[index]![
          draft[index]!.findIndex((info) => info.name === name)
        ].value = value
        draft[index]![
          draft[index]!.findIndex((info) => info.name === name)
        ].error = !(value.length > 0)
      })
    )
  }

  const handleClick = () => {
    setSubmit(true)
    const hasError = ticketInfos
      .flatMap((ticketInfo) => ticketInfo?.flatMap((info) => info.error))
      .some((elem) => elem === true)

    if (hasError) {
      dispatch(
        setModalState({
          variant: 'error',
          message: 'Campos obrigatórios não preenchidos',
          open: true
        })
      )
    } else {
      const formatedTicketInfo = ticketInfos.map((ticketInfo) =>
        ticketInfo!.map((info) => ({
          name: info.name,
          value: info.value
        }))
      )

      dispatch(addTicketInfo(formatedTicketInfo))
      setSuccess(true)
    }
  }

  return (
    <S.Container>
      <Paragraph variant="large" type="semiBold" className="title">
        Dados dos participantes
      </Paragraph>
      <S.Wrapper>
        {tickets?.map(({ id, totalPrice, labels, name, category }, index) => (
          <>
            <S.TicketCard key={id + index}>
              <div>
                {labels ? (
                  <>
                    <Paragraph
                      style={{ textTransform: 'uppercase' }}
                      variant="small"
                      type="semiBold">
                      Seção: {labels.section} Linha: {labels.parent}
                    </Paragraph>
                    <Paragraph
                      style={{ textTransform: 'uppercase' }}
                      variant="small"
                      type="semiBold">
                      Assento: {labels.own}
                    </Paragraph>
                  </>
                ) : (
                  <>
                    <Paragraph
                      style={{ textTransform: 'uppercase' }}
                      variant="small"
                      type="semiBold">
                      {name}
                    </Paragraph>
                    <Paragraph
                      style={{ textTransform: 'uppercase' }}
                      variant="small"
                      type="semiBold">
                      {category}
                    </Paragraph>
                  </>
                )}
                <Paragraph
                  style={{ textTransform: 'uppercase' }}
                  variant="small">
                  {formatPrice(totalPrice)}
                </Paragraph>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                <Paragraph
                  style={{ textTransform: 'uppercase' }}
                  variant="small">
                  ingresso
                </Paragraph>
                <Heading variant="h5" className="price">
                  {formatPrice(totalPrice)}
                </Heading>
              </div>
            </S.TicketCard>
            {selectedEvent!.ticketInfo?.map((ticketInfo, indexTicket) => (
              <Input
                autoFocus={index === 0 && indexTicket === 0}
                key={index + indexTicket}
                label={`${ticketInfo.isRequired ? '*' : ''}${ticketInfo.value}`}
                onChange={(e) =>
                  handleAddTicketInfo(e.target.value, index, ticketInfo.value)
                }
                error={
                  ticketInfos[index]![
                    ticketInfos[index]!.findIndex(
                      (ticket) => ticket.name === ticketInfo.value
                    )
                  ].error && submit
                }
                texterror={`${ticketInfo.value} é obrigatório`}
                fullWidth
              />
            ))}
          </>
        ))}
      </S.Wrapper>
      <S.Actions>
        <BackButton
          title="Voltar"
          onClick={() => dispatch(prevStepAction('showEvent'))}
          className="backbutton"
        />
        <Button
          variant={'contained'}
          color={'primary'}
          size={'medium'}
          fullWidth={false}
          onClick={handleClick}>
          Comprar
        </Button>
      </S.Actions>
    </S.Container>
  )
}
