import { CustomerTicket } from 'redux/Event/types'
import { Coupon } from 'redux/Checkout/types'

export const calculateTotalPrice = (
  customerTickets: CustomerTicket[],
  coupon?: Coupon
): number => {
  const ticketsSum = customerTickets.reduce(
    (acc, ticket) => acc + ticket.totalPrice,
    0
  )
  switch (coupon?.valueType) {
    case 'CENTS':
      return ticketsSum - coupon.value / 100 < 0
        ? 0
        : ticketsSum - coupon.value / 100
    case 'PERCENT':
      return ticketsSum - ticketsSum * (coupon.value / 100)
    default:
      return ticketsSum
  }
}
