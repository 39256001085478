import styled, { css } from 'styled-components'

import { ReactComponent as AlertFile } from '../../../../assets/icons/alert.svg'

export const AlertIcon = styled(AlertFile)``

export const Container = styled.div`
  ${({ theme }) => css`
    > h4 {
      font-size: ${theme.font.size.md};
      line-height: ${theme.font.size.xl};
      background: #540074;
      text-align: center;
      color: white;
      padding: ${theme.spacing.stack.small} 0;
      border-radius: ${theme.border.radius.medium};
      margin-bottom: ${theme.spacing.stack.xxxs};
    }
    .backbuttonWrapper {
      margin: 0 0 ${theme.spacing.stack.xxs};
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        > p {
          font-size: ${theme.font.size.sm};
        }
        > button {
          margin: 0 ${theme.spacing.stack.small} 0 0;
        }
      }
    }
    @media (max-width: 767px) {
      > h4 {
        background: #540074;
        color: white;
        text-align: center;
        padding: ${theme.spacing.stack.small} ${theme.spacing.inline.xxs};
        border-radius: ${theme.border.radius.medium};
        margin-bottom: ${theme.spacing.inline.xxxs};
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }
  `}
`

export const TicketSection = styled.section`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: ${theme.spacing.stack.xxs};
    grid-column-gap: ${theme.spacing.stack.xxxs};
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
  `}
`

export const TicketCard = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #f4f4f4;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.inline.xxs};
    border-radius: ${theme.border.radius.medium};
    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.xxxs} ${theme.spacing.inline.small};
    }
  `}
`
export const SelectTickets = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > p {
      margin: 0 ${theme.spacing.inline.xxs};

      @media (max-width: 767px) {
        margin: 0 ${theme.spacing.inline.small};
      }
    }

    .remove-ticket-btn {
      outline: 0;
      font-weight: ${theme.font.weight.semiBold};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.font.size.md};
      border-radius: 100%;
      border: 1px solid #8601c9;
      width: 32px;
      height: 32px;

      :disabled {
        border: 1px solid gray;
      }
    }
  `}
`
export const Tooltip = styled.div<{ visible: boolean }>`
  ${({ theme, visible }) => css`
    position: absolute;
    background: ${theme.color.base.nth4};
    z-index: 10;
    padding: ${theme.spacing.stack.xxxs};
    opacity: ${visible ? 1 : 0};
    pointer-events: ${visible ? 'all' : 'none'};
    transition: 0.3s ease;
    top: -50%;
    right: 0;
    transform: translateY('-100%');
    border-radius: ${theme.border.radius.medium};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    > p {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0em;
      margin-left: ${theme.spacing.stack.small};
    }
    @media (max-width: 767px) {
      top: 90%;
      width: 80%;
      padding: ${theme.spacing.stack.small};
      > p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
      }
    }
  `}
`

export const AddButton = styled.button<{ isDisabled: boolean }>`
  ${({ theme, isDisabled }) => css`
    outline: 0;
    font-weight: ${theme.font.weight.semiBold};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.font.size.md};
    border-radius: 100%;
    border: 1px solid ${isDisabled ? 'gray' : '#8601c9'};
    width: 32px;
    height: 32px;
  `}
`
