export const timeConvert = (param: number) => {
  const h = Math.floor(param / 3600)
  const m = Math.floor((param % 3600) / 60)
  const s = Math.floor(param % 60)

  const hDisplay = h > 0 ? h + (h === 1 ? ' hora, ' : ' horas, ') : ''
  const mDisplay = m > 0 ? m + (m === 1 ? ' minuto ' : ' minutos ') : ''
  const sDisplay = s > 0 ? s + (s === 1 ? ' segundo' : ' segundos') : ''
  return hDisplay + mDisplay + sDisplay
}

export const toSeconds = (param: string): number => {
  const time = param.split(':')

  const seconds = +time[0] * 60 * 60 + +time[1] * 60 + +time[2]

  return seconds
}

export const toTimeString = (param: number): string => {
  const date = new Date(0)
  date.setSeconds(param)

  return date.toISOString().substring(11, 19)
}
