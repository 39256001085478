import styled, { css } from 'styled-components'

export const Container = styled.section`
  ${({ theme }) => css`
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    min-height: 80vh;
    .total {
      background: #f4f4f4;
      text-align: end;
      padding: 12px ${theme.spacing.stack.xxs};
      border-radius: ${theme.border.radius.medium};
      margin-top: auto;
    }

    .title {
      background: #540074;
      color: white;
      text-align: center;
      padding: ${theme.spacing.stack.small} ${theme.spacing.inline.xxs};
      border-radius: ${theme.border.radius.medium};
      margin-bottom: ${theme.spacing.inline.xxxs};
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      width: 100%;
      box-shadow: none;
      padding-bottom: ${theme.spacing.stack.lg};
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: ${theme.spacing.stack.xxxs};
  `}
`

export const TicketCard = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    display: flex;
    padding: ${theme.spacing.stack.small};
    border-radius: ${theme.border.radius.medium};
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing.stack.xxxs};
    .price {
      color: #8601c9;
    }
    input {
      :nth-child(1) {
        :focus {
          background: red;
        }
      }
    }
  `}
`

export const Actions = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${theme.spacing.stack.xs};
    > div:first-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      > p {
        font-size: ${theme.font.size.sm};
      }
    }
    .backbutton {
      margin: 0 ${theme.spacing.stack.small} 0 0;
    }
    > button {
      background: ${theme.color.alert.success.nth1};
    }
    @media (min-width: 768px) {
      margin-top: ${theme.spacing.stack.xxxs};
    }
  `}
`
