import { Button } from 'applaus-ui-kit'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.xxs} 0;
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      padding-bottom: ${theme.spacing.stack.xxs};
    }
    @media (max-width: 768px) {
      padding-bottom: ${theme.spacing.stack.xxxs};
    }

    .row {
      display: flex;
      justify-content: center;
    }

    .column {
      display: flex;
      width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      height: 100vh;
      .pucharseSummary {
        display: none;
        margin-bottom: ${theme.spacing.stack.xxs};
      }
    }

    @media (min-width: 1171px) {
      .pucharseSummary {
        margin-bottom: ${theme.spacing.stack.md};
      }
    }

    .gridContainer {
      width: 100%;
    }

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;
      @media (min-width: 1170px) {
        height: 70%;
      }
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;

    .payment-buttons {
      > div {
        margin-bottom: ${theme.spacing.stack.xxxs};
      }
    }
    @media (max-width: 767px) {
      margin-top: ${theme.spacing.stack.xxxs};
    }

    .backbutton {
      margin: ${theme.spacing.stack.xxs} 0;
    }
  `}
`

export const ActionsBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacing.stack.sm};
  `}
`

export const StyledButton = styled(Button)`
  background: #2ecc71;
  margin-left: auto;
`
