import { action } from 'typesafe-actions'
import {
  Banner,
  Category,
  Course,
  Events,
  ContentActions,
  Banners
} from './types'

export const listEventsRequestAction = (location?: string) =>
  action(ContentActions.listAllEventsRequest, location)

export const listEventsByCategoryRequestAction = (category: string) =>
  action(ContentActions.filterEventsByCategoryRequest, category)

export const listEventsSuccessAction = (data: {
  events?: Events
  banners?: Banner[]
  categories?: Category[]
  courses?: Course[]
}) => action(ContentActions.listAllEventSuccess, data)

export const listEventsCashBackRequest = (cashBack?: boolean) =>
  action(ContentActions.filterEventsWithCashBackRequest, cashBack)

export const listEventsCashBackSuccess = (events?: Events) =>
  action(ContentActions.filterEventsWithCashBackRequest, events)

export const listEventsFailureAction = () =>
  action(ContentActions.listAllEventFailure)

export const listCoursesRequestAction = () =>
  action(ContentActions.listCoursesRequest)

export const listCoursesSuccessAction = (data: Course[]) =>
  action(ContentActions.listCourseSuccess, data)

export const listBannersSuccessAction = (data: Banners) =>
  action(ContentActions.listAllBannersSuccess, data)

export const listBannersFailureAction = (data: Banners) =>
  action(ContentActions.listAllBannersFailure, data)

export const listBannersRequestAction = () =>
  action(ContentActions.listAllBannersRequest)
