import styled, { css } from 'styled-components'
import { ReactComponent as BackFile } from '../../../../../assets/icons/filledChevronLeft.svg'

import { ReactComponent as LocationFile } from '../../../../../assets/icons/location.svg'
import { ReactComponent as AgeFreeFile } from '../../../../../assets/icons/ageGroup/ageFree.svg'
import { ReactComponent as Age10File } from '../../../../../assets/icons/ageGroup/age10.svg'
import { ReactComponent as Age12File } from '../../../../../assets/icons/ageGroup/age12.svg'
import { ReactComponent as Age14File } from '../../../../../assets/icons/ageGroup/age14.svg'
import { ReactComponent as Age16File } from '../../../../../assets/icons/ageGroup/age16.svg'
import { ReactComponent as Age18File } from '../../../../../assets/icons/ageGroup/age18.svg'

import { ReactComponent as FacebookFile } from 'assets/icons/facebookPurple.svg'

import { ReactComponent as InstagramFile } from 'assets/icons/instagramPurple.svg'
import { ReactComponent as YoutubeFile } from 'assets/icons/youtubePurple.svg'
import { ReactComponent as TiktokFile } from 'assets/icons/tiktokPurple.svg'

import { ReactComponent as ShareFile } from 'assets/icons/shareIcon.svg'

export const ShareIcon = styled(ShareFile)`
  ${({ theme }) => css`
    height: 16px;
    width: 16px;
  `}
`

export const FacebookIcon = styled(FacebookFile)`
  ${({ theme }) => css`
    cursor: pointer;
    @media (max-width: 767px) {
      min-height: 16px;
      min-width: 16px;
    }
  `}
`
export const InstagramIcon = styled(InstagramFile)`
  ${({ theme }) => css`
    cursor: pointer;
    @media (max-width: 767px) {
      min-height: 16px;
      min-width: 16px;
    }
  `}
`
export const YoutubeIcon = styled(YoutubeFile)`
  ${({ theme }) => css`
    cursor: pointer;
    @media (max-width: 767px) {
      min-height: 16px;
      min-width: 20px;
    }
  `}
`
export const TiktokIcon = styled(TiktokFile)`
  ${({ theme }) => css`
    cursor: pointer;
    @media (max-width: 767px) {
      height: 24px;
      width: 24px;
    }
  `}
`

export const AgeFreeIcon = styled(AgeFreeFile)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};

    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age10Icon = styled(Age10File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};

    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age12Icon = styled(Age12File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};

    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age14Icon = styled(Age14File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};

    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age16Icon = styled(Age16File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};

    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age18Icon = styled(Age18File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};

    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const AgeBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    margin-bottom: ${theme.spacing.stack.xxs};
  `}
`

export const FilledBackIcon = styled(BackFile)`
  ${({ theme }) => css`
    position: absolute;
    fill: black;
    height: 32px;
    width: 32px;
    background: white;
    padding: ${theme.spacing.stack.small};
    border-radius: 100%;
    top: 24px;
    left: 24px;
  `}
`

export const LocationIcon = styled(LocationFile)`
  ${({ theme }) => css`
    fill: white;
    top: 24px;
    left: 24px;
    margin-right: ${theme.spacing.inline.xxxs};
    min-width: 24px;
    min-height: 24px;
  `}
`

export const Container = styled.div`
  position: relative;
`

export const EventDetails = styled.div`
  ${({ theme }) => css`
    .header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > h5 {
        color: #6c01a3;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        text-align: left;
      }
      .buttons-wrapper {
        display: flex;
        align-items: center;
        gap: ${theme.spacing.stack.xxxs};
      }
    }

    margin-bottom: ${theme.spacing.stack.small};
  `}
`

export const ShareButton = styled.button`
  ${({ theme }) => css`
    max-width: max-content;
    border: 1px solid ${theme.color.brand.primary.nth1};
    outline: 0;
    background: transparent;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: ${theme.color.brand.primary.nth1};
    border-radius: 24px;
    margin: 16px 0;
  `}
`

export const EventDataBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: max-content;
`

export const LocationInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`

export const Description = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.xxxs};
    word-break: break-all;
    > * {
      font-size: ${theme.spacing.stack.xxxs};
    }
  `}
`

export const EventData = styled.div`
  ${({ theme }) => css`
    background: black;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: ${theme.spacing.stack.xs} ${theme.spacing.stack.xxs};
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 49.29%);
    .textInfo {
      word-break: break-all;
      color: white;
    }
  `}
`

export const CoverUrl = styled.img`
  width: 100%;
  min-height: 488px;
`

export const EventInfo = styled.section`
  ${({ theme }) => css`
    position: absolute;
    top: 95%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.xxs};
    border-top-left-radius: ${theme.border.radius.big};
    border-top-right-radius: ${theme.border.radius.big};
    background: white;
    .heading {
      color: #6c01a3;
    }
    > p {
      margin-bottom: ${theme.spacing.stack.xxxs};
    }
  `}
`

export const TicketBox = styled.section`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    filter: drop-shadow(0px -1px 2px rgba(0, 0, 0, 0.2));
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    align-items: center;
    > button {
      font-size: 9px;
      padding: 10px 26px;
      border-radius: 12px;
    }
    .button {
      background: ${theme.color.alert.success.nth1};
    }
    .sold-off-text {
      color: ${theme.color.alert.error.nth1};
    }
    .waiting-open-text {
      color: ${theme.color.base.nth2};
    }
  `}
`

export const PricingInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > p:nth-child(1n + 2) {
      color: rebeccapurple;
    }

    .textCashBack {
      margin-top: ${theme.spacing.stack.small};
      font-weight: ${theme.font.weight.semiBold};
      color: ${theme.color.brand.primary.nth1};
    }
  `}
`
