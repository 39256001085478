import * as S from './AddressDesktop.styles'
import {
  Container as GridContainer,
  Column,
  Row
} from '../../../../../components/grid/GridSystem'
import { AppBar } from '../../../../../components/appBar/AppBar'
import { PurchaseSummary } from '../../components/purchaseSummary/PurchaseSummary'
import { useFormik } from 'formik'

import { useDispatch, useSelector } from 'react-redux'
import {
  clearCheckoutState,
  setCheckoutState
} from '../../../../../redux/Checkout/actions'
import { Hidden, Input } from 'applaus-ui-kit'
import { DescriptionCard } from '../../../../../components/descriptionCard/DescriptionCard'
import { ButtonBack } from '../../../../../components/buttonBack/buttonBack'
import { ReduxState } from '../../../../../redux/rootReducer'
import { ChangeEvent, useEffect, useState } from 'react'
import history from '../../../../../routes/services/history'
import moment from 'moment'

import { editProfileService } from '../../../../../api/services/profile/editProfileService'
import { editUserRequestAction } from '../../../../../redux/User/actions'
import { validationSchema } from '../validationSchema'
import {
  getAddressService,
  Response
} from '../../../../../api/services/cep/getAddressService'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import jsonStates from '../../../../../utils/estados-cidades.json'
import ReactSelect from 'react-select'

export const AddressDesktop = () => {
  const dispatch = useDispatch()
  const { eventData, ticketState } = useSelector(
    (state: ReduxState) => state.CheckoutReducer.data
  )
  const UserState = useSelector((state: ReduxState) => state.UserReducer)
  const { isDesktop } = useWindowSize()

  const [states] = useState<{ value: string; label: string }[]>(
    jsonStates.estados.map((state) => ({
      value: state.sigla,
      label: state.nome
    }))
  )
  const [cities, setCities] = useState<{ value: string; label: string }[]>([])

  const [selectedState, setSelectedState] = useState('')

  const [stateError, setStateError] = useState('')

  useEffect(() => {
    if (selectedState !== '') {
      setCities(
        jsonStates.estados
          .filter((state) => state.sigla === selectedState)[0]
          .cidades.map((city) => ({
            value: city,
            label: city
          }))
      )
    }
  }, [selectedState])

  const formik = useFormik({
    initialValues: {
      zipCode: UserState.address?.zipCode?.replace(/[-]/g, '') ?? '',
      address: UserState.address?.street ?? '',
      number:
        UserState.address?.number !== null
          ? String(UserState.address?.number)
          : '',
      complement: UserState.address?.complement ?? '',
      state: UserState.address?.state ?? '',
      city: UserState.address?.city ?? ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await editProfileService({
          address: {
            city: formik.values.city,
            complement:
              formik.values.complement === ''
                ? undefined
                : formik.values.complement,
            country: 'BR',
            number: Number(formik.values.number),
            state: formik.values.state,
            street: formik.values.address,
            zipCode: formik.values.zipCode
          }
        })

        dispatch(
          setCheckoutState({
            data: {
              eventData: eventData,
              ticketState: ticketState,
              step: 'defaultCheckout',
              addressCheckout: {
                city: formik.values.city,
                complement:
                  formik.values.complement === ''
                    ? undefined
                    : formik.values.complement,
                country: 'BR',
                number: Number(formik.values.number),
                state: formik.values.state,
                street: formik.values.address,
                zipCode: formik.values.zipCode
              }
            }
          })
        )

        dispatch(
          editUserRequestAction({
            address: {
              city: formik.values.city,
              complement:
                formik.values.complement === ''
                  ? undefined
                  : formik.values.complement,
              country: 'BR',
              number: formik.values.number,
              state: formik.values.state,
              street: formik.values.address,
              zipCode: formik.values.zipCode
            }
          })
        )
      } catch (err) {}
    }
  })

  const handleCep = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.replace(/[^0-9]/g, '').trim().length === 8) {
      const data: Response = await getAddressService(e.target.value)
      formik.setFieldValue('address', data.logradouro)
    }
  }

  useEffect(() => {
    if (formik.values.state !== '') {
      const stateIndex = jsonStates.estados.findIndex(
        (state) => state.sigla === formik.values.state
      )
      if (stateIndex !== -1) {
        setCities(
          jsonStates.estados[stateIndex].cidades.map((city) => ({
            value: city,
            label: city
          }))
        )
      }
    }
  }, [formik.values.state])

  useEffect(() => {
    if (formik.values.state !== '') {
      setStateError('')
    } else setStateError(formik.errors.state ?? '')
  }, [formik.values.state, formik.touched.state, formik.errors.state])

  return (
    <S.Container>
      <Hidden mobile>
        <AppBar />
      </Hidden>

      <GridContainer>
        <Hidden tablet desktop>
          <ButtonBack
            title="Voltar"
            click={() => {
              dispatch(clearCheckoutState())
              history.push(`/event/${eventData?.id}`)
            }}
          />
        </Hidden>
        <Row>
          <Hidden mobile tablet>
            <Column spacing={isDesktop ? 3 : 0} desktop={6}>
              <PurchaseSummary
                eventAddress={eventData?.address.street ?? ''}
                eventDate={
                  moment(eventData?.eventItems[0].dates[0])
                    .locale('pt-br')
                    .format('ll [ás] HH[h]mm') ?? ''
                }
                tickets={ticketState ?? []}
                titleTicket={eventData?.name ?? ''}
              />
            </Column>
          </Hidden>
          <Column spacing={isDesktop ? 3 : 0} mobile={4} desktop={6} tablet={8}>
            <S.Wrapper>
              <DescriptionCard title="Meu endereço" />

              <S.Form onSubmit={formik.handleSubmit}>
                <Input
                  name="zipCode"
                  label="*CEP"
                  placeholder="Digite o CEP"
                  texterror={formik.errors.zipCode}
                  error={
                    Boolean(formik.errors.zipCode) && formik.touched.zipCode
                  }
                  onChange={(e) => {
                    handleCep(e)
                    formik.handleChange(e)
                  }}
                  value={formik.values.zipCode}
                  fullWidth
                />

                <Input
                  name="address"
                  label="*Endereço"
                  placeholder="Digite o endereço"
                  texterror={formik.errors.address}
                  error={
                    Boolean(formik.errors.address) && formik.touched.address
                  }
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  fullWidth
                />

                <Input
                  name="number"
                  label="*Número"
                  placeholder="Digite o número"
                  texterror={formik.errors.number}
                  error={Boolean(formik.errors.number) && formik.touched.number}
                  onChange={formik.handleChange}
                  value={formik.values.number}
                  fullWidth
                />
                <div style={{ marginBottom: '16px' }}>
                  <S.Label>*Estado</S.Label>
                  <ReactSelect
                    className="inputHeight"
                    options={states}
                    onChange={(e) => {
                      setSelectedState(e!.value)
                      formik.setFieldValue('state', e!.value)

                      formik.setFieldValue('city', '')
                    }}
                    value={states.filter(
                      (obj) => formik.values.state === obj.value
                    )}
                    noOptionsMessage={() => 'Estado não encontrado'}
                    placeholder="Estado"
                    styles={S.customStyles}
                  />
                  <S.Error>
                    {formik.touched.state && stateError !== ''
                      ? stateError
                      : null}
                  </S.Error>
                </div>
                <div style={{ marginBottom: '16px' }}>
                  <S.Label>*Cidade</S.Label>
                  <ReactSelect
                    className="inputHeight"
                    options={cities}
                    onChange={(e) => formik.setFieldValue('city', e!.value)}
                    value={cities.filter(
                      (obj) => formik.values.city === obj.value
                    )}
                    noOptionsMessage={() =>
                      selectedState
                        ? 'Cidade não encontrada'
                        : 'Selecione o estado'
                    }
                    placeholder="Cidade"
                    styles={S.customStyles}
                  />
                  <S.Error>
                    {formik.touched.city && Boolean(formik.errors.city)
                      ? formik.errors.city
                      : null}
                  </S.Error>
                </div>
                <Input
                  name="complement"
                  label="Complemento"
                  placeholder="Digite o complemento"
                  texterror={formik.errors.complement}
                  error={
                    Boolean(formik.errors.complement) &&
                    formik.touched.complement
                  }
                  onChange={formik.handleChange}
                  value={formik.values.complement}
                  fullWidth
                />

                <S.ButtonBox>
                  <Hidden mobile>
                    <ButtonBack
                      title="Voltar"
                      click={() => {
                        dispatch(clearCheckoutState())
                        history.push(`/event/${eventData?.id}`)
                      }}
                    />
                  </Hidden>

                  <div>
                    <S.ButtonStyled
                      fullWidth
                      variant="contained"
                      size="large"
                      color="primary">
                      Continuar
                    </S.ButtonStyled>
                  </div>
                </S.ButtonBox>
              </S.Form>
            </S.Wrapper>
          </Column>
        </Row>
      </GridContainer>
    </S.Container>
  )
}
