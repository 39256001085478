import produce from 'immer'
import { Reducer } from 'redux'
import { User, UserActions } from './types'

const INITIAL_STATE: User = {
  id: '',
  userId: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  createdAt: '',
  updatedAt: '',
  document: '',
  cashBackBalanceCents: 0,
  isAuthenticated: false,
  loading: false
}

export const UserReducer: Reducer<User> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActions.setUser:
      return produce(state, (draft) => {
        draft.loading = false
        draft.isAuthenticated = true
        draft.id = action.payload.id
        draft.userId = action.payload.userId
        draft.firstName = action.payload.firstName
        draft.document = action.payload.document
        draft.lastName = action.payload.lastName
        draft.email = action.payload.email
        draft.phone = action.payload.phone
        draft.cashBackBalanceCents = action.payload.cashBackBalanceCents
        if (action.payload.address) {
          draft.address = {
            number: action.payload.address.number,
            state: action.payload.address.state,
            country: action.payload.address.country,
            city: action.payload.address.city,
            street: action.payload.address.street,
            complement: action.payload.address.complement,
            zipCode: action.payload.address.zipCode
          }
        }

        if (action.payload.accessToken && action.payload.refreshToken) {
          draft.accessToken = action.payload.accessToken
          draft.refreshToken = action.payload.refreshToken
        }
      })
    case UserActions.editUserSuccess:
      return produce(state, (draft) => {
        draft.firstName = action.payload.firstName
        draft.lastName = action.payload.lastName
        draft.email = action.payload.email
        draft.phone = action.payload.phone
        draft.address = action.payload.address
      })
    case UserActions.refreshToken:
      return produce(state, (draft) => {
        draft.accessToken = action.payload.accessToken
        draft.refreshToken = action.payload.refreshToken
      })
    case UserActions.addLocation:
      return produce(state, (draft) => {
        draft.location = action.payload
      })
    case UserActions.authenticationRequest:
      return produce(state, (draft) => {
        draft.loading = true
      })
    case UserActions.authenticationFailed:
      return produce(state, (draft) => {
        draft.isAuthenticated = false
      })
    case UserActions.logout:
      return INITIAL_STATE
    default:
      return state
  }
}
