import { Button, Paragraph, Subtitle } from 'applaus-ui-kit'
import * as S from './Location.styles'
import location from './location.json'
import { useEffect, useRef, useState } from 'react'
import Select, { components, GroupBase, SingleValueProps } from 'react-select'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { addLocationAction } from '../../redux/User/actions'
import { locationSchema } from './validationSchema'
import { listEventsRequestAction } from '../../redux/Content/actions'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

type OptionType = {
  value: string
  label: string
}

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    // eslint-disable-next-line no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line no-unused-vars
    Group extends GroupBase<Option>
  > {}
}

function SingleValue({
  children,
  ...props
}: SingleValueProps<OptionType, false>) {
  return <components.SingleValue {...props}>{children}</components.SingleValue>
}

export const LocationModal = ({ isOpen, handleClose }: Props) => {
  const dispatch = useDispatch()

  const [cities, setCities] = useState<{ value: string; label: string }[]>([])

  const [state] = useState(
    location.estados.map((state) => ({ value: state.sigla, label: state.nome }))
  )

  const ref = useRef<HTMLFormElement>(null)

  const formik = useFormik({
    initialValues: {
      state: '',
      city: ''
    },
    validationSchema: locationSchema,
    onSubmit: (values) => {
      dispatch(addLocationAction(values.city))
      dispatch(listEventsRequestAction(values.city))
      handleClose()
    }
  })

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }
    if (formik.values.state !== '') {
      const stateIndex = location.estados.findIndex(
        (state) => state.sigla === formik.values.state
      )
      if (stateIndex !== -1) {
        setCities(
          location.estados[stateIndex].cidades.map((city) => ({
            value: city,
            label: city
          }))
        )
      }
    }
    return function cleanUp() {
      document.body.style.overflow = 'auto'
    }
  }, [formik.values.state])

  useEffect(() => {
    if (ref !== null) {
      const listener = (event: any) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handleClose()
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }
  }, [ref])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <S.Container isOpen={isOpen}>
      <S.Content ref={ref} onSubmit={formik.handleSubmit}>
        <Subtitle variant="regular">Qual a sua localização?</Subtitle>
        <Paragraph variant="regular">
          Assim poderemos fornecer uma experiência
          <br /> personalizada para você!
        </Paragraph>
        <S.Label htmlFor="state">Estado</S.Label>
        <Select<OptionType>
          id="state"
          value={
            state.find((option) => option.value === formik.values.state) ?? {
              value: '',
              label: ''
            }
          }
          onChange={(selectValue) => {
            if (selectValue && selectValue.value) {
              formik.setFieldValue('state', selectValue.value)
            }
          }}
          placeholder="Estado"
          styles={S.customStyles}
          options={state}
          components={{ IndicatorSeparator: () => null, SingleValue }}
          noOptionsMessage={() => 'Estado não encontrado'}
        />
        <S.Label>Cidade</S.Label>
        <Select<OptionType>
          value={
            cities.find((option) => option.value === formik.values.city) ?? {
              value: '',
              label: ''
            }
          }
          onChange={(selectValue) => {
            if (selectValue && selectValue.value) {
              formik.setFieldValue('city', selectValue.value)
            }
          }}
          placeholder="Cidade"
          styles={S.customStyles}
          options={cities}
          components={{ IndicatorSeparator: () => null, SingleValue }}
          noOptionsMessage={() => 'Cidade não encontrada'}
        />
        <Button
          variant="contained"
          size="medium"
          color="primary"
          fullWidth={false}
          type="submit">
          Continuar
        </Button>
      </S.Content>
    </S.Container>
  )
}
