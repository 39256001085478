import api from 'api/config/api'
import { AxiosResponse } from 'axios'
import { Coupon } from 'redux/Checkout/types'

type CouponResponse = {
  coupon: {
    amountOfUse: number
    createdAt: string
    description: string
    discountSource: string
    eventId: string
    id: string
    name: string
    quantity: number | null
    startValidateDate: string | null
    updatedAt: string | null
    value: number
    valueType: 'PERCENT' | 'CENTS'
  }
  couponId: string
  createdAt: string
  customerId: string
  id: string
  status: string
  updatedAt: null | string
  validUntil: string
}

export const redeemCouponService = async (
  couponCode: string
): Promise<Coupon> => {
  const { data }: AxiosResponse<CouponResponse> = await api.post(
    `api/coupons/${couponCode}/redeem`
  )

  return {
    customerCouponId: data.id,
    value: data.coupon.value,
    valueType: data.coupon.valueType,
    name: data.coupon.name
  }
}
