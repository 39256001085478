import { Button, Paragraph } from 'applaus-ui-kit'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import * as S from './PriceFilter.styles'
import { MultiRangeSlider } from '../../../../../components/range-slider/RangeSlider'
type Props = {
  priceState: {
    isOpen?: boolean
    cashBack?: string
    active?: string
    price: {
      minValue: number | undefined
      maxValue: number | undefined
    }
  }
  setPrice: Dispatch<
    SetStateAction<{
      isOpen?: boolean
      active?: string
      cashBack?: string
      price: {
        minValue: number | undefined
        maxValue: number | undefined
      }
    }>
  >
}

export const PriceFilter = ({ priceState, setPrice }: Props) => {
  const [price, setPriceState] = useState<{
    cashBack?: string
    active?: string
    price: {
      minValue: number
      maxValue: number
    }
  }>({
    price: { minValue: 0, maxValue: 1000 }
  })
  const priceRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (priceRef !== null) {
      const listener = (event: any) => {
        if (!priceRef.current || priceRef.current.contains(event.target)) {
          return
        }
        setPrice((prev) => ({ ...prev, isOpen: false }))
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }
  }, [priceRef, priceState])

  return (
    <S.Price ref={priceRef}>
      <S.Button
        onClick={() => setPrice((prev) => ({ ...prev, isOpen: !prev.isOpen }))}>
        <Paragraph variant="small" type="semiBold">
          Preço
        </Paragraph>
        <S.ChevronDownIcon />
      </S.Button>
      <S.PriceDropdown isOpen={priceState.isOpen ?? false}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '8px'
          }}>
          <S.Options
            onClick={() =>
              setPriceState({
                cashBack: undefined,
                active: 'every',
                price: {
                  minValue: 0,
                  maxValue: 1000
                }
              })
            }
            isActive={price.active === 'every'}>
            <Paragraph variant="small" type="semiBold">
              Todos
            </Paragraph>
          </S.Options>
          <S.Options
            onClick={() =>
              setPriceState({
                cashBack: undefined,
                active: 'free',
                price: {
                  minValue: 0,
                  maxValue: 0
                }
              })
            }
            isActive={price.active === 'free'}>
            <Paragraph variant="small" type="semiBold">
              Grátis
            </Paragraph>
          </S.Options>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '8px',
            marginBottom: '8px'
          }}>
          <S.Options
            onClick={() =>
              setPriceState((prev) => ({
                ...prev,
                active: 'cashback',
                cashBack: 'true'
              }))
            }
            isActive={price.active === 'cashback'}>
            <Paragraph variant="small" type="semiBold">
              CashBack
            </Paragraph>
          </S.Options>
          <S.Options
            onClick={() =>
              setPriceState({
                cashBack: undefined,
                active: 'paid',
                price: {
                  minValue: 1,
                  maxValue: 1000
                }
              })
            }
            isActive={price.active === 'paid'}>
            <Paragraph variant="small" type="semiBold">
              Pagos
            </Paragraph>
          </S.Options>
        </div>

        <MultiRangeSlider
          min={0}
          max={1000}
          minValue={price.price.minValue ?? 0}
          maxValue={price.price.maxValue ?? 0}
          setPrice={setPriceState}
        />
        <Button
          variant="contained"
          size="medium"
          color="primary"
          fullWidth
          onClick={() =>
            setPrice((prev) => ({
              cashBack: price.cashBack,
              isOpen: false,
              price: {
                maxValue: price.price.maxValue,
                minValue: price.price.minValue
              }
            }))
          }>
          Pesquisar
        </Button>
      </S.PriceDropdown>
    </S.Price>
  )
}
