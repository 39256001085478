import { Hidden } from 'applaus-ui-kit'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppBar } from '../../../components/appBar/AppBar'
import { ButtonBack } from '../../../components/buttonBack/buttonBack'
import { clearLoading, setLoading } from '../../../redux/Loading/actions'
import history from '../../../routes/services/history'
import { sleep } from '../../../utils/sleep'
import { Table } from './components/table/Table'
import * as S from './CashbackHistory.styles'
import {
  CashbackHistoryResponseFormated,
  getCashbackHistoryService
} from '../../../api/services/cashbackHistory/getCashbackHistory'

export const CashbackHistory = () => {
  const dispatch = useDispatch()
  const [cashbackHistory, setCashbackHistory] =
    useState<CashbackHistoryResponseFormated>()

  const fetchPurchaseHistory = async () => {
    dispatch(setLoading())
    const data = await getCashbackHistoryService()
    setCashbackHistory(data)
    await sleep(3000)
    dispatch(clearLoading())
  }

  useEffect(() => {
    fetchPurchaseHistory()
  }, [])

  return (
    <S.Container>
      <Hidden mobile desktop>
        <AppBar />
      </Hidden>

      <Hidden tablet desktop>
        <div className="buttonBack">
          <ButtonBack
            title="Histórico de cashback"
            click={() => history.push('/profileTab')}
          />
        </div>
      </Hidden>

      <S.Wrapper>
        <Hidden mobile>
          <S.Title>Histórico de Cashback</S.Title>
        </Hidden>
        <Table cashbackHistory={cashbackHistory} />
      </S.Wrapper>
    </S.Container>
  )
}
