import styled, { css } from 'styled-components'

import { ReactComponent as RemoveFile } from 'assets/icons/remove.svg'

export const RemoveIcon = styled(RemoveFile)`
  cursor: pointer;
`

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxs};
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    > div:first-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: ${theme.spacing.stack.xxs};
      > p {
        font-size: ${theme.font.size.sm};
      }
    }
    .backbutton {
      margin: 0 ${theme.spacing.stack.small} 0 0;
    }
  `}
`

export const TicketCard = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    display: flex;
    padding: ${theme.spacing.stack.small};
    border-radius: ${theme.border.radius.medium};
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing.stack.xxxs};
    .price {
      color: #8601c9;
    }
  `}
`

export const TicketBox = styled.section`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    filter: drop-shadow(0px -1px 2px rgba(0, 0, 0, 0.2));
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    align-items: center;
    > button {
      background: ${theme.color.alert.success.nth1};
    }
  `}
`

export const TotalPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h5:last-of-type {
    color: #8601c9;
  }
`

export const CouponCard = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.base.nth4};
    border-radius: 6px;
    display: flex;
    padding: 10px ${theme.spacing.stack.small};
    justify-content: space-between;
    align-items: center;
    margin: 0 0 ${theme.spacing.stack.xxxs};
    .coupon-title {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
    }
    .coupon-code {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
    .coupon-discount {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: ${theme.color.alert.success.nth1};
    }
    @media (max-width: 767px) {
      justify-content: unset;
      .coupon-discount {
        margin-left: auto;
      }
      > div {
        margin-left: 8px;
      }
    }
  `}
`
