import { all, call, delay, put, takeLatest } from 'redux-saga/effects'
import { EventItems, EventsAction } from './types'
import { PayloadAction } from 'typesafe-actions'
import {
  listOneEventService,
  EventResponse
} from '../../api/services/Events/listOneEventService'
import { loadEventSuccessAction } from './actions'
import { clearLoading, setLoading } from '../Loading/actions'
import history from '../../routes/services/history'
import moment from 'moment'

export function* listOneEvent(
  action: PayloadAction<EventsAction.loadEventRequest, string>
) {
  try {
    yield put(setLoading())
    const data: EventResponse = yield call(listOneEventService, action.payload)
    data.eventItems?.forEach((eventItem) => {
      eventItem.tickets.forEach((ticket) => {
        const { priceCents, discountCents } = ticket
        ticket.totalPrice = (priceCents - discountCents) / 100
        return ticket
      })
    })

    const sortEventItemByDate = (
      eventItemA: EventItems,
      eventItemB: EventItems
    ): 1 | -1 | 0 => {
      const smallestEventItemADate = moment.min(
        eventItemA.dates.map((date) => moment(date))
      )

      const smallestEventItemBDate = moment.min(
        eventItemB.dates.map((date) => moment(date))
      )

      if (smallestEventItemADate > smallestEventItemBDate) {
        return 1
      }

      if (smallestEventItemADate < smallestEventItemBDate) {
        return -1
      }

      return 0
    }

    const reduxData = {
      ...data,
      eventItems: data.eventItems
        .map((eventItem) => ({
          ...eventItem,
          isAvailable:
            moment().isAfter(eventItem.salesFrom) &&
            moment().isBefore(eventItem.finalSales)
        }))
        .sort(sortEventItemByDate)
    }

    yield put(
      loadEventSuccessAction({
        ...reduxData,
        salesFrom: moment
          .min(data.eventItems.map(({ salesFrom }) => moment(salesFrom)))
          .toISOString(),
        finalSales: moment
          .max(
            data.eventItems
              .filter(({ finalSales }) => finalSales !== null)
              .map(({ finalSales }) => moment(finalSales))
          )
          .toISOString()
      })
    )
    yield delay(1000)
    yield put(clearLoading())
  } catch (e) {
    history.push('/')
    yield put(clearLoading())
  }
}

export const EventSaga = all([
  takeLatest(EventsAction.loadEventRequest, listOneEvent)
])
