import { Paragraph } from 'applaus-ui-kit'
import { Dispatch, SetStateAction } from 'react'
import * as S from './OrderModal.styles'

type Props = {
  isOpen: boolean
  closeModal: () => void
  orderByState: {
    isOpen: boolean
    options: string[]
    actualOption: string
  }

  setOrderByState: Dispatch<
    SetStateAction<{
      isOpen: boolean
      options: string[]
      actualOption: string
    }>
  >
}

export const OrderModal = ({
  isOpen,
  closeModal,
  setOrderByState,
  orderByState
}: Props) => {
  return (
    <S.Container isOpen={isOpen}>
      <S.Content>
        <S.TitleModal>
          <Paragraph variant="large" type="bold">
            Ordernar por
          </Paragraph>
          <S.CloseIcon onClick={() => closeModal()} />
        </S.TitleModal>
        <S.Option>
          <S.ArrowsIcon active={orderByState.actualOption === 'Relevância'} />
          <div>
            <S.ParagraphStyled
              active={orderByState.actualOption === 'Relevância'}
              variant="regular"
              type="semiBold">
              Relevância
            </S.ParagraphStyled>
            <S.TextDescription>
              <Paragraph variant="small">
                Eventos mais relevantes para sua busca
              </Paragraph>
              {orderByState.actualOption === 'Relevância' ? (
                <S.CheckIcon />
              ) : null}
            </S.TextDescription>
          </div>
        </S.Option>

        <S.Option
          onClick={() => {
            setOrderByState((prev) => ({
              ...prev,
              actualOption: 'Data'
            }))
            closeModal()
          }}>
          <S.CalendarIcon active={orderByState.actualOption === 'Data'} />
          <div>
            <S.ParagraphStyled
              active={orderByState.actualOption === 'Data'}
              variant="regular"
              type="semiBold">
              Data
            </S.ParagraphStyled>
            <S.TextDescription>
              <Paragraph variant="small">
                Eventos mais próximos de acontecer
              </Paragraph>
              {orderByState.actualOption === 'Data' ? <S.CheckIcon /> : null}
            </S.TextDescription>
          </div>
        </S.Option>
        <S.Option
          onClick={() => {
            setOrderByState((prev) => ({
              ...prev,
              actualOption: 'Localização'
            }))
            closeModal()
          }}>
          <S.MapMarkerIcon
            active={orderByState.actualOption === 'Localização'}
          />
          <div>
            <S.ParagraphStyled
              active={orderByState.actualOption === 'Localização'}
              variant="regular"
              type="semiBold">
              Localização
            </S.ParagraphStyled>
            <S.TextDescription>
              <Paragraph variant="small">
                Eventos mais próximos de você
              </Paragraph>
              {orderByState.actualOption === 'Localização' ? (
                <S.CheckIcon />
              ) : null}
            </S.TextDescription>
          </div>
        </S.Option>
      </S.Content>
    </S.Container>
  )
}
