import * as S from './EventProfile.styles'
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/rootReducer'
import { Button, Heading, Paragraph } from 'applaus-ui-kit'
import moment from 'moment'
import { formatPrice } from '../../../../../utils/formatPrice'
import {
  clearEvent,
  purchaseSummaryAction,
  selectEvent
} from '../../../../../redux/Event/actions'
import { MappedEvent } from '../../MappedEvent/MappedEvent'
import { TabulatedEvent } from '../../TabulatedEvent/TabulatedEvent'
import { DateInfo } from '../Components/DateInfo/DateInfo'
import { GeneralLayout } from '../../../../../components/templates/GeneralLayout/GeneralLayout'
import { Column, Row } from '../../../../../components/grid/GridSystem'
import { LocationIframe } from '../../../LocationIframe/LocationIframe'
import { EventStatus } from './EventStatus/EventStatus'
import { clearCheckoutState } from '../../../../../redux/Checkout/actions'
import history from '../../../../../routes/services/history'
import { motion } from 'framer-motion'
import { useWindowSize } from '../../../../../hooks/useWindowSize'

type SocialLink = 'instagram' | 'facebook' | 'youtube' | 'tiktok'

export const DesktopEventProfile = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const ticketRef = useRef<HTMLDivElement>(null)
  const { isDesktop } = useWindowSize()

  const dispatch = useDispatch()
  const { EventReducer, UserReducer } = useSelector(
    (state: ReduxState) => state
  )

  const { email } = UserReducer

  const { eventData, tickets, selectedEvent } = EventReducer

  const renderTickets = useMemo(() => {
    if (eventData && selectedEvent?.isAvailable) {
      return selectedEvent ? (
        eventData?.seatMapId ? (
          <MappedEvent />
        ) : (
          <TabulatedEvent />
        )
      ) : undefined
    } else {
      return <></>
    }
  }, [selectedEvent, eventData])

  const handleBuyClick = () => {
    if (!email) {
      window.location.href =
        `${process.env.REACT_APP_AUTH_WEB_URL}/url?name=${window.location.href}` ??
        ''
    } else {
      dispatch(purchaseSummaryAction())
    }
  }

  useEffect(() => {
    if (containerRef !== null && containerRef.current) {
      containerRef.current.scrollIntoView({
        block: 'start'
      })
    }
  }, [containerRef])

  const ticketPrices = eventData?.eventItems.flatMap((eventItem) =>
    eventItem.tickets.map((ticket) => ticket.totalPrice)
  )

  const [isLinkShared, setIsLinkShared] = useState(false)

  const handleShareEvent = () => {
    setIsLinkShared(true)
    navigator.clipboard.writeText(window.location.href)
  }

  const handleSocialMediaClick = (link: SocialLink) => {
    switch (link) {
      case 'facebook':
        window.open(eventData?.socialLinks?.facebookLink ?? '', '_blank')
        break
      case 'instagram':
        window.open(eventData?.socialLinks?.instagramLink ?? '', '_blank')
        break
      case 'tiktok':
        window.open(eventData?.socialLinks?.tiktokLink ?? '', '_blank')
        break
      case 'youtube':
        window.open(eventData?.socialLinks?.youtubeLink ?? '', '_blank')
        break
    }
  }

  const renderSocialLinks = useMemo(() => {
    const socialLinks: ReactElement[] = []
    if (eventData && eventData.socialLinks) {
      if (eventData.socialLinks.tiktokLink !== '') {
        socialLinks.push(
          <S.TiktokIcon onClick={() => handleSocialMediaClick('tiktok')} />
        )
      }

      if (eventData.socialLinks.facebookLink !== '') {
        socialLinks.push(
          <S.FacebookIcon onClick={() => handleSocialMediaClick('facebook')} />
        )
      }

      if (eventData.socialLinks.instagramLink !== '') {
        socialLinks.push(
          <S.InstagramIcon
            onClick={() => handleSocialMediaClick('instagram')}
          />
        )
      }

      if (eventData.socialLinks.youtubeLink !== '') {
        socialLinks.push(
          <S.YoutubeIcon onClick={() => handleSocialMediaClick('instagram')} />
        )
      }
    }
    return socialLinks
  }, [eventData?.socialLinks])

  console.log(
    '🚀 ~ file: EventProfile.tsx:279 ~ DesktopEventProfile ~ eventData.eventItems:',
    eventData?.eventItems
  )

  return (
    <S.Container>
      {eventData ? (
        <GeneralLayout hasFooter={true} hasHeader={true} headerType="normal">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}>
            <Row ref={containerRef}>
              <Column
                desktop={12}
                tablet={8}
                style={{ objectFit: 'cover' }}
                className="image-column">
                <S.BackButtonIcon
                  onClick={() => {
                    dispatch(clearCheckoutState())
                    dispatch(clearEvent())
                    history.push('/')
                  }}
                />
                <S.EventImage
                  src={
                    isDesktop
                      ? eventData.banners[0].desktopUrl
                      : eventData.banners[0].tabletUrl
                  }
                />
              </Column>
            </Row>
            <Row className="event-info" gutter={1}>
              <Column desktop={6} tablet={4}>
                <S.EventData>
                  <Heading variant="h5">{eventData.name}</Heading>

                  <DateInfo
                    dates={eventData.eventItems.flatMap(({ dates }) => dates)}
                  />

                  <S.LocationBox>
                    <S.LocationIcon />
                    <div>
                      <Paragraph variant="large" type="semiBold">
                        {eventData.address.name}
                      </Paragraph>
                      <Paragraph variant="small" type="normal">
                        {eventData.address.city} - {eventData.address.state},{' '}
                        {eventData.address.zipCode}
                      </Paragraph>
                    </div>
                  </S.LocationBox>

                  <S.AgeBox>
                    {eventData.ageGroup === 'CLASS_L' ? (
                      <>
                        <S.AgeFreeIcon />
                        <Paragraph variant="small" type="normal">
                          Livre para todas as idades
                        </Paragraph>
                      </>
                    ) : eventData.ageGroup === 'CLASS_10' ? (
                      <>
                        <S.Age10Icon />
                        <Paragraph variant="small" type="normal">
                          Não recomendado para menores de 10 anos
                        </Paragraph>
                      </>
                    ) : eventData.ageGroup === 'CLASS_12' ? (
                      <>
                        <S.Age12Icon />
                        <Paragraph variant="small" type="normal">
                          Não recomendado para menores de 12 anos
                        </Paragraph>
                      </>
                    ) : eventData.ageGroup === 'CLASS_14' ? (
                      <>
                        <S.Age14Icon />
                        <Paragraph variant="small" type="normal">
                          Não recomendado para menores de 14 anos
                        </Paragraph>
                      </>
                    ) : eventData.ageGroup === 'CLASS_16' ? (
                      <>
                        <S.Age16Icon />
                        <Paragraph variant="small" type="normal">
                          Não recomendado para menores de 16 anos
                        </Paragraph>
                      </>
                    ) : eventData.ageGroup === 'CLASS_18' ? (
                      <>
                        <S.Age12Icon />
                        <Paragraph variant="small" type="normal">
                          Não recomendado para menores de 18 anos
                        </Paragraph>
                      </>
                    ) : null}
                  </S.AgeBox>
                </S.EventData>
              </Column>
              <Column desktop={6} tablet={4}>
                <EventStatus
                  salesFrom={eventData.salesFrom}
                  finalSales={eventData.finalSales}
                  eventStatus={eventData.status}
                  ticketPrices={ticketPrices ?? []}
                  ticketRef={ticketRef}
                  cashBackPercent={eventData.cashBackPercent}
                />
              </Column>
            </Row>
            <Row>
              <Column desktop={12} tablet={8}>
                <S.EventDetails>
                  <div className="header-wrapper">
                    <Heading variant="h5">Detalhes</Heading>
                    <div className="buttons-wrapper">
                      <button onClick={handleShareEvent}>
                        {isLinkShared ? 'Copiado !' : 'Compartilhar'}{' '}
                        <S.ShareIcon />
                      </button>
                      {renderSocialLinks}
                    </div>
                  </div>

                  <S.Description
                    dangerouslySetInnerHTML={{
                      __html: eventData.description
                    }}
                  />
                </S.EventDetails>
              </Column>
            </Row>
            <Row>
              {eventData.status !== 'CLOSED' &&
                eventData.status !== 'SOLD_OUT' && (
                  <Column desktop={12} tablet={8}>
                    <S.EventTickets ref={ticketRef}>
                      <S.Dates>
                        {eventData.eventItems.length > 0 && (
                          <Heading variant="h4" className="title">
                            Escolha seu assento
                          </Heading>
                        )}

                        <div className="eventItemsBox">
                          {eventData.eventItems.length > 0 &&
                            eventData.eventItems.map((eventItem, index) => {
                              if (eventItem.dates.length > 1) {
                                return (
                                  <S.DatesInfo
                                    disabled={!eventItem.isAvailable}
                                    key={index}
                                    isActive={
                                      eventItem.id === selectedEvent?.id
                                    }
                                    onClick={() => {
                                      eventItem.isAvailable &&
                                        dispatch(selectEvent(eventItem))
                                    }}>
                                    <Paragraph variant={'small'}>
                                      {eventItem.title}
                                    </Paragraph>
                                  </S.DatesInfo>
                                )
                              } else {
                                return (
                                  <S.DatesInfo
                                    disabled={!eventItem.isAvailable}
                                    key={index}
                                    isActive={
                                      eventItem.id === selectedEvent?.id
                                    }
                                    onClick={() => {
                                      eventItem.isAvailable &&
                                        dispatch(selectEvent(eventItem))
                                    }}>
                                    <Paragraph variant={'small'}>
                                      {moment(eventItem.dates[0]).format('ddd')}
                                    </Paragraph>
                                    <S.Paragraph>
                                      {moment(eventItem.dates[0]).format(
                                        'DD/MM - LT'
                                      )}
                                    </S.Paragraph>
                                  </S.DatesInfo>
                                )
                              }
                            })}
                        </div>
                      </S.Dates>
                      {renderTickets}
                    </S.EventTickets>
                  </Column>
                )}
            </Row>
            <Row>
              <Column desktop={12} tablet={8}>
                <S.EventLocation>
                  <Heading variant="h5">Localização</Heading>
                  <LocationIframe
                    number={eventData.address.number}
                    street={eventData.address.street}
                    zipCode={eventData.address.zipCode}
                  />
                </S.EventLocation>
              </Column>
            </Row>
            {tickets && tickets?.length > 0 && (
              <>
                <div style={{ height: '150px' }} />
                <S.TicketBox>
                  <Heading variant="h4">
                    Total:{' '}
                    {formatPrice(
                      tickets.reduce((acc, val) => {
                        return acc + val.totalPrice
                      }, 0)
                    )}
                  </Heading>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth={false}
                    type="button"
                    size="small"
                    style={{ whiteSpace: 'nowrap' }}
                    onClick={handleBuyClick}>
                    Comprar Ingressos
                  </Button>
                </S.TicketBox>
              </>
            )}
          </motion.div>
        </GeneralLayout>
      ) : undefined}
    </S.Container>
  )
}
