import { ButtonHTMLAttributes } from 'react'

import * as S from './BackButton.styles'

export interface BackProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string
}

const BackButton = ({ title, ...props }: BackProps) => {
  return (
    <S.Container>
      <S.ButtonBack {...props} type="button">
        <S.BackIcon />
      </S.ButtonBack>
      <S.Title>{title}</S.Title>
    </S.Container>
  )
}

export default BackButton
