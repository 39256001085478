import styled, { css } from 'styled-components'

import { ReactComponent as LocationFile } from '../../../../../assets/icons/map-marker.svg'
import { ReactComponent as ClockFile } from '../../../../../assets/icons/clock.svg'

export const LocationIcon = styled(LocationFile)`
  ${({ theme }) => css`
    fill: #39383a;
    margin-right: ${theme.spacing.inline.xxs};
  `}
`

export const ClockIcon = styled(ClockFile)`
  ${({ theme }) => css`
    fill: #39383a;
    margin-right: ${theme.spacing.inline.xxs};
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    @media (min-width: 1171px) {
      margin-bottom: ${theme.spacing.stack.xs};
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      .row {
        display: flex;
        justify-content: center;
      }
    }

    .image {
      width: 100%;
    }
  `}
`

export const TicketInfo = styled.section`
  ${({ theme }) => css`
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    padding: ${theme.spacing.stack.lg};
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    overflow-y: auto;
    .total {
      background: #f4f4f4;
      text-align: end;
      padding: 12px ${theme.spacing.stack.xxs};
      border-radius: ${theme.border.radius.medium};
      margin-top: auto;
    }
    .eventName {
      font-size: ${theme.font.size.sm};
      line-height: ${theme.font.size.md};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: ${theme.spacing.stack.xxxs};
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      width: 100%;
      max-height: 80vh;

      .eventName {
        font-size: 41px;
        font-style: normal;
        font-weight: 700;
        line-height: 61px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  `}
`

export const EventData = styled.div`
  ${({ theme }) => css`
    > div {
      display: flex;
      align-items: flex-start;
    }
    .dateInfo {
      margin: ${theme.spacing.stack.xxxs} 0;
    }
    .title {
      color: #6c01a3;
      font-size: ${theme.font.size.md};
      line-height: ${theme.font.size.md};
    }
    margin-bottom: ${theme.spacing.stack.xxs};

    @media (min-width: 768px) and (max-width: 1170px) {
      width: 100%;

      .title {
        font-size: 27px;
        font-style: normal;
        font-weight: 700;
        line-height: 41px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  `}
`

export const CustomerTicket = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-bottom: ${theme.spacing.stack.xxxs};
    div:last-of-type {
      margin-bottom: 0;
    }
  `}
`

export const TicketCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxs};
    background: #f4f4f4;
    border-radius: ${theme.border.radius.medium};
    margin-bottom: ${theme.spacing.stack.xxs};
  `}
`
export const TicketAmmount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > p:last-child {
    color: #8601c9;
  }
`

export const Actions = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${theme.spacing.stack.xxs};
    > div:first-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      > p {
        font-size: ${theme.font.size.sm};
      }
    }
    .backbutton {
      margin: 0 ${theme.spacing.stack.small} 0 0;
    }
    > button {
      background: ${theme.color.alert.success.nth1};
    }
  `}
`

export const Image = styled.img`
  position: -webkit-sticky; /* Safari */
  position: sticky;
`

export const CouponCard = styled.div`
  ${({ theme }) => css`
    @media (min-width: 1170px) {
      display: none;
    }
    background: ${theme.color.base.nth4};
    border-radius: 6px;
    display: flex;
    padding: ${theme.spacing.stack.xs} ${theme.spacing.stack.xxs};
    justify-content: space-between;
    align-items: center;
    margin: 0 0 ${theme.spacing.stack.xxxs};
    .coupon-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      text-align: left;
    }
    .coupon-code {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
    .coupon-discount {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      text-align: left;
      color: ${theme.color.alert.success.nth1};
    }
  `}
`
