import axios, { AxiosResponse } from 'axios'
import store from '../../../redux/store'
import { logoutAction } from '../../../redux/User/actions'

export type CustomerResponse = {
  id: string
  userId: string
  firstName: string
  lastName: string
  email: string
  document: string
  phone: string
  cashBackBalanceCents: number
  address?: {
    country: string
    city: string
    state: string
    street: string
    number: number
    zipCode?: string
    complement?: string
  }
  createdAt: string
  updatedAt?: string
}

export const getCustomerDataService = async (token?: string) => {
  const { dispatch } = store
  try {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const { data }: AxiosResponse<CustomerResponse> = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/customers/info`,
      token !== undefined ? config : undefined
    )
    return data
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response?.status === 401) {
        dispatch(logoutAction())
      }
    }
  }
}
