import axios, { AxiosResponse } from 'axios'

export type RefreshTokenResponse = {
  accessToken: string
  refreshToken: string
  expiresIn: number
  refreshExpiresIn: number
}

export const refreshTokenService = async (refreshToken: string) => {
  const { data }: AxiosResponse<RefreshTokenResponse> = await axios.post(
    `${process.env.REACT_APP_AUTH_URL}api/v1/auth/refresh`,
    {
      clientId: process.env.REACT_APP_CLIENT_ID,
      refreshToken
    }
  )
  return data
}
