import { theme } from 'applaus-ui-kit'
import styled, { css } from 'styled-components'

import { ReactComponent as Lockfile } from '../../../assets/icons/lock.svg'

export const LockIcon = styled(Lockfile)`
  ${({ theme }) => css`
    fill: ${theme.color.brand.primary.nth1};
  `}
`

export const Container = styled.div<{ open: boolean }>`
  ${() => css``}
`
export const UpdatePasswordBox = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    > p {
      color: ${theme.color.brand.primary.nth1};
    }
    margin-bottom: ${theme.spacing.stack.lg};
  `}
`

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 1366px;

    .buttonBox {
      margin-left: auto;
    }
    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      text-align: left;
      color: #39383a;
      margin-bottom: ${theme.spacing.stack.xs};
    }
    > button {
      margin-left: auto;
    }
    @media (max-width: 767px) {
      margin: ${theme.spacing.stack.xxxs} 0;
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      margin: ${theme.spacing.stack.md} 0;
    }
    @media (min-width: 1171px) {
      margin: ${theme.spacing.stack.xxl} 0;
    }
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    line-height: ${theme.font.size.md};
    text-align: left;
  `}
`

export const customStyles = {
  option: (provided: any) => ({
    ...provided,
    background: '#F4F4F4',
    fontSize: theme.font.size.sm,
    color: 'gray',
    cursor: 'pointer',
    transition: 'hover 300ms',
    '&:hover': {
      color: 'black'
    }
  }),
  control: (provided: any) => ({
    ...provided,
    marginTop: theme.spacing.stack.small,
    width: '100%',
    background: '#F4F4F4',
    fontSize: theme.font.size.sm,
    border: 0,
    boxShadow: 'none',
    padding: `3px ${theme.spacing.stack.xxxs}`,
    borderRadius: '8px',
    cursor: 'pointer',
    marginBottom: theme.spacing.stack.xxxs
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: theme.font.size.sm,
    '& input': {
      font: 'inherit'
    }
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0'
  }),
  input: (provided: any) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: theme.font.size.sm,
    '& input': {
      font: 'inherit',
      fontSize: theme.font.size.sm
    }
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: theme.font.size.sm
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: '105px'
  })
}

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  > button {
    margin-left: auto;
  }
`
