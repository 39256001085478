import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.xxs};
    .text {
      margin-top: ${theme.spacing.stack.xxs};
      margin-bottom: ${theme.spacing.stack.md};
    }
  `}
`

export const ButtonBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.stack.small};
  `}
`
