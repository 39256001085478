export const CopyIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.237 6.177L17.823 2.76294C17.492 2.43194 17.053 2.25 16.586 2.25H11C8.932 2.25 7.25 3.932 7.25 6V6.25H6C3.932 6.25 2.25 7.932 2.25 10V18C2.25 20.068 3.932 21.75 6 21.75H13C15.068 21.75 16.75 20.068 16.75 18V17.75H18C20.068 17.75 21.75 16.068 21.75 14V7.41394C21.75 6.94694 21.568 6.507 21.237 6.177ZM17.75 4.81006L19.189 6.24902H18.812C18.227 6.24902 17.75 5.77201 17.75 5.18701V4.81006ZM15.25 18C15.25 19.241 14.241 20.25 13 20.25H6C4.759 20.25 3.75 19.241 3.75 18V10C3.75 8.759 4.759 7.75 6 7.75H7.25V14C7.25 16.068 8.932 17.75 11 17.75H15.25V18ZM18 16.25H11C9.759 16.25 8.75 15.241 8.75 14V6C8.75 4.759 9.759 3.75 11 3.75H16.25V5.18799C16.25 6.60099 17.399 7.75 18.812 7.75H20.25V14C20.25 15.24 19.241 16.25 18 16.25Z"
        fill="#8601C9"
      />
    </svg>
  )
}
