import styled, { css } from 'styled-components'

import { theme } from 'applaus-ui-kit'

export const Container = styled.div<{ isOpen: boolean }>`
  ${({ theme, isOpen }) => css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${theme.color.modal.nth1};
    z-index: 900;
    opacity: ${isOpen ? 1 : 0};
    pointer-events: ${isOpen ? 'all' : 'none'};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease;
  `}
`

export const Content = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.color.neutral.nth4};
    padding: ${theme.spacing.inline.md};
    border-radius: ${theme.border.radius.medium};
    > p {
      text-align: center;
    }
    > button {
      margin: ${theme.spacing.stack.xs} 0 0 auto;
    }
    max-height: 80vh;
    overflow: auto;
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-top: ${theme.spacing.stack.md};
  `}
`

export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    background: '#F4F4F4',
    fontSize: theme.font.size.sm,
    color: 'gray',
    cursor: 'pointer',
    transition: 'hover 300ms',
    '&:hover': {
      color: 'black'
    }
  }),
  control: (provided: any) => ({
    ...provided,
    marginTop: theme.spacing.stack.small,
    width: '100%',
    background: '#F4F4F4',
    fontSize: theme.font.size.sm,
    border: 0,
    boxShadow: 'none',
    padding: `3px ${theme.spacing.stack.xxxs}`,
    borderRadius: '8px',
    cursor: 'pointer'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: theme.font.size.sm,
    '& input': {
      font: 'inherit'
    }
  }),
  input: (provided: any) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: theme.font.size.sm,
    '& input': {
      font: 'inherit',
      fontSize: theme.font.size.sm
    }
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: theme.font.size.sm
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    maxHeight: '105px'
  })
}
