import * as S from './BrandCreditCard.styles'
import Visa from '../../assets/icons/visa.svg'
import Mastercard from '../../assets/icons/mastercard.svg'
import Diners from '../../assets/icons/diners.svg'
import Elo from '../../assets/icons/elo.svg'
import Amex from '../../assets/icons/amex.svg'
import Pix from '../../assets/icons/pix.svg'

type BrandCreditCardProps = {
  paymentMethod?: string
  brandIcon?: string
  brand?: string
  lastNumbers?: string
  final?: boolean
  cashback?: boolean
}

export const BrandCreditCard = ({
  paymentMethod,
  brand,
  brandIcon,
  lastNumbers,
  final,
  cashback
}: BrandCreditCardProps) => {
  return (
    <S.Container>
      <S.BrandIcon
        src={
          brandIcon === 'Visa'
            ? Visa
            : brandIcon === 'Master'
            ? Mastercard
            : brandIcon === 'Elo'
            ? Elo
            : brandIcon === 'Diners'
            ? Diners
            : brandIcon === 'Amex'
            ? Amex
            : brandIcon === 'PIX'
            ? Pix
            : ''
        }
      />
      {cashback ? (
        <S.CashbackIconDiv>
          <S.CashbackIcon />
        </S.CashbackIconDiv>
      ) : null}

      <S.Brand>
        {brand === 'Visa'
          ? 'Visa'
          : brand === 'Master'
          ? 'Mastercard'
          : brand === 'Elo'
          ? 'Elo'
          : brand === 'Diners'
          ? 'Diners'
          : brand === 'Amex'
          ? 'Amex'
          : brand === 'PIX'
          ? 'Pix'
          : null}
      </S.Brand>

      <S.LastNumbers>
        {paymentMethod === 'CREDIT_CARD' ? (
          final ? (
            'final - ' + lastNumbers
          ) : (
            lastNumbers
          )
        ) : paymentMethod === 'PIX' ? (
          'Pix'
        ) : paymentMethod === 'CASH_BACK' ? (
          <S.CashbackIcon />
        ) : (
          'Gratuito'
        )}
      </S.LastNumbers>
    </S.Container>
  )
}
