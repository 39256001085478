import { action } from 'typesafe-actions'
import { EventsAction, Event, CustomerTicket } from './types'
import { EventItems } from '../../api/services/Events/listOneEventService'

export const loadEventRequestAction = (eventId: string) =>
  action(EventsAction.loadEventRequest, eventId)

export const prevStepAction = (
  data: 'home' | 'showEvent' | 'chooseTicket' | 'purchaseSummary'
) => action(EventsAction.prevStep, data)

export const selectEvent = (data: EventItems) =>
  action(EventsAction.selectEvent, data)

export const purchaseSummaryAction = () => action(EventsAction.purchaseSummary)

export const profileEventAction = () => action(EventsAction.profileEvent)

export const chooseTicket = () => action(EventsAction.chooseTicket)

export const selectTicketsAction = (data: CustomerTicket[]) =>
  action(EventsAction.selectTickets, data)

export const loadEventSuccessAction = (data: Event) =>
  action(EventsAction.loadEventSuccess, data)

export const addTicketAction = (ticketId: string) =>
  action(EventsAction.addTicket, ticketId)

export const addTicketInfo = (data: { name: string; value: string }[][]) =>
  action(EventsAction.addTicketInfo, data)

export const removeTicket = (ticketId: string) =>
  action(EventsAction.removeTicket, ticketId)

export const clearSelectedEvent = () => action(EventsAction.clearSelectedEvent)

export const clearEvent = () => action(EventsAction.clearEvent)
