import React, { useEffect } from 'react'

import { Input, InputMask } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import * as S from './MobileCheckout.styles'

import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { clearLoading } from '../../../redux/Loading/actions'

const paymentSchema = Yup.object().shape({
  creditCardOwner: Yup.string().required('Titular do cartão é obrigatório.'),
  creditCardNumber: Yup.string()
    .required('Número do cartão é obrigatório.')
    .test('validateNumber', 'Número de cartão inválido', (value) => {
      if (value && value.replace(/[._ ]/g, '').length >= 13) {
        return true
      } else return false
    }),
  creditCardValidity: Yup.string()
    .required('Validade do cartão é obrigatória.')
    .test('validateValidity', 'Insira uma data válida', (values) => {
      if (values?.replace(/[_/]/g, '').length === 6) {
        return true
      } else return false
    }),
  creditCardCVV: Yup.string().required('CVV é obrigatório.')
})

type Window = {
  [key: string]: any
}

const Iugu = (window as Window).Iugu

type IuguResponse = {
  id: string
  errors: any
}

Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID)
Iugu.setTestMode(process.env.REACT_APP_IUGU_TEST_MODE)

export function MobileCheckout() {
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      creditCardOwner: '',
      creditCardNumber: '',
      creditCardValidity: '',
      creditCardCVV: ''
    },
    validationSchema: paymentSchema,
    onSubmit: (values) => {
      const creditCardOwnerNameList = values.creditCardOwner.split(' ')
      const cc = Iugu.CreditCard(
        values.creditCardNumber.replace(/[._ ]/g, ''),
        values.creditCardValidity.substring(0, 2),
        values.creditCardValidity.substring(3, 7),
        creditCardOwnerNameList[0],
        creditCardOwnerNameList[1],
        values.creditCardCVV.trim()
      )
      const responseIugu = (response: IuguResponse) => {
        if (response) {
          if (response.id) {
            window.postMessage(response.id)
          } else {
            window.postMessage('token_error')
          }
        }
      }
      Iugu.createPaymentToken(cc, responseIugu)
    }
  })

  useEffect(() => {
    dispatch(clearLoading())
  }, [])

  return (
    <S.Container>
      <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0" />

      <S.Wrapper>
        <S.Form onSubmit={formik.handleSubmit}>
          <Input
            name="creditCardOwner"
            label="Nome do titular do cartão"
            placeholder="Digite o nome"
            texterror={formik.errors.creditCardOwner}
            error={
              formik.touched.creditCardOwner &&
              Boolean(formik.errors.creditCardOwner)
            }
            onChange={formik.handleChange}
            value={formik.values.creditCardOwner}
            fullWidth
          />
          <InputMask
            mask="9999 9999 9999 9999"
            name="creditCardNumber"
            label="Número do cartão"
            placeholder="0000 0000 0000 0000"
            texterror={formik.errors.creditCardNumber}
            error={
              formik.touched.creditCardNumber &&
              Boolean(formik.errors.creditCardNumber)
            }
            onChange={formik.handleChange}
            value={formik.values.creditCardNumber}
          />
          <div className="creditCardRow">
            <InputMask
              mask="99/9999"
              name="creditCardValidity"
              label="Data de validade"
              placeholder="00/0000"
              texterror={formik.errors.creditCardValidity}
              error={
                formik.touched.creditCardValidity &&
                Boolean(formik.errors.creditCardValidity)
              }
              onChange={formik.handleChange}
              value={formik.values.creditCardValidity}
            />
            <InputMask
              mask="9999"
              name="creditCardCVV"
              label="CVV"
              placeholder="0000"
              texterror={formik.errors.creditCardCVV}
              error={
                formik.touched.creditCardCVV &&
                Boolean(formik.errors.creditCardCVV)
              }
              onChange={formik.handleChange}
              value={formik.values.creditCardCVV}
            />
          </div>

          <S.ContentBack>
            <div className="button">
              <S.ButtonStyled
                color="primary"
                fullWidth
                size="large"
                variant="contained">
                Comprar
              </S.ButtonStyled>
            </div>
          </S.ContentBack>
        </S.Form>
      </S.Wrapper>
    </S.Container>
  )
}
