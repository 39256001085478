import { Button, Paragraph } from 'applaus-ui-kit'
import { max, min } from 'lodash'
import moment from 'moment'
import { RefObject } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { purchaseSummaryAction } from 'redux/Event/actions'
import { ReduxState } from 'redux/rootReducer'
import { formatPrice } from '../../../../../../utils/formatPrice'

import * as S from './EventStatus.styles'

type Props = {
  ticketPrices: number[]
  ticketRef: RefObject<HTMLDivElement>
  eventStatus: 'CANCELED' | 'OPEN' | 'CLOSED' | 'DRAFT' | 'SOLD_OUT'
  finalSales: string
  salesFrom: string
  cashBackPercent: number | null
}

export const EventStatus = ({
  ticketPrices,
  ticketRef,
  finalSales,
  salesFrom,
  eventStatus,
  cashBackPercent
}: Props) => {
  const { EventReducer, UserReducer } = useSelector(
    (state: ReduxState) => state
  )
  const dispatch = useDispatch()

  const { tickets } = EventReducer
  const { email } = UserReducer
  const handleBuyClick = () => {
    if (!email) {
      window.location.href =
        `${process.env.REACT_APP_AUTH_WEB_URL}/url?name=${window.location.href}` ??
        ''
    } else {
      dispatch(purchaseSummaryAction())
    }
  }

  return (
    <>
      {eventStatus === 'CLOSED' ? (
        <S.EventPricing>
          <Paragraph variant="large" type="bold" className="event-closed-text">
            Evento Fechado
          </Paragraph>
        </S.EventPricing>
      ) : eventStatus === 'CANCELED' ? (
        <S.EventPricing>
          <Paragraph variant="large" type="bold" className="sold-out-text">
            Este evento foi cancelado
          </Paragraph>
        </S.EventPricing>
      ) : eventStatus === 'SOLD_OUT' ? (
        <S.EventPricing>
          <Paragraph variant="large" type="bold" className="sold-out-text">
            Esgotado
          </Paragraph>
        </S.EventPricing>
      ) : moment().isAfter(finalSales) ? (
        <S.EventPricing>
          <Paragraph variant="large" type="bold" className="sold-out-text">
            Vendas Encerradas
          </Paragraph>
        </S.EventPricing>
      ) : moment().isBefore(salesFrom) ? (
        <S.EventPricing>
          <Paragraph variant="large" type="bold" className="opening-event-text">
            As compras dos ingressos abrem
          </Paragraph>
          <Paragraph variant="large" type="bold" className="opening-date">
            {moment(salesFrom).format('DD/MM [`as] hh:mm')}
          </Paragraph>
        </S.EventPricing>
      ) : (
        <S.EventPricing>
          {min(ticketPrices) !== max(ticketPrices) ? (
            <>
              <Paragraph className="textTicket" variant="large">
                Ingressos entre
              </Paragraph>
              <Paragraph className="textPrice" variant="large" type="semiBold">
                {formatPrice(min(ticketPrices) ?? 0)}
                {' e '}
                {formatPrice(max(ticketPrices) ?? 0)}
              </Paragraph>
              <Paragraph className="textCashBack" variant="large">
                {cashBackPercent! > 0 && cashBackPercent !== null
                  ? cashBackPercent + '% cashback'
                  : null}
              </Paragraph>
            </>
          ) : (
            <>
              <Paragraph variant="large">Valor do ingresso</Paragraph>
              <Paragraph variant="large" type="semiBold">
                {formatPrice(min(ticketPrices) ?? 0)}
              </Paragraph>
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            onClick={() => {
              if (ticketRef && ticketRef.current)
                ticketRef.current.scrollIntoView({
                  block: 'start',
                  behavior: 'smooth'
                })
              if (tickets.length > 0) handleBuyClick()
            }}>
            Comprar Ingressos
          </Button>
        </S.EventPricing>
      )}
    </>
  )
}
