import styled, { css } from 'styled-components'

import { ReactComponent as FacebookFile } from '../../assets/icons/facebook.svg'
import { ReactComponent as InstagramFile } from '../../assets/icons/instagram.svg'
import { ReactComponent as YoutubeFile } from '../../assets/icons/youtube.svg'

export const FacebookIcon = styled(FacebookFile)`
  fill: white;
`
export const InstagramIcon = styled(InstagramFile)`
  fill: white;
`
export const YoutubeIcon = styled(YoutubeFile)`
  fill: white;
`

export const Container = styled.div`
  ${({ theme }) => css`
    .applaus-logo {
      width: 100%;
    }
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #8601c9;
    padding-top: 40px;
    padding-bottom: 40px;

    .title {
      color: white;
    }

    .links {
      > li {
        width: max-content;
        list-style: none;
        margin-bottom: ${theme.spacing.stack.small};
        > a {
          text-decoration: none;
        }
      }
      li:last-child {
        margin-bottom: 0;
      }
    }
    .link-text {
      color: white;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      cursor: pointer;
      text-decoration: none;
      position: relative;
      width: max-content;
    }

    .naming-rights {
      color: white;
      text-align: center;
      margin-top: ${theme.spacing.stack.xs};
    }

    @media (min-width: 768px) and (max-width: 1171px) {
      .applaus-logo {
        max-width: 160px;
      }
      padding-top: 24px;
      padding-bottom: 24px;
      .title {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
      }
      .link-text {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
      }
    }
  `}
`

export const SocialLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;

  .social-links {
    display: flex;
    gap: 30px;
    margin-top: 1rem;
    list-style: none;
  }
`
