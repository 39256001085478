import styled, { css } from 'styled-components'

export const Container = styled.div<{ fullWidth: boolean }>`
  ${({ fullWidth }) => css`
    display: flex;
    flex-direction: column;
    width: ${fullWidth ? '100%' : 'auto'};
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.color.base.nth1};
    font-size: ${theme.font.size.xs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: 21px;
    margin-bottom: ${theme.spacing.stack.small};
  `}
`

export const Wrapper = styled.div`
  position: relative;
`

export const Input = styled.input`
  ${({ theme }) => css`
    width: 100%;
    text-overflow: ellipsis;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxxs};
    color: #39383a;
    background: '#DFDFDF';
    border: ${theme.border.width.line} solid ${theme.color.base.nth1};
    border-radius: ${theme.spacing.stack.xxxs};
    font-size: ${theme.font.size.sm};
    padding-right: ${theme.spacing.stack.md};
    &:focus {
      background: #ffffff;
    }
    $::placeholder {
      color: #39383a;
    }
  `}
`

export const Error = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.alert.error.nth1};
    font-size: ${theme.font.size.sm};
    min-height: ${theme.spacing.stack.xxs};
  `}
`

export const IconSvg = styled.svg`
  ${({ theme }) =>
    css`
      cursor: pointer;
      fill: ${theme.color.base.nth1};
      width: 26px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
    `}
`
