import { ProfileTemplate } from '../../../components/templates/ProfileTemplate/ProfileTemplate'
import { PurchaseHistory as PurchaseHistoryComponent } from '../../shared/PurchaseHistory/PurchaseHistory'

export const PurchaseHistory = () => {
  return (
    <ProfileTemplate active="purchaseHistory">
      <PurchaseHistoryComponent />
    </ProfileTemplate>
  )
}
