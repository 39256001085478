/* eslint-disable no-unused-vars */
export enum UserActions {
  setUser = '@user/SET_USER',
  editUserRequest = '@user/EDIT_USER_REQUEST',
  editUserSuccess = '@user/EDIT_USER_SUCCESS',
  refreshToken = '@user/REFRESH_TOKEN',
  logout = '@user/LOGOUT',
  addLocation = '@user/ADD_LOCATION',
  authenticationRequest = '@user/AUTHENTICATION_REQUEST',
  authenticationFailed = '@user/AUTHENTICATION_FAILED'
}

export type User = {
  location?: string
  id: string
  userId: string
  firstName: string
  lastName: string
  email: string
  document: string
  phone: string
  cashBackBalanceCents: number
  loading: boolean
  isAuthenticated: boolean
  address?: {
    country?: string
    city?: string
    state?: string
    street?: string
    number?: number
    zipCode?: string
    complement?: string
  }
  createdAt?: string
  updatedAt?: string
  accessToken?: string
  refreshToken?: string
}
