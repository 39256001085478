import produce from 'immer'
import { Reducer } from 'redux'
import { CheckoutActions, CheckoutState, Coupon } from './types'

const initialState: CheckoutState = {
  data: {
    step: 'defaultCheckout'
  }
}

export const CheckoutReducer: Reducer<CheckoutState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CheckoutActions.SET_CHECKOUT_STATE:
      return produce(state, (draft) => {
        draft.data = action.payload.data
      })
    case CheckoutActions.CLEAR_CHECKOUT_STATE:
      return produce(state, (draft) => {
        draft.data.eventData = undefined
        draft.data.ticketState = undefined
        draft.data.addressCheckout = undefined
        draft.coupon = undefined
      })
    case CheckoutActions.DEFAULT_CHECKOUT:
      return produce(state, (draft) => {
        draft.data.step = 'defaultCheckout'
      })
    case CheckoutActions.CREDIT_CARD:
      return produce(state, (draft) => {
        draft.data.step = 'creditCard'
      })
    case CheckoutActions.PIX:
      return produce(state, (draft) => {
        draft.data.step = 'pix'
      })
    case CheckoutActions.PAYMENT_SUCCESS:
      return produce(state, (draft) => {
        draft.data.step = 'paymentSuccess'
        draft.data.purchaseId = action.payload
      })
    case CheckoutActions.PURCHASE_STATUS:
      return produce(state, (draft) => {
        draft.data.purchaseStatus = action.payload
      })
    case CheckoutActions.REDEEM_COUPON_SUCCESS:
      return produce(state, (draft) => {
        draft.coupon = action.payload as Coupon
      })
    case CheckoutActions.REMOVE_COUPON:
      return produce(state, (draft) => {
        draft.coupon = undefined
      })
    default:
      return state
  }
}
