import { Paragraph } from 'applaus-ui-kit'
import styled, { css } from 'styled-components'
import { ReactComponent as ArrowsFile } from '../../../../../assets/icons/arrows.svg'
import { ReactComponent as CalendarFile } from '../../../../../assets/icons/calendar.svg'
import { ReactComponent as MapMarkerFile } from '../../../../../assets/icons/map-marker.svg'
import { ReactComponent as CloseFile } from '../../../../../assets/icons/times-square.svg'
import { ReactComponent as CheckFile } from '../../../../../assets/icons/check-circle.svg'

export const ArrowsIcon = styled(ArrowsFile)<{ active: boolean }>`
  ${({ theme, active }) => css`
    fill: ${active ? '#6C01A3' : '#39383a'};
    width: 32px;

    @media (min-width: 768px) {
      width: 40px;
      height: 40px;
    }
  `}
`

export const MapMarkerIcon = styled(MapMarkerFile)<{ active: boolean }>`
  ${({ theme, active }) => css`
    fill: ${active ? '#6C01A3' : '#39383a'};
    width: 32px;

    @media (min-width: 768px) {
      width: 40px;
      height: 40px;
    }
  `}
`

export const CalendarIcon = styled(CalendarFile)<{ active: boolean }>`
  ${({ theme, active }) => css`
    fill: ${active ? '#6C01A3' : '#39383a'};
    width: 32px;

    @media (min-width: 768px) {
      width: 40px;
      height: 40px;
    }
  `}
`

export const CloseIcon = styled(CloseFile)`
  ${({ theme }) => css`
    width: 35px;
    height: 25px;

    @media (min-width: 768px) {
      width: 40px;
      height: 40px;
      position: relative;
      top: -30px;
      left: 30px;
    }
  `}
`

export const CheckIcon = styled(CheckFile)`
  ${({ theme }) => css`
    fill: #6c01a3;
    width: 32px;
    margin-left: ${theme.spacing.stack.small};
  `}
`

export const Container = styled.div<{ isOpen: boolean }>`
  ${({ theme, isOpen }) => css`
    opacity: ${isOpen ? 1 : 0};
    pointer-events: ${isOpen ? 'all' : 'none'};
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: ${theme.color.modal.nth1};
    z-index: 888;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.stack.xxs};
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: ${theme.spacing.stack.md};
    }
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: #dfdfdf;
    border-radius: ${theme.border.radius.medium};

    @media (max-width: 767px) {
      position: absolute;
      display: flex;
      bottom: ${theme.spacing.stack.xxs};
      left: ${theme.spacing.stack.xxs};
      right: ${theme.spacing.stack.xxs};
      padding: ${theme.spacing.stack.xxxs};
      display: flex;
      flex-direction: column;
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: ${theme.spacing.stack.md};
    }
  `}
`

export const TitleModal = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 768px) {
      margin-left: 60px;
    }
  `}
`

export const Option = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin: ${theme.spacing.stack.small} 0;
    align-items: center;
    > svg {
      margin-right: ${theme.spacing.stack.small};
    }
  `}
`

export const ParagraphStyled = styled(Paragraph)<{ active: boolean }>`
  ${({ theme, active }) => css`
    color: ${active ? '#6C01A3' : '#39383A'};
  `}
`

export const TextDescription = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: top;

    @media (min-width: 768px) {
      align-items: center;
      > p {
        max-width: 180px;
      }
    }
  `}
`
