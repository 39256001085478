import * as S from './LocationIframe.styles'

type Props = {
  zipCode: string
  street: string
  number: number
}

export const LocationIframe = ({ number, street, zipCode }: Props) => {
  return (
    <S.Iframe>
      <iframe
        className="responsive-iframe"
        src={decodeURI(
          `https://maps.google.com/maps?q=${zipCode}, ${street}, ${number}&t=&z=15&ie=UTF8&iwloc=&output=embed`
        )}
        frameBorder="0"
        scrolling="no"
      />
    </S.Iframe>
  )
}
