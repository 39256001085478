import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.md} 0;
  `}
`

export const CourseWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 1366px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      padding-left: ${theme.spacing.stack.xxs};
    }
    @media (min-width: 1171px) {
      padding: 0 ${theme.spacing.stack.xxxl};
      > div {
        margin-right: ${theme.spacing.inline.small};
      }
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      padding-left: ${theme.spacing.stack.xs};
    }
  `}
`

export const CourseBox = styled.section`
  display: flex;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
