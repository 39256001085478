import styled, { css } from 'styled-components'

import { ReactComponent as ArrowLeft } from '../../assets/icons/chevron-circle-left.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
  `}
`

export const IconBack = styled(ArrowLeft)`
  ${({ theme }) => css`
    fill: #39383a;
    margin-right: ${theme.spacing.stack.small};
  `}
`

export const Title = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.semiBold};
    font-size: ${theme.font.size.sm};
    color: #39383a;
  `}
`

export const SubTitle = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.xxs};
    color: #39383a;
  `}
`
