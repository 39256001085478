import { EventItem } from '../Purchases/types'

export const ContentActions = {
  listAllBannersRequest: '@content/LIST_ALL_BANNERS_REQUEST',
  listAllBannersSuccess: '@content/LIST_ALL_BANNERS_SUCCESS',
  listAllBannersFailure: '@content/LIST_ALL_BANNERS_FAILURE',
  listAllEventsRequest: '@content/LIST_ALL_EVENTS_REQUEST',
  listAllEventSuccess: '@content/LIST_ALL_EVENTS_SUCCESS',
  listAllEventFailure: '@content/LIST_ALL_EVENTS_FAILURE',
  listCoursesRequest: '@content/LIST_COURSES_REQUEST',
  listCourseSuccess: '@content/LIST_COURSES_SUCCESS',
  filterEventsByCategoryRequest: '@content/FILTER_EVENTS_BY_CATEGORY_REQUEST',
  filterEventsByCategorySuccess: '@content/FILTER_EVENTS_BY_CATEGORY_SUCCESS',
  filterEventsWithCashBackRequest:
    '@content/FILTER_EVENTS_WITH_CASHBACK_REQUEST',
  filterEventsWithCashBackSuccess:
    '@content/FILTER_EVENTS_WITH_CASHBACK_SUCCESS'
}

export type Category = {
  id: string
  name: string
}

export type Event = {
  id: string
  name: string
  description: string
  dates: string[]
  eventItem: EventItem[]
  cashBackPercent: number | null
  address: {
    id: string
    country: string
    state: string
    city: string
    street: string
    number: number
    zipCode: string
    latitude?: string
    longitude?: string
    complement?: string
    createdAt: string
    updatedAt?: string
    creatorId?: string
    customerId?: string
    eventId: string
    seatMapId?: string
  }
  banners: { mobileUrl: string; desktopUrl: string; tabletUrl: string }[]
  createdAt: string
  updatedAt?: string
}

export type Events = {
  items: Event[]
  total: number
}

export type Banner = {
  id: string
  name: string
  description: string
  link: string
  position: number
  status: boolean
  linkTarget: string
  desktopUrl: string
  mobileUrl: string
  tabletUrl: string
}

export type Banners = Banner[]

export type Course = {
  id: string
  name: string
  priceCents: number
  discountCents: number
  description: string
  cardUrl: string
  mobileCoverUrl: string
  tabletCoverUrl: string
  desktopCoverUrl: string
  level: 'BEGGINER' | 'ADVANCED' | 'INTERMEDIATE'
  modules: {
    id: string
    title: string
    description: string
    lessons: {
      id: string
      title: string
      description: string
      coverUrl: string
      videoUrl: string
      videoMobileUrl: string
      duration: number
    }[]
  }[]

  faq?: {
    title: string
    answer: string
  }[]
  creatorInfo: {
    firstName: string
    lastName: string
    position: string
    description: string
    socialLinks: string[]
  }
}

export type ContentState = {
  events?: Events
  banners: Banners
  categories: Category[]
  courses: Course[]
  isLoading: boolean
}
