import { AxiosResponse } from 'axios'
import api from '../../config/api'

export type PurchaseOrderResponse = {
  id: string
  status: string
  invoiceId: string
  pixQrCode: string
  pixQrCodeText: string
  failedCode: {
    code: string
    error: string
  }
  customField: string
  customerId: string
  creatorId: string
  createdAt: string
  updatedAt: string
}

export const purchaseOrderGeneric = async (purchaseId: string) => {
  const { data }: AxiosResponse<PurchaseOrderResponse> = await api.get(
    `/api/purchase/orders/${purchaseId}`
  )

  return data
}
