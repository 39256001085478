import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    background: #f4f4f4;
    display: flex;
    column-gap: ${theme.spacing.stack.xxs};
    border-radius: ${theme.border.radius.medium};
    .priceTotal {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `}
`

export const TextSeat = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.semiBold};
    font-size: ${theme.font.size.sm};
    line-height: 28px;
  `}
`

export const TicketCategory = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.semiBold};
    font-size: ${theme.font.size.sm};
    line-height: 28px;
  `}
`

export const TicketValue = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
  `}
`

export const TicketQuantity = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    color: #808080;
    font-size: ${theme.spacing.stack.xxxs};
  `}
`

export const PriceTotal = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.semiBold};
    color: #8601c9;
    font-size: ${theme.font.size.sm};
  `}
`
