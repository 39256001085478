/* eslint-disable no-unused-vars */
export enum PurchasesActions {
  PURCHASE_ORDER_CUSTOMER_REQUEST = '@pucharses/PURCHASE_ORDER_CUSTOMER_REQUEST',
  PURCHASE_ORDER_CUSTOMER_SUCCESS = '@pucharses/PURCHASE_ORDER_CUSTOMER_SUCCESS',
  SET_PURCHASE_ORDER_CUSTOMER = '@pucharses/SET_PURCHASE_ORDER_CUSTOMER',
  VIEW_MORE_PURCHASE_ORDER_REQUEST = '@pucharses/VIEW_MORE_PURCHASE_ORDER_REQUEST',
  VIEW_MORE_PURCHASE_ORDER_FAILURE = '@pucharses/VIEW_MORE_PURCHASE_ORDER_FAILURE',
  VIEW_MORE_PURCHASE_ORDER_SUCCESS = '@pucharses/VIEW_MORE_PURCHASE_ORDER_SUCCESS',
  CLEAR_ITEMS_PER_PAGE = '@purchases/CLEAR_ITEMS_PER_PAGE',
  INITIAL_STATE = '@purchases/INITIAL_STATE',
  GET_PURCHASE_ORDER_BY_ID_REQUEST = '@pucharses/GET_PURCHASEORDER_BY_ID_REQUEST',
  GET_PURCHASE_ORDER_BY_ID_SUCCESS = '@pucharses/GET_PURCHASEORDER_BY_ID_SUCCESS'
}

export type Ticket = {}

export type EventItem = {
  dates: string[]
  description: string
  title: string
  ticketInfo: {
    value: string
    isRequired: boolean
  }[]
  finalSales: string
  event: {
    name: string
    description: string
    type: string
    cashBackPercent: number | null
    address: {
      country: string
      state: string
      city: string
      street: string
      number: number
      zipCode: string
    }
    banners: {
      cardUrl: string
      desktopUrl: string
      mobileUrl: string
      tabletUrl: string
    }
  }
}

export type CustomerTickets = {
  id: string
  seatId: string
  qrCode: string
  customerTicketId: string
  purchaseOrderId: string
  ticketInfo: Record<string, string>
  detachedAt: string
  eventItem: EventItem
  ticket: {
    name: string
    description: string
    priceCents: number
    discountCents: number
    type: string
    category: string
  }
}

export type PurchaseOrder = {
  id: string
  status: string
  orderId: string
  payment: {
    paymentMethod: string
    status: string
    totalPrice: number
    brand: string
    lastCardNumbers: string
    installments: string
    cashBackDiscountCents: number | null
  }
  invoiceId: string
  pixQrCode: string | undefined
  pixQrCodeText: string | undefined
  createdAt: string
  updatedAt: string
  customerTickets: CustomerTickets[]
  eventItem: EventItem
}

export type PurchasesState = {
  itemsPerPage: number
  purchasesState: {
    items: PurchaseOrder[]
    total: number
  }
  selectedPurchaseOrder?: PurchaseOrder
}
