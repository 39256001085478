import * as S from './ChooseEventItem.styles'
import {
  chooseTicket,
  profileEventAction,
  selectEvent
} from '../../../../redux/Event/actions'
import BackButton from '../../../../components/backButton/BackButton'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/rootReducer'
import { Paragraph } from 'applaus-ui-kit'
import moment from 'moment'
import { MappedEvent } from '../MappedEvent/Mobile/MappedEvent'
import { MobileTabulatedEvent } from '../TabulatedEvent/Mobile/MobileTabulatedEvent'
import { EventItems } from '../../../../redux/Event/types'

export const ChooseEventItem = () => {
  const dispatch = useDispatch()
  const { eventData, selectedEvent } = useSelector(
    (state: ReduxState) => state.EventReducer
  )

  const handleDateClick = (eventItem: EventItems) => {
    if (eventItem.isAvailable) {
      dispatch(selectEvent(eventItem))
      dispatch(chooseTicket())
    }
  }

  return (
    <>
      {!selectedEvent ? (
        <S.Container>
          <BackButton
            title="Horários do Evento"
            onClick={() => {
              dispatch(profileEventAction())
            }}
            className="backbutton"
          />

          {eventData!.eventItems.map((eventItem, index) => {
            return (
              <S.EventItems key={index + eventItem.id}>
                <Paragraph variant="regular" type="semiBold">
                  {moment(eventItem.dates[0]).format('ll')}
                </Paragraph>
                <S.Dates>
                  {eventItem.dates.map((date, index) => (
                    <S.EventDate
                      disabled={!eventItem.isAvailable}
                      key={index + date}
                      onClick={() => handleDateClick(eventItem)}>
                      {moment(date).format('LT')}
                    </S.EventDate>
                  ))}
                </S.Dates>
              </S.EventItems>
            )
          })}
        </S.Container>
      ) : eventData?.seatMapId ? (
        <MappedEvent />
      ) : (
        <MobileTabulatedEvent />
      )}
    </>
  )
}
