import * as S from './CashBackEvents.styles'
import { EventCard } from '../../../EventCard/EventCard'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/rootReducer'
import { Paragraph } from 'applaus-ui-kit'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { useEffect, useRef } from 'react'
import moment from 'moment'

export const CashBackEvents = () => {
  const { events } = useSelector((state: ReduxState) => state.ContentsReducer)
  const { isDesktop } = useWindowSize()

  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    console.log(
      events?.items.filter((event) => {
        if (event.eventItem.find((item) => moment().isBefore(item.finalSales)))
          return true
        else return false
      })
    )
  }, [events])
  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 200
    }
  }

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 200
    }
  }

  return (
    <S.Container>
      <S.EventWrapper>
        <Paragraph variant="large" type="bold" className="title">
          Eventos com cashback
        </Paragraph>

        {isDesktop ? (
          <S.ScrollBox>
            <S.ButtonRight onClick={handleScrollRight}>
              <S.ArrowRight />
            </S.ButtonRight>
            <S.EventBox ref={scrollRef} id="card-cashback">
              {events?.items
                .filter((event) => {
                  if (
                    event.eventItem.find((item) =>
                      moment().isBefore(item.finalSales)
                    )
                  )
                    return true
                  else return false
                })
                .map((event, index) => (
                  <EventCard key={event.id + index} {...event} />
                ))}
            </S.EventBox>
            <S.ButtonLeft onClick={handleScrollLeft}>
              <S.ArrowLeft />
            </S.ButtonLeft>
          </S.ScrollBox>
        ) : (
          <S.EventBox>
            {events?.items
              .filter((event) => {
                if (
                  event.eventItem.find((item) =>
                    moment().isBefore(item.finalSales)
                  )
                )
                  return true
                else return false
              })
              .map((event, index) => (
                <EventCard key={event.id + index} {...event} />
              ))}
          </S.EventBox>
        )}
      </S.EventWrapper>
    </S.Container>
  )
}
