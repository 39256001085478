import * as S from './BottomNavigation.styles'
import { useHistory } from 'react-router-dom'

type Props = {
  active: 'home' | 'search' | 'purchases' | 'profile'
}

export const BottomNavigation = ({ active }: Props) => {
  const history = useHistory()
  return (
    <S.Container>
      <ul>
        <S.Item
          active={active === 'home' ? 1 : 0}
          onClick={() => history.push('/')}>
          <S.HomeIcon />
        </S.Item>
        <S.Item
          onClick={() => history.push('/search')}
          active={active === 'search' ? 1 : 0}>
          <S.SearchIcon />
        </S.Item>
        <S.Item
          active={active === 'purchases' ? 1 : 0}
          onClick={() => history.push('/purchases')}>
          <S.LikeIcon />
        </S.Item>
        <S.Item
          active={active === 'profile' ? 1 : 0}
          onClick={() => {
            history.push('/profileTab')
          }}>
          <S.ProfileIcon />
        </S.Item>
      </ul>
    </S.Container>
  )
}
