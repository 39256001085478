import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxs};
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    > div:first-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: ${theme.spacing.stack.xxs};
      > p {
        font-size: ${theme.font.size.sm};
      }
    }
    .backbutton {
      margin: 0 ${theme.spacing.stack.small} 0 0;
    }
  `}
`
export const EventItems = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    > p {
      text-align: center;
      background: #f4f4f4;
      padding: ${theme.spacing.stack.small} ${theme.spacing.inline.xxs};
      border-radius: ${theme.border.radius.medium};
    }
  `}
`

export const Dates = styled.div`
  ${({ theme }) => css`
    margin: 24px 0;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing.stack.xxs};
    justify-content: space-between;
  `}
`

export const EventDate = styled.p<{ disabled: boolean }>`
  ${({ theme, disabled }) => css`
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    padding: ${theme.spacing.inline.nano} ${theme.spacing.inline.xxs};

    border-radius: ${theme.border.radius.medium};

    ${disabled
      ? css`
          background: gray;
          pointer-events: none;
        `
      : css`
          cursor: pointer;
          transition: background ease-in-out 0.5s;
          border: 2px solid #8601c9;
          :hover {
            background: #8601c9;
            color: white;
          }
        `}
  `}
`
