import { ReactNode, FunctionComponent, SVGProps } from 'react'

import * as S from './MenuItem.styles'
import { Paragraph } from 'applaus-ui-kit'

export type MenuItemProps = {
  onClick: () => void
  children: ReactNode
  Icon?: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
}

export const MenuItem = ({ children, onClick, Icon }: MenuItemProps) => {
  return (
    <S.Container onClick={onClick}>
      {Icon ? <Icon /> : undefined}
      <Paragraph variant="large">{children}</Paragraph>
    </S.Container>
  )
}
