import * as S from './PurchaseSummary.styles'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from 'redux/rootReducer'
import {
  addTicketInfo,
  chooseTicket,
  profileEventAction
} from 'redux/Event/actions'
import { Button, Heading, Paragraph, Input } from 'applaus-ui-kit'
import { formatPrice } from 'utils/formatPrice'
import {
  redeemCouponRequestAction,
  removeCouponAction,
  setCheckoutState
} from 'redux/Checkout/actions'
import history from 'routes/services/history'
import { useState } from 'react'
import produce from 'immer'
import {
  clearModalState,
  setModalState
} from 'redux/Modal/Actions/actionsModal'
import { clearLoading, setLoading } from 'redux/Loading/actions'
import { ticketPurchase } from 'api/services/checkout/ticketPurchase'
import { messageHelper } from 'utils/messageHelper'
import { purchaseOrder } from 'api/services/checkout/purchaseOrder'
import { sleep } from 'utils/sleep'
import { calculateTotalPrice } from 'utils/calculateTotalPrice'
import { AddCoupon } from '../../../Checkout/components/addCoupon/AddCoupon'
import { DescriptionCard } from 'components/descriptionCard/DescriptionCard'
import { ButtonBack } from 'components/buttonBack/buttonBack'

export const PurchaseSummary = () => {
  const { EventReducer, CheckoutReducer } = useSelector(
    (state: ReduxState) => state
  )
  const { selectedEvent, tickets, eventData } = EventReducer
  const { coupon } = CheckoutReducer
  const dispatch = useDispatch()

  const initialState = tickets.map(() =>
    selectedEvent!.ticketInfo?.map((ticketInfo) => ({
      name: ticketInfo.value,
      value: '',
      error: ticketInfo.isRequired
    }))
  )

  const [ticketInfos, setTicketInfos] = useState(initialState)
  const [submit, setSubmit] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleAddTicketInfo = (value: string, index: number, name: string) => {
    setTicketInfos(
      produce((draft) => {
        draft[index]![
          draft[index]!.findIndex((info) => info.name === name)
        ].value = value
        draft[index]![
          draft[index]!.findIndex((info) => info.name === name)
        ].error = !(value.length > 0)
      })
    )
  }

  const handleClick = () => {
    setSubmit(true)
    const hasError = ticketInfos
      .flatMap((ticketInfo) => ticketInfo?.flatMap((info) => info.error))
      .some((elem) => elem === true)

    if (hasError) {
      dispatch(
        setModalState({
          variant: 'error',
          message: 'Campos obrigatórios não preenchidos',
          open: true
        })
      )
    } else {
      const formatedTicketInfo = ticketInfos.map((ticketInfo) =>
        ticketInfo!.map((info) => ({
          name: info.name,
          value: info.value
        }))
      )

      dispatch(addTicketInfo(formatedTicketInfo))
      setSuccess(true)
    }
  }

  const totalPriceReduce =
    tickets?.reduce((acc, val) => {
      return acc + val.totalPrice
    }, 0) ?? 0

  const handlePurchaseOrder = async (purchaseOrderId: string) => {
    dispatch(setLoading())
    const purchaseOrderData = await purchaseOrder(
      purchaseOrderId,
      Number(process.env.REACT_APP_REQUEST_INTERVAL)
    )
    await sleep(3000)
    if (purchaseOrderData?.status === 'SUCCESS') {
      dispatch(clearLoading())
      dispatch(
        setModalState({
          message: 'Compra realizada com sucesso!',
          open: true,
          variant: 'success',
          click: () => {
            history.push('/purchases')
            dispatch(clearModalState())
          }
        })
      )
    } else if (purchaseOrderData?.status === 'WAITING') {
      dispatch(clearLoading())
      dispatch(
        setModalState({
          message: 'Compra em análise.',
          open: true,
          variant: 'error',
          click: () => {
            history.push('/purchases')
            dispatch(clearModalState())
          }
        })
      )
    } else {
      dispatch(clearLoading())
      switch (purchaseOrderData?.failedCode.code) {
        case 'TICKET_UNAVAILABLE':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.ticketUnavailable,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.ticketUnavailable,
              click: () => {
                dispatch(clearModalState())
                dispatch(profileEventAction())
                history.push(`/event/${eventData?.id}`)
              }
            })
          )

        case 'PAYMENT_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.reviewData,
              open: true,
              variant: 'error',
              buttonText: 'Revisar dados',
              description: messageHelper.modal.error.reviewData,
              click: () => {
                dispatch(clearModalState())
                dispatch(profileEventAction())
                history.push(`/event/${eventData?.id}`)
              }
            })
          )

        case 'INTEGRATION_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError,
              click: () => {
                dispatch(clearModalState())
                dispatch(profileEventAction())
                history.push(`/event/${eventData?.id}`)
              }
            })
          )

        case 'BOOKING_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError,
              click: () => {
                dispatch(clearModalState())
                dispatch(profileEventAction())
                history.push(`/event/${eventData?.id}`)
              }
            })
          )

        case 'BAD_REQUEST_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError,
              click: () => {
                dispatch(clearModalState())
                dispatch(profileEventAction())
                history.push(`/event/${eventData?.id}`)
              }
            })
          )

        case 'PURCHASE_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError,
              click: () => {
                dispatch(clearModalState())
                dispatch(profileEventAction())
                history.push(`/event/${eventData?.id}`)
              }
            })
          )
      }
    }

    dispatch(clearLoading())
  }

  const buyEventFree = async () => {
    dispatch(setLoading())
    try {
      const { data } = await ticketPurchase({
        paymentMethod: {
          type: 'FREE',
          cashBackValueCents: null
        },
        tickets: tickets.map(({ ticketInfo, id, eventItemId, seatKey }) => ({
          ticketInfo,
          ticketId: id,
          eventItemId,
          seatKey: eventData?.type === 'MAPPED' ? seatKey : undefined
        }))
      })

      if (data.status === 'WAITING' || data.status === 'SUCCESS') {
        await handlePurchaseOrder(data.id)
      }
    } catch (err: any) {
      await sleep(3000)
      dispatch(clearLoading())
      if (err) {
        switch (err.response.data.code) {
          case 'TICKET_UNAVAILABLE':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.ticketUnavailable,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.ticketUnavailable,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'PAYMENT_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.reviewData,
                open: true,
                variant: 'error',
                buttonText: 'Revisar dados',
                description: messageHelper.modal.error.reviewData,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'INTEGRATION_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.integrationError,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.integrationError,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'BOOKING_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.integrationError,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.integrationError,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'BAD_REQUEST_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.integrationError,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.integrationError,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'PURCHASE_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.integrationError,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.integrationError,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )
        }
      }
    }
  }

  const handleRedeemCoupon = (eventCode: string) => {
    dispatch(redeemCouponRequestAction(eventCode))
  }

  const handlePurchaseButtonClick = async () => {
    if (totalPriceReduce === 0) {
      await buyEventFree()
    } else {
      dispatch(
        setCheckoutState({
          data: {
            eventData,
            step: 'defaultCheckout',
            ticketState: tickets
          }
        })
      )
      history.push('/checkout')
    }
  }

  return (
    <S.Container>
      <ButtonBack
        title="Resumo da Compra"
        onClick={() => dispatch(chooseTicket())}
        className="backbutton"
      />

      {selectedEvent!.ticketInfo &&
      selectedEvent!.ticketInfo.length > 0 &&
      !success ? (
        <>
          <DescriptionCard title="Dados dos participantes" />
          {tickets?.map(({ id, totalPrice, labels, name, category }, index) => (
            <>
              <S.TicketCard key={id + index}>
                <div>
                  {labels ? (
                    <>
                      <Paragraph
                        style={{ textTransform: 'uppercase' }}
                        variant="small"
                        type="semiBold">
                        Seção: {labels.section} Linha: {labels.parent}
                      </Paragraph>
                      <Paragraph
                        style={{ textTransform: 'uppercase' }}
                        variant="small"
                        type="semiBold">
                        Assento: {labels.own}
                      </Paragraph>
                    </>
                  ) : (
                    <>
                      <Paragraph
                        style={{ textTransform: 'uppercase' }}
                        variant="small"
                        type="semiBold">
                        {name}
                      </Paragraph>
                      <Paragraph
                        style={{ textTransform: 'uppercase' }}
                        variant="small"
                        type="semiBold">
                        {category}
                      </Paragraph>
                    </>
                  )}
                  <Paragraph
                    style={{ textTransform: 'uppercase' }}
                    variant="small">
                    {formatPrice(totalPrice)}
                  </Paragraph>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                  <Paragraph
                    style={{ textTransform: 'uppercase' }}
                    variant="small">
                    ingresso
                  </Paragraph>
                  <Heading variant="h5" className="price">
                    {formatPrice(totalPrice)}
                  </Heading>
                </div>
              </S.TicketCard>
              {selectedEvent!.ticketInfo?.map((ticketInfo, indexTicket) => (
                <Input
                  autoFocus={indexTicket === 0 && index === 0}
                  fullWidth
                  key={ticketInfo.value + index}
                  label={ticketInfo.value}
                  onChange={(e) =>
                    handleAddTicketInfo(e.target.value, index, ticketInfo.value)
                  }
                  error={
                    ticketInfos[index]![
                      ticketInfos[index]!.findIndex(
                        (ticket) => ticket.name === ticketInfo.value
                      )
                    ].error && submit
                  }
                  texterror={`${ticketInfo.value} é obrigatório`}
                />
              ))}
            </>
          ))}
          <Button
            variant="contained"
            color="primary"
            size="medium"
            fullWidth={false}
            onClick={handleClick}>
            Continuar
          </Button>
        </>
      ) : (
        <>
          <DescriptionCard title="Ingressos" />

          {tickets?.map(({ id, totalPrice, labels, name, category }, index) => (
            <S.TicketCard key={id + index}>
              <div>
                {labels ? (
                  <>
                    <Paragraph
                      style={{ textTransform: 'uppercase' }}
                      variant="small"
                      type="semiBold">
                      Seção: {labels.section} Linha: {labels.parent}
                    </Paragraph>
                    <Paragraph
                      style={{ textTransform: 'uppercase' }}
                      variant="small"
                      type="semiBold">
                      Assento: {labels.own}
                    </Paragraph>
                  </>
                ) : (
                  <>
                    <Paragraph
                      style={{ textTransform: 'uppercase' }}
                      variant="small"
                      type="semiBold">
                      {name}
                    </Paragraph>
                    <Paragraph
                      style={{ textTransform: 'uppercase' }}
                      variant="small"
                      type="semiBold">
                      {category}
                    </Paragraph>
                  </>
                )}
                <Paragraph
                  style={{ textTransform: 'uppercase' }}
                  variant="small">
                  {formatPrice(totalPrice)}
                </Paragraph>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                <Paragraph
                  style={{ textTransform: 'uppercase' }}
                  variant="small">
                  ingresso
                </Paragraph>
                <Heading variant="h5" className="price">
                  {formatPrice(totalPrice)}
                </Heading>
              </div>
            </S.TicketCard>
          ))}
          {coupon ? (
            <S.CouponCard>
              <S.RemoveIcon
                onClick={() =>
                  dispatch(removeCouponAction(coupon.customerCouponId))
                }
              />
              <div>
                <p className="coupon-title">Cupom de desconto</p>
                <p className="coupon-code">{coupon.name}</p>
              </div>
              <p className="coupon-discount">
                {coupon.valueType === 'CENTS'
                  ? `- ${formatPrice(coupon.value / 100)}`
                  : `- ${coupon.value} %`}
              </p>
            </S.CouponCard>
          ) : (
            <AddCoupon handleRedeemCoupon={handleRedeemCoupon} />
          )}
          <div style={{ height: '74px' }} />
          <S.TicketBox>
            <S.TotalPrice>
              <Paragraph variant="small" type="semiBold">
                Total
              </Paragraph>
              <Heading variant="h5">
                {formatPrice(calculateTotalPrice(tickets, coupon))}
              </Heading>
            </S.TotalPrice>

            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="button"
              size="small"
              style={{ whiteSpace: 'nowrap' }}
              onClick={handlePurchaseButtonClick}>
              Comprar
            </Button>
          </S.TicketBox>
        </>
      )}
    </S.Container>
  )
}
