import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    height: 100vh;
    position: relative;
  `}
`

export const CoverUrl = styled.img`
  width: 100%;
  height: 100%;
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 ${theme.spacing.stack.xxs} ${theme.spacing.stack.md};
    p {
      margin-bottom: ${theme.spacing.stack.xxs};
      text-align: center;
    }
  `}
`

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    line-height: ${theme.spacing.stack.sm};
    font-weight: ${theme.font.weight.bold};
    color: #ffff;
  `}
`
