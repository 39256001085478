import { CustomerTicket, Event } from '../Event/types'

/* eslint-disable no-unused-vars */
export enum CheckoutActions {
  SET_CHECKOUT_STATE = '@checkout/SET_CHECKOUT_STATE',
  CLEAR_CHECKOUT_STATE = '@checkout/CLEAR_CHECKOUT_STATE',
  DEFAULT_CHECKOUT = '@checkout/DEFAULT_CHECKOUT',
  CREDIT_CARD = '@checkout/CREDIT_CARD',
  BANK_SLIP = '@checkout/BANK_SLIP',
  PIX = '@checkout/PIX',
  PAYMENT_SUCCESS = '@checkout/PAYMENT_SUCCESS',
  PAYMENT_FAILURE = '@checkout/PAYMENT_FAILURE',
  PURCHASE_STATUS = '@checkout/PURCHASE_STATUS',
  REDEEM_COUPON_REQUEST = '@checkout/REDEEM_COUPON_REQUEST',
  REDEEM_COUPON_SUCCESS = '@checkout/REDEEM_COUPON_SUCCESS',
  REMOVE_COUPON = '@checkout/REMOVE_COUPON'
}

export type Coupon = {
  customerCouponId: string
  value: number
  valueType: 'CENTS' | 'PERCENT'
  name: string
}

export type CreditCard = {
  owner: string
  number: string
  validity: string
  cvv: string
}

export type CheckoutState = {
  data: {
    ticketState?: CustomerTicket[]
    eventData?: Event
    addressCheckout?: {
      country?: string
      city?: string
      state?: string
      street?: string
      number?: number
      zipCode?: string
      complement?: string
    }
    purchaseId?: string
    purchaseStatus?: string
    step:
      | 'defaultCheckout'
      | 'creditCard'
      | 'bankSlip'
      | 'pix'
      | 'paymentSuccess'
  }
  coupon?: Coupon
}
