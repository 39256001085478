import {
  clearSelectedEvent,
  selectTicketsAction
} from '../../../../redux/Event/actions'
import { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/rootReducer'
// @ts-ignore
import { SeatsioSeatingChart } from '@seatsio/seatsio-react'
import { formatPrice } from '../../../../utils/formatPrice'
import produce from 'immer'

type ChartCategories = {
  label: string
}

type ChartSeats = {
  seatId: string
  category: {
    label: string
  }
  selectedTicketType: string
  pricing: {
    ticketTypes: [
      {
        price: number
        ticketType: string
      }
    ]
  }
  labels: {
    own: string
    parent: string
    section: string
    displayedLabel: string
  }
}

type Chart = {
  listCategories: () => Promise<ChartCategories[]>
  listSelectedObjects: () => Promise<ChartSeats[]>
  destroy: () => void
}

export const MappedEvent = () => {
  let chart: Chart | null = null
  const dispatch = useDispatch()
  const [categories, setCategories] = useState<string[]>([])
  const { selectedEvent } = useSelector(
    (state: ReduxState) => state.EventReducer
  )

  const [unavailableCategories, setUnavailableCategories] = useState<string[]>(
    []
  )

  const generatePrice = useMemo(() => {
    setUnavailableCategories([])
    const groupedTickets = _.groupBy(
      selectedEvent?.tickets,
      (ticket) => ticket.category
    )

    const ticketTypes = _.mapValues(groupedTickets, function (value) {
      return value.flatMap((ticket) => ({
        ticketType: ticket.name,
        price: formatPrice(ticket.totalPrice)
      }))
    })

    for (const category of categories) {
      if (ticketTypes[category] === undefined) {
        ticketTypes[category] = [
          { ticketType: 'Adulto', price: formatPrice(0) }
        ]

        setUnavailableCategories(
          produce((draft) => {
            draft.push(category)
          })
        )
      }
    }

    return categories.map((category) => ({
      category: category,
      ticketTypes: _.values(_.pick(ticketTypes, category))[0]
    }))
  }, [selectedEvent, categories])

  useEffect(() => {
    if (chart) {
      // @ts-ignore
      chart.changeConfig({
        unavailableCategories: unavailableCategories
      })
    }
  }, [chart, unavailableCategories])

  const handleListSeats = async () => {
    if (chart !== null && selectedEvent) {
      try {
        const selectedObjects = await chart.listSelectedObjects()

        dispatch(
          selectTicketsAction(
            selectedObjects.map(
              ({ category, labels, seatId, selectedTicketType }) => {
                return {
                  id:
                    selectedEvent.tickets.find(
                      (ticket) =>
                        ticket.category === category.label &&
                        ticket.name === selectedTicketType
                    )?.id ?? '',
                  category: labels.section,
                  name: labels.displayedLabel,
                  totalPrice:
                    selectedEvent?.tickets.find(
                      (ticket) =>
                        ticket.category === category.label &&
                        ticket.name === selectedTicketType
                    )?.totalPrice! ?? 0,
                  labels: labels,
                  eventItemId: selectedEvent.id,
                  seatKey: seatId
                }
              }
            )
          )
        )
      } catch (e) {}
    }
  }

  const handleChartFailed = () => {
    dispatch(clearSelectedEvent())
  }

  return (
    <SeatsioSeatingChart
      workspaceKey={process.env.REACT_APP_WORKSPACEKEY_SEATSIO}
      event={selectedEvent?.eventKey}
      onRenderStarted={async (createdChart: any) => {
        chart = createdChart
        if (chart !== null) {
          const getCategories = await chart.listCategories()

          setCategories(getCategories.map(({ label }) => label))
        }
      }}
      maxSelectedObjects={selectedEvent?.maxCustomerTickets}
      onChartRenderingFailed={handleChartFailed}
      region="sa"
      language="pt"
      session="continue"
      multiSelectEnabled={true}
      showSectionPricingOverlay={true}
      pricing={[...generatePrice]}
      onSelectionValid={() => handleListSeats()}
    />
  )
}
