import styled, { css } from 'styled-components'

import { ReactComponent as SearchFile } from 'assets/icons/coupon.svg'

export const SearchIcon = styled(SearchFile)`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: ${theme.spacing.inline.xxs};
    fill: black;
    transform: translateY(-50%);
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    @media (min-width: 1171px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: ${theme.spacing.stack.xxl} ${theme.spacing.stack.xxxl};
      max-width: 1366px;

      img {
        min-width: 365px;
        max-width: 365px;
        min-height: 245px;
        max-height: 245px;
      }
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      min-width: 320px;
      ${theme.spacing.stack.xl} ${theme.spacing.stack.xxs};
      overflow-y: auto;
      .titleBox {
        display: flex;
        justify-content: center;
      }
      padding: 0;
    }

    @media (max-width: 767px) {
      min-width: 320px;
      padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxs}
        ${theme.spacing.stack.xl} ${theme.spacing.stack.xxs};
      overflow-y: auto;
      .titleBox {
        display: flex;
        justify-content: center;
      }
    }
  `}
`

export const CardEvent = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;
    @media (min-width: 1171px) {
      img {
        min-width: 365px;
        max-width: 365px;
        min-height: 245px;
        max-height: 245px;
      }
    }
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    @media (min-width: 1171px) {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: left;
      color: #39383a;
      margin-bottom: ${theme.spacing.stack.xs};
    }

    @media (max-width: 1170px) {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: center;
      color: #39383a;
      margin-bottom: ${theme.spacing.stack.xs};
    }
  `}
`

export const ContentNoTicket = styled.div`
  ${({ theme }) => css`
    @media (min-width: 1171px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: ${theme.spacing.stack.xxl};
      padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxxl}
        ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxxl};
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: ${theme.spacing.stack.xxs};
      padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxxl}
        ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxxl};
    }

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: ${theme.spacing.stack.xxs};
      padding: ${theme.spacing.stack.xxs};

      @media (min-width: 768px) and (max-width: 1170px) {
        padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxxl}
          ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxxl};
      }
    }
  `}
`

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-bottom: ${theme.spacing.stack.xxs};
  `}
`

export const Input = styled.input`
  ${({ theme }) => css`
    width: 100%;
    height: ${theme.spacing.stack.xl};
    background: ${theme.color.base.nth4};
    border-radius: ${theme.border.radius.medium};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid transparent;
    outline: 0;
    text-indent: ${theme.spacing.stack.xl};
    &:focus {
      background: white;
      border: 1px solid ${theme.color.brand.primary.nth1};
    }
  `}
`

export const TicketImage = styled.img`
  ${({ theme }) => css`
    width: 226px;
    height: 226px;
    margin-bottom: ${theme.spacing.stack.xs};

    @media (min-width: 768px) and (max-width: 1170px) {
      width: 226px;
      height: 226px;
      margin-bottom: ${theme.spacing.stack.xs};
    }

    @media (max-width: 767px) {
      margin-bottom: ${theme.spacing.stack.xxs};
    }
  `}
`

export const TitleNoTicket = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxs};
    color: #adadad;
    text-align: center;

    @media (min-width: 768px) and (max-width: 1170px) {
      color: #adadad;
      text-align: center;
      font-size: ${theme.font.size.lg};
      font-weight: ${theme.font.weight.semiBold};
      line-height: ${theme.spacing.stack.sm};
    }

    @media (max-width: 767px) {
      font-size: ${theme.font.size.sm};
      font-weight: ${theme.font.weight.semiBold};
      line-height: ${theme.spacing.stack.xxs};
      color: #adadad;
      text-align: center;

      @media (min-width: 768px) and (max-width: 1170px) {
        font-size: ${theme.font.size.lg};
        font-weight: ${theme.font.weight.semiBold};
        line-height: ${theme.spacing.stack.sm};
      }
    }
  `}
`

export const CardsTickets = styled.div`
  ${({ theme }) => css`
    @media (min-width: 1171px) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing.stack.xxxs};

      img {
        cursor: pointer;
        min-width: 364px;
        max-width: 364px;
        max-height: 247px;
        border-radius: 8px;
      }
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: ${theme.spacing.stack.xxs};
      margin-top: ${theme.spacing.stack.xxxs};

      img {
        min-width: 340px;
        max-width: 340px;
        height: 283px;
        border-radius: ${theme.border.radius.medium};
      }
    }

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      margin-top: ${theme.spacing.stack.xxxs};
    }
  `}
`

export const SeeMore = styled.p`
  ${({ theme }) => css`
    @media (min-width: 1171px) {
      cursor: pointer;
      margin-top: ${theme.spacing.stack.xxs};
      text-align: start;
      font-size: ${theme.font.size.xs};
      font-weight: ${theme.font.weight.semiBold};
      transition: 0.3s ease;
      width: max-content;
      color: #b88bcf;
      &:hover {
        transform: scale(1.1);
        color: #6c01a3;
      }
    }
    @media (max-width: 1170px) {
      cursor: pointer;
      text-align: end;
      font-size: ${theme.font.size.xs};
      font-weight: ${theme.font.weight.semiBold};
      line-height: ${theme.spacing.stack.xxxs};
      color: #6c01a3;
    }
  `}
`

export const Cards = styled.div`
  @media (min-width: 768px) and (max-width: 1170px) {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    img {
      width: 340px;
      height: 280px;
      border-radius: 12px;
    }
  }
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.xxs};
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: 0 ${theme.spacing.stack.xs} ${theme.spacing.stack.xs}
        ${theme.spacing.stack.xs};
      text-align: center;
    }
  `}
`

export const TotalRequest = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    background: #f4f4f4;
    padding: ${theme.spacing.stack.small};
    margin: ${theme.spacing.stack.xxxs} 0;
    border-radius: 6px;

    @media (min-width: 768px) and (max-width: 1170px) {
      display: flex;
      justify-content: space-between;
      padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
      background: #f4f4f4;
      border-radius: ${theme.border.radius.medium};
    }
  `}
`

export const TextRequest = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxxs};
    color: #39383a;

    @media (min-width: 768px) and (max-width: 1170px) {
      font-weight: ${theme.font.weight.semiBold};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
    }
  `}
`

export const TextTotalRequest = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxxs};
    color: #808080;

    @media (min-width: 768px) and (max-width: 1170px) {
      font-weight: ${theme.font.weight.semiBold};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
      color: #808080;
    }
  `}
`

export const TotalRequestClosed = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    background: #f4f4f4;
    padding: ${theme.spacing.stack.small};
    margin-top: ${theme.spacing.stack.xxxs};
    border-radius: ${theme.border.radius.medium};

    @media (min-width: 768px) and (max-width: 1170px) {
      margin-top: ${theme.spacing.stack.xxs};
      display: flex;
      justify-content: space-between;
      padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
      background: #f4f4f4;
      border-radius: ${theme.border.radius.medium};
    }
  `}
`

export const TextRequestClosed = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxxs};
    color: #39383a;

    @media (min-width: 768px) and (max-width: 1170px) {
      font-weight: ${theme.font.weight.semiBold};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
    }
  `}
`

export const TextTotalRequestClosed = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxxs};
    color: #808080;

    @media (min-width: 768px) and (max-width: 1170px) {
      font-weight: ${theme.font.weight.semiBold};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
      color: #808080;
    }
  `}
`
