import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { User, UserActions } from './types'
import { PayloadAction } from 'typesafe-actions'
import {
  CustomerResponse,
  editProfileService,
  EditUserRequest
} from '../../api/services/profile/editProfileService'
import { editUserSuccessAction } from './actions'
import { ReduxState } from '../rootReducer'
import { setModalState } from '../Modal/Actions/actionsModal'
import { setLoading, clearLoading } from '../Loading/actions'

export function* editUserProfile(
  action: PayloadAction<UserActions.editUserRequest, EditUserRequest>
) {
  try {
    yield put(setLoading())

    const getUser = (state: ReduxState) => state.UserReducer
    const userState: User = yield select(getUser)

    const { firstName, lastName, email, phone, address } = action.payload

    const newAddress = {
      city:
        (userState.address?.city !== address?.city && address?.city !== '') ||
        (userState.address?.complement !== address?.complement &&
          address?.complement !== '') ||
        (userState.address?.number !== Number(address?.number) &&
          Number(address?.number) !== 0) ||
        (userState.address?.state !== address?.state &&
          address?.state !== '') ||
        (userState.address?.street !== address?.street &&
          address?.street !== '') ||
        (userState.address?.zipCode !== address?.zipCode &&
          address?.zipCode !== '') ||
        (userState.address?.country !== address?.country &&
          address?.country !== '')
          ? address?.city
          : undefined,
      complement:
        userState.address?.complement !== address?.complement &&
        address?.complement !== ''
          ? address?.complement
          : undefined,
      country:
        (userState.address?.city !== address?.city && address?.city !== '') ||
        (userState.address?.complement !== address?.complement &&
          address?.complement !== '') ||
        (userState.address?.number !== Number(address?.number) &&
          Number(address?.number) !== 0) ||
        (userState.address?.state !== address?.state &&
          address?.state !== '') ||
        (userState.address?.street !== address?.street &&
          address?.street !== '') ||
        (userState.address?.zipCode !== address?.zipCode &&
          address?.zipCode !== '')
          ? 'BR'
          : undefined,
      number:
        userState.address?.number !== Number(address?.number) &&
        Number(address?.number) !== 0
          ? Number(address?.number)
          : undefined,
      state:
        (userState.address?.city !== address?.city && address?.city !== '') ||
        (userState.address?.complement !== address?.complement &&
          address?.complement !== '') ||
        (userState.address?.number !== Number(address?.number) &&
          Number(address?.number) !== 0) ||
        (userState.address?.state !== address?.state &&
          address?.state !== '') ||
        (userState.address?.street !== address?.street &&
          address?.street !== '') ||
        (userState.address?.zipCode !== address?.zipCode &&
          address?.zipCode !== '') ||
        (userState.address?.country !== address?.country &&
          address?.country !== '')
          ? address?.state
          : undefined,
      street:
        userState.address?.street !== address?.street && address?.street !== ''
          ? address?.street
          : undefined,
      zipCode:
        userState.address?.zipCode !== address?.zipCode &&
        address?.zipCode !== ''
          ? address?.zipCode
          : undefined
    }

    const newUserInfo = {
      email: userState.email !== email ? email : undefined,
      firstName: userState.firstName !== firstName ? firstName : undefined,
      lastName: userState.lastName !== lastName ? lastName : undefined,
      phone:
        userState.phone.replace(/[^0-9]/g, '') !==
        phone?.replace(/[^0-9]/g, '').trim()
          ? phone
          : undefined,
      address:
        Object.values(newAddress).filter((i) => i !== undefined).length > 0
          ? newAddress
          : undefined
    }

    if (Object.values(newUserInfo).filter((i) => i !== undefined).length > 0) {
      const data: CustomerResponse = yield call(editProfileService, newUserInfo)

      yield put(editUserSuccessAction(data))
      yield put(
        setModalState({
          message: 'Dados alterados com sucesso.',
          open: true,
          variant: 'success'
        })
      )
    }

    yield put(clearLoading())
  } catch (e) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        message: 'Não foi possível realizar edição',
        variant: 'error',
        description: 'Tente novamente mais tarde'
      })
    )
  }
}

export const UserSaga = all([
  takeLatest(UserActions.editUserRequest, editUserProfile)
])
