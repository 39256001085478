import { AppBar } from 'components/appBar/AppBar'
import { ButtonBack } from 'components/buttonBack/buttonBack'
import * as S from './ShowTicket.styles'
import { useSelector } from 'react-redux'
import { Hidden } from 'applaus-ui-kit'
import { formatPrice } from 'utils/formatPrice'
import { ReduxState } from 'redux/rootReducer'
import moment from 'moment'
import { useState, useEffect } from 'react'
import QRCode from 'qrcode'
import history from 'routes/services/history'
import { useParams } from 'react-router-dom'
import { ProfileTemplate } from 'components/templates/ProfileTemplate/ProfileTemplate'
import { downloadPurchasePDF } from 'api/services/purchases/downloadPurchasePDF'

export const ShowTicket = () => {
  const { id } = useParams<{ id: string }>()

  const { selectedPurchaseOrder } = useSelector(
    (state: ReduxState) => state.PurchasesReducer
  )

  const [qrCodeImage, setQrCodeImage] = useState<string[]>([])

  useEffect(() => {
    selectedPurchaseOrder?.customerTickets.map((ticket) =>
      QRCode.toDataURL(ticket.qrCode).then((response) =>
        setQrCodeImage((prev) => [...prev, response])
      )
    )
  }, [])

  const handleDownloadTicketsClick = async () => {
    await downloadPurchasePDF(id)
  }

  return (
    <ProfileTemplate active="purchases">
      <S.Container>
        <Hidden desktop>
          <AppBar />
        </Hidden>

        <S.ContentRequest>
          <Hidden tablet>
            <S.TitleRequest>Ingressos</S.TitleRequest>
          </Hidden>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              maxWidth: '800px'
            }}>
            <ButtonBack
              title={
                'Pedido: ' + selectedPurchaseOrder?.orderId.toLocaleUpperCase()
              }
              subTitle={
                'Comprado em ' +
                moment(
                  selectedPurchaseOrder?.updatedAt ||
                    selectedPurchaseOrder?.createdAt
                ).format('DD/MM/YYYY') +
                ' às ' +
                moment(
                  selectedPurchaseOrder?.updatedAt ||
                    selectedPurchaseOrder?.createdAt
                ).format('LT')
              }
              click={() => history.push(`/purchase/${id}`)}
            />
            <div>
              <S.DownloadButton onClick={handleDownloadTicketsClick}>
                Baixar ingresso <S.DownloadIcon />
              </S.DownloadButton>
            </div>
          </div>

          <div className="contentTickets">
            {selectedPurchaseOrder?.customerTickets.map(
              (customerTicket, index) => (
                <S.ContentTicket key={customerTicket.id}>
                  <S.TicketBox>
                    <div className="circleLeft" />
                    <S.Ticket>
                      <div className="titleEventBox">
                        <S.TitleEvent>
                          {selectedPurchaseOrder?.eventItem.event.name}
                        </S.TitleEvent>
                        {selectedPurchaseOrder.customerTickets[index]
                          .detachedAt && (
                          <p className="ticketDetached"> (Utilizado)</p>
                        )}
                      </div>
                      <S.AddressEvent>
                        {selectedPurchaseOrder!.eventItem.event.address.street +
                          ', ' +
                          selectedPurchaseOrder!.eventItem.event.address.city}
                      </S.AddressEvent>
                      <S.Date>
                        {moment(
                          selectedPurchaseOrder?.eventItem.dates[0]
                        ).format('LLLL')}
                      </S.Date>

                      <S.TicketInfoBox>
                        <S.TicketInfo>
                          <div className="contentInfoBox">
                            <h1>Ingresso:</h1>{' '}
                            <p>{customerTicket.ticket.name}</p>
                          </div>
                          {selectedPurchaseOrder.eventItem.event.type ===
                          'MAPPED' ? (
                            <div className="contentInfoBox">
                              <h1>Setor:</h1>{' '}
                              <p>{customerTicket.ticket.category}</p>
                            </div>
                          ) : null}
                          {selectedPurchaseOrder.eventItem.event.type ===
                          'MAPPED' ? (
                            <div className="contentInfoBox">
                              <h1>Lugar:</h1> <p>{customerTicket.seatId}</p>
                            </div>
                          ) : null}
                        </S.TicketInfo>

                        <S.TicketInfo2>
                          {selectedPurchaseOrder.eventItem.ticketInfo.length >
                          0 ? (
                            <div>
                              {selectedPurchaseOrder.eventItem.ticketInfo.map(
                                (ticket, index) =>
                                  index < 3 ? (
                                    <div
                                      key={index}
                                      className="contentTicketInfo">
                                      <h1>{ticket.value}:</h1>{' '}
                                      <p>
                                        {
                                          customerTicket.ticketInfo[
                                            ticket.value
                                          ]
                                        }
                                      </p>
                                    </div>
                                  ) : null
                              )}
                            </div>
                          ) : (
                            <div></div>
                          )}
                          <S.PurchaseData>
                            <div className="contentPurchaseData">
                              <h1>Data da compra:</h1>{' '}
                              <p>
                                {moment(
                                  selectedPurchaseOrder?.updatedAt ||
                                    selectedPurchaseOrder?.createdAt
                                ).format('L') +
                                  ' às ' +
                                  moment(
                                    selectedPurchaseOrder?.updatedAt ||
                                      selectedPurchaseOrder?.createdAt
                                  ).format('LT')}
                              </p>
                            </div>
                            <div className="contentPurchaseData">
                              <h1>Valor:</h1>{' '}
                              <p>
                                {formatPrice(
                                  (customerTicket.ticket.priceCents -
                                    customerTicket.ticket.discountCents) /
                                    100
                                )}
                              </p>
                            </div>
                          </S.PurchaseData>
                        </S.TicketInfo2>
                      </S.TicketInfoBox>
                    </S.Ticket>
                    <div className="circleRight" />
                  </S.TicketBox>

                  <S.QrCode>
                    <p>
                      {index + 1} de{' '}
                      {selectedPurchaseOrder.customerTickets.length} ingressos
                    </p>
                    <img
                      src={qrCodeImage[index]}
                      alt="QRCode"
                      className="qrCodeImage"
                    />
                    <p className="idQrCode">
                      {selectedPurchaseOrder.customerTickets[
                        index
                      ].customerTicketId.toUpperCase()}
                    </p>
                  </S.QrCode>
                </S.ContentTicket>
              )
            )}
          </div>
        </S.ContentRequest>
      </S.Container>
    </ProfileTemplate>
  )
}
