import { action } from 'typesafe-actions'
import { EditUserRequest } from '../../api/services/profile/editProfileService'
import { User, UserActions } from './types'

export const setUserAction = (data: User) => action(UserActions.setUser, data)

export type EditUser = {
  firstName?: string
  lastName?: string
  email?: string
  document?: string
  password?: string
  phone?: string
  address?: {
    country?: string
    city?: string
    state?: string
    street?: string
    number?: string
    zipCode?: string
    complement?: string | null
  }
}

export const editUserRequestAction = (data: EditUser) =>
  action(UserActions.editUserRequest, data)

export const editUserSuccessAction = (data: EditUserRequest) =>
  action(UserActions.editUserSuccess, data)

export const refreshTokenAction = (data: {
  accessToken: string
  refreshToken: string
}) => action(UserActions.refreshToken, data)

export const logoutAction = () => action(UserActions.logout)

export const addLocationAction = (city: string) =>
  action(UserActions.addLocation, city)

export const authenticationRequest = () =>
  action(UserActions.authenticationRequest)

export const authenticationFailed = () =>
  action(UserActions.authenticationFailed)
