import api from '../../config/api'

type Request = {
  currentPassword: string
  password: string
}

export const updatePasswordService = async (data: Request) => {
  await api.patch('/api/customers/credentials', data)
}
