import * as S from './MappedEvent.styles'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/rootReducer'
import {
  prevStepAction,
  purchaseSummaryAction
} from '../../../../../redux/Event/actions'
import { Button, Paragraph } from 'applaus-ui-kit'
import BackButton from '../../../../../components/backButton/BackButton'
import { MappedEvent as MappedEventComponent } from '../MappedEvent'

export const MappedEvent = () => {
  const dispatch = useDispatch()
  const { selectedEvent, tickets } = useSelector(
    (state: ReduxState) => state.EventReducer
  )

  return (
    <S.Container>
      <BackButton
        title="Selecione seu assento"
        onClick={() => dispatch(prevStepAction('chooseTicket'))}
        className="backbutton"
      />
      {selectedEvent ? <MappedEventComponent /> : undefined}
      {tickets && tickets?.length > 0 && (
        <>
          <div style={{ height: '74px' }} />
          <S.TicketBox>
            <S.TicketWrapper>
              <S.TicketAmount>
                <Paragraph variant="small" type="semiBold">
                  {tickets.length}
                </Paragraph>
              </S.TicketAmount>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="button"
                size="small"
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => {
                  dispatch(purchaseSummaryAction())
                }}>
                Comprar agora
              </Button>
            </S.TicketWrapper>
          </S.TicketBox>
        </>
      )}
    </S.Container>
  )
}
