import { Button, Heading, Paragraph } from 'applaus-ui-kit'
import { Dispatch, SetStateAction } from 'react'
import { ListEventResponse } from '../../../api/services/Events/listEvents'
import { EventCard } from '../../shared/EventCard/EventCard'
import { DateFilter } from './components/DateFilter/DateFilter'
import { OrderByfilter } from './components/OrderBy/OrderBy'
import { PriceFilter } from './components/PriceFilter/PriceFilter'
import * as S from './Search.styles'
import { GeneralLayout } from '../../../components/templates/GeneralLayout/GeneralLayout'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import ReactSelect from 'react-select'
import history from '../../../routes/services/history'
import { useQuery } from '../../../hooks/useQuery'
import moment from 'moment'

const categories = [
  { label: 'Shows', values: 'Shows' },
  { label: 'Eventos Online', values: 'Eventos Online' },
  { label: 'Esportes', values: 'Esportes' },
  { label: 'Palestras', values: 'Palestras' },
  { label: 'Teatro', values: 'Teatro' }
]

type Props = {
  eventsFound: ListEventResponse | undefined
  products: { isOpen: boolean; events: boolean; course: boolean }
  category: string
  setCategory: Dispatch<SetStateAction<string>>
  setProducts: Dispatch<
    SetStateAction<{
      isOpen: boolean
      events: boolean
      course: boolean
    }>
  >
  date: {
    isOpen: boolean
    date: undefined | Date[]
  }
  setDate: Dispatch<
    SetStateAction<{
      isOpen: boolean
      date: undefined | Date[]
    }>
  >

  price: {
    isOpen?: boolean | undefined
    cashBack?: string
    price: {
      minValue: number | undefined
      maxValue: number | undefined
    }
  }
  setPrice: Dispatch<
    SetStateAction<{
      isOpen?: boolean | undefined
      cashBack?: string
      price: {
        minValue: number | undefined
        maxValue: number | undefined
      }
    }>
  >
  orderByState: {
    isOpen: boolean
    options: string[]
    actualOption: string
  }

  setOrderByState: Dispatch<
    SetStateAction<{
      isOpen: boolean
      options: string[]
      actualOption: string
    }>
  >
}

export const Search = ({
  eventsFound,
  category,
  setCategory,
  products,
  setProducts,
  date,
  setDate,
  price,
  setPrice,
  orderByState,
  setOrderByState
}: Props) => {
  const query = useQuery()

  const handleCleanFilters = () => {
    history.push('/search?name=')
    setCategory('')
    setPrice({
      isOpen: false,
      cashBack: undefined,
      price: {
        minValue: undefined,
        maxValue: undefined
      }
    })
    setOrderByState({
      isOpen: false,
      options: ['Relevância', 'Data', 'Localização'],
      actualOption: 'Relevância'
    })
    setProducts({ isOpen: false, events: true, course: false })
    setDate({ date: undefined, isOpen: false })
  }

  return (
    <GeneralLayout hasFooter hasHeader headerType="search">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <S.EventsFoundText>
              <Heading variant="h5">
                <span className="events-quantity">
                  {eventsFound ? eventsFound.items.length : 0}
                </span>
                EVENTOS ENCONTRADOS
              </Heading>
            </S.EventsFoundText>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <S.FilterWrapper>
              <S.FilterOptions>
                <Paragraph style={{ whiteSpace: 'nowrap' }} variant="regular">
                  Filtrar por:{' '}
                </Paragraph>
                <ReactSelect
                  options={categories}
                  onChange={(e) => setCategory(e!.values)}
                  value={categories.filter((obj) => category === obj.values)}
                  noOptionsMessage={() => 'Categoria não encontrada'}
                  placeholder="Categoria"
                  styles={S.customStyles}
                />
                <DateFilter dateState={date} setDate={setDate} />
                <PriceFilter priceState={price} setPrice={setPrice} />
              </S.FilterOptions>
              <S.OrderWrapper>
                <Paragraph variant="regular">Ordenar por: </Paragraph>
                <OrderByfilter
                  orderByState={orderByState}
                  setOrderByState={setOrderByState}
                />
              </S.OrderWrapper>
            </S.FilterWrapper>
          </Col>
        </Row>
        {query.get('name') !== '' ||
        category !== '' ||
        orderByState.actualOption === 'Data' ||
        date.date ||
        price.price.minValue !== undefined ||
        price.price.maxValue !== undefined ||
        price.cashBack === 'true' ? (
          <Row style={{ marginBottom: '24px' }}>
            <Col md={2} lg={3}>
              <Button
                color="primary"
                size="small"
                fullWidth={false}
                variant="contained"
                onClick={handleCleanFilters}>
                Limpar filtros
              </Button>
            </Col>
          </Row>
        ) : null}
        <Row>
          <S.CardEvents>
            {eventsFound &&
              eventsFound?.items
                .filter((eventsFound) => {
                  if (
                    eventsFound.eventItem.find((item) =>
                      moment().isBefore(item.finalSales)
                    )
                  )
                    return true
                  else return false
                })
                .map((event) => (
                  <EventCard key={event.id} {...event} showInfo />
                ))}
          </S.CardEvents>
        </Row>
      </Container>
    </GeneralLayout>
  )
}
