import styled, { css } from 'styled-components'

import { ReactComponent as LocationFile } from '../../../assets/icons/location.svg'
import { ReactComponent as DateFile } from '../../../assets/icons/clock2.svg'

export const Container = styled.div<{ opacity?: boolean }>`
  ${({ theme, opacity }) => css`
    border: ${theme.border.radius.medium};
    display: flex;
    margin-bottom: ${theme.spacing.stack.xxxs};
    opacity: ${opacity ? '0.5' : '1'};
    max-height: 120px;
    cursor: pointer;
  `}
`

export const CoverUrl = styled.img`
  ${({ theme }) => css`
    min-width: 40%;
    max-width: 40%;
    max-height: 120px;
    border-radius: ${theme.border.radius.medium} 0 0
      ${theme.border.radius.medium};
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    width: 60%;
    display: flex;
    border-radius: 0 ${theme.border.radius.medium} ${theme.border.radius.medium}
      0;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.spacing.stack.xxxs};
    background: #f4f4f4;
  `}
`

export const TitleContent = styled.div`
  ${({ theme }) => css`
    display: block;
    font-size: ${theme.font.size.xs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxs};
    color: #6c01a3;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
  `}
`

export const LocationBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: ${theme.spacing.stack.nano};
    svg {
      margin-right: ${theme.spacing.stack.nano};
    }
  `}
`

export const IconLocation = styled(LocationFile)`
  ${({ theme }) => css`
    fill: #39383a;
    width: ${theme.spacing.stack.xxxs};
    height: ${theme.spacing.stack.xxxs};
  `}
`

export const TextLocation = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    font-weight: ${theme.font.weight.regular};
    line-height: ${theme.spacing.stack.xxxs};
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
  `}
`

export const DatesBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
  `}
`

export const Date = styled.div`
  ${({ theme }) => css`
    display: flex;
    svg {
      margin-right: ${theme.spacing.inline.small};
    }
  `}
`

export const IconDate = styled(DateFile)`
  ${({ theme }) => css`
    fill: #39383a;
    width: ${theme.spacing.stack.xxxs};
    height: ${theme.spacing.stack.xxxs};
  `}
`

export const TextDate = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    font-weight: ${theme.font.weight.regular};
    line-height: ${theme.spacing.stack.xxxs};
  `}
`
