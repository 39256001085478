import { AxiosResponse } from 'axios'
import api from '../../config/api'

export type PurchaseOrderResponse = {
  id: string
  status: string
  invoiceId: string
  pixQrCode: string
  pixQrCodeText: string
  failedCode: {
    code: string
    error: string
  }
  customField: string
  customerId: string
  creatorId: string
  createdAt: string
  updatedAt: string
}

export const purchaseOrder = async (
  purchaseId: string,
  intervalTime: number
) => {
  function sleep() {
    return new Promise((resolve) => setTimeout(resolve, intervalTime))
  }

  let requestData

  for (let i = Number(process.env.REACT_APP_ATTEMPTS); i > 0; i--) {
    try {
      const { data }: AxiosResponse<PurchaseOrderResponse> = await api.get(
        `/api/purchase/orders/${purchaseId}`
      )
      if (data.status === 'SUCCESS') {
        return data
      } else {
        requestData = data
      }
      await sleep()
    } catch {}
  }
  return requestData
}
