import { SearchPage } from '../../mobile/Search/Search'
import { Search as DesktopSearch } from '../../desktop/Search/Search'
import { Hidden } from 'applaus-ui-kit'
import { useEffect, useState } from 'react'
import { useQuery } from '../../../hooks/useQuery'
import { useDispatch, useSelector } from 'react-redux'
import { ListEventResponse } from '../../../api/services/Events/listEvents'
import { clearLoading, setLoading } from '../../../redux/Loading/actions'
import { filterEventService } from '../../../api/services/Events/filterEventService'
import { sleep } from '../../../utils/sleep'
import moment from 'moment'
import { ReduxState } from '../../../redux/rootReducer'

export const Search = () => {
  const query = useQuery()
  const dispatch = useDispatch()
  const { location } = useSelector((state: ReduxState) => state.UserReducer)
  const [eventsFound, setEventsFound] = useState<ListEventResponse | undefined>(
    undefined
  )

  const [category, setCategory] = useState(query.get('category') ?? '')

  const [products, setProducts] = useState({
    isOpen: false,
    events: true,
    course: false
  })

  const [date, setDate] = useState<{
    isOpen: boolean
    date: undefined | Date[]
  }>({
    isOpen: false,
    date: undefined
  })

  const [price, setPrice] = useState<{
    isOpen?: boolean
    cashBack?: string
    price: {
      minValue: number | undefined
      maxValue: number | undefined
    }
  }>({
    isOpen: false,
    price: {
      minValue: undefined,
      maxValue: undefined
    }
  })

  const [orderByState, setOrderByState] = useState<{
    isOpen: boolean
    options: string[]
    actualOption: string
  }>({
    isOpen: false,
    options: ['Relevância', 'Data', 'Localização'],
    actualOption: 'Relevância'
  })

  const searchEvents = async () => {
    try {
      dispatch(setLoading())

      const data = await filterEventService({
        name: query.get('name'),
        category: category,
        initialDate: date.date && date.date[0].toDateString(),
        finalDate: date.date && date.date[1].toDateString(),
        initialPrice: price.price.minValue,
        finalPrice: price.price.maxValue,
        cashback: price.cashBack
      })

      if (orderByState.actualOption === 'Data') {
        const sortedMinDate = data!.items.flatMap((event) => {
          let minDate
          event.eventItem.flatMap((item) => {
            return (minDate = moment.min(
              item.dates.map((date) => moment(date))
            ))
          })
          return { ...event, minDate: minDate }
        })

        const sortedEvents = sortedMinDate.sort((a, b) => {
          if (moment(a.minDate) < moment(b.minDate)) {
            return -1
          } else return 1
        })

        setEventsFound({
          items: sortedEvents,
          total: data!.total
        })
      } else if (orderByState.actualOption === 'Localização') {
        const filteredCity =
          data?.items.filter((event) => event.address.city === location) ?? []

        setEventsFound({
          items: filteredCity,
          total: data!.total
        })
      } else {
        setEventsFound(data)
      }

      await sleep(3000)
      dispatch(clearLoading())
    } catch (e) {
      dispatch(clearLoading())
    }
  }

  useEffect(() => {
    searchEvents()
  }, [
    query.get('category'),
    query.get('name'),
    category,
    products.course,
    products.events,
    date.date,
    price.cashBack,
    price.price,
    orderByState.actualOption
  ])

  return (
    <>
      <Hidden tablet mobile>
        <DesktopSearch
          eventsFound={eventsFound}
          date={date}
          setDate={setDate}
          products={products}
          setProducts={setProducts}
          price={price}
          setPrice={setPrice}
          orderByState={orderByState}
          setOrderByState={setOrderByState}
          category={category}
          setCategory={setCategory}
        />
      </Hidden>
      <Hidden desktop>
        <SearchPage
          categoryMobile={category}
          setCategoryMobile={setCategory}
          eventsFound={eventsFound}
          date={date}
          setDate={setDate}
          products={products}
          setProducts={setProducts}
          price={price}
          setPrice={setPrice}
          orderByState={orderByState}
          setOrderByState={setOrderByState}
        />
      </Hidden>
    </>
  )
}
