import { ProfileTemplate } from '../../../components/templates/ProfileTemplate/ProfileTemplate'
import { EditProfile } from '../../shared/EditProfile/EditProfile'

export const Profile = () => {
  return (
    <ProfileTemplate active="account">
      <EditProfile open={true} />
    </ProfileTemplate>
  )
}
