import { AxiosResponse } from 'axios'
import api from '../../config/api'

export const downloadPurchasePDF = async (purchaseId: string) => {
  const { data }: AxiosResponse<any> = await api.get(
    `/api/purchase/orders/download/${purchaseId}`,
    {
      responseType: 'blob'
    }
  )
  const pdfBlob = new Blob([data], { type: 'application/pdf' })
  const blobUrl = window.URL.createObjectURL(pdfBlob)
  const link = document.createElement('a')
  link.href = blobUrl
  link.setAttribute('download', 'Ingressos.pdf')
  link.click()
  link.remove()
  URL.revokeObjectURL(blobUrl)
}
