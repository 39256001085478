export const messageHelper = {
  yup: {
    error: {
      corporateNameIsMandatory: 'Razão social é obrigatória',
      shortName: 'Nome deve ter mínimo 2 caracteres',
      longName: 'Nome deve ter menos do que 55 caracteres',
      nameIsMandatory: 'Nome é obrigatório.',
      shortLastName: 'Sobrenome deve ter mínimo 2 caracteres',
      longLastName: 'Sobrenome deve ter menos do que 55 caracteres',
      lastNameIsMandatory: 'Sobrenome é obrigatório.',
      cnpjIsMandatory: 'CNPJ é obrigatório.',
      cnpjIsInvalid: 'CNPJ inválido.',
      documentIsMandatory: 'CPF é obrigatório.',
      documentIsInvalid: 'CPF é inválido.',
      emailIsMandatory: 'Email é obrigatório.',
      emailIsInvalid: 'Digite um email válido.',
      phoneIsMandatory: 'Telefone é obrigatório.',
      phoneIsInvalid: 'Telefone inválido.',
      cepIsMandatory: 'CEP é obrigatório.',
      cepIsInvalid: 'CEP inválido.',
      streetIsMandatory: 'Rua é obrigatória.',
      districtIsMandatory: 'Bairro é obrigatório.',
      cityIsMandatory: 'Cidade é obrigatória.',
      cityIsInvalid: 'Digite uma cidade válida.',
      stateIsMandatory: 'Estado é obrigatório.',
      stateIsInvalid: 'Digite um estado válido.',
      shortAddress: 'Endereço deve ter mínimo 5 caracteres',
      longAddress: 'Endereço deve ter menos do que 255 caracteres',
      shortComplement: 'Complemento deve ter mínimo 5 caracteres',
      longComplement: 'Complemento deve ter menos do que 255 caracteres',
      passwordConfirmation: 'Senhas não coincidem'
    }
  },
  modal: {
    message: {
      reviewData: 'Por favor, revise seus dados de pagamento',
      ticketUnavailable: 'Ingresso não disponível',
      integrationError: 'Não foi possível finalizar a compra'
    },
    error: {
      reviewData:
        'Sua compra não foi finalizada devido a algum problema na autorização do pagamento. Você pode ter preenchido algum dado incorretamente ou pode ter ocorrido algum problema com a operadora, no caso de compra com cartão.',
      ticketUnavailable:
        'Infelizmente esse ingresso não se encontra disponível para compra, tente novamente.',
      integrationError: 'Ocorreu um erro interno, tente novamente'
    }
  }
}
