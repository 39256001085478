import {
  Column,
  Container as GridContainer,
  Row
} from '../../components/grid/GridSystem'
import * as S from './Footer.styles'
import logo from '../../assets/logo.svg'

import { Hidden, Paragraph } from 'applaus-ui-kit'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <Hidden mobile>
      <div style={{ paddingBottom: '250px' }} />
      <S.Container>
        <GridContainer as={'footer'}>
          <Row gutter={1}>
            <Column desktop={2} tablet={2}>
              <img src={logo} alt="applaus-logo" className="applaus-logo" />
            </Column>
            <Hidden tablet>
              <Column desktop={1} />
            </Hidden>
            <Column desktop={2} tablet={2}>
              <ul className="links">
                <li className="li">
                  <Link
                    style={{ cursor: 'pointer' }}
                    to={{
                      pathname:
                        'https://play.google.com/store/apps/details?id=br.com.applaus'
                    }}
                    className="link"
                    target="_blank">
                    <Paragraph variant="regular" className="link-text">
                      Baixe nosso app
                    </Paragraph>
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: 'https://applausplataforma.zendesk.com/hc/pt-br'
                    }}
                    className="link"
                    target="_blank">
                    <Paragraph variant="regular" className="link-text">
                      Nosso FAQ
                    </Paragraph>
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `${process.env.REACT_APP_AUTH_WEB_URL}/terms`
                    }}
                    className="link"
                    target="_blank">
                    <Paragraph variant="regular" className="link-text">
                      Termos de Uso
                    </Paragraph>
                  </Link>
                </li>
              </ul>
            </Column>
            <Hidden tablet>
              <Column desktop={1} />
            </Hidden>
            <Column desktop={3} tablet={2}>
              <ul className="links">
                <li>
                  <Link
                    to={{
                      pathname: `${process.env.REACT_APP_AUTH_WEB_URL}/terms`
                    }}
                    className="link"
                    target="_blank">
                    <Paragraph variant="regular" className="link-text">
                      Política de Privacidade
                    </Paragraph>
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: 'https://applausplataforma.zendesk.com/hc/pt-br'
                    }}
                    className="link"
                    target="_blank">
                    <Paragraph variant="regular" className="link-text">
                      Fale Conosco
                    </Paragraph>
                  </Link>
                </li>
              </ul>
            </Column>
            <Column desktop={3} tablet={2}>
              <S.SocialLinksWrapper>
                <Paragraph variant="regular" className="title">
                  Siga Nossas Páginas:
                </Paragraph>
                <ul className="social-links">
                  <li>
                    <Link
                      to={{
                        pathname: ' https://www.facebook.com/applaus.com.br'
                      }}
                      className="link"
                      target="_blank">
                      <S.FacebookIcon />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: 'https://www.instagram.com/applaus.br/'
                      }}
                      className="link"
                      target="_blank">
                      <S.InstagramIcon />
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to={{
                        pathname: 'https://google.com.br'
                      }}
                      className="link"
                      target="_blank">
                      <S.YoutubeIcon />
                    </Link>
                  </li> */}
                </ul>
              </S.SocialLinksWrapper>
            </Column>
          </Row>
          <Row>
            <Column desktop={12} tablet={8}>
              <Paragraph variant="regular" className="naming-rights">
                2022 | Todos os direitos reservados.
              </Paragraph>
              <Paragraph variant="regular" className="naming-rights">
                43.994.612/0001-50 | APPLAUS SERVICOS DE INTERNET LTDA
              </Paragraph>
            </Column>
          </Row>
        </GridContainer>
      </S.Container>
    </Hidden>
  )
}
