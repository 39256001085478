import styled, { css } from 'styled-components'

export const Container = styled.div<{
  isLoading: boolean
  blur?: boolean
  disableBackground: boolean
}>`
  ${({ isLoading, blur, disableBackground }) => css`
    display: ${isLoading ? 'flex' : 'none'};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    ${blur &&
    css`
      backdrop-filter: blur(8px);
    `};
    background: ${disableBackground ? 'transparent' : 'rgba(0, 0, 0, 0.5)'};
    align-items: center;
    justify-content: center;
    z-index: 1000;
  `}
`
