import { theme, AlertModal } from 'applaus-ui-kit'
import { useEffect } from 'react'
import GlobalStyle from './styles/global'
import { Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import Routes from './routes/routes'
import history from './routes/services/history'
import { useCookies } from 'react-cookie'
import { decode } from 'jsonwebtoken'
import { useDispatch, useSelector } from 'react-redux'
import {
  authenticationFailed,
  authenticationRequest,
  setUserAction
} from './redux/User/actions'
import { getCustomerDataService } from './api/services/profile/getCustomerDataService'
import { ReduxState } from './redux/rootReducer'
import { getCustomerInfo } from './api/services/profile/getCustomerInfo'
import { clearModalState } from './redux/Modal/Actions/actionsModal'
import { Loading } from './components/loading/Loading'
import { listCoursesRequestAction } from './redux/Content/actions'
import { purchasesOrderCustomerRequest } from './redux/Purchases/actions'
import { clearLoading, setLoading } from './redux/Loading/actions'

function App() {
  const [getCookie] = useCookies(['applaus-auth-customer'])
  const removeCookie = useCookies(['applaus-auth-customer'])[2]
  const dispatch = useDispatch()

  const { UserReducer, ModalReducer, LoadingReducer } = useSelector(
    (state: ReduxState) => state
  )

  const getCustomerData = async (token: string) => {
    return await getCustomerDataService(token)
  }

  const initialRequest = async () => {
    if (getCookie['applaus-auth-customer']) {
      const decoded = decode(getCookie['applaus-auth-customer'].accessToken)
      try {
        if (decoded && typeof decoded !== 'string') {
          const data = await getCustomerData(
            getCookie['applaus-auth-customer'].accessToken
          )
          if (data) {
            dispatch(
              setUserAction({
                ...data,

                accessToken: getCookie['applaus-auth-customer'].accessToken,
                refreshToken: getCookie['applaus-auth-customer'].refreshToken,
                isAuthenticated: true,
                loading: false
              })
            )
          }

          if (history.location.pathname.includes('/purchases')) {
            dispatch(purchasesOrderCustomerRequest())
          }
        }
        removeCookie('applaus-auth-customer', {
          path: '/',
          domain: process.env.REACT_APP_DOMAIM
        })
        dispatch(clearLoading())
      } catch (e) {
        dispatch(authenticationFailed())
        dispatch(clearLoading())
      }
    } else if (UserReducer.accessToken !== '') {
      try {
        const data = await getCustomerInfo()
        dispatch(
          setUserAction({ ...data, loading: false, isAuthenticated: true })
        )
        dispatch(clearLoading())
      } catch (e) {
        dispatch(authenticationFailed())
        dispatch(clearLoading())
      }
    }
  }

  useEffect(() => {
    if (!history.location.pathname.includes('/mobileCheckout')) {
      dispatch(authenticationRequest())
      dispatch(setLoading())
      dispatch(listCoursesRequestAction())
      initialRequest()
    }
  }, [])

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <Routes />

        <GlobalStyle />
        <AlertModal
          open={ModalReducer.open}
          variant={ModalReducer.variant}
          message={ModalReducer.message}
          description={ModalReducer.description}
          buttonText={ModalReducer.buttonText ? ModalReducer.buttonText : 'Ok'}
          click={
            ModalReducer.click
              ? ModalReducer.click
              : () => dispatch(clearModalState())
          }
        />

        <Loading
          isLoading={LoadingReducer.isLoading}
          blur={LoadingReducer.blur}
        />
      </ThemeProvider>
    </Router>
  )
}

export default App
