import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
  `}
`

export const Image = styled.img`
  ${() => css`
    width: 100%;
    max-height: 208px;

    @media (min-width: 768px) and (max-width: 1170px) {
      max-height: 375px;
    }

    @media (min-width: 1171px) {
      width: 100%;
      max-height: 498px;
    }
  `}
`
