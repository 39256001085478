import * as Yup from 'yup'
import { messageHelper } from '../../../../utils/messageHelper'

export const validationSchema = Yup.object().shape({
  zipCode: Yup.string()
    .required(messageHelper.yup.error.cepIsMandatory)
    .test('validateZipCode', messageHelper.yup.error.cepIsInvalid, (value) => {
      if (value && value.replace(/[^0-9]/g, '').trim().length === 8) {
        return true
      } else return false
    }),
  number: Yup.string()
    .required('Número é obrigatório.')
    .test('validateNumber', 'Número não pode ser 0', (value) => {
      if (value && value === '0') {
        return false
      } else return true
    }),
  address: Yup.string()
    .required('Endereço é obrigatório.')
    .min(5, messageHelper.yup.error.shortAddress)
    .max(255, messageHelper.yup.error.longAddress),
  complement: Yup.string()
    .nullable()
    .test(
      'validateMinComplement',
      messageHelper.yup.error.shortComplement,
      (value) => {
        if (value && value.length < 5) {
          return false
        } else return true
      }
    )
    .test(
      'validateMaxComplement',
      messageHelper.yup.error.longComplement,
      (value) => {
        if (value && value.length > 255) {
          return false
        } else return true
      }
    ),
  city: Yup.string().required(messageHelper.yup.error.cityIsMandatory),
  state: Yup.string().required(messageHelper.yup.error.stateIsMandatory)
})
