import * as S from './Ticket.styles'

import moment from 'moment'

type TicketProps = {
  titleEvent: string
  locationEvent: string
  dateEvent: string[]
  click?: () => void
  opacity?: boolean
  image: string
}

export const MobileTicketCard = ({
  click,
  opacity,
  dateEvent,
  locationEvent,
  titleEvent,
  image
}: TicketProps) => {
  const renderDate = () => {
    const dates = dateEvent.map((d) => moment(d))
    const maxDate = moment.max(dates)
    const minDate = moment.min(dates)
    return (
      <S.Date>
        <S.IconDate />
        <S.TextDate>
          {dateEvent.length > 1
            ? minDate.format('DD/MM/YY') + ' - ' + maxDate.format('DD/MM/YY')
            : minDate.format('DD/MM/YY')}
        </S.TextDate>
      </S.Date>
    )
  }

  return (
    <S.Container opacity={opacity} onClick={click}>
      <S.CoverUrl src={image} />
      <S.Content>
        <S.TitleContent>{titleEvent}</S.TitleContent>
        <S.LocationBox>
          <S.IconLocation />
          <S.TextLocation>{locationEvent}</S.TextLocation>
        </S.LocationBox>
        <S.DatesBox>{renderDate()}</S.DatesBox>
      </S.Content>
    </S.Container>
  )
}
