import { combineReducers } from 'redux'
import { TicketReducer } from './Tickets/reducer'
import { ContentsReducer } from './Content/reducer'
import { UserReducer } from './User/reducer'
import { EventReducer } from './Event/reducer'
import { CheckoutReducer } from './Checkout/reducer'
import { ModalReducer } from './Modal/reducer'
import { PurchasesReducer } from './Purchases/reducer'
import { LoadingReducer } from './Loading/reducer'
import { LocationReducer } from './Location/reducer'

const rootReducer = combineReducers({
  TicketReducer,
  ContentsReducer,
  UserReducer,
  EventReducer,
  CheckoutReducer,
  ModalReducer,
  PurchasesReducer,
  LoadingReducer,
  LocationReducer
})

export type ReduxState = ReturnType<typeof rootReducer>

export default rootReducer
