import { applyMiddleware, createStore, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer, { ReduxState } from './rootReducer'

import rootSaga from './rootSaga'

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
  key: 'applaus-customer',
  whitelist: ['UserReducer', 'EventReducer', 'CheckoutReducer'],
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const enhancer: any = applyMiddleware(sagaMiddleware)

const store: Store<ReduxState> = createStore(
  persistedReducer,
  composeWithDevTools(enhancer)
)

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export default store
