import * as S from './DesktopAppBar.styles'
import logo from '../../../assets/logo.png'
import { Button, Paragraph } from 'applaus-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/rootReducer'
import { Menu } from '../../menu/Menu/Menu'
import { MenuItem } from '../../menu/MenuItem/MenuItem'
import { logoutAction } from '../../../redux/User/actions'
import { useEffect, useRef, useState } from 'react'
import history from '../../../routes/services/history'
import { clearCheckoutState } from '../../../redux/Checkout/actions'
import { clearEvent } from '../../../redux/Event/actions'
import { Column, Container, Row } from '../../grid/GridSystem'
import { LocationModal } from '../../locationModal/LocationModal'
import { formatPrice } from '../../../utils/formatPrice'

type Props = {
  hideSearch?: boolean
}

export const DesktopAppBar = ({ hideSearch = false }: Props) => {
  const { firstName, lastName, location, cashBackBalanceCents } = useSelector(
    (state: ReduxState) => state.UserReducer
  )
  const [showMenu, setShowMenu] = useState(false)
  const [locationModalState, setLocationModalState] = useState(false)
  const [searchText, setsearchText] = useState('')
  const dispatch = useDispatch()

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref !== null) {
      const listener = (event: any) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        setShowMenu(false)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }
  }, [ref, setShowMenu])

  const handleClosemodal = () => {
    setLocationModalState(false)
  }

  return (
    <>
      <Container>
        <Row>
          <Column desktop={12}>
            <S.Content>
              <S.Logo
                src={logo}
                onClick={() => {
                  dispatch(clearCheckoutState())
                  dispatch(clearEvent())
                  history.push('/')
                }}
              />
              {hideSearch === false ? (
                <S.Wrapper>
                  <S.InputWrapper>
                    <S.SearchIcon />
                    <S.StyledInput
                      placeholder="Procure eventos, cursos, livros..."
                      onChange={(e) => setsearchText(e.target.value)}
                      onKeyPress={(e) =>
                        e.key === 'Enter' &&
                        history.push(`/search?name=${searchText}`)
                      }
                    />
                  </S.InputWrapper>

                  <S.LocationWrapper
                    onClick={() => setLocationModalState(true)}>
                    <S.LocationIcon />
                    <Paragraph variant="regular">
                      {location ?? 'Escolha sua cidade'}
                    </Paragraph>
                    <S.ChevronDownIcon className="chevronIcon" />
                  </S.LocationWrapper>
                </S.Wrapper>
              ) : undefined}
              <S.ButtonContainer style={{ maxHeight: '60px' }}>
                {firstName !== '' ? (
                  <S.DropDown ref={ref}>
                    <S.DropDownContent>
                      <div>
                        <Paragraph
                          variant="large"
                          type="bold"
                          className="userName"
                          onClick={() => setShowMenu((prev) => !prev)}>
                          {firstName} {lastName}
                        </Paragraph>
                        <S.ChevronDownIcon
                          onClick={() => setShowMenu((prev) => !prev)}
                          className="chevronIcon"
                        />
                      </div>
                      {cashBackBalanceCents !== undefined && (
                        <div>
                          <S.CashBackIcon />
                          <div className="cashback-value">
                            {formatPrice(cashBackBalanceCents / 100)}
                          </div>
                        </div>
                      )}
                    </S.DropDownContent>
                    <Menu id="menu" open={showMenu}>
                      <MenuItem
                        onClick={() => history.push('/profile')}
                        Icon={S.UserIcon}>
                        Minha Conta
                      </MenuItem>
                      <MenuItem
                        onClick={() => dispatch(logoutAction())}
                        Icon={S.LogoutIcon}>
                        Sair
                      </MenuItem>
                    </Menu>
                  </S.DropDown>
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      (window.location.href =
                        process.env.REACT_APP_AUTH_WEB_URL ?? '')
                    }>
                    Fazer login
                  </Button>
                )}
              </S.ButtonContainer>
            </S.Content>
          </Column>
        </Row>
      </Container>
      <div style={{ height: '24px' }} />
      <LocationModal
        isOpen={locationModalState}
        handleClose={handleClosemodal}
      />
    </>
  )
}
