import { useWindowSize } from '../../hooks/useWindowSize'
import { TabletAppBar } from './Tablet/TabletAppBar'
import { DesktopAppBar } from './Desktop/DesktopAppBar'

type Props = {
  hideSearch?: boolean
}

export const AppBar = ({ hideSearch = false }: Props) => {
  const windowSize = useWindowSize()

  return windowSize.isTablet ? (
    <TabletAppBar />
  ) : (
    <DesktopAppBar hideSearch={hideSearch} />
  )
}
