import styled, { css } from 'styled-components'
import { Button } from 'applaus-ui-kit'

export const Container = styled.div`
  ${({ theme }) => css`
    @media (max-width: 767px) {
      ${({ theme }) => css`
        padding: 0 ${theme.spacing.stack.xxs};
        display: flex;
        flex-direction: column;
      `}
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    .buttonBox {
      margin: ${theme.spacing.stack.xxxs} 0 ${theme.spacing.stack.xs} 0;
      display: flex;
      flex-direction: column;
      div:nth-child(1) {
        margin-bottom: ${theme.spacing.stack.xs};
      }
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      padding: ${theme.spacing.stack.md} ${theme.spacing.stack.xxxl};
    }
  `}
`

export const Form = styled.form`
  ${({ theme }) => css`
    .creditCardRow {
      @media (min-width: 768px) {
        display: flex;
        div:first-child {
          margin-right: ${theme.spacing.stack.xxxs};
        }
      }
    }
  `}
`

export const TextTermsOfUse = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.sm};
    a {
      cursor: pointer;
      color: #8601c9;
      font-weight: ${theme.font.weight.semiBold};
      font-size: ${theme.font.size.sm};
      text-decoration: none;
    }
  `}
`

export const ContentBack = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${theme.spacing.stack.sm};
    @media (max-width: 767px) {
      justify-content: end;
    }
  `}
`

export const ButtonStyled = styled(Button)`
  background: #2ecc71;
`
