import produce from 'immer'
import { Reducer } from 'redux'
import { ModalState, ModalActions } from './types'

const initialState: ModalState = {
  open: false,
  message: '',
  variant: 'error'
}

export const ModalReducer: Reducer<ModalState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ModalActions.SET_MODAL_STATE:
      return produce(state, (draftState) => {
        draftState.open = action.payload.open
        draftState.message = action.payload.message
        draftState.variant = action.payload.variant
        draftState.description = action.payload.description
        draftState.buttonText = action.payload.buttonText
        draftState.click = action.payload.click
      })
    case ModalActions.CLEAR_MODAL_STATE:
      return initialState
    default:
      return state
  }
}
