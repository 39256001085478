import styled, { css } from 'styled-components'

import { ReactComponent as CashBackFile } from '../../../../assets/icons/cashback.svg'

import { Button } from 'applaus-ui-kit'

export const CashBackIcon = styled(CashBackFile)`
  ${({ theme }) => css`
    min-width: 32px;
    min-height: 32px;
    margin-right: 24px;
    fill: ${theme.color.base.nth1};
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    .installments-options {
      margin-top: ${theme.spacing.stack.sm};
    }
    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.xxs} 0;
      display: flex;
      flex-direction: column;
      min-width: 320px;

      .button-back {
        margin-bottom: ${theme.spacing.stack.xxxs};
      }

      .gridContainer {
        margin-right: 0;
        margin-left: 0;
      }
    }
  `}
`

export const ContentForm = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
  `}
`

export const customStyles = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '8px',
    cursor: 'pointer',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: () => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    svg: {
      fill: '#8601C9'
    }
  }),
  singleValue: () => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600
  }),
  placeholder: () => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}

export const Wrapper = styled.div`
  ${({ theme }) => css`
    .spacing {
      margin-bottom: ${theme.spacing.stack.xxs};
    }
  `}
`

export const Form = styled.form`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.stack.xxs};
    .creditCardRow {
      @media (min-width: 768px) {
        display: flex;
        div:first-child {
          margin-right: ${theme.spacing.stack.xxxs};
        }
      }
    }
  `}
`

export const TextTermsOfUse = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.sm};
    a {
      cursor: pointer;
      color: #8601c9;
      font-weight: ${theme.font.weight.semiBold};
      font-size: ${theme.font.size.sm};
      text-decoration: none;
    }
    @media (max-width: 767px) {
      font-weight: ${theme.font.weight.regular};
      font-size: ${theme.font.size.xxs};
      a {
        cursor: pointer;
        color: #8601c9;
        font-weight: ${theme.font.weight.semiBold};
        font-size: ${theme.font.size.xxs};
        text-decoration: none;
      }
    }
  `}
`

export const ContentBack = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${theme.spacing.stack.sm};
    @media (max-width: 767px) {
      justify-content: end;
    }
  `}
`

export const ButtonStyled = styled(Button)`
  ${({ theme }) => css`
    background: #2ecc71;
  `}
`

export const CashBackComponent = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.base.nth4};
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    justify-content: space-between;
    padding: 14px ${theme.spacing.stack.xxxs} 14px ${theme.spacing.stack.xs};
    border-radius: 6px;
    align-items: center;
    margin: ${theme.spacing.stack.xxxs} 0;
    > div {
      display: flex;
      align-items: center;
      > p {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
        text-align: left;
        white-space: nowrap;
        max-width: 80%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    > span {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      text-align: left;
      color: ${theme.color.brand.primary.nth1};
      margin-left: auto;
      margin-right: ${theme.spacing.inline.xxxs};
    }
    @media (max-width: 767px) {
      padding: 6px ${theme.spacing.stack.xxxs} 6px ${theme.spacing.stack.xxxs};
      margin-bottom: ${theme.spacing.stack.xxxs};
      > div {
        > p {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
        }
      }
    }
  `}
`

export const CashbackValueWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.xs};
    .cashback-title {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      color: ${theme.color.base.nth3};
    }
    > span {
      font-size: 27px;
      font-style: normal;
      font-weight: 600;
      line-height: 41px;
      text-align: left;
      color: ${theme.color.brand.primary.nth1};
    }

    .edit-cashback-value {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: ${theme.color.brand.primary.nth1};
      margin-top: ${theme.spacing.stack.small};

      cursor: pointer;
      width: max-content;
      padding: 8px 8px 8px 0;
    }

    .available-box {
      display: flex;
    }

    .available-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      margin-bottom: ${theme.spacing.stack.xxxs};
    }

    .available-value {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: ${theme.color.brand.primary.nth1};
      margin-left: ${theme.spacing.inline.nano};
    }
  `}
`

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: stretch;
    > input {
      width: 100%;
      padding: 8px 24px 8px 16px;
      color: #39383a;
      background: ${theme.color.base.nth4};
      border: 1px solid transparent;
      border-radius: 6px;
      font-size: 16px;
    }
    .desktop-button {
      border-radius: 0px 6px 6px 0px;
    }

    @media (min-width: 1171px) {
      margin-bottom: ${theme.spacing.stack.xxxs};
      > input {
        border-radius: 6px 0px 0px 6px;
        padding: 7px 24px 7px 16px;
      }
    }
  `}
`

export const Option = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  > span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
  > p {
    margin-left: 8px;
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
    text-align: center;
  }
  transition: 0.3s ease;
  cursor: pointer;
  &:hover {
    background: lightgrey;
  }

  @media (max-width: 767px) {
    > span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }

    > p {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
    }
  }
`
