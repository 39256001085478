import { useEffect, useMemo, useState } from 'react'
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import { Heading, Paragraph, Button, Hidden } from 'applaus-ui-kit'
import moment from 'moment'
import ReactPixel from 'react-facebook-pixel'

import { useWindowSize } from 'hooks/useWindowSize'

import * as S from './DesktopPurchaseSummary.styles'
import eventImage from 'assets/mock/desktopPurchaseSumary.png'
import { ParticipantData } from './components/ParticipantData/ParticipantData'
import { Column, Row } from 'components/grid/GridSystem'
import BackButton from 'components/backButton/BackButton'
import { GeneralLayout } from 'components/templates/GeneralLayout/GeneralLayout'

import history from 'routes/services/history'
import { ticketPurchase } from 'api/services/checkout/ticketPurchase'
import { purchaseOrder } from 'api/services/checkout/purchaseOrder'

import { ReduxState } from 'redux/rootReducer'
import { clearLoading, setLoading } from 'redux/Loading/actions'
import { setCheckoutState } from 'redux/Checkout/actions'
import { prevStepAction, profileEventAction } from 'redux/Event/actions'
import {
  clearModalState,
  setModalState
} from 'redux/Modal/Actions/actionsModal'

import { calculateTotalPrice } from 'utils/calculateTotalPrice'
import { messageHelper } from 'utils/messageHelper'
import { sleep } from 'utils/sleep'
import { formatPrice } from 'utils/formatPrice'

export const DesktopPurchaseSummary = () => {
  const dispatch = useDispatch()
  const { EventReducer, CheckoutReducer } = useSelector(
    (state: ReduxState) => state
  )

  const { selectedEvent, tickets, eventData } = EventReducer
  const { coupon } = CheckoutReducer

  const [success, setSuccess] = useState(false)

  const { isDesktop } = useWindowSize()

  useEffect(() => {
    window.scrollTo(0, 0)
    console.log(eventData!.id)
    if (eventData?.pixels) {
      ReactPixel.init(eventData.pixels.facebookpixel, undefined, {
        autoConfig: true,
        debug: true
      })
    }
  }, [eventData?.pixels])

  const totalPriceReduce = useMemo(
    () => calculateTotalPrice(tickets, coupon),
    [coupon, tickets]
  )

  const handlePurchaseOrder = async (purchaseOrderId: string) => {
    dispatch(setLoading())
    const purchaseOrderData = await purchaseOrder(
      purchaseOrderId,
      Number(process.env.REACT_APP_REQUEST_INTERVAL)
    )
    await sleep(3000)
    if (purchaseOrderData?.status === 'SUCCESS') {
      ReactPixel.track('Free')
      dispatch(clearLoading())
      dispatch(
        setModalState({
          message: 'Compra realizada com sucesso!',
          open: true,
          variant: 'success',
          click: () => {
            history.push('/purchases')
            dispatch(clearModalState())
          }
        })
      )
    } else if (purchaseOrderData?.status === 'WAITING') {
      dispatch(clearLoading())
      dispatch(
        setModalState({
          message: 'Compra em análise.',
          open: true,
          variant: 'error',
          click: () => {
            history.push('/purchases')
            dispatch(clearModalState())
          }
        })
      )
    } else {
      dispatch(clearLoading())
      switch (purchaseOrderData?.failedCode.code) {
        case 'TICKET_UNAVAILABLE':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.ticketUnavailable,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.ticketUnavailable,
              click: () => {
                dispatch(clearModalState())
                dispatch(profileEventAction())
                history.push(`/event/${eventData?.id}`)
              }
            })
          )

        case 'PAYMENT_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.reviewData,
              open: true,
              variant: 'error',
              buttonText: 'Revisar dados',
              description: messageHelper.modal.error.reviewData,
              click: () => {
                dispatch(clearModalState())
                dispatch(profileEventAction())
                history.push(`/event/${eventData?.id}`)
              }
            })
          )

        case 'INTEGRATION_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError,
              click: () => {
                dispatch(clearModalState())
                dispatch(profileEventAction())
                history.push(`/event/${eventData?.id}`)
              }
            })
          )

        case 'BOOKING_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError,
              click: () => {
                dispatch(clearModalState())
                dispatch(profileEventAction())
                history.push(`/event/${eventData?.id}`)
              }
            })
          )

        case 'BAD_REQUEST_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError,
              click: () => {
                dispatch(clearModalState())
                dispatch(profileEventAction())
                history.push(`/event/${eventData?.id}`)
              }
            })
          )

        case 'PURCHASE_ERROR':
          return dispatch(
            setModalState({
              message: messageHelper.modal.message.integrationError,
              open: true,
              variant: 'error',
              description: messageHelper.modal.error.integrationError,
              click: () => {
                dispatch(clearModalState())
                dispatch(profileEventAction())
                history.push(`/event/${eventData?.id}`)
              }
            })
          )
      }
    }

    dispatch(clearLoading())
  }

  const buyEventFree = async () => {
    dispatch(setLoading())
    try {
      const { data } = await ticketPurchase({
        paymentMethod: {
          type: 'FREE',
          cashBackValueCents: null,
          customerCouponId: coupon?.customerCouponId
        },
        tickets: tickets.map(({ ticketInfo, id, eventItemId, seatKey }) => ({
          ticketInfo,
          ticketId: id,
          eventItemId,
          seatKey: eventData?.type === 'MAPPED' ? seatKey : undefined
        }))
      })

      if (data.status === 'WAITING' || data.status === 'SUCCESS') {
        dispatch(clearLoading())
        await handlePurchaseOrder(data.id)
      }
    } catch (err: any) {
      await sleep(3000)
      dispatch(clearLoading())
      if (err) {
        switch (err.response.data.code) {
          case 'TICKET_UNAVAILABLE':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.ticketUnavailable,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.ticketUnavailable,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'PAYMENT_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.reviewData,
                open: true,
                variant: 'error',
                buttonText: 'Revisar dados',
                description: messageHelper.modal.error.reviewData,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'INTEGRATION_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.integrationError,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.integrationError,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'BOOKING_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.integrationError,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.integrationError,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'BAD_REQUEST_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.integrationError,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.integrationError,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )

          case 'PURCHASE_ERROR':
            return dispatch(
              setModalState({
                message: messageHelper.modal.message.integrationError,
                open: true,
                variant: 'error',
                description: messageHelper.modal.error.integrationError,
                click: () => {
                  dispatch(clearModalState())
                  dispatch(profileEventAction())
                  history.push(`/event/${eventData?.id}`)
                }
              })
            )
        }
      }
    }
  }

  const handlePurchaseButtonClick = () => {
    if (totalPriceReduce === 0) {
      buyEventFree()
    } else {
      dispatch(
        setCheckoutState({
          data: {
            eventData,
            step: 'defaultCheckout',
            ticketState: tickets
          }
        })
      )
      history.push('/checkout')
    }
  }

  if (eventData) {
    return (
      <S.Container>
        <GeneralLayout hasFooter={false} hasHeader={true} headerType={'normal'}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="content">
            <Row className="row">
              <Hidden tablet>
                <Column
                  desktop={6}
                  spacing={isDesktop ? 3 : 0}
                  className="image-column">
                  <S.Image
                    src={eventImage}
                    alt={eventData.name}
                    className="image"
                  />
                </Column>
              </Hidden>
              <Column desktop={6} spacing={isDesktop ? 3 : 0} tablet={6}>
                {selectedEvent!.ticketInfo &&
                selectedEvent!.ticketInfo.length > 0 &&
                !success ? (
                  <ParticipantData setSuccess={setSuccess} />
                ) : (
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                    <S.TicketInfo>
                      <S.EventData>
                        <Heading variant="h4" className="title">
                          RESUMO DA COMPRA
                        </Heading>
                        <Heading variant="h3" className="eventName">
                          {eventData.name}
                        </Heading>
                        <div className="dateInfo">
                          <S.ClockIcon />
                          <div>
                            <Paragraph variant="regular">
                              {moment(eventData.eventItems[0].dates[0])
                                .locale('pt-br')
                                .format('ll [ás] HH[h]mm')}
                            </Paragraph>
                          </div>
                        </div>
                        <div>
                          <S.LocationIcon />

                          <Paragraph variant="regular">
                            {eventData.address.street}
                          </Paragraph>
                        </div>
                      </S.EventData>
                      <S.CustomerTicket>
                        {tickets?.map((ticket, index) => (
                          <S.TicketCard key={ticket.id + index}>
                            <div>
                              <Paragraph variant="regular" type="semiBold">
                                {ticket.name}
                              </Paragraph>
                              {/* <Paragraph variant="regular" type="semiBold">
                                {ticket.category}
                              </Paragraph> */}
                              <Paragraph variant="regular">
                                {formatPrice(ticket.totalPrice)}
                              </Paragraph>
                            </div>
                            <S.TicketAmmount>
                              <Paragraph variant="regular">ingresso</Paragraph>
                              <Paragraph variant="regular" type="semiBold">
                                {ticket.totalPrice === 0
                                  ? 'Grátis'
                                  : formatPrice(ticket.totalPrice)}
                              </Paragraph>
                            </S.TicketAmmount>
                          </S.TicketCard>
                        ))}
                      </S.CustomerTicket>

                      {coupon && (
                        <S.CouponCard>
                          <div>
                            <p className="coupon-title">Cupom de desconto</p>
                            <p className="coupon-code">{coupon.name}</p>
                          </div>
                          <p className="coupon-discount">
                            {coupon.valueType === 'CENTS'
                              ? formatPrice(coupon.value / 100)
                              : `- ${coupon.value} %`}
                          </p>
                        </S.CouponCard>
                      )}

                      <Paragraph
                        className="total"
                        variant="large"
                        type="bold">{`Total: ${
                        totalPriceReduce === 0
                          ? 'Grátis'
                          : formatPrice(totalPriceReduce)
                      }`}</Paragraph>
                      <S.Actions>
                        <BackButton
                          title="Voltar"
                          onClick={() => dispatch(prevStepAction('showEvent'))}
                          className="backbutton"
                        />
                        <Button
                          variant={'contained'}
                          color={'primary'}
                          size={'medium'}
                          fullWidth={false}
                          onClick={handlePurchaseButtonClick}>
                          Comprar
                        </Button>
                      </S.Actions>
                    </S.TicketInfo>
                  </motion.div>
                )}
              </Column>
            </Row>
          </motion.div>
        </GeneralLayout>
      </S.Container>
    )
  } else return <p />
}
