import api from '../../config/api'
import { AxiosResponse } from 'axios'

export type CustomerResponse = {
  id: string
  userId: string
  firstName: string
  lastName: string
  email: string
  document: string
  phone: string
  cashBackBalanceCents: number
  address?: {
    city: string
    complement: string
    country: string
    createdAt: string
    creatorId?: string
    customerId: string
    eventId?: string
    id: string
    latitude?: string
    longitude?: string
    number: number
    seatMapId: string
    state: string
    street: string
    updatedAt?: string
    zipCode: string
  }
  createdAt: string
  updatedAt?: string
}

export const getCustomerInfo = async () => {
  const { data }: AxiosResponse<CustomerResponse> = await api.get(
    '/api/customers/info'
  )
  return data
}
