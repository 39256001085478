import { ReactElement } from 'react'
import { MenuItemProps } from '../MenuItem/MenuItem'

import * as S from './Menu.styles'

type MenuProps = {
  id: string
  open: boolean
  children: ReactElement<MenuItemProps> | Array<ReactElement<MenuItemProps>>
}

export const Menu = ({ children, open, id }: MenuProps) => {
  return (
    <S.Menu id={id} open={open}>
      <div className="menu-content">{children}</div>
    </S.Menu>
  )
}
