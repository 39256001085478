import { calculateTotalPrice } from 'utils/calculateTotalPrice'
import { CustomerTicket } from 'redux/Event/types'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { CheckoutActions, Coupon } from './types'
import { PayloadAction } from 'typesafe-actions'
import { redeemCouponService } from 'api/services/checkout/redeemCoupon'
import { redeemCouponSuccessAction } from './actions'
import { setModalState } from '../Modal/Actions/actionsModal'
import axios, { AxiosError } from 'axios'
import { ReduxState } from 'redux/rootReducer'

type Error = {
  message: string
}

function* redeemCoupon(
  action: PayloadAction<CheckoutActions.REDEEM_COUPON_REQUEST, string>
) {
  try {
    const data: Coupon = yield call(redeemCouponService, action.payload)
    const getTickets = (state: ReduxState) => state.EventReducer.tickets
    const tickets: CustomerTicket[] = yield select(getTickets)

    const totalPrice = calculateTotalPrice(tickets, data)

    if (totalPrice > 0 && totalPrice < 1) {
      yield put(
        setModalState({
          open: true,
          message:
            'O valor minimo aceito para transações no cartão de crédito ou pix é de 1 real.',
          variant: 'error',
          buttonText: 'Fechar'
        })
      )
    } else {
      yield put(redeemCouponSuccessAction(data))
      yield put(
        setModalState({
          open: true,
          message: 'Cupom resgatado com sucesso',
          variant: 'success',
          buttonText: 'Fechar'
        })
      )
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const error = err as AxiosError<Error>
      switch (error.response?.data.message) {
        case 'this coupon not valid yet':
          yield put(
            setModalState({
              open: true,
              message: 'Cupom não está válido',
              variant: 'error',
              buttonText: 'Fechar'
            })
          )
          break
        case 'coupon not found':
          yield put(
            setModalState({
              open: true,
              message: 'Cupom não encontrado',
              variant: 'error',
              buttonText: 'Fechar'
            })
          )
          break
        case 'coupon already used':
          yield put(
            setModalState({
              open: true,
              message: 'Cupom já utilizado',
              variant: 'error',
              buttonText: 'Fechar'
            })
          )
          break
      }
    }
  }
}

export const CheckoutSaga = all([
  takeLatest(CheckoutActions.REDEEM_COUPON_REQUEST, redeemCoupon)
])
