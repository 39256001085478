import { Hidden, Paragraph } from 'applaus-ui-kit'
import * as S from './EventCard.styles'

import { Event } from '../../../redux/Content/types'
import moment from 'moment'
import history from '../../../routes/services/history'
import { useWindowSize } from '../../../hooks/useWindowSize'

interface Props extends Event {
  showInfo?: boolean
}

export const EventCard = (props: Props) => {
  const { isMobile } = useWindowSize()
  const handleClickCard = (id: string) => {
    history.push(`/event/${id}`)
  }

  const renderDate = () => {
    const dates = props.eventItem.flatMap(({ dates }) =>
      dates.flatMap((date) => moment(date))
    )
    const maxDate = moment.max(dates)
    const minDate = moment.min(dates)
    return (
      <Paragraph variant="regular" className="event-info">
        {!isMobile && dates.length > 1
          ? minDate.format('DD/MM/YY') + ' - ' + maxDate.format('DD/MM/YY')
          : minDate.format('DD/MM/YY')}
      </Paragraph>
    )
  }

  return (
    <S.Container onClick={() => handleClickCard(props.id)}>
      <S.ImageWrapper>
        <Hidden mobile tablet>
          <S.EventImage
            hasInfo={props.showInfo}
            src={props.banners[0]?.desktopUrl}
          />
        </Hidden>
        <S.MobileImageWrapper desktop tablet>
          <S.EventImage
            hasInfo={props.showInfo}
            src={props.banners[0]?.mobileUrl}
          />
        </S.MobileImageWrapper>
        <Hidden desktop mobile>
          <S.EventImage
            hasInfo={props.showInfo}
            src={props.banners[0]?.tabletUrl}
          />
        </Hidden>
        {props.cashBackPercent ? (
          <S.CashBackContent>
            <p>{props.cashBackPercent}% de cashback</p>
          </S.CashBackContent>
        ) : undefined}
      </S.ImageWrapper>
      <S.EventIfonWrapper>
        <Paragraph variant="large" type="bold" className="event-name">
          {props.name}
        </Paragraph>
        <div className="icon-text-box">
          <S.LocationIcon />
          <Paragraph variant="regular" className="event-info">
            {props.address.city}{' '}
            {isMobile ? undefined : `- ${props.address.state}`}
          </Paragraph>
        </div>
        <div className="icon-text-box">
          <S.ClockIcon />
          {renderDate()}
        </div>
      </S.EventIfonWrapper>
    </S.Container>
  )
}
