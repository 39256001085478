import styled, { css } from 'styled-components'

import { ReactComponent as CashBackFile } from '../../../../assets/icons/cashback.svg'

export const CashBackIcon = styled(CashBackFile)`
  ${({ theme }) => css`
    min-width: 32px;
    min-height: 32px;
    margin-right: 24px;
    fill: ${theme.color.base.nth1};
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    min-width: 320px;

    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.xxs} 0;
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
     .descriptionCard {
      margin-top ${theme.spacing.stack.xxxs};
      margin-bottom ${theme.spacing.stack.xxxs};
    }

    .buttonCard {
      margin-bottom ${theme.spacing.stack.xxxs};
    }

    margin-bottom: ${theme.spacing.stack.xs}; 
  `}
`

export const ContentPix = styled.form`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`

export const TextWaitingPayment = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.bold};
    line-height: ${theme.spacing.stack.xxs};
  `}
`

export const QrCode = styled.img`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.xxxs} auto ${theme.spacing.stack.xxs} auto;
    min-width: 128px;
    max-width: 128px;

    @media (min-width: 768px) {
      min-width: 200px;
      max-width: 200px;
    }
  `}
`

export const TextDescription = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.xxs} 0;

    h1 {
      font-size: ${theme.font.size.xxs};
      font-weight: ${theme.font.weight.semiBold};
      line-height: ${theme.spacing.stack.xxxs};
    }

    p {
      font-size: ${theme.font.size.xxs};
      font-weight: ${theme.font.weight.regular};
      line-height: ${theme.spacing.stack.xxxs};
    }
  `}
`

export const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CashBackComponent = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.base.nth4};
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    justify-content: space-between;
    padding: 14px ${theme.spacing.stack.xxxs} 14px ${theme.spacing.stack.xs};
    border-radius: 6px;
    align-items: center;
    margin: ${theme.spacing.stack.xxxs} 0;
    > div {
      display: flex;
      align-items: center;
      > p {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
        text-align: left;
        white-space: nowrap;
        max-width: 80%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    > span {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      text-align: left;
      color: ${theme.color.brand.primary.nth1};
      margin-left: auto;
      margin-right: ${theme.spacing.inline.xxxs};
    }
    @media (max-width: 767px) {
      padding: 6px ${theme.spacing.stack.xxxs} 6px ${theme.spacing.stack.xxxs};
      margin-bottom: ${theme.spacing.stack.xxxs};
      > div {
        > p {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
        }
      }
    }
  `}
`

export const CashbackValueWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.xs};
    .cashback-title {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      color: ${theme.color.base.nth3};
    }
    > span {
      font-size: 27px;
      font-style: normal;
      font-weight: 600;
      line-height: 41px;
      text-align: left;
      color: ${theme.color.brand.primary.nth1};
    }

    .edit-cashback-value {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: ${theme.color.brand.primary.nth1};
      margin-top: ${theme.spacing.stack.small};

      cursor: pointer;
      width: max-content;
      padding: 8px 8px 8px 0;
    }

    .available-box {
      display: flex;
    }

    .available-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      margin-bottom: ${theme.spacing.stack.xxxs};
    }

    .available-value {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: ${theme.color.brand.primary.nth1};
      margin-left: ${theme.spacing.inline.nano};
    }
  `}
`

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: stretch;
    > input {
      width: 100%;
      padding: 8px 24px 8px 16px;
      color: #39383a;
      background: ${theme.color.base.nth4};
      border: 1px solid transparent;
      border-radius: 6px;
      font-size: 16px;
    }
    .desktop-button {
      border-radius: 0px 6px 6px 0px;
    }

    @media (min-width: 1171px) {
      margin-bottom: ${theme.spacing.stack.xxxs};
      > input {
        border-radius: 6px 0px 0px 6px;
        padding: 7px 24px 7px 16px;
      }
    }
  `}
`
