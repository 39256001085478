import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProfileTemplate } from '../../../components/templates/ProfileTemplate/ProfileTemplate'
import {
  purchasesInitialStateAction,
  purchasesOrderCustomerRequest
} from '../../../redux/Purchases/actions'
import { ReduxState } from '../../../redux/rootReducer'

import { Purchases as PurchasesComponent } from '../../shared/Purchases/Purchases'

export const Purchases = () => {
  const { accessToken, email } = useSelector(
    (state: ReduxState) => state.UserReducer
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (accessToken && email !== '') {
      dispatch(purchasesOrderCustomerRequest())
    }
    return () => {
      dispatch(purchasesInitialStateAction())
    }
  }, [])

  return (
    <ProfileTemplate active="purchases">
      <PurchasesComponent />
    </ProfileTemplate>
  )
}
