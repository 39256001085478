import * as Yup from 'yup'
import { messageHelper } from '../../../../utils/messageHelper'

export const UpdatePasswordValidateSchema = Yup.object({
  currentPassword: Yup.string().required('Senha atual é obrigatória'),
  newPassword: Yup.string().required('Nova senha é obrigatória'),
  confirmNewPassword: Yup.string()
    .required('Confirmação de senha é obrigatória')
    .when('newPassword', {
      is: (newPassword: string | undefined) =>
        !!(newPassword && newPassword.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        messageHelper.yup.error.passwordConfirmation
      )
    })
})
