import { ProfileTemplate } from '../../../components/templates/ProfileTemplate/ProfileTemplate'
import { CashbackHistory as CashbackHistoryComponent } from '../../shared/CashbackHistory/CashbackHistory'

export const CashbackHistory = () => {
  return (
    <ProfileTemplate active="cashbackHistory">
      <CashbackHistoryComponent />
    </ProfileTemplate>
  )
}
