import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  *{
    -webkit-tap-highlight-color: transparent; 
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: Poppins;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  }
  body, #root { 
    -webkit-font-smoothing: antialiased;
    min-height: 100vh;
  }

  button {
    cursor: pointer;
  }

      /* for desktop */
      .whatsapp_float {
          position: fixed;
          width: 60px;
          height: 60px;
          bottom: 40px;
          left: 40px;
          background-color: #25d366;
          color: #FFF;
          border-radius: 50px;
          text-align: center;
          font-size: 30px;
          box-shadow: 2px 2px 3px #999;
          z-index: 100;
      }

      .whatsapp-icon {
          margin-top: 16px;
      }

      /* for mobile */
      @media screen and (max-width: 767px) {
          .whatsapp-icon {
              margin-top: 10px;
          }

          .whatsapp_float {
              width: 40px;
              height: 40px;
              bottom: 20px;
              right: 10px;
              font-size: 22px;
          }
      }
`
