import { Hidden } from 'applaus-ui-kit'
import produce from 'immer'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  getPurchaseHistoryService,
  PurchaseHistoryResponse
} from '../../../api/services/purchaseHistory/getPurchaseHistoryService'
import { AppBar } from '../../../components/appBar/AppBar'
import { ButtonBack } from '../../../components/buttonBack/buttonBack'
import { clearLoading, setLoading } from '../../../redux/Loading/actions'
import history from '../../../routes/services/history'
import { sleep } from '../../../utils/sleep'
import { Table } from './components/table/Table'
import * as S from './PurchaseHistory.styles'

export const PurchaseHistory = () => {
  const dispatch = useDispatch()
  const [purchaseHistory, setPurchaseHistory] =
    useState<PurchaseHistoryResponse>({ items: [], total: 0 })
  const [skipRequest, setSkipRequest] = useState(10)

  const fetchPurchaseHistory = async () => {
    dispatch(setLoading())
    const data = await getPurchaseHistoryService()
    setPurchaseHistory(data)
    await sleep(3000)
    dispatch(clearLoading())
  }

  const viewMorePurchaseOrderRequest = async () => {
    dispatch(setLoading())

    setSkipRequest((prevState) => prevState + 10)
    const data = await getPurchaseHistoryService(skipRequest)

    const mappedPurchases = produce(purchaseHistory, (draft) => {
      data.items.map((item) => draft.items.push(item))
    })

    setPurchaseHistory(mappedPurchases)
    await sleep(3000)
    dispatch(clearLoading())
  }

  console.log(purchaseHistory.items.length)

  useEffect(() => {
    fetchPurchaseHistory()
  }, [])

  return (
    <S.Container>
      <Hidden mobile desktop>
        <AppBar />
      </Hidden>

      <Hidden tablet desktop>
        <div className="buttonBack">
          <ButtonBack
            title="Histórico de Compras"
            click={() => history.push('/profileTab')}
          />
        </div>
      </Hidden>

      <S.Wrapper>
        <Hidden mobile>
          <S.Title>Histórico de Compras</S.Title>
        </Hidden>
        <Table purchaseHistory={purchaseHistory} />
        {purchaseHistory &&
          (purchaseHistory?.items.length > 0 &&
          purchaseHistory.items.length < purchaseHistory.total ? (
            <S.SeeMore onClick={() => viewMorePurchaseOrderRequest()}>
              Ver mais
            </S.SeeMore>
          ) : null)}
      </S.Wrapper>
    </S.Container>
  )
}
