import { Button } from 'applaus-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'

import * as S from './OrderProcess.styles'
import { DateInfo } from '../../../Event/EventProfile/Components/DateInfo/DateInfo'

import history from 'routes/services/history'

import { useWindowSize } from 'hooks/useWindowSize'

import { setCheckoutState } from 'redux/Checkout/actions'
import { ReduxState } from 'redux/rootReducer'
import { clearEvent } from 'redux/Event/actions'
import { AppBar } from 'components/appBar/AppBar'
import {
  Container as GridContainer,
  Column,
  Row
} from 'components/grid/GridSystem'
import { useEffect } from 'react'

export const OrderProcess = () => {
  const dispatch = useDispatch()
  const { isDesktop } = useWindowSize()

  const { purchaseStatus, eventData, purchaseId } = useSelector(
    (state: ReduxState) => state.CheckoutReducer.data
  )

  useEffect(() => {
    if (eventData?.pixels) {
      ReactPixel.init(eventData?.pixels.facebookpixel, undefined, {
        autoConfig: true,
        debug: true
      })
      console.log(eventData?.pixels.facebookpixel)

      ReactPixel.track('Purchase')
    }
  }, [eventData?.pixels])

  return (
    <S.Container>
      <AppBar />

      <S.Title>
        {purchaseStatus === 'SUCCESS'
          ? 'PEDIDO EFETUADO COM SUCESSO!'
          : 'PEDIDO EM ANÁLISE!'}
      </S.Title>
      <GridContainer className="gridContainer">
        <Row>
          <Column
            desktop={6}
            tablet={8}
            spacing={isDesktop ? 2 : 0}
            className="coverUrl">
            <S.CoverUrl src={eventData?.banners[0].desktopUrl} />
          </Column>
          <Column
            desktop={6}
            tablet={8}
            spacing={isDesktop ? 2 : 0}
            className="ticketBox">
            <S.ShowTicket>
              <S.EventName>{eventData?.name}</S.EventName>
              <S.DataBox>
                <DateInfo
                  dates={eventData!.eventItems.flatMap(({ dates }) => dates)}
                />
              </S.DataBox>
              <S.LocationBox>
                <S.IconLocation />
                <div>
                  <S.TitleLocation>Evento presencial em</S.TitleLocation>
                  <S.PurpleTitleLocation>
                    {eventData?.address.street}
                  </S.PurpleTitleLocation>
                </div>
              </S.LocationBox>
              <div className="buttonBox">
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => {
                    history.push(`/tickets/${purchaseId}`)
                    dispatch(clearEvent())

                    dispatch(
                      setCheckoutState({
                        data: {
                          step: 'defaultCheckout'
                        }
                      })
                    )
                  }}>
                  Ver Ingressos
                </Button>
              </div>
            </S.ShowTicket>
          </Column>
        </Row>
      </GridContainer>
    </S.Container>
  )
}
