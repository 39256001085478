import { AxiosResponse } from 'axios'
import api from '../../config/api'

type PurchaseHistory = {
  id: string
  status: 'APPROVED' | 'PROCESSING' | 'FAILED'
  totalPrice: number
  description: string
  lastCardNumbers: number
  cashBackDiscountCents: number | null
  brand: string
  paymentMethod: string
  createdAt: string
  updatedAt: string
  customerId: string
  purchaseOrderId: string
  customer: {
    id: string
    firstName: string
    lastName: string
    email: string
    document: string
    userId: string
    type: string
    groups: []
    createdAt: string
    updatedAt: string
    phone: string
    customField: {
      externalId: string
    }
  }
}

export type PurchaseHistoryResponse = {
  items: PurchaseHistory[]
  total: number
}

export const getPurchaseHistoryService = async (skip?: number) => {
  const { data }: AxiosResponse<PurchaseHistoryResponse> = await api.get(
    skip ? `/api/payments?take=10&skip=${skip}` : '/api/payments'
  )

  return data
}
