import styled, { css } from 'styled-components'

import { ReactComponent as ChevronDownFile } from '../../../../../assets/icons/chevron-down-filled.svg'

export const ChevronDownIcon = styled(ChevronDownFile)`
  stroke: #6c01a3;
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  right: 8px;
`

export const Price = styled.div`
  position: relative;
`

export const Button = styled.button`
  ${({ theme }) => css`
    outline: none;
    border: none;
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxs}
      ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};
    height: max-content;
    margin: 0 ${theme.spacing.stack.small};
    border-radius: ${theme.border.radius.light};
    background: #f4f4f4;
    transition: 0.7s ease;
    position: relative;
    > p {
      color: #39383a;
      margin-right: ${theme.spacing.stack.small};
    }
    &:hover {
      background: #8601c9;
      > p {
        transition: 0.3s ease;
        color: white;
      }
      > svg {
        transition: 0.3s ease;
        transform: translateY(-40%);
        stroke: white;
      }
    }
  `}
`

export const PriceDropdown = styled.div<{ isOpen: boolean }>`
  ${({ theme, isOpen }) => css`
    background: white;
    opacity: ${isOpen ? 1 : 0};
    pointer-events: ${isOpen ? 'all' : 'none'};
    transition: 0.3s ease;
    position: absolute;
    top: 98%;
    z-index: 888;
    left: 0;
    display: flex;
    width: max-content;
    flex-wrap: wrap;
    margin: 0 ${theme.spacing.stack.small};
    padding: ${theme.spacing.stack.xxxs};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    > div {
      display: flex;
      > p {
        margin-left: ${theme.spacing.stack.small};
      }
    }
    > div:first-child {
      margin-bottom: ${theme.spacing.stack.xxxs};
    }
    > button {
      margin-top: ${theme.spacing.stack.small};
    }
  `}
`

export const Options = styled.button<{ isActive?: boolean }>`
  ${({ theme, isActive }) => css`
    width: 100%;
    outline: none;
    border: none;
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};
    height: max-content;
    text-align: center;
    border-radius: ${theme.border.radius.light};
    background: ${isActive ? '#8601c9' : '#dfdfdf'};
    transition: 0.7s ease;
    position: relative;
    font-weight: bold;
    white-space: nowrap;
    > p {
      color: ${isActive ? 'white' : '#39383a'};
    }

    &:hover {
      background: #8601c9;
      > p {
        color: white;
        transition: 0.7s ease;
      }
    }
  `}
`
