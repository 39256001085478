import * as S from './CourseSection.styles'
import { useSwipeable } from 'react-swipeable'
import { ConfigurationOptions } from 'react-swipeable/dist/types'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/rootReducer'
import { Paragraph } from 'applaus-ui-kit'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { CourseCard } from '../CourseCard/CourseCard'

export const Courses = () => {
  const { courses } = useSelector((state: ReduxState) => state.ContentsReducer)
  const { isDesktop } = useWindowSize()

  const config: ConfigurationOptions = {
    delta: 10,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true,
    rotationAngle: 0
  }

  const handlersCourses = useSwipeable({
    onSwiping: (e) => {
      const cardBox = document.getElementById('card-box')
      if (cardBox) {
        if (e.dir === 'Left') {
          cardBox.scrollTo({
            left: cardBox.scrollLeft + e.absX,
            behavior: 'smooth'
          })
        } else if (e.dir === 'Right') {
          cardBox.scrollTo({
            left: cardBox.scrollLeft - e.absX,
            behavior: 'smooth'
          })
        }
      }
    },
    ...config
  })

  if (courses.length > 0) {
    return (
      <S.Container>
        {isDesktop ? (
          <S.CourseWrapper {...handlersCourses} id="card-box">
            <Paragraph variant="large" type="bold">
              Cursos
            </Paragraph>
            <S.CourseBox>
              {courses.map((course, index) => (
                <CourseCard key={course.id} {...course} />
              ))}
            </S.CourseBox>
          </S.CourseWrapper>
        ) : (
          <S.CourseWrapper>
            <Paragraph variant="large" type="bold">
              Cursos
            </Paragraph>
            <S.CourseBox>
              {courses.map((course, index) => (
                <CourseCard key={course.id} {...course} />
              ))}
            </S.CourseBox>
          </S.CourseWrapper>
        )}
      </S.Container>
    )
  } else return <></>
}
