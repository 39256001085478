import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    cursor: pointer;
    margin: ${theme.spacing.stack.xxxs} 0;
    > p {
      margin-left: ${theme.spacing.inline.small};
      white-space: nowrap;
    }
  `}
`
