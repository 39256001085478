import styled, { css } from 'styled-components'

export type MenuProps = {
  open: boolean
}

export const Menu = styled.menu<MenuProps>`
  ${({ open, theme }) => css`
    position: relative;
    display: ${open ? 'inline-block' : 'none'};
    .menu-content {
      position: absolute;
      left: 0;
      transform: translate(-100%, +10%);
      background-color: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 0 ${theme.spacing.stack.xxs};
      z-index: 1;
    }
  `}
`
