import { Reducer } from "redux"

const INITIAL_STATE = [
    {
        "nome": "Abadia de Goiás",
        "latitude": -16.7573,
        "longitude": -49.4412
    },
    {
        "nome": "Abadia dos Dourados",
        "latitude": -18.4831,
        "longitude": -47.3916
    },
    {
        "nome": "Abadiânia",
        "latitude": -16.197,
        "longitude": -48.7057
    },
    {
        "nome": "Abaeté",
        "latitude": -19.1551,
        "longitude": -45.4444
    },
    {
        "nome": "Abaetetuba",
        "latitude": -1.72183,
        "longitude": -48.8788
    },
    {
        "nome": "Abaiara",
        "latitude": -7.34588,
        "longitude": -39.0416
    },
    {
        "nome": "Abaíra",
        "latitude": -13.2488,
        "longitude": -41.6619
    },
    {
        "nome": "Abaré",
        "latitude": -8.72073,
        "longitude": -39.1162
    },
    {
        "nome": "Abatiá",
        "latitude": -23.3049,
        "longitude": -50.3133
    },
    {
        "nome": "Abdon Batista",
        "latitude": -27.6126,
        "longitude": -51.0233
    },
    {
        "nome": "Abel Figueiredo",
        "latitude": -4.95333,
        "longitude": -48.3933
    },
    {
        "nome": "Abelardo Luz",
        "latitude": -26.5716,
        "longitude": -52.3229
    },
    {
        "nome": "Abre Campo",
        "latitude": -20.2996,
        "longitude": -42.4743
    },
    {
        "nome": "Abreu e Lima",
        "latitude": -7.90072,
        "longitude": -34.8984
    },
    {
        "nome": "Abreulândia",
        "latitude": -9.62101,
        "longitude": -49.1518
    },
    {
        "nome": "Acaiaca",
        "latitude": -20.359,
        "longitude": -43.1439
    },
    {
        "nome": "Açailândia",
        "latitude": -4.94714,
        "longitude": -47.5004
    },
    {
        "nome": "Acajutiba",
        "latitude": -11.6575,
        "longitude": -38.0197
    },
    {
        "nome": "Acará",
        "latitude": -1.95383,
        "longitude": -48.1985
    },
    {
        "nome": "Acarape",
        "latitude": -4.22083,
        "longitude": -38.7055
    },
    {
        "nome": "Acaraú",
        "latitude": -2.88769,
        "longitude": -40.1183
    },
    {
        "nome": "Acari",
        "latitude": -6.4282,
        "longitude": -36.6347
    },
    {
        "nome": "Acauã",
        "latitude": -8.21954,
        "longitude": -41.0831
    },
    {
        "nome": "Aceguá",
        "latitude": -31.8665,
        "longitude": -54.1615
    },
    {
        "nome": "Acopiara",
        "latitude": -6.08911,
        "longitude": -39.448
    },
    {
        "nome": "Acorizal",
        "latitude": -15.194,
        "longitude": -56.3632
    },
    {
        "nome": "Acrelândia",
        "latitude": -9.82581,
        "longitude": -66.8972
    },
    {
        "nome": "Acreúna",
        "latitude": -17.396,
        "longitude": -50.3749
    },
    {
        "nome": "Açu",
        "latitude": -5.58362,
        "longitude": -36.914
    },
    {
        "nome": "Açucena",
        "latitude": -19.0671,
        "longitude": -42.5419
    },
    {
        "nome": "Adamantina",
        "latitude": -21.682,
        "longitude": -51.0737
    },
    {
        "nome": "Adelândia",
        "latitude": -16.4127,
        "longitude": -50.1657
    },
    {
        "nome": "Adolfo",
        "latitude": -21.2325,
        "longitude": -49.6451
    },
    {
        "nome": "Adrianópolis",
        "latitude": -24.6606,
        "longitude": -48.9922
    },
    {
        "nome": "Adustina",
        "latitude": -10.5437,
        "longitude": -38.1113
    },
    {
        "nome": "Afogados da Ingazeira",
        "latitude": -7.74312,
        "longitude": -37.631
    },
    {
        "nome": "Afonso Bezerra",
        "latitude": -5.49229,
        "longitude": -36.5075
    },
    {
        "nome": "Afonso Cláudio",
        "latitude": -20.0778,
        "longitude": -41.1261
    },
    {
        "nome": "Afonso Cunha",
        "latitude": -4.13631,
        "longitude": -43.3275
    },
    {
        "nome": "Afrânio",
        "latitude": -8.51136,
        "longitude": -41.0095
    },
    {
        "nome": "Afuá",
        "latitude": -0.154874,
        "longitude": -50.3861
    },
    {
        "nome": "Agrestina",
        "latitude": -8.45966,
        "longitude": -35.9447
    },
    {
        "nome": "Agricolândia",
        "latitude": -5.79676,
        "longitude": -42.6664
    },
    {
        "nome": "Agrolândia",
        "latitude": -27.4087,
        "longitude": -49.822
    },
    {
        "nome": "Agronômica",
        "latitude": -27.2662,
        "longitude": -49.708
    },
    {
        "nome": "Água Azul do Norte",
        "latitude": -6.79053,
        "longitude": -50.4791
    },
    {
        "nome": "Água Boa",
        "latitude": -17.9914,
        "longitude": -42.3806
    },
    {
        "nome": "Água Boa",
        "latitude": -14.051,
        "longitude": -52.1601
    },
    {
        "nome": "Água Branca",
        "latitude": -5.88856,
        "longitude": -42.637
    },
    {
        "nome": "Água Branca",
        "latitude": -7.51144,
        "longitude": -37.6357
    },
    {
        "nome": "Água Branca",
        "latitude": -9.262,
        "longitude": -37.938
    },
    {
        "nome": "Água Clara",
        "latitude": -20.4452,
        "longitude": -52.879
    },
    {
        "nome": "Água Comprida",
        "latitude": -20.0576,
        "longitude": -48.1069
    },
    {
        "nome": "Água Doce",
        "latitude": -26.9985,
        "longitude": -51.5528
    },
    {
        "nome": "Água Doce do Maranhão",
        "latitude": -2.84048,
        "longitude": -42.1189
    },
    {
        "nome": "Água Doce do Norte",
        "latitude": -18.5482,
        "longitude": -40.9854
    },
    {
        "nome": "Água Fria",
        "latitude": -11.8618,
        "longitude": -38.7639
    },
    {
        "nome": "Água Fria de Goiás",
        "latitude": -14.9778,
        "longitude": -47.7823
    },
    {
        "nome": "Água Limpa",
        "latitude": -18.0771,
        "longitude": -48.7603
    },
    {
        "nome": "Água Nova",
        "latitude": -6.20351,
        "longitude": -38.2941
    },
    {
        "nome": "Água Preta",
        "latitude": -8.70609,
        "longitude": -35.5263
    },
    {
        "nome": "Água Santa",
        "latitude": -28.1672,
        "longitude": -52.031
    },
    {
        "nome": "Aguaí",
        "latitude": -22.0572,
        "longitude": -46.9735
    },
    {
        "nome": "Aguanil",
        "latitude": -20.9439,
        "longitude": -45.3915
    },
    {
        "nome": "Águas Belas",
        "latitude": -9.11125,
        "longitude": -37.1226
    },
    {
        "nome": "Águas da Prata",
        "latitude": -21.9319,
        "longitude": -46.7176
    },
    {
        "nome": "Águas de Chapecó",
        "latitude": -27.0754,
        "longitude": -52.9808
    },
    {
        "nome": "Águas de Lindóia",
        "latitude": -22.4733,
        "longitude": -46.6314
    },
    {
        "nome": "Águas de Santa Bárbara",
        "latitude": -22.8812,
        "longitude": -49.2421
    },
    {
        "nome": "Águas de São Pedro",
        "latitude": -22.5977,
        "longitude": -47.8734
    },
    {
        "nome": "Águas Formosas",
        "latitude": -17.0802,
        "longitude": -40.9384
    },
    {
        "nome": "Águas Frias",
        "latitude": -26.8794,
        "longitude": -52.8568
    },
    {
        "nome": "Águas Lindas de Goiás",
        "latitude": -15.7617,
        "longitude": -48.2816
    },
    {
        "nome": "Águas Mornas",
        "latitude": -27.6963,
        "longitude": -48.8243
    },
    {
        "nome": "Águas Vermelhas",
        "latitude": -15.7431,
        "longitude": -41.4571
    },
    {
        "nome": "Agudo",
        "latitude": -29.6447,
        "longitude": -53.2515
    },
    {
        "nome": "Agudos",
        "latitude": -22.4694,
        "longitude": -48.9863
    },
    {
        "nome": "Agudos do Sul",
        "latitude": -25.9899,
        "longitude": -49.3343
    },
    {
        "nome": "Águia Branca",
        "latitude": -18.9846,
        "longitude": -40.7437
    },
    {
        "nome": "Aguiar",
        "latitude": -7.0918,
        "longitude": -38.1681
    },
    {
        "nome": "Aguiarnópolis",
        "latitude": -6.55409,
        "longitude": -47.4702
    },
    {
        "nome": "Aimorés",
        "latitude": -19.5007,
        "longitude": -41.0746
    },
    {
        "nome": "Aiquara",
        "latitude": -14.1269,
        "longitude": -39.8937
    },
    {
        "nome": "Aiuaba",
        "latitude": -6.57122,
        "longitude": -40.1178
    },
    {
        "nome": "Aiuruoca",
        "latitude": -21.9736,
        "longitude": -44.6042
    },
    {
        "nome": "Ajuricaba",
        "latitude": -28.2342,
        "longitude": -53.7757
    },
    {
        "nome": "Alagoa",
        "latitude": -22.171,
        "longitude": -44.6413
    },
    {
        "nome": "Alagoa Grande",
        "latitude": -7.03943,
        "longitude": -35.6206
    },
    {
        "nome": "Alagoa Nova",
        "latitude": -7.05377,
        "longitude": -35.7591
    },
    {
        "nome": "Alagoinha",
        "latitude": -6.94657,
        "longitude": -35.5332
    },
    {
        "nome": "Alagoinha",
        "latitude": -8.4665,
        "longitude": -36.7788
    },
    {
        "nome": "Alagoinha do Piauí",
        "latitude": -7.00039,
        "longitude": -40.9282
    },
    {
        "nome": "Alagoinhas",
        "latitude": -12.1335,
        "longitude": -38.4208
    },
    {
        "nome": "Alambari",
        "latitude": -23.5503,
        "longitude": -47.898
    },
    {
        "nome": "Albertina",
        "latitude": -22.2018,
        "longitude": -46.6139
    },
    {
        "nome": "Alcântara",
        "latitude": -2.39574,
        "longitude": -44.4062
    },
    {
        "nome": "Alcântaras",
        "latitude": -3.58537,
        "longitude": -40.5479
    },
    {
        "nome": "Alcantil",
        "latitude": -7.73668,
        "longitude": -36.0511
    },
    {
        "nome": "Alcinópolis",
        "latitude": -18.3255,
        "longitude": -53.7042
    },
    {
        "nome": "Alcobaça",
        "latitude": -17.5195,
        "longitude": -39.2036
    },
    {
        "nome": "Aldeias Altas",
        "latitude": -4.62621,
        "longitude": -43.4689
    },
    {
        "nome": "Alecrim",
        "latitude": -27.6579,
        "longitude": -54.7649
    },
    {
        "nome": "Alegre",
        "latitude": -20.758,
        "longitude": -41.5382
    },
    {
        "nome": "Alegrete",
        "latitude": -29.7902,
        "longitude": -55.7949
    },
    {
        "nome": "Alegrete do Piauí",
        "latitude": -7.24196,
        "longitude": -40.8566
    },
    {
        "nome": "Alegria",
        "latitude": -27.8345,
        "longitude": -54.0557
    },
    {
        "nome": "Além Paraíba",
        "latitude": -21.8797,
        "longitude": -42.7176
    },
    {
        "nome": "Alenquer",
        "latitude": -1.94623,
        "longitude": -54.7384
    },
    {
        "nome": "Alexandria",
        "latitude": -6.40533,
        "longitude": -38.0142
    },
    {
        "nome": "Alexânia",
        "latitude": -16.0834,
        "longitude": -48.5076
    },
    {
        "nome": "Alfenas",
        "latitude": -21.4256,
        "longitude": -45.9477
    },
    {
        "nome": "Alfredo Chaves",
        "latitude": -20.6396,
        "longitude": -40.7543
    },
    {
        "nome": "Alfredo Marcondes",
        "latitude": -21.9527,
        "longitude": -51.414
    },
    {
        "nome": "Alfredo Vasconcelos",
        "latitude": -21.1535,
        "longitude": -43.7718
    },
    {
        "nome": "Alfredo Wagner",
        "latitude": -27.7001,
        "longitude": -49.3273
    },
    {
        "nome": "Algodão de Jandaíra",
        "latitude": -6.89292,
        "longitude": -36.0129
    },
    {
        "nome": "Alhandra",
        "latitude": -7.42977,
        "longitude": -34.9057
    },
    {
        "nome": "Aliança",
        "latitude": -7.60398,
        "longitude": -35.2227
    },
    {
        "nome": "Aliança do Tocantins",
        "latitude": -11.3056,
        "longitude": -48.9361
    },
    {
        "nome": "Almadina",
        "latitude": -14.7089,
        "longitude": -39.6415
    },
    {
        "nome": "Almas",
        "latitude": -11.5706,
        "longitude": -47.1792
    },
    {
        "nome": "Almeirim",
        "latitude": -1.52904,
        "longitude": -52.5788
    },
    {
        "nome": "Almenara",
        "latitude": -16.1785,
        "longitude": -40.6942
    },
    {
        "nome": "Almino Afonso",
        "latitude": -6.1475,
        "longitude": -37.7636
    },
    {
        "nome": "Almirante Tamandaré",
        "latitude": -25.3188,
        "longitude": -49.3037
    },
    {
        "nome": "Almirante Tamandaré do Sul",
        "latitude": -28.1149,
        "longitude": -52.9142
    },
    {
        "nome": "Aloândia",
        "latitude": -17.7292,
        "longitude": -49.4769
    },
    {
        "nome": "Alpercata",
        "latitude": -18.974,
        "longitude": -41.97
    },
    {
        "nome": "Alpestre",
        "latitude": -27.2502,
        "longitude": -53.0341
    },
    {
        "nome": "Alpinópolis",
        "latitude": -20.8631,
        "longitude": -46.3878
    },
    {
        "nome": "Alta Floresta",
        "latitude": -9.86674,
        "longitude": -56.0867
    },
    {
        "nome": "Alta Floresta D'Oeste",
        "latitude": -11.9283,
        "longitude": -61.9953
    },
    {
        "nome": "Altair",
        "latitude": -20.5242,
        "longitude": -49.0571
    },
    {
        "nome": "Altamira",
        "latitude": -3.20407,
        "longitude": -52.21
    },
    {
        "nome": "Altamira do Maranhão",
        "latitude": -4.16598,
        "longitude": -45.4706
    },
    {
        "nome": "Altamira do Paraná",
        "latitude": -24.7983,
        "longitude": -52.7128
    },
    {
        "nome": "Altaneira",
        "latitude": -6.99837,
        "longitude": -39.7356
    },
    {
        "nome": "Alterosa",
        "latitude": -21.2488,
        "longitude": -46.1387
    },
    {
        "nome": "Altinho",
        "latitude": -8.48482,
        "longitude": -36.0644
    },
    {
        "nome": "Altinópolis",
        "latitude": -21.0214,
        "longitude": -47.3712
    },
    {
        "nome": "Alto Alegre",
        "latitude": -21.5811,
        "longitude": -50.168
    },
    {
        "nome": "Alto Alegre",
        "latitude": 2.98858,
        "longitude": -61.3072
    },
    {
        "nome": "Alto Alegre",
        "latitude": -28.7769,
        "longitude": -52.9893
    },
    {
        "nome": "Alto Alegre do Maranhão",
        "latitude": -4.213,
        "longitude": -44.446
    },
    {
        "nome": "Alto Alegre do Pindaré",
        "latitude": -3.66689,
        "longitude": -45.8421
    },
    {
        "nome": "Alto Alegre dos Parecis",
        "latitude": -12.132,
        "longitude": -61.835
    },
    {
        "nome": "Alto Araguaia",
        "latitude": -17.3153,
        "longitude": -53.2181
    },
    {
        "nome": "Alto Bela Vista",
        "latitude": -27.4333,
        "longitude": -51.9044
    },
    {
        "nome": "Alto Boa Vista",
        "latitude": -11.6732,
        "longitude": -51.3883
    },
    {
        "nome": "Alto Caparaó",
        "latitude": -20.431,
        "longitude": -41.8738
    },
    {
        "nome": "Alto do Rodrigues",
        "latitude": -5.28186,
        "longitude": -36.75
    },
    {
        "nome": "Alto Feliz",
        "latitude": -29.3919,
        "longitude": -51.3123
    },
    {
        "nome": "Alto Garças",
        "latitude": -16.9462,
        "longitude": -53.5272
    },
    {
        "nome": "Alto Horizonte",
        "latitude": -14.1978,
        "longitude": -49.3378
    },
    {
        "nome": "Alto Jequitibá",
        "latitude": -20.4208,
        "longitude": -41.967
    },
    {
        "nome": "Alto Longá",
        "latitude": -5.25634,
        "longitude": -42.2096
    },
    {
        "nome": "Alto Paraguai",
        "latitude": -14.5137,
        "longitude": -56.4776
    },
    {
        "nome": "Alto Paraíso",
        "latitude": -26.1146,
        "longitude": -52.7469
    },
    {
        "nome": "Alto Paraíso",
        "latitude": -9.71429,
        "longitude": -63.3188
    },
    {
        "nome": "Alto Paraíso de Goiás",
        "latitude": -14.1305,
        "longitude": -47.51
    },
    {
        "nome": "Alto Paraná",
        "latitude": -23.1312,
        "longitude": -52.3189
    },
    {
        "nome": "Alto Parnaíba",
        "latitude": -9.10273,
        "longitude": -45.9303
    },
    {
        "nome": "Alto Piquiri",
        "latitude": -24.0224,
        "longitude": -53.44
    },
    {
        "nome": "Alto Rio Doce",
        "latitude": -21.0281,
        "longitude": -43.4067
    },
    {
        "nome": "Alto Rio Novo",
        "latitude": -19.0618,
        "longitude": -41.0209
    },
    {
        "nome": "Alto Santo",
        "latitude": -5.50894,
        "longitude": -38.2743
    },
    {
        "nome": "Alto Taquari",
        "latitude": -17.8241,
        "longitude": -53.2792
    },
    {
        "nome": "Altônia",
        "latitude": -23.8759,
        "longitude": -53.8958
    },
    {
        "nome": "Altos",
        "latitude": -5.03888,
        "longitude": -42.4612
    },
    {
        "nome": "Alumínio",
        "latitude": -23.5306,
        "longitude": -47.2546
    },
    {
        "nome": "Alvarães",
        "latitude": -3.22727,
        "longitude": -64.8007
    },
    {
        "nome": "Alvarenga",
        "latitude": -19.4174,
        "longitude": -41.7317
    },
    {
        "nome": "Álvares Florence",
        "latitude": -20.3203,
        "longitude": -49.9141
    },
    {
        "nome": "Álvares Machado",
        "latitude": -22.0764,
        "longitude": -51.4722
    },
    {
        "nome": "Álvaro de Carvalho",
        "latitude": -22.0841,
        "longitude": -49.719
    },
    {
        "nome": "Alvinlândia",
        "latitude": -22.4435,
        "longitude": -49.7623
    },
    {
        "nome": "Alvinópolis",
        "latitude": -20.1098,
        "longitude": -43.0535
    },
    {
        "nome": "Alvorada",
        "latitude": -12.4785,
        "longitude": -49.1249
    },
    {
        "nome": "Alvorada",
        "latitude": -29.9914,
        "longitude": -51.0809
    },
    {
        "nome": "Alvorada D'Oeste",
        "latitude": -11.3463,
        "longitude": -62.2847
    },
    {
        "nome": "Alvorada de Minas",
        "latitude": -18.7334,
        "longitude": -43.3638
    },
    {
        "nome": "Alvorada do Gurguéia",
        "latitude": -8.42418,
        "longitude": -43.777
    },
    {
        "nome": "Alvorada do Norte",
        "latitude": -14.4797,
        "longitude": -46.491
    },
    {
        "nome": "Alvorada do Sul",
        "latitude": -22.7813,
        "longitude": -51.2297
    },
    {
        "nome": "Amajari",
        "latitude": 3.64571,
        "longitude": -61.3692
    },
    {
        "nome": "Amambai",
        "latitude": -23.1058,
        "longitude": -55.2253
    },
    {
        "nome": "Amapá",
        "latitude": 2.05267,
        "longitude": -50.7957
    },
    {
        "nome": "Amapá do Maranhão",
        "latitude": -1.67524,
        "longitude": -46.0024
    },
    {
        "nome": "Amaporã",
        "latitude": -23.0943,
        "longitude": -52.7866
    },
    {
        "nome": "Amaraji",
        "latitude": -8.37691,
        "longitude": -35.4501
    },
    {
        "nome": "Amaral Ferrador",
        "latitude": -30.8756,
        "longitude": -52.2509
    },
    {
        "nome": "Amaralina",
        "latitude": -13.9236,
        "longitude": -49.2962
    },
    {
        "nome": "Amarante",
        "latitude": -6.24304,
        "longitude": -42.8433
    },
    {
        "nome": "Amarante do Maranhão",
        "latitude": -5.56913,
        "longitude": -46.7473
    },
    {
        "nome": "Amargosa",
        "latitude": -13.0215,
        "longitude": -39.602
    },
    {
        "nome": "Amaturá",
        "latitude": -3.37455,
        "longitude": -68.2005
    },
    {
        "nome": "Amélia Rodrigues",
        "latitude": -12.3914,
        "longitude": -38.7563
    },
    {
        "nome": "América Dourada",
        "latitude": -11.4429,
        "longitude": -41.439
    },
    {
        "nome": "Americana",
        "latitude": -22.7374,
        "longitude": -47.3331
    },
    {
        "nome": "Americano do Brasil",
        "latitude": -16.2514,
        "longitude": -49.9831
    },
    {
        "nome": "Américo Brasiliense",
        "latitude": -21.7288,
        "longitude": -48.1147
    },
    {
        "nome": "Américo de Campos",
        "latitude": -20.2985,
        "longitude": -49.7359
    },
    {
        "nome": "Ametista do Sul",
        "latitude": -27.3607,
        "longitude": -53.183
    },
    {
        "nome": "Amontada",
        "latitude": -3.36017,
        "longitude": -39.8288
    },
    {
        "nome": "Amorinópolis",
        "latitude": -16.6151,
        "longitude": -51.0919
    },
    {
        "nome": "Amparo",
        "latitude": -7.55502,
        "longitude": -37.0628
    },
    {
        "nome": "Amparo",
        "latitude": -22.7088,
        "longitude": -46.772
    },
    {
        "nome": "Amparo de São Francisco",
        "latitude": -10.1348,
        "longitude": -36.935
    },
    {
        "nome": "Amparo do Serra",
        "latitude": -20.5051,
        "longitude": -42.8009
    },
    {
        "nome": "Ampére",
        "latitude": -25.9168,
        "longitude": -53.4686
    },
    {
        "nome": "Anadia",
        "latitude": -9.68489,
        "longitude": -36.3078
    },
    {
        "nome": "Anagé",
        "latitude": -14.6151,
        "longitude": -41.1356
    },
    {
        "nome": "Anahy",
        "latitude": -24.6449,
        "longitude": -53.1332
    },
    {
        "nome": "Anajás",
        "latitude": -0.996811,
        "longitude": -49.9354
    },
    {
        "nome": "Anajatuba",
        "latitude": -3.26269,
        "longitude": -44.6126
    },
    {
        "nome": "Analândia",
        "latitude": -22.1289,
        "longitude": -47.6619
    },
    {
        "nome": "Anamã",
        "latitude": -3.56697,
        "longitude": -61.3963
    },
    {
        "nome": "Ananás",
        "latitude": -6.36437,
        "longitude": -48.0735
    },
    {
        "nome": "Ananindeua",
        "latitude": -1.36391,
        "longitude": -48.3743
    },
    {
        "nome": "Anápolis",
        "latitude": -16.3281,
        "longitude": -48.953
    },
    {
        "nome": "Anapu",
        "latitude": -3.46985,
        "longitude": -51.2003
    },
    {
        "nome": "Anapurus",
        "latitude": -3.67577,
        "longitude": -43.1014
    },
    {
        "nome": "Anastácio",
        "latitude": -20.4823,
        "longitude": -55.8104
    },
    {
        "nome": "Anaurilândia",
        "latitude": -22.1852,
        "longitude": -52.7191
    },
    {
        "nome": "Anchieta",
        "latitude": -26.5382,
        "longitude": -53.3319
    },
    {
        "nome": "Anchieta",
        "latitude": -20.7955,
        "longitude": -40.6425
    },
    {
        "nome": "Andaraí",
        "latitude": -12.8049,
        "longitude": -41.3297
    },
    {
        "nome": "Andirá",
        "latitude": -23.0533,
        "longitude": -50.2304
    },
    {
        "nome": "Andorinha",
        "latitude": -10.3482,
        "longitude": -39.8391
    },
    {
        "nome": "Andradas",
        "latitude": -22.0695,
        "longitude": -46.5724
    },
    {
        "nome": "Andradina",
        "latitude": -20.8948,
        "longitude": -51.3786
    },
    {
        "nome": "André da Rocha",
        "latitude": -28.6283,
        "longitude": -51.5797
    },
    {
        "nome": "Andrelândia",
        "latitude": -21.7411,
        "longitude": -44.3117
    },
    {
        "nome": "Angatuba",
        "latitude": -23.4917,
        "longitude": -48.4139
    },
    {
        "nome": "Angelândia",
        "latitude": -17.7279,
        "longitude": -42.2641
    },
    {
        "nome": "Angélica",
        "latitude": -22.1527,
        "longitude": -53.7708
    },
    {
        "nome": "Angelim",
        "latitude": -8.88429,
        "longitude": -36.2902
    },
    {
        "nome": "Angelina",
        "latitude": -27.5704,
        "longitude": -48.9879
    },
    {
        "nome": "Angical",
        "latitude": -12.0063,
        "longitude": -44.7003
    },
    {
        "nome": "Angical do Piauí",
        "latitude": -6.08786,
        "longitude": -42.74
    },
    {
        "nome": "Angico",
        "latitude": -6.39179,
        "longitude": -47.8611
    },
    {
        "nome": "Angicos",
        "latitude": -5.65792,
        "longitude": -36.6094
    },
    {
        "nome": "Angra dos Reis",
        "latitude": -23.0011,
        "longitude": -44.3196
    },
    {
        "nome": "Anguera",
        "latitude": -12.1462,
        "longitude": -39.2462
    },
    {
        "nome": "Ângulo",
        "latitude": -23.1946,
        "longitude": -51.9154
    },
    {
        "nome": "Anhanguera",
        "latitude": -18.3339,
        "longitude": -48.2204
    },
    {
        "nome": "Anhembi",
        "latitude": -22.793,
        "longitude": -48.1336
    },
    {
        "nome": "Anhumas",
        "latitude": -22.2934,
        "longitude": -51.3895
    },
    {
        "nome": "Anicuns",
        "latitude": -16.4642,
        "longitude": -49.9617
    },
    {
        "nome": "Anísio de Abreu",
        "latitude": -9.18564,
        "longitude": -43.0494
    },
    {
        "nome": "Anita Garibaldi",
        "latitude": -27.6897,
        "longitude": -51.1271
    },
    {
        "nome": "Anitápolis",
        "latitude": -27.9012,
        "longitude": -49.1316
    },
    {
        "nome": "Anori",
        "latitude": -3.74603,
        "longitude": -61.6575
    },
    {
        "nome": "Anta Gorda",
        "latitude": -28.9698,
        "longitude": -52.0102
    },
    {
        "nome": "Antas",
        "latitude": -10.3856,
        "longitude": -38.3401
    },
    {
        "nome": "Antonina",
        "latitude": -25.4386,
        "longitude": -48.7191
    },
    {
        "nome": "Antonina do Norte",
        "latitude": -6.76919,
        "longitude": -39.987
    },
    {
        "nome": "Antônio Almeida",
        "latitude": -7.21276,
        "longitude": -44.1889
    },
    {
        "nome": "Antônio Cardoso",
        "latitude": -12.4335,
        "longitude": -39.1176
    },
    {
        "nome": "Antônio Carlos",
        "latitude": -27.5191,
        "longitude": -48.766
    },
    {
        "nome": "Antônio Carlos",
        "latitude": -21.321,
        "longitude": -43.7451
    },
    {
        "nome": "Antônio Dias",
        "latitude": -19.6491,
        "longitude": -42.8732
    },
    {
        "nome": "Antônio Gonçalves",
        "latitude": -10.5767,
        "longitude": -40.2785
    },
    {
        "nome": "Antônio João",
        "latitude": -22.1927,
        "longitude": -55.9517
    },
    {
        "nome": "Antônio Martins",
        "latitude": -6.21367,
        "longitude": -37.8834
    },
    {
        "nome": "Antônio Olinto",
        "latitude": -25.9804,
        "longitude": -50.1972
    },
    {
        "nome": "Antônio Prado",
        "latitude": -28.8565,
        "longitude": -51.2883
    },
    {
        "nome": "Antônio Prado de Minas",
        "latitude": -21.0192,
        "longitude": -42.1109
    },
    {
        "nome": "Aparecida",
        "latitude": -6.78466,
        "longitude": -38.0803
    },
    {
        "nome": "Aparecida",
        "latitude": -22.8495,
        "longitude": -45.2325
    },
    {
        "nome": "Aparecida d'Oeste",
        "latitude": -20.4487,
        "longitude": -50.8835
    },
    {
        "nome": "Aparecida de Goiânia",
        "latitude": -16.8198,
        "longitude": -49.2469
    },
    {
        "nome": "Aparecida do Rio Doce",
        "latitude": -18.2941,
        "longitude": -51.1516
    },
    {
        "nome": "Aparecida do Rio Negro",
        "latitude": -9.94139,
        "longitude": -47.9638
    },
    {
        "nome": "Aparecida do Taboado",
        "latitude": -20.0873,
        "longitude": -51.0961
    },
    {
        "nome": "Aperibé",
        "latitude": -21.6252,
        "longitude": -42.1017
    },
    {
        "nome": "Apiacá",
        "latitude": -21.1523,
        "longitude": -41.5693
    },
    {
        "nome": "Apiacás",
        "latitude": -9.53981,
        "longitude": -57.4587
    },
    {
        "nome": "Apiaí",
        "latitude": -24.5108,
        "longitude": -48.8443
    },
    {
        "nome": "Apicum-Açu",
        "latitude": -1.45862,
        "longitude": -45.0864
    },
    {
        "nome": "Apiúna",
        "latitude": -27.0375,
        "longitude": -49.3885
    },
    {
        "nome": "Apodi",
        "latitude": -5.65349,
        "longitude": -37.7946
    },
    {
        "nome": "Aporá",
        "latitude": -11.6577,
        "longitude": -38.0814
    },
    {
        "nome": "Aporé",
        "latitude": -18.9607,
        "longitude": -51.9232
    },
    {
        "nome": "Apuarema",
        "latitude": -13.8542,
        "longitude": -39.7501
    },
    {
        "nome": "Apucarana",
        "latitude": -23.55,
        "longitude": -51.4635
    },
    {
        "nome": "Apuí",
        "latitude": -7.19409,
        "longitude": -59.896
    },
    {
        "nome": "Apuiarés",
        "latitude": -3.94506,
        "longitude": -39.4359
    },
    {
        "nome": "Aquidabã",
        "latitude": -10.278,
        "longitude": -37.0148
    },
    {
        "nome": "Aquidauana",
        "latitude": -20.4666,
        "longitude": -55.7868
    },
    {
        "nome": "Aquiraz",
        "latitude": -3.89929,
        "longitude": -38.3896
    },
    {
        "nome": "Arabutã",
        "latitude": -27.1587,
        "longitude": -52.1423
    },
    {
        "nome": "Araçagi",
        "latitude": -6.84374,
        "longitude": -35.3737
    },
    {
        "nome": "Araçaí",
        "latitude": -19.1955,
        "longitude": -44.2493
    },
    {
        "nome": "Aracaju",
        "latitude": -10.9091,
        "longitude": -37.0677
    },
    {
        "nome": "Araçariguama",
        "latitude": -23.4366,
        "longitude": -47.0608
    },
    {
        "nome": "Araças",
        "latitude": -12.22,
        "longitude": -38.2027
    },
    {
        "nome": "Aracati",
        "latitude": -4.55826,
        "longitude": -37.7679
    },
    {
        "nome": "Aracatu",
        "latitude": -14.428,
        "longitude": -41.4648
    },
    {
        "nome": "Araçatuba",
        "latitude": -21.2076,
        "longitude": -50.4401
    },
    {
        "nome": "Araci",
        "latitude": -11.3253,
        "longitude": -38.9584
    },
    {
        "nome": "Aracitaba",
        "latitude": -21.3446,
        "longitude": -43.3736
    },
    {
        "nome": "Araçoiaba",
        "latitude": -7.78391,
        "longitude": -35.0809
    },
    {
        "nome": "Aracoiaba",
        "latitude": -4.36872,
        "longitude": -38.8125
    },
    {
        "nome": "Araçoiaba da Serra",
        "latitude": -23.5029,
        "longitude": -47.6166
    },
    {
        "nome": "Aracruz",
        "latitude": -19.82,
        "longitude": -40.2764
    },
    {
        "nome": "Araçu",
        "latitude": -16.3563,
        "longitude": -49.6804
    },
    {
        "nome": "Araçuaí",
        "latitude": -16.8523,
        "longitude": -42.0637
    },
    {
        "nome": "Aragarças",
        "latitude": -15.8955,
        "longitude": -52.2372
    },
    {
        "nome": "Aragoiânia",
        "latitude": -16.9087,
        "longitude": -49.4476
    },
    {
        "nome": "Aragominas",
        "latitude": -7.16005,
        "longitude": -48.5291
    },
    {
        "nome": "Araguacema",
        "latitude": -8.80755,
        "longitude": -49.5569
    },
    {
        "nome": "Araguaçu",
        "latitude": -12.9289,
        "longitude": -49.8231
    },
    {
        "nome": "Araguaiana",
        "latitude": -15.7291,
        "longitude": -51.8341
    },
    {
        "nome": "Araguaína",
        "latitude": -7.19238,
        "longitude": -48.2044
    },
    {
        "nome": "Araguainha",
        "latitude": -16.857,
        "longitude": -53.0318
    },
    {
        "nome": "Araguanã",
        "latitude": -6.58225,
        "longitude": -48.6395
    },
    {
        "nome": "Araguanã",
        "latitude": -2.94644,
        "longitude": -45.6589
    },
    {
        "nome": "Araguapaz",
        "latitude": -15.0909,
        "longitude": -50.6315
    },
    {
        "nome": "Araguari",
        "latitude": -18.6456,
        "longitude": -48.1934
    },
    {
        "nome": "Araguatins",
        "latitude": -5.64659,
        "longitude": -48.1232
    },
    {
        "nome": "Araioses",
        "latitude": -2.89091,
        "longitude": -41.905
    },
    {
        "nome": "Aral Moreira",
        "latitude": -22.9385,
        "longitude": -55.6334
    },
    {
        "nome": "Aramari",
        "latitude": -12.0884,
        "longitude": -38.4969
    },
    {
        "nome": "Arambaré",
        "latitude": -30.9092,
        "longitude": -51.5046
    },
    {
        "nome": "Arame",
        "latitude": -4.88347,
        "longitude": -46.0032
    },
    {
        "nome": "Aramina",
        "latitude": -20.0882,
        "longitude": -47.7873
    },
    {
        "nome": "Arandu",
        "latitude": -23.1386,
        "longitude": -49.0487
    },
    {
        "nome": "Arantina",
        "latitude": -21.9102,
        "longitude": -44.2555
    },
    {
        "nome": "Arapeí",
        "latitude": -22.6717,
        "longitude": -44.4441
    },
    {
        "nome": "Arapiraca",
        "latitude": -9.75487,
        "longitude": -36.6615
    },
    {
        "nome": "Arapoema",
        "latitude": -7.65463,
        "longitude": -49.0637
    },
    {
        "nome": "Araponga",
        "latitude": -20.6686,
        "longitude": -42.5178
    },
    {
        "nome": "Arapongas",
        "latitude": -23.4153,
        "longitude": -51.4259
    },
    {
        "nome": "Araporã",
        "latitude": -18.4357,
        "longitude": -49.1847
    },
    {
        "nome": "Arapoti",
        "latitude": -24.1548,
        "longitude": -49.8285
    },
    {
        "nome": "Arapuã",
        "latitude": -24.3132,
        "longitude": -51.7856
    },
    {
        "nome": "Arapuá",
        "latitude": -19.0268,
        "longitude": -46.1484
    },
    {
        "nome": "Araputanga",
        "latitude": -15.4641,
        "longitude": -58.3425
    },
    {
        "nome": "Araquari",
        "latitude": -26.3754,
        "longitude": -48.7188
    },
    {
        "nome": "Arara",
        "latitude": -6.82813,
        "longitude": -35.7552
    },
    {
        "nome": "Araranguá",
        "latitude": -28.9356,
        "longitude": -49.4918
    },
    {
        "nome": "Araraquara",
        "latitude": -21.7845,
        "longitude": -48.178
    },
    {
        "nome": "Araras",
        "latitude": -22.3572,
        "longitude": -47.3842
    },
    {
        "nome": "Ararendá",
        "latitude": -4.74567,
        "longitude": -40.831
    },
    {
        "nome": "Arari",
        "latitude": -3.45214,
        "longitude": -44.7665
    },
    {
        "nome": "Araricá",
        "latitude": -29.6168,
        "longitude": -50.9291
    },
    {
        "nome": "Araripe",
        "latitude": -7.21319,
        "longitude": -40.1359
    },
    {
        "nome": "Araripina",
        "latitude": -7.57073,
        "longitude": -40.494
    },
    {
        "nome": "Araruama",
        "latitude": -22.8697,
        "longitude": -42.3326
    },
    {
        "nome": "Araruna",
        "latitude": -23.9315,
        "longitude": -52.5021
    },
    {
        "nome": "Araruna",
        "latitude": -6.54848,
        "longitude": -35.7498
    },
    {
        "nome": "Arataca",
        "latitude": -15.2651,
        "longitude": -39.419
    },
    {
        "nome": "Aratiba",
        "latitude": -27.3978,
        "longitude": -52.2975
    },
    {
        "nome": "Aratuba",
        "latitude": -4.41229,
        "longitude": -39.0471
    },
    {
        "nome": "Aratuípe",
        "latitude": -13.0716,
        "longitude": -39.0038
    },
    {
        "nome": "Arauá",
        "latitude": -11.2614,
        "longitude": -37.6201
    },
    {
        "nome": "Araucária",
        "latitude": -25.5859,
        "longitude": -49.4047
    },
    {
        "nome": "Araújos",
        "latitude": -19.9405,
        "longitude": -45.1671
    },
    {
        "nome": "Araxá",
        "latitude": -19.5902,
        "longitude": -46.9438
    },
    {
        "nome": "Arceburgo",
        "latitude": -21.359,
        "longitude": -46.9401
    },
    {
        "nome": "Arco-Íris",
        "latitude": -21.7728,
        "longitude": -50.466
    },
    {
        "nome": "Arcos",
        "latitude": -20.2863,
        "longitude": -45.5373
    },
    {
        "nome": "Arcoverde",
        "latitude": -8.41519,
        "longitude": -37.0577
    },
    {
        "nome": "Areado",
        "latitude": -21.3572,
        "longitude": -46.1421
    },
    {
        "nome": "Areal",
        "latitude": -22.2283,
        "longitude": -43.1118
    },
    {
        "nome": "Arealva",
        "latitude": -22.031,
        "longitude": -48.9135
    },
    {
        "nome": "Areia",
        "latitude": -6.96396,
        "longitude": -35.6977
    },
    {
        "nome": "Areia Branca",
        "latitude": -4.95254,
        "longitude": -37.1252
    },
    {
        "nome": "Areia Branca",
        "latitude": -10.758,
        "longitude": -37.3251
    },
    {
        "nome": "Areia de Baraúnas",
        "latitude": -7.11702,
        "longitude": -36.9404
    },
    {
        "nome": "Areial",
        "latitude": -7.04789,
        "longitude": -35.9313
    },
    {
        "nome": "Areias",
        "latitude": -22.5786,
        "longitude": -44.6992
    },
    {
        "nome": "Areiópolis",
        "latitude": -22.6672,
        "longitude": -48.6681
    },
    {
        "nome": "Arenápolis",
        "latitude": -14.4472,
        "longitude": -56.8437
    },
    {
        "nome": "Arenópolis",
        "latitude": -16.3837,
        "longitude": -51.5563
    },
    {
        "nome": "Arês",
        "latitude": -6.18831,
        "longitude": -35.1608
    },
    {
        "nome": "Argirita",
        "latitude": -21.6083,
        "longitude": -42.8292
    },
    {
        "nome": "Aricanduva",
        "latitude": -17.8666,
        "longitude": -42.5533
    },
    {
        "nome": "Arinos",
        "latitude": -15.9187,
        "longitude": -46.1043
    },
    {
        "nome": "Aripuanã",
        "latitude": -10.1723,
        "longitude": -59.4568
    },
    {
        "nome": "Ariquemes",
        "latitude": -9.90571,
        "longitude": -63.0325
    },
    {
        "nome": "Ariranha",
        "latitude": -21.1872,
        "longitude": -48.7904
    },
    {
        "nome": "Ariranha do Ivaí",
        "latitude": -24.3857,
        "longitude": -51.5839
    },
    {
        "nome": "Armação dos Búzios",
        "latitude": -22.7528,
        "longitude": -41.8846
    },
    {
        "nome": "Armazém",
        "latitude": -28.2448,
        "longitude": -49.0215
    },
    {
        "nome": "Arneiroz",
        "latitude": -6.3165,
        "longitude": -40.1653
    },
    {
        "nome": "Aroazes",
        "latitude": -6.11022,
        "longitude": -41.7822
    },
    {
        "nome": "Aroeiras",
        "latitude": -7.54473,
        "longitude": -35.7066
    },
    {
        "nome": "Aroeiras do Itaim",
        "latitude": -7.24502,
        "longitude": -41.5325
    },
    {
        "nome": "Arraial",
        "latitude": -6.65075,
        "longitude": -42.5418
    },
    {
        "nome": "Arraial do Cabo",
        "latitude": -22.9774,
        "longitude": -42.0267
    },
    {
        "nome": "Arraias",
        "latitude": -12.9287,
        "longitude": -46.9359
    },
    {
        "nome": "Arroio do Meio",
        "latitude": -29.4014,
        "longitude": -51.9557
    },
    {
        "nome": "Arroio do Padre",
        "latitude": -31.4389,
        "longitude": -52.4246
    },
    {
        "nome": "Arroio do Sal",
        "latitude": -29.5439,
        "longitude": -49.8895
    },
    {
        "nome": "Arroio do Tigre",
        "latitude": -29.3348,
        "longitude": -53.0966
    },
    {
        "nome": "Arroio dos Ratos",
        "latitude": -30.0875,
        "longitude": -51.7275
    },
    {
        "nome": "Arroio Grande",
        "latitude": -32.2327,
        "longitude": -53.0862
    },
    {
        "nome": "Arroio Trinta",
        "latitude": -26.9257,
        "longitude": -51.3407
    },
    {
        "nome": "Artur Nogueira",
        "latitude": -22.5727,
        "longitude": -47.1727
    },
    {
        "nome": "Aruanã",
        "latitude": -14.9166,
        "longitude": -51.075
    },
    {
        "nome": "Arujá",
        "latitude": -23.3965,
        "longitude": -46.32
    },
    {
        "nome": "Arvoredo",
        "latitude": -27.0748,
        "longitude": -52.4543
    },
    {
        "nome": "Arvorezinha",
        "latitude": -28.8737,
        "longitude": -52.1781
    },
    {
        "nome": "Ascurra",
        "latitude": -26.9548,
        "longitude": -49.3783
    },
    {
        "nome": "Aspásia",
        "latitude": -20.16,
        "longitude": -50.728
    },
    {
        "nome": "Assaí",
        "latitude": -23.3697,
        "longitude": -50.8459
    },
    {
        "nome": "Assaré",
        "latitude": -6.8669,
        "longitude": -39.8689
    },
    {
        "nome": "Assis",
        "latitude": -22.66,
        "longitude": -50.4183
    },
    {
        "nome": "Assis Brasil",
        "latitude": -10.9298,
        "longitude": -69.5738
    },
    {
        "nome": "Assis Chateaubriand",
        "latitude": -24.4168,
        "longitude": -53.5213
    },
    {
        "nome": "Assunção",
        "latitude": -7.07231,
        "longitude": -36.725
    },
    {
        "nome": "Assunção do Piauí",
        "latitude": -5.865,
        "longitude": -41.0389
    },
    {
        "nome": "Astolfo Dutra",
        "latitude": -21.3184,
        "longitude": -42.8572
    },
    {
        "nome": "Astorga",
        "latitude": -23.2318,
        "longitude": -51.6668
    },
    {
        "nome": "Atalaia",
        "latitude": -23.1517,
        "longitude": -52.0551
    },
    {
        "nome": "Atalaia",
        "latitude": -9.5119,
        "longitude": -36.0086
    },
    {
        "nome": "Atalaia do Norte",
        "latitude": -4.37055,
        "longitude": -70.1967
    },
    {
        "nome": "Atalanta",
        "latitude": -27.4219,
        "longitude": -49.7789
    },
    {
        "nome": "Ataléia",
        "latitude": -18.0438,
        "longitude": -41.1149
    },
    {
        "nome": "Atibaia",
        "latitude": -23.1171,
        "longitude": -46.5563
    },
    {
        "nome": "Atilio Vivacqua",
        "latitude": -20.913,
        "longitude": -41.1986
    },
    {
        "nome": "Augustinópolis",
        "latitude": -5.46863,
        "longitude": -47.8863
    },
    {
        "nome": "Augusto Corrêa",
        "latitude": -1.05109,
        "longitude": -46.6147
    },
    {
        "nome": "Augusto de Lima",
        "latitude": -18.0997,
        "longitude": -44.2655
    },
    {
        "nome": "Augusto Pestana",
        "latitude": -28.5172,
        "longitude": -53.9883
    },
    {
        "nome": "Augusto Severo (Campo Grande)",
        "latitude": -5.86206,
        "longitude": -37.3135
    },
    {
        "nome": "Áurea",
        "latitude": -27.6936,
        "longitude": -52.0505
    },
    {
        "nome": "Aurelino Leal",
        "latitude": -14.321,
        "longitude": -39.329
    },
    {
        "nome": "Auriflama",
        "latitude": -20.6836,
        "longitude": -50.5572
    },
    {
        "nome": "Aurilândia",
        "latitude": -16.6773,
        "longitude": -50.4641
    },
    {
        "nome": "Aurora",
        "latitude": -6.93349,
        "longitude": -38.9742
    },
    {
        "nome": "Aurora",
        "latitude": -27.3098,
        "longitude": -49.6295
    },
    {
        "nome": "Aurora do Pará",
        "latitude": -2.14898,
        "longitude": -47.5677
    },
    {
        "nome": "Aurora do Tocantins",
        "latitude": -12.7105,
        "longitude": -46.4076
    },
    {
        "nome": "Autazes",
        "latitude": -3.58574,
        "longitude": -59.1256
    },
    {
        "nome": "Avaí",
        "latitude": -22.1514,
        "longitude": -49.3356
    },
    {
        "nome": "Avanhandava",
        "latitude": -21.4584,
        "longitude": -49.9509
    },
    {
        "nome": "Avaré",
        "latitude": -23.1067,
        "longitude": -48.9251
    },
    {
        "nome": "Aveiro",
        "latitude": -3.60841,
        "longitude": -55.3199
    },
    {
        "nome": "Avelino Lopes",
        "latitude": -10.1345,
        "longitude": -43.9563
    },
    {
        "nome": "Avelinópolis",
        "latitude": -16.4672,
        "longitude": -49.7579
    },
    {
        "nome": "Axixá",
        "latitude": -2.83939,
        "longitude": -44.062
    },
    {
        "nome": "Axixá do Tocantins",
        "latitude": -5.61275,
        "longitude": -47.7701
    },
    {
        "nome": "Babaçulândia",
        "latitude": -7.20923,
        "longitude": -47.7613
    },
    {
        "nome": "Bacabal",
        "latitude": -4.22447,
        "longitude": -44.7832
    },
    {
        "nome": "Bacabeira",
        "latitude": -2.96452,
        "longitude": -44.3164
    },
    {
        "nome": "Bacuri",
        "latitude": -1.6965,
        "longitude": -45.1328
    },
    {
        "nome": "Bacurituba",
        "latitude": -2.71,
        "longitude": -44.7329
    },
    {
        "nome": "Bady Bassitt",
        "latitude": -20.9197,
        "longitude": -49.4385
    },
    {
        "nome": "Baependi",
        "latitude": -21.957,
        "longitude": -44.8874
    },
    {
        "nome": "Bagé",
        "latitude": -31.3297,
        "longitude": -54.0999
    },
    {
        "nome": "Bagre",
        "latitude": -1.90057,
        "longitude": -50.1987
    },
    {
        "nome": "Baía da Traição",
        "latitude": -6.69209,
        "longitude": -34.9381
    },
    {
        "nome": "Baía Formosa",
        "latitude": -6.37161,
        "longitude": -35.0033
    },
    {
        "nome": "Baianópolis",
        "latitude": -12.3016,
        "longitude": -44.5388
    },
    {
        "nome": "Baião",
        "latitude": -2.79021,
        "longitude": -49.6694
    },
    {
        "nome": "Baixa Grande",
        "latitude": -11.9519,
        "longitude": -40.169
    },
    {
        "nome": "Baixa Grande do Ribeiro",
        "latitude": -7.84903,
        "longitude": -45.219
    },
    {
        "nome": "Baixio",
        "latitude": -6.71945,
        "longitude": -38.7134
    },
    {
        "nome": "Baixo Guandu",
        "latitude": -19.5213,
        "longitude": -41.0109
    },
    {
        "nome": "Balbinos",
        "latitude": -21.8963,
        "longitude": -49.3619
    },
    {
        "nome": "Baldim",
        "latitude": -19.2832,
        "longitude": -43.9613
    },
    {
        "nome": "Baliza",
        "latitude": -16.1966,
        "longitude": -52.5393
    },
    {
        "nome": "Balneário Arroio do Silva",
        "latitude": -28.9806,
        "longitude": -49.4237
    },
    {
        "nome": "Balneário Barra do Sul",
        "latitude": -26.4597,
        "longitude": -48.6123
    },
    {
        "nome": "Balneário Camboriú",
        "latitude": -26.9926,
        "longitude": -48.6352
    },
    {
        "nome": "Balneário Gaivota",
        "latitude": -29.1527,
        "longitude": -49.5841
    },
    {
        "nome": "Balneário Piçarras",
        "latitude": -26.7639,
        "longitude": -48.6717
    },
    {
        "nome": "Balneário Pinhal",
        "latitude": -30.2419,
        "longitude": -50.2337
    },
    {
        "nome": "Balneário Rincão",
        "latitude": -28.8314,
        "longitude": -49.2352
    },
    {
        "nome": "Balsa Nova",
        "latitude": -25.5804,
        "longitude": -49.6291
    },
    {
        "nome": "Bálsamo",
        "latitude": -20.7348,
        "longitude": -49.5865
    },
    {
        "nome": "Balsas",
        "latitude": -7.53214,
        "longitude": -46.0372
    },
    {
        "nome": "Bambuí",
        "latitude": -20.0166,
        "longitude": -45.9754
    },
    {
        "nome": "Banabuiú",
        "latitude": -5.30454,
        "longitude": -38.9132
    },
    {
        "nome": "Bananal",
        "latitude": -22.6819,
        "longitude": -44.3281
    },
    {
        "nome": "Bananeiras",
        "latitude": -6.74775,
        "longitude": -35.6246
    },
    {
        "nome": "Bandeira",
        "latitude": -15.8783,
        "longitude": -40.5622
    },
    {
        "nome": "Bandeira do Sul",
        "latitude": -21.7308,
        "longitude": -46.3833
    },
    {
        "nome": "Bandeirante",
        "latitude": -26.7705,
        "longitude": -53.6413
    },
    {
        "nome": "Bandeirantes",
        "latitude": -19.9275,
        "longitude": -54.3585
    },
    {
        "nome": "Bandeirantes",
        "latitude": -23.1078,
        "longitude": -50.3704
    },
    {
        "nome": "Bandeirantes do Tocantins",
        "latitude": -7.75612,
        "longitude": -48.5836
    },
    {
        "nome": "Bannach",
        "latitude": -7.34779,
        "longitude": -50.3959
    },
    {
        "nome": "Banzaê",
        "latitude": -10.5788,
        "longitude": -38.6212
    },
    {
        "nome": "Barão",
        "latitude": -29.3725,
        "longitude": -51.4949
    },
    {
        "nome": "Barão de Antonina",
        "latitude": -23.6284,
        "longitude": -49.5634
    },
    {
        "nome": "Barão de Cocais",
        "latitude": -19.9389,
        "longitude": -43.4755
    },
    {
        "nome": "Barão de Cotegipe",
        "latitude": -27.6208,
        "longitude": -52.3798
    },
    {
        "nome": "Barão de Grajaú",
        "latitude": -6.74463,
        "longitude": -43.0261
    },
    {
        "nome": "Barão de Melgaço",
        "latitude": -16.2067,
        "longitude": -55.9623
    },
    {
        "nome": "Barão de Monte Alto",
        "latitude": -21.2444,
        "longitude": -42.2372
    },
    {
        "nome": "Barão do Triunfo",
        "latitude": -30.3891,
        "longitude": -51.7384
    },
    {
        "nome": "Baraúna",
        "latitude": -5.06977,
        "longitude": -37.6129
    },
    {
        "nome": "Baraúna",
        "latitude": -6.63484,
        "longitude": -36.2601
    },
    {
        "nome": "Barbacena",
        "latitude": -21.2214,
        "longitude": -43.7703
    },
    {
        "nome": "Barbalha",
        "latitude": -7.2982,
        "longitude": -39.3021
    },
    {
        "nome": "Barbosa",
        "latitude": -21.2657,
        "longitude": -49.9518
    },
    {
        "nome": "Barbosa Ferraz",
        "latitude": -24.0334,
        "longitude": -52.004
    },
    {
        "nome": "Barcarena",
        "latitude": -1.51187,
        "longitude": -48.6195
    },
    {
        "nome": "Barcelona",
        "latitude": -5.94284,
        "longitude": -35.9247
    },
    {
        "nome": "Barcelos",
        "latitude": -0.983373,
        "longitude": -62.9311
    },
    {
        "nome": "Bariri",
        "latitude": -22.073,
        "longitude": -48.7438
    },
    {
        "nome": "Barra",
        "latitude": -11.0859,
        "longitude": -43.1459
    },
    {
        "nome": "Barra Bonita",
        "latitude": -26.654,
        "longitude": -53.44
    },
    {
        "nome": "Barra Bonita",
        "latitude": -22.4909,
        "longitude": -48.5583
    },
    {
        "nome": "Barra D'Alcântara",
        "latitude": -6.51645,
        "longitude": -42.1146
    },
    {
        "nome": "Barra da Estiva",
        "latitude": -13.6237,
        "longitude": -41.3347
    },
    {
        "nome": "Barra de Guabiraba",
        "latitude": -8.42075,
        "longitude": -35.6585
    },
    {
        "nome": "Barra de Santa Rosa",
        "latitude": -6.71816,
        "longitude": -36.0671
    },
    {
        "nome": "Barra de Santana",
        "latitude": -7.51809,
        "longitude": -35.9913
    },
    {
        "nome": "Barra de Santo Antônio",
        "latitude": -9.4023,
        "longitude": -35.5101
    },
    {
        "nome": "Barra de São Francisco",
        "latitude": -18.7548,
        "longitude": -40.8965
    },
    {
        "nome": "Barra de São Miguel",
        "latitude": -7.74603,
        "longitude": -36.3209
    },
    {
        "nome": "Barra de São Miguel",
        "latitude": -9.83842,
        "longitude": -35.9057
    },
    {
        "nome": "Barra do Bugres",
        "latitude": -15.0702,
        "longitude": -57.1878
    },
    {
        "nome": "Barra do Chapéu",
        "latitude": -24.4722,
        "longitude": -49.0238
    },
    {
        "nome": "Barra do Choça",
        "latitude": -14.8654,
        "longitude": -40.5791
    },
    {
        "nome": "Barra do Corda",
        "latitude": -5.49682,
        "longitude": -45.2485
    },
    {
        "nome": "Barra do Garças",
        "latitude": -15.8804,
        "longitude": -52.264
    },
    {
        "nome": "Barra do Guarita",
        "latitude": -27.1927,
        "longitude": -53.7109
    },
    {
        "nome": "Barra do Jacaré",
        "latitude": -23.116,
        "longitude": -50.1842
    },
    {
        "nome": "Barra do Mendes",
        "latitude": -11.81,
        "longitude": -42.059
    },
    {
        "nome": "Barra do Ouro",
        "latitude": -7.69593,
        "longitude": -47.6776
    },
    {
        "nome": "Barra do Piraí",
        "latitude": -22.4715,
        "longitude": -43.8269
    },
    {
        "nome": "Barra do Quaraí",
        "latitude": -30.2029,
        "longitude": -57.5497
    },
    {
        "nome": "Barra do Ribeiro",
        "latitude": -30.2939,
        "longitude": -51.3014
    },
    {
        "nome": "Barra do Rio Azul",
        "latitude": -27.4069,
        "longitude": -52.4084
    },
    {
        "nome": "Barra do Rocha",
        "latitude": -14.2,
        "longitude": -39.5991
    },
    {
        "nome": "Barra do Turvo",
        "latitude": -24.759,
        "longitude": -48.5013
    },
    {
        "nome": "Barra dos Coqueiros",
        "latitude": -10.8996,
        "longitude": -37.0323
    },
    {
        "nome": "Barra Funda",
        "latitude": -27.9205,
        "longitude": -53.0391
    },
    {
        "nome": "Barra Longa",
        "latitude": -20.2869,
        "longitude": -43.0402
    },
    {
        "nome": "Barra Mansa",
        "latitude": -22.5481,
        "longitude": -44.1752
    },
    {
        "nome": "Barra Velha",
        "latitude": -26.637,
        "longitude": -48.6933
    },
    {
        "nome": "Barracão",
        "latitude": -27.6739,
        "longitude": -51.4585
    },
    {
        "nome": "Barracão",
        "latitude": -26.2502,
        "longitude": -53.6324
    },
    {
        "nome": "Barras",
        "latitude": -4.24468,
        "longitude": -42.2922
    },
    {
        "nome": "Barreira",
        "latitude": -4.28921,
        "longitude": -38.6429
    },
    {
        "nome": "Barreiras",
        "latitude": -12.1439,
        "longitude": -44.9968
    },
    {
        "nome": "Barreiras do Piauí",
        "latitude": -9.9296,
        "longitude": -45.4702
    },
    {
        "nome": "Barreirinha",
        "latitude": -2.79886,
        "longitude": -57.0679
    },
    {
        "nome": "Barreirinhas",
        "latitude": -2.75863,
        "longitude": -42.8232
    },
    {
        "nome": "Barreiros",
        "latitude": -8.81605,
        "longitude": -35.1832
    },
    {
        "nome": "Barretos",
        "latitude": -20.5531,
        "longitude": -48.5698
    },
    {
        "nome": "Barrinha",
        "latitude": -21.1864,
        "longitude": -48.1636
    },
    {
        "nome": "Barro",
        "latitude": -7.17188,
        "longitude": -38.7741
    },
    {
        "nome": "Barro Alto",
        "latitude": -11.7605,
        "longitude": -41.9054
    },
    {
        "nome": "Barro Alto",
        "latitude": -14.9658,
        "longitude": -48.9086
    },
    {
        "nome": "Barro Duro",
        "latitude": -5.81673,
        "longitude": -42.5147
    },
    {
        "nome": "Barro Preto",
        "latitude": -14.7948,
        "longitude": -39.476
    },
    {
        "nome": "Barrocas",
        "latitude": -11.5272,
        "longitude": -39.0776
    },
    {
        "nome": "Barrolândia",
        "latitude": -9.83404,
        "longitude": -48.7252
    },
    {
        "nome": "Barroquinha",
        "latitude": -3.02051,
        "longitude": -41.1358
    },
    {
        "nome": "Barros Cassal",
        "latitude": -29.0947,
        "longitude": -52.5836
    },
    {
        "nome": "Barroso",
        "latitude": -21.1907,
        "longitude": -43.972
    },
    {
        "nome": "Barueri",
        "latitude": -23.5057,
        "longitude": -46.879
    },
    {
        "nome": "Bastos",
        "latitude": -21.921,
        "longitude": -50.7357
    },
    {
        "nome": "Bataguassu",
        "latitude": -21.7159,
        "longitude": -52.4221
    },
    {
        "nome": "Batalha",
        "latitude": -4.0223,
        "longitude": -42.0787
    },
    {
        "nome": "Batalha",
        "latitude": -9.6742,
        "longitude": -37.133
    },
    {
        "nome": "Batatais",
        "latitude": -20.8929,
        "longitude": -47.5921
    },
    {
        "nome": "Batayporã",
        "latitude": -22.2944,
        "longitude": -53.2705
    },
    {
        "nome": "Baturité",
        "latitude": -4.32598,
        "longitude": -38.8812
    },
    {
        "nome": "Bauru",
        "latitude": -22.3246,
        "longitude": -49.0871
    },
    {
        "nome": "Bayeux",
        "latitude": -7.1238,
        "longitude": -34.9293
    },
    {
        "nome": "Bebedouro",
        "latitude": -20.9491,
        "longitude": -48.4791
    },
    {
        "nome": "Beberibe",
        "latitude": -4.17741,
        "longitude": -38.1271
    },
    {
        "nome": "Bela Cruz",
        "latitude": -3.04996,
        "longitude": -40.1671
    },
    {
        "nome": "Bela Vista",
        "latitude": -22.1073,
        "longitude": -56.5263
    },
    {
        "nome": "Bela Vista da Caroba",
        "latitude": -25.8842,
        "longitude": -53.6725
    },
    {
        "nome": "Bela Vista de Goiás",
        "latitude": -16.9693,
        "longitude": -48.9513
    },
    {
        "nome": "Bela Vista de Minas",
        "latitude": -19.8302,
        "longitude": -43.0922
    },
    {
        "nome": "Bela Vista do Maranhão",
        "latitude": -3.72618,
        "longitude": -45.3075
    },
    {
        "nome": "Bela Vista do Paraíso",
        "latitude": -22.9937,
        "longitude": -51.1927
    },
    {
        "nome": "Bela Vista do Piauí",
        "latitude": -7.98809,
        "longitude": -41.8675
    },
    {
        "nome": "Bela Vista do Toldo",
        "latitude": -26.2746,
        "longitude": -50.4664
    },
    {
        "nome": "Belágua",
        "latitude": -3.15485,
        "longitude": -43.5122
    },
    {
        "nome": "Belém",
        "latitude": -1.4554,
        "longitude": -48.4898
    },
    {
        "nome": "Belém",
        "latitude": -6.74261,
        "longitude": -35.5166
    },
    {
        "nome": "Belém",
        "latitude": -9.57047,
        "longitude": -36.4904
    },
    {
        "nome": "Belém de Maria",
        "latitude": -8.62504,
        "longitude": -35.8335
    },
    {
        "nome": "Belém do Brejo do Cruz",
        "latitude": -6.18515,
        "longitude": -37.5348
    },
    {
        "nome": "Belém do Piauí",
        "latitude": -7.36652,
        "longitude": -40.9688
    },
    {
        "nome": "Belém do São Francisco",
        "latitude": -8.75046,
        "longitude": -38.9623
    },
    {
        "nome": "Belford Roxo",
        "latitude": -22.764,
        "longitude": -43.3992
    },
    {
        "nome": "Belmiro Braga",
        "latitude": -21.944,
        "longitude": -43.4084
    },
    {
        "nome": "Belmonte",
        "latitude": -26.843,
        "longitude": -53.5758
    },
    {
        "nome": "Belmonte",
        "latitude": -15.8608,
        "longitude": -38.8758
    },
    {
        "nome": "Belo Campo",
        "latitude": -15.0334,
        "longitude": -41.2652
    },
    {
        "nome": "Belo Horizonte",
        "latitude": -19.9102,
        "longitude": -43.9266
    },
    {
        "nome": "Belo Jardim",
        "latitude": -8.3313,
        "longitude": -36.4258
    },
    {
        "nome": "Belo Monte",
        "latitude": -9.82272,
        "longitude": -37.277
    },
    {
        "nome": "Belo Oriente",
        "latitude": -19.2199,
        "longitude": -42.4828
    },
    {
        "nome": "Belo Vale",
        "latitude": -20.4077,
        "longitude": -44.0275
    },
    {
        "nome": "Belterra",
        "latitude": -2.63609,
        "longitude": -54.9374
    },
    {
        "nome": "Beneditinos",
        "latitude": -5.45676,
        "longitude": -42.3638
    },
    {
        "nome": "Benedito Leite",
        "latitude": -7.21037,
        "longitude": -44.5577
    },
    {
        "nome": "Benedito Novo",
        "latitude": -26.781,
        "longitude": -49.3593
    },
    {
        "nome": "Benevides",
        "latitude": -1.36183,
        "longitude": -48.2434
    },
    {
        "nome": "Benjamin Constant",
        "latitude": -4.37768,
        "longitude": -70.0342
    },
    {
        "nome": "Benjamin Constant do Sul",
        "latitude": -27.5086,
        "longitude": -52.5995
    },
    {
        "nome": "Bento de Abreu",
        "latitude": -21.2686,
        "longitude": -50.814
    },
    {
        "nome": "Bento Fernandes",
        "latitude": -5.69906,
        "longitude": -35.813
    },
    {
        "nome": "Bento Gonçalves",
        "latitude": -29.1662,
        "longitude": -51.5165
    },
    {
        "nome": "Bequimão",
        "latitude": -2.44162,
        "longitude": -44.7842
    },
    {
        "nome": "Berilo",
        "latitude": -16.9567,
        "longitude": -42.4606
    },
    {
        "nome": "Berizal",
        "latitude": -15.61,
        "longitude": -41.7432
    },
    {
        "nome": "Bernardino Batista",
        "latitude": -6.44572,
        "longitude": -38.5521
    },
    {
        "nome": "Bernardino de Campos",
        "latitude": -23.0164,
        "longitude": -49.4679
    },
    {
        "nome": "Bernardo do Mearim",
        "latitude": -4.62666,
        "longitude": -44.7608
    },
    {
        "nome": "Bernardo Sayão",
        "latitude": -7.87481,
        "longitude": -48.8893
    },
    {
        "nome": "Bertioga",
        "latitude": -23.8486,
        "longitude": -46.1396
    },
    {
        "nome": "Bertolínia",
        "latitude": -7.63338,
        "longitude": -43.9498
    },
    {
        "nome": "Bertópolis",
        "latitude": -17.059,
        "longitude": -40.58
    },
    {
        "nome": "Beruri",
        "latitude": -3.89874,
        "longitude": -61.3616
    },
    {
        "nome": "Betânia",
        "latitude": -8.26787,
        "longitude": -38.0345
    },
    {
        "nome": "Betânia do Piauí",
        "latitude": -8.14376,
        "longitude": -40.7989
    },
    {
        "nome": "Betim",
        "latitude": -19.9668,
        "longitude": -44.2008
    },
    {
        "nome": "Bezerros",
        "latitude": -8.2328,
        "longitude": -35.796
    },
    {
        "nome": "Bias Fortes",
        "latitude": -21.602,
        "longitude": -43.7574
    },
    {
        "nome": "Bicas",
        "latitude": -21.7232,
        "longitude": -43.056
    },
    {
        "nome": "Biguaçu",
        "latitude": -27.496,
        "longitude": -48.6598
    },
    {
        "nome": "Bilac",
        "latitude": -21.404,
        "longitude": -50.4746
    },
    {
        "nome": "Biquinhas",
        "latitude": -18.7754,
        "longitude": -45.4974
    },
    {
        "nome": "Birigui",
        "latitude": -21.291,
        "longitude": -50.3432
    },
    {
        "nome": "Biritiba-Mirim",
        "latitude": -23.5698,
        "longitude": -46.0407
    },
    {
        "nome": "Biritinga",
        "latitude": -11.6072,
        "longitude": -38.8051
    },
    {
        "nome": "Bituruna",
        "latitude": -26.1607,
        "longitude": -51.5518
    },
    {
        "nome": "Blumenau",
        "latitude": -26.9155,
        "longitude": -49.0709
    },
    {
        "nome": "Boa Esperança",
        "latitude": -24.2467,
        "longitude": -52.7876
    },
    {
        "nome": "Boa Esperança",
        "latitude": -21.0927,
        "longitude": -45.5612
    },
    {
        "nome": "Boa Esperança",
        "latitude": -18.5395,
        "longitude": -40.3025
    },
    {
        "nome": "Boa Esperança do Iguaçu",
        "latitude": -25.6324,
        "longitude": -53.2108
    },
    {
        "nome": "Boa Esperança do Sul",
        "latitude": -21.9918,
        "longitude": -48.3906
    },
    {
        "nome": "Boa Hora",
        "latitude": -4.41404,
        "longitude": -42.1357
    },
    {
        "nome": "Boa Nova",
        "latitude": -14.3598,
        "longitude": -40.2064
    },
    {
        "nome": "Boa Ventura",
        "latitude": -7.40982,
        "longitude": -38.2113
    },
    {
        "nome": "Boa Ventura de São Roque",
        "latitude": -24.8688,
        "longitude": -51.6276
    },
    {
        "nome": "Boa Viagem",
        "latitude": -5.11258,
        "longitude": -39.7337
    },
    {
        "nome": "Boa Vista",
        "latitude": 2.82384,
        "longitude": -60.6753
    },
    {
        "nome": "Boa Vista",
        "latitude": -7.26365,
        "longitude": -36.2357
    },
    {
        "nome": "Boa Vista da Aparecida",
        "latitude": -25.4308,
        "longitude": -53.4117
    },
    {
        "nome": "Boa Vista das Missões",
        "latitude": -27.6671,
        "longitude": -53.3102
    },
    {
        "nome": "Boa Vista do Buricá",
        "latitude": -27.6693,
        "longitude": -54.1082
    },
    {
        "nome": "Boa Vista do Cadeado",
        "latitude": -28.5791,
        "longitude": -53.8108
    },
    {
        "nome": "Boa Vista do Gurupi",
        "latitude": -1.77614,
        "longitude": -46.3002
    },
    {
        "nome": "Boa Vista do Incra",
        "latitude": -28.8185,
        "longitude": -53.391
    },
    {
        "nome": "Boa Vista do Ramos",
        "latitude": -2.97409,
        "longitude": -57.5873
    },
    {
        "nome": "Boa Vista do Sul",
        "latitude": -29.3544,
        "longitude": -51.6687
    },
    {
        "nome": "Boa Vista do Tupim",
        "latitude": -12.6498,
        "longitude": -40.6064
    },
    {
        "nome": "Boca da Mata",
        "latitude": -9.64308,
        "longitude": -36.2125
    },
    {
        "nome": "Boca do Acre",
        "latitude": -8.74232,
        "longitude": -67.3919
    },
    {
        "nome": "Bocaina",
        "latitude": -6.94124,
        "longitude": -41.3168
    },
    {
        "nome": "Bocaina",
        "latitude": -22.1365,
        "longitude": -48.523
    },
    {
        "nome": "Bocaina de Minas",
        "latitude": -22.1697,
        "longitude": -44.3972
    },
    {
        "nome": "Bocaina do Sul",
        "latitude": -27.7455,
        "longitude": -49.9423
    },
    {
        "nome": "Bocaiúva",
        "latitude": -17.1135,
        "longitude": -43.8104
    },
    {
        "nome": "Bocaiúva do Sul",
        "latitude": -25.2066,
        "longitude": -49.1141
    },
    {
        "nome": "Bodó",
        "latitude": -5.98027,
        "longitude": -36.4167
    },
    {
        "nome": "Bodocó",
        "latitude": -7.77759,
        "longitude": -39.9338
    },
    {
        "nome": "Bodoquena",
        "latitude": -20.537,
        "longitude": -56.7127
    },
    {
        "nome": "Bofete",
        "latitude": -23.1055,
        "longitude": -48.2582
    },
    {
        "nome": "Boituva",
        "latitude": -23.2855,
        "longitude": -47.6786
    },
    {
        "nome": "Bom Conselho",
        "latitude": -9.16919,
        "longitude": -36.6857
    },
    {
        "nome": "Bom Despacho",
        "latitude": -19.7386,
        "longitude": -45.2622
    },
    {
        "nome": "Bom Jardim",
        "latitude": -22.1545,
        "longitude": -42.4251
    },
    {
        "nome": "Bom Jardim",
        "latitude": -7.79695,
        "longitude": -35.5784
    },
    {
        "nome": "Bom Jardim",
        "latitude": -3.54129,
        "longitude": -45.606
    },
    {
        "nome": "Bom Jardim da Serra",
        "latitude": -28.3377,
        "longitude": -49.6373
    },
    {
        "nome": "Bom Jardim de Goiás",
        "latitude": -16.2063,
        "longitude": -52.1728
    },
    {
        "nome": "Bom Jardim de Minas",
        "latitude": -21.9479,
        "longitude": -44.1885
    },
    {
        "nome": "Bom Jesus",
        "latitude": -26.7326,
        "longitude": -52.3919
    },
    {
        "nome": "Bom Jesus",
        "latitude": -28.6697,
        "longitude": -50.4295
    },
    {
        "nome": "Bom Jesus",
        "latitude": -9.07124,
        "longitude": -44.359
    },
    {
        "nome": "Bom Jesus",
        "latitude": -5.98648,
        "longitude": -35.5792
    },
    {
        "nome": "Bom Jesus",
        "latitude": -6.81601,
        "longitude": -38.6453
    },
    {
        "nome": "Bom Jesus da Lapa",
        "latitude": -13.2506,
        "longitude": -43.4108
    },
    {
        "nome": "Bom Jesus da Penha",
        "latitude": -21.0148,
        "longitude": -46.5174
    },
    {
        "nome": "Bom Jesus da Serra",
        "latitude": -14.3663,
        "longitude": -40.5126
    },
    {
        "nome": "Bom Jesus das Selvas",
        "latitude": -4.47638,
        "longitude": -46.8641
    },
    {
        "nome": "Bom Jesus de Goiás",
        "latitude": -18.2173,
        "longitude": -49.74
    },
    {
        "nome": "Bom Jesus do Amparo",
        "latitude": -19.7054,
        "longitude": -43.4782
    },
    {
        "nome": "Bom Jesus do Araguaia",
        "latitude": -12.1706,
        "longitude": -51.5032
    },
    {
        "nome": "Bom Jesus do Galho",
        "latitude": -19.836,
        "longitude": -42.3165
    },
    {
        "nome": "Bom Jesus do Itabapoana",
        "latitude": -21.1449,
        "longitude": -41.6822
    },
    {
        "nome": "Bom Jesus do Norte",
        "latitude": -21.1173,
        "longitude": -41.6731
    },
    {
        "nome": "Bom Jesus do Oeste",
        "latitude": -26.6927,
        "longitude": -53.0967
    },
    {
        "nome": "Bom Jesus do Sul",
        "latitude": -26.1958,
        "longitude": -53.5955
    },
    {
        "nome": "Bom Jesus do Tocantins",
        "latitude": -5.0424,
        "longitude": -48.6047
    },
    {
        "nome": "Bom Jesus do Tocantins",
        "latitude": -8.96306,
        "longitude": -48.165
    },
    {
        "nome": "Bom Jesus dos Perdões",
        "latitude": -23.1356,
        "longitude": -46.4675
    },
    {
        "nome": "Bom Lugar",
        "latitude": -4.37311,
        "longitude": -45.0326
    },
    {
        "nome": "Bom Princípio",
        "latitude": -29.4856,
        "longitude": -51.3548
    },
    {
        "nome": "Bom Princípio do Piauí",
        "latitude": -3.19631,
        "longitude": -41.6403
    },
    {
        "nome": "Bom Progresso",
        "latitude": -27.5399,
        "longitude": -53.8716
    },
    {
        "nome": "Bom Repouso",
        "latitude": -22.4675,
        "longitude": -46.144
    },
    {
        "nome": "Bom Retiro",
        "latitude": -27.799,
        "longitude": -49.487
    },
    {
        "nome": "Bom Retiro do Sul",
        "latitude": -29.6071,
        "longitude": -51.9456
    },
    {
        "nome": "Bom Sucesso",
        "latitude": -21.0329,
        "longitude": -44.7537
    },
    {
        "nome": "Bom Sucesso",
        "latitude": -23.7063,
        "longitude": -51.7671
    },
    {
        "nome": "Bom Sucesso",
        "latitude": -6.44176,
        "longitude": -37.9234
    },
    {
        "nome": "Bom Sucesso de Itararé",
        "latitude": -24.3155,
        "longitude": -49.1451
    },
    {
        "nome": "Bom Sucesso do Sul",
        "latitude": -26.0731,
        "longitude": -52.8353
    },
    {
        "nome": "Bombinhas",
        "latitude": -27.1382,
        "longitude": -48.5146
    },
    {
        "nome": "Bonfim",
        "latitude": 3.36161,
        "longitude": -59.8333
    },
    {
        "nome": "Bonfim",
        "latitude": -20.3302,
        "longitude": -44.2366
    },
    {
        "nome": "Bonfim do Piauí",
        "latitude": -9.1605,
        "longitude": -42.8865
    },
    {
        "nome": "Bonfinópolis",
        "latitude": -16.6173,
        "longitude": -48.9616
    },
    {
        "nome": "Bonfinópolis de Minas",
        "latitude": -16.568,
        "longitude": -45.9839
    },
    {
        "nome": "Boninal",
        "latitude": -12.7069,
        "longitude": -41.8286
    },
    {
        "nome": "Bonito",
        "latitude": -8.47163,
        "longitude": -35.7292
    },
    {
        "nome": "Bonito",
        "latitude": -11.9668,
        "longitude": -41.2647
    },
    {
        "nome": "Bonito",
        "latitude": -1.36745,
        "longitude": -47.3066
    },
    {
        "nome": "Bonito",
        "latitude": -21.1261,
        "longitude": -56.4836
    },
    {
        "nome": "Bonito de Minas",
        "latitude": -15.3231,
        "longitude": -44.7543
    },
    {
        "nome": "Bonito de Santa Fé",
        "latitude": -7.31341,
        "longitude": -38.5133
    },
    {
        "nome": "Bonópolis",
        "latitude": -13.6329,
        "longitude": -49.8106
    },
    {
        "nome": "Boqueirão",
        "latitude": -7.487,
        "longitude": -36.1309
    },
    {
        "nome": "Boqueirão do Leão",
        "latitude": -29.3046,
        "longitude": -52.4284
    },
    {
        "nome": "Boqueirão do Piauí",
        "latitude": -4.48181,
        "longitude": -42.1212
    },
    {
        "nome": "Boquim",
        "latitude": -11.1397,
        "longitude": -37.6195
    },
    {
        "nome": "Boquira",
        "latitude": -12.8205,
        "longitude": -42.7324
    },
    {
        "nome": "Borá",
        "latitude": -22.2696,
        "longitude": -50.5409
    },
    {
        "nome": "Boracéia",
        "latitude": -22.1926,
        "longitude": -48.7808
    },
    {
        "nome": "Borba",
        "latitude": -4.39154,
        "longitude": -59.5874
    },
    {
        "nome": "Borborema",
        "latitude": -6.80199,
        "longitude": -35.6187
    },
    {
        "nome": "Borborema",
        "latitude": -21.6214,
        "longitude": -49.0741
    },
    {
        "nome": "Borda da Mata",
        "latitude": -22.2707,
        "longitude": -46.1653
    },
    {
        "nome": "Borebi",
        "latitude": -22.5728,
        "longitude": -48.9707
    },
    {
        "nome": "Borrazópolis",
        "latitude": -23.9366,
        "longitude": -51.5875
    },
    {
        "nome": "Bossoroca",
        "latitude": -28.7291,
        "longitude": -54.9035
    },
    {
        "nome": "Botelhos",
        "latitude": -21.6412,
        "longitude": -46.391
    },
    {
        "nome": "Botucatu",
        "latitude": -22.8837,
        "longitude": -48.4437
    },
    {
        "nome": "Botumirim",
        "latitude": -16.8657,
        "longitude": -43.0086
    },
    {
        "nome": "Botuporã",
        "latitude": -13.3772,
        "longitude": -42.5163
    },
    {
        "nome": "Botuverá",
        "latitude": -27.2007,
        "longitude": -49.0689
    },
    {
        "nome": "Bozano",
        "latitude": -28.3659,
        "longitude": -53.772
    },
    {
        "nome": "Braço do Norte",
        "latitude": -28.2681,
        "longitude": -49.1701
    },
    {
        "nome": "Braço do Trombudo",
        "latitude": -27.3586,
        "longitude": -49.8821
    },
    {
        "nome": "Braga",
        "latitude": -27.6173,
        "longitude": -53.7405
    },
    {
        "nome": "Bragança",
        "latitude": -1.06126,
        "longitude": -46.7826
    },
    {
        "nome": "Bragança Paulista",
        "latitude": -22.9527,
        "longitude": -46.5419
    },
    {
        "nome": "Braganey",
        "latitude": -24.8173,
        "longitude": -53.1218
    },
    {
        "nome": "Branquinha",
        "latitude": -9.23342,
        "longitude": -36.0162
    },
    {
        "nome": "Brás Pires",
        "latitude": -20.8419,
        "longitude": -43.2406
    },
    {
        "nome": "Brasil Novo",
        "latitude": -3.29792,
        "longitude": -52.534
    },
    {
        "nome": "Brasilândia",
        "latitude": -21.2544,
        "longitude": -52.0365
    },
    {
        "nome": "Brasilândia de Minas",
        "latitude": -16.9999,
        "longitude": -46.0081
    },
    {
        "nome": "Brasilândia do Sul",
        "latitude": -24.1978,
        "longitude": -53.5275
    },
    {
        "nome": "Brasilândia do Tocantins",
        "latitude": -8.38918,
        "longitude": -48.4822
    },
    {
        "nome": "Brasiléia",
        "latitude": -10.995,
        "longitude": -68.7497
    },
    {
        "nome": "Brasileira",
        "latitude": -4.1337,
        "longitude": -41.7859
    },
    {
        "nome": "Brasília",
        "latitude": -15.7795,
        "longitude": -47.9297
    },
    {
        "nome": "Brasília de Minas",
        "latitude": -16.2104,
        "longitude": -44.4299
    },
    {
        "nome": "Brasnorte",
        "latitude": -12.1474,
        "longitude": -57.9833
    },
    {
        "nome": "Braúna",
        "latitude": -21.499,
        "longitude": -50.3175
    },
    {
        "nome": "Braúnas",
        "latitude": -19.0562,
        "longitude": -42.7099
    },
    {
        "nome": "Brazabrantes",
        "latitude": -16.4281,
        "longitude": -49.3863
    },
    {
        "nome": "Brazópolis",
        "latitude": -22.4743,
        "longitude": -45.6166
    },
    {
        "nome": "Brejão",
        "latitude": -9.02915,
        "longitude": -36.566
    },
    {
        "nome": "Brejetuba",
        "latitude": -20.1395,
        "longitude": -41.2954
    },
    {
        "nome": "Brejinho",
        "latitude": -6.18566,
        "longitude": -35.3591
    },
    {
        "nome": "Brejinho",
        "latitude": -7.34694,
        "longitude": -37.2865
    },
    {
        "nome": "Brejinho de Nazaré",
        "latitude": -11.0058,
        "longitude": -48.5683
    },
    {
        "nome": "Brejo",
        "latitude": -3.67796,
        "longitude": -42.7527
    },
    {
        "nome": "Brejo Alegre",
        "latitude": -21.1651,
        "longitude": -50.1861
    },
    {
        "nome": "Brejo da Madre de Deus",
        "latitude": -8.14933,
        "longitude": -36.3741
    },
    {
        "nome": "Brejo de Areia",
        "latitude": -4.334,
        "longitude": -45.581
    },
    {
        "nome": "Brejo do Cruz",
        "latitude": -6.34185,
        "longitude": -37.4943
    },
    {
        "nome": "Brejo do Piauí",
        "latitude": -8.20314,
        "longitude": -42.8229
    },
    {
        "nome": "Brejo dos Santos",
        "latitude": -6.37065,
        "longitude": -37.8253
    },
    {
        "nome": "Brejo Grande",
        "latitude": -10.4297,
        "longitude": -36.4611
    },
    {
        "nome": "Brejo Grande do Araguaia",
        "latitude": -5.69822,
        "longitude": -48.4103
    },
    {
        "nome": "Brejo Santo",
        "latitude": -7.48469,
        "longitude": -38.9799
    },
    {
        "nome": "Brejões",
        "latitude": -13.1039,
        "longitude": -39.7988
    },
    {
        "nome": "Brejolândia",
        "latitude": -12.4815,
        "longitude": -43.9679
    },
    {
        "nome": "Breu Branco",
        "latitude": -3.77191,
        "longitude": -49.5735
    },
    {
        "nome": "Breves",
        "latitude": -1.68036,
        "longitude": -50.4791
    },
    {
        "nome": "Britânia",
        "latitude": -15.2428,
        "longitude": -51.1602
    },
    {
        "nome": "Brochier",
        "latitude": -29.5501,
        "longitude": -51.5945
    },
    {
        "nome": "Brodowski",
        "latitude": -20.9845,
        "longitude": -47.6572
    },
    {
        "nome": "Brotas",
        "latitude": -22.2795,
        "longitude": -48.1251
    },
    {
        "nome": "Brotas de Macaúbas",
        "latitude": -11.9915,
        "longitude": -42.6326
    },
    {
        "nome": "Brumadinho",
        "latitude": -20.151,
        "longitude": -44.2007
    },
    {
        "nome": "Brumado",
        "latitude": -14.2021,
        "longitude": -41.6696
    },
    {
        "nome": "Brunópolis",
        "latitude": -27.3058,
        "longitude": -50.8684
    },
    {
        "nome": "Brusque",
        "latitude": -27.0977,
        "longitude": -48.9107
    },
    {
        "nome": "Bueno Brandão",
        "latitude": -22.4383,
        "longitude": -46.3491
    },
    {
        "nome": "Buenópolis",
        "latitude": -17.8744,
        "longitude": -44.1775
    },
    {
        "nome": "Buenos Aires",
        "latitude": -7.72449,
        "longitude": -35.3182
    },
    {
        "nome": "Buerarema",
        "latitude": -14.9595,
        "longitude": -39.3028
    },
    {
        "nome": "Bugre",
        "latitude": -19.4231,
        "longitude": -42.2552
    },
    {
        "nome": "Buíque",
        "latitude": -8.61954,
        "longitude": -37.1606
    },
    {
        "nome": "Bujari",
        "latitude": -9.81528,
        "longitude": -67.955
    },
    {
        "nome": "Bujaru",
        "latitude": -1.51762,
        "longitude": -48.0381
    },
    {
        "nome": "Buri",
        "latitude": -23.7977,
        "longitude": -48.5958
    },
    {
        "nome": "Buritama",
        "latitude": -21.0661,
        "longitude": -50.1475
    },
    {
        "nome": "Buriti",
        "latitude": -3.94169,
        "longitude": -42.9179
    },
    {
        "nome": "Buriti Alegre",
        "latitude": -18.1378,
        "longitude": -49.0404
    },
    {
        "nome": "Buriti Bravo",
        "latitude": -5.83239,
        "longitude": -43.8353
    },
    {
        "nome": "Buriti de Goiás",
        "latitude": -16.1792,
        "longitude": -50.4302
    },
    {
        "nome": "Buriti do Tocantins",
        "latitude": -5.31448,
        "longitude": -48.2271
    },
    {
        "nome": "Buriti dos Lopes",
        "latitude": -3.18259,
        "longitude": -41.8695
    },
    {
        "nome": "Buriti dos Montes",
        "latitude": -5.30584,
        "longitude": -41.0933
    },
    {
        "nome": "Buriticupu",
        "latitude": -4.32375,
        "longitude": -46.4409
    },
    {
        "nome": "Buritinópolis",
        "latitude": -14.4772,
        "longitude": -46.4076
    },
    {
        "nome": "Buritirama",
        "latitude": -10.7171,
        "longitude": -43.6302
    },
    {
        "nome": "Buritirana",
        "latitude": -5.59823,
        "longitude": -47.0131
    },
    {
        "nome": "Buritis",
        "latitude": -10.1943,
        "longitude": -63.8324
    },
    {
        "nome": "Buritis",
        "latitude": -15.6218,
        "longitude": -46.4221
    },
    {
        "nome": "Buritizal",
        "latitude": -20.1911,
        "longitude": -47.7096
    },
    {
        "nome": "Buritizeiro",
        "latitude": -17.3656,
        "longitude": -44.9606
    },
    {
        "nome": "Butiá",
        "latitude": -30.1179,
        "longitude": -51.9601
    },
    {
        "nome": "Caapiranga",
        "latitude": -3.31537,
        "longitude": -61.2206
    },
    {
        "nome": "Caaporã",
        "latitude": -7.51351,
        "longitude": -34.9055
    },
    {
        "nome": "Caarapó",
        "latitude": -22.6368,
        "longitude": -54.8209
    },
    {
        "nome": "Caatiba",
        "latitude": -14.9699,
        "longitude": -40.4092
    },
    {
        "nome": "Cabaceiras",
        "latitude": -7.48899,
        "longitude": -36.287
    },
    {
        "nome": "Cabaceiras do Paraguaçu",
        "latitude": -12.5317,
        "longitude": -39.1902
    },
    {
        "nome": "Cabeceira Grande",
        "latitude": -16.0335,
        "longitude": -47.0862
    },
    {
        "nome": "Cabeceiras",
        "latitude": -15.7995,
        "longitude": -46.9265
    },
    {
        "nome": "Cabeceiras do Piauí",
        "latitude": -4.4773,
        "longitude": -42.3069
    },
    {
        "nome": "Cabedelo",
        "latitude": -6.98731,
        "longitude": -34.8284
    },
    {
        "nome": "Cabixi",
        "latitude": -13.4945,
        "longitude": -60.552
    },
    {
        "nome": "Cabo de Santo Agostinho",
        "latitude": -8.28218,
        "longitude": -35.0253
    },
    {
        "nome": "Cabo Frio",
        "latitude": -22.8894,
        "longitude": -42.0286
    },
    {
        "nome": "Cabo Verde",
        "latitude": -21.4699,
        "longitude": -46.3919
    },
    {
        "nome": "Cabrália Paulista",
        "latitude": -22.4576,
        "longitude": -49.3393
    },
    {
        "nome": "Cabreúva",
        "latitude": -23.3053,
        "longitude": -47.1362
    },
    {
        "nome": "Cabrobó",
        "latitude": -8.50548,
        "longitude": -39.3094
    },
    {
        "nome": "Caçador",
        "latitude": -26.7757,
        "longitude": -51.012
    },
    {
        "nome": "Caçapava",
        "latitude": -23.0992,
        "longitude": -45.7076
    },
    {
        "nome": "Caçapava do Sul",
        "latitude": -30.5144,
        "longitude": -53.4827
    },
    {
        "nome": "Cacaulândia",
        "latitude": -10.349,
        "longitude": -62.9043
    },
    {
        "nome": "Cacequi",
        "latitude": -29.8883,
        "longitude": -54.822
    },
    {
        "nome": "Cáceres",
        "latitude": -16.0764,
        "longitude": -57.6818
    },
    {
        "nome": "Cachoeira",
        "latitude": -12.5994,
        "longitude": -38.9587
    },
    {
        "nome": "Cachoeira Alta",
        "latitude": -18.7618,
        "longitude": -50.9432
    },
    {
        "nome": "Cachoeira da Prata",
        "latitude": -19.521,
        "longitude": -44.4544
    },
    {
        "nome": "Cachoeira de Goiás",
        "latitude": -16.6635,
        "longitude": -50.646
    },
    {
        "nome": "Cachoeira de Minas",
        "latitude": -22.3511,
        "longitude": -45.7809
    },
    {
        "nome": "Cachoeira de Pajeú",
        "latitude": -15.9688,
        "longitude": -41.4948
    },
    {
        "nome": "Cachoeira do Arari",
        "latitude": -1.01226,
        "longitude": -48.9503
    },
    {
        "nome": "Cachoeira do Piriá",
        "latitude": -1.75974,
        "longitude": -46.5459
    },
    {
        "nome": "Cachoeira do Sul",
        "latitude": -30.033,
        "longitude": -52.8928
    },
    {
        "nome": "Cachoeira dos Índios",
        "latitude": -6.91353,
        "longitude": -38.676
    },
    {
        "nome": "Cachoeira Dourada",
        "latitude": -18.4859,
        "longitude": -49.4766
    },
    {
        "nome": "Cachoeira Dourada",
        "latitude": -18.5161,
        "longitude": -49.5039
    },
    {
        "nome": "Cachoeira Grande",
        "latitude": -2.93074,
        "longitude": -44.0528
    },
    {
        "nome": "Cachoeira Paulista",
        "latitude": -22.6665,
        "longitude": -45.0154
    },
    {
        "nome": "Cachoeiras de Macacu",
        "latitude": -22.4658,
        "longitude": -42.6523
    },
    {
        "nome": "Cachoeirinha",
        "latitude": -6.1156,
        "longitude": -47.9234
    },
    {
        "nome": "Cachoeirinha",
        "latitude": -8.48668,
        "longitude": -36.2402
    },
    {
        "nome": "Cachoeirinha",
        "latitude": -29.9472,
        "longitude": -51.1016
    },
    {
        "nome": "Cachoeiro de Itapemirim",
        "latitude": -20.8462,
        "longitude": -41.1198
    },
    {
        "nome": "Cacimba de Areia",
        "latitude": -7.12128,
        "longitude": -37.1563
    },
    {
        "nome": "Cacimba de Dentro",
        "latitude": -6.6386,
        "longitude": -35.7778
    },
    {
        "nome": "Cacimbas",
        "latitude": -7.20721,
        "longitude": -37.0604
    },
    {
        "nome": "Cacimbinhas",
        "latitude": -9.40121,
        "longitude": -36.9911
    },
    {
        "nome": "Cacique Doble",
        "latitude": -27.767,
        "longitude": -51.6597
    },
    {
        "nome": "Cacoal",
        "latitude": -11.4343,
        "longitude": -61.4562
    },
    {
        "nome": "Caconde",
        "latitude": -21.528,
        "longitude": -46.6437
    },
    {
        "nome": "Caçu",
        "latitude": -18.5594,
        "longitude": -51.1328
    },
    {
        "nome": "Caculé",
        "latitude": -14.5003,
        "longitude": -42.2229
    },
    {
        "nome": "Caém",
        "latitude": -11.0677,
        "longitude": -40.432
    },
    {
        "nome": "Caetanópolis",
        "latitude": -19.2971,
        "longitude": -44.4189
    },
    {
        "nome": "Caetanos",
        "latitude": -14.3347,
        "longitude": -40.9175
    },
    {
        "nome": "Caeté",
        "latitude": -19.8826,
        "longitude": -43.6704
    },
    {
        "nome": "Caetés",
        "latitude": -8.7803,
        "longitude": -36.6268
    },
    {
        "nome": "Caetité",
        "latitude": -14.0684,
        "longitude": -42.4861
    },
    {
        "nome": "Cafarnaum",
        "latitude": -11.6914,
        "longitude": -41.4688
    },
    {
        "nome": "Cafeara",
        "latitude": -22.789,
        "longitude": -51.7142
    },
    {
        "nome": "Cafelândia",
        "latitude": -21.8031,
        "longitude": -49.6092
    },
    {
        "nome": "Cafelândia",
        "latitude": -24.6189,
        "longitude": -53.3207
    },
    {
        "nome": "Cafezal do Sul",
        "latitude": -23.9005,
        "longitude": -53.5124
    },
    {
        "nome": "Caiabu",
        "latitude": -22.0127,
        "longitude": -51.2394
    },
    {
        "nome": "Caiana",
        "latitude": -20.6956,
        "longitude": -41.9292
    },
    {
        "nome": "Caiapônia",
        "latitude": -16.9539,
        "longitude": -51.8091
    },
    {
        "nome": "Caibaté",
        "latitude": -28.2905,
        "longitude": -54.6454
    },
    {
        "nome": "Caibi",
        "latitude": -27.0741,
        "longitude": -53.2458
    },
    {
        "nome": "Caiçara",
        "latitude": -27.2791,
        "longitude": -53.4257
    },
    {
        "nome": "Caiçara",
        "latitude": -6.62115,
        "longitude": -35.4581
    },
    {
        "nome": "Caiçara do Norte",
        "latitude": -5.07091,
        "longitude": -36.0717
    },
    {
        "nome": "Caiçara do Rio do Vento",
        "latitude": -5.76541,
        "longitude": -35.9938
    },
    {
        "nome": "Caicó",
        "latitude": -6.45441,
        "longitude": -37.1067
    },
    {
        "nome": "Caieiras",
        "latitude": -23.3607,
        "longitude": -46.7397
    },
    {
        "nome": "Cairu",
        "latitude": -13.4904,
        "longitude": -39.0465
    },
    {
        "nome": "Caiuá",
        "latitude": -21.8322,
        "longitude": -51.9969
    },
    {
        "nome": "Cajamar",
        "latitude": -23.355,
        "longitude": -46.8781
    },
    {
        "nome": "Cajapió",
        "latitude": -2.87326,
        "longitude": -44.6741
    },
    {
        "nome": "Cajari",
        "latitude": -3.32742,
        "longitude": -45.0145
    },
    {
        "nome": "Cajati",
        "latitude": -24.7324,
        "longitude": -48.1223
    },
    {
        "nome": "Cajazeiras",
        "latitude": -6.88004,
        "longitude": -38.5577
    },
    {
        "nome": "Cajazeiras do Piauí",
        "latitude": -6.79667,
        "longitude": -42.3903
    },
    {
        "nome": "Cajazeirinhas",
        "latitude": -6.96016,
        "longitude": -37.8009
    },
    {
        "nome": "Cajobi",
        "latitude": -20.8773,
        "longitude": -48.8063
    },
    {
        "nome": "Cajueiro",
        "latitude": -9.3994,
        "longitude": -36.1559
    },
    {
        "nome": "Cajueiro da Praia",
        "latitude": -2.93111,
        "longitude": -41.3408
    },
    {
        "nome": "Cajuri",
        "latitude": -20.7903,
        "longitude": -42.7925
    },
    {
        "nome": "Cajuru",
        "latitude": -21.2749,
        "longitude": -47.303
    },
    {
        "nome": "Calçado",
        "latitude": -8.73108,
        "longitude": -36.3366
    },
    {
        "nome": "Calçoene",
        "latitude": 2.50475,
        "longitude": -50.9512
    },
    {
        "nome": "Caldas",
        "latitude": -21.9183,
        "longitude": -46.3843
    },
    {
        "nome": "Caldas Brandão",
        "latitude": -7.1025,
        "longitude": -35.3272
    },
    {
        "nome": "Caldas Novas",
        "latitude": -17.7441,
        "longitude": -48.6246
    },
    {
        "nome": "Caldazinha",
        "latitude": -16.7117,
        "longitude": -49.0013
    },
    {
        "nome": "Caldeirão Grande",
        "latitude": -11.0208,
        "longitude": -40.2956
    },
    {
        "nome": "Caldeirão Grande do Piauí",
        "latitude": -7.3314,
        "longitude": -40.6366
    },
    {
        "nome": "Califórnia",
        "latitude": -23.6566,
        "longitude": -51.3574
    },
    {
        "nome": "Calmon",
        "latitude": -26.5942,
        "longitude": -51.095
    },
    {
        "nome": "Calumbi",
        "latitude": -7.93551,
        "longitude": -38.1482
    },
    {
        "nome": "Camacan",
        "latitude": -15.4142,
        "longitude": -39.4919
    },
    {
        "nome": "Camaçari",
        "latitude": -12.6996,
        "longitude": -38.3263
    },
    {
        "nome": "Camacho",
        "latitude": -20.6294,
        "longitude": -45.1593
    },
    {
        "nome": "Camalaú",
        "latitude": -7.88503,
        "longitude": -36.8242
    },
    {
        "nome": "Camamu",
        "latitude": -13.9398,
        "longitude": -39.1071
    },
    {
        "nome": "Camanducaia",
        "latitude": -22.7515,
        "longitude": -46.1494
    },
    {
        "nome": "Camapuã",
        "latitude": -19.5347,
        "longitude": -54.0431
    },
    {
        "nome": "Camaquã",
        "latitude": -30.8489,
        "longitude": -51.8043
    },
    {
        "nome": "Camaragibe",
        "latitude": -8.02351,
        "longitude": -34.9782
    },
    {
        "nome": "Camargo",
        "latitude": -28.588,
        "longitude": -52.2003
    },
    {
        "nome": "Cambará",
        "latitude": -23.0423,
        "longitude": -50.0753
    },
    {
        "nome": "Cambará do Sul",
        "latitude": -29.0474,
        "longitude": -50.1465
    },
    {
        "nome": "Cambé",
        "latitude": -23.2766,
        "longitude": -51.2798
    },
    {
        "nome": "Cambira",
        "latitude": -23.589,
        "longitude": -51.5792
    },
    {
        "nome": "Camboriú",
        "latitude": -27.0241,
        "longitude": -48.6503
    },
    {
        "nome": "Cambuci",
        "latitude": -21.5691,
        "longitude": -41.9187
    },
    {
        "nome": "Cambuí",
        "latitude": -22.6115,
        "longitude": -46.0572
    },
    {
        "nome": "Cambuquira",
        "latitude": -21.854,
        "longitude": -45.2896
    },
    {
        "nome": "Cametá",
        "latitude": -2.24295,
        "longitude": -49.4979
    },
    {
        "nome": "Camocim",
        "latitude": -2.9005,
        "longitude": -40.8544
    },
    {
        "nome": "Camocim de São Félix",
        "latitude": -8.35865,
        "longitude": -35.7653
    },
    {
        "nome": "Campanário",
        "latitude": -18.2427,
        "longitude": -41.7355
    },
    {
        "nome": "Campanha",
        "latitude": -21.836,
        "longitude": -45.4004
    },
    {
        "nome": "Campestre",
        "latitude": -21.7079,
        "longitude": -46.2381
    },
    {
        "nome": "Campestre",
        "latitude": -8.84723,
        "longitude": -35.5685
    },
    {
        "nome": "Campestre da Serra",
        "latitude": -28.7926,
        "longitude": -51.0941
    },
    {
        "nome": "Campestre de Goiás",
        "latitude": -16.7624,
        "longitude": -49.695
    },
    {
        "nome": "Campestre do Maranhão",
        "latitude": -6.17075,
        "longitude": -47.3625
    },
    {
        "nome": "Campina da Lagoa",
        "latitude": -24.5893,
        "longitude": -52.7976
    },
    {
        "nome": "Campina das Missões",
        "latitude": -27.9888,
        "longitude": -54.8416
    },
    {
        "nome": "Campina do Monte Alegre",
        "latitude": -23.5895,
        "longitude": -48.4758
    },
    {
        "nome": "Campina do Simão",
        "latitude": -25.0802,
        "longitude": -51.8237
    },
    {
        "nome": "Campina Grande",
        "latitude": -7.22196,
        "longitude": -35.8731
    },
    {
        "nome": "Campina Grande do Sul",
        "latitude": -25.3044,
        "longitude": -49.0551
    },
    {
        "nome": "Campina Verde",
        "latitude": -19.5382,
        "longitude": -49.4862
    },
    {
        "nome": "Campinaçu",
        "latitude": -13.787,
        "longitude": -48.5704
    },
    {
        "nome": "Campinápolis",
        "latitude": -14.5162,
        "longitude": -52.893
    },
    {
        "nome": "Campinas",
        "latitude": -22.9053,
        "longitude": -47.0659
    },
    {
        "nome": "Campinas do Piauí",
        "latitude": -7.6593,
        "longitude": -41.8775
    },
    {
        "nome": "Campinas do Sul",
        "latitude": -27.7174,
        "longitude": -52.6248
    },
    {
        "nome": "Campinorte",
        "latitude": -14.3137,
        "longitude": -49.1511
    },
    {
        "nome": "Campo Alegre",
        "latitude": -26.195,
        "longitude": -49.2676
    },
    {
        "nome": "Campo Alegre",
        "latitude": -9.78451,
        "longitude": -36.3525
    },
    {
        "nome": "Campo Alegre de Goiás",
        "latitude": -17.6363,
        "longitude": -47.7768
    },
    {
        "nome": "Campo Alegre de Lourdes",
        "latitude": -9.52221,
        "longitude": -43.0126
    },
    {
        "nome": "Campo Alegre do Fidalgo",
        "latitude": -8.38236,
        "longitude": -41.8344
    },
    {
        "nome": "Campo Azul",
        "latitude": -16.5028,
        "longitude": -44.8096
    },
    {
        "nome": "Campo Belo",
        "latitude": -20.8932,
        "longitude": -45.2699
    },
    {
        "nome": "Campo Belo do Sul",
        "latitude": -27.8975,
        "longitude": -50.7595
    },
    {
        "nome": "Campo Bom",
        "latitude": -29.6747,
        "longitude": -51.0606
    },
    {
        "nome": "Campo Bonito",
        "latitude": -25.0294,
        "longitude": -52.9939
    },
    {
        "nome": "Campo do Brito",
        "latitude": -10.7392,
        "longitude": -37.4954
    },
    {
        "nome": "Campo do Meio",
        "latitude": -21.1127,
        "longitude": -45.8273
    },
    {
        "nome": "Campo do Tenente",
        "latitude": -25.98,
        "longitude": -49.6844
    },
    {
        "nome": "Campo Erê",
        "latitude": -26.3931,
        "longitude": -53.0856
    },
    {
        "nome": "Campo Florido",
        "latitude": -19.7631,
        "longitude": -48.5716
    },
    {
        "nome": "Campo Formoso",
        "latitude": -10.5105,
        "longitude": -40.32
    },
    {
        "nome": "Campo Grande",
        "latitude": -9.95542,
        "longitude": -36.7926
    },
    {
        "nome": "Campo Grande",
        "latitude": -20.4486,
        "longitude": -54.6295
    },
    {
        "nome": "Campo Grande do Piauí",
        "latitude": -7.12827,
        "longitude": -41.0315
    },
    {
        "nome": "Campo Largo",
        "latitude": -25.4525,
        "longitude": -49.529
    },
    {
        "nome": "Campo Largo do Piauí",
        "latitude": -3.80441,
        "longitude": -42.64
    },
    {
        "nome": "Campo Limpo de Goiás",
        "latitude": -16.2971,
        "longitude": -49.0895
    },
    {
        "nome": "Campo Limpo Paulista",
        "latitude": -23.2078,
        "longitude": -46.7889
    },
    {
        "nome": "Campo Magro",
        "latitude": -25.3687,
        "longitude": -49.4501
    },
    {
        "nome": "Campo Maior",
        "latitude": -4.8217,
        "longitude": -42.1641
    },
    {
        "nome": "Campo Mourão",
        "latitude": -24.0463,
        "longitude": -52.378
    },
    {
        "nome": "Campo Novo",
        "latitude": -27.6792,
        "longitude": -53.8052
    },
    {
        "nome": "Campo Novo de Rondônia",
        "latitude": -10.5712,
        "longitude": -63.6266
    },
    {
        "nome": "Campo Novo do Parecis",
        "latitude": -13.6587,
        "longitude": -57.8907
    },
    {
        "nome": "Campo Redondo",
        "latitude": -6.23829,
        "longitude": -36.1888
    },
    {
        "nome": "Campo Verde",
        "latitude": -15.545,
        "longitude": -55.1626
    },
    {
        "nome": "Campos Altos",
        "latitude": -19.6914,
        "longitude": -46.1725
    },
    {
        "nome": "Campos Belos",
        "latitude": -13.035,
        "longitude": -46.7681
    },
    {
        "nome": "Campos Borges",
        "latitude": -28.8871,
        "longitude": -53.0008
    },
    {
        "nome": "Campos de Júlio",
        "latitude": -13.7242,
        "longitude": -59.2858
    },
    {
        "nome": "Campos do Jordão",
        "latitude": -22.7296,
        "longitude": -45.5833
    },
    {
        "nome": "Campos dos Goytacazes",
        "latitude": -21.7622,
        "longitude": -41.3181
    },
    {
        "nome": "Campos Gerais",
        "latitude": -21.237,
        "longitude": -45.7569
    },
    {
        "nome": "Campos Lindos",
        "latitude": -7.98956,
        "longitude": -46.8645
    },
    {
        "nome": "Campos Novos",
        "latitude": -27.4002,
        "longitude": -51.2276
    },
    {
        "nome": "Campos Novos Paulista",
        "latitude": -22.602,
        "longitude": -49.9987
    },
    {
        "nome": "Campos Sales",
        "latitude": -7.06761,
        "longitude": -40.3687
    },
    {
        "nome": "Campos Verdes",
        "latitude": -14.2442,
        "longitude": -49.6528
    },
    {
        "nome": "Camutanga",
        "latitude": -7.40545,
        "longitude": -35.2664
    },
    {
        "nome": "Cana Verde",
        "latitude": -21.0232,
        "longitude": -45.1801
    },
    {
        "nome": "Canaã",
        "latitude": -20.6869,
        "longitude": -42.6167
    },
    {
        "nome": "Canaã dos Carajás",
        "latitude": -6.49659,
        "longitude": -49.8776
    },
    {
        "nome": "Canabrava do Norte",
        "latitude": -11.0556,
        "longitude": -51.8209
    },
    {
        "nome": "Cananéia",
        "latitude": -25.0144,
        "longitude": -47.9341
    },
    {
        "nome": "Canapi",
        "latitude": -9.11932,
        "longitude": -37.5967
    },
    {
        "nome": "Canápolis",
        "latitude": -13.0725,
        "longitude": -44.201
    },
    {
        "nome": "Canápolis",
        "latitude": -18.7212,
        "longitude": -49.2035
    },
    {
        "nome": "Canarana",
        "latitude": -11.6858,
        "longitude": -41.7677
    },
    {
        "nome": "Canarana",
        "latitude": -13.5515,
        "longitude": -52.2705
    },
    {
        "nome": "Canas",
        "latitude": -22.7003,
        "longitude": -45.0521
    },
    {
        "nome": "Canavieira",
        "latitude": -7.68821,
        "longitude": -43.7233
    },
    {
        "nome": "Canavieiras",
        "latitude": -15.6722,
        "longitude": -38.9536
    },
    {
        "nome": "Candeal",
        "latitude": -11.8049,
        "longitude": -39.1203
    },
    {
        "nome": "Candeias",
        "latitude": -12.6716,
        "longitude": -38.5472
    },
    {
        "nome": "Candeias",
        "latitude": -20.7692,
        "longitude": -45.2765
    },
    {
        "nome": "Candeias do Jamari",
        "latitude": -8.7907,
        "longitude": -63.7005
    },
    {
        "nome": "Candelária",
        "latitude": -29.6684,
        "longitude": -52.7895
    },
    {
        "nome": "Candiba",
        "latitude": -14.4097,
        "longitude": -42.8667
    },
    {
        "nome": "Cândido de Abreu",
        "latitude": -24.5649,
        "longitude": -51.3372
    },
    {
        "nome": "Cândido Godói",
        "latitude": -27.9515,
        "longitude": -54.7517
    },
    {
        "nome": "Cândido Mendes",
        "latitude": -1.43265,
        "longitude": -45.7161
    },
    {
        "nome": "Cândido Mota",
        "latitude": -22.7471,
        "longitude": -50.3873
    },
    {
        "nome": "Cândido Rodrigues",
        "latitude": -21.3275,
        "longitude": -48.6327
    },
    {
        "nome": "Cândido Sales",
        "latitude": -15.4993,
        "longitude": -41.2414
    },
    {
        "nome": "Candiota",
        "latitude": -31.5516,
        "longitude": -53.6773
    },
    {
        "nome": "Candói",
        "latitude": -25.5758,
        "longitude": -52.0409
    },
    {
        "nome": "Canela",
        "latitude": -29.356,
        "longitude": -50.8119
    },
    {
        "nome": "Canelinha",
        "latitude": -27.2616,
        "longitude": -48.7658
    },
    {
        "nome": "Canguaretama",
        "latitude": -6.37193,
        "longitude": -35.1281
    },
    {
        "nome": "Canguçu",
        "latitude": -31.396,
        "longitude": -52.6783
    },
    {
        "nome": "Canhoba",
        "latitude": -10.1365,
        "longitude": -36.9806
    },
    {
        "nome": "Canhotinho",
        "latitude": -8.87652,
        "longitude": -36.1979
    },
    {
        "nome": "Canindé",
        "latitude": -4.35162,
        "longitude": -39.3155
    },
    {
        "nome": "Canindé de São Francisco",
        "latitude": -9.64882,
        "longitude": -37.7923
    },
    {
        "nome": "Canitar",
        "latitude": -23.004,
        "longitude": -49.7839
    },
    {
        "nome": "Canoas",
        "latitude": -29.9128,
        "longitude": -51.1857
    },
    {
        "nome": "Canoinhas",
        "latitude": -26.1766,
        "longitude": -50.395
    },
    {
        "nome": "Cansanção",
        "latitude": -10.6647,
        "longitude": -39.4944
    },
    {
        "nome": "Cantá",
        "latitude": 2.60994,
        "longitude": -60.6058
    },
    {
        "nome": "Cantagalo",
        "latitude": -21.9797,
        "longitude": -42.3664
    },
    {
        "nome": "Cantagalo",
        "latitude": -25.3734,
        "longitude": -52.1198
    },
    {
        "nome": "Cantagalo",
        "latitude": -18.5248,
        "longitude": -42.6223
    },
    {
        "nome": "Cantanhede",
        "latitude": -3.63757,
        "longitude": -44.383
    },
    {
        "nome": "Canto do Buriti",
        "latitude": -8.1111,
        "longitude": -42.9517
    },
    {
        "nome": "Canudos",
        "latitude": -9.90014,
        "longitude": -39.1471
    },
    {
        "nome": "Canudos do Vale",
        "latitude": -29.3271,
        "longitude": -52.2374
    },
    {
        "nome": "Canutama",
        "latitude": -6.52582,
        "longitude": -64.3953
    },
    {
        "nome": "Capanema",
        "latitude": -1.20529,
        "longitude": -47.1778
    },
    {
        "nome": "Capanema",
        "latitude": -25.6691,
        "longitude": -53.8055
    },
    {
        "nome": "Capão Alto",
        "latitude": -27.9389,
        "longitude": -50.5098
    },
    {
        "nome": "Capão Bonito",
        "latitude": -24.0113,
        "longitude": -48.3482
    },
    {
        "nome": "Capão Bonito do Sul",
        "latitude": -28.1254,
        "longitude": -51.3961
    },
    {
        "nome": "Capão da Canoa",
        "latitude": -29.7642,
        "longitude": -50.0282
    },
    {
        "nome": "Capão do Cipó",
        "latitude": -28.9312,
        "longitude": -54.5558
    },
    {
        "nome": "Capão do Leão",
        "latitude": -31.7565,
        "longitude": -52.4889
    },
    {
        "nome": "Caparaó",
        "latitude": -20.5289,
        "longitude": -41.9061
    },
    {
        "nome": "Capela",
        "latitude": -9.41504,
        "longitude": -36.0826
    },
    {
        "nome": "Capela",
        "latitude": -10.5069,
        "longitude": -37.0628
    },
    {
        "nome": "Capela de Santana",
        "latitude": -29.6961,
        "longitude": -51.328
    },
    {
        "nome": "Capela do Alto",
        "latitude": -23.4685,
        "longitude": -47.7388
    },
    {
        "nome": "Capela do Alto Alegre",
        "latitude": -11.6658,
        "longitude": -39.8349
    },
    {
        "nome": "Capela Nova",
        "latitude": -20.9179,
        "longitude": -43.622
    },
    {
        "nome": "Capelinha",
        "latitude": -17.6888,
        "longitude": -42.5147
    },
    {
        "nome": "Capetinga",
        "latitude": -20.6163,
        "longitude": -47.0571
    },
    {
        "nome": "Capim",
        "latitude": -6.91624,
        "longitude": -35.1673
    },
    {
        "nome": "Capim Branco",
        "latitude": -19.5471,
        "longitude": -44.1304
    },
    {
        "nome": "Capim Grosso",
        "latitude": -11.3797,
        "longitude": -40.0089
    },
    {
        "nome": "Capinópolis",
        "latitude": -18.6862,
        "longitude": -49.5706
    },
    {
        "nome": "Capinzal",
        "latitude": -27.3473,
        "longitude": -51.6057
    },
    {
        "nome": "Capinzal do Norte",
        "latitude": -4.7236,
        "longitude": -44.328
    },
    {
        "nome": "Capistrano",
        "latitude": -4.45569,
        "longitude": -38.9048
    },
    {
        "nome": "Capitão",
        "latitude": -29.2674,
        "longitude": -51.9853
    },
    {
        "nome": "Capitão Andrade",
        "latitude": -19.0748,
        "longitude": -41.8614
    },
    {
        "nome": "Capitão de Campos",
        "latitude": -4.457,
        "longitude": -41.944
    },
    {
        "nome": "Capitão Enéas",
        "latitude": -16.3265,
        "longitude": -43.7084
    },
    {
        "nome": "Capitão Gervásio Oliveira",
        "latitude": -8.49655,
        "longitude": -41.814
    },
    {
        "nome": "Capitão Leônidas Marques",
        "latitude": -25.4816,
        "longitude": -53.6112
    },
    {
        "nome": "Capitão Poço",
        "latitude": -1.74785,
        "longitude": -47.0629
    },
    {
        "nome": "Capitólio",
        "latitude": -20.6164,
        "longitude": -46.0493
    },
    {
        "nome": "Capivari",
        "latitude": -22.9951,
        "longitude": -47.5071
    },
    {
        "nome": "Capivari de Baixo",
        "latitude": -28.4498,
        "longitude": -48.9631
    },
    {
        "nome": "Capivari do Sul",
        "latitude": -30.1383,
        "longitude": -50.5152
    },
    {
        "nome": "Capixaba",
        "latitude": -10.566,
        "longitude": -67.686
    },
    {
        "nome": "Capoeiras",
        "latitude": -8.73423,
        "longitude": -36.6306
    },
    {
        "nome": "Caputira",
        "latitude": -20.1703,
        "longitude": -42.2683
    },
    {
        "nome": "Caraá",
        "latitude": -29.7869,
        "longitude": -50.4316
    },
    {
        "nome": "Caracaraí",
        "latitude": 1.82766,
        "longitude": -61.1304
    },
    {
        "nome": "Caracol",
        "latitude": -9.27933,
        "longitude": -43.329
    },
    {
        "nome": "Caracol",
        "latitude": -22.011,
        "longitude": -57.0277
    },
    {
        "nome": "Caraguatatuba",
        "latitude": -23.6125,
        "longitude": -45.4125
    },
    {
        "nome": "Caraí",
        "latitude": -17.1862,
        "longitude": -41.7004
    },
    {
        "nome": "Caraíbas",
        "latitude": -14.7177,
        "longitude": -41.2603
    },
    {
        "nome": "Carambeí",
        "latitude": -24.9152,
        "longitude": -50.0986
    },
    {
        "nome": "Caranaíba",
        "latitude": -20.8707,
        "longitude": -43.7417
    },
    {
        "nome": "Carandaí",
        "latitude": -20.9566,
        "longitude": -43.811
    },
    {
        "nome": "Carangola",
        "latitude": -20.7343,
        "longitude": -42.0313
    },
    {
        "nome": "Carapebus",
        "latitude": -22.1821,
        "longitude": -41.663
    },
    {
        "nome": "Carapicuíba",
        "latitude": -23.5235,
        "longitude": -46.8407
    },
    {
        "nome": "Caratinga",
        "latitude": -19.7868,
        "longitude": -42.1292
    },
    {
        "nome": "Carauari",
        "latitude": -4.88161,
        "longitude": -66.9086
    },
    {
        "nome": "Caraúbas",
        "latitude": -5.78387,
        "longitude": -37.5586
    },
    {
        "nome": "Caraúbas",
        "latitude": -7.72049,
        "longitude": -36.492
    },
    {
        "nome": "Caraúbas do Piauí",
        "latitude": -3.47525,
        "longitude": -41.8425
    },
    {
        "nome": "Caravelas",
        "latitude": -17.7268,
        "longitude": -39.2597
    },
    {
        "nome": "Carazinho",
        "latitude": -28.2958,
        "longitude": -52.7933
    },
    {
        "nome": "Carbonita",
        "latitude": -17.5255,
        "longitude": -43.0137
    },
    {
        "nome": "Cardeal da Silva",
        "latitude": -11.9472,
        "longitude": -37.9469
    },
    {
        "nome": "Cardoso",
        "latitude": -20.08,
        "longitude": -49.9183
    },
    {
        "nome": "Cardoso Moreira",
        "latitude": -21.4846,
        "longitude": -41.6165
    },
    {
        "nome": "Careaçu",
        "latitude": -22.0424,
        "longitude": -45.696
    },
    {
        "nome": "Careiro",
        "latitude": -3.76803,
        "longitude": -60.369
    },
    {
        "nome": "Careiro da Várzea",
        "latitude": -3.314,
        "longitude": -59.5557
    },
    {
        "nome": "Cariacica",
        "latitude": -20.2632,
        "longitude": -40.4165
    },
    {
        "nome": "Caridade",
        "latitude": -4.22514,
        "longitude": -39.1912
    },
    {
        "nome": "Caridade do Piauí",
        "latitude": -7.73435,
        "longitude": -40.9848
    },
    {
        "nome": "Carinhanha",
        "latitude": -14.2985,
        "longitude": -43.7724
    },
    {
        "nome": "Carira",
        "latitude": -10.3524,
        "longitude": -37.7002
    },
    {
        "nome": "Cariré",
        "latitude": -3.94858,
        "longitude": -40.476
    },
    {
        "nome": "Cariri do Tocantins",
        "latitude": -11.8881,
        "longitude": -49.1609
    },
    {
        "nome": "Caririaçu",
        "latitude": -7.02808,
        "longitude": -39.2828
    },
    {
        "nome": "Cariús",
        "latitude": -6.52428,
        "longitude": -39.4916
    },
    {
        "nome": "Carlinda",
        "latitude": -9.94912,
        "longitude": -55.8417
    },
    {
        "nome": "Carlópolis",
        "latitude": -23.4269,
        "longitude": -49.7235
    },
    {
        "nome": "Carlos Barbosa",
        "latitude": -29.2969,
        "longitude": -51.5028
    },
    {
        "nome": "Carlos Chagas",
        "latitude": -17.6973,
        "longitude": -40.7723
    },
    {
        "nome": "Carlos Gomes",
        "latitude": -27.7167,
        "longitude": -51.9121
    },
    {
        "nome": "Carmésia",
        "latitude": -19.0877,
        "longitude": -43.1382
    },
    {
        "nome": "Carmo",
        "latitude": -21.931,
        "longitude": -42.6046
    },
    {
        "nome": "Carmo da Cachoeira",
        "latitude": -21.4633,
        "longitude": -45.2201
    },
    {
        "nome": "Carmo da Mata",
        "latitude": -20.5575,
        "longitude": -44.8735
    },
    {
        "nome": "Carmo de Minas",
        "latitude": -22.1204,
        "longitude": -45.1307
    },
    {
        "nome": "Carmo do Cajuru",
        "latitude": -20.1912,
        "longitude": -44.7664
    },
    {
        "nome": "Carmo do Paranaíba",
        "latitude": -18.991,
        "longitude": -46.3167
    },
    {
        "nome": "Carmo do Rio Claro",
        "latitude": -20.9736,
        "longitude": -46.1149
    },
    {
        "nome": "Carmo do Rio Verde",
        "latitude": -15.3549,
        "longitude": -49.708
    },
    {
        "nome": "Carmolândia",
        "latitude": -7.03262,
        "longitude": -48.3978
    },
    {
        "nome": "Carmópolis",
        "latitude": -10.6449,
        "longitude": -36.9887
    },
    {
        "nome": "Carmópolis de Minas",
        "latitude": -20.5396,
        "longitude": -44.6336
    },
    {
        "nome": "Carnaíba",
        "latitude": -7.79342,
        "longitude": -37.7946
    },
    {
        "nome": "Carnaúba dos Dantas",
        "latitude": -6.55015,
        "longitude": -36.5868
    },
    {
        "nome": "Carnaubais",
        "latitude": -5.34181,
        "longitude": -36.8335
    },
    {
        "nome": "Carnaubal",
        "latitude": -4.15985,
        "longitude": -40.9413
    },
    {
        "nome": "Carnaubeira da Penha",
        "latitude": -8.31799,
        "longitude": -38.7512
    },
    {
        "nome": "Carneirinho",
        "latitude": -19.6987,
        "longitude": -50.6894
    },
    {
        "nome": "Carneiros",
        "latitude": -9.48476,
        "longitude": -37.3773
    },
    {
        "nome": "Caroebe",
        "latitude": 0.884203,
        "longitude": -59.6959
    },
    {
        "nome": "Carolina",
        "latitude": -7.33584,
        "longitude": -47.4634
    },
    {
        "nome": "Carpina",
        "latitude": -7.84566,
        "longitude": -35.2514
    },
    {
        "nome": "Carrancas",
        "latitude": -21.4898,
        "longitude": -44.6446
    },
    {
        "nome": "Carrapateira",
        "latitude": -7.03414,
        "longitude": -38.3399
    },
    {
        "nome": "Carrasco Bonito",
        "latitude": -5.31415,
        "longitude": -48.0314
    },
    {
        "nome": "Caruaru",
        "latitude": -8.28455,
        "longitude": -35.9699
    },
    {
        "nome": "Carutapera",
        "latitude": -1.19696,
        "longitude": -46.0085
    },
    {
        "nome": "Carvalhópolis",
        "latitude": -21.7735,
        "longitude": -45.8421
    },
    {
        "nome": "Carvalhos",
        "latitude": -22,
        "longitude": -44.4632
    },
    {
        "nome": "Casa Branca",
        "latitude": -21.7708,
        "longitude": -47.0852
    },
    {
        "nome": "Casa Grande",
        "latitude": -20.7925,
        "longitude": -43.9343
    },
    {
        "nome": "Casa Nova",
        "latitude": -9.16408,
        "longitude": -40.974
    },
    {
        "nome": "Casca",
        "latitude": -28.5605,
        "longitude": -51.9815
    },
    {
        "nome": "Cascalho Rico",
        "latitude": -18.5772,
        "longitude": -47.8716
    },
    {
        "nome": "Cascavel",
        "latitude": -24.9573,
        "longitude": -53.459
    },
    {
        "nome": "Cascavel",
        "latitude": -4.12967,
        "longitude": -38.2412
    },
    {
        "nome": "Caseara",
        "latitude": -9.27612,
        "longitude": -49.9521
    },
    {
        "nome": "Caseiros",
        "latitude": -28.2582,
        "longitude": -51.6861
    },
    {
        "nome": "Casimiro de Abreu",
        "latitude": -22.4812,
        "longitude": -42.2066
    },
    {
        "nome": "Casinhas",
        "latitude": -7.74084,
        "longitude": -35.7206
    },
    {
        "nome": "Casserengue",
        "latitude": -6.77954,
        "longitude": -35.8179
    },
    {
        "nome": "Cássia",
        "latitude": -20.5831,
        "longitude": -46.9201
    },
    {
        "nome": "Cássia dos Coqueiros",
        "latitude": -21.2801,
        "longitude": -47.1643
    },
    {
        "nome": "Cassilândia",
        "latitude": -19.1179,
        "longitude": -51.7313
    },
    {
        "nome": "Castanhal",
        "latitude": -1.29797,
        "longitude": -47.9167
    },
    {
        "nome": "Castanheira",
        "latitude": -11.1251,
        "longitude": -58.6081
    },
    {
        "nome": "Castanheiras",
        "latitude": -11.4253,
        "longitude": -61.9482
    },
    {
        "nome": "Castelândia",
        "latitude": -18.0921,
        "longitude": -50.203
    },
    {
        "nome": "Castelo",
        "latitude": -20.6033,
        "longitude": -41.2031
    },
    {
        "nome": "Castelo do Piauí",
        "latitude": -5.31869,
        "longitude": -41.5499
    },
    {
        "nome": "Castilho",
        "latitude": -20.8689,
        "longitude": -51.4884
    },
    {
        "nome": "Castro",
        "latitude": -24.7891,
        "longitude": -50.0108
    },
    {
        "nome": "Castro Alves",
        "latitude": -12.7579,
        "longitude": -39.4248
    },
    {
        "nome": "Cataguases",
        "latitude": -21.3924,
        "longitude": -42.6896
    },
    {
        "nome": "Catalão",
        "latitude": -18.1656,
        "longitude": -47.944
    },
    {
        "nome": "Catanduva",
        "latitude": -21.1314,
        "longitude": -48.977
    },
    {
        "nome": "Catanduvas",
        "latitude": -25.2044,
        "longitude": -53.1548
    },
    {
        "nome": "Catanduvas",
        "latitude": -27.069,
        "longitude": -51.6602
    },
    {
        "nome": "Catarina",
        "latitude": -6.12291,
        "longitude": -39.8736
    },
    {
        "nome": "Catas Altas",
        "latitude": -20.0734,
        "longitude": -43.4061
    },
    {
        "nome": "Catas Altas da Noruega",
        "latitude": -20.6901,
        "longitude": -43.4939
    },
    {
        "nome": "Catende",
        "latitude": -8.67509,
        "longitude": -35.7024
    },
    {
        "nome": "Catiguá",
        "latitude": -21.0519,
        "longitude": -49.0616
    },
    {
        "nome": "Catingueira",
        "latitude": -7.12008,
        "longitude": -37.6064
    },
    {
        "nome": "Catolândia",
        "latitude": -12.31,
        "longitude": -44.8648
    },
    {
        "nome": "Catolé do Rocha",
        "latitude": -6.34062,
        "longitude": -37.747
    },
    {
        "nome": "Catu",
        "latitude": -12.3513,
        "longitude": -38.3791
    },
    {
        "nome": "Catuípe",
        "latitude": -28.2554,
        "longitude": -54.0132
    },
    {
        "nome": "Catuji",
        "latitude": -17.3018,
        "longitude": -41.5276
    },
    {
        "nome": "Catunda",
        "latitude": -4.64336,
        "longitude": -40.2
    },
    {
        "nome": "Caturaí",
        "latitude": -16.4447,
        "longitude": -49.4936
    },
    {
        "nome": "Caturama",
        "latitude": -13.3239,
        "longitude": -42.2904
    },
    {
        "nome": "Caturité",
        "latitude": -7.41659,
        "longitude": -36.0306
    },
    {
        "nome": "Catuti",
        "latitude": -15.3616,
        "longitude": -42.9627
    },
    {
        "nome": "Caucaia",
        "latitude": -3.72797,
        "longitude": -38.6619
    },
    {
        "nome": "Cavalcante",
        "latitude": -13.7976,
        "longitude": -47.4566
    },
    {
        "nome": "Caxambu",
        "latitude": -21.9753,
        "longitude": -44.9319
    },
    {
        "nome": "Caxambu do Sul",
        "latitude": -27.1624,
        "longitude": -52.8807
    },
    {
        "nome": "Caxias",
        "latitude": -4.86505,
        "longitude": -43.3617
    },
    {
        "nome": "Caxias do Sul",
        "latitude": -29.1629,
        "longitude": -51.1792
    },
    {
        "nome": "Caxingó",
        "latitude": -3.41904,
        "longitude": -41.8955
    },
    {
        "nome": "Ceará-Mirim",
        "latitude": -5.64323,
        "longitude": -35.4247
    },
    {
        "nome": "Cedral",
        "latitude": -2.00027,
        "longitude": -44.5281
    },
    {
        "nome": "Cedral",
        "latitude": -20.9009,
        "longitude": -49.2664
    },
    {
        "nome": "Cedro",
        "latitude": -6.60034,
        "longitude": -39.0609
    },
    {
        "nome": "Cedro",
        "latitude": -7.71179,
        "longitude": -39.2367
    },
    {
        "nome": "Cedro de São João",
        "latitude": -10.2534,
        "longitude": -36.8856
    },
    {
        "nome": "Cedro do Abaeté",
        "latitude": -19.1458,
        "longitude": -45.712
    },
    {
        "nome": "Celso Ramos",
        "latitude": -27.6327,
        "longitude": -51.335
    },
    {
        "nome": "Centenário",
        "latitude": -27.7615,
        "longitude": -51.9984
    },
    {
        "nome": "Centenário",
        "latitude": -8.96103,
        "longitude": -47.3304
    },
    {
        "nome": "Centenário do Sul",
        "latitude": -22.8188,
        "longitude": -51.5973
    },
    {
        "nome": "Central",
        "latitude": -11.1376,
        "longitude": -42.1116
    },
    {
        "nome": "Central de Minas",
        "latitude": -18.7612,
        "longitude": -41.3143
    },
    {
        "nome": "Central do Maranhão",
        "latitude": -2.19831,
        "longitude": -44.8254
    },
    {
        "nome": "Centralina",
        "latitude": -18.5852,
        "longitude": -49.2014
    },
    {
        "nome": "Centro do Guilherme",
        "latitude": -2.44891,
        "longitude": -46.0345
    },
    {
        "nome": "Centro Novo do Maranhão",
        "latitude": -2.12696,
        "longitude": -46.1228
    },
    {
        "nome": "Cerejeiras",
        "latitude": -13.187,
        "longitude": -60.8168
    },
    {
        "nome": "Ceres",
        "latitude": -15.3061,
        "longitude": -49.6
    },
    {
        "nome": "Cerqueira César",
        "latitude": -23.038,
        "longitude": -49.1655
    },
    {
        "nome": "Cerquilho",
        "latitude": -23.1665,
        "longitude": -47.7459
    },
    {
        "nome": "Cerrito",
        "latitude": -31.8419,
        "longitude": -52.8004
    },
    {
        "nome": "Cerro Azul",
        "latitude": -26.0891,
        "longitude": -52.8691
    },
    {
        "nome": "Cerro Branco",
        "latitude": -29.657,
        "longitude": -52.9406
    },
    {
        "nome": "Cerro Corá",
        "latitude": -6.03503,
        "longitude": -36.3503
    },
    {
        "nome": "Cerro Grande",
        "latitude": -27.6106,
        "longitude": -53.1672
    },
    {
        "nome": "Cerro Grande do Sul",
        "latitude": -30.5905,
        "longitude": -51.7418
    },
    {
        "nome": "Cerro Largo",
        "latitude": -28.1463,
        "longitude": -54.7428
    },
    {
        "nome": "Cerro Negro",
        "latitude": -27.7942,
        "longitude": -50.8673
    },
    {
        "nome": "Cesário Lange",
        "latitude": -23.226,
        "longitude": -47.9545
    },
    {
        "nome": "Céu Azul",
        "latitude": -25.1489,
        "longitude": -53.8415
    },
    {
        "nome": "Cezarina",
        "latitude": -16.9718,
        "longitude": -49.7758
    },
    {
        "nome": "Chã de Alegria",
        "latitude": -8.00679,
        "longitude": -35.204
    },
    {
        "nome": "Chã Grande",
        "latitude": -8.23827,
        "longitude": -35.4571
    },
    {
        "nome": "Chã Preta",
        "latitude": -9.2556,
        "longitude": -36.2983
    },
    {
        "nome": "Chácara",
        "latitude": -21.6733,
        "longitude": -43.215
    },
    {
        "nome": "Chalé",
        "latitude": -20.0453,
        "longitude": -41.6897
    },
    {
        "nome": "Chapada",
        "latitude": -28.0559,
        "longitude": -53.0665
    },
    {
        "nome": "Chapada da Natividade",
        "latitude": -11.6175,
        "longitude": -47.7486
    },
    {
        "nome": "Chapada de Areia",
        "latitude": -10.1419,
        "longitude": -49.1403
    },
    {
        "nome": "Chapada do Norte",
        "latitude": -17.0881,
        "longitude": -42.5392
    },
    {
        "nome": "Chapada dos Guimarães",
        "latitude": -15.4643,
        "longitude": -55.7499
    },
    {
        "nome": "Chapada Gaúcha",
        "latitude": -15.3014,
        "longitude": -45.6116
    },
    {
        "nome": "Chapadão do Céu",
        "latitude": -18.4073,
        "longitude": -52.549
    },
    {
        "nome": "Chapadão do Lageado",
        "latitude": -27.5905,
        "longitude": -49.5539
    },
    {
        "nome": "Chapadão do Sul",
        "latitude": -18.788,
        "longitude": -52.6263
    },
    {
        "nome": "Chapadinha",
        "latitude": -3.73875,
        "longitude": -43.3538
    },
    {
        "nome": "Chapecó",
        "latitude": -27.1004,
        "longitude": -52.6152
    },
    {
        "nome": "Charqueada",
        "latitude": -22.5096,
        "longitude": -47.7755
    },
    {
        "nome": "Charqueadas",
        "latitude": -29.9625,
        "longitude": -51.6289
    },
    {
        "nome": "Charrua",
        "latitude": -27.9493,
        "longitude": -52.015
    },
    {
        "nome": "Chaval",
        "latitude": -3.03571,
        "longitude": -41.2435
    },
    {
        "nome": "Chavantes",
        "latitude": -23.0366,
        "longitude": -49.7096
    },
    {
        "nome": "Chaves",
        "latitude": -0.164154,
        "longitude": -49.987
    },
    {
        "nome": "Chiador",
        "latitude": -21.9996,
        "longitude": -43.0617
    },
    {
        "nome": "Chiapetta",
        "latitude": -27.923,
        "longitude": -53.9419
    },
    {
        "nome": "Chopinzinho",
        "latitude": -25.8515,
        "longitude": -52.5173
    },
    {
        "nome": "Choró",
        "latitude": -4.83906,
        "longitude": -39.1344
    },
    {
        "nome": "Chorozinho",
        "latitude": -4.28873,
        "longitude": -38.4986
    },
    {
        "nome": "Chorrochó",
        "latitude": -8.9695,
        "longitude": -39.0979
    },
    {
        "nome": "Chuí",
        "latitude": -33.6866,
        "longitude": -53.4594
    },
    {
        "nome": "Chupinguaia",
        "latitude": -12.5611,
        "longitude": -60.8877
    },
    {
        "nome": "Chuvisca",
        "latitude": -30.7504,
        "longitude": -51.9737
    },
    {
        "nome": "Cianorte",
        "latitude": -23.6599,
        "longitude": -52.6054
    },
    {
        "nome": "Cícero Dantas",
        "latitude": -10.5897,
        "longitude": -38.3794
    },
    {
        "nome": "Cidade Gaúcha",
        "latitude": -23.3772,
        "longitude": -52.9436
    },
    {
        "nome": "Cidade Ocidental",
        "latitude": -16.0765,
        "longitude": -47.9252
    },
    {
        "nome": "Cidelândia",
        "latitude": -5.17465,
        "longitude": -47.7781
    },
    {
        "nome": "Cidreira",
        "latitude": -30.1604,
        "longitude": -50.2337
    },
    {
        "nome": "Cipó",
        "latitude": -11.1032,
        "longitude": -38.5179
    },
    {
        "nome": "Cipotânea",
        "latitude": -20.9026,
        "longitude": -43.3629
    },
    {
        "nome": "Ciríaco",
        "latitude": -28.3419,
        "longitude": -51.8741
    },
    {
        "nome": "Claraval",
        "latitude": -20.397,
        "longitude": -47.2768
    },
    {
        "nome": "Claro dos Poções",
        "latitude": -17.082,
        "longitude": -44.2061
    },
    {
        "nome": "Cláudia",
        "latitude": -11.5075,
        "longitude": -54.8835
    },
    {
        "nome": "Cláudio",
        "latitude": -20.4437,
        "longitude": -44.7673
    },
    {
        "nome": "Clementina",
        "latitude": -21.5604,
        "longitude": -50.4525
    },
    {
        "nome": "Clevelândia",
        "latitude": -26.4043,
        "longitude": -52.3508
    },
    {
        "nome": "Coaraci",
        "latitude": -14.637,
        "longitude": -39.5556
    },
    {
        "nome": "Coari",
        "latitude": -4.09412,
        "longitude": -63.1441
    },
    {
        "nome": "Cocal",
        "latitude": -3.47279,
        "longitude": -41.5546
    },
    {
        "nome": "Cocal de Telha",
        "latitude": -4.5571,
        "longitude": -41.9587
    },
    {
        "nome": "Cocal do Sul",
        "latitude": -28.5986,
        "longitude": -49.3335
    },
    {
        "nome": "Cocal dos Alves",
        "latitude": -3.62047,
        "longitude": -41.4402
    },
    {
        "nome": "Cocalinho",
        "latitude": -14.3903,
        "longitude": -51.0001
    },
    {
        "nome": "Cocalzinho de Goiás",
        "latitude": -15.7914,
        "longitude": -48.7747
    },
    {
        "nome": "Cocos",
        "latitude": -14.1814,
        "longitude": -44.5352
    },
    {
        "nome": "Codajás",
        "latitude": -3.83053,
        "longitude": -62.0658
    },
    {
        "nome": "Codó",
        "latitude": -4.45562,
        "longitude": -43.8924
    },
    {
        "nome": "Coelho Neto",
        "latitude": -4.25245,
        "longitude": -43.0108
    },
    {
        "nome": "Coimbra",
        "latitude": -20.8535,
        "longitude": -42.8008
    },
    {
        "nome": "Coité do Nóia",
        "latitude": -9.63348,
        "longitude": -36.5845
    },
    {
        "nome": "Coivaras",
        "latitude": -5.09224,
        "longitude": -42.208
    },
    {
        "nome": "Colares",
        "latitude": -0.936423,
        "longitude": -48.2803
    },
    {
        "nome": "Colatina",
        "latitude": -19.5493,
        "longitude": -40.6269
    },
    {
        "nome": "Colíder",
        "latitude": -10.8135,
        "longitude": -55.461
    },
    {
        "nome": "Colina",
        "latitude": -20.7114,
        "longitude": -48.5387
    },
    {
        "nome": "Colinas",
        "latitude": -29.3948,
        "longitude": -51.8556
    },
    {
        "nome": "Colinas",
        "latitude": -6.03199,
        "longitude": -44.2543
    },
    {
        "nome": "Colinas do Sul",
        "latitude": -14.1528,
        "longitude": -48.076
    },
    {
        "nome": "Colinas do Tocantins",
        "latitude": -8.05764,
        "longitude": -48.4757
    },
    {
        "nome": "Colméia",
        "latitude": -8.72463,
        "longitude": -48.7638
    },
    {
        "nome": "Colniza",
        "latitude": -9.46121,
        "longitude": -59.2252
    },
    {
        "nome": "Colômbia",
        "latitude": -20.1768,
        "longitude": -48.6865
    },
    {
        "nome": "Colombo",
        "latitude": -25.2925,
        "longitude": -49.2262
    },
    {
        "nome": "Colônia do Gurguéia",
        "latitude": -8.1837,
        "longitude": -43.794
    },
    {
        "nome": "Colônia do Piauí",
        "latitude": -7.22651,
        "longitude": -42.1756
    },
    {
        "nome": "Colônia Leopoldina",
        "latitude": -8.91806,
        "longitude": -35.7214
    },
    {
        "nome": "Colorado",
        "latitude": -28.5258,
        "longitude": -52.9928
    },
    {
        "nome": "Colorado",
        "latitude": -22.8374,
        "longitude": -51.9743
    },
    {
        "nome": "Colorado do Oeste",
        "latitude": -13.1174,
        "longitude": -60.5454
    },
    {
        "nome": "Coluna",
        "latitude": -18.2311,
        "longitude": -42.8352
    },
    {
        "nome": "Combinado",
        "latitude": -12.7917,
        "longitude": -46.5388
    },
    {
        "nome": "Comendador Gomes",
        "latitude": -19.6973,
        "longitude": -49.0789
    },
    {
        "nome": "Comendador Levy Gasparian",
        "latitude": -22.0404,
        "longitude": -43.214
    },
    {
        "nome": "Comercinho",
        "latitude": -16.2963,
        "longitude": -41.7945
    },
    {
        "nome": "Comodoro",
        "latitude": -13.6614,
        "longitude": -59.7848
    },
    {
        "nome": "Conceição",
        "latitude": -7.55106,
        "longitude": -38.5014
    },
    {
        "nome": "Conceição da Aparecida",
        "latitude": -21.096,
        "longitude": -46.2049
    },
    {
        "nome": "Conceição da Barra",
        "latitude": -18.5883,
        "longitude": -39.7362
    },
    {
        "nome": "Conceição da Barra de Minas",
        "latitude": -21.1316,
        "longitude": -44.4729
    },
    {
        "nome": "Conceição da Feira",
        "latitude": -12.5078,
        "longitude": -38.9978
    },
    {
        "nome": "Conceição das Alagoas",
        "latitude": -19.9172,
        "longitude": -48.3839
    },
    {
        "nome": "Conceição das Pedras",
        "latitude": -22.1576,
        "longitude": -45.4562
    },
    {
        "nome": "Conceição de Ipanema",
        "latitude": -19.9326,
        "longitude": -41.6908
    },
    {
        "nome": "Conceição de Macabu",
        "latitude": -22.0834,
        "longitude": -41.8719
    },
    {
        "nome": "Conceição do Almeida",
        "latitude": -12.7836,
        "longitude": -39.1715
    },
    {
        "nome": "Conceição do Araguaia",
        "latitude": -8.26136,
        "longitude": -49.2689
    },
    {
        "nome": "Conceição do Canindé",
        "latitude": -7.87638,
        "longitude": -41.5942
    },
    {
        "nome": "Conceição do Castelo",
        "latitude": -20.3639,
        "longitude": -41.2417
    },
    {
        "nome": "Conceição do Coité",
        "latitude": -11.56,
        "longitude": -39.2808
    },
    {
        "nome": "Conceição do Jacuípe",
        "latitude": -12.3268,
        "longitude": -38.7684
    },
    {
        "nome": "Conceição do Lago-Açu",
        "latitude": -3.85142,
        "longitude": -44.8895
    },
    {
        "nome": "Conceição do Mato Dentro",
        "latitude": -19.0344,
        "longitude": -43.4221
    },
    {
        "nome": "Conceição do Pará",
        "latitude": -19.7456,
        "longitude": -44.8945
    },
    {
        "nome": "Conceição do Rio Verde",
        "latitude": -21.8778,
        "longitude": -45.087
    },
    {
        "nome": "Conceição do Tocantins",
        "latitude": -12.2209,
        "longitude": -47.2951
    },
    {
        "nome": "Conceição dos Ouros",
        "latitude": -22.4078,
        "longitude": -45.7996
    },
    {
        "nome": "Conchal",
        "latitude": -22.3375,
        "longitude": -47.1729
    },
    {
        "nome": "Conchas",
        "latitude": -23.0154,
        "longitude": -48.0134
    },
    {
        "nome": "Concórdia",
        "latitude": -27.2335,
        "longitude": -52.026
    },
    {
        "nome": "Concórdia do Pará",
        "latitude": -1.99238,
        "longitude": -47.9422
    },
    {
        "nome": "Condado",
        "latitude": -6.89831,
        "longitude": -37.606
    },
    {
        "nome": "Condado",
        "latitude": -7.58787,
        "longitude": -35.0999
    },
    {
        "nome": "Conde",
        "latitude": -7.25746,
        "longitude": -34.8999
    },
    {
        "nome": "Conde",
        "latitude": -11.8179,
        "longitude": -37.6131
    },
    {
        "nome": "Condeúba",
        "latitude": -14.9022,
        "longitude": -41.9718
    },
    {
        "nome": "Condor",
        "latitude": -28.2075,
        "longitude": -53.4905
    },
    {
        "nome": "Cônego Marinho",
        "latitude": -15.2892,
        "longitude": -44.4181
    },
    {
        "nome": "Confins",
        "latitude": -19.6282,
        "longitude": -43.9931
    },
    {
        "nome": "Confresa",
        "latitude": -10.6437,
        "longitude": -51.5699
    },
    {
        "nome": "Congo",
        "latitude": -7.79078,
        "longitude": -36.6581
    },
    {
        "nome": "Congonhal",
        "latitude": -22.1488,
        "longitude": -46.043
    },
    {
        "nome": "Congonhas",
        "latitude": -20.4958,
        "longitude": -43.851
    },
    {
        "nome": "Congonhas do Norte",
        "latitude": -18.8021,
        "longitude": -43.6767
    },
    {
        "nome": "Congonhinhas",
        "latitude": -23.5493,
        "longitude": -50.5569
    },
    {
        "nome": "Conquista",
        "latitude": -19.9312,
        "longitude": -47.5492
    },
    {
        "nome": "Conquista D'Oeste",
        "latitude": -14.5381,
        "longitude": -59.5444
    },
    {
        "nome": "Conselheiro Lafaiete",
        "latitude": -20.6634,
        "longitude": -43.7846
    },
    {
        "nome": "Conselheiro Mairinck",
        "latitude": -23.623,
        "longitude": -50.1707
    },
    {
        "nome": "Conselheiro Pena",
        "latitude": -19.1789,
        "longitude": -41.4736
    },
    {
        "nome": "Consolação",
        "latitude": -22.5493,
        "longitude": -45.9255
    },
    {
        "nome": "Constantina",
        "latitude": -27.732,
        "longitude": -52.9938
    },
    {
        "nome": "Contagem",
        "latitude": -19.9321,
        "longitude": -44.0539
    },
    {
        "nome": "Contenda",
        "latitude": -25.6788,
        "longitude": -49.535
    },
    {
        "nome": "Contendas do Sincorá",
        "latitude": -13.7537,
        "longitude": -41.048
    },
    {
        "nome": "Coqueiral",
        "latitude": -21.1858,
        "longitude": -45.4366
    },
    {
        "nome": "Coqueiro Baixo",
        "latitude": -29.1802,
        "longitude": -52.0942
    },
    {
        "nome": "Coqueiro Seco",
        "latitude": -9.63715,
        "longitude": -35.7994
    },
    {
        "nome": "Coqueiros do Sul",
        "latitude": -28.1194,
        "longitude": -52.7842
    },
    {
        "nome": "Coração de Jesus",
        "latitude": -16.6841,
        "longitude": -44.3635
    },
    {
        "nome": "Coração de Maria",
        "latitude": -12.2333,
        "longitude": -38.7487
    },
    {
        "nome": "Corbélia",
        "latitude": -24.7971,
        "longitude": -53.3006
    },
    {
        "nome": "Cordeiro",
        "latitude": -22.0267,
        "longitude": -42.3648
    },
    {
        "nome": "Cordeirópolis",
        "latitude": -22.4778,
        "longitude": -47.4519
    },
    {
        "nome": "Cordeiros",
        "latitude": -15.0356,
        "longitude": -41.9308
    },
    {
        "nome": "Cordilheira Alta",
        "latitude": -26.9844,
        "longitude": -52.6056
    },
    {
        "nome": "Cordisburgo",
        "latitude": -19.1224,
        "longitude": -44.3224
    },
    {
        "nome": "Cordislândia",
        "latitude": -21.7891,
        "longitude": -45.6999
    },
    {
        "nome": "Coreaú",
        "latitude": -3.5415,
        "longitude": -40.6587
    },
    {
        "nome": "Coremas",
        "latitude": -7.00712,
        "longitude": -37.9346
    },
    {
        "nome": "Corguinho",
        "latitude": -19.8243,
        "longitude": -54.8281
    },
    {
        "nome": "Coribe",
        "latitude": -13.8232,
        "longitude": -44.4586
    },
    {
        "nome": "Corinto",
        "latitude": -18.369,
        "longitude": -44.4542
    },
    {
        "nome": "Cornélio Procópio",
        "latitude": -23.1829,
        "longitude": -50.6498
    },
    {
        "nome": "Coroaci",
        "latitude": -18.6156,
        "longitude": -42.2791
    },
    {
        "nome": "Coroados",
        "latitude": -21.3521,
        "longitude": -50.2859
    },
    {
        "nome": "Coroatá",
        "latitude": -4.13442,
        "longitude": -44.1244
    },
    {
        "nome": "Coromandel",
        "latitude": -18.4734,
        "longitude": -47.1933
    },
    {
        "nome": "Coronel Barros",
        "latitude": -28.3921,
        "longitude": -54.0686
    },
    {
        "nome": "Coronel Bicaco",
        "latitude": -27.7197,
        "longitude": -53.7022
    },
    {
        "nome": "Coronel Domingos Soares",
        "latitude": -26.2277,
        "longitude": -52.0356
    },
    {
        "nome": "Coronel Ezequiel",
        "latitude": -6.3748,
        "longitude": -36.2223
    },
    {
        "nome": "Coronel Fabriciano",
        "latitude": -19.5179,
        "longitude": -42.6276
    },
    {
        "nome": "Coronel Freitas",
        "latitude": -26.9057,
        "longitude": -52.7011
    },
    {
        "nome": "Coronel João Pessoa",
        "latitude": -6.24974,
        "longitude": -38.4441
    },
    {
        "nome": "Coronel João Sá",
        "latitude": -10.2847,
        "longitude": -37.9198
    },
    {
        "nome": "Coronel José Dias",
        "latitude": -8.81397,
        "longitude": -42.5232
    },
    {
        "nome": "Coronel Macedo",
        "latitude": -23.6261,
        "longitude": -49.31
    },
    {
        "nome": "Coronel Martins",
        "latitude": -26.511,
        "longitude": -52.6694
    },
    {
        "nome": "Coronel Murta",
        "latitude": -16.6148,
        "longitude": -42.184
    },
    {
        "nome": "Coronel Pacheco",
        "latitude": -21.5898,
        "longitude": -43.256
    },
    {
        "nome": "Coronel Pilar",
        "latitude": -29.2695,
        "longitude": -51.6847
    },
    {
        "nome": "Coronel Sapucaia",
        "latitude": -23.2724,
        "longitude": -55.5278
    },
    {
        "nome": "Coronel Vivida",
        "latitude": -25.9767,
        "longitude": -52.5641
    },
    {
        "nome": "Coronel Xavier Chaves",
        "latitude": -21.0277,
        "longitude": -44.2206
    },
    {
        "nome": "Córrego Danta",
        "latitude": -19.8198,
        "longitude": -45.9032
    },
    {
        "nome": "Córrego do Bom Jesus",
        "latitude": -22.6269,
        "longitude": -46.0241
    },
    {
        "nome": "Córrego do Ouro",
        "latitude": -16.2918,
        "longitude": -50.5503
    },
    {
        "nome": "Córrego Fundo",
        "latitude": -20.4474,
        "longitude": -45.5617
    },
    {
        "nome": "Córrego Novo",
        "latitude": -19.8361,
        "longitude": -42.3988
    },
    {
        "nome": "Correia Pinto",
        "latitude": -27.5877,
        "longitude": -50.3614
    },
    {
        "nome": "Corrente",
        "latitude": -10.4333,
        "longitude": -45.1633
    },
    {
        "nome": "Correntes",
        "latitude": -9.12117,
        "longitude": -36.3244
    },
    {
        "nome": "Correntina",
        "latitude": -13.3477,
        "longitude": -44.6333
    },
    {
        "nome": "Cortês",
        "latitude": -8.47443,
        "longitude": -35.5468
    },
    {
        "nome": "Corumbá",
        "latitude": -19.0077,
        "longitude": -57.651
    },
    {
        "nome": "Corumbá de Goiás",
        "latitude": -15.9245,
        "longitude": -48.8117
    },
    {
        "nome": "Corumbaíba",
        "latitude": -18.1415,
        "longitude": -48.5626
    },
    {
        "nome": "Corumbataí",
        "latitude": -22.2213,
        "longitude": -47.6215
    },
    {
        "nome": "Corumbataí do Sul",
        "latitude": -24.101,
        "longitude": -52.1177
    },
    {
        "nome": "Corumbiara",
        "latitude": -12.9551,
        "longitude": -60.8947
    },
    {
        "nome": "Corupá",
        "latitude": -26.4246,
        "longitude": -49.246
    },
    {
        "nome": "Coruripe",
        "latitude": -10.1276,
        "longitude": -36.1717
    },
    {
        "nome": "Cosmópolis",
        "latitude": -22.6419,
        "longitude": -47.1926
    },
    {
        "nome": "Cosmorama",
        "latitude": -20.4755,
        "longitude": -49.7827
    },
    {
        "nome": "Costa Marques",
        "latitude": -12.4367,
        "longitude": -64.228
    },
    {
        "nome": "Costa Rica",
        "latitude": -18.5432,
        "longitude": -53.1287
    },
    {
        "nome": "Cotegipe",
        "latitude": -12.0228,
        "longitude": -44.2566
    },
    {
        "nome": "Cotia",
        "latitude": -23.6022,
        "longitude": -46.919
    },
    {
        "nome": "Cotiporã",
        "latitude": -28.9891,
        "longitude": -51.6971
    },
    {
        "nome": "Cotriguaçu",
        "latitude": -9.85656,
        "longitude": -58.4192
    },
    {
        "nome": "Couto de Magalhães de Minas",
        "latitude": -18.0727,
        "longitude": -43.4648
    },
    {
        "nome": "Couto Magalhães",
        "latitude": -8.28411,
        "longitude": -49.2473
    },
    {
        "nome": "Coxilha",
        "latitude": -28.128,
        "longitude": -52.3023
    },
    {
        "nome": "Coxim",
        "latitude": -18.5013,
        "longitude": -54.751
    },
    {
        "nome": "Coxixola",
        "latitude": -7.62365,
        "longitude": -36.6064
    },
    {
        "nome": "Craíbas",
        "latitude": -9.6178,
        "longitude": -36.7697
    },
    {
        "nome": "Crateús",
        "latitude": -5.16768,
        "longitude": -40.6536
    },
    {
        "nome": "Crato",
        "latitude": -7.2153,
        "longitude": -39.4103
    },
    {
        "nome": "Cravinhos",
        "latitude": -21.338,
        "longitude": -47.7324
    },
    {
        "nome": "Cravolândia",
        "latitude": -13.3531,
        "longitude": -39.8031
    },
    {
        "nome": "Criciúma",
        "latitude": -28.6723,
        "longitude": -49.3729
    },
    {
        "nome": "Crisólita",
        "latitude": -17.2381,
        "longitude": -40.9184
    },
    {
        "nome": "Crisópolis",
        "latitude": -11.5059,
        "longitude": -38.1515
    },
    {
        "nome": "Crissiumal",
        "latitude": -27.4999,
        "longitude": -54.0994
    },
    {
        "nome": "Cristais",
        "latitude": -20.8733,
        "longitude": -45.5167
    },
    {
        "nome": "Cristais Paulista",
        "latitude": -20.4036,
        "longitude": -47.4209
    },
    {
        "nome": "Cristal",
        "latitude": -31.0046,
        "longitude": -52.0436
    },
    {
        "nome": "Cristal do Sul",
        "latitude": -27.452,
        "longitude": -53.2422
    },
    {
        "nome": "Cristalândia",
        "latitude": -10.5985,
        "longitude": -49.1942
    },
    {
        "nome": "Cristalândia do Piauí",
        "latitude": -10.6443,
        "longitude": -45.1893
    },
    {
        "nome": "Cristália",
        "latitude": -16.716,
        "longitude": -42.8571
    },
    {
        "nome": "Cristalina",
        "latitude": -16.7676,
        "longitude": -47.6131
    },
    {
        "nome": "Cristiano Otoni",
        "latitude": -20.8324,
        "longitude": -43.8166
    },
    {
        "nome": "Cristianópolis",
        "latitude": -17.1987,
        "longitude": -48.7034
    },
    {
        "nome": "Cristina",
        "latitude": -22.208,
        "longitude": -45.2673
    },
    {
        "nome": "Cristinápolis",
        "latitude": -11.4668,
        "longitude": -37.7585
    },
    {
        "nome": "Cristino Castro",
        "latitude": -8.82273,
        "longitude": -44.223
    },
    {
        "nome": "Cristópolis",
        "latitude": -12.2249,
        "longitude": -44.4214
    },
    {
        "nome": "Crixás",
        "latitude": -14.5412,
        "longitude": -49.974
    },
    {
        "nome": "Crixás do Tocantins",
        "latitude": -11.0994,
        "longitude": -48.9152
    },
    {
        "nome": "Croatá",
        "latitude": -4.40481,
        "longitude": -40.9022
    },
    {
        "nome": "Cromínia",
        "latitude": -17.2883,
        "longitude": -49.3798
    },
    {
        "nome": "Crucilândia",
        "latitude": -20.3923,
        "longitude": -44.3334
    },
    {
        "nome": "Cruz",
        "latitude": -2.91813,
        "longitude": -40.176
    },
    {
        "nome": "Cruz Alta",
        "latitude": -28.645,
        "longitude": -53.6048
    },
    {
        "nome": "Cruz das Almas",
        "latitude": -12.6675,
        "longitude": -39.1008
    },
    {
        "nome": "Cruz do Espírito Santo",
        "latitude": -7.13902,
        "longitude": -35.0857
    },
    {
        "nome": "Cruz Machado",
        "latitude": -26.0166,
        "longitude": -51.343
    },
    {
        "nome": "Cruzália",
        "latitude": -22.7373,
        "longitude": -50.7909
    },
    {
        "nome": "Cruzaltense",
        "latitude": -27.6672,
        "longitude": -52.6522
    },
    {
        "nome": "Cruzeiro",
        "latitude": -22.5728,
        "longitude": -44.969
    },
    {
        "nome": "Cruzeiro da Fortaleza",
        "latitude": -18.944,
        "longitude": -46.6669
    },
    {
        "nome": "Cruzeiro do Iguaçu",
        "latitude": -25.6192,
        "longitude": -53.1285
    },
    {
        "nome": "Cruzeiro do Oeste",
        "latitude": -23.7799,
        "longitude": -53.0774
    },
    {
        "nome": "Cruzeiro do Sul",
        "latitude": -22.9624,
        "longitude": -52.1622
    },
    {
        "nome": "Cruzeiro do Sul",
        "latitude": -29.5148,
        "longitude": -51.9928
    },
    {
        "nome": "Cruzeiro do Sul",
        "latitude": -7.62762,
        "longitude": -72.6756
    },
    {
        "nome": "Cruzeta",
        "latitude": -6.40894,
        "longitude": -36.7782
    },
    {
        "nome": "Cruzília",
        "latitude": -21.84,
        "longitude": -44.8067
    },
    {
        "nome": "Cruzmaltina",
        "latitude": -24.0132,
        "longitude": -51.4563
    },
    {
        "nome": "Cubatão",
        "latitude": -23.8911,
        "longitude": -46.424
    },
    {
        "nome": "Cubati",
        "latitude": -6.86686,
        "longitude": -36.3619
    },
    {
        "nome": "Cuiabá",
        "latitude": -15.601,
        "longitude": -56.0974
    },
    {
        "nome": "Cuité",
        "latitude": -6.47647,
        "longitude": -36.1515
    },
    {
        "nome": "Cuité de Mamanguape",
        "latitude": -6.91292,
        "longitude": -35.2502
    },
    {
        "nome": "Cuitegi",
        "latitude": -6.89058,
        "longitude": -35.5215
    },
    {
        "nome": "Cujubim",
        "latitude": -9.36065,
        "longitude": -62.5846
    },
    {
        "nome": "Cumari",
        "latitude": -18.2644,
        "longitude": -48.1511
    },
    {
        "nome": "Cumaru",
        "latitude": -8.00827,
        "longitude": -35.6957
    },
    {
        "nome": "Cumaru do Norte",
        "latitude": -7.81097,
        "longitude": -50.7698
    },
    {
        "nome": "Cumbe",
        "latitude": -10.352,
        "longitude": -37.1846
    },
    {
        "nome": "Cunha",
        "latitude": -23.0731,
        "longitude": -44.9576
    },
    {
        "nome": "Cunha Porã",
        "latitude": -26.895,
        "longitude": -53.1662
    },
    {
        "nome": "Cunhataí",
        "latitude": -26.9709,
        "longitude": -53.0895
    },
    {
        "nome": "Cuparaque",
        "latitude": -18.9648,
        "longitude": -41.0986
    },
    {
        "nome": "Cupira",
        "latitude": -8.62432,
        "longitude": -35.9518
    },
    {
        "nome": "Curaçá",
        "latitude": -8.98458,
        "longitude": -39.8997
    },
    {
        "nome": "Curimatá",
        "latitude": -10.0326,
        "longitude": -44.3002
    },
    {
        "nome": "Curionópolis",
        "latitude": -6.09965,
        "longitude": -49.6068
    },
    {
        "nome": "Curitiba",
        "latitude": -25.4195,
        "longitude": -49.2646
    },
    {
        "nome": "Curitibanos",
        "latitude": -27.2824,
        "longitude": -50.5816
    },
    {
        "nome": "Curiúva",
        "latitude": -24.0362,
        "longitude": -50.4576
    },
    {
        "nome": "Currais",
        "latitude": -9.01175,
        "longitude": -44.4062
    },
    {
        "nome": "Currais Novos",
        "latitude": -6.25484,
        "longitude": -36.5146
    },
    {
        "nome": "Curral de Cima",
        "latitude": -6.72325,
        "longitude": -35.2639
    },
    {
        "nome": "Curral de Dentro",
        "latitude": -15.9327,
        "longitude": -41.8557
    },
    {
        "nome": "Curral Novo do Piauí",
        "latitude": -7.8313,
        "longitude": -40.8957
    },
    {
        "nome": "Curral Velho",
        "latitude": -7.53075,
        "longitude": -38.1962
    },
    {
        "nome": "Curralinho",
        "latitude": -1.81179,
        "longitude": -49.7952
    },
    {
        "nome": "Curralinhos",
        "latitude": -5.60825,
        "longitude": -42.8376
    },
    {
        "nome": "Curuá",
        "latitude": -1.88775,
        "longitude": -55.1168
    },
    {
        "nome": "Curuçá",
        "latitude": -0.733214,
        "longitude": -47.8515
    },
    {
        "nome": "Cururupu",
        "latitude": -1.81475,
        "longitude": -44.8644
    },
    {
        "nome": "Curvelândia",
        "latitude": -15.6084,
        "longitude": -57.9133
    },
    {
        "nome": "Curvelo",
        "latitude": -18.7527,
        "longitude": -44.4303
    },
    {
        "nome": "Custódia",
        "latitude": -8.08546,
        "longitude": -37.6443
    },
    {
        "nome": "Cutias",
        "latitude": 0.970761,
        "longitude": -50.8005
    },
    {
        "nome": "Damianópolis",
        "latitude": -14.5604,
        "longitude": -46.178
    },
    {
        "nome": "Damião",
        "latitude": -6.63161,
        "longitude": -35.9101
    },
    {
        "nome": "Damolândia",
        "latitude": -16.2544,
        "longitude": -49.3631
    },
    {
        "nome": "Darcinópolis",
        "latitude": -6.71591,
        "longitude": -47.7597
    },
    {
        "nome": "Dário Meira",
        "latitude": -14.4229,
        "longitude": -39.9031
    },
    {
        "nome": "Datas",
        "latitude": -18.4478,
        "longitude": -43.6591
    },
    {
        "nome": "David Canabarro",
        "latitude": -28.3849,
        "longitude": -51.8482
    },
    {
        "nome": "Davinópolis",
        "latitude": -5.54637,
        "longitude": -47.4217
    },
    {
        "nome": "Davinópolis",
        "latitude": -18.1501,
        "longitude": -47.5568
    },
    {
        "nome": "Delfim Moreira",
        "latitude": -22.5036,
        "longitude": -45.2792
    },
    {
        "nome": "Delfinópolis",
        "latitude": -20.3468,
        "longitude": -46.8456
    },
    {
        "nome": "Delmiro Gouveia",
        "latitude": -9.38534,
        "longitude": -37.9987
    },
    {
        "nome": "Delta",
        "latitude": -19.9721,
        "longitude": -47.7841
    },
    {
        "nome": "Demerval Lobão",
        "latitude": -5.35875,
        "longitude": -42.6776
    },
    {
        "nome": "Denise",
        "latitude": -14.7324,
        "longitude": -57.0583
    },
    {
        "nome": "Deodápolis",
        "latitude": -22.2763,
        "longitude": -54.1682
    },
    {
        "nome": "Deputado Irapuan Pinheiro",
        "latitude": -5.91485,
        "longitude": -39.257
    },
    {
        "nome": "Derrubadas",
        "latitude": -27.2642,
        "longitude": -53.8645
    },
    {
        "nome": "Descalvado",
        "latitude": -21.9002,
        "longitude": -47.6181
    },
    {
        "nome": "Descanso",
        "latitude": -26.827,
        "longitude": -53.5034
    },
    {
        "nome": "Descoberto",
        "latitude": -21.46,
        "longitude": -42.9618
    },
    {
        "nome": "Desterro",
        "latitude": -7.287,
        "longitude": -37.0925
    },
    {
        "nome": "Desterro de Entre Rios",
        "latitude": -20.665,
        "longitude": -44.3334
    },
    {
        "nome": "Desterro do Melo",
        "latitude": -21.143,
        "longitude": -43.5178
    },
    {
        "nome": "Dezesseis de Novembro",
        "latitude": -28.219,
        "longitude": -55.0617
    },
    {
        "nome": "Diadema",
        "latitude": -23.6813,
        "longitude": -46.6205
    },
    {
        "nome": "Diamante",
        "latitude": -7.41738,
        "longitude": -38.2615
    },
    {
        "nome": "Diamante D'Oeste",
        "latitude": -24.9419,
        "longitude": -54.1052
    },
    {
        "nome": "Diamante do Norte",
        "latitude": -22.655,
        "longitude": -52.8617
    },
    {
        "nome": "Diamante do Sul",
        "latitude": -25.035,
        "longitude": -52.6768
    },
    {
        "nome": "Diamantina",
        "latitude": -18.2413,
        "longitude": -43.6031
    },
    {
        "nome": "Diamantino",
        "latitude": -14.4037,
        "longitude": -56.4366
    },
    {
        "nome": "Dianópolis",
        "latitude": -11.624,
        "longitude": -46.8198
    },
    {
        "nome": "Dias d'Ávila",
        "latitude": -12.6187,
        "longitude": -38.2926
    },
    {
        "nome": "Dilermando de Aguiar",
        "latitude": -29.7054,
        "longitude": -54.2122
    },
    {
        "nome": "Diogo de Vasconcelos",
        "latitude": -20.4879,
        "longitude": -43.1953
    },
    {
        "nome": "Dionísio",
        "latitude": -19.8433,
        "longitude": -42.7701
    },
    {
        "nome": "Dionísio Cerqueira",
        "latitude": -26.2648,
        "longitude": -53.6351
    },
    {
        "nome": "Diorama",
        "latitude": -16.2329,
        "longitude": -51.2543
    },
    {
        "nome": "Dirce Reis",
        "latitude": -20.4642,
        "longitude": -50.6073
    },
    {
        "nome": "Dirceu Arcoverde",
        "latitude": -9.33939,
        "longitude": -42.4348
    },
    {
        "nome": "Divina Pastora",
        "latitude": -10.6782,
        "longitude": -37.1506
    },
    {
        "nome": "Divinésia",
        "latitude": -20.9917,
        "longitude": -43.0003
    },
    {
        "nome": "Divino",
        "latitude": -20.6134,
        "longitude": -42.1438
    },
    {
        "nome": "Divino das Laranjeiras",
        "latitude": -18.7755,
        "longitude": -41.4781
    },
    {
        "nome": "Divino de São Lourenço",
        "latitude": -20.6229,
        "longitude": -41.6937
    },
    {
        "nome": "Divinolândia",
        "latitude": -21.6637,
        "longitude": -46.7361
    },
    {
        "nome": "Divinolândia de Minas",
        "latitude": -18.8004,
        "longitude": -42.6103
    },
    {
        "nome": "Divinópolis",
        "latitude": -20.1446,
        "longitude": -44.8912
    },
    {
        "nome": "Divinópolis de Goiás",
        "latitude": -13.2853,
        "longitude": -46.3999
    },
    {
        "nome": "Divinópolis do Tocantins",
        "latitude": -9.80018,
        "longitude": -49.2169
    },
    {
        "nome": "Divisa Alegre",
        "latitude": -15.7221,
        "longitude": -41.3463
    },
    {
        "nome": "Divisa Nova",
        "latitude": -21.5092,
        "longitude": -46.1904
    },
    {
        "nome": "Divisópolis",
        "latitude": -15.7254,
        "longitude": -40.9997
    },
    {
        "nome": "Dobrada",
        "latitude": -21.5155,
        "longitude": -48.3935
    },
    {
        "nome": "Dois Córregos",
        "latitude": -22.3673,
        "longitude": -48.3819
    },
    {
        "nome": "Dois Irmãos",
        "latitude": -29.5836,
        "longitude": -51.0898
    },
    {
        "nome": "Dois Irmãos das Missões",
        "latitude": -27.6621,
        "longitude": -53.5304
    },
    {
        "nome": "Dois Irmãos do Buriti",
        "latitude": -20.6848,
        "longitude": -55.2915
    },
    {
        "nome": "Dois Irmãos do Tocantins",
        "latitude": -9.25534,
        "longitude": -49.0638
    },
    {
        "nome": "Dois Lajeados",
        "latitude": -28.983,
        "longitude": -51.8396
    },
    {
        "nome": "Dois Riachos",
        "latitude": -9.38465,
        "longitude": -37.0965
    },
    {
        "nome": "Dois Vizinhos",
        "latitude": -25.7407,
        "longitude": -53.057
    },
    {
        "nome": "Dolcinópolis",
        "latitude": -20.124,
        "longitude": -50.5149
    },
    {
        "nome": "Dom Aquino",
        "latitude": -15.8099,
        "longitude": -54.9223
    },
    {
        "nome": "Dom Basílio",
        "latitude": -13.7565,
        "longitude": -41.7677
    },
    {
        "nome": "Dom Bosco",
        "latitude": -16.652,
        "longitude": -46.2597
    },
    {
        "nome": "Dom Cavati",
        "latitude": -19.3735,
        "longitude": -42.1121
    },
    {
        "nome": "Dom Eliseu",
        "latitude": -4.19944,
        "longitude": -47.8245
    },
    {
        "nome": "Dom Expedito Lopes",
        "latitude": -6.95332,
        "longitude": -41.6396
    },
    {
        "nome": "Dom Feliciano",
        "latitude": -30.7004,
        "longitude": -52.1026
    },
    {
        "nome": "Dom Inocêncio",
        "latitude": -9.00516,
        "longitude": -41.9697
    },
    {
        "nome": "Dom Joaquim",
        "latitude": -18.961,
        "longitude": -43.2544
    },
    {
        "nome": "Dom Macedo Costa",
        "latitude": -12.9016,
        "longitude": -39.1923
    },
    {
        "nome": "Dom Pedrito",
        "latitude": -30.9756,
        "longitude": -54.6694
    },
    {
        "nome": "Dom Pedro",
        "latitude": -5.03518,
        "longitude": -44.4409
    },
    {
        "nome": "Dom Pedro de Alcântara",
        "latitude": -29.3639,
        "longitude": -49.853
    },
    {
        "nome": "Dom Silvério",
        "latitude": -20.1627,
        "longitude": -42.9627
    },
    {
        "nome": "Dom Viçoso",
        "latitude": -22.2511,
        "longitude": -45.1643
    },
    {
        "nome": "Domingos Martins",
        "latitude": -20.3603,
        "longitude": -40.6594
    },
    {
        "nome": "Domingos Mourão",
        "latitude": -4.2495,
        "longitude": -41.2683
    },
    {
        "nome": "Dona Emma",
        "latitude": -26.981,
        "longitude": -49.7261
    },
    {
        "nome": "Dona Eusébia",
        "latitude": -21.319,
        "longitude": -42.807
    },
    {
        "nome": "Dona Francisca",
        "latitude": -29.6195,
        "longitude": -53.3617
    },
    {
        "nome": "Dona Inês",
        "latitude": -6.61566,
        "longitude": -35.6205
    },
    {
        "nome": "Dores de Campos",
        "latitude": -21.1139,
        "longitude": -44.0207
    },
    {
        "nome": "Dores de Guanhães",
        "latitude": -19.0516,
        "longitude": -42.9254
    },
    {
        "nome": "Dores do Indaiá",
        "latitude": -19.4628,
        "longitude": -45.5927
    },
    {
        "nome": "Dores do Rio Preto",
        "latitude": -20.6931,
        "longitude": -41.8405
    },
    {
        "nome": "Dores do Turvo",
        "latitude": -20.9785,
        "longitude": -43.1834
    },
    {
        "nome": "Doresópolis",
        "latitude": -20.2868,
        "longitude": -45.9007
    },
    {
        "nome": "Dormentes",
        "latitude": -8.44116,
        "longitude": -40.7662
    },
    {
        "nome": "Douradina",
        "latitude": -22.0405,
        "longitude": -54.6158
    },
    {
        "nome": "Douradina",
        "latitude": -23.3807,
        "longitude": -53.2918
    },
    {
        "nome": "Dourado",
        "latitude": -22.1044,
        "longitude": -48.3178
    },
    {
        "nome": "Douradoquara",
        "latitude": -18.4338,
        "longitude": -47.5993
    },
    {
        "nome": "Dourados",
        "latitude": -22.2231,
        "longitude": -54.812
    },
    {
        "nome": "Doutor Camargo",
        "latitude": -23.5582,
        "longitude": -52.2178
    },
    {
        "nome": "Doutor Maurício Cardoso",
        "latitude": -27.5103,
        "longitude": -54.3577
    },
    {
        "nome": "Doutor Pedrinho",
        "latitude": -26.7174,
        "longitude": -49.4795
    },
    {
        "nome": "Doutor Ricardo",
        "latitude": -29.084,
        "longitude": -51.9972
    },
    {
        "nome": "Doutor Severiano",
        "latitude": -6.08082,
        "longitude": -38.3794
    },
    {
        "nome": "Doutor Ulysses",
        "latitude": -24.5665,
        "longitude": -49.4219
    },
    {
        "nome": "Doverlândia",
        "latitude": -16.7188,
        "longitude": -52.3189
    },
    {
        "nome": "Dracena",
        "latitude": -21.4843,
        "longitude": -51.535
    },
    {
        "nome": "Duartina",
        "latitude": -22.4146,
        "longitude": -49.4084
    },
    {
        "nome": "Duas Barras",
        "latitude": -22.0536,
        "longitude": -42.5232
    },
    {
        "nome": "Duas Estradas",
        "latitude": -6.68499,
        "longitude": -35.418
    },
    {
        "nome": "Dueré",
        "latitude": -11.3416,
        "longitude": -49.2716
    },
    {
        "nome": "Dumont",
        "latitude": -21.2324,
        "longitude": -47.9756
    },
    {
        "nome": "Duque Bacelar",
        "latitude": -4.15002,
        "longitude": -42.9477
    },
    {
        "nome": "Duque de Caxias",
        "latitude": -22.7858,
        "longitude": -43.3049
    },
    {
        "nome": "Durandé",
        "latitude": -20.2058,
        "longitude": -41.7977
    },
    {
        "nome": "Echaporã",
        "latitude": -22.4326,
        "longitude": -50.2038
    },
    {
        "nome": "Ecoporanga",
        "latitude": -18.3702,
        "longitude": -40.836
    },
    {
        "nome": "Edealina",
        "latitude": -17.4239,
        "longitude": -49.6644
    },
    {
        "nome": "Edéia",
        "latitude": -17.3406,
        "longitude": -49.9295
    },
    {
        "nome": "Eirunepé",
        "latitude": -6.65677,
        "longitude": -69.8662
    },
    {
        "nome": "Eldorado",
        "latitude": -23.7868,
        "longitude": -54.2838
    },
    {
        "nome": "Eldorado",
        "latitude": -24.5281,
        "longitude": -48.1141
    },
    {
        "nome": "Eldorado do Carajás",
        "latitude": -6.10389,
        "longitude": -49.3553
    },
    {
        "nome": "Eldorado do Sul",
        "latitude": -30.0847,
        "longitude": -51.6187
    },
    {
        "nome": "Elesbão Veloso",
        "latitude": -6.19947,
        "longitude": -42.1355
    },
    {
        "nome": "Elias Fausto",
        "latitude": -23.0428,
        "longitude": -47.3682
    },
    {
        "nome": "Eliseu Martins",
        "latitude": -8.09629,
        "longitude": -43.6705
    },
    {
        "nome": "Elisiário",
        "latitude": -21.1678,
        "longitude": -49.1146
    },
    {
        "nome": "Elísio Medrado",
        "latitude": -12.9417,
        "longitude": -39.5191
    },
    {
        "nome": "Elói Mendes",
        "latitude": -21.6088,
        "longitude": -45.5691
    },
    {
        "nome": "Emas",
        "latitude": -7.09964,
        "longitude": -37.7163
    },
    {
        "nome": "Embaúba",
        "latitude": -20.9796,
        "longitude": -48.8325
    },
    {
        "nome": "Embu das Artes",
        "latitude": -23.6437,
        "longitude": -46.8579
    },
    {
        "nome": "Embu-Guaçu",
        "latitude": -23.8297,
        "longitude": -46.8136
    },
    {
        "nome": "Emilianópolis",
        "latitude": -21.8314,
        "longitude": -51.4832
    },
    {
        "nome": "Encantado",
        "latitude": -29.2351,
        "longitude": -51.8703
    },
    {
        "nome": "Encanto",
        "latitude": -6.10691,
        "longitude": -38.3033
    },
    {
        "nome": "Encruzilhada",
        "latitude": -15.5302,
        "longitude": -40.9124
    },
    {
        "nome": "Encruzilhada do Sul",
        "latitude": -30.543,
        "longitude": -52.5204
    },
    {
        "nome": "Enéas Marques",
        "latitude": -25.9445,
        "longitude": -53.1659
    },
    {
        "nome": "Engenheiro Beltrão",
        "latitude": -23.797,
        "longitude": -52.2659
    },
    {
        "nome": "Engenheiro Caldas",
        "latitude": -19.2065,
        "longitude": -42.0503
    },
    {
        "nome": "Engenheiro Coelho",
        "latitude": -22.4836,
        "longitude": -47.211
    },
    {
        "nome": "Engenheiro Navarro",
        "latitude": -17.2831,
        "longitude": -43.947
    },
    {
        "nome": "Engenheiro Paulo de Frontin",
        "latitude": -22.5498,
        "longitude": -43.6827
    },
    {
        "nome": "Engenho Velho",
        "latitude": -27.706,
        "longitude": -52.9145
    },
    {
        "nome": "Entre Folhas",
        "latitude": -19.6218,
        "longitude": -42.2306
    },
    {
        "nome": "Entre Rios",
        "latitude": -11.9392,
        "longitude": -38.0871
    },
    {
        "nome": "Entre Rios",
        "latitude": -26.7225,
        "longitude": -52.5585
    },
    {
        "nome": "Entre Rios de Minas",
        "latitude": -20.6706,
        "longitude": -44.0654
    },
    {
        "nome": "Entre Rios do Oeste",
        "latitude": -24.7042,
        "longitude": -54.2385
    },
    {
        "nome": "Entre Rios do Sul",
        "latitude": -27.5298,
        "longitude": -52.7347
    },
    {
        "nome": "Entre-Ijuís",
        "latitude": -28.3686,
        "longitude": -54.2686
    },
    {
        "nome": "Envira",
        "latitude": -7.43789,
        "longitude": -70.0281
    },
    {
        "nome": "Epitaciolândia",
        "latitude": -11.0188,
        "longitude": -68.7341
    },
    {
        "nome": "Equador",
        "latitude": -6.93835,
        "longitude": -36.717
    },
    {
        "nome": "Erebango",
        "latitude": -27.8544,
        "longitude": -52.3005
    },
    {
        "nome": "Erechim",
        "latitude": -27.6364,
        "longitude": -52.2697
    },
    {
        "nome": "Ererê",
        "latitude": -6.02751,
        "longitude": -38.3461
    },
    {
        "nome": "Érico Cardoso",
        "latitude": -13.4215,
        "longitude": -42.1352
    },
    {
        "nome": "Ermo",
        "latitude": -28.9869,
        "longitude": -49.643
    },
    {
        "nome": "Ernestina",
        "latitude": -28.4977,
        "longitude": -52.5836
    },
    {
        "nome": "Erval Grande",
        "latitude": -27.3926,
        "longitude": -52.574
    },
    {
        "nome": "Erval Seco",
        "latitude": -27.5443,
        "longitude": -53.5005
    },
    {
        "nome": "Erval Velho",
        "latitude": -27.2743,
        "longitude": -51.443
    },
    {
        "nome": "Ervália",
        "latitude": -20.8403,
        "longitude": -42.6544
    },
    {
        "nome": "Escada",
        "latitude": -8.35672,
        "longitude": -35.2241
    },
    {
        "nome": "Esmeralda",
        "latitude": -28.0518,
        "longitude": -51.1933
    },
    {
        "nome": "Esmeraldas",
        "latitude": -19.764,
        "longitude": -44.3065
    },
    {
        "nome": "Espera Feliz",
        "latitude": -20.6508,
        "longitude": -41.9119
    },
    {
        "nome": "Esperança",
        "latitude": -7.02278,
        "longitude": -35.8597
    },
    {
        "nome": "Esperança do Sul",
        "latitude": -27.3603,
        "longitude": -53.9891
    },
    {
        "nome": "Esperança Nova",
        "latitude": -23.7238,
        "longitude": -53.811
    },
    {
        "nome": "Esperantina",
        "latitude": -5.36593,
        "longitude": -48.5378
    },
    {
        "nome": "Esperantina",
        "latitude": -3.88863,
        "longitude": -42.2324
    },
    {
        "nome": "Esperantinópolis",
        "latitude": -4.87938,
        "longitude": -44.6926
    },
    {
        "nome": "Espigão Alto do Iguaçu",
        "latitude": -25.4216,
        "longitude": -52.8348
    },
    {
        "nome": "Espigão D'Oeste",
        "latitude": -11.5266,
        "longitude": -61.0252
    },
    {
        "nome": "Espinosa",
        "latitude": -14.9249,
        "longitude": -42.809
    },
    {
        "nome": "Espírito Santo",
        "latitude": -6.33563,
        "longitude": -35.3052
    },
    {
        "nome": "Espírito Santo do Dourado",
        "latitude": -22.0454,
        "longitude": -45.9548
    },
    {
        "nome": "Espírito Santo do Pinhal",
        "latitude": -22.1909,
        "longitude": -46.7477
    },
    {
        "nome": "Espírito Santo do Turvo",
        "latitude": -22.6925,
        "longitude": -49.4341
    },
    {
        "nome": "Esplanada",
        "latitude": -11.7942,
        "longitude": -37.9432
    },
    {
        "nome": "Espumoso",
        "latitude": -28.7286,
        "longitude": -52.8461
    },
    {
        "nome": "Estação",
        "latitude": -27.9135,
        "longitude": -52.2635
    },
    {
        "nome": "Estância",
        "latitude": -11.2659,
        "longitude": -37.4484
    },
    {
        "nome": "Estância Velha",
        "latitude": -29.6535,
        "longitude": -51.1843
    },
    {
        "nome": "Esteio",
        "latitude": -29.852,
        "longitude": -51.1841
    },
    {
        "nome": "Estiva",
        "latitude": -22.4577,
        "longitude": -46.0191
    },
    {
        "nome": "Estiva Gerbi",
        "latitude": -22.2713,
        "longitude": -46.9481
    },
    {
        "nome": "Estreito",
        "latitude": -6.56077,
        "longitude": -47.4431
    },
    {
        "nome": "Estrela",
        "latitude": -29.5002,
        "longitude": -51.9495
    },
    {
        "nome": "Estrela d'Oeste",
        "latitude": -20.2875,
        "longitude": -50.4049
    },
    {
        "nome": "Estrela Dalva",
        "latitude": -21.7412,
        "longitude": -42.4574
    },
    {
        "nome": "Estrela de Alagoas",
        "latitude": -9.39089,
        "longitude": -36.7644
    },
    {
        "nome": "Estrela do Indaiá",
        "latitude": -19.5169,
        "longitude": -45.7859
    },
    {
        "nome": "Estrela do Norte",
        "latitude": -13.8665,
        "longitude": -49.0716
    },
    {
        "nome": "Estrela do Norte",
        "latitude": -22.4859,
        "longitude": -51.6632
    },
    {
        "nome": "Estrela do Sul",
        "latitude": -18.7399,
        "longitude": -47.6956
    },
    {
        "nome": "Estrela Velha",
        "latitude": -29.1713,
        "longitude": -53.1639
    },
    {
        "nome": "Euclides da Cunha",
        "latitude": -10.5078,
        "longitude": -39.0153
    },
    {
        "nome": "Euclides da Cunha Paulista",
        "latitude": -22.5545,
        "longitude": -52.5928
    },
    {
        "nome": "Eugênio de Castro",
        "latitude": -28.5315,
        "longitude": -54.1506
    },
    {
        "nome": "Eugenópolis",
        "latitude": -21.1002,
        "longitude": -42.1878
    },
    {
        "nome": "Eunápolis",
        "latitude": -16.3715,
        "longitude": -39.5821
    },
    {
        "nome": "Eusébio",
        "latitude": -3.8925,
        "longitude": -38.4559
    },
    {
        "nome": "Ewbank da Câmara",
        "latitude": -21.5498,
        "longitude": -43.5068
    },
    {
        "nome": "Extrema",
        "latitude": -22.854,
        "longitude": -46.3178
    },
    {
        "nome": "Extremoz",
        "latitude": -5.70143,
        "longitude": -35.3048
    },
    {
        "nome": "Exu",
        "latitude": -7.50364,
        "longitude": -39.7238
    },
    {
        "nome": "Fagundes",
        "latitude": -7.34454,
        "longitude": -35.7931
    },
    {
        "nome": "Fagundes Varela",
        "latitude": -28.8794,
        "longitude": -51.7014
    },
    {
        "nome": "Faina",
        "latitude": -15.4473,
        "longitude": -50.3622
    },
    {
        "nome": "Fama",
        "latitude": -21.4089,
        "longitude": -45.8286
    },
    {
        "nome": "Faria Lemos",
        "latitude": -20.8097,
        "longitude": -42.0213
    },
    {
        "nome": "Farias Brito",
        "latitude": -6.92146,
        "longitude": -39.5651
    },
    {
        "nome": "Faro",
        "latitude": -2.16805,
        "longitude": -56.7405
    },
    {
        "nome": "Farol",
        "latitude": -24.0958,
        "longitude": -52.6217
    },
    {
        "nome": "Farroupilha",
        "latitude": -29.2227,
        "longitude": -51.3419
    },
    {
        "nome": "Fartura",
        "latitude": -23.3916,
        "longitude": -49.5124
    },
    {
        "nome": "Fartura do Piauí",
        "latitude": -9.48342,
        "longitude": -42.7912
    },
    {
        "nome": "Fátima",
        "latitude": -10.7603,
        "longitude": -48.9076
    },
    {
        "nome": "Fátima",
        "latitude": -10.616,
        "longitude": -38.2239
    },
    {
        "nome": "Fátima do Sul",
        "latitude": -22.3789,
        "longitude": -54.5131
    },
    {
        "nome": "Faxinal",
        "latitude": -24.0077,
        "longitude": -51.3227
    },
    {
        "nome": "Faxinal do Soturno",
        "latitude": -29.5788,
        "longitude": -53.4484
    },
    {
        "nome": "Faxinal dos Guedes",
        "latitude": -26.8451,
        "longitude": -52.2596
    },
    {
        "nome": "Faxinalzinho",
        "latitude": -27.4238,
        "longitude": -52.6789
    },
    {
        "nome": "Fazenda Nova",
        "latitude": -16.1834,
        "longitude": -50.7781
    },
    {
        "nome": "Fazenda Rio Grande",
        "latitude": -25.6624,
        "longitude": -49.3073
    },
    {
        "nome": "Fazenda Vilanova",
        "latitude": -29.5885,
        "longitude": -51.8217
    },
    {
        "nome": "Feijó",
        "latitude": -8.17054,
        "longitude": -70.351
    },
    {
        "nome": "Feira da Mata",
        "latitude": -14.2044,
        "longitude": -44.2744
    },
    {
        "nome": "Feira de Santana",
        "latitude": -12.2664,
        "longitude": -38.9663
    },
    {
        "nome": "Feira Grande",
        "latitude": -9.89859,
        "longitude": -36.6815
    },
    {
        "nome": "Feira Nova",
        "latitude": -7.94704,
        "longitude": -35.3801
    },
    {
        "nome": "Feira Nova",
        "latitude": -10.2616,
        "longitude": -37.3147
    },
    {
        "nome": "Feira Nova do Maranhão",
        "latitude": -6.96508,
        "longitude": -46.6786
    },
    {
        "nome": "Felício dos Santos",
        "latitude": -18.0755,
        "longitude": -43.2422
    },
    {
        "nome": "Felipe Guerra",
        "latitude": -5.59274,
        "longitude": -37.6875
    },
    {
        "nome": "Felisburgo",
        "latitude": -16.6348,
        "longitude": -40.7605
    },
    {
        "nome": "Felixlândia",
        "latitude": -18.7507,
        "longitude": -44.9004
    },
    {
        "nome": "Feliz",
        "latitude": -29.4527,
        "longitude": -51.3032
    },
    {
        "nome": "Feliz Deserto",
        "latitude": -10.2935,
        "longitude": -36.3028
    },
    {
        "nome": "Feliz Natal",
        "latitude": -12.385,
        "longitude": -54.9227
    },
    {
        "nome": "Fênix",
        "latitude": -23.9135,
        "longitude": -51.9805
    },
    {
        "nome": "Fernandes Pinheiro",
        "latitude": -25.4107,
        "longitude": -50.5456
    },
    {
        "nome": "Fernandes Tourinho",
        "latitude": -19.1541,
        "longitude": -42.0803
    },
    {
        "nome": "Fernando de Noronha",
        "latitude": -3.8396,
        "longitude": -32.4107
    },
    {
        "nome": "Fernando Falcão",
        "latitude": -6.16207,
        "longitude": -44.8979
    },
    {
        "nome": "Fernando Pedroza",
        "latitude": -5.69096,
        "longitude": -36.5282
    },
    {
        "nome": "Fernando Prestes",
        "latitude": -21.2661,
        "longitude": -48.6874
    },
    {
        "nome": "Fernandópolis",
        "latitude": -20.2806,
        "longitude": -50.2471
    },
    {
        "nome": "Fernão",
        "latitude": -22.3607,
        "longitude": -49.5187
    },
    {
        "nome": "Ferraz de Vasconcelos",
        "latitude": -23.5411,
        "longitude": -46.371
    },
    {
        "nome": "Ferreira Gomes",
        "latitude": 0.857256,
        "longitude": -51.1795
    },
    {
        "nome": "Ferreiros",
        "latitude": -7.44666,
        "longitude": -35.2373
    },
    {
        "nome": "Ferros",
        "latitude": -19.2343,
        "longitude": -43.0192
    },
    {
        "nome": "Fervedouro",
        "latitude": -20.726,
        "longitude": -42.279
    },
    {
        "nome": "Figueira",
        "latitude": -23.8455,
        "longitude": -50.4031
    },
    {
        "nome": "Figueirão",
        "latitude": -18.6782,
        "longitude": -53.638
    },
    {
        "nome": "Figueirópolis",
        "latitude": -12.1312,
        "longitude": -49.1748
    },
    {
        "nome": "Figueirópolis D'Oeste",
        "latitude": -15.4439,
        "longitude": -58.7391
    },
    {
        "nome": "Filadélfia",
        "latitude": -7.33501,
        "longitude": -47.4954
    },
    {
        "nome": "Filadélfia",
        "latitude": -10.7405,
        "longitude": -40.1437
    },
    {
        "nome": "Firmino Alves",
        "latitude": -14.9823,
        "longitude": -39.9269
    },
    {
        "nome": "Firminópolis",
        "latitude": -16.5778,
        "longitude": -50.304
    },
    {
        "nome": "Flexeiras",
        "latitude": -9.27281,
        "longitude": -35.7139
    },
    {
        "nome": "Flor da Serra do Sul",
        "latitude": -26.2523,
        "longitude": -53.3092
    },
    {
        "nome": "Flor do Sertão",
        "latitude": -26.7811,
        "longitude": -53.3505
    },
    {
        "nome": "Flora Rica",
        "latitude": -21.6727,
        "longitude": -51.3821
    },
    {
        "nome": "Floraí",
        "latitude": -23.3178,
        "longitude": -52.3029
    },
    {
        "nome": "Florânia",
        "latitude": -6.12264,
        "longitude": -36.8226
    },
    {
        "nome": "Floreal",
        "latitude": -20.6752,
        "longitude": -50.1513
    },
    {
        "nome": "Flores",
        "latitude": -7.85842,
        "longitude": -37.9715
    },
    {
        "nome": "Flores da Cunha",
        "latitude": -29.0261,
        "longitude": -51.1875
    },
    {
        "nome": "Flores de Goiás",
        "latitude": -14.4451,
        "longitude": -47.0417
    },
    {
        "nome": "Flores do Piauí",
        "latitude": -7.78793,
        "longitude": -42.918
    },
    {
        "nome": "Floresta",
        "latitude": -23.6031,
        "longitude": -52.0807
    },
    {
        "nome": "Floresta",
        "latitude": -8.60307,
        "longitude": -38.5687
    },
    {
        "nome": "Floresta Azul",
        "latitude": -14.8629,
        "longitude": -39.6579
    },
    {
        "nome": "Floresta do Araguaia",
        "latitude": -7.55335,
        "longitude": -49.7125
    },
    {
        "nome": "Floresta do Piauí",
        "latitude": -7.46682,
        "longitude": -41.7883
    },
    {
        "nome": "Florestal",
        "latitude": -19.888,
        "longitude": -44.4318
    },
    {
        "nome": "Florestópolis",
        "latitude": -22.8623,
        "longitude": -51.3882
    },
    {
        "nome": "Floriano",
        "latitude": -6.77182,
        "longitude": -43.0241
    },
    {
        "nome": "Floriano Peixoto",
        "latitude": -27.8614,
        "longitude": -52.0838
    },
    {
        "nome": "Florianópolis",
        "latitude": -27.5945,
        "longitude": -48.5477
    },
    {
        "nome": "Flórida",
        "latitude": -23.0847,
        "longitude": -51.9546
    },
    {
        "nome": "Flórida Paulista",
        "latitude": -21.6127,
        "longitude": -51.1724
    },
    {
        "nome": "Florínia",
        "latitude": -22.868,
        "longitude": -50.6814
    },
    {
        "nome": "Fonte Boa",
        "latitude": -2.52342,
        "longitude": -66.0942
    },
    {
        "nome": "Fontoura Xavier",
        "latitude": -28.9817,
        "longitude": -52.3445
    },
    {
        "nome": "Formiga",
        "latitude": -20.4618,
        "longitude": -45.4268
    },
    {
        "nome": "Formigueiro",
        "latitude": -30.0035,
        "longitude": -53.4959
    },
    {
        "nome": "Formosa",
        "latitude": -15.54,
        "longitude": -47.337
    },
    {
        "nome": "Formosa da Serra Negra",
        "latitude": -6.44017,
        "longitude": -46.1916
    },
    {
        "nome": "Formosa do Oeste",
        "latitude": -24.2951,
        "longitude": -53.3114
    },
    {
        "nome": "Formosa do Rio Preto",
        "latitude": -11.0328,
        "longitude": -45.193
    },
    {
        "nome": "Formosa do Sul",
        "latitude": -26.6453,
        "longitude": -52.7946
    },
    {
        "nome": "Formoso",
        "latitude": -13.6499,
        "longitude": -48.8775
    },
    {
        "nome": "Formoso",
        "latitude": -14.9446,
        "longitude": -46.2371
    },
    {
        "nome": "Formoso do Araguaia",
        "latitude": -11.7976,
        "longitude": -49.5316
    },
    {
        "nome": "Forquetinha",
        "latitude": -29.3828,
        "longitude": -52.0981
    },
    {
        "nome": "Forquilha",
        "latitude": -3.79945,
        "longitude": -40.2634
    },
    {
        "nome": "Forquilhinha",
        "latitude": -28.7454,
        "longitude": -49.4785
    },
    {
        "nome": "Fortaleza",
        "latitude": -3.71664,
        "longitude": -38.5423
    },
    {
        "nome": "Fortaleza de Minas",
        "latitude": -20.8508,
        "longitude": -46.712
    },
    {
        "nome": "Fortaleza do Tabocão",
        "latitude": -9.05611,
        "longitude": -48.5206
    },
    {
        "nome": "Fortaleza dos Nogueiras",
        "latitude": -6.95983,
        "longitude": -46.1749
    },
    {
        "nome": "Fortaleza dos Valos",
        "latitude": -28.7986,
        "longitude": -53.2249
    },
    {
        "nome": "Fortim",
        "latitude": -4.45126,
        "longitude": -37.7981
    },
    {
        "nome": "Fortuna",
        "latitude": -5.72792,
        "longitude": -44.1565
    },
    {
        "nome": "Fortuna de Minas",
        "latitude": -19.5578,
        "longitude": -44.4472
    },
    {
        "nome": "Foz do Iguaçu",
        "latitude": -25.5427,
        "longitude": -54.5827
    },
    {
        "nome": "Foz do Jordão",
        "latitude": -25.7371,
        "longitude": -52.1188
    },
    {
        "nome": "Fraiburgo",
        "latitude": -27.0233,
        "longitude": -50.92
    },
    {
        "nome": "Franca",
        "latitude": -20.5352,
        "longitude": -47.4039
    },
    {
        "nome": "Francinópolis",
        "latitude": -6.39334,
        "longitude": -42.2591
    },
    {
        "nome": "Francisco Alves",
        "latitude": -24.0667,
        "longitude": -53.8461
    },
    {
        "nome": "Francisco Ayres",
        "latitude": -6.62606,
        "longitude": -42.6881
    },
    {
        "nome": "Francisco Badaró",
        "latitude": -16.9883,
        "longitude": -42.3568
    },
    {
        "nome": "Francisco Beltrão",
        "latitude": -26.0817,
        "longitude": -53.0535
    },
    {
        "nome": "Francisco Dantas",
        "latitude": -6.07234,
        "longitude": -38.1212
    },
    {
        "nome": "Francisco Dumont",
        "latitude": -17.3107,
        "longitude": -44.2317
    },
    {
        "nome": "Francisco Macedo",
        "latitude": -7.331,
        "longitude": -40.788
    },
    {
        "nome": "Francisco Morato",
        "latitude": -23.2792,
        "longitude": -46.7448
    },
    {
        "nome": "Francisco Sá",
        "latitude": -16.4827,
        "longitude": -43.4896
    },
    {
        "nome": "Francisco Santos",
        "latitude": -6.99491,
        "longitude": -41.1288
    },
    {
        "nome": "Franciscópolis",
        "latitude": -17.9578,
        "longitude": -42.0094
    },
    {
        "nome": "Franco da Rocha",
        "latitude": -23.3229,
        "longitude": -46.729
    },
    {
        "nome": "Frecheirinha",
        "latitude": -3.75557,
        "longitude": -40.818
    },
    {
        "nome": "Frederico Westphalen",
        "latitude": -27.3586,
        "longitude": -53.3958
    },
    {
        "nome": "Frei Gaspar",
        "latitude": -18.0709,
        "longitude": -41.4325
    },
    {
        "nome": "Frei Inocêncio",
        "latitude": -18.5556,
        "longitude": -41.9121
    },
    {
        "nome": "Frei Lagonegro",
        "latitude": -18.1751,
        "longitude": -42.7617
    },
    {
        "nome": "Frei Martinho",
        "latitude": -6.39759,
        "longitude": -36.4526
    },
    {
        "nome": "Frei Miguelinho",
        "latitude": -7.93918,
        "longitude": -35.9113
    },
    {
        "nome": "Frei Paulo",
        "latitude": -10.5513,
        "longitude": -37.5279
    },
    {
        "nome": "Frei Rogério",
        "latitude": -27.175,
        "longitude": -50.8076
    },
    {
        "nome": "Fronteira",
        "latitude": -20.2748,
        "longitude": -49.1984
    },
    {
        "nome": "Fronteira dos Vales",
        "latitude": -16.8898,
        "longitude": -40.923
    },
    {
        "nome": "Fronteiras",
        "latitude": -7.08173,
        "longitude": -40.6146
    },
    {
        "nome": "Fruta de Leite",
        "latitude": -16.1225,
        "longitude": -42.5288
    },
    {
        "nome": "Frutal",
        "latitude": -20.0259,
        "longitude": -48.9355
    },
    {
        "nome": "Frutuoso Gomes",
        "latitude": -6.15669,
        "longitude": -37.8375
    },
    {
        "nome": "Fundão",
        "latitude": -19.937,
        "longitude": -40.4078
    },
    {
        "nome": "Funilândia",
        "latitude": -19.3661,
        "longitude": -44.061
    },
    {
        "nome": "Gabriel Monteiro",
        "latitude": -21.5294,
        "longitude": -50.5573
    },
    {
        "nome": "Gado Bravo",
        "latitude": -7.58279,
        "longitude": -35.7899
    },
    {
        "nome": "Gália",
        "latitude": -22.2918,
        "longitude": -49.5504
    },
    {
        "nome": "Galiléia",
        "latitude": -19.0005,
        "longitude": -41.5387
    },
    {
        "nome": "Galinhos",
        "latitude": -5.0909,
        "longitude": -36.2754
    },
    {
        "nome": "Galvão",
        "latitude": -26.4549,
        "longitude": -52.6875
    },
    {
        "nome": "Gameleira",
        "latitude": -8.5798,
        "longitude": -35.3846
    },
    {
        "nome": "Gameleira de Goiás",
        "latitude": -16.4854,
        "longitude": -48.6454
    },
    {
        "nome": "Gameleiras",
        "latitude": -15.0829,
        "longitude": -43.125
    },
    {
        "nome": "Gandu",
        "latitude": -13.7441,
        "longitude": -39.4747
    },
    {
        "nome": "Garanhuns",
        "latitude": -8.88243,
        "longitude": -36.4966
    },
    {
        "nome": "Gararu",
        "latitude": -9.9722,
        "longitude": -37.0869
    },
    {
        "nome": "Garça",
        "latitude": -22.2125,
        "longitude": -49.6546
    },
    {
        "nome": "Garibaldi",
        "latitude": -29.259,
        "longitude": -51.5352
    },
    {
        "nome": "Garopaba",
        "latitude": -28.0275,
        "longitude": -48.6192
    },
    {
        "nome": "Garrafão do Norte",
        "latitude": -1.92986,
        "longitude": -47.0505
    },
    {
        "nome": "Garruchos",
        "latitude": -28.1944,
        "longitude": -55.6383
    },
    {
        "nome": "Garuva",
        "latitude": -26.0292,
        "longitude": -48.852
    },
    {
        "nome": "Gaspar",
        "latitude": -26.9336,
        "longitude": -48.9534
    },
    {
        "nome": "Gastão Vidigal",
        "latitude": -20.7948,
        "longitude": -50.1912
    },
    {
        "nome": "Gaúcha do Norte",
        "latitude": -13.2443,
        "longitude": -53.0809
    },
    {
        "nome": "Gaurama",
        "latitude": -27.5856,
        "longitude": -52.0915
    },
    {
        "nome": "Gavião",
        "latitude": -11.4688,
        "longitude": -39.7757
    },
    {
        "nome": "Gavião Peixoto",
        "latitude": -21.8367,
        "longitude": -48.4957
    },
    {
        "nome": "Geminiano",
        "latitude": -7.15476,
        "longitude": -41.3409
    },
    {
        "nome": "General Câmara",
        "latitude": -29.9032,
        "longitude": -51.7612
    },
    {
        "nome": "General Carneiro",
        "latitude": -15.7094,
        "longitude": -52.7574
    },
    {
        "nome": "General Carneiro",
        "latitude": -26.425,
        "longitude": -51.3172
    },
    {
        "nome": "General Maynard",
        "latitude": -10.6835,
        "longitude": -36.9838
    },
    {
        "nome": "General Salgado",
        "latitude": -20.6485,
        "longitude": -50.364
    },
    {
        "nome": "General Sampaio",
        "latitude": -4.04351,
        "longitude": -39.454
    },
    {
        "nome": "Gentil",
        "latitude": -28.4316,
        "longitude": -52.0337
    },
    {
        "nome": "Gentio do Ouro",
        "latitude": -11.4342,
        "longitude": -42.5077
    },
    {
        "nome": "Getulina",
        "latitude": -21.7961,
        "longitude": -49.9312
    },
    {
        "nome": "Getúlio Vargas",
        "latitude": -27.8911,
        "longitude": -52.2294
    },
    {
        "nome": "Gilbués",
        "latitude": -9.83001,
        "longitude": -45.3423
    },
    {
        "nome": "Girau do Ponciano",
        "latitude": -9.88404,
        "longitude": -36.8316
    },
    {
        "nome": "Giruá",
        "latitude": -28.0297,
        "longitude": -54.3517
    },
    {
        "nome": "Glaucilândia",
        "latitude": -16.8481,
        "longitude": -43.692
    },
    {
        "nome": "Glicério",
        "latitude": -21.3812,
        "longitude": -50.2123
    },
    {
        "nome": "Glória",
        "latitude": -9.34382,
        "longitude": -38.2544
    },
    {
        "nome": "Glória D'Oeste",
        "latitude": -15.768,
        "longitude": -58.3108
    },
    {
        "nome": "Glória de Dourados",
        "latitude": -22.4136,
        "longitude": -54.2335
    },
    {
        "nome": "Glória do Goitá",
        "latitude": -8.00568,
        "longitude": -35.2904
    },
    {
        "nome": "Glorinha",
        "latitude": -29.8798,
        "longitude": -50.7734
    },
    {
        "nome": "Godofredo Viana",
        "latitude": -1.40259,
        "longitude": -45.7795
    },
    {
        "nome": "Godoy Moreira",
        "latitude": -24.173,
        "longitude": -51.9246
    },
    {
        "nome": "Goiabeira",
        "latitude": -18.9807,
        "longitude": -41.2235
    },
    {
        "nome": "Goianá",
        "latitude": -21.536,
        "longitude": -43.1957
    },
    {
        "nome": "Goiana",
        "latitude": -7.5606,
        "longitude": -34.9959
    },
    {
        "nome": "Goianápolis",
        "latitude": -16.5098,
        "longitude": -49.0234
    },
    {
        "nome": "Goiandira",
        "latitude": -18.1352,
        "longitude": -48.0875
    },
    {
        "nome": "Goianésia",
        "latitude": -15.3118,
        "longitude": -49.1162
    },
    {
        "nome": "Goianésia do Pará",
        "latitude": -3.84338,
        "longitude": -49.0974
    },
    {
        "nome": "Goiânia",
        "latitude": -16.6864,
        "longitude": -49.2643
    },
    {
        "nome": "Goianinha",
        "latitude": -6.26486,
        "longitude": -35.1943
    },
    {
        "nome": "Goianira",
        "latitude": -16.4947,
        "longitude": -49.427
    },
    {
        "nome": "Goianorte",
        "latitude": -8.77413,
        "longitude": -48.9313
    },
    {
        "nome": "Goiás",
        "latitude": -15.9333,
        "longitude": -50.14
    },
    {
        "nome": "Goiatins",
        "latitude": -7.71478,
        "longitude": -47.3252
    },
    {
        "nome": "Goiatuba",
        "latitude": -18.0105,
        "longitude": -49.3658
    },
    {
        "nome": "Goioerê",
        "latitude": -24.1835,
        "longitude": -53.0248
    },
    {
        "nome": "Goioxim",
        "latitude": -25.1927,
        "longitude": -51.9911
    },
    {
        "nome": "Gonçalves",
        "latitude": -22.6545,
        "longitude": -45.8556
    },
    {
        "nome": "Gonçalves Dias",
        "latitude": -5.1475,
        "longitude": -44.3013
    },
    {
        "nome": "Gongogi",
        "latitude": -14.3195,
        "longitude": -39.469
    },
    {
        "nome": "Gonzaga",
        "latitude": -18.8196,
        "longitude": -42.4769
    },
    {
        "nome": "Gouveia",
        "latitude": -18.4519,
        "longitude": -43.7423
    },
    {
        "nome": "Gouvelândia",
        "latitude": -18.6238,
        "longitude": -50.0805
    },
    {
        "nome": "Governador Archer",
        "latitude": -5.02078,
        "longitude": -44.2754
    },
    {
        "nome": "Governador Celso Ramos",
        "latitude": -27.3172,
        "longitude": -48.5576
    },
    {
        "nome": "Governador Dix-Sept Rosado",
        "latitude": -5.44887,
        "longitude": -37.5183
    },
    {
        "nome": "Governador Edison Lobão",
        "latitude": -5.74973,
        "longitude": -47.3646
    },
    {
        "nome": "Governador Eugênio Barros",
        "latitude": -5.31897,
        "longitude": -44.2469
    },
    {
        "nome": "Governador Jorge Teixeira",
        "latitude": -10.61,
        "longitude": -62.7371
    },
    {
        "nome": "Governador Lindenberg",
        "latitude": -19.1864,
        "longitude": -40.4473
    },
    {
        "nome": "Governador Luiz Rocha",
        "latitude": -5.47835,
        "longitude": -44.0774
    },
    {
        "nome": "Governador Mangabeira",
        "latitude": -12.5994,
        "longitude": -39.0412
    },
    {
        "nome": "Governador Newton Bello",
        "latitude": -3.43245,
        "longitude": -45.6619
    },
    {
        "nome": "Governador Nunes Freire",
        "latitude": -2.12899,
        "longitude": -45.8777
    },
    {
        "nome": "Governador Valadares",
        "latitude": -18.8545,
        "longitude": -41.9555
    },
    {
        "nome": "Graça",
        "latitude": -4.04422,
        "longitude": -40.749
    },
    {
        "nome": "Graça Aranha",
        "latitude": -5.40547,
        "longitude": -44.3358
    },
    {
        "nome": "Gracho Cardoso",
        "latitude": -10.2252,
        "longitude": -37.2006
    },
    {
        "nome": "Grajaú",
        "latitude": -5.81367,
        "longitude": -46.1462
    },
    {
        "nome": "Gramado",
        "latitude": -29.3734,
        "longitude": -50.8762
    },
    {
        "nome": "Gramado dos Loureiros",
        "latitude": -27.4429,
        "longitude": -52.9149
    },
    {
        "nome": "Gramado Xavier",
        "latitude": -29.2706,
        "longitude": -52.5795
    },
    {
        "nome": "Grandes Rios",
        "latitude": -24.1466,
        "longitude": -51.5094
    },
    {
        "nome": "Granito",
        "latitude": -7.70711,
        "longitude": -39.615
    },
    {
        "nome": "Granja",
        "latitude": -3.12788,
        "longitude": -40.8372
    },
    {
        "nome": "Granjeiro",
        "latitude": -6.88134,
        "longitude": -39.2144
    },
    {
        "nome": "Grão Mogol",
        "latitude": -16.5662,
        "longitude": -42.8923
    },
    {
        "nome": "Grão Pará",
        "latitude": -28.1809,
        "longitude": -49.2252
    },
    {
        "nome": "Gravatá",
        "latitude": -8.21118,
        "longitude": -35.5675
    },
    {
        "nome": "Gravataí",
        "latitude": -29.9413,
        "longitude": -50.9869
    },
    {
        "nome": "Gravatal",
        "latitude": -28.3208,
        "longitude": -49.0427
    },
    {
        "nome": "Groaíras",
        "latitude": -3.91787,
        "longitude": -40.3852
    },
    {
        "nome": "Grossos",
        "latitude": -4.98068,
        "longitude": -37.1621
    },
    {
        "nome": "Grupiara",
        "latitude": -18.5003,
        "longitude": -47.7318
    },
    {
        "nome": "Guabiju",
        "latitude": -28.5421,
        "longitude": -51.6948
    },
    {
        "nome": "Guabiruba",
        "latitude": -27.0808,
        "longitude": -48.9804
    },
    {
        "nome": "Guaçuí",
        "latitude": -20.7668,
        "longitude": -41.6734
    },
    {
        "nome": "Guadalupe",
        "latitude": -6.78285,
        "longitude": -43.5594
    },
    {
        "nome": "Guaíba",
        "latitude": -30.1086,
        "longitude": -51.3233
    },
    {
        "nome": "Guaiçara",
        "latitude": -21.6195,
        "longitude": -49.8013
    },
    {
        "nome": "Guaimbê",
        "latitude": -21.9091,
        "longitude": -49.8986
    },
    {
        "nome": "Guaíra",
        "latitude": -20.3196,
        "longitude": -48.312
    },
    {
        "nome": "Guaíra",
        "latitude": -24.085,
        "longitude": -54.2573
    },
    {
        "nome": "Guairaçá",
        "latitude": -22.932,
        "longitude": -52.6906
    },
    {
        "nome": "Guaiúba",
        "latitude": -4.04057,
        "longitude": -38.6404
    },
    {
        "nome": "Guajará",
        "latitude": -7.53797,
        "longitude": -72.5907
    },
    {
        "nome": "Guajará-Mirim",
        "latitude": -10.7889,
        "longitude": -65.3296
    },
    {
        "nome": "Guajeru",
        "latitude": -14.5467,
        "longitude": -41.9381
    },
    {
        "nome": "Guamaré",
        "latitude": -5.10619,
        "longitude": -36.3222
    },
    {
        "nome": "Guamiranga",
        "latitude": -25.1912,
        "longitude": -50.8021
    },
    {
        "nome": "Guanambi",
        "latitude": -14.2231,
        "longitude": -42.7799
    },
    {
        "nome": "Guanhães",
        "latitude": -18.7713,
        "longitude": -42.9312
    },
    {
        "nome": "Guapé",
        "latitude": -20.7631,
        "longitude": -45.9152
    },
    {
        "nome": "Guapiaçu",
        "latitude": -20.7959,
        "longitude": -49.2172
    },
    {
        "nome": "Guapiara",
        "latitude": -24.1892,
        "longitude": -48.5295
    },
    {
        "nome": "Guapimirim",
        "latitude": -22.5347,
        "longitude": -42.9895
    },
    {
        "nome": "Guapirama",
        "latitude": -23.5203,
        "longitude": -50.0407
    },
    {
        "nome": "Guapó",
        "latitude": -16.8297,
        "longitude": -49.5345
    },
    {
        "nome": "Guaporé",
        "latitude": -28.8399,
        "longitude": -51.8895
    },
    {
        "nome": "Guaporema",
        "latitude": -23.3402,
        "longitude": -52.7786
    },
    {
        "nome": "Guará",
        "latitude": -20.4302,
        "longitude": -47.8236
    },
    {
        "nome": "Guarabira",
        "latitude": -6.85064,
        "longitude": -35.485
    },
    {
        "nome": "Guaraçaí",
        "latitude": -21.0292,
        "longitude": -51.2119
    },
    {
        "nome": "Guaraci",
        "latitude": -20.4977,
        "longitude": -48.9391
    },
    {
        "nome": "Guaraci",
        "latitude": -22.9694,
        "longitude": -51.6504
    },
    {
        "nome": "Guaraciaba",
        "latitude": -20.5716,
        "longitude": -43.0094
    },
    {
        "nome": "Guaraciaba",
        "latitude": -26.6042,
        "longitude": -53.5243
    },
    {
        "nome": "Guaraciaba do Norte",
        "latitude": -4.15814,
        "longitude": -40.7476
    },
    {
        "nome": "Guaraciama",
        "latitude": -17.0142,
        "longitude": -43.6675
    },
    {
        "nome": "Guaraí",
        "latitude": -8.83543,
        "longitude": -48.5114
    },
    {
        "nome": "Guaraíta",
        "latitude": -15.6121,
        "longitude": -50.0265
    },
    {
        "nome": "Guaramiranga",
        "latitude": -4.26248,
        "longitude": -38.932
    },
    {
        "nome": "Guaramirim",
        "latitude": -26.4688,
        "longitude": -49.0026
    },
    {
        "nome": "Guaranésia",
        "latitude": -21.3009,
        "longitude": -46.7964
    },
    {
        "nome": "Guarani",
        "latitude": -21.3563,
        "longitude": -43.0328
    },
    {
        "nome": "Guarani d'Oeste",
        "latitude": -20.0746,
        "longitude": -50.3411
    },
    {
        "nome": "Guarani das Missões",
        "latitude": -28.1491,
        "longitude": -54.5629
    },
    {
        "nome": "Guarani de Goiás",
        "latitude": -13.9421,
        "longitude": -46.4868
    },
    {
        "nome": "Guaraniaçu",
        "latitude": -25.0968,
        "longitude": -52.8755
    },
    {
        "nome": "Guarantã",
        "latitude": -21.8942,
        "longitude": -49.5914
    },
    {
        "nome": "Guarantã do Norte",
        "latitude": -9.96218,
        "longitude": -54.9121
    },
    {
        "nome": "Guarapari",
        "latitude": -20.6772,
        "longitude": -40.5093
    },
    {
        "nome": "Guarapuava",
        "latitude": -25.3902,
        "longitude": -51.4623
    },
    {
        "nome": "Guaraqueçaba",
        "latitude": -25.3071,
        "longitude": -48.3204
    },
    {
        "nome": "Guarará",
        "latitude": -21.7304,
        "longitude": -43.0334
    },
    {
        "nome": "Guararapes",
        "latitude": -21.2544,
        "longitude": -50.6453
    },
    {
        "nome": "Guararema",
        "latitude": -23.4112,
        "longitude": -46.0369
    },
    {
        "nome": "Guaratinga",
        "latitude": -16.5833,
        "longitude": -39.7847
    },
    {
        "nome": "Guaratinguetá",
        "latitude": -22.8075,
        "longitude": -45.1938
    },
    {
        "nome": "Guaratuba",
        "latitude": -25.8817,
        "longitude": -48.5752
    },
    {
        "nome": "Guarda-Mor",
        "latitude": -17.7673,
        "longitude": -47.0998
    },
    {
        "nome": "Guareí",
        "latitude": -23.3714,
        "longitude": -48.1837
    },
    {
        "nome": "Guariba",
        "latitude": -21.3594,
        "longitude": -48.2316
    },
    {
        "nome": "Guaribas",
        "latitude": -9.38647,
        "longitude": -43.6943
    },
    {
        "nome": "Guarinos",
        "latitude": -14.7292,
        "longitude": -49.7006
    },
    {
        "nome": "Guarujá",
        "latitude": -23.9888,
        "longitude": -46.258
    },
    {
        "nome": "Guarujá do Sul",
        "latitude": -26.3858,
        "longitude": -53.5296
    },
    {
        "nome": "Guarulhos",
        "latitude": -23.4538,
        "longitude": -46.5333
    },
    {
        "nome": "Guatambú",
        "latitude": -27.1341,
        "longitude": -52.7887
    },
    {
        "nome": "Guatapará",
        "latitude": -21.4944,
        "longitude": -48.0356
    },
    {
        "nome": "Guaxupé",
        "latitude": -21.305,
        "longitude": -46.7081
    },
    {
        "nome": "Guia Lopes da Laguna",
        "latitude": -21.4583,
        "longitude": -56.1117
    },
    {
        "nome": "Guidoval",
        "latitude": -21.155,
        "longitude": -42.7887
    },
    {
        "nome": "Guimarães",
        "latitude": -2.12755,
        "longitude": -44.602
    },
    {
        "nome": "Guimarânia",
        "latitude": -18.8425,
        "longitude": -46.7901
    },
    {
        "nome": "Guiratinga",
        "latitude": -16.346,
        "longitude": -53.7575
    },
    {
        "nome": "Guiricema",
        "latitude": -21.0098,
        "longitude": -42.7207
    },
    {
        "nome": "Gurinhatã",
        "latitude": -19.2143,
        "longitude": -49.7876
    },
    {
        "nome": "Gurinhém",
        "latitude": -7.1233,
        "longitude": -35.4222
    },
    {
        "nome": "Gurjão",
        "latitude": -7.24833,
        "longitude": -36.4923
    },
    {
        "nome": "Gurupá",
        "latitude": -1.41412,
        "longitude": -51.6338
    },
    {
        "nome": "Gurupi",
        "latitude": -11.7279,
        "longitude": -49.068
    },
    {
        "nome": "Guzolândia",
        "latitude": -20.6467,
        "longitude": -50.6645
    },
    {
        "nome": "Harmonia",
        "latitude": -29.5456,
        "longitude": -51.4185
    },
    {
        "nome": "Heitoraí",
        "latitude": -15.719,
        "longitude": -49.8268
    },
    {
        "nome": "Heliodora",
        "latitude": -22.0644,
        "longitude": -45.5453
    },
    {
        "nome": "Heliópolis",
        "latitude": -10.6825,
        "longitude": -38.2907
    },
    {
        "nome": "Herculândia",
        "latitude": -22.0038,
        "longitude": -50.3907
    },
    {
        "nome": "Herval",
        "latitude": -32.024,
        "longitude": -53.3944
    },
    {
        "nome": "Herval d'Oeste",
        "latitude": -27.1903,
        "longitude": -51.4917
    },
    {
        "nome": "Herveiras",
        "latitude": -29.4552,
        "longitude": -52.6553
    },
    {
        "nome": "Hidrolândia",
        "latitude": -16.9626,
        "longitude": -49.2265
    },
    {
        "nome": "Hidrolândia",
        "latitude": -4.40958,
        "longitude": -40.4056
    },
    {
        "nome": "Hidrolina",
        "latitude": -14.7261,
        "longitude": -49.4634
    },
    {
        "nome": "Holambra",
        "latitude": -22.6405,
        "longitude": -47.0487
    },
    {
        "nome": "Honório Serpa",
        "latitude": -26.139,
        "longitude": -52.3848
    },
    {
        "nome": "Horizonte",
        "latitude": -4.1209,
        "longitude": -38.4707
    },
    {
        "nome": "Horizontina",
        "latitude": -27.6282,
        "longitude": -54.3053
    },
    {
        "nome": "Hortolândia",
        "latitude": -22.8529,
        "longitude": -47.2143
    },
    {
        "nome": "Hugo Napoleão",
        "latitude": -5.9886,
        "longitude": -42.5598
    },
    {
        "nome": "Hulha Negra",
        "latitude": -31.4067,
        "longitude": -53.8667
    },
    {
        "nome": "Humaitá",
        "latitude": -27.5691,
        "longitude": -53.9695
    },
    {
        "nome": "Humaitá",
        "latitude": -7.51171,
        "longitude": -63.0327
    },
    {
        "nome": "Humberto de Campos",
        "latitude": -2.59828,
        "longitude": -43.4649
    },
    {
        "nome": "Iacanga",
        "latitude": -21.8896,
        "longitude": -49.031
    },
    {
        "nome": "Iaciara",
        "latitude": -14.1011,
        "longitude": -46.6335
    },
    {
        "nome": "Iacri",
        "latitude": -21.8572,
        "longitude": -50.6932
    },
    {
        "nome": "Iaçu",
        "latitude": -12.7666,
        "longitude": -40.2056
    },
    {
        "nome": "Iapu",
        "latitude": -19.4387,
        "longitude": -42.2147
    },
    {
        "nome": "Iaras",
        "latitude": -22.8682,
        "longitude": -49.1634
    },
    {
        "nome": "Iati",
        "latitude": -9.04559,
        "longitude": -36.8498
    },
    {
        "nome": "Ibaiti",
        "latitude": -23.8478,
        "longitude": -50.1932
    },
    {
        "nome": "Ibarama",
        "latitude": -29.4203,
        "longitude": -53.1295
    },
    {
        "nome": "Ibaretama",
        "latitude": -4.80376,
        "longitude": -38.7501
    },
    {
        "nome": "Ibaté",
        "latitude": -21.9584,
        "longitude": -47.9882
    },
    {
        "nome": "Ibateguara",
        "latitude": -8.97823,
        "longitude": -35.9373
    },
    {
        "nome": "Ibatiba",
        "latitude": -20.2347,
        "longitude": -41.5087
    },
    {
        "nome": "Ibema",
        "latitude": -25.1193,
        "longitude": -53.0072
    },
    {
        "nome": "Ibertioga",
        "latitude": -21.433,
        "longitude": -43.9639
    },
    {
        "nome": "Ibiá",
        "latitude": -19.4749,
        "longitude": -46.5474
    },
    {
        "nome": "Ibiaçá",
        "latitude": -28.0566,
        "longitude": -51.8599
    },
    {
        "nome": "Ibiaí",
        "latitude": -16.8591,
        "longitude": -44.9046
    },
    {
        "nome": "Ibiam",
        "latitude": -27.1847,
        "longitude": -51.2352
    },
    {
        "nome": "Ibiapina",
        "latitude": -3.92403,
        "longitude": -40.8911
    },
    {
        "nome": "Ibiara",
        "latitude": -7.47957,
        "longitude": -38.4059
    },
    {
        "nome": "Ibiassucê",
        "latitude": -14.2711,
        "longitude": -42.257
    },
    {
        "nome": "Ibicaraí",
        "latitude": -14.8579,
        "longitude": -39.5914
    },
    {
        "nome": "Ibicaré",
        "latitude": -27.0881,
        "longitude": -51.3681
    },
    {
        "nome": "Ibicoara",
        "latitude": -13.4059,
        "longitude": -41.284
    },
    {
        "nome": "Ibicuí",
        "latitude": -14.845,
        "longitude": -39.9879
    },
    {
        "nome": "Ibicuitinga",
        "latitude": -4.96999,
        "longitude": -38.6362
    },
    {
        "nome": "Ibimirim",
        "latitude": -8.54026,
        "longitude": -37.7032
    },
    {
        "nome": "Ibipeba",
        "latitude": -11.6438,
        "longitude": -42.0195
    },
    {
        "nome": "Ibipitanga",
        "latitude": -12.8804,
        "longitude": -42.4856
    },
    {
        "nome": "Ibiporã",
        "latitude": -23.2659,
        "longitude": -51.0522
    },
    {
        "nome": "Ibiquera",
        "latitude": -12.6444,
        "longitude": -40.9338
    },
    {
        "nome": "Ibirá",
        "latitude": -21.083,
        "longitude": -49.2448
    },
    {
        "nome": "Ibiracatu",
        "latitude": -15.6605,
        "longitude": -44.1667
    },
    {
        "nome": "Ibiraci",
        "latitude": -20.4611,
        "longitude": -47.1222
    },
    {
        "nome": "Ibiraçu",
        "latitude": -19.8366,
        "longitude": -40.3732
    },
    {
        "nome": "Ibiraiaras",
        "latitude": -28.3741,
        "longitude": -51.6377
    },
    {
        "nome": "Ibirajuba",
        "latitude": -8.57633,
        "longitude": -36.1812
    },
    {
        "nome": "Ibirama",
        "latitude": -27.0547,
        "longitude": -49.5193
    },
    {
        "nome": "Ibirapitanga",
        "latitude": -14.1649,
        "longitude": -39.3787
    },
    {
        "nome": "Ibirapuã",
        "latitude": -17.6832,
        "longitude": -40.1129
    },
    {
        "nome": "Ibirapuitã",
        "latitude": -28.6247,
        "longitude": -52.5158
    },
    {
        "nome": "Ibirarema",
        "latitude": -22.8185,
        "longitude": -50.0739
    },
    {
        "nome": "Ibirataia",
        "latitude": -14.0643,
        "longitude": -39.6459
    },
    {
        "nome": "Ibirité",
        "latitude": -20.0252,
        "longitude": -44.0569
    },
    {
        "nome": "Ibirubá",
        "latitude": -28.6302,
        "longitude": -53.0961
    },
    {
        "nome": "Ibitiara",
        "latitude": -12.6502,
        "longitude": -42.2179
    },
    {
        "nome": "Ibitinga",
        "latitude": -21.7562,
        "longitude": -48.8319
    },
    {
        "nome": "Ibitirama",
        "latitude": -20.5466,
        "longitude": -41.6667
    },
    {
        "nome": "Ibititá",
        "latitude": -11.5414,
        "longitude": -41.9748
    },
    {
        "nome": "Ibitiúra de Minas",
        "latitude": -22.0604,
        "longitude": -46.4368
    },
    {
        "nome": "Ibituruna",
        "latitude": -21.1541,
        "longitude": -44.7479
    },
    {
        "nome": "Ibiúna",
        "latitude": -23.6596,
        "longitude": -47.223
    },
    {
        "nome": "Ibotirama",
        "latitude": -12.1779,
        "longitude": -43.2167
    },
    {
        "nome": "Icapuí",
        "latitude": -4.71206,
        "longitude": -37.3531
    },
    {
        "nome": "Içara",
        "latitude": -28.7132,
        "longitude": -49.3087
    },
    {
        "nome": "Icaraí de Minas",
        "latitude": -16.214,
        "longitude": -44.9034
    },
    {
        "nome": "Icaraíma",
        "latitude": -23.3944,
        "longitude": -53.615
    },
    {
        "nome": "Icatu",
        "latitude": -2.77206,
        "longitude": -44.0501
    },
    {
        "nome": "Icém",
        "latitude": -20.3391,
        "longitude": -49.1915
    },
    {
        "nome": "Ichu",
        "latitude": -11.7431,
        "longitude": -39.1905
    },
    {
        "nome": "Icó",
        "latitude": -6.39627,
        "longitude": -38.8554
    },
    {
        "nome": "Iconha",
        "latitude": -20.7913,
        "longitude": -40.8132
    },
    {
        "nome": "Ielmo Marinho",
        "latitude": -5.82447,
        "longitude": -35.55
    },
    {
        "nome": "Iepê",
        "latitude": -22.6602,
        "longitude": -51.0779
    },
    {
        "nome": "Igaci",
        "latitude": -9.53768,
        "longitude": -36.6372
    },
    {
        "nome": "Igaporã",
        "latitude": -13.774,
        "longitude": -42.7155
    },
    {
        "nome": "Igaraçu do Tietê",
        "latitude": -22.509,
        "longitude": -48.5597
    },
    {
        "nome": "Igaracy",
        "latitude": -7.17184,
        "longitude": -38.1478
    },
    {
        "nome": "Igarapava",
        "latitude": -20.0407,
        "longitude": -47.7466
    },
    {
        "nome": "Igarapé",
        "latitude": -20.0707,
        "longitude": -44.2994
    },
    {
        "nome": "Igarapé do Meio",
        "latitude": -3.65771,
        "longitude": -45.2114
    },
    {
        "nome": "Igarapé Grande",
        "latitude": -4.6625,
        "longitude": -44.8558
    },
    {
        "nome": "Igarapé-Açu",
        "latitude": -1.12539,
        "longitude": -47.626
    },
    {
        "nome": "Igarapé-Miri",
        "latitude": -1.97533,
        "longitude": -48.9575
    },
    {
        "nome": "Igarassu",
        "latitude": -7.82881,
        "longitude": -34.9013
    },
    {
        "nome": "Igaratá",
        "latitude": -23.2037,
        "longitude": -46.157
    },
    {
        "nome": "Igaratinga",
        "latitude": -19.9476,
        "longitude": -44.7063
    },
    {
        "nome": "Igrapiúna",
        "latitude": -13.8295,
        "longitude": -39.1361
    },
    {
        "nome": "Igreja Nova",
        "latitude": -10.1235,
        "longitude": -36.6597
    },
    {
        "nome": "Igrejinha",
        "latitude": -29.5693,
        "longitude": -50.7919
    },
    {
        "nome": "Iguaba Grande",
        "latitude": -22.8495,
        "longitude": -42.2299
    },
    {
        "nome": "Iguaí",
        "latitude": -14.7528,
        "longitude": -40.0894
    },
    {
        "nome": "Iguape",
        "latitude": -24.699,
        "longitude": -47.5537
    },
    {
        "nome": "Iguaraçu",
        "latitude": -23.1949,
        "longitude": -51.8256
    },
    {
        "nome": "Iguaracy",
        "latitude": -7.83222,
        "longitude": -37.5082
    },
    {
        "nome": "Iguatama",
        "latitude": -20.1776,
        "longitude": -45.7111
    },
    {
        "nome": "Iguatemi",
        "latitude": -23.6736,
        "longitude": -54.5637
    },
    {
        "nome": "Iguatu",
        "latitude": -6.36281,
        "longitude": -39.2892
    },
    {
        "nome": "Iguatu",
        "latitude": -24.7153,
        "longitude": -53.0827
    },
    {
        "nome": "Ijaci",
        "latitude": -21.1738,
        "longitude": -44.9233
    },
    {
        "nome": "Ijuí",
        "latitude": -28.388,
        "longitude": -53.92
    },
    {
        "nome": "Ilha Comprida",
        "latitude": -24.7307,
        "longitude": -47.5383
    },
    {
        "nome": "Ilha das Flores",
        "latitude": -10.4425,
        "longitude": -36.5479
    },
    {
        "nome": "Ilha de Itamaracá",
        "latitude": -7.74766,
        "longitude": -34.8303
    },
    {
        "nome": "Ilha Grande",
        "latitude": -2.85774,
        "longitude": -41.8186
    },
    {
        "nome": "Ilha Solteira",
        "latitude": -20.4326,
        "longitude": -51.3426
    },
    {
        "nome": "Ilhabela",
        "latitude": -23.7785,
        "longitude": -45.3552
    },
    {
        "nome": "Ilhéus",
        "latitude": -14.793,
        "longitude": -39.046
    },
    {
        "nome": "Ilhota",
        "latitude": -26.9023,
        "longitude": -48.8251
    },
    {
        "nome": "Ilicínea",
        "latitude": -20.9402,
        "longitude": -45.8308
    },
    {
        "nome": "Ilópolis",
        "latitude": -28.9282,
        "longitude": -52.1258
    },
    {
        "nome": "Imaculada",
        "latitude": -7.3889,
        "longitude": -37.5079
    },
    {
        "nome": "Imaruí",
        "latitude": -28.3339,
        "longitude": -48.817
    },
    {
        "nome": "Imbaú",
        "latitude": -24.448,
        "longitude": -50.7533
    },
    {
        "nome": "Imbé",
        "latitude": -29.9753,
        "longitude": -50.1281
    },
    {
        "nome": "Imbé de Minas",
        "latitude": -19.6017,
        "longitude": -41.9695
    },
    {
        "nome": "Imbituba",
        "latitude": -28.2284,
        "longitude": -48.6659
    },
    {
        "nome": "Imbituva",
        "latitude": -25.2285,
        "longitude": -50.5989
    },
    {
        "nome": "Imbuia",
        "latitude": -27.4908,
        "longitude": -49.4218
    },
    {
        "nome": "Imigrante",
        "latitude": -29.3508,
        "longitude": -51.7748
    },
    {
        "nome": "Imperatriz",
        "latitude": -5.51847,
        "longitude": -47.4777
    },
    {
        "nome": "Inácio Martins",
        "latitude": -25.5704,
        "longitude": -51.0769
    },
    {
        "nome": "Inaciolândia",
        "latitude": -18.4869,
        "longitude": -49.9888
    },
    {
        "nome": "Inajá",
        "latitude": -8.90206,
        "longitude": -37.8351
    },
    {
        "nome": "Inajá",
        "latitude": -22.7509,
        "longitude": -52.1995
    },
    {
        "nome": "Inconfidentes",
        "latitude": -22.3136,
        "longitude": -46.3264
    },
    {
        "nome": "Indaiabira",
        "latitude": -15.4911,
        "longitude": -42.2005
    },
    {
        "nome": "Indaial",
        "latitude": -26.8992,
        "longitude": -49.2354
    },
    {
        "nome": "Indaiatuba",
        "latitude": -23.0816,
        "longitude": -47.2101
    },
    {
        "nome": "Independência",
        "latitude": -27.8354,
        "longitude": -54.1886
    },
    {
        "nome": "Independência",
        "latitude": -5.38789,
        "longitude": -40.3085
    },
    {
        "nome": "Indiana",
        "latitude": -22.1738,
        "longitude": -51.2555
    },
    {
        "nome": "Indianópolis",
        "latitude": -23.4762,
        "longitude": -52.6989
    },
    {
        "nome": "Indianópolis",
        "latitude": -19.0341,
        "longitude": -47.9155
    },
    {
        "nome": "Indiaporã",
        "latitude": -19.979,
        "longitude": -50.2909
    },
    {
        "nome": "Indiara",
        "latitude": -17.1387,
        "longitude": -49.9862
    },
    {
        "nome": "Indiaroba",
        "latitude": -11.5157,
        "longitude": -37.515
    },
    {
        "nome": "Indiavaí",
        "latitude": -15.4921,
        "longitude": -58.5802
    },
    {
        "nome": "Ingá",
        "latitude": -7.28144,
        "longitude": -35.605
    },
    {
        "nome": "Ingaí",
        "latitude": -21.4024,
        "longitude": -44.9152
    },
    {
        "nome": "Ingazeira",
        "latitude": -7.66909,
        "longitude": -37.4576
    },
    {
        "nome": "Inhacorá",
        "latitude": -27.8752,
        "longitude": -54.015
    },
    {
        "nome": "Inhambupe",
        "latitude": -11.781,
        "longitude": -38.355
    },
    {
        "nome": "Inhangapi",
        "latitude": -1.4349,
        "longitude": -47.9114
    },
    {
        "nome": "Inhapi",
        "latitude": -9.22594,
        "longitude": -37.7509
    },
    {
        "nome": "Inhapim",
        "latitude": -19.5476,
        "longitude": -42.1147
    },
    {
        "nome": "Inhaúma",
        "latitude": -19.4898,
        "longitude": -44.3934
    },
    {
        "nome": "Inhuma",
        "latitude": -6.665,
        "longitude": -41.7041
    },
    {
        "nome": "Inhumas",
        "latitude": -16.3611,
        "longitude": -49.5001
    },
    {
        "nome": "Inimutaba",
        "latitude": -18.7271,
        "longitude": -44.3584
    },
    {
        "nome": "Inocência",
        "latitude": -19.7277,
        "longitude": -51.9281
    },
    {
        "nome": "Inúbia Paulista",
        "latitude": -21.7695,
        "longitude": -50.9633
    },
    {
        "nome": "Iomerê",
        "latitude": -27.0019,
        "longitude": -51.2442
    },
    {
        "nome": "Ipaba",
        "latitude": -19.4158,
        "longitude": -42.4139
    },
    {
        "nome": "Ipameri",
        "latitude": -17.7215,
        "longitude": -48.1581
    },
    {
        "nome": "Ipanema",
        "latitude": -19.7992,
        "longitude": -41.7164
    },
    {
        "nome": "Ipanguaçu",
        "latitude": -5.48984,
        "longitude": -36.8501
    },
    {
        "nome": "Ipaporanga",
        "latitude": -4.89764,
        "longitude": -40.7537
    },
    {
        "nome": "Ipatinga",
        "latitude": -19.4703,
        "longitude": -42.5476
    },
    {
        "nome": "Ipaumirim",
        "latitude": -6.78265,
        "longitude": -38.7179
    },
    {
        "nome": "Ipaussu",
        "latitude": -23.0575,
        "longitude": -49.6279
    },
    {
        "nome": "Ipê",
        "latitude": -28.8171,
        "longitude": -51.2859
    },
    {
        "nome": "Ipecaetá",
        "latitude": -12.3028,
        "longitude": -39.3069
    },
    {
        "nome": "Iperó",
        "latitude": -23.3513,
        "longitude": -47.6927
    },
    {
        "nome": "Ipeúna",
        "latitude": -22.4355,
        "longitude": -47.7151
    },
    {
        "nome": "Ipiaçu",
        "latitude": -18.6927,
        "longitude": -49.9436
    },
    {
        "nome": "Ipiaú",
        "latitude": -14.1226,
        "longitude": -39.7353
    },
    {
        "nome": "Ipiguá",
        "latitude": -20.6557,
        "longitude": -49.3842
    },
    {
        "nome": "Ipirá",
        "latitude": -12.1561,
        "longitude": -39.7359
    },
    {
        "nome": "Ipira",
        "latitude": -27.4038,
        "longitude": -51.7758
    },
    {
        "nome": "Ipiranga",
        "latitude": -25.0238,
        "longitude": -50.5794
    },
    {
        "nome": "Ipiranga de Goiás",
        "latitude": -15.1689,
        "longitude": -49.6695
    },
    {
        "nome": "Ipiranga do Norte",
        "latitude": -12.2408,
        "longitude": -56.1531
    },
    {
        "nome": "Ipiranga do Piauí",
        "latitude": -6.82421,
        "longitude": -41.7381
    },
    {
        "nome": "Ipiranga do Sul",
        "latitude": -27.9404,
        "longitude": -52.4271
    },
    {
        "nome": "Ipixuna",
        "latitude": -7.04791,
        "longitude": -71.6934
    },
    {
        "nome": "Ipixuna do Pará",
        "latitude": -2.55992,
        "longitude": -47.5059
    },
    {
        "nome": "Ipojuca",
        "latitude": -8.39303,
        "longitude": -35.0609
    },
    {
        "nome": "Iporã",
        "latitude": -24.0083,
        "longitude": -53.706
    },
    {
        "nome": "Iporá",
        "latitude": -16.4398,
        "longitude": -51.118
    },
    {
        "nome": "Iporã do Oeste",
        "latitude": -26.9854,
        "longitude": -53.5355
    },
    {
        "nome": "Iporanga",
        "latitude": -24.5847,
        "longitude": -48.5971
    },
    {
        "nome": "Ipu",
        "latitude": -4.31748,
        "longitude": -40.7059
    },
    {
        "nome": "Ipuã",
        "latitude": -20.4438,
        "longitude": -48.0129
    },
    {
        "nome": "Ipuaçu",
        "latitude": -26.635,
        "longitude": -52.4556
    },
    {
        "nome": "Ipubi",
        "latitude": -7.64505,
        "longitude": -40.1476
    },
    {
        "nome": "Ipueira",
        "latitude": -6.80596,
        "longitude": -37.2045
    },
    {
        "nome": "Ipueiras",
        "latitude": -11.2329,
        "longitude": -48.46
    },
    {
        "nome": "Ipueiras",
        "latitude": -4.53802,
        "longitude": -40.7118
    },
    {
        "nome": "Ipuiúna",
        "latitude": -22.1013,
        "longitude": -46.1915
    },
    {
        "nome": "Ipumirim",
        "latitude": -27.0772,
        "longitude": -52.1289
    },
    {
        "nome": "Ipupiara",
        "latitude": -11.8219,
        "longitude": -42.6179
    },
    {
        "nome": "Iracema",
        "latitude": 2.18305,
        "longitude": -61.0415
    },
    {
        "nome": "Iracema",
        "latitude": -5.8124,
        "longitude": -38.2919
    },
    {
        "nome": "Iracema do Oeste",
        "latitude": -24.4262,
        "longitude": -53.3528
    },
    {
        "nome": "Iracemápolis",
        "latitude": -22.5832,
        "longitude": -47.523
    },
    {
        "nome": "Iraceminha",
        "latitude": -26.8215,
        "longitude": -53.2767
    },
    {
        "nome": "Iraí",
        "latitude": -27.1951,
        "longitude": -53.2543
    },
    {
        "nome": "Iraí de Minas",
        "latitude": -18.9819,
        "longitude": -47.461
    },
    {
        "nome": "Irajuba",
        "latitude": -13.2563,
        "longitude": -40.0848
    },
    {
        "nome": "Iramaia",
        "latitude": -13.2902,
        "longitude": -40.9595
    },
    {
        "nome": "Iranduba",
        "latitude": -3.27479,
        "longitude": -60.19
    },
    {
        "nome": "Irani",
        "latitude": -27.0287,
        "longitude": -51.9012
    },
    {
        "nome": "Irapuã",
        "latitude": -21.2768,
        "longitude": -49.4164
    },
    {
        "nome": "Irapuru",
        "latitude": -21.5684,
        "longitude": -51.3472
    },
    {
        "nome": "Iraquara",
        "latitude": -12.2429,
        "longitude": -41.6155
    },
    {
        "nome": "Irará",
        "latitude": -12.0504,
        "longitude": -38.7631
    },
    {
        "nome": "Irati",
        "latitude": -25.4697,
        "longitude": -50.6493
    },
    {
        "nome": "Irati",
        "latitude": -26.6539,
        "longitude": -52.8955
    },
    {
        "nome": "Irauçuba",
        "latitude": -3.74737,
        "longitude": -39.7843
    },
    {
        "nome": "Irecê",
        "latitude": -11.3033,
        "longitude": -41.8535
    },
    {
        "nome": "Iretama",
        "latitude": -24.4253,
        "longitude": -52.1012
    },
    {
        "nome": "Irineópolis",
        "latitude": -26.242,
        "longitude": -50.7957
    },
    {
        "nome": "Irituia",
        "latitude": -1.76984,
        "longitude": -47.446
    },
    {
        "nome": "Irupi",
        "latitude": -20.3501,
        "longitude": -41.6444
    },
    {
        "nome": "Isaías Coelho",
        "latitude": -7.73597,
        "longitude": -41.6735
    },
    {
        "nome": "Israelândia",
        "latitude": -16.3144,
        "longitude": -50.9087
    },
    {
        "nome": "Itá",
        "latitude": -27.2907,
        "longitude": -52.3212
    },
    {
        "nome": "Itaara",
        "latitude": -29.6013,
        "longitude": -53.7725
    },
    {
        "nome": "Itabaiana",
        "latitude": -7.33167,
        "longitude": -35.3317
    },
    {
        "nome": "Itabaiana",
        "latitude": -10.6826,
        "longitude": -37.4273
    },
    {
        "nome": "Itabaianinha",
        "latitude": -11.2693,
        "longitude": -37.7875
    },
    {
        "nome": "Itabela",
        "latitude": -16.5732,
        "longitude": -39.5593
    },
    {
        "nome": "Itaberá",
        "latitude": -23.8638,
        "longitude": -49.14
    },
    {
        "nome": "Itaberaba",
        "latitude": -12.5242,
        "longitude": -40.3059
    },
    {
        "nome": "Itaberaí",
        "latitude": -16.0206,
        "longitude": -49.806
    },
    {
        "nome": "Itabi",
        "latitude": -10.1248,
        "longitude": -37.1056
    },
    {
        "nome": "Itabira",
        "latitude": -19.6239,
        "longitude": -43.2312
    },
    {
        "nome": "Itabirinha",
        "latitude": -18.5712,
        "longitude": -41.234
    },
    {
        "nome": "Itabirito",
        "latitude": -20.2501,
        "longitude": -43.8038
    },
    {
        "nome": "Itaboraí",
        "latitude": -22.7565,
        "longitude": -42.8639
    },
    {
        "nome": "Itabuna",
        "latitude": -14.7876,
        "longitude": -39.2781
    },
    {
        "nome": "Itacajá",
        "latitude": -8.39293,
        "longitude": -47.7726
    },
    {
        "nome": "Itacambira",
        "latitude": -17.0625,
        "longitude": -43.3069
    },
    {
        "nome": "Itacarambi",
        "latitude": -15.089,
        "longitude": -44.095
    },
    {
        "nome": "Itacaré",
        "latitude": -14.2784,
        "longitude": -38.9959
    },
    {
        "nome": "Itacoatiara",
        "latitude": -3.13861,
        "longitude": -58.4449
    },
    {
        "nome": "Itacuruba",
        "latitude": -8.82231,
        "longitude": -38.6975
    },
    {
        "nome": "Itacurubi",
        "latitude": -28.7913,
        "longitude": -55.2447
    },
    {
        "nome": "Itaeté",
        "latitude": -12.9831,
        "longitude": -40.9677
    },
    {
        "nome": "Itagi",
        "latitude": -14.1615,
        "longitude": -40.0131
    },
    {
        "nome": "Itagibá",
        "latitude": -14.2782,
        "longitude": -39.8449
    },
    {
        "nome": "Itagimirim",
        "latitude": -16.0819,
        "longitude": -39.6133
    },
    {
        "nome": "Itaguaçu",
        "latitude": -19.8018,
        "longitude": -40.8601
    },
    {
        "nome": "Itaguaçu da Bahia",
        "latitude": -11.0147,
        "longitude": -42.3997
    },
    {
        "nome": "Itaguaí",
        "latitude": -22.8636,
        "longitude": -43.7798
    },
    {
        "nome": "Itaguajé",
        "latitude": -22.6183,
        "longitude": -51.9674
    },
    {
        "nome": "Itaguara",
        "latitude": -20.3947,
        "longitude": -44.4875
    },
    {
        "nome": "Itaguari",
        "latitude": -15.918,
        "longitude": -49.6071
    },
    {
        "nome": "Itaguaru",
        "latitude": -15.7565,
        "longitude": -49.6354
    },
    {
        "nome": "Itaguatins",
        "latitude": -5.77267,
        "longitude": -47.4864
    },
    {
        "nome": "Itaí",
        "latitude": -23.4213,
        "longitude": -49.092
    },
    {
        "nome": "Itaíba",
        "latitude": -8.94569,
        "longitude": -37.4173
    },
    {
        "nome": "Itaiçaba",
        "latitude": -4.67146,
        "longitude": -37.833
    },
    {
        "nome": "Itainópolis",
        "latitude": -7.44336,
        "longitude": -41.4687
    },
    {
        "nome": "Itaiópolis",
        "latitude": -26.339,
        "longitude": -49.9092
    },
    {
        "nome": "Itaipava do Grajaú",
        "latitude": -5.14252,
        "longitude": -45.7877
    },
    {
        "nome": "Itaipé",
        "latitude": -17.4014,
        "longitude": -41.6697
    },
    {
        "nome": "Itaipulândia",
        "latitude": -25.1366,
        "longitude": -54.3001
    },
    {
        "nome": "Itaitinga",
        "latitude": -3.96577,
        "longitude": -38.5298
    },
    {
        "nome": "Itaituba",
        "latitude": -4.2667,
        "longitude": -55.9926
    },
    {
        "nome": "Itajá",
        "latitude": -5.63894,
        "longitude": -36.8712
    },
    {
        "nome": "Itajá",
        "latitude": -19.0673,
        "longitude": -51.5495
    },
    {
        "nome": "Itajaí",
        "latitude": -26.9101,
        "longitude": -48.6705
    },
    {
        "nome": "Itajobi",
        "latitude": -21.3123,
        "longitude": -49.0629
    },
    {
        "nome": "Itaju",
        "latitude": -21.9857,
        "longitude": -48.8116
    },
    {
        "nome": "Itaju do Colônia",
        "latitude": -15.1366,
        "longitude": -39.7283
    },
    {
        "nome": "Itajubá",
        "latitude": -22.4225,
        "longitude": -45.4598
    },
    {
        "nome": "Itajuípe",
        "latitude": -14.6788,
        "longitude": -39.3698
    },
    {
        "nome": "Italva",
        "latitude": -21.4296,
        "longitude": -41.7014
    },
    {
        "nome": "Itamaraju",
        "latitude": -17.0378,
        "longitude": -39.5386
    },
    {
        "nome": "Itamarandiba",
        "latitude": -17.8552,
        "longitude": -42.8561
    },
    {
        "nome": "Itamarati",
        "latitude": -6.43852,
        "longitude": -68.2437
    },
    {
        "nome": "Itamarati de Minas",
        "latitude": -21.4179,
        "longitude": -42.813
    },
    {
        "nome": "Itamari",
        "latitude": -13.7782,
        "longitude": -39.683
    },
    {
        "nome": "Itambacuri",
        "latitude": -18.035,
        "longitude": -41.683
    },
    {
        "nome": "Itambaracá",
        "latitude": -23.0181,
        "longitude": -50.4097
    },
    {
        "nome": "Itambé",
        "latitude": -23.6601,
        "longitude": -51.9912
    },
    {
        "nome": "Itambé",
        "latitude": -7.41403,
        "longitude": -35.0963
    },
    {
        "nome": "Itambé",
        "latitude": -15.2429,
        "longitude": -40.63
    },
    {
        "nome": "Itambé do Mato Dentro",
        "latitude": -19.4158,
        "longitude": -43.3182
    },
    {
        "nome": "Itamogi",
        "latitude": -21.0758,
        "longitude": -47.046
    },
    {
        "nome": "Itamonte",
        "latitude": -22.2859,
        "longitude": -44.868
    },
    {
        "nome": "Itanagra",
        "latitude": -12.2614,
        "longitude": -38.0436
    },
    {
        "nome": "Itanhaém",
        "latitude": -24.1736,
        "longitude": -46.788
    },
    {
        "nome": "Itanhandu",
        "latitude": -22.2942,
        "longitude": -44.9382
    },
    {
        "nome": "Itanhangá",
        "latitude": -12.2259,
        "longitude": -56.6463
    },
    {
        "nome": "Itanhém",
        "latitude": -17.1642,
        "longitude": -40.3321
    },
    {
        "nome": "Itanhomi",
        "latitude": -19.1736,
        "longitude": -41.863
    },
    {
        "nome": "Itaobim",
        "latitude": -16.5571,
        "longitude": -41.5017
    },
    {
        "nome": "Itaóca",
        "latitude": -24.6393,
        "longitude": -48.8413
    },
    {
        "nome": "Itaocara",
        "latitude": -21.6748,
        "longitude": -42.0758
    },
    {
        "nome": "Itapaci",
        "latitude": -14.9522,
        "longitude": -49.5511
    },
    {
        "nome": "Itapagipe",
        "latitude": -19.9062,
        "longitude": -49.3781
    },
    {
        "nome": "Itapajé",
        "latitude": -3.68314,
        "longitude": -39.5855
    },
    {
        "nome": "Itaparica",
        "latitude": -12.8932,
        "longitude": -38.68
    },
    {
        "nome": "Itapé",
        "latitude": -14.8876,
        "longitude": -39.4239
    },
    {
        "nome": "Itapebi",
        "latitude": -15.9551,
        "longitude": -39.5329
    },
    {
        "nome": "Itapecerica",
        "latitude": -20.4704,
        "longitude": -45.127
    },
    {
        "nome": "Itapecerica da Serra",
        "latitude": -23.7161,
        "longitude": -46.8572
    },
    {
        "nome": "Itapecuru Mirim",
        "latitude": -3.40202,
        "longitude": -44.3508
    },
    {
        "nome": "Itapejara d'Oeste",
        "latitude": -25.9619,
        "longitude": -52.8152
    },
    {
        "nome": "Itapema",
        "latitude": -27.0861,
        "longitude": -48.616
    },
    {
        "nome": "Itapemirim",
        "latitude": -21.0095,
        "longitude": -40.8307
    },
    {
        "nome": "Itaperuçu",
        "latitude": -25.2193,
        "longitude": -49.3454
    },
    {
        "nome": "Itaperuna",
        "latitude": -21.1997,
        "longitude": -41.8799
    },
    {
        "nome": "Itapetim",
        "latitude": -7.37178,
        "longitude": -37.1863
    },
    {
        "nome": "Itapetinga",
        "latitude": -15.2475,
        "longitude": -40.2482
    },
    {
        "nome": "Itapetininga",
        "latitude": -23.5886,
        "longitude": -48.0483
    },
    {
        "nome": "Itapeva",
        "latitude": -23.9788,
        "longitude": -48.8764
    },
    {
        "nome": "Itapeva",
        "latitude": -22.7665,
        "longitude": -46.2241
    },
    {
        "nome": "Itapevi",
        "latitude": -23.5488,
        "longitude": -46.9327
    },
    {
        "nome": "Itapicuru",
        "latitude": -11.3088,
        "longitude": -38.2262
    },
    {
        "nome": "Itapipoca",
        "latitude": -3.49933,
        "longitude": -39.5836
    },
    {
        "nome": "Itapira",
        "latitude": -22.4357,
        "longitude": -46.8224
    },
    {
        "nome": "Itapiranga",
        "latitude": -2.74081,
        "longitude": -58.0293
    },
    {
        "nome": "Itapiranga",
        "latitude": -27.1659,
        "longitude": -53.7166
    },
    {
        "nome": "Itapirapuã",
        "latitude": -15.8205,
        "longitude": -50.6094
    },
    {
        "nome": "Itapirapuã Paulista",
        "latitude": -24.572,
        "longitude": -49.1661
    },
    {
        "nome": "Itapiratins",
        "latitude": -8.37982,
        "longitude": -48.1072
    },
    {
        "nome": "Itapissuma",
        "latitude": -7.76798,
        "longitude": -34.8971
    },
    {
        "nome": "Itapitanga",
        "latitude": -14.4139,
        "longitude": -39.5657
    },
    {
        "nome": "Itapiúna",
        "latitude": -4.55516,
        "longitude": -38.9281
    },
    {
        "nome": "Itapoá",
        "latitude": -26.1158,
        "longitude": -48.6182
    },
    {
        "nome": "Itápolis",
        "latitude": -21.5942,
        "longitude": -48.8149
    },
    {
        "nome": "Itaporã",
        "latitude": -22.08,
        "longitude": -54.7934
    },
    {
        "nome": "Itaporã do Tocantins",
        "latitude": -8.57172,
        "longitude": -48.6895
    },
    {
        "nome": "Itaporanga",
        "latitude": -23.7043,
        "longitude": -49.4819
    },
    {
        "nome": "Itaporanga",
        "latitude": -7.30202,
        "longitude": -38.1504
    },
    {
        "nome": "Itaporanga d'Ajuda",
        "latitude": -10.99,
        "longitude": -37.3078
    },
    {
        "nome": "Itapororoca",
        "latitude": -6.82374,
        "longitude": -35.2406
    },
    {
        "nome": "Itapuã do Oeste",
        "latitude": -9.19687,
        "longitude": -63.1809
    },
    {
        "nome": "Itapuca",
        "latitude": -28.7768,
        "longitude": -52.1693
    },
    {
        "nome": "Itapuí",
        "latitude": -22.2324,
        "longitude": -48.7197
    },
    {
        "nome": "Itapura",
        "latitude": -20.6419,
        "longitude": -51.5063
    },
    {
        "nome": "Itapuranga",
        "latitude": -15.5606,
        "longitude": -49.949
    },
    {
        "nome": "Itaquaquecetuba",
        "latitude": -23.4835,
        "longitude": -46.3457
    },
    {
        "nome": "Itaquara",
        "latitude": -13.4459,
        "longitude": -39.9378
    },
    {
        "nome": "Itaqui",
        "latitude": -29.1311,
        "longitude": -56.5515
    },
    {
        "nome": "Itaquiraí",
        "latitude": -23.4779,
        "longitude": -54.187
    },
    {
        "nome": "Itaquitinga",
        "latitude": -7.66373,
        "longitude": -35.1002
    },
    {
        "nome": "Itarana",
        "latitude": -19.875,
        "longitude": -40.8753
    },
    {
        "nome": "Itarantim",
        "latitude": -15.6528,
        "longitude": -40.065
    },
    {
        "nome": "Itararé",
        "latitude": -24.1085,
        "longitude": -49.3352
    },
    {
        "nome": "Itarema",
        "latitude": -2.9248,
        "longitude": -39.9167
    },
    {
        "nome": "Itariri",
        "latitude": -24.2834,
        "longitude": -47.1736
    },
    {
        "nome": "Itarumã",
        "latitude": -18.7646,
        "longitude": -51.3485
    },
    {
        "nome": "Itati",
        "latitude": -29.4974,
        "longitude": -50.1016
    },
    {
        "nome": "Itatiaia",
        "latitude": -22.4897,
        "longitude": -44.5675
    },
    {
        "nome": "Itatiaiuçu",
        "latitude": -20.1983,
        "longitude": -44.4211
    },
    {
        "nome": "Itatiba",
        "latitude": -23.0035,
        "longitude": -46.8464
    },
    {
        "nome": "Itatiba do Sul",
        "latitude": -27.3846,
        "longitude": -52.4538
    },
    {
        "nome": "Itatim",
        "latitude": -12.7099,
        "longitude": -39.6952
    },
    {
        "nome": "Itatinga",
        "latitude": -23.1047,
        "longitude": -48.6157
    },
    {
        "nome": "Itatira",
        "latitude": -4.52608,
        "longitude": -39.6202
    },
    {
        "nome": "Itatuba",
        "latitude": -7.38115,
        "longitude": -35.638
    },
    {
        "nome": "Itaú",
        "latitude": -5.8363,
        "longitude": -37.9912
    },
    {
        "nome": "Itaú de Minas",
        "latitude": -20.7375,
        "longitude": -46.7525
    },
    {
        "nome": "Itaúba",
        "latitude": -11.0614,
        "longitude": -55.2766
    },
    {
        "nome": "Itaubal",
        "latitude": 0.602185,
        "longitude": -50.6996
    },
    {
        "nome": "Itauçu",
        "latitude": -16.2029,
        "longitude": -49.6109
    },
    {
        "nome": "Itaueira",
        "latitude": -7.59989,
        "longitude": -43.0249
    },
    {
        "nome": "Itaúna",
        "latitude": -20.0818,
        "longitude": -44.5801
    },
    {
        "nome": "Itaúna do Sul",
        "latitude": -22.7289,
        "longitude": -52.8874
    },
    {
        "nome": "Itaverava",
        "latitude": -20.6769,
        "longitude": -43.6141
    },
    {
        "nome": "Itinga",
        "latitude": -16.61,
        "longitude": -41.7672
    },
    {
        "nome": "Itinga do Maranhão",
        "latitude": -4.45293,
        "longitude": -47.5235
    },
    {
        "nome": "Itiquira",
        "latitude": -17.2147,
        "longitude": -54.1422
    },
    {
        "nome": "Itirapina",
        "latitude": -22.2562,
        "longitude": -47.8166
    },
    {
        "nome": "Itirapuã",
        "latitude": -20.6416,
        "longitude": -47.2194
    },
    {
        "nome": "Itiruçu",
        "latitude": -13.529,
        "longitude": -40.1472
    },
    {
        "nome": "Itiúba",
        "latitude": -10.6948,
        "longitude": -39.8446
    },
    {
        "nome": "Itobi",
        "latitude": -21.7309,
        "longitude": -46.9743
    },
    {
        "nome": "Itororó",
        "latitude": -15.11,
        "longitude": -40.0684
    },
    {
        "nome": "Itu",
        "latitude": -23.2544,
        "longitude": -47.2927
    },
    {
        "nome": "Ituaçu",
        "latitude": -13.8107,
        "longitude": -41.3003
    },
    {
        "nome": "Ituberá",
        "latitude": -13.7249,
        "longitude": -39.1481
    },
    {
        "nome": "Itueta",
        "latitude": -19.3999,
        "longitude": -41.1746
    },
    {
        "nome": "Ituiutaba",
        "latitude": -18.9772,
        "longitude": -49.4639
    },
    {
        "nome": "Itumbiara",
        "latitude": -18.4093,
        "longitude": -49.2158
    },
    {
        "nome": "Itumirim",
        "latitude": -21.3171,
        "longitude": -44.8724
    },
    {
        "nome": "Itupeva",
        "latitude": -23.1526,
        "longitude": -47.0593
    },
    {
        "nome": "Itupiranga",
        "latitude": -5.13272,
        "longitude": -49.3358
    },
    {
        "nome": "Ituporanga",
        "latitude": -27.4101,
        "longitude": -49.5963
    },
    {
        "nome": "Iturama",
        "latitude": -19.7276,
        "longitude": -50.1966
    },
    {
        "nome": "Itutinga",
        "latitude": -21.3,
        "longitude": -44.6567
    },
    {
        "nome": "Ituverava",
        "latitude": -20.3355,
        "longitude": -47.7902
    },
    {
        "nome": "Iuiú",
        "latitude": -14.4054,
        "longitude": -43.5595
    },
    {
        "nome": "Iúna",
        "latitude": -20.3531,
        "longitude": -41.5334
    },
    {
        "nome": "Ivaí",
        "latitude": -25.0067,
        "longitude": -50.857
    },
    {
        "nome": "Ivaiporã",
        "latitude": -24.2485,
        "longitude": -51.6754
    },
    {
        "nome": "Ivaté",
        "latitude": -23.4072,
        "longitude": -53.3687
    },
    {
        "nome": "Ivatuba",
        "latitude": -23.6187,
        "longitude": -52.2203
    },
    {
        "nome": "Ivinhema",
        "latitude": -22.3046,
        "longitude": -53.8184
    },
    {
        "nome": "Ivolândia",
        "latitude": -16.5995,
        "longitude": -50.7921
    },
    {
        "nome": "Ivorá",
        "latitude": -29.5232,
        "longitude": -53.5842
    },
    {
        "nome": "Ivoti",
        "latitude": -29.5995,
        "longitude": -51.1533
    },
    {
        "nome": "Jaboatão dos Guararapes",
        "latitude": -8.11298,
        "longitude": -35.015
    },
    {
        "nome": "Jaborá",
        "latitude": -27.1782,
        "longitude": -51.7279
    },
    {
        "nome": "Jaborandi",
        "latitude": -13.6071,
        "longitude": -44.4255
    },
    {
        "nome": "Jaborandi",
        "latitude": -20.6884,
        "longitude": -48.4112
    },
    {
        "nome": "Jaboti",
        "latitude": -23.7435,
        "longitude": -50.0729
    },
    {
        "nome": "Jaboticaba",
        "latitude": -27.6347,
        "longitude": -53.2762
    },
    {
        "nome": "Jaboticabal",
        "latitude": -21.252,
        "longitude": -48.3252
    },
    {
        "nome": "Jaboticatubas",
        "latitude": -19.5119,
        "longitude": -43.7373
    },
    {
        "nome": "Jaçanã",
        "latitude": -6.41856,
        "longitude": -36.2031
    },
    {
        "nome": "Jacaraci",
        "latitude": -14.8541,
        "longitude": -42.4329
    },
    {
        "nome": "Jacaraú",
        "latitude": -6.61453,
        "longitude": -35.289
    },
    {
        "nome": "Jacaré dos Homens",
        "latitude": -9.63545,
        "longitude": -37.2076
    },
    {
        "nome": "Jacareacanga",
        "latitude": -6.21469,
        "longitude": -57.7544
    },
    {
        "nome": "Jacareí",
        "latitude": -23.2983,
        "longitude": -45.9658
    },
    {
        "nome": "Jacarezinho",
        "latitude": -23.1591,
        "longitude": -49.9739
    },
    {
        "nome": "Jaci",
        "latitude": -20.8805,
        "longitude": -49.5797
    },
    {
        "nome": "Jaciara",
        "latitude": -15.9548,
        "longitude": -54.9733
    },
    {
        "nome": "Jacinto",
        "latitude": -16.1428,
        "longitude": -40.295
    },
    {
        "nome": "Jacinto Machado",
        "latitude": -28.9961,
        "longitude": -49.7623
    },
    {
        "nome": "Jacobina",
        "latitude": -11.1812,
        "longitude": -40.5117
    },
    {
        "nome": "Jacobina do Piauí",
        "latitude": -7.93063,
        "longitude": -41.2075
    },
    {
        "nome": "Jacuí",
        "latitude": -21.0137,
        "longitude": -46.7359
    },
    {
        "nome": "Jacuípe",
        "latitude": -8.83951,
        "longitude": -35.4591
    },
    {
        "nome": "Jacuizinho",
        "latitude": -29.0401,
        "longitude": -53.0657
    },
    {
        "nome": "Jacundá",
        "latitude": -4.44617,
        "longitude": -49.1153
    },
    {
        "nome": "Jacupiranga",
        "latitude": -24.6963,
        "longitude": -48.0064
    },
    {
        "nome": "Jacutinga",
        "latitude": -27.7291,
        "longitude": -52.5372
    },
    {
        "nome": "Jacutinga",
        "latitude": -22.286,
        "longitude": -46.6166
    },
    {
        "nome": "Jaguapitã",
        "latitude": -23.1104,
        "longitude": -51.5342
    },
    {
        "nome": "Jaguaquara",
        "latitude": -13.5248,
        "longitude": -39.964
    },
    {
        "nome": "Jaguaraçu",
        "latitude": -19.647,
        "longitude": -42.7498
    },
    {
        "nome": "Jaguarão",
        "latitude": -32.5604,
        "longitude": -53.377
    },
    {
        "nome": "Jaguarari",
        "latitude": -10.2569,
        "longitude": -40.1999
    },
    {
        "nome": "Jaguaré",
        "latitude": -18.907,
        "longitude": -40.0759
    },
    {
        "nome": "Jaguaretama",
        "latitude": -5.6051,
        "longitude": -38.7639
    },
    {
        "nome": "Jaguari",
        "latitude": -29.4936,
        "longitude": -54.703
    },
    {
        "nome": "Jaguariaíva",
        "latitude": -24.2439,
        "longitude": -49.7066
    },
    {
        "nome": "Jaguaribara",
        "latitude": -5.67765,
        "longitude": -38.5359
    },
    {
        "nome": "Jaguaribe",
        "latitude": -5.90213,
        "longitude": -38.6227
    },
    {
        "nome": "Jaguaripe",
        "latitude": -13.1109,
        "longitude": -38.8939
    },
    {
        "nome": "Jaguariúna",
        "latitude": -22.7037,
        "longitude": -46.9851
    },
    {
        "nome": "Jaguaruana",
        "latitude": -4.83151,
        "longitude": -37.781
    },
    {
        "nome": "Jaguaruna",
        "latitude": -28.6146,
        "longitude": -49.0296
    },
    {
        "nome": "Jaíba",
        "latitude": -15.3432,
        "longitude": -43.6688
    },
    {
        "nome": "Jaicós",
        "latitude": -7.36229,
        "longitude": -41.1371
    },
    {
        "nome": "Jales",
        "latitude": -20.2672,
        "longitude": -50.5494
    },
    {
        "nome": "Jambeiro",
        "latitude": -23.2522,
        "longitude": -45.6942
    },
    {
        "nome": "Jampruca",
        "latitude": -18.461,
        "longitude": -41.809
    },
    {
        "nome": "Janaúba",
        "latitude": -15.8022,
        "longitude": -43.3132
    },
    {
        "nome": "Jandaia",
        "latitude": -17.0481,
        "longitude": -50.1453
    },
    {
        "nome": "Jandaia do Sul",
        "latitude": -23.6011,
        "longitude": -51.6448
    },
    {
        "nome": "Jandaíra",
        "latitude": -5.35211,
        "longitude": -36.1278
    },
    {
        "nome": "Jandaíra",
        "latitude": -11.5616,
        "longitude": -37.7853
    },
    {
        "nome": "Jandira",
        "latitude": -23.5275,
        "longitude": -46.9023
    },
    {
        "nome": "Janduís",
        "latitude": -6.01474,
        "longitude": -37.4048
    },
    {
        "nome": "Jangada",
        "latitude": -15.235,
        "longitude": -56.4917
    },
    {
        "nome": "Janiópolis",
        "latitude": -24.1401,
        "longitude": -52.7784
    },
    {
        "nome": "Januária",
        "latitude": -15.4802,
        "longitude": -44.3639
    },
    {
        "nome": "Januário Cicco (Boa Saúde)",
        "latitude": -6.16566,
        "longitude": -35.6219
    },
    {
        "nome": "Japaraíba",
        "latitude": -20.1442,
        "longitude": -45.5015
    },
    {
        "nome": "Japaratinga",
        "latitude": -9.08746,
        "longitude": -35.2634
    },
    {
        "nome": "Japaratuba",
        "latitude": -10.5849,
        "longitude": -36.9418
    },
    {
        "nome": "Japeri",
        "latitude": -22.6435,
        "longitude": -43.6602
    },
    {
        "nome": "Japi",
        "latitude": -6.46544,
        "longitude": -35.9346
    },
    {
        "nome": "Japira",
        "latitude": -23.8142,
        "longitude": -50.1422
    },
    {
        "nome": "Japoatã",
        "latitude": -10.3477,
        "longitude": -36.8045
    },
    {
        "nome": "Japonvar",
        "latitude": -15.9891,
        "longitude": -44.2758
    },
    {
        "nome": "Japorã",
        "latitude": -23.8903,
        "longitude": -54.4059
    },
    {
        "nome": "Japurá",
        "latitude": -23.4693,
        "longitude": -52.5557
    },
    {
        "nome": "Japurá",
        "latitude": -1.88237,
        "longitude": -66.9291
    },
    {
        "nome": "Jaqueira",
        "latitude": -8.72618,
        "longitude": -35.7942
    },
    {
        "nome": "Jaquirana",
        "latitude": -28.8811,
        "longitude": -50.3637
    },
    {
        "nome": "Jaraguá",
        "latitude": -15.7529,
        "longitude": -49.3344
    },
    {
        "nome": "Jaraguá do Sul",
        "latitude": -26.4851,
        "longitude": -49.0713
    },
    {
        "nome": "Jaraguari",
        "latitude": -20.1386,
        "longitude": -54.3996
    },
    {
        "nome": "Jaramataia",
        "latitude": -9.66224,
        "longitude": -37.0046
    },
    {
        "nome": "Jardim",
        "latitude": -7.57599,
        "longitude": -39.2826
    },
    {
        "nome": "Jardim",
        "latitude": -21.4799,
        "longitude": -56.1489
    },
    {
        "nome": "Jardim Alegre",
        "latitude": -24.1809,
        "longitude": -51.6902
    },
    {
        "nome": "Jardim de Angicos",
        "latitude": -5.64999,
        "longitude": -35.9713
    },
    {
        "nome": "Jardim de Piranhas",
        "latitude": -6.37665,
        "longitude": -37.3496
    },
    {
        "nome": "Jardim do Mulato",
        "latitude": -6.099,
        "longitude": -42.63
    },
    {
        "nome": "Jardim do Seridó",
        "latitude": -6.58047,
        "longitude": -36.7736
    },
    {
        "nome": "Jardim Olinda",
        "latitude": -22.5523,
        "longitude": -52.0503
    },
    {
        "nome": "Jardinópolis",
        "latitude": -21.0176,
        "longitude": -47.7606
    },
    {
        "nome": "Jardinópolis",
        "latitude": -26.7191,
        "longitude": -52.8625
    },
    {
        "nome": "Jari",
        "latitude": -29.2922,
        "longitude": -54.2237
    },
    {
        "nome": "Jarinu",
        "latitude": -23.1039,
        "longitude": -46.728
    },
    {
        "nome": "Jaru",
        "latitude": -10.4318,
        "longitude": -62.4788
    },
    {
        "nome": "Jataí",
        "latitude": -17.8784,
        "longitude": -51.7204
    },
    {
        "nome": "Jataizinho",
        "latitude": -23.2578,
        "longitude": -50.9777
    },
    {
        "nome": "Jataúba",
        "latitude": -7.97668,
        "longitude": -36.4943
    },
    {
        "nome": "Jateí",
        "latitude": -22.4806,
        "longitude": -54.3079
    },
    {
        "nome": "Jati",
        "latitude": -7.6797,
        "longitude": -39.0029
    },
    {
        "nome": "Jatobá",
        "latitude": -5.82282,
        "longitude": -44.2153
    },
    {
        "nome": "Jatobá",
        "latitude": -9.17476,
        "longitude": -38.2607
    },
    {
        "nome": "Jatobá do Piauí",
        "latitude": -4.77025,
        "longitude": -41.817
    },
    {
        "nome": "Jaú",
        "latitude": -22.2936,
        "longitude": -48.5592
    },
    {
        "nome": "Jaú do Tocantins",
        "latitude": -12.6509,
        "longitude": -48.589
    },
    {
        "nome": "Jaupaci",
        "latitude": -16.1773,
        "longitude": -50.9508
    },
    {
        "nome": "Jauru",
        "latitude": -15.3342,
        "longitude": -58.8723
    },
    {
        "nome": "Jeceaba",
        "latitude": -20.5339,
        "longitude": -43.9894
    },
    {
        "nome": "Jenipapo de Minas",
        "latitude": -17.0831,
        "longitude": -42.2589
    },
    {
        "nome": "Jenipapo dos Vieiras",
        "latitude": -5.36237,
        "longitude": -45.6356
    },
    {
        "nome": "Jequeri",
        "latitude": -20.4542,
        "longitude": -42.6651
    },
    {
        "nome": "Jequiá da Praia",
        "latitude": -10.0133,
        "longitude": -36.0142
    },
    {
        "nome": "Jequié",
        "latitude": -13.8509,
        "longitude": -40.0877
    },
    {
        "nome": "Jequitaí",
        "latitude": -17.229,
        "longitude": -44.4376
    },
    {
        "nome": "Jequitibá",
        "latitude": -19.2345,
        "longitude": -44.0304
    },
    {
        "nome": "Jequitinhonha",
        "latitude": -16.4375,
        "longitude": -41.0117
    },
    {
        "nome": "Jeremoabo",
        "latitude": -10.0685,
        "longitude": -38.3471
    },
    {
        "nome": "Jericó",
        "latitude": -6.54577,
        "longitude": -37.8036
    },
    {
        "nome": "Jeriquara",
        "latitude": -20.3116,
        "longitude": -47.5918
    },
    {
        "nome": "Jerônimo Monteiro",
        "latitude": -20.7994,
        "longitude": -41.3948
    },
    {
        "nome": "Jerumenha",
        "latitude": -7.09128,
        "longitude": -43.5033
    },
    {
        "nome": "Jesuânia",
        "latitude": -21.9887,
        "longitude": -45.2911
    },
    {
        "nome": "Jesuítas",
        "latitude": -24.3839,
        "longitude": -53.3849
    },
    {
        "nome": "Jesúpolis",
        "latitude": -15.9484,
        "longitude": -49.3739
    },
    {
        "nome": "Ji-Paraná",
        "latitude": -10.8777,
        "longitude": -61.9322
    },
    {
        "nome": "Jijoca de Jericoacoara",
        "latitude": -2.79331,
        "longitude": -40.5127
    },
    {
        "nome": "Jiquiriçá",
        "latitude": -13.2621,
        "longitude": -39.5737
    },
    {
        "nome": "Jitaúna",
        "latitude": -14.0131,
        "longitude": -39.8969
    },
    {
        "nome": "Joaçaba",
        "latitude": -27.1721,
        "longitude": -51.5108
    },
    {
        "nome": "Joaíma",
        "latitude": -16.6522,
        "longitude": -41.0229
    },
    {
        "nome": "Joanésia",
        "latitude": -19.1729,
        "longitude": -42.6775
    },
    {
        "nome": "Joanópolis",
        "latitude": -22.927,
        "longitude": -46.2741
    },
    {
        "nome": "João Alfredo",
        "latitude": -7.86565,
        "longitude": -35.5787
    },
    {
        "nome": "João Câmara",
        "latitude": -5.54094,
        "longitude": -35.8122
    },
    {
        "nome": "João Costa",
        "latitude": -8.50736,
        "longitude": -42.4264
    },
    {
        "nome": "João Dias",
        "latitude": -6.27215,
        "longitude": -37.7885
    },
    {
        "nome": "João Dourado",
        "latitude": -11.3486,
        "longitude": -41.6548
    },
    {
        "nome": "João Lisboa",
        "latitude": -5.44363,
        "longitude": -47.4064
    },
    {
        "nome": "João Monlevade",
        "latitude": -19.8126,
        "longitude": -43.1735
    },
    {
        "nome": "João Neiva",
        "latitude": -19.7577,
        "longitude": -40.386
    },
    {
        "nome": "João Pessoa",
        "latitude": -7.11509,
        "longitude": -34.8641
    },
    {
        "nome": "João Pinheiro",
        "latitude": -17.7398,
        "longitude": -46.1715
    },
    {
        "nome": "João Ramalho",
        "latitude": -22.2473,
        "longitude": -50.7694
    },
    {
        "nome": "Joaquim Felício",
        "latitude": -17.758,
        "longitude": -44.1643
    },
    {
        "nome": "Joaquim Gomes",
        "latitude": -9.1328,
        "longitude": -35.7474
    },
    {
        "nome": "Joaquim Nabuco",
        "latitude": -8.62281,
        "longitude": -35.5288
    },
    {
        "nome": "Joaquim Pires",
        "latitude": -3.50164,
        "longitude": -42.1865
    },
    {
        "nome": "Joaquim Távora",
        "latitude": -23.4987,
        "longitude": -49.909
    },
    {
        "nome": "Joca Claudino",
        "latitude": -6.48362,
        "longitude": -38.4764
    },
    {
        "nome": "Joca Marques",
        "latitude": -3.4804,
        "longitude": -42.4255
    },
    {
        "nome": "Jóia",
        "latitude": -28.6435,
        "longitude": -54.1141
    },
    {
        "nome": "Joinville",
        "latitude": -26.3045,
        "longitude": -48.8487
    },
    {
        "nome": "Jordânia",
        "latitude": -15.9009,
        "longitude": -40.1841
    },
    {
        "nome": "Jordão",
        "latitude": -9.43091,
        "longitude": -71.8974
    },
    {
        "nome": "José Boiteux",
        "latitude": -26.9566,
        "longitude": -49.6286
    },
    {
        "nome": "José Bonifácio",
        "latitude": -21.0551,
        "longitude": -49.6892
    },
    {
        "nome": "José da Penha",
        "latitude": -6.31095,
        "longitude": -38.2823
    },
    {
        "nome": "José de Freitas",
        "latitude": -4.75146,
        "longitude": -42.5746
    },
    {
        "nome": "José Gonçalves de Minas",
        "latitude": -16.9053,
        "longitude": -42.6014
    },
    {
        "nome": "José Raydan",
        "latitude": -18.2195,
        "longitude": -42.4946
    },
    {
        "nome": "Joselândia",
        "latitude": -4.98611,
        "longitude": -44.6958
    },
    {
        "nome": "Josenópolis",
        "latitude": -16.5417,
        "longitude": -42.5151
    },
    {
        "nome": "Joviânia",
        "latitude": -17.802,
        "longitude": -49.6197
    },
    {
        "nome": "Juara",
        "latitude": -11.2639,
        "longitude": -57.5244
    },
    {
        "nome": "Juarez Távora",
        "latitude": -7.1713,
        "longitude": -35.5686
    },
    {
        "nome": "Juarina",
        "latitude": -8.11951,
        "longitude": -49.0643
    },
    {
        "nome": "Juatuba",
        "latitude": -19.9448,
        "longitude": -44.3451
    },
    {
        "nome": "Juazeirinho",
        "latitude": -7.06092,
        "longitude": -36.5793
    },
    {
        "nome": "Juazeiro",
        "latitude": -9.41622,
        "longitude": -40.5033
    },
    {
        "nome": "Juazeiro do Norte",
        "latitude": -7.19621,
        "longitude": -39.3076
    },
    {
        "nome": "Juazeiro do Piauí",
        "latitude": -5.17459,
        "longitude": -41.6976
    },
    {
        "nome": "Jucás",
        "latitude": -6.51523,
        "longitude": -39.5187
    },
    {
        "nome": "Jucati",
        "latitude": -8.70195,
        "longitude": -36.4871
    },
    {
        "nome": "Jucuruçu",
        "latitude": -16.8488,
        "longitude": -40.1641
    },
    {
        "nome": "Jucurutu",
        "latitude": -6.0306,
        "longitude": -37.009
    },
    {
        "nome": "Juína",
        "latitude": -11.3728,
        "longitude": -58.7483
    },
    {
        "nome": "Juiz de Fora",
        "latitude": -21.7595,
        "longitude": -43.3398
    },
    {
        "nome": "Júlio Borges",
        "latitude": -10.3225,
        "longitude": -44.2381
    },
    {
        "nome": "Júlio de Castilhos",
        "latitude": -29.2299,
        "longitude": -53.6772
    },
    {
        "nome": "Júlio Mesquita",
        "latitude": -22.0112,
        "longitude": -49.7873
    },
    {
        "nome": "Jumirim",
        "latitude": -23.0884,
        "longitude": -47.7868
    },
    {
        "nome": "Junco do Maranhão",
        "latitude": -1.83888,
        "longitude": -46.09
    },
    {
        "nome": "Junco do Seridó",
        "latitude": -6.99269,
        "longitude": -36.7166
    },
    {
        "nome": "Jundiá",
        "latitude": -6.26866,
        "longitude": -35.3495
    },
    {
        "nome": "Jundiá",
        "latitude": -8.93297,
        "longitude": -35.5669
    },
    {
        "nome": "Jundiaí",
        "latitude": -23.1852,
        "longitude": -46.8974
    },
    {
        "nome": "Jundiaí do Sul",
        "latitude": -23.4357,
        "longitude": -50.2496
    },
    {
        "nome": "Junqueiro",
        "latitude": -9.90696,
        "longitude": -36.4803
    },
    {
        "nome": "Junqueirópolis",
        "latitude": -21.5103,
        "longitude": -51.4342
    },
    {
        "nome": "Jupi",
        "latitude": -8.70904,
        "longitude": -36.4126
    },
    {
        "nome": "Jupiá",
        "latitude": -26.395,
        "longitude": -52.7298
    },
    {
        "nome": "Juquiá",
        "latitude": -24.3101,
        "longitude": -47.6426
    },
    {
        "nome": "Juquitiba",
        "latitude": -23.9244,
        "longitude": -47.0653
    },
    {
        "nome": "Juramento",
        "latitude": -16.8473,
        "longitude": -43.5865
    },
    {
        "nome": "Juranda",
        "latitude": -24.4209,
        "longitude": -52.8413
    },
    {
        "nome": "Jurema",
        "latitude": -8.70714,
        "longitude": -36.1347
    },
    {
        "nome": "Jurema",
        "latitude": -9.21992,
        "longitude": -43.1337
    },
    {
        "nome": "Juripiranga",
        "latitude": -7.36176,
        "longitude": -35.2321
    },
    {
        "nome": "Juru",
        "latitude": -7.52983,
        "longitude": -37.815
    },
    {
        "nome": "Juruá",
        "latitude": -3.48438,
        "longitude": -66.0718
    },
    {
        "nome": "Juruaia",
        "latitude": -21.2493,
        "longitude": -46.5735
    },
    {
        "nome": "Juruena",
        "latitude": -10.3178,
        "longitude": -58.3592
    },
    {
        "nome": "Juruti",
        "latitude": -2.16347,
        "longitude": -56.0889
    },
    {
        "nome": "Juscimeira",
        "latitude": -16.0633,
        "longitude": -54.8859
    },
    {
        "nome": "Jussara",
        "latitude": -11.0431,
        "longitude": -41.9702
    },
    {
        "nome": "Jussara",
        "latitude": -15.8659,
        "longitude": -50.8668
    },
    {
        "nome": "Jussara",
        "latitude": -23.6219,
        "longitude": -52.4693
    },
    {
        "nome": "Jussari",
        "latitude": -15.192,
        "longitude": -39.491
    },
    {
        "nome": "Jussiape",
        "latitude": -13.5155,
        "longitude": -41.5882
    },
    {
        "nome": "Jutaí",
        "latitude": -2.75814,
        "longitude": -66.7595
    },
    {
        "nome": "Juti",
        "latitude": -22.8596,
        "longitude": -54.6061
    },
    {
        "nome": "Juvenília",
        "latitude": -14.2662,
        "longitude": -44.1597
    },
    {
        "nome": "Kaloré",
        "latitude": -23.8188,
        "longitude": -51.6687
    },
    {
        "nome": "Lábrea",
        "latitude": -7.26413,
        "longitude": -64.7948
    },
    {
        "nome": "Lacerdópolis",
        "latitude": -27.2579,
        "longitude": -51.5577
    },
    {
        "nome": "Ladainha",
        "latitude": -17.6279,
        "longitude": -41.7488
    },
    {
        "nome": "Ladário",
        "latitude": -19.0089,
        "longitude": -57.5973
    },
    {
        "nome": "Lafaiete Coutinho",
        "latitude": -13.6541,
        "longitude": -40.2119
    },
    {
        "nome": "Lagamar",
        "latitude": -18.1759,
        "longitude": -46.8063
    },
    {
        "nome": "Lagarto",
        "latitude": -10.9136,
        "longitude": -37.6689
    },
    {
        "nome": "Lages",
        "latitude": -27.815,
        "longitude": -50.3259
    },
    {
        "nome": "Lago da Pedra",
        "latitude": -4.56974,
        "longitude": -45.1319
    },
    {
        "nome": "Lago do Junco",
        "latitude": -4.609,
        "longitude": -45.049
    },
    {
        "nome": "Lago dos Rodrigues",
        "latitude": -4.61173,
        "longitude": -44.9798
    },
    {
        "nome": "Lago Verde",
        "latitude": -3.94661,
        "longitude": -44.826
    },
    {
        "nome": "Lagoa",
        "latitude": -6.58572,
        "longitude": -37.9127
    },
    {
        "nome": "Lagoa Alegre",
        "latitude": -4.51539,
        "longitude": -42.6309
    },
    {
        "nome": "Lagoa Bonita do Sul",
        "latitude": -29.4939,
        "longitude": -53.017
    },
    {
        "nome": "Lagoa d'Anta",
        "latitude": -6.39493,
        "longitude": -35.5949
    },
    {
        "nome": "Lagoa da Canoa",
        "latitude": -9.83291,
        "longitude": -36.7413
    },
    {
        "nome": "Lagoa da Confusão",
        "latitude": -10.7906,
        "longitude": -49.6199
    },
    {
        "nome": "Lagoa da Prata",
        "latitude": -20.0237,
        "longitude": -45.5401
    },
    {
        "nome": "Lagoa de Dentro",
        "latitude": -6.67213,
        "longitude": -35.3706
    },
    {
        "nome": "Lagoa de Itaenga",
        "latitude": -7.93005,
        "longitude": -35.2874
    },
    {
        "nome": "Lagoa de Pedras",
        "latitude": -6.15082,
        "longitude": -35.4299
    },
    {
        "nome": "Lagoa de São Francisco",
        "latitude": -4.38505,
        "longitude": -41.5969
    },
    {
        "nome": "Lagoa de Velhos",
        "latitude": -6.0119,
        "longitude": -35.8729
    },
    {
        "nome": "Lagoa do Barro do Piauí",
        "latitude": -8.47673,
        "longitude": -41.5342
    },
    {
        "nome": "Lagoa do Carro",
        "latitude": -7.84383,
        "longitude": -35.3108
    },
    {
        "nome": "Lagoa do Mato",
        "latitude": -6.05023,
        "longitude": -43.5333
    },
    {
        "nome": "Lagoa do Ouro",
        "latitude": -9.12567,
        "longitude": -36.4584
    },
    {
        "nome": "Lagoa do Piauí",
        "latitude": -5.41864,
        "longitude": -42.6437
    },
    {
        "nome": "Lagoa do Sítio",
        "latitude": -6.50766,
        "longitude": -41.5653
    },
    {
        "nome": "Lagoa do Tocantins",
        "latitude": -10.368,
        "longitude": -47.538
    },
    {
        "nome": "Lagoa dos Gatos",
        "latitude": -8.6602,
        "longitude": -35.904
    },
    {
        "nome": "Lagoa dos Patos",
        "latitude": -16.978,
        "longitude": -44.5754
    },
    {
        "nome": "Lagoa dos Três Cantos",
        "latitude": -28.5676,
        "longitude": -52.8618
    },
    {
        "nome": "Lagoa Dourada",
        "latitude": -20.9139,
        "longitude": -44.0797
    },
    {
        "nome": "Lagoa Formosa",
        "latitude": -18.7715,
        "longitude": -46.4012
    },
    {
        "nome": "Lagoa Grande",
        "latitude": -17.8323,
        "longitude": -46.5165
    },
    {
        "nome": "Lagoa Grande",
        "latitude": -8.99452,
        "longitude": -40.2767
    },
    {
        "nome": "Lagoa Grande do Maranhão",
        "latitude": -4.98893,
        "longitude": -45.3816
    },
    {
        "nome": "Lagoa Nova",
        "latitude": -6.09339,
        "longitude": -36.4703
    },
    {
        "nome": "Lagoa Real",
        "latitude": -14.0334,
        "longitude": -42.1328
    },
    {
        "nome": "Lagoa Salgada",
        "latitude": -6.12295,
        "longitude": -35.4724
    },
    {
        "nome": "Lagoa Santa",
        "latitude": -19.1832,
        "longitude": -51.3998
    },
    {
        "nome": "Lagoa Santa",
        "latitude": -19.6397,
        "longitude": -43.8932
    },
    {
        "nome": "Lagoa Seca",
        "latitude": -7.15535,
        "longitude": -35.8491
    },
    {
        "nome": "Lagoa Vermelha",
        "latitude": -28.2093,
        "longitude": -51.5248
    },
    {
        "nome": "Lagoão",
        "latitude": -29.2348,
        "longitude": -52.7997
    },
    {
        "nome": "Lagoinha",
        "latitude": -23.0846,
        "longitude": -45.1944
    },
    {
        "nome": "Lagoinha do Piauí",
        "latitude": -5.83074,
        "longitude": -42.6223
    },
    {
        "nome": "Laguna",
        "latitude": -28.4843,
        "longitude": -48.7772
    },
    {
        "nome": "Laguna Carapã",
        "latitude": -22.5448,
        "longitude": -55.1502
    },
    {
        "nome": "Laje",
        "latitude": -13.1673,
        "longitude": -39.4213
    },
    {
        "nome": "Laje do Muriaé",
        "latitude": -21.2091,
        "longitude": -42.1271
    },
    {
        "nome": "Lajeado",
        "latitude": -9.74996,
        "longitude": -48.3565
    },
    {
        "nome": "Lajeado",
        "latitude": -29.4591,
        "longitude": -51.9644
    },
    {
        "nome": "Lajeado do Bugre",
        "latitude": -27.6913,
        "longitude": -53.1818
    },
    {
        "nome": "Lajeado Grande",
        "latitude": -26.8576,
        "longitude": -52.5648
    },
    {
        "nome": "Lajeado Novo",
        "latitude": -6.18539,
        "longitude": -47.0293
    },
    {
        "nome": "Lajedão",
        "latitude": -17.6056,
        "longitude": -40.3383
    },
    {
        "nome": "Lajedinho",
        "latitude": -12.3529,
        "longitude": -40.9048
    },
    {
        "nome": "Lajedo",
        "latitude": -8.65791,
        "longitude": -36.3293
    },
    {
        "nome": "Lajedo do Tabocal",
        "latitude": -13.4663,
        "longitude": -40.2204
    },
    {
        "nome": "Lajes",
        "latitude": -5.69322,
        "longitude": -36.247
    },
    {
        "nome": "Lajes Pintadas",
        "latitude": -6.14943,
        "longitude": -36.1171
    },
    {
        "nome": "Lajinha",
        "latitude": -20.1539,
        "longitude": -41.6228
    },
    {
        "nome": "Lamarão",
        "latitude": -11.773,
        "longitude": -38.887
    },
    {
        "nome": "Lambari",
        "latitude": -21.9671,
        "longitude": -45.3498
    },
    {
        "nome": "Lambari D'Oeste",
        "latitude": -15.3188,
        "longitude": -58.0046
    },
    {
        "nome": "Lamim",
        "latitude": -20.79,
        "longitude": -43.4706
    },
    {
        "nome": "Landri Sales",
        "latitude": -7.25922,
        "longitude": -43.9364
    },
    {
        "nome": "Lapa",
        "latitude": -25.7671,
        "longitude": -49.7168
    },
    {
        "nome": "Lapão",
        "latitude": -11.3851,
        "longitude": -41.8286
    },
    {
        "nome": "Laranja da Terra",
        "latitude": -19.8994,
        "longitude": -41.0621
    },
    {
        "nome": "Laranjal",
        "latitude": -21.3715,
        "longitude": -42.4732
    },
    {
        "nome": "Laranjal",
        "latitude": -24.8862,
        "longitude": -52.47
    },
    {
        "nome": "Laranjal do Jari",
        "latitude": -0.804911,
        "longitude": -52.453
    },
    {
        "nome": "Laranjal Paulista",
        "latitude": -23.0506,
        "longitude": -47.8375
    },
    {
        "nome": "Laranjeiras",
        "latitude": -10.7981,
        "longitude": -37.1731
    },
    {
        "nome": "Laranjeiras do Sul",
        "latitude": -25.4077,
        "longitude": -52.4109
    },
    {
        "nome": "Lassance",
        "latitude": -17.887,
        "longitude": -44.5735
    },
    {
        "nome": "Lastro",
        "latitude": -6.50603,
        "longitude": -38.1742
    },
    {
        "nome": "Laurentino",
        "latitude": -27.2173,
        "longitude": -49.7331
    },
    {
        "nome": "Lauro de Freitas",
        "latitude": -12.8978,
        "longitude": -38.321
    },
    {
        "nome": "Lauro Muller",
        "latitude": -28.3859,
        "longitude": -49.4035
    },
    {
        "nome": "Lavandeira",
        "latitude": -12.7847,
        "longitude": -46.5099
    },
    {
        "nome": "Lavínia",
        "latitude": -21.1639,
        "longitude": -51.0412
    },
    {
        "nome": "Lavras",
        "latitude": -21.248,
        "longitude": -45.0009
    },
    {
        "nome": "Lavras da Mangabeira",
        "latitude": -6.7448,
        "longitude": -38.9706
    },
    {
        "nome": "Lavras do Sul",
        "latitude": -30.8071,
        "longitude": -53.8931
    },
    {
        "nome": "Lavrinhas",
        "latitude": -22.57,
        "longitude": -44.9024
    },
    {
        "nome": "Leandro Ferreira",
        "latitude": -19.7193,
        "longitude": -45.0279
    },
    {
        "nome": "Lebon Régis",
        "latitude": -26.928,
        "longitude": -50.6921
    },
    {
        "nome": "Leme",
        "latitude": -22.1809,
        "longitude": -47.3841
    },
    {
        "nome": "Leme do Prado",
        "latitude": -17.0793,
        "longitude": -42.6936
    },
    {
        "nome": "Lençóis",
        "latitude": -12.5616,
        "longitude": -41.3928
    },
    {
        "nome": "Lençóis Paulista",
        "latitude": -22.6027,
        "longitude": -48.8037
    },
    {
        "nome": "Leoberto Leal",
        "latitude": -27.5081,
        "longitude": -49.2789
    },
    {
        "nome": "Leopoldina",
        "latitude": -21.5296,
        "longitude": -42.6421
    },
    {
        "nome": "Leopoldo de Bulhões",
        "latitude": -16.619,
        "longitude": -48.7428
    },
    {
        "nome": "Leópolis",
        "latitude": -23.0818,
        "longitude": -50.7511
    },
    {
        "nome": "Liberato Salzano",
        "latitude": -27.601,
        "longitude": -53.0753
    },
    {
        "nome": "Liberdade",
        "latitude": -22.0275,
        "longitude": -44.3208
    },
    {
        "nome": "Licínio de Almeida",
        "latitude": -14.6842,
        "longitude": -42.5095
    },
    {
        "nome": "Lidianópolis",
        "latitude": -24.11,
        "longitude": -51.6506
    },
    {
        "nome": "Lima Campos",
        "latitude": -4.51837,
        "longitude": -44.4646
    },
    {
        "nome": "Lima Duarte",
        "latitude": -21.8386,
        "longitude": -43.7934
    },
    {
        "nome": "Limeira",
        "latitude": -22.566,
        "longitude": -47.397
    },
    {
        "nome": "Limeira do Oeste",
        "latitude": -19.5512,
        "longitude": -50.5815
    },
    {
        "nome": "Limoeiro",
        "latitude": -7.8726,
        "longitude": -35.4402
    },
    {
        "nome": "Limoeiro de Anadia",
        "latitude": -9.74098,
        "longitude": -36.5121
    },
    {
        "nome": "Limoeiro do Ajuru",
        "latitude": -1.8985,
        "longitude": -49.3903
    },
    {
        "nome": "Limoeiro do Norte",
        "latitude": -5.14392,
        "longitude": -38.0847
    },
    {
        "nome": "Lindoeste",
        "latitude": -25.2596,
        "longitude": -53.5733
    },
    {
        "nome": "Lindóia",
        "latitude": -22.5226,
        "longitude": -46.65
    },
    {
        "nome": "Lindóia do Sul",
        "latitude": -27.0545,
        "longitude": -52.069
    },
    {
        "nome": "Lindolfo Collor",
        "latitude": -29.5859,
        "longitude": -51.2141
    },
    {
        "nome": "Linha Nova",
        "latitude": -29.4679,
        "longitude": -51.2003
    },
    {
        "nome": "Linhares",
        "latitude": -19.3946,
        "longitude": -40.0643
    },
    {
        "nome": "Lins",
        "latitude": -21.6718,
        "longitude": -49.7526
    },
    {
        "nome": "Livramento",
        "latitude": -7.37113,
        "longitude": -36.9491
    },
    {
        "nome": "Livramento de Nossa Senhora",
        "latitude": -13.6369,
        "longitude": -41.8432
    },
    {
        "nome": "Lizarda",
        "latitude": -9.59002,
        "longitude": -46.6738
    },
    {
        "nome": "Loanda",
        "latitude": -22.9232,
        "longitude": -53.1362
    },
    {
        "nome": "Lobato",
        "latitude": -23.0058,
        "longitude": -51.9524
    },
    {
        "nome": "Logradouro",
        "latitude": -6.61191,
        "longitude": -35.4384
    },
    {
        "nome": "Londrina",
        "latitude": -23.304,
        "longitude": -51.1691
    },
    {
        "nome": "Lontra",
        "latitude": -15.9013,
        "longitude": -44.306
    },
    {
        "nome": "Lontras",
        "latitude": -27.1684,
        "longitude": -49.535
    },
    {
        "nome": "Lorena",
        "latitude": -22.7334,
        "longitude": -45.1197
    },
    {
        "nome": "Loreto",
        "latitude": -7.08111,
        "longitude": -45.1451
    },
    {
        "nome": "Lourdes",
        "latitude": -20.966,
        "longitude": -50.2263
    },
    {
        "nome": "Louveira",
        "latitude": -23.0856,
        "longitude": -46.9484
    },
    {
        "nome": "Lucas do Rio Verde",
        "latitude": -13.0588,
        "longitude": -55.9042
    },
    {
        "nome": "Lucélia",
        "latitude": -21.7182,
        "longitude": -51.0215
    },
    {
        "nome": "Lucena",
        "latitude": -6.90258,
        "longitude": -34.8748
    },
    {
        "nome": "Lucianópolis",
        "latitude": -22.4294,
        "longitude": -49.522
    },
    {
        "nome": "Luciara",
        "latitude": -11.2219,
        "longitude": -50.6676
    },
    {
        "nome": "Lucrécia",
        "latitude": -6.10525,
        "longitude": -37.8134
    },
    {
        "nome": "Luís Antônio",
        "latitude": -21.55,
        "longitude": -47.7801
    },
    {
        "nome": "Luís Correia",
        "latitude": -2.88438,
        "longitude": -41.6641
    },
    {
        "nome": "Luís Domingues",
        "latitude": -1.27492,
        "longitude": -45.867
    },
    {
        "nome": "Luís Eduardo Magalhães",
        "latitude": -12.0956,
        "longitude": -45.7866
    },
    {
        "nome": "Luís Gomes",
        "latitude": -6.40588,
        "longitude": -38.3899
    },
    {
        "nome": "Luisburgo",
        "latitude": -20.4468,
        "longitude": -42.0976
    },
    {
        "nome": "Luislândia",
        "latitude": -16.1095,
        "longitude": -44.5886
    },
    {
        "nome": "Luiz Alves",
        "latitude": -26.7151,
        "longitude": -48.9322
    },
    {
        "nome": "Luiziana",
        "latitude": -24.2853,
        "longitude": -52.269
    },
    {
        "nome": "Luiziânia",
        "latitude": -21.6737,
        "longitude": -50.3294
    },
    {
        "nome": "Luminárias",
        "latitude": -21.5145,
        "longitude": -44.9034
    },
    {
        "nome": "Lunardelli",
        "latitude": -24.0821,
        "longitude": -51.7368
    },
    {
        "nome": "Lupércio",
        "latitude": -22.4146,
        "longitude": -49.818
    },
    {
        "nome": "Lupionópolis",
        "latitude": -22.755,
        "longitude": -51.6601
    },
    {
        "nome": "Lutécia",
        "latitude": -22.3384,
        "longitude": -50.394
    },
    {
        "nome": "Luz",
        "latitude": -19.7911,
        "longitude": -45.6794
    },
    {
        "nome": "Luzerna",
        "latitude": -27.1304,
        "longitude": -51.4682
    },
    {
        "nome": "Luziânia",
        "latitude": -16.253,
        "longitude": -47.95
    },
    {
        "nome": "Luzilândia",
        "latitude": -3.4683,
        "longitude": -42.3718
    },
    {
        "nome": "Luzinópolis",
        "latitude": -6.17794,
        "longitude": -47.8582
    },
    {
        "nome": "Macaé",
        "latitude": -22.3768,
        "longitude": -41.7848
    },
    {
        "nome": "Macaíba",
        "latitude": -5.85229,
        "longitude": -35.3552
    },
    {
        "nome": "Macajuba",
        "latitude": -12.1326,
        "longitude": -40.3571
    },
    {
        "nome": "Maçambará",
        "latitude": -29.1445,
        "longitude": -56.0674
    },
    {
        "nome": "Macambira",
        "latitude": -10.6619,
        "longitude": -37.5413
    },
    {
        "nome": "Macapá",
        "latitude": 0.034934,
        "longitude": -51.0694
    },
    {
        "nome": "Macaparana",
        "latitude": -7.55564,
        "longitude": -35.4425
    },
    {
        "nome": "Macarani",
        "latitude": -15.5646,
        "longitude": -40.4209
    },
    {
        "nome": "Macatuba",
        "latitude": -22.5002,
        "longitude": -48.7102
    },
    {
        "nome": "Macau",
        "latitude": -5.10795,
        "longitude": -36.6318
    },
    {
        "nome": "Macaubal",
        "latitude": -20.8022,
        "longitude": -49.9687
    },
    {
        "nome": "Macaúbas",
        "latitude": -13.0186,
        "longitude": -42.6945
    },
    {
        "nome": "Macedônia",
        "latitude": -20.1444,
        "longitude": -50.1973
    },
    {
        "nome": "Maceió",
        "latitude": -9.66599,
        "longitude": -35.735
    },
    {
        "nome": "Machacalis",
        "latitude": -17.0723,
        "longitude": -40.7245
    },
    {
        "nome": "Machadinho",
        "latitude": -27.5667,
        "longitude": -51.6668
    },
    {
        "nome": "Machadinho D'Oeste",
        "latitude": -9.44363,
        "longitude": -61.9818
    },
    {
        "nome": "Machado",
        "latitude": -21.6778,
        "longitude": -45.9219
    },
    {
        "nome": "Machados",
        "latitude": -7.68827,
        "longitude": -35.5114
    },
    {
        "nome": "Macieira",
        "latitude": -26.8552,
        "longitude": -51.3705
    },
    {
        "nome": "Macuco",
        "latitude": -21.9813,
        "longitude": -42.2533
    },
    {
        "nome": "Macururé",
        "latitude": -9.16226,
        "longitude": -39.0518
    },
    {
        "nome": "Madalena",
        "latitude": -4.84601,
        "longitude": -39.5725
    },
    {
        "nome": "Madeiro",
        "latitude": -3.48624,
        "longitude": -42.4981
    },
    {
        "nome": "Madre de Deus",
        "latitude": -12.7446,
        "longitude": -38.6153
    },
    {
        "nome": "Madre de Deus de Minas",
        "latitude": -21.483,
        "longitude": -44.3287
    },
    {
        "nome": "Mãe d'Água",
        "latitude": -7.25201,
        "longitude": -37.4322
    },
    {
        "nome": "Mãe do Rio",
        "latitude": -2.05683,
        "longitude": -47.5601
    },
    {
        "nome": "Maetinga",
        "latitude": -14.6623,
        "longitude": -41.4915
    },
    {
        "nome": "Mafra",
        "latitude": -26.1159,
        "longitude": -49.8086
    },
    {
        "nome": "Magalhães Barata",
        "latitude": -0.803391,
        "longitude": -47.6014
    },
    {
        "nome": "Magalhães de Almeida",
        "latitude": -3.39232,
        "longitude": -42.2117
    },
    {
        "nome": "Magda",
        "latitude": -20.6445,
        "longitude": -50.2305
    },
    {
        "nome": "Magé",
        "latitude": -22.6632,
        "longitude": -43.0315
    },
    {
        "nome": "Maiquinique",
        "latitude": -15.624,
        "longitude": -40.2587
    },
    {
        "nome": "Mairi",
        "latitude": -11.7107,
        "longitude": -40.1437
    },
    {
        "nome": "Mairinque",
        "latitude": -23.5398,
        "longitude": -47.185
    },
    {
        "nome": "Mairiporã",
        "latitude": -23.3171,
        "longitude": -46.5897
    },
    {
        "nome": "Mairipotaba",
        "latitude": -17.2975,
        "longitude": -49.4898
    },
    {
        "nome": "Major Gercino",
        "latitude": -27.4192,
        "longitude": -48.9488
    },
    {
        "nome": "Major Isidoro",
        "latitude": -9.53009,
        "longitude": -36.992
    },
    {
        "nome": "Major Sales",
        "latitude": -6.39949,
        "longitude": -38.324
    },
    {
        "nome": "Major Vieira",
        "latitude": -26.3709,
        "longitude": -50.3266
    },
    {
        "nome": "Malacacheta",
        "latitude": -17.8456,
        "longitude": -42.0769
    },
    {
        "nome": "Malhada",
        "latitude": -14.3371,
        "longitude": -43.7686
    },
    {
        "nome": "Malhada de Pedras",
        "latitude": -14.3847,
        "longitude": -41.8842
    },
    {
        "nome": "Malhada dos Bois",
        "latitude": -10.3418,
        "longitude": -36.9252
    },
    {
        "nome": "Malhador",
        "latitude": -10.6649,
        "longitude": -37.3004
    },
    {
        "nome": "Mallet",
        "latitude": -25.8806,
        "longitude": -50.8173
    },
    {
        "nome": "Malta",
        "latitude": -6.89719,
        "longitude": -37.5221
    },
    {
        "nome": "Mamanguape",
        "latitude": -6.8337,
        "longitude": -35.1213
    },
    {
        "nome": "Mambaí",
        "latitude": -14.4823,
        "longitude": -46.1165
    },
    {
        "nome": "Mamborê",
        "latitude": -24.317,
        "longitude": -52.5271
    },
    {
        "nome": "Mamonas",
        "latitude": -15.0479,
        "longitude": -42.9469
    },
    {
        "nome": "Mampituba",
        "latitude": -29.2136,
        "longitude": -49.9311
    },
    {
        "nome": "Manacapuru",
        "latitude": -3.29066,
        "longitude": -60.6216
    },
    {
        "nome": "Manaíra",
        "latitude": -7.70331,
        "longitude": -38.1523
    },
    {
        "nome": "Manaquiri",
        "latitude": -3.44078,
        "longitude": -60.4612
    },
    {
        "nome": "Manari",
        "latitude": -8.9649,
        "longitude": -37.6313
    },
    {
        "nome": "Manaus",
        "latitude": -3.11866,
        "longitude": -60.0212
    },
    {
        "nome": "Mâncio Lima",
        "latitude": -7.61657,
        "longitude": -72.8997
    },
    {
        "nome": "Mandaguaçu",
        "latitude": -23.3458,
        "longitude": -52.0944
    },
    {
        "nome": "Mandaguari",
        "latitude": -23.5446,
        "longitude": -51.671
    },
    {
        "nome": "Mandirituba",
        "latitude": -25.777,
        "longitude": -49.3282
    },
    {
        "nome": "Manduri",
        "latitude": -23.0056,
        "longitude": -49.3202
    },
    {
        "nome": "Manfrinópolis",
        "latitude": -26.1441,
        "longitude": -53.3113
    },
    {
        "nome": "Manga",
        "latitude": -14.7529,
        "longitude": -43.9391
    },
    {
        "nome": "Mangaratiba",
        "latitude": -22.9594,
        "longitude": -44.0409
    },
    {
        "nome": "Mangueirinha",
        "latitude": -25.9421,
        "longitude": -52.1743
    },
    {
        "nome": "Manhuaçu",
        "latitude": -20.2572,
        "longitude": -42.028
    },
    {
        "nome": "Manhumirim",
        "latitude": -20.3591,
        "longitude": -41.9589
    },
    {
        "nome": "Manicoré",
        "latitude": -5.80462,
        "longitude": -61.2895
    },
    {
        "nome": "Manoel Emídio",
        "latitude": -8.01234,
        "longitude": -43.8755
    },
    {
        "nome": "Manoel Ribas",
        "latitude": -24.5144,
        "longitude": -51.6658
    },
    {
        "nome": "Manoel Urbano",
        "latitude": -8.83291,
        "longitude": -69.2679
    },
    {
        "nome": "Manoel Viana",
        "latitude": -29.5859,
        "longitude": -55.4841
    },
    {
        "nome": "Manoel Vitorino",
        "latitude": -14.1476,
        "longitude": -40.2399
    },
    {
        "nome": "Mansidão",
        "latitude": -10.7227,
        "longitude": -44.0428
    },
    {
        "nome": "Mantena",
        "latitude": -18.7761,
        "longitude": -40.9874
    },
    {
        "nome": "Mantenópolis",
        "latitude": -18.8594,
        "longitude": -41.124
    },
    {
        "nome": "Maquiné",
        "latitude": -29.6798,
        "longitude": -50.2079
    },
    {
        "nome": "Mar de Espanha",
        "latitude": -21.8707,
        "longitude": -43.0062
    },
    {
        "nome": "Mar Vermelho",
        "latitude": -9.44739,
        "longitude": -36.3881
    },
    {
        "nome": "Mara Rosa",
        "latitude": -14.0148,
        "longitude": -49.1777
    },
    {
        "nome": "Maraã",
        "latitude": -1.85313,
        "longitude": -65.573
    },
    {
        "nome": "Marabá",
        "latitude": -5.38075,
        "longitude": -49.1327
    },
    {
        "nome": "Marabá Paulista",
        "latitude": -22.1068,
        "longitude": -51.9617
    },
    {
        "nome": "Maracaçumé",
        "latitude": -2.04918,
        "longitude": -45.9587
    },
    {
        "nome": "Maracaí",
        "latitude": -22.6149,
        "longitude": -50.6713
    },
    {
        "nome": "Maracajá",
        "latitude": -28.8463,
        "longitude": -49.4605
    },
    {
        "nome": "Maracaju",
        "latitude": -21.6105,
        "longitude": -55.1678
    },
    {
        "nome": "Maracanã",
        "latitude": -0.778899,
        "longitude": -47.452
    },
    {
        "nome": "Maracanaú",
        "latitude": -3.86699,
        "longitude": -38.6259
    },
    {
        "nome": "Maracás",
        "latitude": -13.4355,
        "longitude": -40.4323
    },
    {
        "nome": "Maragogi",
        "latitude": -9.00744,
        "longitude": -35.2267
    },
    {
        "nome": "Maragogipe",
        "latitude": -12.776,
        "longitude": -38.9175
    },
    {
        "nome": "Maraial",
        "latitude": -8.79062,
        "longitude": -35.8266
    },
    {
        "nome": "Marajá do Sena",
        "latitude": -4.62806,
        "longitude": -45.4531
    },
    {
        "nome": "Maranguape",
        "latitude": -3.89143,
        "longitude": -38.6829
    },
    {
        "nome": "Maranhãozinho",
        "latitude": -2.24078,
        "longitude": -45.8507
    },
    {
        "nome": "Marapanim",
        "latitude": -0.714702,
        "longitude": -47.7034
    },
    {
        "nome": "Marapoama",
        "latitude": -21.2587,
        "longitude": -49.13
    },
    {
        "nome": "Maratá",
        "latitude": -29.5457,
        "longitude": -51.5573
    },
    {
        "nome": "Marataízes",
        "latitude": -21.0398,
        "longitude": -40.8384
    },
    {
        "nome": "Marau",
        "latitude": -28.4498,
        "longitude": -52.1986
    },
    {
        "nome": "Maraú",
        "latitude": -14.1035,
        "longitude": -39.0137
    },
    {
        "nome": "Maravilha",
        "latitude": -9.23045,
        "longitude": -37.3524
    },
    {
        "nome": "Maravilha",
        "latitude": -26.7665,
        "longitude": -53.1737
    },
    {
        "nome": "Maravilhas",
        "latitude": -19.5076,
        "longitude": -44.6779
    },
    {
        "nome": "Marcação",
        "latitude": -6.76535,
        "longitude": -35.0087
    },
    {
        "nome": "Marcelândia",
        "latitude": -11.0463,
        "longitude": -54.4377
    },
    {
        "nome": "Marcelino Ramos",
        "latitude": -27.4676,
        "longitude": -51.9095
    },
    {
        "nome": "Marcelino Vieira",
        "latitude": -6.2846,
        "longitude": -38.1642
    },
    {
        "nome": "Marcionílio Souza",
        "latitude": -13.0064,
        "longitude": -40.5295
    },
    {
        "nome": "Marco",
        "latitude": -3.1285,
        "longitude": -40.1582
    },
    {
        "nome": "Marcolândia",
        "latitude": -7.44169,
        "longitude": -40.6602
    },
    {
        "nome": "Marcos Parente",
        "latitude": -7.11565,
        "longitude": -43.8926
    },
    {
        "nome": "Marechal Cândido Rondon",
        "latitude": -24.557,
        "longitude": -54.0571
    },
    {
        "nome": "Marechal Deodoro",
        "latitude": -9.70971,
        "longitude": -35.8967
    },
    {
        "nome": "Marechal Floriano",
        "latitude": -20.4159,
        "longitude": -40.67
    },
    {
        "nome": "Marechal Thaumaturgo",
        "latitude": -8.93898,
        "longitude": -72.7997
    },
    {
        "nome": "Marema",
        "latitude": -26.8024,
        "longitude": -52.6264
    },
    {
        "nome": "Mari",
        "latitude": -7.05942,
        "longitude": -35.318
    },
    {
        "nome": "Maria da Fé",
        "latitude": -22.3044,
        "longitude": -45.3773
    },
    {
        "nome": "Maria Helena",
        "latitude": -23.6158,
        "longitude": -53.2053
    },
    {
        "nome": "Marialva",
        "latitude": -23.4843,
        "longitude": -51.7928
    },
    {
        "nome": "Mariana",
        "latitude": -20.3765,
        "longitude": -43.414
    },
    {
        "nome": "Mariana Pimentel",
        "latitude": -30.353,
        "longitude": -51.5803
    },
    {
        "nome": "Mariano Moro",
        "latitude": -27.3568,
        "longitude": -52.1467
    },
    {
        "nome": "Marianópolis do Tocantins",
        "latitude": -9.79377,
        "longitude": -49.6553
    },
    {
        "nome": "Mariápolis",
        "latitude": -21.7959,
        "longitude": -51.1824
    },
    {
        "nome": "Maribondo",
        "latitude": -9.58353,
        "longitude": -36.3045
    },
    {
        "nome": "Maricá",
        "latitude": -22.9354,
        "longitude": -42.8246
    },
    {
        "nome": "Marilac",
        "latitude": -18.5079,
        "longitude": -42.0822
    },
    {
        "nome": "Marilândia",
        "latitude": -19.4114,
        "longitude": -40.5456
    },
    {
        "nome": "Marilândia do Sul",
        "latitude": -23.7425,
        "longitude": -51.3137
    },
    {
        "nome": "Marilena",
        "latitude": -22.7336,
        "longitude": -53.0402
    },
    {
        "nome": "Marília",
        "latitude": -22.2171,
        "longitude": -49.9501
    },
    {
        "nome": "Mariluz",
        "latitude": -24.0089,
        "longitude": -53.1432
    },
    {
        "nome": "Maringá",
        "latitude": -23.4205,
        "longitude": -51.9333
    },
    {
        "nome": "Marinópolis",
        "latitude": -20.4389,
        "longitude": -50.8254
    },
    {
        "nome": "Mário Campos",
        "latitude": -20.0582,
        "longitude": -44.1883
    },
    {
        "nome": "Mariópolis",
        "latitude": -26.355,
        "longitude": -52.5532
    },
    {
        "nome": "Maripá",
        "latitude": -24.42,
        "longitude": -53.8286
    },
    {
        "nome": "Maripá de Minas",
        "latitude": -21.6979,
        "longitude": -42.9546
    },
    {
        "nome": "Marituba",
        "latitude": -1.36002,
        "longitude": -48.3421
    },
    {
        "nome": "Marizópolis",
        "latitude": -6.82748,
        "longitude": -38.3528
    },
    {
        "nome": "Marliéria",
        "latitude": -19.7096,
        "longitude": -42.7327
    },
    {
        "nome": "Marmeleiro",
        "latitude": -26.1472,
        "longitude": -53.0267
    },
    {
        "nome": "Marmelópolis",
        "latitude": -22.447,
        "longitude": -45.1645
    },
    {
        "nome": "Marques de Souza",
        "latitude": -29.3311,
        "longitude": -52.0973
    },
    {
        "nome": "Marquinho",
        "latitude": -25.112,
        "longitude": -52.2497
    },
    {
        "nome": "Martinho Campos",
        "latitude": -19.3306,
        "longitude": -45.2434
    },
    {
        "nome": "Martinópole",
        "latitude": -3.2252,
        "longitude": -40.6896
    },
    {
        "nome": "Martinópolis",
        "latitude": -22.1462,
        "longitude": -51.1709
    },
    {
        "nome": "Martins",
        "latitude": -6.08279,
        "longitude": -37.908
    },
    {
        "nome": "Martins Soares",
        "latitude": -20.2546,
        "longitude": -41.8786
    },
    {
        "nome": "Maruim",
        "latitude": -10.7308,
        "longitude": -37.0856
    },
    {
        "nome": "Marumbi",
        "latitude": -23.7058,
        "longitude": -51.6404
    },
    {
        "nome": "Marzagão",
        "latitude": -17.983,
        "longitude": -48.6415
    },
    {
        "nome": "Mascote",
        "latitude": -15.5542,
        "longitude": -39.3016
    },
    {
        "nome": "Massapê",
        "latitude": -3.52364,
        "longitude": -40.3423
    },
    {
        "nome": "Massapê do Piauí",
        "latitude": -7.47469,
        "longitude": -41.1103
    },
    {
        "nome": "Massaranduba",
        "latitude": -7.18995,
        "longitude": -35.7848
    },
    {
        "nome": "Massaranduba",
        "latitude": -26.6109,
        "longitude": -49.0054
    },
    {
        "nome": "Mata",
        "latitude": -29.5649,
        "longitude": -54.4641
    },
    {
        "nome": "Mata de São João",
        "latitude": -12.5307,
        "longitude": -38.3009
    },
    {
        "nome": "Mata Grande",
        "latitude": -9.11824,
        "longitude": -37.7323
    },
    {
        "nome": "Mata Roma",
        "latitude": -3.62035,
        "longitude": -43.1112
    },
    {
        "nome": "Mata Verde",
        "latitude": -15.6869,
        "longitude": -40.7366
    },
    {
        "nome": "Matão",
        "latitude": -21.6025,
        "longitude": -48.364
    },
    {
        "nome": "Mataraca",
        "latitude": -6.59673,
        "longitude": -35.0531
    },
    {
        "nome": "Mateiros",
        "latitude": -10.5464,
        "longitude": -46.4168
    },
    {
        "nome": "Matelândia",
        "latitude": -25.2496,
        "longitude": -53.9935
    },
    {
        "nome": "Materlândia",
        "latitude": -18.4699,
        "longitude": -43.0579
    },
    {
        "nome": "Mateus Leme",
        "latitude": -19.9794,
        "longitude": -44.4318
    },
    {
        "nome": "Mathias Lobato",
        "latitude": -18.59,
        "longitude": -41.9166
    },
    {
        "nome": "Matias Barbosa",
        "latitude": -21.869,
        "longitude": -43.3135
    },
    {
        "nome": "Matias Cardoso",
        "latitude": -14.8563,
        "longitude": -43.9146
    },
    {
        "nome": "Matias Olímpio",
        "latitude": -3.71492,
        "longitude": -42.5507
    },
    {
        "nome": "Matina",
        "latitude": -13.9109,
        "longitude": -42.8439
    },
    {
        "nome": "Matinha",
        "latitude": -3.09849,
        "longitude": -45.035
    },
    {
        "nome": "Matinhas",
        "latitude": -7.12486,
        "longitude": -35.7669
    },
    {
        "nome": "Matinhos",
        "latitude": -25.8237,
        "longitude": -48.549
    },
    {
        "nome": "Matipó",
        "latitude": -20.2873,
        "longitude": -42.3401
    },
    {
        "nome": "Mato Castelhano",
        "latitude": -28.28,
        "longitude": -52.1932
    },
    {
        "nome": "Mato Grosso",
        "latitude": -6.54018,
        "longitude": -37.7279
    },
    {
        "nome": "Mato Leitão",
        "latitude": -29.5285,
        "longitude": -52.1278
    },
    {
        "nome": "Mato Queimado",
        "latitude": -28.252,
        "longitude": -54.6159
    },
    {
        "nome": "Mato Rico",
        "latitude": -24.6995,
        "longitude": -52.1454
    },
    {
        "nome": "Mato Verde",
        "latitude": -15.3944,
        "longitude": -42.86
    },
    {
        "nome": "Matões",
        "latitude": -5.51359,
        "longitude": -43.2018
    },
    {
        "nome": "Matões do Norte",
        "latitude": -3.6244,
        "longitude": -44.5468
    },
    {
        "nome": "Matos Costa",
        "latitude": -26.4709,
        "longitude": -51.1501
    },
    {
        "nome": "Matozinhos",
        "latitude": -19.5543,
        "longitude": -44.0868
    },
    {
        "nome": "Matrinchã",
        "latitude": -15.4342,
        "longitude": -50.7456
    },
    {
        "nome": "Matriz de Camaragibe",
        "latitude": -9.15437,
        "longitude": -35.5243
    },
    {
        "nome": "Matupá",
        "latitude": -10.1821,
        "longitude": -54.9467
    },
    {
        "nome": "Maturéia",
        "latitude": -7.26188,
        "longitude": -37.351
    },
    {
        "nome": "Matutina",
        "latitude": -19.2179,
        "longitude": -45.9664
    },
    {
        "nome": "Mauá",
        "latitude": -23.6677,
        "longitude": -46.4613
    },
    {
        "nome": "Mauá da Serra",
        "latitude": -23.8988,
        "longitude": -51.2277
    },
    {
        "nome": "Maués",
        "latitude": -3.39289,
        "longitude": -57.7067
    },
    {
        "nome": "Maurilândia",
        "latitude": -17.9719,
        "longitude": -50.3388
    },
    {
        "nome": "Maurilândia do Tocantins",
        "latitude": -5.95169,
        "longitude": -47.5125
    },
    {
        "nome": "Mauriti",
        "latitude": -7.38597,
        "longitude": -38.7708
    },
    {
        "nome": "Maxaranguape",
        "latitude": -5.52181,
        "longitude": -35.2631
    },
    {
        "nome": "Maximiliano de Almeida",
        "latitude": -27.6325,
        "longitude": -51.802
    },
    {
        "nome": "Mazagão",
        "latitude": -0.11336,
        "longitude": -51.2891
    },
    {
        "nome": "Medeiros",
        "latitude": -19.9865,
        "longitude": -46.2181
    },
    {
        "nome": "Medeiros Neto",
        "latitude": -17.3707,
        "longitude": -40.2238
    },
    {
        "nome": "Medianeira",
        "latitude": -25.2977,
        "longitude": -54.0943
    },
    {
        "nome": "Medicilândia",
        "latitude": -3.44637,
        "longitude": -52.8875
    },
    {
        "nome": "Medina",
        "latitude": -16.2245,
        "longitude": -41.4728
    },
    {
        "nome": "Meleiro",
        "latitude": -28.8244,
        "longitude": -49.6378
    },
    {
        "nome": "Melgaço",
        "latitude": -1.8032,
        "longitude": -50.7149
    },
    {
        "nome": "Mendes",
        "latitude": -22.5245,
        "longitude": -43.7312
    },
    {
        "nome": "Mendes Pimentel",
        "latitude": -18.6631,
        "longitude": -41.4052
    },
    {
        "nome": "Mendonça",
        "latitude": -21.1757,
        "longitude": -49.5791
    },
    {
        "nome": "Mercedes",
        "latitude": -24.4538,
        "longitude": -54.1618
    },
    {
        "nome": "Mercês",
        "latitude": -21.1976,
        "longitude": -43.3337
    },
    {
        "nome": "Meridiano",
        "latitude": -20.3579,
        "longitude": -50.1811
    },
    {
        "nome": "Meruoca",
        "latitude": -3.53974,
        "longitude": -40.4531
    },
    {
        "nome": "Mesópolis",
        "latitude": -19.9684,
        "longitude": -50.6326
    },
    {
        "nome": "Mesquita",
        "latitude": -22.8028,
        "longitude": -43.4601
    },
    {
        "nome": "Mesquita",
        "latitude": -19.224,
        "longitude": -42.6079
    },
    {
        "nome": "Messias",
        "latitude": -9.39384,
        "longitude": -35.8392
    },
    {
        "nome": "Messias Targino",
        "latitude": -6.07194,
        "longitude": -37.5158
    },
    {
        "nome": "Miguel Alves",
        "latitude": -4.16857,
        "longitude": -42.8963
    },
    {
        "nome": "Miguel Calmon",
        "latitude": -11.4299,
        "longitude": -40.6031
    },
    {
        "nome": "Miguel Leão",
        "latitude": -5.68077,
        "longitude": -42.7436
    },
    {
        "nome": "Miguel Pereira",
        "latitude": -22.4572,
        "longitude": -43.4803
    },
    {
        "nome": "Miguelópolis",
        "latitude": -20.1796,
        "longitude": -48.031
    },
    {
        "nome": "Milagres",
        "latitude": -7.29749,
        "longitude": -38.9378
    },
    {
        "nome": "Milagres",
        "latitude": -12.8646,
        "longitude": -39.8611
    },
    {
        "nome": "Milagres do Maranhão",
        "latitude": -3.57443,
        "longitude": -42.6131
    },
    {
        "nome": "Milhã",
        "latitude": -5.67252,
        "longitude": -39.1875
    },
    {
        "nome": "Milton Brandão",
        "latitude": -4.68295,
        "longitude": -41.4173
    },
    {
        "nome": "Mimoso de Goiás",
        "latitude": -15.0515,
        "longitude": -48.1611
    },
    {
        "nome": "Mimoso do Sul",
        "latitude": -21.0628,
        "longitude": -41.3615
    },
    {
        "nome": "Minaçu",
        "latitude": -13.5304,
        "longitude": -48.2206
    },
    {
        "nome": "Minador do Negrão",
        "latitude": -9.31236,
        "longitude": -36.8696
    },
    {
        "nome": "Minas do Leão",
        "latitude": -30.1346,
        "longitude": -52.0423
    },
    {
        "nome": "Minas Novas",
        "latitude": -17.2156,
        "longitude": -42.5884
    },
    {
        "nome": "Minduri",
        "latitude": -21.6797,
        "longitude": -44.6051
    },
    {
        "nome": "Mineiros",
        "latitude": -17.5654,
        "longitude": -52.5537
    },
    {
        "nome": "Mineiros do Tietê",
        "latitude": -22.412,
        "longitude": -48.451
    },
    {
        "nome": "Ministro Andreazza",
        "latitude": -11.196,
        "longitude": -61.5174
    },
    {
        "nome": "Mira Estrela",
        "latitude": -19.9789,
        "longitude": -50.139
    },
    {
        "nome": "Mirabela",
        "latitude": -16.256,
        "longitude": -44.1602
    },
    {
        "nome": "Miracatu",
        "latitude": -24.2766,
        "longitude": -47.4625
    },
    {
        "nome": "Miracema",
        "latitude": -21.4148,
        "longitude": -42.1938
    },
    {
        "nome": "Miracema do Tocantins",
        "latitude": -9.56556,
        "longitude": -48.393
    },
    {
        "nome": "Mirador",
        "latitude": -6.37454,
        "longitude": -44.3683
    },
    {
        "nome": "Mirador",
        "latitude": -23.255,
        "longitude": -52.7761
    },
    {
        "nome": "Miradouro",
        "latitude": -20.8899,
        "longitude": -42.3458
    },
    {
        "nome": "Miraguaí",
        "latitude": -27.497,
        "longitude": -53.6891
    },
    {
        "nome": "Miraí",
        "latitude": -21.2021,
        "longitude": -42.6122
    },
    {
        "nome": "Miraíma",
        "latitude": -3.56867,
        "longitude": -39.9663
    },
    {
        "nome": "Miranda",
        "latitude": -20.2355,
        "longitude": -56.3746
    },
    {
        "nome": "Miranda do Norte",
        "latitude": -3.56313,
        "longitude": -44.5814
    },
    {
        "nome": "Mirandiba",
        "latitude": -8.12113,
        "longitude": -38.7388
    },
    {
        "nome": "Mirandópolis",
        "latitude": -21.1313,
        "longitude": -51.1035
    },
    {
        "nome": "Mirangaba",
        "latitude": -10.961,
        "longitude": -40.574
    },
    {
        "nome": "Miranorte",
        "latitude": -9.52907,
        "longitude": -48.5922
    },
    {
        "nome": "Mirante",
        "latitude": -14.2385,
        "longitude": -40.7718
    },
    {
        "nome": "Mirante da Serra",
        "latitude": -11.029,
        "longitude": -62.6696
    },
    {
        "nome": "Mirante do Paranapanema",
        "latitude": -22.2904,
        "longitude": -51.9084
    },
    {
        "nome": "Miraselva",
        "latitude": -22.9657,
        "longitude": -51.4846
    },
    {
        "nome": "Mirassol",
        "latitude": -20.8169,
        "longitude": -49.5206
    },
    {
        "nome": "Mirassol d'Oeste",
        "latitude": -15.6759,
        "longitude": -58.0951
    },
    {
        "nome": "Mirassolândia",
        "latitude": -20.6179,
        "longitude": -49.4617
    },
    {
        "nome": "Miravânia",
        "latitude": -14.7348,
        "longitude": -44.4092
    },
    {
        "nome": "Mirim Doce",
        "latitude": -27.197,
        "longitude": -50.0786
    },
    {
        "nome": "Mirinzal",
        "latitude": -2.07094,
        "longitude": -44.7787
    },
    {
        "nome": "Missal",
        "latitude": -25.0919,
        "longitude": -54.2477
    },
    {
        "nome": "Missão Velha",
        "latitude": -7.23522,
        "longitude": -39.143
    },
    {
        "nome": "Mocajuba",
        "latitude": -2.5831,
        "longitude": -49.5042
    },
    {
        "nome": "Mococa",
        "latitude": -21.4647,
        "longitude": -47.0024
    },
    {
        "nome": "Modelo",
        "latitude": -26.7729,
        "longitude": -53.04
    },
    {
        "nome": "Moeda",
        "latitude": -20.3399,
        "longitude": -44.0509
    },
    {
        "nome": "Moema",
        "latitude": -19.8387,
        "longitude": -45.4127
    },
    {
        "nome": "Mogeiro",
        "latitude": -7.28517,
        "longitude": -35.4832
    },
    {
        "nome": "Mogi das Cruzes",
        "latitude": -23.5208,
        "longitude": -46.1854
    },
    {
        "nome": "Mogi Guaçu",
        "latitude": -22.3675,
        "longitude": -46.9428
    },
    {
        "nome": "Mogi Mirim",
        "latitude": -22.4332,
        "longitude": -46.9532
    },
    {
        "nome": "Moiporá",
        "latitude": -16.5434,
        "longitude": -50.739
    },
    {
        "nome": "Moita Bonita",
        "latitude": -10.5769,
        "longitude": -37.3512
    },
    {
        "nome": "Moju",
        "latitude": -1.88993,
        "longitude": -48.7668
    },
    {
        "nome": "Mojuí dos Campos",
        "latitude": -2.6822,
        "longitude": -54.6425
    },
    {
        "nome": "Mombaça",
        "latitude": -5.73844,
        "longitude": -39.63
    },
    {
        "nome": "Mombuca",
        "latitude": -22.9285,
        "longitude": -47.559
    },
    {
        "nome": "Monção",
        "latitude": -3.48125,
        "longitude": -45.2496
    },
    {
        "nome": "Monções",
        "latitude": -20.8509,
        "longitude": -50.0975
    },
    {
        "nome": "Mondaí",
        "latitude": -27.1008,
        "longitude": -53.4032
    },
    {
        "nome": "Mongaguá",
        "latitude": -24.0809,
        "longitude": -46.6265
    },
    {
        "nome": "Monjolos",
        "latitude": -18.3245,
        "longitude": -44.118
    },
    {
        "nome": "Monsenhor Gil",
        "latitude": -5.562,
        "longitude": -42.6075
    },
    {
        "nome": "Monsenhor Hipólito",
        "latitude": -6.99275,
        "longitude": -41.026
    },
    {
        "nome": "Monsenhor Paulo",
        "latitude": -21.7579,
        "longitude": -45.5391
    },
    {
        "nome": "Monsenhor Tabosa",
        "latitude": -4.79102,
        "longitude": -40.0646
    },
    {
        "nome": "Montadas",
        "latitude": -7.08848,
        "longitude": -35.9592
    },
    {
        "nome": "Montalvânia",
        "latitude": -14.4197,
        "longitude": -44.3719
    },
    {
        "nome": "Montanha",
        "latitude": -18.1303,
        "longitude": -40.3668
    },
    {
        "nome": "Montanhas",
        "latitude": -6.48522,
        "longitude": -35.2842
    },
    {
        "nome": "Montauri",
        "latitude": -28.6462,
        "longitude": -52.0767
    },
    {
        "nome": "Monte Alegre",
        "latitude": -1.99768,
        "longitude": -54.0724
    },
    {
        "nome": "Monte Alegre",
        "latitude": -6.07063,
        "longitude": -35.3253
    },
    {
        "nome": "Monte Alegre de Goiás",
        "latitude": -13.2552,
        "longitude": -46.8928
    },
    {
        "nome": "Monte Alegre de Minas",
        "latitude": -18.869,
        "longitude": -48.881
    },
    {
        "nome": "Monte Alegre de Sergipe",
        "latitude": -10.0256,
        "longitude": -37.5616
    },
    {
        "nome": "Monte Alegre do Piauí",
        "latitude": -9.75364,
        "longitude": -45.3037
    },
    {
        "nome": "Monte Alegre do Sul",
        "latitude": -22.6817,
        "longitude": -46.681
    },
    {
        "nome": "Monte Alegre dos Campos",
        "latitude": -28.6805,
        "longitude": -50.7834
    },
    {
        "nome": "Monte Alto",
        "latitude": -21.2655,
        "longitude": -48.4971
    },
    {
        "nome": "Monte Aprazível",
        "latitude": -20.768,
        "longitude": -49.7184
    },
    {
        "nome": "Monte Azul",
        "latitude": -15.1514,
        "longitude": -42.8718
    },
    {
        "nome": "Monte Azul Paulista",
        "latitude": -20.9065,
        "longitude": -48.6387
    },
    {
        "nome": "Monte Belo",
        "latitude": -21.3271,
        "longitude": -46.3635
    },
    {
        "nome": "Monte Belo do Sul",
        "latitude": -29.1607,
        "longitude": -51.6333
    },
    {
        "nome": "Monte Carlo",
        "latitude": -27.2239,
        "longitude": -50.9808
    },
    {
        "nome": "Monte Carmelo",
        "latitude": -18.7302,
        "longitude": -47.4912
    },
    {
        "nome": "Monte Castelo",
        "latitude": -26.461,
        "longitude": -50.2327
    },
    {
        "nome": "Monte Castelo",
        "latitude": -21.2981,
        "longitude": -51.5679
    },
    {
        "nome": "Monte das Gameleiras",
        "latitude": -6.43698,
        "longitude": -35.7831
    },
    {
        "nome": "Monte do Carmo",
        "latitude": -10.7611,
        "longitude": -48.1114
    },
    {
        "nome": "Monte Formoso",
        "latitude": -16.8691,
        "longitude": -41.2473
    },
    {
        "nome": "Monte Horebe",
        "latitude": -7.20402,
        "longitude": -38.5838
    },
    {
        "nome": "Monte Mor",
        "latitude": -22.945,
        "longitude": -47.3122
    },
    {
        "nome": "Monte Negro",
        "latitude": -10.2458,
        "longitude": -63.29
    },
    {
        "nome": "Monte Santo",
        "latitude": -10.4374,
        "longitude": -39.3321
    },
    {
        "nome": "Monte Santo de Minas",
        "latitude": -21.1873,
        "longitude": -46.9753
    },
    {
        "nome": "Monte Santo do Tocantins",
        "latitude": -10.0075,
        "longitude": -48.9941
    },
    {
        "nome": "Monte Sião",
        "latitude": -22.4335,
        "longitude": -46.573
    },
    {
        "nome": "Monteiro",
        "latitude": -7.88363,
        "longitude": -37.1184
    },
    {
        "nome": "Monteiro Lobato",
        "latitude": -22.9544,
        "longitude": -45.8407
    },
    {
        "nome": "Monteirópolis",
        "latitude": -9.60357,
        "longitude": -37.2505
    },
    {
        "nome": "Montenegro",
        "latitude": -29.6824,
        "longitude": -51.4679
    },
    {
        "nome": "Montes Altos",
        "latitude": -5.83067,
        "longitude": -47.0673
    },
    {
        "nome": "Montes Claros",
        "latitude": -16.7282,
        "longitude": -43.8578
    },
    {
        "nome": "Montes Claros de Goiás",
        "latitude": -16.0059,
        "longitude": -51.3979
    },
    {
        "nome": "Montezuma",
        "latitude": -15.1702,
        "longitude": -42.4941
    },
    {
        "nome": "Montividiu",
        "latitude": -17.4439,
        "longitude": -51.1728
    },
    {
        "nome": "Montividiu do Norte",
        "latitude": -13.3485,
        "longitude": -48.6853
    },
    {
        "nome": "Morada Nova",
        "latitude": -5.09736,
        "longitude": -38.3702
    },
    {
        "nome": "Morada Nova de Minas",
        "latitude": -18.5998,
        "longitude": -45.3584
    },
    {
        "nome": "Moraújo",
        "latitude": -3.46311,
        "longitude": -40.6776
    },
    {
        "nome": "Moreilândia",
        "latitude": -7.61931,
        "longitude": -39.546
    },
    {
        "nome": "Moreira Sales",
        "latitude": -24.0509,
        "longitude": -53.0102
    },
    {
        "nome": "Moreno",
        "latitude": -8.10871,
        "longitude": -35.0835
    },
    {
        "nome": "Mormaço",
        "latitude": -28.6968,
        "longitude": -52.6999
    },
    {
        "nome": "Morpará",
        "latitude": -11.5569,
        "longitude": -43.2766
    },
    {
        "nome": "Morretes",
        "latitude": -25.4744,
        "longitude": -48.8345
    },
    {
        "nome": "Morrinhos",
        "latitude": -17.7334,
        "longitude": -49.1059
    },
    {
        "nome": "Morrinhos",
        "latitude": -3.23426,
        "longitude": -40.1233
    },
    {
        "nome": "Morrinhos do Sul",
        "latitude": -29.3578,
        "longitude": -49.9328
    },
    {
        "nome": "Morro Agudo",
        "latitude": -20.7288,
        "longitude": -48.0581
    },
    {
        "nome": "Morro Agudo de Goiás",
        "latitude": -15.3184,
        "longitude": -50.0553
    },
    {
        "nome": "Morro Cabeça no Tempo",
        "latitude": -9.71891,
        "longitude": -43.9072
    },
    {
        "nome": "Morro da Fumaça",
        "latitude": -28.6511,
        "longitude": -49.2169
    },
    {
        "nome": "Morro da Garça",
        "latitude": -18.5356,
        "longitude": -44.601
    },
    {
        "nome": "Morro do Chapéu",
        "latitude": -11.5488,
        "longitude": -41.1565
    },
    {
        "nome": "Morro do Chapéu do Piauí",
        "latitude": -3.73337,
        "longitude": -42.3024
    },
    {
        "nome": "Morro do Pilar",
        "latitude": -19.2236,
        "longitude": -43.3795
    },
    {
        "nome": "Morro Grande",
        "latitude": -28.8006,
        "longitude": -49.7214
    },
    {
        "nome": "Morro Redondo",
        "latitude": -31.5887,
        "longitude": -52.6261
    },
    {
        "nome": "Morro Reuter",
        "latitude": -29.5379,
        "longitude": -51.0811
    },
    {
        "nome": "Morros",
        "latitude": -2.85379,
        "longitude": -44.0357
    },
    {
        "nome": "Mortugaba",
        "latitude": -15.0225,
        "longitude": -42.3727
    },
    {
        "nome": "Morungaba",
        "latitude": -22.8811,
        "longitude": -46.7896
    },
    {
        "nome": "Mossâmedes",
        "latitude": -16.124,
        "longitude": -50.2136
    },
    {
        "nome": "Mossoró",
        "latitude": -5.18374,
        "longitude": -37.3474
    },
    {
        "nome": "Mostardas",
        "latitude": -31.1054,
        "longitude": -50.9167
    },
    {
        "nome": "Motuca",
        "latitude": -21.5103,
        "longitude": -48.1538
    },
    {
        "nome": "Mozarlândia",
        "latitude": -14.7457,
        "longitude": -50.5713
    },
    {
        "nome": "Muaná",
        "latitude": -1.53936,
        "longitude": -49.2224
    },
    {
        "nome": "Mucajaí",
        "latitude": 2.43998,
        "longitude": -60.9096
    },
    {
        "nome": "Mucambo",
        "latitude": -3.90271,
        "longitude": -40.7452
    },
    {
        "nome": "Mucugê",
        "latitude": -13.0053,
        "longitude": -41.3703
    },
    {
        "nome": "Muçum",
        "latitude": -29.163,
        "longitude": -51.8714
    },
    {
        "nome": "Mucuri",
        "latitude": -18.0754,
        "longitude": -39.5565
    },
    {
        "nome": "Mucurici",
        "latitude": -18.0965,
        "longitude": -40.52
    },
    {
        "nome": "Muitos Capões",
        "latitude": -28.3132,
        "longitude": -51.1836
    },
    {
        "nome": "Muliterno",
        "latitude": -28.3253,
        "longitude": -51.7697
    },
    {
        "nome": "Mulungu",
        "latitude": -7.02525,
        "longitude": -35.46
    },
    {
        "nome": "Mulungu",
        "latitude": -4.30294,
        "longitude": -38.9951
    },
    {
        "nome": "Mulungu do Morro",
        "latitude": -11.9648,
        "longitude": -41.6374
    },
    {
        "nome": "Mundo Novo",
        "latitude": -11.8541,
        "longitude": -40.4714
    },
    {
        "nome": "Mundo Novo",
        "latitude": -23.9355,
        "longitude": -54.281
    },
    {
        "nome": "Mundo Novo",
        "latitude": -13.7729,
        "longitude": -50.2814
    },
    {
        "nome": "Munhoz",
        "latitude": -22.6092,
        "longitude": -46.362
    },
    {
        "nome": "Munhoz de Melo",
        "latitude": -23.1487,
        "longitude": -51.7737
    },
    {
        "nome": "Muniz Ferreira",
        "latitude": -13.0092,
        "longitude": -39.1092
    },
    {
        "nome": "Muniz Freire",
        "latitude": -20.4652,
        "longitude": -41.4156
    },
    {
        "nome": "Muquém de São Francisco",
        "latitude": -12.065,
        "longitude": -43.5497
    },
    {
        "nome": "Muqui",
        "latitude": -20.9509,
        "longitude": -41.346
    },
    {
        "nome": "Muriaé",
        "latitude": -21.13,
        "longitude": -42.3693
    },
    {
        "nome": "Muribeca",
        "latitude": -10.4271,
        "longitude": -36.9588
    },
    {
        "nome": "Murici",
        "latitude": -9.30682,
        "longitude": -35.9428
    },
    {
        "nome": "Murici dos Portelas",
        "latitude": -3.319,
        "longitude": -42.094
    },
    {
        "nome": "Muricilândia",
        "latitude": -7.14669,
        "longitude": -48.6091
    },
    {
        "nome": "Muritiba",
        "latitude": -12.6329,
        "longitude": -38.9921
    },
    {
        "nome": "Murutinga do Sul",
        "latitude": -20.9908,
        "longitude": -51.2774
    },
    {
        "nome": "Mutuípe",
        "latitude": -13.2284,
        "longitude": -39.5044
    },
    {
        "nome": "Mutum",
        "latitude": -19.8121,
        "longitude": -41.4407
    },
    {
        "nome": "Mutunópolis",
        "latitude": -13.7303,
        "longitude": -49.2745
    },
    {
        "nome": "Muzambinho",
        "latitude": -21.3692,
        "longitude": -46.5213
    },
    {
        "nome": "Nacip Raydan",
        "latitude": -18.4544,
        "longitude": -42.2481
    },
    {
        "nome": "Nantes",
        "latitude": -22.6156,
        "longitude": -51.24
    },
    {
        "nome": "Nanuque",
        "latitude": -17.8481,
        "longitude": -40.3533
    },
    {
        "nome": "Não-Me-Toque",
        "latitude": -28.4548,
        "longitude": -52.8182
    },
    {
        "nome": "Naque",
        "latitude": -19.2291,
        "longitude": -42.3312
    },
    {
        "nome": "Narandiba",
        "latitude": -22.4057,
        "longitude": -51.5274
    },
    {
        "nome": "Natal",
        "latitude": -5.79357,
        "longitude": -35.1986
    },
    {
        "nome": "Natalândia",
        "latitude": -16.5021,
        "longitude": -46.4874
    },
    {
        "nome": "Natércia",
        "latitude": -22.1158,
        "longitude": -45.5123
    },
    {
        "nome": "Natividade",
        "latitude": -11.7034,
        "longitude": -47.7223
    },
    {
        "nome": "Natividade",
        "latitude": -21.039,
        "longitude": -41.9697
    },
    {
        "nome": "Natividade da Serra",
        "latitude": -23.3707,
        "longitude": -45.4468
    },
    {
        "nome": "Natuba",
        "latitude": -7.63514,
        "longitude": -35.5586
    },
    {
        "nome": "Navegantes",
        "latitude": -26.8943,
        "longitude": -48.6546
    },
    {
        "nome": "Naviraí",
        "latitude": -23.0618,
        "longitude": -54.1995
    },
    {
        "nome": "Nazaré",
        "latitude": -13.0235,
        "longitude": -39.0108
    },
    {
        "nome": "Nazaré",
        "latitude": -6.37496,
        "longitude": -47.6643
    },
    {
        "nome": "Nazaré da Mata",
        "latitude": -7.74149,
        "longitude": -35.2193
    },
    {
        "nome": "Nazaré do Piauí",
        "latitude": -6.97023,
        "longitude": -42.6773
    },
    {
        "nome": "Nazaré Paulista",
        "latitude": -23.1747,
        "longitude": -46.3983
    },
    {
        "nome": "Nazareno",
        "latitude": -21.2168,
        "longitude": -44.6138
    },
    {
        "nome": "Nazarezinho",
        "latitude": -6.9114,
        "longitude": -38.322
    },
    {
        "nome": "Nazária",
        "latitude": -5.35128,
        "longitude": -42.8153
    },
    {
        "nome": "Nazário",
        "latitude": -16.5808,
        "longitude": -49.8817
    },
    {
        "nome": "Neópolis",
        "latitude": -10.3215,
        "longitude": -36.585
    },
    {
        "nome": "Nepomuceno",
        "latitude": -21.2324,
        "longitude": -45.235
    },
    {
        "nome": "Nerópolis",
        "latitude": -16.4047,
        "longitude": -49.2227
    },
    {
        "nome": "Neves Paulista",
        "latitude": -20.843,
        "longitude": -49.6358
    },
    {
        "nome": "Nhamundá",
        "latitude": -2.20793,
        "longitude": -56.7112
    },
    {
        "nome": "Nhandeara",
        "latitude": -20.6945,
        "longitude": -50.0436
    },
    {
        "nome": "Nicolau Vergueiro",
        "latitude": -28.5298,
        "longitude": -52.4676
    },
    {
        "nome": "Nilo Peçanha",
        "latitude": -13.604,
        "longitude": -39.1091
    },
    {
        "nome": "Nilópolis",
        "latitude": -22.8057,
        "longitude": -43.4233
    },
    {
        "nome": "Nina Rodrigues",
        "latitude": -3.46788,
        "longitude": -43.9134
    },
    {
        "nome": "Ninheira",
        "latitude": -15.3148,
        "longitude": -41.7564
    },
    {
        "nome": "Nioaque",
        "latitude": -21.1419,
        "longitude": -55.8296
    },
    {
        "nome": "Nipoã",
        "latitude": -20.9114,
        "longitude": -49.7833
    },
    {
        "nome": "Niquelândia",
        "latitude": -14.4662,
        "longitude": -48.4599
    },
    {
        "nome": "Nísia Floresta",
        "latitude": -6.09329,
        "longitude": -35.1991
    },
    {
        "nome": "Niterói",
        "latitude": -22.8832,
        "longitude": -43.1034
    },
    {
        "nome": "Nobres",
        "latitude": -14.7192,
        "longitude": -56.3284
    },
    {
        "nome": "Nonoai",
        "latitude": -27.3689,
        "longitude": -52.7756
    },
    {
        "nome": "Nordestina",
        "latitude": -10.8192,
        "longitude": -39.4297
    },
    {
        "nome": "Normandia",
        "latitude": 3.8853,
        "longitude": -59.6204
    },
    {
        "nome": "Nortelândia",
        "latitude": -14.454,
        "longitude": -56.7945
    },
    {
        "nome": "Nossa Senhora Aparecida",
        "latitude": -10.3944,
        "longitude": -37.4517
    },
    {
        "nome": "Nossa Senhora da Glória",
        "latitude": -10.2158,
        "longitude": -37.4211
    },
    {
        "nome": "Nossa Senhora das Dores",
        "latitude": -10.4854,
        "longitude": -37.1963
    },
    {
        "nome": "Nossa Senhora das Graças",
        "latitude": -22.9129,
        "longitude": -51.7978
    },
    {
        "nome": "Nossa Senhora de Lourdes",
        "latitude": -10.0772,
        "longitude": -37.0615
    },
    {
        "nome": "Nossa Senhora de Nazaré",
        "latitude": -4.63019,
        "longitude": -42.173
    },
    {
        "nome": "Nossa Senhora do Livramento",
        "latitude": -15.772,
        "longitude": -56.3432
    },
    {
        "nome": "Nossa Senhora do Socorro",
        "latitude": -10.8468,
        "longitude": -37.1231
    },
    {
        "nome": "Nossa Senhora dos Remédios",
        "latitude": -3.97574,
        "longitude": -42.6184
    },
    {
        "nome": "Nova Aliança",
        "latitude": -21.0156,
        "longitude": -49.4986
    },
    {
        "nome": "Nova Aliança do Ivaí",
        "latitude": -23.1763,
        "longitude": -52.6032
    },
    {
        "nome": "Nova Alvorada",
        "latitude": -28.6822,
        "longitude": -52.1631
    },
    {
        "nome": "Nova Alvorada do Sul",
        "latitude": -21.4657,
        "longitude": -54.3825
    },
    {
        "nome": "Nova América",
        "latitude": -15.0206,
        "longitude": -49.8953
    },
    {
        "nome": "Nova América da Colina",
        "latitude": -23.3308,
        "longitude": -50.7168
    },
    {
        "nome": "Nova Andradina",
        "latitude": -22.238,
        "longitude": -53.3437
    },
    {
        "nome": "Nova Araçá",
        "latitude": -28.6537,
        "longitude": -51.7458
    },
    {
        "nome": "Nova Aurora",
        "latitude": -24.5289,
        "longitude": -53.2575
    },
    {
        "nome": "Nova Aurora",
        "latitude": -18.0597,
        "longitude": -48.2552
    },
    {
        "nome": "Nova Bandeirantes",
        "latitude": -9.84977,
        "longitude": -57.8139
    },
    {
        "nome": "Nova Bassano",
        "latitude": -28.7291,
        "longitude": -51.7072
    },
    {
        "nome": "Nova Belém",
        "latitude": -18.4925,
        "longitude": -41.1107
    },
    {
        "nome": "Nova Boa Vista",
        "latitude": -27.9926,
        "longitude": -52.9784
    },
    {
        "nome": "Nova Brasilândia",
        "latitude": -14.9612,
        "longitude": -54.9685
    },
    {
        "nome": "Nova Brasilândia D'Oeste",
        "latitude": -11.7247,
        "longitude": -62.3127
    },
    {
        "nome": "Nova Bréscia",
        "latitude": -29.2182,
        "longitude": -52.0319
    },
    {
        "nome": "Nova Campina",
        "latitude": -24.1224,
        "longitude": -48.9022
    },
    {
        "nome": "Nova Canaã",
        "latitude": -14.7912,
        "longitude": -40.1458
    },
    {
        "nome": "Nova Canaã do Norte",
        "latitude": -10.558,
        "longitude": -55.953
    },
    {
        "nome": "Nova Canaã Paulista",
        "latitude": -20.3836,
        "longitude": -50.9483
    },
    {
        "nome": "Nova Candelária",
        "latitude": -27.6137,
        "longitude": -54.1074
    },
    {
        "nome": "Nova Cantu",
        "latitude": -24.6723,
        "longitude": -52.5661
    },
    {
        "nome": "Nova Castilho",
        "latitude": -20.7615,
        "longitude": -50.3477
    },
    {
        "nome": "Nova Colinas",
        "latitude": -7.12263,
        "longitude": -46.2607
    },
    {
        "nome": "Nova Crixás",
        "latitude": -14.0957,
        "longitude": -50.33
    },
    {
        "nome": "Nova Cruz",
        "latitude": -6.47511,
        "longitude": -35.4286
    },
    {
        "nome": "Nova Era",
        "latitude": -19.7577,
        "longitude": -43.0333
    },
    {
        "nome": "Nova Erechim",
        "latitude": -26.8982,
        "longitude": -52.9066
    },
    {
        "nome": "Nova Esperança",
        "latitude": -23.182,
        "longitude": -52.2031
    },
    {
        "nome": "Nova Esperança do Piriá",
        "latitude": -2.26693,
        "longitude": -46.9731
    },
    {
        "nome": "Nova Esperança do Sudoeste",
        "latitude": -25.9004,
        "longitude": -53.2618
    },
    {
        "nome": "Nova Esperança do Sul",
        "latitude": -29.4066,
        "longitude": -54.8293
    },
    {
        "nome": "Nova Europa",
        "latitude": -21.7765,
        "longitude": -48.5705
    },
    {
        "nome": "Nova Fátima",
        "latitude": -23.4324,
        "longitude": -50.5665
    },
    {
        "nome": "Nova Fátima",
        "latitude": -11.6031,
        "longitude": -39.6302
    },
    {
        "nome": "Nova Floresta",
        "latitude": -6.45056,
        "longitude": -36.2057
    },
    {
        "nome": "Nova Friburgo",
        "latitude": -22.2932,
        "longitude": -42.5377
    },
    {
        "nome": "Nova Glória",
        "latitude": -15.145,
        "longitude": -49.5737
    },
    {
        "nome": "Nova Granada",
        "latitude": -20.5321,
        "longitude": -49.3123
    },
    {
        "nome": "Nova Guarita",
        "latitude": -10.312,
        "longitude": -55.4061
    },
    {
        "nome": "Nova Guataporanga",
        "latitude": -21.332,
        "longitude": -51.6447
    },
    {
        "nome": "Nova Hartz",
        "latitude": -29.5808,
        "longitude": -50.9051
    },
    {
        "nome": "Nova Ibiá",
        "latitude": -13.812,
        "longitude": -39.6182
    },
    {
        "nome": "Nova Iguaçu",
        "latitude": -22.7556,
        "longitude": -43.4603
    },
    {
        "nome": "Nova Iguaçu de Goiás",
        "latitude": -14.2868,
        "longitude": -49.3872
    },
    {
        "nome": "Nova Independência",
        "latitude": -21.1026,
        "longitude": -51.4905
    },
    {
        "nome": "Nova Iorque",
        "latitude": -6.73047,
        "longitude": -44.0471
    },
    {
        "nome": "Nova Ipixuna",
        "latitude": -4.91622,
        "longitude": -49.0822
    },
    {
        "nome": "Nova Itaberaba",
        "latitude": -26.9428,
        "longitude": -52.8141
    },
    {
        "nome": "Nova Itarana",
        "latitude": -13.0241,
        "longitude": -40.0653
    },
    {
        "nome": "Nova Lacerda",
        "latitude": -14.4727,
        "longitude": -59.6001
    },
    {
        "nome": "Nova Laranjeiras",
        "latitude": -25.3054,
        "longitude": -52.5447
    },
    {
        "nome": "Nova Lima",
        "latitude": -19.9758,
        "longitude": -43.8509
    },
    {
        "nome": "Nova Londrina",
        "latitude": -22.7639,
        "longitude": -52.9868
    },
    {
        "nome": "Nova Luzitânia",
        "latitude": -20.856,
        "longitude": -50.2617
    },
    {
        "nome": "Nova Mamoré",
        "latitude": -10.4077,
        "longitude": -65.3346
    },
    {
        "nome": "Nova Marilândia",
        "latitude": -14.3568,
        "longitude": -56.9696
    },
    {
        "nome": "Nova Maringá",
        "latitude": -13.0136,
        "longitude": -57.0908
    },
    {
        "nome": "Nova Módica",
        "latitude": -18.4417,
        "longitude": -41.4984
    },
    {
        "nome": "Nova Monte Verde",
        "latitude": -9.99998,
        "longitude": -57.5261
    },
    {
        "nome": "Nova Mutum",
        "latitude": -13.8374,
        "longitude": -56.0743
    },
    {
        "nome": "Nova Nazaré",
        "latitude": -13.9486,
        "longitude": -51.8002
    },
    {
        "nome": "Nova Odessa",
        "latitude": -22.7832,
        "longitude": -47.2941
    },
    {
        "nome": "Nova Olímpia",
        "latitude": -23.4703,
        "longitude": -53.0898
    },
    {
        "nome": "Nova Olímpia",
        "latitude": -14.7889,
        "longitude": -57.2886
    },
    {
        "nome": "Nova Olinda",
        "latitude": -7.63171,
        "longitude": -48.4252
    },
    {
        "nome": "Nova Olinda",
        "latitude": -7.08415,
        "longitude": -39.6713
    },
    {
        "nome": "Nova Olinda",
        "latitude": -7.47232,
        "longitude": -38.0382
    },
    {
        "nome": "Nova Olinda do Maranhão",
        "latitude": -2.84227,
        "longitude": -45.6953
    },
    {
        "nome": "Nova Olinda do Norte",
        "latitude": -3.90037,
        "longitude": -59.094
    },
    {
        "nome": "Nova Pádua",
        "latitude": -29.0275,
        "longitude": -51.3098
    },
    {
        "nome": "Nova Palma",
        "latitude": -29.471,
        "longitude": -53.4689
    },
    {
        "nome": "Nova Palmeira",
        "latitude": -6.67122,
        "longitude": -36.422
    },
    {
        "nome": "Nova Petrópolis",
        "latitude": -29.3741,
        "longitude": -51.1136
    },
    {
        "nome": "Nova Ponte",
        "latitude": -19.1461,
        "longitude": -47.6779
    },
    {
        "nome": "Nova Porteirinha",
        "latitude": -15.7993,
        "longitude": -43.2941
    },
    {
        "nome": "Nova Prata",
        "latitude": -28.7799,
        "longitude": -51.6113
    },
    {
        "nome": "Nova Prata do Iguaçu",
        "latitude": -25.6309,
        "longitude": -53.3469
    },
    {
        "nome": "Nova Ramada",
        "latitude": -28.0667,
        "longitude": -53.6992
    },
    {
        "nome": "Nova Redenção",
        "latitude": -12.815,
        "longitude": -41.0748
    },
    {
        "nome": "Nova Resende",
        "latitude": -21.1286,
        "longitude": -46.4157
    },
    {
        "nome": "Nova Roma",
        "latitude": -13.7388,
        "longitude": -46.8734
    },
    {
        "nome": "Nova Roma do Sul",
        "latitude": -28.9882,
        "longitude": -51.4095
    },
    {
        "nome": "Nova Rosalândia",
        "latitude": -10.5651,
        "longitude": -48.9125
    },
    {
        "nome": "Nova Russas",
        "latitude": -4.70581,
        "longitude": -40.5621
    },
    {
        "nome": "Nova Santa Bárbara",
        "latitude": -23.5865,
        "longitude": -50.7598
    },
    {
        "nome": "Nova Santa Helena",
        "latitude": -10.8651,
        "longitude": -55.1872
    },
    {
        "nome": "Nova Santa Rita",
        "latitude": -29.8525,
        "longitude": -51.2837
    },
    {
        "nome": "Nova Santa Rita",
        "latitude": -8.09707,
        "longitude": -42.0471
    },
    {
        "nome": "Nova Santa Rosa",
        "latitude": -24.4693,
        "longitude": -53.9552
    },
    {
        "nome": "Nova Serrana",
        "latitude": -19.8713,
        "longitude": -44.9847
    },
    {
        "nome": "Nova Soure",
        "latitude": -11.2329,
        "longitude": -38.4871
    },
    {
        "nome": "Nova Tebas",
        "latitude": -24.438,
        "longitude": -51.9454
    },
    {
        "nome": "Nova Timboteua",
        "latitude": -1.20874,
        "longitude": -47.3921
    },
    {
        "nome": "Nova Trento",
        "latitude": -27.278,
        "longitude": -48.9298
    },
    {
        "nome": "Nova Ubiratã",
        "latitude": -12.9834,
        "longitude": -55.2556
    },
    {
        "nome": "Nova União",
        "latitude": -19.6876,
        "longitude": -43.583
    },
    {
        "nome": "Nova União",
        "latitude": -10.9068,
        "longitude": -62.5564
    },
    {
        "nome": "Nova Venécia",
        "latitude": -18.715,
        "longitude": -40.4053
    },
    {
        "nome": "Nova Veneza",
        "latitude": -28.6338,
        "longitude": -49.5055
    },
    {
        "nome": "Nova Veneza",
        "latitude": -16.3695,
        "longitude": -49.3168
    },
    {
        "nome": "Nova Viçosa",
        "latitude": -17.8926,
        "longitude": -39.3743
    },
    {
        "nome": "Nova Xavantina",
        "latitude": -14.6771,
        "longitude": -52.3502
    },
    {
        "nome": "Novais",
        "latitude": -20.9893,
        "longitude": -48.9141
    },
    {
        "nome": "Novo Acordo",
        "latitude": -9.97063,
        "longitude": -47.6785
    },
    {
        "nome": "Novo Airão",
        "latitude": -2.63637,
        "longitude": -60.9434
    },
    {
        "nome": "Novo Alegre",
        "latitude": -12.9217,
        "longitude": -46.5713
    },
    {
        "nome": "Novo Aripuanã",
        "latitude": -5.12593,
        "longitude": -60.3732
    },
    {
        "nome": "Novo Barreiro",
        "latitude": -27.9077,
        "longitude": -53.1103
    },
    {
        "nome": "Novo Brasil",
        "latitude": -16.0313,
        "longitude": -50.7113
    },
    {
        "nome": "Novo Cabrais",
        "latitude": -29.7338,
        "longitude": -52.9489
    },
    {
        "nome": "Novo Cruzeiro",
        "latitude": -17.4654,
        "longitude": -41.8826
    },
    {
        "nome": "Novo Gama",
        "latitude": -16.0592,
        "longitude": -48.0417
    },
    {
        "nome": "Novo Hamburgo",
        "latitude": -29.6875,
        "longitude": -51.1328
    },
    {
        "nome": "Novo Horizonte",
        "latitude": -26.4442,
        "longitude": -52.8281
    },
    {
        "nome": "Novo Horizonte",
        "latitude": -21.4651,
        "longitude": -49.2234
    },
    {
        "nome": "Novo Horizonte",
        "latitude": -12.8083,
        "longitude": -42.1682
    },
    {
        "nome": "Novo Horizonte do Norte",
        "latitude": -11.4089,
        "longitude": -57.3488
    },
    {
        "nome": "Novo Horizonte do Oeste",
        "latitude": -11.6961,
        "longitude": -61.9951
    },
    {
        "nome": "Novo Horizonte do Sul",
        "latitude": -22.6693,
        "longitude": -53.8601
    },
    {
        "nome": "Novo Itacolomi",
        "latitude": -23.7631,
        "longitude": -51.5079
    },
    {
        "nome": "Novo Jardim",
        "latitude": -11.826,
        "longitude": -46.6325
    },
    {
        "nome": "Novo Lino",
        "latitude": -8.94191,
        "longitude": -35.664
    },
    {
        "nome": "Novo Machado",
        "latitude": -27.5765,
        "longitude": -54.5036
    },
    {
        "nome": "Novo Mundo",
        "latitude": -9.95616,
        "longitude": -55.2029
    },
    {
        "nome": "Novo Oriente",
        "latitude": -5.52552,
        "longitude": -40.7713
    },
    {
        "nome": "Novo Oriente de Minas",
        "latitude": -17.4089,
        "longitude": -41.2194
    },
    {
        "nome": "Novo Oriente do Piauí",
        "latitude": -6.44901,
        "longitude": -41.9261
    },
    {
        "nome": "Novo Planalto",
        "latitude": -13.2424,
        "longitude": -49.506
    },
    {
        "nome": "Novo Progresso",
        "latitude": -7.14347,
        "longitude": -55.3786
    },
    {
        "nome": "Novo Repartimento",
        "latitude": -4.24749,
        "longitude": -49.9499
    },
    {
        "nome": "Novo Santo Antônio",
        "latitude": -5.28749,
        "longitude": -41.9325
    },
    {
        "nome": "Novo Santo Antônio",
        "latitude": -12.2875,
        "longitude": -50.9686
    },
    {
        "nome": "Novo São Joaquim",
        "latitude": -14.9054,
        "longitude": -53.0194
    },
    {
        "nome": "Novo Tiradentes",
        "latitude": -27.5649,
        "longitude": -53.1837
    },
    {
        "nome": "Novo Triunfo",
        "latitude": -10.3182,
        "longitude": -38.4014
    },
    {
        "nome": "Novo Xingu",
        "latitude": -27.749,
        "longitude": -53.0639
    },
    {
        "nome": "Novorizonte",
        "latitude": -16.0162,
        "longitude": -42.4044
    },
    {
        "nome": "Nuporanga",
        "latitude": -20.7296,
        "longitude": -47.7429
    },
    {
        "nome": "Óbidos",
        "latitude": -1.90107,
        "longitude": -55.5208
    },
    {
        "nome": "Ocara",
        "latitude": -4.48523,
        "longitude": -38.5933
    },
    {
        "nome": "Ocauçu",
        "latitude": -22.438,
        "longitude": -49.922
    },
    {
        "nome": "Oeiras",
        "latitude": -7.01915,
        "longitude": -42.1283
    },
    {
        "nome": "Oeiras do Pará",
        "latitude": -2.00358,
        "longitude": -49.8628
    },
    {
        "nome": "Oiapoque",
        "latitude": 3.84074,
        "longitude": -51.8331
    },
    {
        "nome": "Olaria",
        "latitude": -21.8598,
        "longitude": -43.9356
    },
    {
        "nome": "Óleo",
        "latitude": -22.9435,
        "longitude": -49.3419
    },
    {
        "nome": "Olho d'Água",
        "latitude": -7.22118,
        "longitude": -37.7406
    },
    {
        "nome": "Olho d'Água das Cunhãs",
        "latitude": -4.13417,
        "longitude": -45.1163
    },
    {
        "nome": "Olho d'Água das Flores",
        "latitude": -9.53686,
        "longitude": -37.2971
    },
    {
        "nome": "Olho d'Água do Casado",
        "latitude": -9.50357,
        "longitude": -37.8301
    },
    {
        "nome": "Olho D'Água do Piauí",
        "latitude": -5.84125,
        "longitude": -42.5594
    },
    {
        "nome": "Olho d'Água Grande",
        "latitude": -10.0572,
        "longitude": -36.8101
    },
    {
        "nome": "Olho-d'Água do Borges",
        "latitude": -5.9486,
        "longitude": -37.7047
    },
    {
        "nome": "Olhos d'Água",
        "latitude": -17.3982,
        "longitude": -43.5719
    },
    {
        "nome": "Olímpia",
        "latitude": -20.7366,
        "longitude": -48.9106
    },
    {
        "nome": "Olímpio Noronha",
        "latitude": -22.0685,
        "longitude": -45.2657
    },
    {
        "nome": "Olinda",
        "latitude": -8.01017,
        "longitude": -34.8545
    },
    {
        "nome": "Olinda Nova do Maranhão",
        "latitude": -2.99295,
        "longitude": -44.9897
    },
    {
        "nome": "Olindina",
        "latitude": -11.3497,
        "longitude": -38.3379
    },
    {
        "nome": "Olivedos",
        "latitude": -6.98434,
        "longitude": -36.241
    },
    {
        "nome": "Oliveira",
        "latitude": -20.6982,
        "longitude": -44.829
    },
    {
        "nome": "Oliveira de Fátima",
        "latitude": -10.707,
        "longitude": -48.9086
    },
    {
        "nome": "Oliveira dos Brejinhos",
        "latitude": -12.3132,
        "longitude": -42.8969
    },
    {
        "nome": "Oliveira Fortes",
        "latitude": -21.3401,
        "longitude": -43.4499
    },
    {
        "nome": "Olivença",
        "latitude": -9.51954,
        "longitude": -37.1954
    },
    {
        "nome": "Onça de Pitangui",
        "latitude": -19.7276,
        "longitude": -44.8058
    },
    {
        "nome": "Onda Verde",
        "latitude": -20.6042,
        "longitude": -49.2929
    },
    {
        "nome": "Oratórios",
        "latitude": -20.4298,
        "longitude": -42.7977
    },
    {
        "nome": "Oriente",
        "latitude": -22.1549,
        "longitude": -50.0971
    },
    {
        "nome": "Orindiúva",
        "latitude": -20.1861,
        "longitude": -49.3464
    },
    {
        "nome": "Oriximiná",
        "latitude": -1.75989,
        "longitude": -55.8579
    },
    {
        "nome": "Orizânia",
        "latitude": -20.5142,
        "longitude": -42.1991
    },
    {
        "nome": "Orizona",
        "latitude": -17.0334,
        "longitude": -48.2964
    },
    {
        "nome": "Orlândia",
        "latitude": -20.7169,
        "longitude": -47.8852
    },
    {
        "nome": "Orleans",
        "latitude": -28.3487,
        "longitude": -49.2986
    },
    {
        "nome": "Orobó",
        "latitude": -7.74553,
        "longitude": -35.5956
    },
    {
        "nome": "Orocó",
        "latitude": -8.61026,
        "longitude": -39.6026
    },
    {
        "nome": "Orós",
        "latitude": -6.25182,
        "longitude": -38.9053
    },
    {
        "nome": "Ortigueira",
        "latitude": -24.2058,
        "longitude": -50.9185
    },
    {
        "nome": "Osasco",
        "latitude": -23.5324,
        "longitude": -46.7916
    },
    {
        "nome": "Oscar Bressane",
        "latitude": -22.3149,
        "longitude": -50.2811
    },
    {
        "nome": "Osório",
        "latitude": -29.8881,
        "longitude": -50.2667
    },
    {
        "nome": "Osvaldo Cruz",
        "latitude": -21.7968,
        "longitude": -50.8793
    },
    {
        "nome": "Otacílio Costa",
        "latitude": -27.4789,
        "longitude": -50.1231
    },
    {
        "nome": "Ourém",
        "latitude": -1.54168,
        "longitude": -47.1126
    },
    {
        "nome": "Ouriçangas",
        "latitude": -12.0175,
        "longitude": -38.6166
    },
    {
        "nome": "Ouricuri",
        "latitude": -7.87918,
        "longitude": -40.08
    },
    {
        "nome": "Ourilândia do Norte",
        "latitude": -6.7529,
        "longitude": -51.0858
    },
    {
        "nome": "Ourinhos",
        "latitude": -22.9797,
        "longitude": -49.8697
    },
    {
        "nome": "Ourizona",
        "latitude": -23.4053,
        "longitude": -52.1964
    },
    {
        "nome": "Ouro",
        "latitude": -27.3379,
        "longitude": -51.6194
    },
    {
        "nome": "Ouro Branco",
        "latitude": -20.5263,
        "longitude": -43.6962
    },
    {
        "nome": "Ouro Branco",
        "latitude": -6.6958,
        "longitude": -36.9428
    },
    {
        "nome": "Ouro Branco",
        "latitude": -9.15884,
        "longitude": -37.3556
    },
    {
        "nome": "Ouro Fino",
        "latitude": -22.2779,
        "longitude": -46.3716
    },
    {
        "nome": "Ouro Preto",
        "latitude": -20.3796,
        "longitude": -43.512
    },
    {
        "nome": "Ouro Preto do Oeste",
        "latitude": -10.7167,
        "longitude": -62.2565
    },
    {
        "nome": "Ouro Velho",
        "latitude": -7.61604,
        "longitude": -37.1519
    },
    {
        "nome": "Ouro Verde",
        "latitude": -26.692,
        "longitude": -52.3108
    },
    {
        "nome": "Ouro Verde",
        "latitude": -21.4872,
        "longitude": -51.7024
    },
    {
        "nome": "Ouro Verde de Goiás",
        "latitude": -16.2181,
        "longitude": -49.1942
    },
    {
        "nome": "Ouro Verde de Minas",
        "latitude": -18.0719,
        "longitude": -41.2734
    },
    {
        "nome": "Ouro Verde do Oeste",
        "latitude": -24.7933,
        "longitude": -53.9043
    },
    {
        "nome": "Ouroeste",
        "latitude": -20.0061,
        "longitude": -50.3768
    },
    {
        "nome": "Ourolândia",
        "latitude": -10.9578,
        "longitude": -41.0756
    },
    {
        "nome": "Ouvidor",
        "latitude": -18.2277,
        "longitude": -47.8355
    },
    {
        "nome": "Pacaembu",
        "latitude": -21.5627,
        "longitude": -51.2654
    },
    {
        "nome": "Pacajá",
        "latitude": -3.83542,
        "longitude": -50.6399
    },
    {
        "nome": "Pacajus",
        "latitude": -4.17107,
        "longitude": -38.465
    },
    {
        "nome": "Pacaraima",
        "latitude": 4.4799,
        "longitude": -61.1477
    },
    {
        "nome": "Pacatuba",
        "latitude": -3.9784,
        "longitude": -38.6183
    },
    {
        "nome": "Pacatuba",
        "latitude": -10.4538,
        "longitude": -36.6531
    },
    {
        "nome": "Paço do Lumiar",
        "latitude": -2.51657,
        "longitude": -44.1019
    },
    {
        "nome": "Pacoti",
        "latitude": -4.22492,
        "longitude": -38.922
    },
    {
        "nome": "Pacujá",
        "latitude": -3.98327,
        "longitude": -40.6989
    },
    {
        "nome": "Padre Bernardo",
        "latitude": -15.1605,
        "longitude": -48.2833
    },
    {
        "nome": "Padre Carvalho",
        "latitude": -16.3646,
        "longitude": -42.5088
    },
    {
        "nome": "Padre Marcos",
        "latitude": -7.35101,
        "longitude": -40.8997
    },
    {
        "nome": "Padre Paraíso",
        "latitude": -17.0758,
        "longitude": -41.4821
    },
    {
        "nome": "Paes Landim",
        "latitude": -7.77375,
        "longitude": -42.2474
    },
    {
        "nome": "Pai Pedro",
        "latitude": -15.5271,
        "longitude": -43.07
    },
    {
        "nome": "Paial",
        "latitude": -27.2541,
        "longitude": -52.4975
    },
    {
        "nome": "Paiçandu",
        "latitude": -23.4555,
        "longitude": -52.046
    },
    {
        "nome": "Paim Filho",
        "latitude": -27.7075,
        "longitude": -51.763
    },
    {
        "nome": "Paineiras",
        "latitude": -18.8993,
        "longitude": -45.5321
    },
    {
        "nome": "Painel",
        "latitude": -27.9234,
        "longitude": -50.0972
    },
    {
        "nome": "Pains",
        "latitude": -20.3705,
        "longitude": -45.6627
    },
    {
        "nome": "Paiva",
        "latitude": -21.2913,
        "longitude": -43.4088
    },
    {
        "nome": "Pajeú do Piauí",
        "latitude": -7.85508,
        "longitude": -42.8248
    },
    {
        "nome": "Palestina",
        "latitude": -9.67493,
        "longitude": -37.339
    },
    {
        "nome": "Palestina",
        "latitude": -20.39,
        "longitude": -49.4309
    },
    {
        "nome": "Palestina de Goiás",
        "latitude": -16.7392,
        "longitude": -51.5309
    },
    {
        "nome": "Palestina do Pará",
        "latitude": -5.74027,
        "longitude": -48.3181
    },
    {
        "nome": "Palhano",
        "latitude": -4.73672,
        "longitude": -37.9655
    },
    {
        "nome": "Palhoça",
        "latitude": -27.6455,
        "longitude": -48.6697
    },
    {
        "nome": "Palma",
        "latitude": -21.3748,
        "longitude": -42.3123
    },
    {
        "nome": "Palma Sola",
        "latitude": -26.3471,
        "longitude": -53.2771
    },
    {
        "nome": "Palmácia",
        "latitude": -4.13831,
        "longitude": -38.8446
    },
    {
        "nome": "Palmares",
        "latitude": -8.68423,
        "longitude": -35.589
    },
    {
        "nome": "Palmares do Sul",
        "latitude": -30.2535,
        "longitude": -50.5103
    },
    {
        "nome": "Palmares Paulista",
        "latitude": -21.0854,
        "longitude": -48.8037
    },
    {
        "nome": "Palmas",
        "latitude": -26.4839,
        "longitude": -51.9888
    },
    {
        "nome": "Palmas",
        "latitude": -10.24,
        "longitude": -48.3558
    },
    {
        "nome": "Palmas de Monte Alto",
        "latitude": -14.2676,
        "longitude": -43.1609
    },
    {
        "nome": "Palmeira",
        "latitude": -25.4257,
        "longitude": -50.007
    },
    {
        "nome": "Palmeira",
        "latitude": -27.583,
        "longitude": -50.1577
    },
    {
        "nome": "Palmeira d'Oeste",
        "latitude": -20.4148,
        "longitude": -50.7632
    },
    {
        "nome": "Palmeira das Missões",
        "latitude": -27.9007,
        "longitude": -53.3134
    },
    {
        "nome": "Palmeira do Piauí",
        "latitude": -8.73076,
        "longitude": -44.2466
    },
    {
        "nome": "Palmeira dos Índios",
        "latitude": -9.40568,
        "longitude": -36.6328
    },
    {
        "nome": "Palmeirais",
        "latitude": -5.97086,
        "longitude": -43.056
    },
    {
        "nome": "Palmeirândia",
        "latitude": -2.64433,
        "longitude": -44.8933
    },
    {
        "nome": "Palmeirante",
        "latitude": -7.84786,
        "longitude": -47.9242
    },
    {
        "nome": "Palmeiras",
        "latitude": -12.5059,
        "longitude": -41.5809
    },
    {
        "nome": "Palmeiras de Goiás",
        "latitude": -16.8044,
        "longitude": -49.924
    },
    {
        "nome": "Palmeiras do Tocantins",
        "latitude": -6.61658,
        "longitude": -47.5464
    },
    {
        "nome": "Palmeirina",
        "latitude": -9.0109,
        "longitude": -36.3242
    },
    {
        "nome": "Palmeirópolis",
        "latitude": -13.0447,
        "longitude": -48.4026
    },
    {
        "nome": "Palmelo",
        "latitude": -17.3258,
        "longitude": -48.426
    },
    {
        "nome": "Palminópolis",
        "latitude": -16.7924,
        "longitude": -50.1652
    },
    {
        "nome": "Palmital",
        "latitude": -22.7858,
        "longitude": -50.218
    },
    {
        "nome": "Palmital",
        "latitude": -24.8853,
        "longitude": -52.2029
    },
    {
        "nome": "Palmitinho",
        "latitude": -27.3596,
        "longitude": -53.558
    },
    {
        "nome": "Palmitos",
        "latitude": -27.0702,
        "longitude": -53.1586
    },
    {
        "nome": "Palmópolis",
        "latitude": -16.7364,
        "longitude": -40.4296
    },
    {
        "nome": "Palotina",
        "latitude": -24.2868,
        "longitude": -53.8404
    },
    {
        "nome": "Panamá",
        "latitude": -18.1783,
        "longitude": -49.355
    },
    {
        "nome": "Panambi",
        "latitude": -28.2833,
        "longitude": -53.5023
    },
    {
        "nome": "Pancas",
        "latitude": -19.2229,
        "longitude": -40.8534
    },
    {
        "nome": "Panelas",
        "latitude": -8.66121,
        "longitude": -36.0125
    },
    {
        "nome": "Panorama",
        "latitude": -21.354,
        "longitude": -51.8562
    },
    {
        "nome": "Pantano Grande",
        "latitude": -30.1902,
        "longitude": -52.3729
    },
    {
        "nome": "Pão de Açúcar",
        "latitude": -9.74032,
        "longitude": -37.4403
    },
    {
        "nome": "Papagaios",
        "latitude": -19.4419,
        "longitude": -44.7468
    },
    {
        "nome": "Papanduva",
        "latitude": -26.3777,
        "longitude": -50.1419
    },
    {
        "nome": "Paquetá",
        "latitude": -7.10303,
        "longitude": -41.7
    },
    {
        "nome": "Pará de Minas",
        "latitude": -19.8534,
        "longitude": -44.6114
    },
    {
        "nome": "Paracambi",
        "latitude": -22.6078,
        "longitude": -43.7108
    },
    {
        "nome": "Paracatu",
        "latitude": -17.2252,
        "longitude": -46.8711
    },
    {
        "nome": "Paracuru",
        "latitude": -3.41436,
        "longitude": -39.03
    },
    {
        "nome": "Paragominas",
        "latitude": -3.00212,
        "longitude": -47.3527
    },
    {
        "nome": "Paraguaçu",
        "latitude": -21.5465,
        "longitude": -45.7374
    },
    {
        "nome": "Paraguaçu Paulista",
        "latitude": -22.4114,
        "longitude": -50.5732
    },
    {
        "nome": "Paraí",
        "latitude": -28.5964,
        "longitude": -51.7896
    },
    {
        "nome": "Paraíba do Sul",
        "latitude": -22.1585,
        "longitude": -43.304
    },
    {
        "nome": "Paraibano",
        "latitude": -6.4264,
        "longitude": -43.9792
    },
    {
        "nome": "Paraibuna",
        "latitude": -23.3872,
        "longitude": -45.6639
    },
    {
        "nome": "Paraipaba",
        "latitude": -3.43799,
        "longitude": -39.1479
    },
    {
        "nome": "Paraíso",
        "latitude": -21.0159,
        "longitude": -48.7761
    },
    {
        "nome": "Paraíso",
        "latitude": -26.62,
        "longitude": -53.6716
    },
    {
        "nome": "Paraíso das Águas",
        "latitude": -19.0216,
        "longitude": -53.0116
    },
    {
        "nome": "Paraíso do Norte",
        "latitude": -23.2824,
        "longitude": -52.6054
    },
    {
        "nome": "Paraíso do Sul",
        "latitude": -29.6717,
        "longitude": -53.144
    },
    {
        "nome": "Paraíso do Tocantins",
        "latitude": -10.175,
        "longitude": -48.8823
    },
    {
        "nome": "Paraisópolis",
        "latitude": -22.5539,
        "longitude": -45.7803
    },
    {
        "nome": "Parambu",
        "latitude": -6.20768,
        "longitude": -40.6905
    },
    {
        "nome": "Paramirim",
        "latitude": -13.4388,
        "longitude": -42.2395
    },
    {
        "nome": "Paramoti",
        "latitude": -4.08815,
        "longitude": -39.2417
    },
    {
        "nome": "Paranã",
        "latitude": -12.6167,
        "longitude": -47.8734
    },
    {
        "nome": "Paraná",
        "latitude": -6.47565,
        "longitude": -38.3057
    },
    {
        "nome": "Paranacity",
        "latitude": -22.9297,
        "longitude": -52.1549
    },
    {
        "nome": "Paranaguá",
        "latitude": -25.5161,
        "longitude": -48.5225
    },
    {
        "nome": "Paranaíba",
        "latitude": -19.6746,
        "longitude": -51.1909
    },
    {
        "nome": "Paranaiguara",
        "latitude": -18.9141,
        "longitude": -50.6539
    },
    {
        "nome": "Paranaíta",
        "latitude": -9.65835,
        "longitude": -56.4786
    },
    {
        "nome": "Paranapanema",
        "latitude": -23.3862,
        "longitude": -48.7214
    },
    {
        "nome": "Paranapoema",
        "latitude": -22.6412,
        "longitude": -52.0905
    },
    {
        "nome": "Paranapuã",
        "latitude": -20.1048,
        "longitude": -50.5886
    },
    {
        "nome": "Paranatama",
        "latitude": -8.91875,
        "longitude": -36.6549
    },
    {
        "nome": "Paranatinga",
        "latitude": -14.4265,
        "longitude": -54.0524
    },
    {
        "nome": "Paranavaí",
        "latitude": -23.0816,
        "longitude": -52.4617
    },
    {
        "nome": "Paranhos",
        "latitude": -23.8911,
        "longitude": -55.429
    },
    {
        "nome": "Paraopeba",
        "latitude": -19.2732,
        "longitude": -44.4044
    },
    {
        "nome": "Parapuã",
        "latitude": -21.7792,
        "longitude": -50.7949
    },
    {
        "nome": "Parari",
        "latitude": -7.30975,
        "longitude": -36.6522
    },
    {
        "nome": "Paratinga",
        "latitude": -12.687,
        "longitude": -43.1798
    },
    {
        "nome": "Paraty",
        "latitude": -23.2221,
        "longitude": -44.7175
    },
    {
        "nome": "Paraú",
        "latitude": -5.76893,
        "longitude": -37.1032
    },
    {
        "nome": "Parauapebas",
        "latitude": -6.06781,
        "longitude": -49.9037
    },
    {
        "nome": "Paraúna",
        "latitude": -16.9463,
        "longitude": -50.4484
    },
    {
        "nome": "Parazinho",
        "latitude": -5.22276,
        "longitude": -35.8398
    },
    {
        "nome": "Pardinho",
        "latitude": -23.0841,
        "longitude": -48.3679
    },
    {
        "nome": "Pareci Novo",
        "latitude": -29.6365,
        "longitude": -51.3974
    },
    {
        "nome": "Parecis",
        "latitude": -12.1754,
        "longitude": -61.6032
    },
    {
        "nome": "Parelhas",
        "latitude": -6.68491,
        "longitude": -36.6566
    },
    {
        "nome": "Pariconha",
        "latitude": -9.25634,
        "longitude": -37.9988
    },
    {
        "nome": "Parintins",
        "latitude": -2.63741,
        "longitude": -56.729
    },
    {
        "nome": "Paripiranga",
        "latitude": -10.6859,
        "longitude": -37.8626
    },
    {
        "nome": "Paripueira",
        "latitude": -9.46313,
        "longitude": -35.552
    },
    {
        "nome": "Pariquera-Açu",
        "latitude": -24.7147,
        "longitude": -47.8742
    },
    {
        "nome": "Parisi",
        "latitude": -20.3034,
        "longitude": -50.0163
    },
    {
        "nome": "Parnaguá",
        "latitude": -10.2166,
        "longitude": -44.63
    },
    {
        "nome": "Parnaíba",
        "latitude": -2.90585,
        "longitude": -41.7754
    },
    {
        "nome": "Parnamirim",
        "latitude": -5.91116,
        "longitude": -35.271
    },
    {
        "nome": "Parnamirim",
        "latitude": -8.08729,
        "longitude": -39.5795
    },
    {
        "nome": "Parnarama",
        "latitude": -5.67365,
        "longitude": -43.1011
    },
    {
        "nome": "Parobé",
        "latitude": -29.6243,
        "longitude": -50.8312
    },
    {
        "nome": "Passa e Fica",
        "latitude": -6.43018,
        "longitude": -35.6442
    },
    {
        "nome": "Passa Quatro",
        "latitude": -22.3871,
        "longitude": -44.9709
    },
    {
        "nome": "Passa Sete",
        "latitude": -29.4577,
        "longitude": -52.9599
    },
    {
        "nome": "Passa Tempo",
        "latitude": -20.6539,
        "longitude": -44.4926
    },
    {
        "nome": "Passa-Vinte",
        "latitude": -22.2097,
        "longitude": -44.2344
    },
    {
        "nome": "Passabém",
        "latitude": -19.3509,
        "longitude": -43.1383
    },
    {
        "nome": "Passagem",
        "latitude": -6.27268,
        "longitude": -35.37
    },
    {
        "nome": "Passagem",
        "latitude": -7.13467,
        "longitude": -37.0433
    },
    {
        "nome": "Passagem Franca",
        "latitude": -6.17745,
        "longitude": -43.7755
    },
    {
        "nome": "Passagem Franca do Piauí",
        "latitude": -5.86036,
        "longitude": -42.4436
    },
    {
        "nome": "Passira",
        "latitude": -7.9971,
        "longitude": -35.5813
    },
    {
        "nome": "Passo de Camaragibe",
        "latitude": -9.24511,
        "longitude": -35.4745
    },
    {
        "nome": "Passo de Torres",
        "latitude": -29.3099,
        "longitude": -49.722
    },
    {
        "nome": "Passo do Sobrado",
        "latitude": -29.748,
        "longitude": -52.2748
    },
    {
        "nome": "Passo Fundo",
        "latitude": -28.2576,
        "longitude": -52.4091
    },
    {
        "nome": "Passos",
        "latitude": -20.7193,
        "longitude": -46.609
    },
    {
        "nome": "Passos Maia",
        "latitude": -26.7829,
        "longitude": -52.0568
    },
    {
        "nome": "Pastos Bons",
        "latitude": -6.60296,
        "longitude": -44.0745
    },
    {
        "nome": "Patis",
        "latitude": -16.0773,
        "longitude": -44.0787
    },
    {
        "nome": "Pato Bragado",
        "latitude": -24.6271,
        "longitude": -54.2265
    },
    {
        "nome": "Pato Branco",
        "latitude": -26.2292,
        "longitude": -52.6706
    },
    {
        "nome": "Patos",
        "latitude": -7.01743,
        "longitude": -37.2747
    },
    {
        "nome": "Patos de Minas",
        "latitude": -18.5699,
        "longitude": -46.5013
    },
    {
        "nome": "Patos do Piauí",
        "latitude": -7.67231,
        "longitude": -41.2408
    },
    {
        "nome": "Patrocínio",
        "latitude": -18.9379,
        "longitude": -46.9934
    },
    {
        "nome": "Patrocínio do Muriaé",
        "latitude": -21.1544,
        "longitude": -42.2125
    },
    {
        "nome": "Patrocínio Paulista",
        "latitude": -20.6384,
        "longitude": -47.2801
    },
    {
        "nome": "Patu",
        "latitude": -6.10656,
        "longitude": -37.6356
    },
    {
        "nome": "Paty do Alferes",
        "latitude": -22.4309,
        "longitude": -43.4285
    },
    {
        "nome": "Pau Brasil",
        "latitude": -15.4572,
        "longitude": -39.6458
    },
    {
        "nome": "Pau d'Arco",
        "latitude": -1.59772,
        "longitude": -46.9268
    },
    {
        "nome": "Pau D'Arco",
        "latitude": -7.53919,
        "longitude": -49.367
    },
    {
        "nome": "Pau D'Arco do Piauí",
        "latitude": -5.26072,
        "longitude": -42.3908
    },
    {
        "nome": "Pau dos Ferros",
        "latitude": -6.10498,
        "longitude": -38.2077
    },
    {
        "nome": "Paudalho",
        "latitude": -7.90287,
        "longitude": -35.1716
    },
    {
        "nome": "Pauini",
        "latitude": -7.71311,
        "longitude": -66.992
    },
    {
        "nome": "Paula Cândido",
        "latitude": -20.8754,
        "longitude": -42.9752
    },
    {
        "nome": "Paula Freitas",
        "latitude": -26.2105,
        "longitude": -50.931
    },
    {
        "nome": "Paulicéia",
        "latitude": -21.3153,
        "longitude": -51.8321
    },
    {
        "nome": "Paulínia",
        "latitude": -22.7542,
        "longitude": -47.1488
    },
    {
        "nome": "Paulino Neves",
        "latitude": -2.72094,
        "longitude": -42.5258
    },
    {
        "nome": "Paulista",
        "latitude": -6.59138,
        "longitude": -37.6185
    },
    {
        "nome": "Paulista",
        "latitude": -7.93401,
        "longitude": -34.8684
    },
    {
        "nome": "Paulistana",
        "latitude": -8.13436,
        "longitude": -41.1431
    },
    {
        "nome": "Paulistânia",
        "latitude": -22.5768,
        "longitude": -49.4008
    },
    {
        "nome": "Paulistas",
        "latitude": -18.4276,
        "longitude": -42.8628
    },
    {
        "nome": "Paulo Afonso",
        "latitude": -9.3983,
        "longitude": -38.2216
    },
    {
        "nome": "Paulo Bento",
        "latitude": -27.7051,
        "longitude": -52.4169
    },
    {
        "nome": "Paulo de Faria",
        "latitude": -20.0296,
        "longitude": -49.4
    },
    {
        "nome": "Paulo Frontin",
        "latitude": -26.0466,
        "longitude": -50.8304
    },
    {
        "nome": "Paulo Jacinto",
        "latitude": -9.36792,
        "longitude": -36.3672
    },
    {
        "nome": "Paulo Lopes",
        "latitude": -27.9607,
        "longitude": -48.6864
    },
    {
        "nome": "Paulo Ramos",
        "latitude": -4.44485,
        "longitude": -45.2398
    },
    {
        "nome": "Pavão",
        "latitude": -17.4267,
        "longitude": -41.0035
    },
    {
        "nome": "Paverama",
        "latitude": -29.5486,
        "longitude": -51.7339
    },
    {
        "nome": "Pavussu",
        "latitude": -7.96059,
        "longitude": -43.2284
    },
    {
        "nome": "Pé de Serra",
        "latitude": -11.8313,
        "longitude": -39.611
    },
    {
        "nome": "Peabiru",
        "latitude": -23.914,
        "longitude": -52.3431
    },
    {
        "nome": "Peçanha",
        "latitude": -18.5441,
        "longitude": -42.5583
    },
    {
        "nome": "Pederneiras",
        "latitude": -22.3511,
        "longitude": -48.7781
    },
    {
        "nome": "Pedra",
        "latitude": -8.49641,
        "longitude": -36.94
    },
    {
        "nome": "Pedra Azul",
        "latitude": -16.0086,
        "longitude": -41.2909
    },
    {
        "nome": "Pedra Bela",
        "latitude": -22.7902,
        "longitude": -46.4455
    },
    {
        "nome": "Pedra Bonita",
        "latitude": -20.5219,
        "longitude": -42.3304
    },
    {
        "nome": "Pedra Branca",
        "latitude": -7.42169,
        "longitude": -38.0689
    },
    {
        "nome": "Pedra Branca",
        "latitude": -5.45341,
        "longitude": -39.7078
    },
    {
        "nome": "Pedra Branca do Amapari",
        "latitude": 0.777424,
        "longitude": -51.9503
    },
    {
        "nome": "Pedra do Anta",
        "latitude": -20.5968,
        "longitude": -42.7123
    },
    {
        "nome": "Pedra do Indaiá",
        "latitude": -20.2563,
        "longitude": -45.2107
    },
    {
        "nome": "Pedra Dourada",
        "latitude": -20.8266,
        "longitude": -42.1515
    },
    {
        "nome": "Pedra Grande",
        "latitude": -5.14988,
        "longitude": -35.876
    },
    {
        "nome": "Pedra Lavrada",
        "latitude": -6.74997,
        "longitude": -36.4758
    },
    {
        "nome": "Pedra Mole",
        "latitude": -10.6134,
        "longitude": -37.6922
    },
    {
        "nome": "Pedra Preta",
        "latitude": -5.57352,
        "longitude": -36.1084
    },
    {
        "nome": "Pedra Preta",
        "latitude": -16.6245,
        "longitude": -54.4722
    },
    {
        "nome": "Pedralva",
        "latitude": -22.2386,
        "longitude": -45.4654
    },
    {
        "nome": "Pedranópolis",
        "latitude": -20.2474,
        "longitude": -50.1129
    },
    {
        "nome": "Pedrão",
        "latitude": -12.1491,
        "longitude": -38.6487
    },
    {
        "nome": "Pedras Altas",
        "latitude": -31.7365,
        "longitude": -53.5814
    },
    {
        "nome": "Pedras de Fogo",
        "latitude": -7.39107,
        "longitude": -35.1065
    },
    {
        "nome": "Pedras de Maria da Cruz",
        "latitude": -15.6032,
        "longitude": -44.391
    },
    {
        "nome": "Pedras Grandes",
        "latitude": -28.4339,
        "longitude": -49.1949
    },
    {
        "nome": "Pedregulho",
        "latitude": -20.2535,
        "longitude": -47.4775
    },
    {
        "nome": "Pedreira",
        "latitude": -22.7413,
        "longitude": -46.8948
    },
    {
        "nome": "Pedreiras",
        "latitude": -4.56482,
        "longitude": -44.6006
    },
    {
        "nome": "Pedrinhas",
        "latitude": -11.1902,
        "longitude": -37.6775
    },
    {
        "nome": "Pedrinhas Paulista",
        "latitude": -22.8174,
        "longitude": -50.7933
    },
    {
        "nome": "Pedrinópolis",
        "latitude": -19.2241,
        "longitude": -47.4579
    },
    {
        "nome": "Pedro Afonso",
        "latitude": -8.97034,
        "longitude": -48.1729
    },
    {
        "nome": "Pedro Alexandre",
        "latitude": -10.012,
        "longitude": -37.8932
    },
    {
        "nome": "Pedro Avelino",
        "latitude": -5.5161,
        "longitude": -36.3867
    },
    {
        "nome": "Pedro Canário",
        "latitude": -18.3004,
        "longitude": -39.9574
    },
    {
        "nome": "Pedro de Toledo",
        "latitude": -24.2764,
        "longitude": -47.2354
    },
    {
        "nome": "Pedro do Rosário",
        "latitude": -2.97272,
        "longitude": -45.3493
    },
    {
        "nome": "Pedro Gomes",
        "latitude": -18.0996,
        "longitude": -54.5507
    },
    {
        "nome": "Pedro II",
        "latitude": -4.42585,
        "longitude": -41.4482
    },
    {
        "nome": "Pedro Laurentino",
        "latitude": -8.06807,
        "longitude": -42.2847
    },
    {
        "nome": "Pedro Leopoldo",
        "latitude": -19.6308,
        "longitude": -44.0383
    },
    {
        "nome": "Pedro Osório",
        "latitude": -31.8642,
        "longitude": -52.8184
    },
    {
        "nome": "Pedro Régis",
        "latitude": -6.63323,
        "longitude": -35.2966
    },
    {
        "nome": "Pedro Teixeira",
        "latitude": -21.7076,
        "longitude": -43.743
    },
    {
        "nome": "Pedro Velho",
        "latitude": -6.4356,
        "longitude": -35.2195
    },
    {
        "nome": "Peixe",
        "latitude": -12.0254,
        "longitude": -48.5395
    },
    {
        "nome": "Peixe-Boi",
        "latitude": -1.19382,
        "longitude": -47.324
    },
    {
        "nome": "Peixoto de Azevedo",
        "latitude": -10.2262,
        "longitude": -54.9794
    },
    {
        "nome": "Pejuçara",
        "latitude": -28.4283,
        "longitude": -53.6579
    },
    {
        "nome": "Pelotas",
        "latitude": -31.7649,
        "longitude": -52.3371
    },
    {
        "nome": "Penaforte",
        "latitude": -7.82163,
        "longitude": -39.0707
    },
    {
        "nome": "Penalva",
        "latitude": -3.27674,
        "longitude": -45.1768
    },
    {
        "nome": "Penápolis",
        "latitude": -21.4148,
        "longitude": -50.0769
    },
    {
        "nome": "Pendências",
        "latitude": -5.2564,
        "longitude": -36.7095
    },
    {
        "nome": "Penedo",
        "latitude": -10.2874,
        "longitude": -36.5819
    },
    {
        "nome": "Penha",
        "latitude": -26.7754,
        "longitude": -48.6465
    },
    {
        "nome": "Pentecoste",
        "latitude": -3.79274,
        "longitude": -39.2692
    },
    {
        "nome": "Pequeri",
        "latitude": -21.8341,
        "longitude": -43.1145
    },
    {
        "nome": "Pequi",
        "latitude": -19.6284,
        "longitude": -44.6604
    },
    {
        "nome": "Pequizeiro",
        "latitude": -8.5932,
        "longitude": -48.9327
    },
    {
        "nome": "Perdigão",
        "latitude": -19.9411,
        "longitude": -45.078
    },
    {
        "nome": "Perdizes",
        "latitude": -19.3434,
        "longitude": -47.2963
    },
    {
        "nome": "Perdões",
        "latitude": -21.0932,
        "longitude": -45.0896
    },
    {
        "nome": "Pereira Barreto",
        "latitude": -20.6368,
        "longitude": -51.1123
    },
    {
        "nome": "Pereiras",
        "latitude": -23.0804,
        "longitude": -47.972
    },
    {
        "nome": "Pereiro",
        "latitude": -6.03576,
        "longitude": -38.4624
    },
    {
        "nome": "Peri Mirim",
        "latitude": -2.57676,
        "longitude": -44.8504
    },
    {
        "nome": "Periquito",
        "latitude": -19.1573,
        "longitude": -42.2333
    },
    {
        "nome": "Peritiba",
        "latitude": -27.3754,
        "longitude": -51.9018
    },
    {
        "nome": "Peritoró",
        "latitude": -4.37459,
        "longitude": -44.3369
    },
    {
        "nome": "Perobal",
        "latitude": -23.8949,
        "longitude": -53.4098
    },
    {
        "nome": "Pérola",
        "latitude": -23.8039,
        "longitude": -53.6834
    },
    {
        "nome": "Pérola d'Oeste",
        "latitude": -25.8278,
        "longitude": -53.7433
    },
    {
        "nome": "Perolândia",
        "latitude": -17.5258,
        "longitude": -52.065
    },
    {
        "nome": "Peruíbe",
        "latitude": -24.312,
        "longitude": -47.0012
    },
    {
        "nome": "Pescador",
        "latitude": -18.357,
        "longitude": -41.6006
    },
    {
        "nome": "Pescaria Brava",
        "latitude": -28.3966,
        "longitude": -48.8864
    },
    {
        "nome": "Pesqueira",
        "latitude": -8.35797,
        "longitude": -36.6978
    },
    {
        "nome": "Petrolândia",
        "latitude": -9.06863,
        "longitude": -38.3027
    },
    {
        "nome": "Petrolândia",
        "latitude": -27.5346,
        "longitude": -49.6937
    },
    {
        "nome": "Petrolina",
        "latitude": -9.38866,
        "longitude": -40.5027
    },
    {
        "nome": "Petrolina de Goiás",
        "latitude": -16.0968,
        "longitude": -49.3364
    },
    {
        "nome": "Petrópolis",
        "latitude": -22.52,
        "longitude": -43.1926
    },
    {
        "nome": "Piaçabuçu",
        "latitude": -10.406,
        "longitude": -36.434
    },
    {
        "nome": "Piacatu",
        "latitude": -21.5921,
        "longitude": -50.6003
    },
    {
        "nome": "Piancó",
        "latitude": -7.19282,
        "longitude": -37.9289
    },
    {
        "nome": "Piatã",
        "latitude": -13.1465,
        "longitude": -41.7702
    },
    {
        "nome": "Piau",
        "latitude": -21.5096,
        "longitude": -43.313
    },
    {
        "nome": "Picada Café",
        "latitude": -29.4464,
        "longitude": -51.1367
    },
    {
        "nome": "Piçarra",
        "latitude": -6.43778,
        "longitude": -48.8716
    },
    {
        "nome": "Picos",
        "latitude": -7.07721,
        "longitude": -41.467
    },
    {
        "nome": "Picuí",
        "latitude": -6.50845,
        "longitude": -36.3497
    },
    {
        "nome": "Piedade",
        "latitude": -23.7139,
        "longitude": -47.4256
    },
    {
        "nome": "Piedade de Caratinga",
        "latitude": -19.7593,
        "longitude": -42.0756
    },
    {
        "nome": "Piedade de Ponte Nova",
        "latitude": -20.2438,
        "longitude": -42.7379
    },
    {
        "nome": "Piedade do Rio Grande",
        "latitude": -21.469,
        "longitude": -44.1938
    },
    {
        "nome": "Piedade dos Gerais",
        "latitude": -20.4715,
        "longitude": -44.2243
    },
    {
        "nome": "Piên",
        "latitude": -26.0965,
        "longitude": -49.4336
    },
    {
        "nome": "Pilão Arcado",
        "latitude": -10.0051,
        "longitude": -42.4936
    },
    {
        "nome": "Pilar",
        "latitude": -7.26403,
        "longitude": -35.2523
    },
    {
        "nome": "Pilar",
        "latitude": -9.60135,
        "longitude": -35.9543
    },
    {
        "nome": "Pilar de Goiás",
        "latitude": -14.7608,
        "longitude": -49.5784
    },
    {
        "nome": "Pilar do Sul",
        "latitude": -23.8077,
        "longitude": -47.7222
    },
    {
        "nome": "Pilões",
        "latitude": -6.26364,
        "longitude": -38.0461
    },
    {
        "nome": "Pilões",
        "latitude": -6.86827,
        "longitude": -35.613
    },
    {
        "nome": "Pilõezinhos",
        "latitude": -6.84277,
        "longitude": -35.531
    },
    {
        "nome": "Pimenta",
        "latitude": -20.4827,
        "longitude": -45.8049
    },
    {
        "nome": "Pimenta Bueno",
        "latitude": -11.672,
        "longitude": -61.198
    },
    {
        "nome": "Pimenteiras",
        "latitude": -6.23839,
        "longitude": -41.4113
    },
    {
        "nome": "Pimenteiras do Oeste",
        "latitude": -13.4823,
        "longitude": -61.0471
    },
    {
        "nome": "Pindaí",
        "latitude": -14.4921,
        "longitude": -42.686
    },
    {
        "nome": "Pindamonhangaba",
        "latitude": -22.9246,
        "longitude": -45.4613
    },
    {
        "nome": "Pindaré-Mirim",
        "latitude": -3.60985,
        "longitude": -45.342
    },
    {
        "nome": "Pindoba",
        "latitude": -9.47382,
        "longitude": -36.2918
    },
    {
        "nome": "Pindobaçu",
        "latitude": -10.7433,
        "longitude": -40.3675
    },
    {
        "nome": "Pindorama",
        "latitude": -21.1853,
        "longitude": -48.9086
    },
    {
        "nome": "Pindorama do Tocantins",
        "latitude": -11.1311,
        "longitude": -47.5726
    },
    {
        "nome": "Pindoretama",
        "latitude": -4.01584,
        "longitude": -38.3061
    },
    {
        "nome": "Pingo-d'Água",
        "latitude": -19.7287,
        "longitude": -42.4095
    },
    {
        "nome": "Pinhais",
        "latitude": -25.4429,
        "longitude": -49.1927
    },
    {
        "nome": "Pinhal",
        "latitude": -27.508,
        "longitude": -53.2082
    },
    {
        "nome": "Pinhal da Serra",
        "latitude": -27.8751,
        "longitude": -51.1673
    },
    {
        "nome": "Pinhal de São Bento",
        "latitude": -26.0324,
        "longitude": -53.482
    },
    {
        "nome": "Pinhal Grande",
        "latitude": -29.345,
        "longitude": -53.3206
    },
    {
        "nome": "Pinhalão",
        "latitude": -23.7982,
        "longitude": -50.0536
    },
    {
        "nome": "Pinhalzinho",
        "latitude": -22.7811,
        "longitude": -46.5897
    },
    {
        "nome": "Pinhalzinho",
        "latitude": -26.8495,
        "longitude": -52.9913
    },
    {
        "nome": "Pinhão",
        "latitude": -10.5677,
        "longitude": -37.7242
    },
    {
        "nome": "Pinhão",
        "latitude": -25.6944,
        "longitude": -51.6536
    },
    {
        "nome": "Pinheiral",
        "latitude": -22.5172,
        "longitude": -44.0022
    },
    {
        "nome": "Pinheirinho do Vale",
        "latitude": -27.2109,
        "longitude": -53.608
    },
    {
        "nome": "Pinheiro",
        "latitude": -2.52224,
        "longitude": -45.0788
    },
    {
        "nome": "Pinheiro Machado",
        "latitude": -31.5794,
        "longitude": -53.3798
    },
    {
        "nome": "Pinheiro Preto",
        "latitude": -27.0483,
        "longitude": -51.2243
    },
    {
        "nome": "Pinheiros",
        "latitude": -18.4141,
        "longitude": -40.2171
    },
    {
        "nome": "Pintadas",
        "latitude": -11.8117,
        "longitude": -39.9009
    },
    {
        "nome": "Pinto Bandeira",
        "latitude": -29.0975,
        "longitude": -51.4503
    },
    {
        "nome": "Pintópolis",
        "latitude": -16.0572,
        "longitude": -45.1402
    },
    {
        "nome": "Pio IX",
        "latitude": -6.83002,
        "longitude": -40.6083
    },
    {
        "nome": "Pio XII",
        "latitude": -3.89315,
        "longitude": -45.1759
    },
    {
        "nome": "Piquerobi",
        "latitude": -21.8747,
        "longitude": -51.7282
    },
    {
        "nome": "Piquet Carneiro",
        "latitude": -5.80025,
        "longitude": -39.417
    },
    {
        "nome": "Piquete",
        "latitude": -22.6069,
        "longitude": -45.1869
    },
    {
        "nome": "Piracaia",
        "latitude": -23.0525,
        "longitude": -46.3594
    },
    {
        "nome": "Piracanjuba",
        "latitude": -17.302,
        "longitude": -49.017
    },
    {
        "nome": "Piracema",
        "latitude": -20.5089,
        "longitude": -44.4783
    },
    {
        "nome": "Piracicaba",
        "latitude": -22.7338,
        "longitude": -47.6476
    },
    {
        "nome": "Piracuruca",
        "latitude": -3.93335,
        "longitude": -41.7088
    },
    {
        "nome": "Piraí",
        "latitude": -22.6215,
        "longitude": -43.9081
    },
    {
        "nome": "Piraí do Norte",
        "latitude": -13.759,
        "longitude": -39.3836
    },
    {
        "nome": "Piraí do Sul",
        "latitude": -24.5306,
        "longitude": -49.9433
    },
    {
        "nome": "Piraju",
        "latitude": -23.1981,
        "longitude": -49.3803
    },
    {
        "nome": "Pirajuba",
        "latitude": -19.9092,
        "longitude": -48.7027
    },
    {
        "nome": "Pirajuí",
        "latitude": -21.999,
        "longitude": -49.4608
    },
    {
        "nome": "Pirambu",
        "latitude": -10.7215,
        "longitude": -36.8544
    },
    {
        "nome": "Piranga",
        "latitude": -20.6834,
        "longitude": -43.2967
    },
    {
        "nome": "Pirangi",
        "latitude": -21.0886,
        "longitude": -48.6607
    },
    {
        "nome": "Piranguçu",
        "latitude": -22.5249,
        "longitude": -45.4945
    },
    {
        "nome": "Piranguinho",
        "latitude": -22.395,
        "longitude": -45.5324
    },
    {
        "nome": "Piranhas",
        "latitude": -9.624,
        "longitude": -37.757
    },
    {
        "nome": "Piranhas",
        "latitude": -16.4258,
        "longitude": -51.8235
    },
    {
        "nome": "Pirapemas",
        "latitude": -3.72041,
        "longitude": -44.2216
    },
    {
        "nome": "Pirapetinga",
        "latitude": -21.6554,
        "longitude": -42.3434
    },
    {
        "nome": "Pirapó",
        "latitude": -28.0439,
        "longitude": -55.2001
    },
    {
        "nome": "Pirapora",
        "latitude": -17.3392,
        "longitude": -44.934
    },
    {
        "nome": "Pirapora do Bom Jesus",
        "latitude": -23.3965,
        "longitude": -46.9991
    },
    {
        "nome": "Pirapozinho",
        "latitude": -22.2711,
        "longitude": -51.4976
    },
    {
        "nome": "Piraquara",
        "latitude": -25.4422,
        "longitude": -49.0624
    },
    {
        "nome": "Piraquê",
        "latitude": -6.77302,
        "longitude": -48.2958
    },
    {
        "nome": "Pirassununga",
        "latitude": -21.996,
        "longitude": -47.4257
    },
    {
        "nome": "Piratini",
        "latitude": -31.4473,
        "longitude": -53.0973
    },
    {
        "nome": "Piratininga",
        "latitude": -22.4142,
        "longitude": -49.1339
    },
    {
        "nome": "Piratuba",
        "latitude": -27.4242,
        "longitude": -51.7668
    },
    {
        "nome": "Piraúba",
        "latitude": -21.2825,
        "longitude": -43.0172
    },
    {
        "nome": "Pirenópolis",
        "latitude": -15.8507,
        "longitude": -48.9584
    },
    {
        "nome": "Pires do Rio",
        "latitude": -17.3019,
        "longitude": -48.2768
    },
    {
        "nome": "Pires Ferreira",
        "latitude": -4.23922,
        "longitude": -40.6442
    },
    {
        "nome": "Piripá",
        "latitude": -14.9444,
        "longitude": -41.7168
    },
    {
        "nome": "Piripiri",
        "latitude": -4.27157,
        "longitude": -41.7716
    },
    {
        "nome": "Piritiba",
        "latitude": -11.73,
        "longitude": -40.5587
    },
    {
        "nome": "Pirpirituba",
        "latitude": -6.77922,
        "longitude": -35.4906
    },
    {
        "nome": "Pitanga",
        "latitude": -24.7588,
        "longitude": -51.7596
    },
    {
        "nome": "Pitangueiras",
        "latitude": -21.0132,
        "longitude": -48.221
    },
    {
        "nome": "Pitangueiras",
        "latitude": -23.2281,
        "longitude": -51.5873
    },
    {
        "nome": "Pitangui",
        "latitude": -19.6741,
        "longitude": -44.8964
    },
    {
        "nome": "Pitimbu",
        "latitude": -7.4664,
        "longitude": -34.8151
    },
    {
        "nome": "Pium",
        "latitude": -10.442,
        "longitude": -49.1876
    },
    {
        "nome": "Piúma",
        "latitude": -20.8334,
        "longitude": -40.7268
    },
    {
        "nome": "Piumhi",
        "latitude": -20.4762,
        "longitude": -45.9589
    },
    {
        "nome": "Placas",
        "latitude": -3.86813,
        "longitude": -54.2124
    },
    {
        "nome": "Plácido de Castro",
        "latitude": -10.2806,
        "longitude": -67.1371
    },
    {
        "nome": "Planaltina",
        "latitude": -15.452,
        "longitude": -47.6089
    },
    {
        "nome": "Planaltina do Paraná",
        "latitude": -23.0101,
        "longitude": -52.9162
    },
    {
        "nome": "Planaltino",
        "latitude": -13.2618,
        "longitude": -40.3695
    },
    {
        "nome": "Planalto",
        "latitude": -14.6654,
        "longitude": -40.4718
    },
    {
        "nome": "Planalto",
        "latitude": -27.3297,
        "longitude": -53.0575
    },
    {
        "nome": "Planalto",
        "latitude": -21.0342,
        "longitude": -49.933
    },
    {
        "nome": "Planalto",
        "latitude": -25.7211,
        "longitude": -53.7642
    },
    {
        "nome": "Planalto Alegre",
        "latitude": -27.0704,
        "longitude": -52.867
    },
    {
        "nome": "Planalto da Serra",
        "latitude": -14.6518,
        "longitude": -54.7819
    },
    {
        "nome": "Planura",
        "latitude": -20.1376,
        "longitude": -48.7
    },
    {
        "nome": "Platina",
        "latitude": -22.6371,
        "longitude": -50.2104
    },
    {
        "nome": "Poá",
        "latitude": -23.5333,
        "longitude": -46.3473
    },
    {
        "nome": "Poção",
        "latitude": -8.18726,
        "longitude": -36.7111
    },
    {
        "nome": "Poção de Pedras",
        "latitude": -4.74626,
        "longitude": -44.9432
    },
    {
        "nome": "Pocinhos",
        "latitude": -7.06658,
        "longitude": -36.0668
    },
    {
        "nome": "Poço Branco",
        "latitude": -5.62233,
        "longitude": -35.6635
    },
    {
        "nome": "Poço Dantas",
        "latitude": -6.39876,
        "longitude": -38.4909
    },
    {
        "nome": "Poço das Antas",
        "latitude": -29.4481,
        "longitude": -51.6719
    },
    {
        "nome": "Poço das Trincheiras",
        "latitude": -9.30742,
        "longitude": -37.2889
    },
    {
        "nome": "Poço de José de Moura",
        "latitude": -6.56401,
        "longitude": -38.5111
    },
    {
        "nome": "Poço Fundo",
        "latitude": -21.78,
        "longitude": -45.9658
    },
    {
        "nome": "Poço Redondo",
        "latitude": -9.80616,
        "longitude": -37.6833
    },
    {
        "nome": "Poço Verde",
        "latitude": -10.7151,
        "longitude": -38.1813
    },
    {
        "nome": "Poções",
        "latitude": -14.5234,
        "longitude": -40.3634
    },
    {
        "nome": "Poconé",
        "latitude": -16.266,
        "longitude": -56.6261
    },
    {
        "nome": "Poços de Caldas",
        "latitude": -21.78,
        "longitude": -46.5692
    },
    {
        "nome": "Pocrane",
        "latitude": -19.6208,
        "longitude": -41.6334
    },
    {
        "nome": "Pojuca",
        "latitude": -12.4303,
        "longitude": -38.3374
    },
    {
        "nome": "Poloni",
        "latitude": -20.7829,
        "longitude": -49.8258
    },
    {
        "nome": "Pombal",
        "latitude": -6.76606,
        "longitude": -37.8003
    },
    {
        "nome": "Pombos",
        "latitude": -8.13982,
        "longitude": -35.3967
    },
    {
        "nome": "Pomerode",
        "latitude": -26.7384,
        "longitude": -49.1785
    },
    {
        "nome": "Pompéia",
        "latitude": -22.107,
        "longitude": -50.176
    },
    {
        "nome": "Pompéu",
        "latitude": -19.2257,
        "longitude": -45.0141
    },
    {
        "nome": "Pongaí",
        "latitude": -21.7396,
        "longitude": -49.3604
    },
    {
        "nome": "Ponta de Pedras",
        "latitude": -1.39587,
        "longitude": -48.8661
    },
    {
        "nome": "Ponta Grossa",
        "latitude": -25.0916,
        "longitude": -50.1668
    },
    {
        "nome": "Ponta Porã",
        "latitude": -22.5296,
        "longitude": -55.7203
    },
    {
        "nome": "Pontal",
        "latitude": -21.0216,
        "longitude": -48.0423
    },
    {
        "nome": "Pontal do Araguaia",
        "latitude": -15.9274,
        "longitude": -52.3273
    },
    {
        "nome": "Pontal do Paraná",
        "latitude": -25.6735,
        "longitude": -48.5111
    },
    {
        "nome": "Pontalina",
        "latitude": -17.5225,
        "longitude": -49.4489
    },
    {
        "nome": "Pontalinda",
        "latitude": -20.4396,
        "longitude": -50.5258
    },
    {
        "nome": "Pontão",
        "latitude": -28.0585,
        "longitude": -52.6791
    },
    {
        "nome": "Ponte Alta",
        "latitude": -27.4835,
        "longitude": -50.3764
    },
    {
        "nome": "Ponte Alta do Bom Jesus",
        "latitude": -12.0853,
        "longitude": -46.4825
    },
    {
        "nome": "Ponte Alta do Norte",
        "latitude": -27.1591,
        "longitude": -50.4659
    },
    {
        "nome": "Ponte Alta do Tocantins",
        "latitude": -10.7481,
        "longitude": -47.5276
    },
    {
        "nome": "Ponte Branca",
        "latitude": -16.7584,
        "longitude": -52.8369
    },
    {
        "nome": "Ponte Nova",
        "latitude": -20.4111,
        "longitude": -42.8978
    },
    {
        "nome": "Ponte Preta",
        "latitude": -27.6587,
        "longitude": -52.4848
    },
    {
        "nome": "Ponte Serrada",
        "latitude": -26.8733,
        "longitude": -52.0112
    },
    {
        "nome": "Pontes e Lacerda",
        "latitude": -15.2219,
        "longitude": -59.3435
    },
    {
        "nome": "Pontes Gestal",
        "latitude": -20.1727,
        "longitude": -49.7064
    },
    {
        "nome": "Ponto Belo",
        "latitude": -18.1253,
        "longitude": -40.5458
    },
    {
        "nome": "Ponto Chique",
        "latitude": -16.6282,
        "longitude": -45.0588
    },
    {
        "nome": "Ponto dos Volantes",
        "latitude": -16.7473,
        "longitude": -41.5025
    },
    {
        "nome": "Ponto Novo",
        "latitude": -10.8653,
        "longitude": -40.1311
    },
    {
        "nome": "Populina",
        "latitude": -19.9453,
        "longitude": -50.538
    },
    {
        "nome": "Poranga",
        "latitude": -4.74672,
        "longitude": -40.9205
    },
    {
        "nome": "Porangaba",
        "latitude": -23.1761,
        "longitude": -48.1195
    },
    {
        "nome": "Porangatu",
        "latitude": -13.4391,
        "longitude": -49.1503
    },
    {
        "nome": "Porciúncula",
        "latitude": -20.9632,
        "longitude": -42.0465
    },
    {
        "nome": "Porecatu",
        "latitude": -22.7537,
        "longitude": -51.3795
    },
    {
        "nome": "Portalegre",
        "latitude": -6.02064,
        "longitude": -37.9865
    },
    {
        "nome": "Portão",
        "latitude": -29.7015,
        "longitude": -51.2429
    },
    {
        "nome": "Porteirão",
        "latitude": -17.8143,
        "longitude": -50.1653
    },
    {
        "nome": "Porteiras",
        "latitude": -7.52265,
        "longitude": -39.114
    },
    {
        "nome": "Porteirinha",
        "latitude": -15.7404,
        "longitude": -43.0281
    },
    {
        "nome": "Portel",
        "latitude": -1.93639,
        "longitude": -50.8194
    },
    {
        "nome": "Portelândia",
        "latitude": -17.3554,
        "longitude": -52.6799
    },
    {
        "nome": "Porto",
        "latitude": -3.88815,
        "longitude": -42.6998
    },
    {
        "nome": "Porto Acre",
        "latitude": -9.58138,
        "longitude": -67.5478
    },
    {
        "nome": "Porto Alegre",
        "latitude": -30.0318,
        "longitude": -51.2065
    },
    {
        "nome": "Porto Alegre do Norte",
        "latitude": -10.8761,
        "longitude": -51.6357
    },
    {
        "nome": "Porto Alegre do Piauí",
        "latitude": -6.96423,
        "longitude": -44.1837
    },
    {
        "nome": "Porto Alegre do Tocantins",
        "latitude": -11.618,
        "longitude": -47.0621
    },
    {
        "nome": "Porto Amazonas",
        "latitude": -25.54,
        "longitude": -49.8946
    },
    {
        "nome": "Porto Barreiro",
        "latitude": -25.5477,
        "longitude": -52.4067
    },
    {
        "nome": "Porto Belo",
        "latitude": -27.1586,
        "longitude": -48.5469
    },
    {
        "nome": "Porto Calvo",
        "latitude": -9.05195,
        "longitude": -35.3987
    },
    {
        "nome": "Porto da Folha",
        "latitude": -9.91626,
        "longitude": -37.2842
    },
    {
        "nome": "Porto de Moz",
        "latitude": -1.74691,
        "longitude": -52.2361
    },
    {
        "nome": "Porto de Pedras",
        "latitude": -9.16006,
        "longitude": -35.3049
    },
    {
        "nome": "Porto do Mangue",
        "latitude": -5.05441,
        "longitude": -36.7887
    },
    {
        "nome": "Porto dos Gaúchos",
        "latitude": -11.533,
        "longitude": -57.4132
    },
    {
        "nome": "Porto Esperidião",
        "latitude": -15.857,
        "longitude": -58.4619
    },
    {
        "nome": "Porto Estrela",
        "latitude": -15.3235,
        "longitude": -57.2204
    },
    {
        "nome": "Porto Feliz",
        "latitude": -23.2093,
        "longitude": -47.5251
    },
    {
        "nome": "Porto Ferreira",
        "latitude": -21.8498,
        "longitude": -47.487
    },
    {
        "nome": "Porto Firme",
        "latitude": -20.6642,
        "longitude": -43.0834
    },
    {
        "nome": "Porto Franco",
        "latitude": -6.34149,
        "longitude": -47.3962
    },
    {
        "nome": "Porto Grande",
        "latitude": 0.71243,
        "longitude": -51.4155
    },
    {
        "nome": "Porto Lucena",
        "latitude": -27.8569,
        "longitude": -55.01
    },
    {
        "nome": "Porto Mauá",
        "latitude": -27.5796,
        "longitude": -54.6657
    },
    {
        "nome": "Porto Murtinho",
        "latitude": -21.6981,
        "longitude": -57.8836
    },
    {
        "nome": "Porto Nacional",
        "latitude": -10.7027,
        "longitude": -48.408
    },
    {
        "nome": "Porto Real",
        "latitude": -22.4175,
        "longitude": -44.2952
    },
    {
        "nome": "Porto Real do Colégio",
        "latitude": -10.1849,
        "longitude": -36.8376
    },
    {
        "nome": "Porto Rico",
        "latitude": -22.7747,
        "longitude": -53.2677
    },
    {
        "nome": "Porto Rico do Maranhão",
        "latitude": -1.85925,
        "longitude": -44.5842
    },
    {
        "nome": "Porto Seguro",
        "latitude": -16.4435,
        "longitude": -39.0643
    },
    {
        "nome": "Porto União",
        "latitude": -26.2451,
        "longitude": -51.0759
    },
    {
        "nome": "Porto Velho",
        "latitude": -8.76077,
        "longitude": -63.8999
    },
    {
        "nome": "Porto Vera Cruz",
        "latitude": -27.7405,
        "longitude": -54.8994
    },
    {
        "nome": "Porto Vitória",
        "latitude": -26.1674,
        "longitude": -51.231
    },
    {
        "nome": "Porto Walter",
        "latitude": -8.26323,
        "longitude": -72.7537
    },
    {
        "nome": "Porto Xavier",
        "latitude": -27.9082,
        "longitude": -55.1379
    },
    {
        "nome": "Posse",
        "latitude": -14.0859,
        "longitude": -46.3704
    },
    {
        "nome": "Poté",
        "latitude": -17.8077,
        "longitude": -41.786
    },
    {
        "nome": "Potengi",
        "latitude": -7.09154,
        "longitude": -40.0233
    },
    {
        "nome": "Potim",
        "latitude": -22.8343,
        "longitude": -45.2552
    },
    {
        "nome": "Potiraguá",
        "latitude": -15.5943,
        "longitude": -39.8638
    },
    {
        "nome": "Potirendaba",
        "latitude": -21.0428,
        "longitude": -49.3815
    },
    {
        "nome": "Potiretama",
        "latitude": -5.71287,
        "longitude": -38.1578
    },
    {
        "nome": "Pouso Alegre",
        "latitude": -22.2266,
        "longitude": -45.9389
    },
    {
        "nome": "Pouso Alto",
        "latitude": -22.1964,
        "longitude": -44.9748
    },
    {
        "nome": "Pouso Novo",
        "latitude": -29.1738,
        "longitude": -52.2136
    },
    {
        "nome": "Pouso Redondo",
        "latitude": -27.2567,
        "longitude": -49.9301
    },
    {
        "nome": "Poxoréu",
        "latitude": -15.8299,
        "longitude": -54.4208
    },
    {
        "nome": "Pracinha",
        "latitude": -21.8496,
        "longitude": -51.0868
    },
    {
        "nome": "Pracuúba",
        "latitude": 1.74543,
        "longitude": -50.7892
    },
    {
        "nome": "Prado",
        "latitude": -17.3364,
        "longitude": -39.2227
    },
    {
        "nome": "Prado Ferreira",
        "latitude": -23.0357,
        "longitude": -51.4429
    },
    {
        "nome": "Pradópolis",
        "latitude": -21.3626,
        "longitude": -48.0679
    },
    {
        "nome": "Prados",
        "latitude": -21.0597,
        "longitude": -44.0778
    },
    {
        "nome": "Praia Grande",
        "latitude": -24.0084,
        "longitude": -46.4121
    },
    {
        "nome": "Praia Grande",
        "latitude": -29.1918,
        "longitude": -49.9525
    },
    {
        "nome": "Praia Norte",
        "latitude": -5.39281,
        "longitude": -47.8111
    },
    {
        "nome": "Prainha",
        "latitude": -1.798,
        "longitude": -53.4779
    },
    {
        "nome": "Pranchita",
        "latitude": -26.0209,
        "longitude": -53.7397
    },
    {
        "nome": "Prata",
        "latitude": -19.3086,
        "longitude": -48.9276
    },
    {
        "nome": "Prata",
        "latitude": -7.68826,
        "longitude": -37.0801
    },
    {
        "nome": "Prata do Piauí",
        "latitude": -5.67265,
        "longitude": -42.2046
    },
    {
        "nome": "Pratânia",
        "latitude": -22.8112,
        "longitude": -48.6636
    },
    {
        "nome": "Pratápolis",
        "latitude": -20.7411,
        "longitude": -46.8624
    },
    {
        "nome": "Pratinha",
        "latitude": -19.739,
        "longitude": -46.3755
    },
    {
        "nome": "Presidente Alves",
        "latitude": -22.0999,
        "longitude": -49.4381
    },
    {
        "nome": "Presidente Bernardes",
        "latitude": -22.0082,
        "longitude": -51.5565
    },
    {
        "nome": "Presidente Bernardes",
        "latitude": -20.7656,
        "longitude": -43.1895
    },
    {
        "nome": "Presidente Castello Branco",
        "latitude": -27.2218,
        "longitude": -51.8089
    },
    {
        "nome": "Presidente Castelo Branco",
        "latitude": -23.2782,
        "longitude": -52.1536
    },
    {
        "nome": "Presidente Dutra",
        "latitude": -11.2923,
        "longitude": -41.9843
    },
    {
        "nome": "Presidente Dutra",
        "latitude": -5.2898,
        "longitude": -44.495
    },
    {
        "nome": "Presidente Epitácio",
        "latitude": -21.7651,
        "longitude": -52.1111
    },
    {
        "nome": "Presidente Figueiredo",
        "latitude": -2.02981,
        "longitude": -60.0234
    },
    {
        "nome": "Presidente Getúlio",
        "latitude": -27.0474,
        "longitude": -49.6246
    },
    {
        "nome": "Presidente Jânio Quadros",
        "latitude": -14.6885,
        "longitude": -41.6798
    },
    {
        "nome": "Presidente Juscelino",
        "latitude": -18.6401,
        "longitude": -44.06
    },
    {
        "nome": "Presidente Juscelino",
        "latitude": -2.91872,
        "longitude": -44.0715
    },
    {
        "nome": "Presidente Kennedy",
        "latitude": -8.5406,
        "longitude": -48.5062
    },
    {
        "nome": "Presidente Kennedy",
        "latitude": -21.0964,
        "longitude": -41.0468
    },
    {
        "nome": "Presidente Kubitschek",
        "latitude": -18.6193,
        "longitude": -43.5628
    },
    {
        "nome": "Presidente Lucena",
        "latitude": -29.5175,
        "longitude": -51.1798
    },
    {
        "nome": "Presidente Médici",
        "latitude": -11.169,
        "longitude": -61.8986
    },
    {
        "nome": "Presidente Médici",
        "latitude": -2.38991,
        "longitude": -45.82
    },
    {
        "nome": "Presidente Nereu",
        "latitude": -27.2768,
        "longitude": -49.3889
    },
    {
        "nome": "Presidente Olegário",
        "latitude": -18.4096,
        "longitude": -46.4165
    },
    {
        "nome": "Presidente Prudente",
        "latitude": -22.1207,
        "longitude": -51.3925
    },
    {
        "nome": "Presidente Sarney",
        "latitude": -2.58799,
        "longitude": -45.3595
    },
    {
        "nome": "Presidente Tancredo Neves",
        "latitude": -13.4471,
        "longitude": -39.4203
    },
    {
        "nome": "Presidente Vargas",
        "latitude": -3.40787,
        "longitude": -44.0234
    },
    {
        "nome": "Presidente Venceslau",
        "latitude": -21.8732,
        "longitude": -51.8447
    },
    {
        "nome": "Primavera",
        "latitude": -8.32999,
        "longitude": -35.3544
    },
    {
        "nome": "Primavera",
        "latitude": -0.945439,
        "longitude": -47.1253
    },
    {
        "nome": "Primavera de Rondônia",
        "latitude": -11.8295,
        "longitude": -61.3153
    },
    {
        "nome": "Primavera do Leste",
        "latitude": -15.544,
        "longitude": -54.2811
    },
    {
        "nome": "Primeira Cruz",
        "latitude": -2.50568,
        "longitude": -43.4232
    },
    {
        "nome": "Primeiro de Maio",
        "latitude": -22.8517,
        "longitude": -51.0293
    },
    {
        "nome": "Princesa",
        "latitude": -26.4441,
        "longitude": -53.5994
    },
    {
        "nome": "Princesa Isabel",
        "latitude": -7.73175,
        "longitude": -37.9886
    },
    {
        "nome": "Professor Jamil",
        "latitude": -17.2497,
        "longitude": -49.244
    },
    {
        "nome": "Progresso",
        "latitude": -29.2441,
        "longitude": -52.3197
    },
    {
        "nome": "Promissão",
        "latitude": -21.5356,
        "longitude": -49.8599
    },
    {
        "nome": "Propriá",
        "latitude": -10.2138,
        "longitude": -36.8442
    },
    {
        "nome": "Protásio Alves",
        "latitude": -28.7572,
        "longitude": -51.4757
    },
    {
        "nome": "Prudente de Morais",
        "latitude": -19.4742,
        "longitude": -44.1591
    },
    {
        "nome": "Prudentópolis",
        "latitude": -25.2111,
        "longitude": -50.9754
    },
    {
        "nome": "Pugmil",
        "latitude": -10.424,
        "longitude": -48.8957
    },
    {
        "nome": "Pureza",
        "latitude": -5.46393,
        "longitude": -35.5554
    },
    {
        "nome": "Putinga",
        "latitude": -29.0045,
        "longitude": -52.1569
    },
    {
        "nome": "Puxinanã",
        "latitude": -7.15479,
        "longitude": -35.9543
    },
    {
        "nome": "Quadra",
        "latitude": -23.2993,
        "longitude": -48.0547
    },
    {
        "nome": "Quaraí",
        "latitude": -30.384,
        "longitude": -56.4483
    },
    {
        "nome": "Quartel Geral",
        "latitude": -19.2703,
        "longitude": -45.5569
    },
    {
        "nome": "Quarto Centenário",
        "latitude": -24.2775,
        "longitude": -53.0759
    },
    {
        "nome": "Quatá",
        "latitude": -22.2456,
        "longitude": -50.6966
    },
    {
        "nome": "Quatiguá",
        "latitude": -23.5671,
        "longitude": -49.916
    },
    {
        "nome": "Quatipuru",
        "latitude": -0.899604,
        "longitude": -47.0134
    },
    {
        "nome": "Quatis",
        "latitude": -22.4045,
        "longitude": -44.2597
    },
    {
        "nome": "Quatro Barras",
        "latitude": -25.3673,
        "longitude": -49.0763
    },
    {
        "nome": "Quatro Irmãos",
        "latitude": -27.8257,
        "longitude": -52.4424
    },
    {
        "nome": "Quatro Pontes",
        "latitude": -24.5752,
        "longitude": -53.9759
    },
    {
        "nome": "Quebrangulo",
        "latitude": -9.32001,
        "longitude": -36.4692
    },
    {
        "nome": "Quedas do Iguaçu",
        "latitude": -25.4492,
        "longitude": -52.9102
    },
    {
        "nome": "Queimada Nova",
        "latitude": -8.57064,
        "longitude": -41.4106
    },
    {
        "nome": "Queimadas",
        "latitude": -7.35029,
        "longitude": -35.9031
    },
    {
        "nome": "Queimadas",
        "latitude": -10.9736,
        "longitude": -39.6293
    },
    {
        "nome": "Queimados",
        "latitude": -22.7102,
        "longitude": -43.5518
    },
    {
        "nome": "Queiroz",
        "latitude": -21.7969,
        "longitude": -50.2415
    },
    {
        "nome": "Queluz",
        "latitude": -22.5312,
        "longitude": -44.7781
    },
    {
        "nome": "Queluzito",
        "latitude": -20.7416,
        "longitude": -43.8851
    },
    {
        "nome": "Querência",
        "latitude": -12.6093,
        "longitude": -52.1821
    },
    {
        "nome": "Querência do Norte",
        "latitude": -23.0838,
        "longitude": -53.483
    },
    {
        "nome": "Quevedos",
        "latitude": -29.3504,
        "longitude": -54.0789
    },
    {
        "nome": "Quijingue",
        "latitude": -10.7505,
        "longitude": -39.2137
    },
    {
        "nome": "Quilombo",
        "latitude": -26.7264,
        "longitude": -52.724
    },
    {
        "nome": "Quinta do Sol",
        "latitude": -23.8533,
        "longitude": -52.1309
    },
    {
        "nome": "Quintana",
        "latitude": -22.0692,
        "longitude": -50.307
    },
    {
        "nome": "Quinze de Novembro",
        "latitude": -28.7466,
        "longitude": -53.1011
    },
    {
        "nome": "Quipapá",
        "latitude": -8.81175,
        "longitude": -36.0137
    },
    {
        "nome": "Quirinópolis",
        "latitude": -18.4472,
        "longitude": -50.4547
    },
    {
        "nome": "Quissamã",
        "latitude": -22.1031,
        "longitude": -41.4693
    },
    {
        "nome": "Quitandinha",
        "latitude": -25.8734,
        "longitude": -49.4973
    },
    {
        "nome": "Quiterianópolis",
        "latitude": -5.8425,
        "longitude": -40.7002
    },
    {
        "nome": "Quixabá",
        "latitude": -7.0224,
        "longitude": -37.1458
    },
    {
        "nome": "Quixaba",
        "latitude": -7.70734,
        "longitude": -37.8446
    },
    {
        "nome": "Quixabeira",
        "latitude": -11.4031,
        "longitude": -40.12
    },
    {
        "nome": "Quixadá",
        "latitude": -4.9663,
        "longitude": -39.0155
    },
    {
        "nome": "Quixelô",
        "latitude": -6.24637,
        "longitude": -39.2011
    },
    {
        "nome": "Quixeramobim",
        "latitude": -5.19067,
        "longitude": -39.2889
    },
    {
        "nome": "Quixeré",
        "latitude": -5.07148,
        "longitude": -37.9802
    },
    {
        "nome": "Rafael Fernandes",
        "latitude": -6.18987,
        "longitude": -38.2211
    },
    {
        "nome": "Rafael Godeiro",
        "latitude": -6.07244,
        "longitude": -37.716
    },
    {
        "nome": "Rafael Jambeiro",
        "latitude": -12.4053,
        "longitude": -39.5007
    },
    {
        "nome": "Rafard",
        "latitude": -23.0105,
        "longitude": -47.5318
    },
    {
        "nome": "Ramilândia",
        "latitude": -25.1195,
        "longitude": -54.023
    },
    {
        "nome": "Rancharia",
        "latitude": -22.2269,
        "longitude": -50.893
    },
    {
        "nome": "Rancho Alegre",
        "latitude": -23.0676,
        "longitude": -50.9145
    },
    {
        "nome": "Rancho Alegre D'Oeste",
        "latitude": -24.3065,
        "longitude": -52.9552
    },
    {
        "nome": "Rancho Queimado",
        "latitude": -27.6727,
        "longitude": -49.0191
    },
    {
        "nome": "Raposa",
        "latitude": -2.4254,
        "longitude": -44.0973
    },
    {
        "nome": "Raposos",
        "latitude": -19.9636,
        "longitude": -43.8079
    },
    {
        "nome": "Raul Soares",
        "latitude": -20.1061,
        "longitude": -42.4502
    },
    {
        "nome": "Realeza",
        "latitude": -25.7711,
        "longitude": -53.526
    },
    {
        "nome": "Rebouças",
        "latitude": -25.6232,
        "longitude": -50.6877
    },
    {
        "nome": "Recife",
        "latitude": -8.04666,
        "longitude": -34.8771
    },
    {
        "nome": "Recreio",
        "latitude": -21.5289,
        "longitude": -42.4676
    },
    {
        "nome": "Recursolândia",
        "latitude": -8.7227,
        "longitude": -47.2421
    },
    {
        "nome": "Redenção",
        "latitude": -8.02529,
        "longitude": -50.0317
    },
    {
        "nome": "Redenção",
        "latitude": -4.21587,
        "longitude": -38.7277
    },
    {
        "nome": "Redenção da Serra",
        "latitude": -23.2638,
        "longitude": -45.5422
    },
    {
        "nome": "Redenção do Gurguéia",
        "latitude": -9.47937,
        "longitude": -44.5811
    },
    {
        "nome": "Redentora",
        "latitude": -27.664,
        "longitude": -53.6407
    },
    {
        "nome": "Reduto",
        "latitude": -20.2401,
        "longitude": -41.9848
    },
    {
        "nome": "Regeneração",
        "latitude": -6.23115,
        "longitude": -42.6842
    },
    {
        "nome": "Regente Feijó",
        "latitude": -22.2181,
        "longitude": -51.3055
    },
    {
        "nome": "Reginópolis",
        "latitude": -21.8914,
        "longitude": -49.2268
    },
    {
        "nome": "Registro",
        "latitude": -24.4979,
        "longitude": -47.8449
    },
    {
        "nome": "Relvado",
        "latitude": -29.1164,
        "longitude": -52.0778
    },
    {
        "nome": "Remanso",
        "latitude": -9.61944,
        "longitude": -42.0848
    },
    {
        "nome": "Remígio",
        "latitude": -6.94992,
        "longitude": -35.8011
    },
    {
        "nome": "Renascença",
        "latitude": -26.1588,
        "longitude": -52.9703
    },
    {
        "nome": "Reriutaba",
        "latitude": -4.14191,
        "longitude": -40.5759
    },
    {
        "nome": "Resende",
        "latitude": -22.4705,
        "longitude": -44.4509
    },
    {
        "nome": "Resende Costa",
        "latitude": -20.9171,
        "longitude": -44.2407
    },
    {
        "nome": "Reserva",
        "latitude": -24.6492,
        "longitude": -50.8466
    },
    {
        "nome": "Reserva do Cabaçal",
        "latitude": -15.0743,
        "longitude": -58.4585
    },
    {
        "nome": "Reserva do Iguaçu",
        "latitude": -25.8319,
        "longitude": -52.0272
    },
    {
        "nome": "Resplendor",
        "latitude": -19.3194,
        "longitude": -41.2462
    },
    {
        "nome": "Ressaquinha",
        "latitude": -21.0642,
        "longitude": -43.7598
    },
    {
        "nome": "Restinga",
        "latitude": -20.6056,
        "longitude": -47.4833
    },
    {
        "nome": "Restinga Sêca",
        "latitude": -29.8188,
        "longitude": -53.3807
    },
    {
        "nome": "Retirolândia",
        "latitude": -11.4832,
        "longitude": -39.4234
    },
    {
        "nome": "Riachão",
        "latitude": -6.54269,
        "longitude": -35.661
    },
    {
        "nome": "Riachão",
        "latitude": -7.35819,
        "longitude": -46.6225
    },
    {
        "nome": "Riachão das Neves",
        "latitude": -11.7508,
        "longitude": -44.9143
    },
    {
        "nome": "Riachão do Bacamarte",
        "latitude": -7.25347,
        "longitude": -35.6693
    },
    {
        "nome": "Riachão do Dantas",
        "latitude": -11.0729,
        "longitude": -37.731
    },
    {
        "nome": "Riachão do Jacuípe",
        "latitude": -11.8067,
        "longitude": -39.3818
    },
    {
        "nome": "Riachão do Poço",
        "latitude": -7.14173,
        "longitude": -35.2914
    },
    {
        "nome": "Riachinho",
        "latitude": -6.44005,
        "longitude": -48.1371
    },
    {
        "nome": "Riachinho",
        "latitude": -16.2258,
        "longitude": -45.9888
    },
    {
        "nome": "Riacho da Cruz",
        "latitude": -5.92654,
        "longitude": -37.949
    },
    {
        "nome": "Riacho das Almas",
        "latitude": -8.13742,
        "longitude": -35.8648
    },
    {
        "nome": "Riacho de Santana",
        "latitude": -6.25139,
        "longitude": -38.3116
    },
    {
        "nome": "Riacho de Santana",
        "latitude": -13.6059,
        "longitude": -42.9397
    },
    {
        "nome": "Riacho de Santo Antônio",
        "latitude": -7.68023,
        "longitude": -36.157
    },
    {
        "nome": "Riacho dos Cavalos",
        "latitude": -6.44067,
        "longitude": -37.6483
    },
    {
        "nome": "Riacho dos Machados",
        "latitude": -16.0091,
        "longitude": -43.0488
    },
    {
        "nome": "Riacho Frio",
        "latitude": -10.1244,
        "longitude": -44.9503
    },
    {
        "nome": "Riachuelo",
        "latitude": -5.82156,
        "longitude": -35.8215
    },
    {
        "nome": "Riachuelo",
        "latitude": -10.735,
        "longitude": -37.1966
    },
    {
        "nome": "Rialma",
        "latitude": -15.3145,
        "longitude": -49.5814
    },
    {
        "nome": "Rianápolis",
        "latitude": -15.4456,
        "longitude": -49.5114
    },
    {
        "nome": "Ribamar Fiquene",
        "latitude": -5.93067,
        "longitude": -47.3888
    },
    {
        "nome": "Ribas do Rio Pardo",
        "latitude": -20.4445,
        "longitude": -53.7588
    },
    {
        "nome": "Ribeira",
        "latitude": -24.6517,
        "longitude": -49.0044
    },
    {
        "nome": "Ribeira do Amparo",
        "latitude": -11.0421,
        "longitude": -38.4242
    },
    {
        "nome": "Ribeira do Piauí",
        "latitude": -7.69028,
        "longitude": -42.7128
    },
    {
        "nome": "Ribeira do Pombal",
        "latitude": -10.8373,
        "longitude": -38.5382
    },
    {
        "nome": "Ribeirão",
        "latitude": -8.50957,
        "longitude": -35.3698
    },
    {
        "nome": "Ribeirão Bonito",
        "latitude": -22.0685,
        "longitude": -48.182
    },
    {
        "nome": "Ribeirão Branco",
        "latitude": -24.2206,
        "longitude": -48.7635
    },
    {
        "nome": "Ribeirão Cascalheira",
        "latitude": -12.9367,
        "longitude": -51.8244
    },
    {
        "nome": "Ribeirão Claro",
        "latitude": -23.1941,
        "longitude": -49.7597
    },
    {
        "nome": "Ribeirão Corrente",
        "latitude": -20.4579,
        "longitude": -47.5904
    },
    {
        "nome": "Ribeirão das Neves",
        "latitude": -19.7621,
        "longitude": -44.0844
    },
    {
        "nome": "Ribeirão do Largo",
        "latitude": -15.4508,
        "longitude": -40.7441
    },
    {
        "nome": "Ribeirão do Pinhal",
        "latitude": -23.4091,
        "longitude": -50.3601
    },
    {
        "nome": "Ribeirão do Sul",
        "latitude": -22.789,
        "longitude": -49.933
    },
    {
        "nome": "Ribeirão dos Índios",
        "latitude": -21.8382,
        "longitude": -51.6103
    },
    {
        "nome": "Ribeirão Grande",
        "latitude": -24.1011,
        "longitude": -48.3679
    },
    {
        "nome": "Ribeirão Pires",
        "latitude": -23.7067,
        "longitude": -46.4058
    },
    {
        "nome": "Ribeirão Preto",
        "latitude": -21.1699,
        "longitude": -47.8099
    },
    {
        "nome": "Ribeirão Vermelho",
        "latitude": -21.1879,
        "longitude": -45.0637
    },
    {
        "nome": "Ribeirãozinho",
        "latitude": -16.4856,
        "longitude": -52.6924
    },
    {
        "nome": "Ribeiro Gonçalves",
        "latitude": -7.55651,
        "longitude": -45.2447
    },
    {
        "nome": "Ribeirópolis",
        "latitude": -10.5357,
        "longitude": -37.438
    },
    {
        "nome": "Rifaina",
        "latitude": -20.0803,
        "longitude": -47.4291
    },
    {
        "nome": "Rincão",
        "latitude": -21.5894,
        "longitude": -48.0728
    },
    {
        "nome": "Rinópolis",
        "latitude": -21.7284,
        "longitude": -50.7239
    },
    {
        "nome": "Rio Acima",
        "latitude": -20.0876,
        "longitude": -43.7878
    },
    {
        "nome": "Rio Azul",
        "latitude": -25.7306,
        "longitude": -50.7985
    },
    {
        "nome": "Rio Bananal",
        "latitude": -19.2719,
        "longitude": -40.3366
    },
    {
        "nome": "Rio Bom",
        "latitude": -23.7606,
        "longitude": -51.4122
    },
    {
        "nome": "Rio Bonito",
        "latitude": -22.7181,
        "longitude": -42.6276
    },
    {
        "nome": "Rio Bonito do Iguaçu",
        "latitude": -25.4874,
        "longitude": -52.5292
    },
    {
        "nome": "Rio Branco",
        "latitude": -15.2483,
        "longitude": -58.1259
    },
    {
        "nome": "Rio Branco",
        "latitude": -9.97499,
        "longitude": -67.8243
    },
    {
        "nome": "Rio Branco do Ivaí",
        "latitude": -24.3244,
        "longitude": -51.3187
    },
    {
        "nome": "Rio Branco do Sul",
        "latitude": -25.1892,
        "longitude": -49.3115
    },
    {
        "nome": "Rio Brilhante",
        "latitude": -21.8033,
        "longitude": -54.5427
    },
    {
        "nome": "Rio Casca",
        "latitude": -20.2285,
        "longitude": -42.6462
    },
    {
        "nome": "Rio Claro",
        "latitude": -22.72,
        "longitude": -44.1419
    },
    {
        "nome": "Rio Claro",
        "latitude": -22.3984,
        "longitude": -47.5546
    },
    {
        "nome": "Rio Crespo",
        "latitude": -9.69965,
        "longitude": -62.9011
    },
    {
        "nome": "Rio da Conceição",
        "latitude": -11.3949,
        "longitude": -46.8847
    },
    {
        "nome": "Rio das Antas",
        "latitude": -26.8946,
        "longitude": -51.0674
    },
    {
        "nome": "Rio das Flores",
        "latitude": -22.1692,
        "longitude": -43.5856
    },
    {
        "nome": "Rio das Ostras",
        "latitude": -22.5174,
        "longitude": -41.9475
    },
    {
        "nome": "Rio das Pedras",
        "latitude": -22.8417,
        "longitude": -47.6047
    },
    {
        "nome": "Rio de Contas",
        "latitude": -13.5852,
        "longitude": -41.8048
    },
    {
        "nome": "Rio de Janeiro",
        "latitude": -22.9129,
        "longitude": -43.2003
    },
    {
        "nome": "Rio do Antônio",
        "latitude": -14.4071,
        "longitude": -42.0721
    },
    {
        "nome": "Rio do Campo",
        "latitude": -26.9452,
        "longitude": -50.136
    },
    {
        "nome": "Rio do Fogo",
        "latitude": -5.2765,
        "longitude": -35.3794
    },
    {
        "nome": "Rio do Oeste",
        "latitude": -27.1952,
        "longitude": -49.7989
    },
    {
        "nome": "Rio do Pires",
        "latitude": -13.1185,
        "longitude": -42.2902
    },
    {
        "nome": "Rio do Prado",
        "latitude": -16.6056,
        "longitude": -40.5714
    },
    {
        "nome": "Rio do Sul",
        "latitude": -27.2156,
        "longitude": -49.643
    },
    {
        "nome": "Rio Doce",
        "latitude": -20.2412,
        "longitude": -42.8995
    },
    {
        "nome": "Rio dos Bois",
        "latitude": -9.34425,
        "longitude": -48.5245
    },
    {
        "nome": "Rio dos Cedros",
        "latitude": -26.7398,
        "longitude": -49.2718
    },
    {
        "nome": "Rio dos Índios",
        "latitude": -27.2973,
        "longitude": -52.8417
    },
    {
        "nome": "Rio Espera",
        "latitude": -20.855,
        "longitude": -43.4721
    },
    {
        "nome": "Rio Formoso",
        "latitude": -8.6592,
        "longitude": -35.1532
    },
    {
        "nome": "Rio Fortuna",
        "latitude": -28.1244,
        "longitude": -49.1068
    },
    {
        "nome": "Rio Grande",
        "latitude": -32.0349,
        "longitude": -52.1071
    },
    {
        "nome": "Rio Grande da Serra",
        "latitude": -23.7437,
        "longitude": -46.3971
    },
    {
        "nome": "Rio Grande do Piauí",
        "latitude": -7.78029,
        "longitude": -43.1369
    },
    {
        "nome": "Rio Largo",
        "latitude": -9.47783,
        "longitude": -35.8394
    },
    {
        "nome": "Rio Manso",
        "latitude": -20.2666,
        "longitude": -44.3069
    },
    {
        "nome": "Rio Maria",
        "latitude": -7.31236,
        "longitude": -50.0379
    },
    {
        "nome": "Rio Negrinho",
        "latitude": -26.2591,
        "longitude": -49.5177
    },
    {
        "nome": "Rio Negro",
        "latitude": -19.447,
        "longitude": -54.9859
    },
    {
        "nome": "Rio Negro",
        "latitude": -26.095,
        "longitude": -49.7982
    },
    {
        "nome": "Rio Novo",
        "latitude": -21.4649,
        "longitude": -43.1168
    },
    {
        "nome": "Rio Novo do Sul",
        "latitude": -20.8556,
        "longitude": -40.9388
    },
    {
        "nome": "Rio Paranaíba",
        "latitude": -19.1861,
        "longitude": -46.2455
    },
    {
        "nome": "Rio Pardo",
        "latitude": -29.988,
        "longitude": -52.3711
    },
    {
        "nome": "Rio Pardo de Minas",
        "latitude": -15.616,
        "longitude": -42.5405
    },
    {
        "nome": "Rio Piracicaba",
        "latitude": -19.9284,
        "longitude": -43.1829
    },
    {
        "nome": "Rio Pomba",
        "latitude": -21.2712,
        "longitude": -43.1696
    },
    {
        "nome": "Rio Preto",
        "latitude": -22.0861,
        "longitude": -43.8293
    },
    {
        "nome": "Rio Preto da Eva",
        "latitude": -2.7045,
        "longitude": -59.6858
    },
    {
        "nome": "Rio Quente",
        "latitude": -17.774,
        "longitude": -48.7725
    },
    {
        "nome": "Rio Real",
        "latitude": -11.4814,
        "longitude": -37.9332
    },
    {
        "nome": "Rio Rufino",
        "latitude": -27.8592,
        "longitude": -49.7754
    },
    {
        "nome": "Rio Sono",
        "latitude": -9.35002,
        "longitude": -47.888
    },
    {
        "nome": "Rio Tinto",
        "latitude": -6.80383,
        "longitude": -35.0776
    },
    {
        "nome": "Rio Verde",
        "latitude": -17.7923,
        "longitude": -50.9192
    },
    {
        "nome": "Rio Verde de Mato Grosso",
        "latitude": -18.9249,
        "longitude": -54.8434
    },
    {
        "nome": "Rio Vermelho",
        "latitude": -18.2922,
        "longitude": -43.0018
    },
    {
        "nome": "Riolândia",
        "latitude": -19.9868,
        "longitude": -49.6836
    },
    {
        "nome": "Riozinho",
        "latitude": -29.639,
        "longitude": -50.4488
    },
    {
        "nome": "Riqueza",
        "latitude": -27.0653,
        "longitude": -53.3265
    },
    {
        "nome": "Ritápolis",
        "latitude": -21.0276,
        "longitude": -44.3204
    },
    {
        "nome": "Riversul",
        "latitude": -23.829,
        "longitude": -49.429
    },
    {
        "nome": "Roca Sales",
        "latitude": -29.2884,
        "longitude": -51.8658
    },
    {
        "nome": "Rochedo",
        "latitude": -19.9565,
        "longitude": -54.8848
    },
    {
        "nome": "Rochedo de Minas",
        "latitude": -21.6284,
        "longitude": -43.0165
    },
    {
        "nome": "Rodeio",
        "latitude": -26.9243,
        "longitude": -49.3649
    },
    {
        "nome": "Rodeio Bonito",
        "latitude": -27.4742,
        "longitude": -53.1706
    },
    {
        "nome": "Rodeiro",
        "latitude": -21.2035,
        "longitude": -42.8586
    },
    {
        "nome": "Rodelas",
        "latitude": -8.85021,
        "longitude": -38.78
    },
    {
        "nome": "Rodolfo Fernandes",
        "latitude": -5.78393,
        "longitude": -38.0579
    },
    {
        "nome": "Rodrigues Alves",
        "latitude": -7.73864,
        "longitude": -72.661
    },
    {
        "nome": "Rolador",
        "latitude": -28.2566,
        "longitude": -54.8186
    },
    {
        "nome": "Rolândia",
        "latitude": -23.3101,
        "longitude": -51.3659
    },
    {
        "nome": "Rolante",
        "latitude": -29.6462,
        "longitude": -50.5819
    },
    {
        "nome": "Rolim de Moura",
        "latitude": -11.7271,
        "longitude": -61.7714
    },
    {
        "nome": "Romaria",
        "latitude": -18.8838,
        "longitude": -47.5782
    },
    {
        "nome": "Romelândia",
        "latitude": -26.6809,
        "longitude": -53.3172
    },
    {
        "nome": "Roncador",
        "latitude": -24.5958,
        "longitude": -52.2716
    },
    {
        "nome": "Ronda Alta",
        "latitude": -27.7758,
        "longitude": -52.8056
    },
    {
        "nome": "Rondinha",
        "latitude": -27.8315,
        "longitude": -52.9081
    },
    {
        "nome": "Rondolândia",
        "latitude": -10.8376,
        "longitude": -61.4697
    },
    {
        "nome": "Rondon",
        "latitude": -23.412,
        "longitude": -52.7659
    },
    {
        "nome": "Rondon do Pará",
        "latitude": -4.77793,
        "longitude": -48.067
    },
    {
        "nome": "Rondonópolis",
        "latitude": -16.4673,
        "longitude": -54.6372
    },
    {
        "nome": "Roque Gonzales",
        "latitude": -28.1297,
        "longitude": -55.0266
    },
    {
        "nome": "Rorainópolis",
        "latitude": 0.939956,
        "longitude": -60.4389
    },
    {
        "nome": "Rosana",
        "latitude": -22.5782,
        "longitude": -53.0603
    },
    {
        "nome": "Rosário",
        "latitude": -2.93444,
        "longitude": -44.2531
    },
    {
        "nome": "Rosário da Limeira",
        "latitude": -20.9812,
        "longitude": -42.5112
    },
    {
        "nome": "Rosário do Catete",
        "latitude": -10.6904,
        "longitude": -37.0357
    },
    {
        "nome": "Rosário do Ivaí",
        "latitude": -24.2682,
        "longitude": -51.272
    },
    {
        "nome": "Rosário do Sul",
        "latitude": -30.2515,
        "longitude": -54.9221
    },
    {
        "nome": "Rosário Oeste",
        "latitude": -14.8259,
        "longitude": -56.4236
    },
    {
        "nome": "Roseira",
        "latitude": -22.8938,
        "longitude": -45.307
    },
    {
        "nome": "Roteiro",
        "latitude": -9.83503,
        "longitude": -35.9782
    },
    {
        "nome": "Rubelita",
        "latitude": -16.4053,
        "longitude": -42.261
    },
    {
        "nome": "Rubiácea",
        "latitude": -21.3006,
        "longitude": -50.7296
    },
    {
        "nome": "Rubiataba",
        "latitude": -15.1617,
        "longitude": -49.8048
    },
    {
        "nome": "Rubim",
        "latitude": -16.3775,
        "longitude": -40.5397
    },
    {
        "nome": "Rubinéia",
        "latitude": -20.1759,
        "longitude": -51.007
    },
    {
        "nome": "Rurópolis",
        "latitude": -4.10028,
        "longitude": -54.9092
    },
    {
        "nome": "Russas",
        "latitude": -4.92673,
        "longitude": -37.9721
    },
    {
        "nome": "Ruy Barbosa",
        "latitude": -5.88745,
        "longitude": -35.933
    },
    {
        "nome": "Ruy Barbosa",
        "latitude": -12.2816,
        "longitude": -40.4931
    },
    {
        "nome": "Sabará",
        "latitude": -19.884,
        "longitude": -43.8263
    },
    {
        "nome": "Sabáudia",
        "latitude": -23.3155,
        "longitude": -51.555
    },
    {
        "nome": "Sabino",
        "latitude": -21.4593,
        "longitude": -49.5755
    },
    {
        "nome": "Sabinópolis",
        "latitude": -18.6653,
        "longitude": -43.0752
    },
    {
        "nome": "Saboeiro",
        "latitude": -6.5346,
        "longitude": -39.9017
    },
    {
        "nome": "Sacramento",
        "latitude": -19.8622,
        "longitude": -47.4508
    },
    {
        "nome": "Sagrada Família",
        "latitude": -27.7085,
        "longitude": -53.1351
    },
    {
        "nome": "Sagres",
        "latitude": -21.8823,
        "longitude": -50.9594
    },
    {
        "nome": "Sairé",
        "latitude": -8.32864,
        "longitude": -35.6967
    },
    {
        "nome": "Saldanha Marinho",
        "latitude": -28.3941,
        "longitude": -53.097
    },
    {
        "nome": "Sales",
        "latitude": -21.3427,
        "longitude": -49.4897
    },
    {
        "nome": "Sales Oliveira",
        "latitude": -20.7696,
        "longitude": -47.8369
    },
    {
        "nome": "Salesópolis",
        "latitude": -23.5288,
        "longitude": -45.8465
    },
    {
        "nome": "Salete",
        "latitude": -26.9798,
        "longitude": -49.9988
    },
    {
        "nome": "Salgadinho",
        "latitude": -7.10098,
        "longitude": -36.8458
    },
    {
        "nome": "Salgadinho",
        "latitude": -7.9269,
        "longitude": -35.6503
    },
    {
        "nome": "Salgado",
        "latitude": -11.0288,
        "longitude": -37.4804
    },
    {
        "nome": "Salgado de São Félix",
        "latitude": -7.35337,
        "longitude": -35.4305
    },
    {
        "nome": "Salgado Filho",
        "latitude": -26.1777,
        "longitude": -53.3631
    },
    {
        "nome": "Salgueiro",
        "latitude": -8.07373,
        "longitude": -39.1247
    },
    {
        "nome": "Salinas",
        "latitude": -16.1753,
        "longitude": -42.2964
    },
    {
        "nome": "Salinas da Margarida",
        "latitude": -12.873,
        "longitude": -38.7562
    },
    {
        "nome": "Salinópolis",
        "latitude": -0.630815,
        "longitude": -47.3465
    },
    {
        "nome": "Salitre",
        "latitude": -7.28398,
        "longitude": -40.45
    },
    {
        "nome": "Salmourão",
        "latitude": -21.6267,
        "longitude": -50.8614
    },
    {
        "nome": "Saloá",
        "latitude": -8.9723,
        "longitude": -36.691
    },
    {
        "nome": "Saltinho",
        "latitude": -26.6049,
        "longitude": -53.0578
    },
    {
        "nome": "Saltinho",
        "latitude": -22.8442,
        "longitude": -47.6754
    },
    {
        "nome": "Salto",
        "latitude": -23.1996,
        "longitude": -47.2931
    },
    {
        "nome": "Salto da Divisa",
        "latitude": -16.0063,
        "longitude": -39.9391
    },
    {
        "nome": "Salto de Pirapora",
        "latitude": -23.6474,
        "longitude": -47.5743
    },
    {
        "nome": "Salto do Céu",
        "latitude": -15.1303,
        "longitude": -58.1317
    },
    {
        "nome": "Salto do Itararé",
        "latitude": -23.6074,
        "longitude": -49.6354
    },
    {
        "nome": "Salto do Jacuí",
        "latitude": -29.0951,
        "longitude": -53.2133
    },
    {
        "nome": "Salto do Lontra",
        "latitude": -25.7813,
        "longitude": -53.3135
    },
    {
        "nome": "Salto Grande",
        "latitude": -22.8894,
        "longitude": -49.9831
    },
    {
        "nome": "Salto Veloso",
        "latitude": -26.903,
        "longitude": -51.4043
    },
    {
        "nome": "Salvador",
        "latitude": -12.9718,
        "longitude": -38.5011
    },
    {
        "nome": "Salvador das Missões",
        "latitude": -28.1233,
        "longitude": -54.8373
    },
    {
        "nome": "Salvador do Sul",
        "latitude": -29.4386,
        "longitude": -51.5077
    },
    {
        "nome": "Salvaterra",
        "latitude": -0.758444,
        "longitude": -48.5139
    },
    {
        "nome": "Sambaíba",
        "latitude": -7.13447,
        "longitude": -45.3515
    },
    {
        "nome": "Sampaio",
        "latitude": -5.35423,
        "longitude": -47.8782
    },
    {
        "nome": "Sananduva",
        "latitude": -27.947,
        "longitude": -51.8079
    },
    {
        "nome": "Sanclerlândia",
        "latitude": -16.197,
        "longitude": -50.3124
    },
    {
        "nome": "Sandolândia",
        "latitude": -12.538,
        "longitude": -49.9242
    },
    {
        "nome": "Sandovalina",
        "latitude": -22.4551,
        "longitude": -51.7648
    },
    {
        "nome": "Sangão",
        "latitude": -28.6326,
        "longitude": -49.1322
    },
    {
        "nome": "Sanharó",
        "latitude": -8.36097,
        "longitude": -36.5696
    },
    {
        "nome": "Sant'Ana do Livramento",
        "latitude": -30.8773,
        "longitude": -55.5392
    },
    {
        "nome": "Santa Adélia",
        "latitude": -21.2427,
        "longitude": -48.8063
    },
    {
        "nome": "Santa Albertina",
        "latitude": -20.0311,
        "longitude": -50.7297
    },
    {
        "nome": "Santa Amélia",
        "latitude": -23.2654,
        "longitude": -50.4288
    },
    {
        "nome": "Santa Bárbara",
        "latitude": -11.9515,
        "longitude": -38.9681
    },
    {
        "nome": "Santa Bárbara",
        "latitude": -19.9604,
        "longitude": -43.4101
    },
    {
        "nome": "Santa Bárbara d'Oeste",
        "latitude": -22.7553,
        "longitude": -47.4143
    },
    {
        "nome": "Santa Bárbara de Goiás",
        "latitude": -16.5714,
        "longitude": -49.6954
    },
    {
        "nome": "Santa Bárbara do Leste",
        "latitude": -19.9753,
        "longitude": -42.1457
    },
    {
        "nome": "Santa Bárbara do Monte Verde",
        "latitude": -21.9592,
        "longitude": -43.7027
    },
    {
        "nome": "Santa Bárbara do Pará",
        "latitude": -1.19219,
        "longitude": -48.238
    },
    {
        "nome": "Santa Bárbara do Sul",
        "latitude": -28.3653,
        "longitude": -53.251
    },
    {
        "nome": "Santa Bárbara do Tugúrio",
        "latitude": -21.2431,
        "longitude": -43.5607
    },
    {
        "nome": "Santa Branca",
        "latitude": -23.3933,
        "longitude": -45.8875
    },
    {
        "nome": "Santa Brígida",
        "latitude": -9.73227,
        "longitude": -38.1209
    },
    {
        "nome": "Santa Carmem",
        "latitude": -11.9125,
        "longitude": -55.2263
    },
    {
        "nome": "Santa Cecília",
        "latitude": -26.9592,
        "longitude": -50.4252
    },
    {
        "nome": "Santa Cecília",
        "latitude": -7.7389,
        "longitude": -35.8764
    },
    {
        "nome": "Santa Cecília do Pavão",
        "latitude": -23.5201,
        "longitude": -50.7835
    },
    {
        "nome": "Santa Cecília do Sul",
        "latitude": -28.1609,
        "longitude": -51.9279
    },
    {
        "nome": "Santa Clara d'Oeste",
        "latitude": -20.09,
        "longitude": -50.9491
    },
    {
        "nome": "Santa Clara do Sul",
        "latitude": -29.4747,
        "longitude": -52.0843
    },
    {
        "nome": "Santa Cruz",
        "latitude": -6.22475,
        "longitude": -36.0193
    },
    {
        "nome": "Santa Cruz",
        "latitude": -6.5237,
        "longitude": -38.0617
    },
    {
        "nome": "Santa Cruz",
        "latitude": -8.24153,
        "longitude": -40.3434
    },
    {
        "nome": "Santa Cruz Cabrália",
        "latitude": -16.2825,
        "longitude": -39.0295
    },
    {
        "nome": "Santa Cruz da Baixa Verde",
        "latitude": -7.81339,
        "longitude": -38.1476
    },
    {
        "nome": "Santa Cruz da Conceição",
        "latitude": -22.1405,
        "longitude": -47.4512
    },
    {
        "nome": "Santa Cruz da Esperança",
        "latitude": -21.2951,
        "longitude": -47.4304
    },
    {
        "nome": "Santa Cruz da Vitória",
        "latitude": -14.964,
        "longitude": -39.8115
    },
    {
        "nome": "Santa Cruz das Palmeiras",
        "latitude": -21.8235,
        "longitude": -47.248
    },
    {
        "nome": "Santa Cruz de Goiás",
        "latitude": -17.3155,
        "longitude": -48.4809
    },
    {
        "nome": "Santa Cruz de Minas",
        "latitude": -21.1241,
        "longitude": -44.2202
    },
    {
        "nome": "Santa Cruz de Monte Castelo",
        "latitude": -22.9582,
        "longitude": -53.2949
    },
    {
        "nome": "Santa Cruz de Salinas",
        "latitude": -16.0967,
        "longitude": -41.7418
    },
    {
        "nome": "Santa Cruz do Arari",
        "latitude": -0.661019,
        "longitude": -49.1771
    },
    {
        "nome": "Santa Cruz do Capibaribe",
        "latitude": -7.94802,
        "longitude": -36.2061
    },
    {
        "nome": "Santa Cruz do Escalvado",
        "latitude": -20.2372,
        "longitude": -42.8169
    },
    {
        "nome": "Santa Cruz do Piauí",
        "latitude": -7.1785,
        "longitude": -41.7609
    },
    {
        "nome": "Santa Cruz do Rio Pardo",
        "latitude": -22.8988,
        "longitude": -49.6354
    },
    {
        "nome": "Santa Cruz do Sul",
        "latitude": -29.722,
        "longitude": -52.4343
    },
    {
        "nome": "Santa Cruz do Xingu",
        "latitude": -10.1532,
        "longitude": -52.3953
    },
    {
        "nome": "Santa Cruz dos Milagres",
        "latitude": -5.80581,
        "longitude": -41.9506
    },
    {
        "nome": "Santa Efigênia de Minas",
        "latitude": -18.8235,
        "longitude": -42.4388
    },
    {
        "nome": "Santa Ernestina",
        "latitude": -21.4618,
        "longitude": -48.3953
    },
    {
        "nome": "Santa Fé",
        "latitude": -23.04,
        "longitude": -51.808
    },
    {
        "nome": "Santa Fé de Goiás",
        "latitude": -15.7664,
        "longitude": -51.1037
    },
    {
        "nome": "Santa Fé de Minas",
        "latitude": -16.6859,
        "longitude": -45.4102
    },
    {
        "nome": "Santa Fé do Araguaia",
        "latitude": -7.15803,
        "longitude": -48.7165
    },
    {
        "nome": "Santa Fé do Sul",
        "latitude": -20.2083,
        "longitude": -50.932
    },
    {
        "nome": "Santa Filomena",
        "latitude": -9.11228,
        "longitude": -45.9116
    },
    {
        "nome": "Santa Filomena",
        "latitude": -8.16688,
        "longitude": -40.6079
    },
    {
        "nome": "Santa Filomena do Maranhão",
        "latitude": -5.49671,
        "longitude": -44.5638
    },
    {
        "nome": "Santa Gertrudes",
        "latitude": -22.4572,
        "longitude": -47.5272
    },
    {
        "nome": "Santa Helena",
        "latitude": -24.8585,
        "longitude": -54.336
    },
    {
        "nome": "Santa Helena",
        "latitude": -26.937,
        "longitude": -53.6214
    },
    {
        "nome": "Santa Helena",
        "latitude": -2.24426,
        "longitude": -45.29
    },
    {
        "nome": "Santa Helena",
        "latitude": -6.7176,
        "longitude": -38.6427
    },
    {
        "nome": "Santa Helena de Goiás",
        "latitude": -17.8115,
        "longitude": -50.5977
    },
    {
        "nome": "Santa Helena de Minas",
        "latitude": -16.9707,
        "longitude": -40.6727
    },
    {
        "nome": "Santa Inês",
        "latitude": -13.2793,
        "longitude": -39.814
    },
    {
        "nome": "Santa Inês",
        "latitude": -22.6376,
        "longitude": -51.9024
    },
    {
        "nome": "Santa Inês",
        "latitude": -7.621,
        "longitude": -38.554
    },
    {
        "nome": "Santa Inês",
        "latitude": -3.65112,
        "longitude": -45.3774
    },
    {
        "nome": "Santa Isabel",
        "latitude": -23.3172,
        "longitude": -46.2237
    },
    {
        "nome": "Santa Isabel",
        "latitude": -15.2958,
        "longitude": -49.4259
    },
    {
        "nome": "Santa Isabel do Ivaí",
        "latitude": -23.0025,
        "longitude": -53.1989
    },
    {
        "nome": "Santa Isabel do Rio Negro",
        "latitude": -0.410824,
        "longitude": -65.0092
    },
    {
        "nome": "Santa Izabel do Oeste",
        "latitude": -25.8217,
        "longitude": -53.4801
    },
    {
        "nome": "Santa Izabel do Pará",
        "latitude": -1.29686,
        "longitude": -48.1606
    },
    {
        "nome": "Santa Juliana",
        "latitude": -19.3108,
        "longitude": -47.5322
    },
    {
        "nome": "Santa Leopoldina",
        "latitude": -20.0999,
        "longitude": -40.527
    },
    {
        "nome": "Santa Lúcia",
        "latitude": -21.685,
        "longitude": -48.0885
    },
    {
        "nome": "Santa Lúcia",
        "latitude": -25.4104,
        "longitude": -53.5638
    },
    {
        "nome": "Santa Luz",
        "latitude": -8.9488,
        "longitude": -44.1296
    },
    {
        "nome": "Santa Luzia",
        "latitude": -4.06873,
        "longitude": -45.69
    },
    {
        "nome": "Santa Luzia",
        "latitude": -15.4342,
        "longitude": -39.3287
    },
    {
        "nome": "Santa Luzia",
        "latitude": -19.7548,
        "longitude": -43.8497
    },
    {
        "nome": "Santa Luzia",
        "latitude": -6.86092,
        "longitude": -36.9178
    },
    {
        "nome": "Santa Luzia D'Oeste",
        "latitude": -11.9074,
        "longitude": -61.7777
    },
    {
        "nome": "Santa Luzia do Itanhy",
        "latitude": -11.3536,
        "longitude": -37.4586
    },
    {
        "nome": "Santa Luzia do Norte",
        "latitude": -9.6037,
        "longitude": -35.8232
    },
    {
        "nome": "Santa Luzia do Pará",
        "latitude": -1.52147,
        "longitude": -46.9008
    },
    {
        "nome": "Santa Luzia do Paruá",
        "latitude": -2.51123,
        "longitude": -45.7801
    },
    {
        "nome": "Santa Margarida",
        "latitude": -20.3839,
        "longitude": -42.2519
    },
    {
        "nome": "Santa Margarida do Sul",
        "latitude": -30.3393,
        "longitude": -54.0817
    },
    {
        "nome": "Santa Maria",
        "latitude": -29.6868,
        "longitude": -53.8149
    },
    {
        "nome": "Santa Maria",
        "latitude": -5.83802,
        "longitude": -35.6914
    },
    {
        "nome": "Santa Maria da Boa Vista",
        "latitude": -8.79766,
        "longitude": -39.8241
    },
    {
        "nome": "Santa Maria da Serra",
        "latitude": -22.5661,
        "longitude": -48.1593
    },
    {
        "nome": "Santa Maria da Vitória",
        "latitude": -13.3859,
        "longitude": -44.2011
    },
    {
        "nome": "Santa Maria das Barreiras",
        "latitude": -8.85784,
        "longitude": -49.7215
    },
    {
        "nome": "Santa Maria de Itabira",
        "latitude": -19.4431,
        "longitude": -43.1064
    },
    {
        "nome": "Santa Maria de Jetibá",
        "latitude": -20.0253,
        "longitude": -40.7439
    },
    {
        "nome": "Santa Maria do Cambucá",
        "latitude": -7.83676,
        "longitude": -35.8941
    },
    {
        "nome": "Santa Maria do Herval",
        "latitude": -29.4902,
        "longitude": -50.9919
    },
    {
        "nome": "Santa Maria do Oeste",
        "latitude": -24.9377,
        "longitude": -51.8696
    },
    {
        "nome": "Santa Maria do Pará",
        "latitude": -1.35392,
        "longitude": -47.5712
    },
    {
        "nome": "Santa Maria do Salto",
        "latitude": -16.2479,
        "longitude": -40.1512
    },
    {
        "nome": "Santa Maria do Suaçuí",
        "latitude": -18.1896,
        "longitude": -42.4139
    },
    {
        "nome": "Santa Maria do Tocantins",
        "latitude": -8.8046,
        "longitude": -47.7887
    },
    {
        "nome": "Santa Maria Madalena",
        "latitude": -21.9547,
        "longitude": -42.0098
    },
    {
        "nome": "Santa Mariana",
        "latitude": -23.1465,
        "longitude": -50.5167
    },
    {
        "nome": "Santa Mercedes",
        "latitude": -21.3495,
        "longitude": -51.7564
    },
    {
        "nome": "Santa Mônica",
        "latitude": -23.108,
        "longitude": -53.1103
    },
    {
        "nome": "Santa Quitéria",
        "latitude": -4.32608,
        "longitude": -40.1523
    },
    {
        "nome": "Santa Quitéria do Maranhão",
        "latitude": -3.49308,
        "longitude": -42.5688
    },
    {
        "nome": "Santa Rita",
        "latitude": -3.14241,
        "longitude": -44.3211
    },
    {
        "nome": "Santa Rita",
        "latitude": -7.11724,
        "longitude": -34.9753
    },
    {
        "nome": "Santa Rita d'Oeste",
        "latitude": -20.1414,
        "longitude": -50.8358
    },
    {
        "nome": "Santa Rita de Caldas",
        "latitude": -22.0292,
        "longitude": -46.3385
    },
    {
        "nome": "Santa Rita de Cássia",
        "latitude": -11.0063,
        "longitude": -44.5255
    },
    {
        "nome": "Santa Rita de Ibitipoca",
        "latitude": -21.5658,
        "longitude": -43.9163
    },
    {
        "nome": "Santa Rita de Jacutinga",
        "latitude": -22.1474,
        "longitude": -44.0977
    },
    {
        "nome": "Santa Rita de Minas",
        "latitude": -19.876,
        "longitude": -42.1363
    },
    {
        "nome": "Santa Rita do Araguaia",
        "latitude": -17.3269,
        "longitude": -53.2012
    },
    {
        "nome": "Santa Rita do Itueto",
        "latitude": -19.3576,
        "longitude": -41.3821
    },
    {
        "nome": "Santa Rita do Novo Destino",
        "latitude": -15.1351,
        "longitude": -49.1203
    },
    {
        "nome": "Santa Rita do Pardo",
        "latitude": -21.3016,
        "longitude": -52.8333
    },
    {
        "nome": "Santa Rita do Passa Quatro",
        "latitude": -21.7083,
        "longitude": -47.478
    },
    {
        "nome": "Santa Rita do Sapucaí",
        "latitude": -22.2461,
        "longitude": -45.7034
    },
    {
        "nome": "Santa Rita do Tocantins",
        "latitude": -10.8617,
        "longitude": -48.9161
    },
    {
        "nome": "Santa Rita do Trivelato",
        "latitude": -13.8146,
        "longitude": -55.2706
    },
    {
        "nome": "Santa Rosa",
        "latitude": -27.8702,
        "longitude": -54.4796
    },
    {
        "nome": "Santa Rosa da Serra",
        "latitude": -19.5186,
        "longitude": -45.9611
    },
    {
        "nome": "Santa Rosa de Goiás",
        "latitude": -16.084,
        "longitude": -49.4953
    },
    {
        "nome": "Santa Rosa de Lima",
        "latitude": -28.0331,
        "longitude": -49.133
    },
    {
        "nome": "Santa Rosa de Lima",
        "latitude": -10.6434,
        "longitude": -37.1931
    },
    {
        "nome": "Santa Rosa de Viterbo",
        "latitude": -21.4776,
        "longitude": -47.3622
    },
    {
        "nome": "Santa Rosa do Piauí",
        "latitude": -6.79581,
        "longitude": -42.2814
    },
    {
        "nome": "Santa Rosa do Purus",
        "latitude": -9.44652,
        "longitude": -70.4902
    },
    {
        "nome": "Santa Rosa do Sul",
        "latitude": -29.1313,
        "longitude": -49.7109
    },
    {
        "nome": "Santa Rosa do Tocantins",
        "latitude": -11.4474,
        "longitude": -48.1216
    },
    {
        "nome": "Santa Salete",
        "latitude": -20.2429,
        "longitude": -50.6887
    },
    {
        "nome": "Santa Teresa",
        "latitude": -19.9363,
        "longitude": -40.5979
    },
    {
        "nome": "Santa Teresinha",
        "latitude": -12.7697,
        "longitude": -39.5215
    },
    {
        "nome": "Santa Teresinha",
        "latitude": -7.07964,
        "longitude": -37.4435
    },
    {
        "nome": "Santa Tereza",
        "latitude": -29.1655,
        "longitude": -51.7351
    },
    {
        "nome": "Santa Tereza de Goiás",
        "latitude": -13.7138,
        "longitude": -49.0144
    },
    {
        "nome": "Santa Tereza do Oeste",
        "latitude": -25.0543,
        "longitude": -53.6274
    },
    {
        "nome": "Santa Tereza do Tocantins",
        "latitude": -10.2746,
        "longitude": -47.8033
    },
    {
        "nome": "Santa Terezinha",
        "latitude": -26.7813,
        "longitude": -50.009
    },
    {
        "nome": "Santa Terezinha",
        "latitude": -10.4704,
        "longitude": -50.514
    },
    {
        "nome": "Santa Terezinha",
        "latitude": -7.37696,
        "longitude": -37.4787
    },
    {
        "nome": "Santa Terezinha de Goiás",
        "latitude": -14.4326,
        "longitude": -49.7091
    },
    {
        "nome": "Santa Terezinha de Itaipu",
        "latitude": -25.4391,
        "longitude": -54.402
    },
    {
        "nome": "Santa Terezinha do Progresso",
        "latitude": -26.624,
        "longitude": -53.1997
    },
    {
        "nome": "Santa Terezinha do Tocantins",
        "latitude": -6.44438,
        "longitude": -47.6684
    },
    {
        "nome": "Santa Vitória",
        "latitude": -18.8414,
        "longitude": -50.1208
    },
    {
        "nome": "Santa Vitória do Palmar",
        "latitude": -33.525,
        "longitude": -53.3717
    },
    {
        "nome": "Santaluz",
        "latitude": -11.2508,
        "longitude": -39.375
    },
    {
        "nome": "Santana",
        "latitude": -12.9792,
        "longitude": -44.0506
    },
    {
        "nome": "Santana",
        "latitude": -0.045434,
        "longitude": -51.1729
    },
    {
        "nome": "Santana da Boa Vista",
        "latitude": -30.8697,
        "longitude": -53.11
    },
    {
        "nome": "Santana da Ponte Pensa",
        "latitude": -20.2523,
        "longitude": -50.8014
    },
    {
        "nome": "Santana da Vargem",
        "latitude": -21.2449,
        "longitude": -45.5005
    },
    {
        "nome": "Santana de Cataguases",
        "latitude": -21.2893,
        "longitude": -42.5524
    },
    {
        "nome": "Santana de Mangueira",
        "latitude": -7.54705,
        "longitude": -38.3236
    },
    {
        "nome": "Santana de Parnaíba",
        "latitude": -23.4439,
        "longitude": -46.9178
    },
    {
        "nome": "Santana de Pirapama",
        "latitude": -18.9962,
        "longitude": -44.0409
    },
    {
        "nome": "Santana do Acaraú",
        "latitude": -3.46144,
        "longitude": -40.2118
    },
    {
        "nome": "Santana do Araguaia",
        "latitude": -9.3281,
        "longitude": -50.35
    },
    {
        "nome": "Santana do Cariri",
        "latitude": -7.17613,
        "longitude": -39.7302
    },
    {
        "nome": "Santana do Deserto",
        "latitude": -21.9512,
        "longitude": -43.1583
    },
    {
        "nome": "Santana do Garambéu",
        "latitude": -21.5983,
        "longitude": -44.105
    },
    {
        "nome": "Santana do Ipanema",
        "latitude": -9.36999,
        "longitude": -37.248
    },
    {
        "nome": "Santana do Itararé",
        "latitude": -23.7587,
        "longitude": -49.6293
    },
    {
        "nome": "Santana do Jacaré",
        "latitude": -20.9007,
        "longitude": -45.1285
    },
    {
        "nome": "Santana do Manhuaçu",
        "latitude": -20.1031,
        "longitude": -41.9278
    },
    {
        "nome": "Santana do Maranhão",
        "latitude": -3.109,
        "longitude": -42.4064
    },
    {
        "nome": "Santana do Matos",
        "latitude": -5.94605,
        "longitude": -36.6578
    },
    {
        "nome": "Santana do Mundaú",
        "latitude": -9.17141,
        "longitude": -36.2176
    },
    {
        "nome": "Santana do Paraíso",
        "latitude": -19.3661,
        "longitude": -42.5446
    },
    {
        "nome": "Santana do Piauí",
        "latitude": -6.94696,
        "longitude": -41.5178
    },
    {
        "nome": "Santana do Riacho",
        "latitude": -19.1662,
        "longitude": -43.722
    },
    {
        "nome": "Santana do São Francisco",
        "latitude": -10.2922,
        "longitude": -36.6105
    },
    {
        "nome": "Santana do Seridó",
        "latitude": -6.76643,
        "longitude": -36.7312
    },
    {
        "nome": "Santana dos Garrotes",
        "latitude": -7.38162,
        "longitude": -37.9819
    },
    {
        "nome": "Santana dos Montes",
        "latitude": -20.7868,
        "longitude": -43.6949
    },
    {
        "nome": "Santanópolis",
        "latitude": -12.0311,
        "longitude": -38.8694
    },
    {
        "nome": "Santarém",
        "latitude": -2.43849,
        "longitude": -54.6996
    },
    {
        "nome": "Santarém Novo",
        "latitude": -0.93097,
        "longitude": -47.3855
    },
    {
        "nome": "Santiago",
        "latitude": -29.1897,
        "longitude": -54.8666
    },
    {
        "nome": "Santiago do Sul",
        "latitude": -26.6388,
        "longitude": -52.6799
    },
    {
        "nome": "Santo Afonso",
        "latitude": -14.4945,
        "longitude": -57.0091
    },
    {
        "nome": "Santo Amaro",
        "latitude": -12.5472,
        "longitude": -38.7137
    },
    {
        "nome": "Santo Amaro da Imperatriz",
        "latitude": -27.6852,
        "longitude": -48.7813
    },
    {
        "nome": "Santo Amaro das Brotas",
        "latitude": -10.7892,
        "longitude": -37.0564
    },
    {
        "nome": "Santo Amaro do Maranhão",
        "latitude": -2.50068,
        "longitude": -43.238
    },
    {
        "nome": "Santo Anastácio",
        "latitude": -21.9747,
        "longitude": -51.6527
    },
    {
        "nome": "Santo André",
        "latitude": -23.6737,
        "longitude": -46.5432
    },
    {
        "nome": "Santo André",
        "latitude": -7.22016,
        "longitude": -36.6213
    },
    {
        "nome": "Santo Ângelo",
        "latitude": -28.3001,
        "longitude": -54.2668
    },
    {
        "nome": "Santo Antônio",
        "latitude": -6.31195,
        "longitude": -35.4739
    },
    {
        "nome": "Santo Antônio da Alegria",
        "latitude": -21.0864,
        "longitude": -47.1464
    },
    {
        "nome": "Santo Antônio da Barra",
        "latitude": -17.5585,
        "longitude": -50.6345
    },
    {
        "nome": "Santo Antônio da Patrulha",
        "latitude": -29.8268,
        "longitude": -50.5175
    },
    {
        "nome": "Santo Antônio da Platina",
        "latitude": -23.2959,
        "longitude": -50.0815
    },
    {
        "nome": "Santo Antônio das Missões",
        "latitude": -28.514,
        "longitude": -55.2251
    },
    {
        "nome": "Santo Antônio de Goiás",
        "latitude": -16.4815,
        "longitude": -49.3096
    },
    {
        "nome": "Santo Antônio de Jesus",
        "latitude": -12.9614,
        "longitude": -39.2584
    },
    {
        "nome": "Santo Antônio de Lisboa",
        "latitude": -6.98676,
        "longitude": -41.2252
    },
    {
        "nome": "Santo Antônio de Pádua",
        "latitude": -21.541,
        "longitude": -42.1832
    },
    {
        "nome": "Santo Antônio de Posse",
        "latitude": -22.6029,
        "longitude": -46.9192
    },
    {
        "nome": "Santo Antônio do Amparo",
        "latitude": -20.943,
        "longitude": -44.9176
    },
    {
        "nome": "Santo Antônio do Aracanguá",
        "latitude": -20.9331,
        "longitude": -50.498
    },
    {
        "nome": "Santo Antônio do Aventureiro",
        "latitude": -21.7606,
        "longitude": -42.8115
    },
    {
        "nome": "Santo Antônio do Caiuá",
        "latitude": -22.7351,
        "longitude": -52.344
    },
    {
        "nome": "Santo Antônio do Descoberto",
        "latitude": -15.9412,
        "longitude": -48.2578
    },
    {
        "nome": "Santo Antônio do Grama",
        "latitude": -20.3185,
        "longitude": -42.6047
    },
    {
        "nome": "Santo Antônio do Içá",
        "latitude": -3.09544,
        "longitude": -67.9463
    },
    {
        "nome": "Santo Antônio do Itambé",
        "latitude": -18.4609,
        "longitude": -43.3006
    },
    {
        "nome": "Santo Antônio do Jacinto",
        "latitude": -16.5332,
        "longitude": -40.1817
    },
    {
        "nome": "Santo Antônio do Jardim",
        "latitude": -22.1121,
        "longitude": -46.6845
    },
    {
        "nome": "Santo Antônio do Leste",
        "latitude": -14.805,
        "longitude": -53.6075
    },
    {
        "nome": "Santo Antônio do Leverger",
        "latitude": -15.8632,
        "longitude": -56.0788
    },
    {
        "nome": "Santo Antônio do Monte",
        "latitude": -20.085,
        "longitude": -45.2947
    },
    {
        "nome": "Santo Antônio do Palma",
        "latitude": -28.4956,
        "longitude": -52.0267
    },
    {
        "nome": "Santo Antônio do Paraíso",
        "latitude": -23.4969,
        "longitude": -50.6455
    },
    {
        "nome": "Santo Antônio do Pinhal",
        "latitude": -22.827,
        "longitude": -45.663
    },
    {
        "nome": "Santo Antônio do Planalto",
        "latitude": -28.403,
        "longitude": -52.6992
    },
    {
        "nome": "Santo Antônio do Retiro",
        "latitude": -15.3393,
        "longitude": -42.6171
    },
    {
        "nome": "Santo Antônio do Rio Abaixo",
        "latitude": -19.2374,
        "longitude": -43.2604
    },
    {
        "nome": "Santo Antônio do Sudoeste",
        "latitude": -26.0737,
        "longitude": -53.7251
    },
    {
        "nome": "Santo Antônio do Tauá",
        "latitude": -1.1522,
        "longitude": -48.1314
    },
    {
        "nome": "Santo Antônio dos Lopes",
        "latitude": -4.86613,
        "longitude": -44.3653
    },
    {
        "nome": "Santo Antônio dos Milagres",
        "latitude": -6.04647,
        "longitude": -42.7123
    },
    {
        "nome": "Santo Augusto",
        "latitude": -27.8526,
        "longitude": -53.7776
    },
    {
        "nome": "Santo Cristo",
        "latitude": -27.8263,
        "longitude": -54.662
    },
    {
        "nome": "Santo Estêvão",
        "latitude": -12.428,
        "longitude": -39.2505
    },
    {
        "nome": "Santo Expedito",
        "latitude": -21.8467,
        "longitude": -51.3929
    },
    {
        "nome": "Santo Expedito do Sul",
        "latitude": -27.9074,
        "longitude": -51.6434
    },
    {
        "nome": "Santo Hipólito",
        "latitude": -18.2968,
        "longitude": -44.2229
    },
    {
        "nome": "Santo Inácio",
        "latitude": -22.6957,
        "longitude": -51.7969
    },
    {
        "nome": "Santo Inácio do Piauí",
        "latitude": -7.42072,
        "longitude": -41.9063
    },
    {
        "nome": "Santópolis do Aguapeí",
        "latitude": -21.6376,
        "longitude": -50.5044
    },
    {
        "nome": "Santos",
        "latitude": -23.9535,
        "longitude": -46.335
    },
    {
        "nome": "Santos Dumont",
        "latitude": -21.4634,
        "longitude": -43.5499
    },
    {
        "nome": "São Benedito",
        "latitude": -4.04713,
        "longitude": -40.8596
    },
    {
        "nome": "São Benedito do Rio Preto",
        "latitude": -3.33515,
        "longitude": -43.5287
    },
    {
        "nome": "São Benedito do Sul",
        "latitude": -8.8166,
        "longitude": -35.9453
    },
    {
        "nome": "São Bentinho",
        "latitude": -6.88596,
        "longitude": -37.7243
    },
    {
        "nome": "São Bento",
        "latitude": -6.48529,
        "longitude": -37.4488
    },
    {
        "nome": "São Bento",
        "latitude": -2.69781,
        "longitude": -44.8289
    },
    {
        "nome": "São Bento Abade",
        "latitude": -21.5839,
        "longitude": -45.0699
    },
    {
        "nome": "São Bento do Norte",
        "latitude": -5.09259,
        "longitude": -35.9587
    },
    {
        "nome": "São Bento do Sapucaí",
        "latitude": -22.6837,
        "longitude": -45.7287
    },
    {
        "nome": "São Bento do Sul",
        "latitude": -26.2495,
        "longitude": -49.3831
    },
    {
        "nome": "São Bento do Tocantins",
        "latitude": -6.0258,
        "longitude": -47.9012
    },
    {
        "nome": "São Bento do Trairí",
        "latitude": -6.33798,
        "longitude": -36.0863
    },
    {
        "nome": "São Bento do Una",
        "latitude": -8.52637,
        "longitude": -36.4465
    },
    {
        "nome": "São Bernardino",
        "latitude": -26.4739,
        "longitude": -52.9687
    },
    {
        "nome": "São Bernardo",
        "latitude": -3.37223,
        "longitude": -42.4191
    },
    {
        "nome": "São Bernardo do Campo",
        "latitude": -23.6914,
        "longitude": -46.5646
    },
    {
        "nome": "São Bonifácio",
        "latitude": -27.9009,
        "longitude": -48.9326
    },
    {
        "nome": "São Borja",
        "latitude": -28.6578,
        "longitude": -56.0036
    },
    {
        "nome": "São Brás",
        "latitude": -10.1141,
        "longitude": -36.8522
    },
    {
        "nome": "São Brás do Suaçuí",
        "latitude": -20.6242,
        "longitude": -43.9515
    },
    {
        "nome": "São Braz do Piauí",
        "latitude": -9.05797,
        "longitude": -43.0076
    },
    {
        "nome": "São Caetano",
        "latitude": -8.33763,
        "longitude": -36.2869
    },
    {
        "nome": "São Caetano de Odivelas",
        "latitude": -0.747293,
        "longitude": -48.0246
    },
    {
        "nome": "São Caetano do Sul",
        "latitude": -23.6229,
        "longitude": -46.5548
    },
    {
        "nome": "São Carlos",
        "latitude": -22.0174,
        "longitude": -47.886
    },
    {
        "nome": "São Carlos",
        "latitude": -27.0798,
        "longitude": -53.0037
    },
    {
        "nome": "São Carlos do Ivaí",
        "latitude": -23.3158,
        "longitude": -52.4761
    },
    {
        "nome": "São Cristóvão",
        "latitude": -11.0084,
        "longitude": -37.2044
    },
    {
        "nome": "São Cristovão do Sul",
        "latitude": -27.2666,
        "longitude": -50.4388
    },
    {
        "nome": "São Desidério",
        "latitude": -12.3572,
        "longitude": -44.9769
    },
    {
        "nome": "São Domingos",
        "latitude": -11.4649,
        "longitude": -39.5268
    },
    {
        "nome": "São Domingos",
        "latitude": -26.5548,
        "longitude": -52.5313
    },
    {
        "nome": "São Domingos",
        "latitude": -6.80313,
        "longitude": -37.9488
    },
    {
        "nome": "São Domingos",
        "latitude": -10.7916,
        "longitude": -37.5685
    },
    {
        "nome": "São Domingos",
        "latitude": -13.621,
        "longitude": -46.7415
    },
    {
        "nome": "São Domingos das Dores",
        "latitude": -19.5246,
        "longitude": -42.0106
    },
    {
        "nome": "São Domingos do Araguaia",
        "latitude": -5.53732,
        "longitude": -48.7366
    },
    {
        "nome": "São Domingos do Azeitão",
        "latitude": -6.81471,
        "longitude": -44.6509
    },
    {
        "nome": "São Domingos do Capim",
        "latitude": -1.68768,
        "longitude": -47.7665
    },
    {
        "nome": "São Domingos do Cariri",
        "latitude": -7.63273,
        "longitude": -36.4374
    },
    {
        "nome": "São Domingos do Maranhão",
        "latitude": -5.58095,
        "longitude": -44.3822
    },
    {
        "nome": "São Domingos do Norte",
        "latitude": -19.1452,
        "longitude": -40.6281
    },
    {
        "nome": "São Domingos do Prata",
        "latitude": -19.8678,
        "longitude": -42.971
    },
    {
        "nome": "São Domingos do Sul",
        "latitude": -28.5312,
        "longitude": -51.886
    },
    {
        "nome": "São Felipe",
        "latitude": -12.8394,
        "longitude": -39.0893
    },
    {
        "nome": "São Felipe D'Oeste",
        "latitude": -11.9023,
        "longitude": -61.5026
    },
    {
        "nome": "São Félix",
        "latitude": -12.6104,
        "longitude": -38.9727
    },
    {
        "nome": "São Félix de Balsas",
        "latitude": -7.07535,
        "longitude": -44.8092
    },
    {
        "nome": "São Félix de Minas",
        "latitude": -18.5959,
        "longitude": -41.4889
    },
    {
        "nome": "São Félix do Araguaia",
        "latitude": -11.615,
        "longitude": -50.6706
    },
    {
        "nome": "São Félix do Coribe",
        "latitude": -13.4019,
        "longitude": -44.1837
    },
    {
        "nome": "São Félix do Piauí",
        "latitude": -5.93485,
        "longitude": -42.1172
    },
    {
        "nome": "São Félix do Tocantins",
        "latitude": -10.1615,
        "longitude": -46.6618
    },
    {
        "nome": "São Félix do Xingu",
        "latitude": -6.64254,
        "longitude": -51.9904
    },
    {
        "nome": "São Fernando",
        "latitude": -6.37975,
        "longitude": -37.1864
    },
    {
        "nome": "São Fidélis",
        "latitude": -21.6551,
        "longitude": -41.756
    },
    {
        "nome": "São Francisco",
        "latitude": -20.3623,
        "longitude": -50.6952
    },
    {
        "nome": "São Francisco",
        "latitude": -6.60773,
        "longitude": -38.0968
    },
    {
        "nome": "São Francisco",
        "latitude": -10.3442,
        "longitude": -36.8869
    },
    {
        "nome": "São Francisco",
        "latitude": -15.9514,
        "longitude": -44.8593
    },
    {
        "nome": "São Francisco de Assis",
        "latitude": -29.5547,
        "longitude": -55.1253
    },
    {
        "nome": "São Francisco de Assis do Piauí",
        "latitude": -8.23599,
        "longitude": -41.6873
    },
    {
        "nome": "São Francisco de Goiás",
        "latitude": -15.9256,
        "longitude": -49.2605
    },
    {
        "nome": "São Francisco de Itabapoana",
        "latitude": -21.4702,
        "longitude": -41.1091
    },
    {
        "nome": "São Francisco de Paula",
        "latitude": -29.4404,
        "longitude": -50.5828
    },
    {
        "nome": "São Francisco de Paula",
        "latitude": -20.7036,
        "longitude": -44.9838
    },
    {
        "nome": "São Francisco de Sales",
        "latitude": -19.8611,
        "longitude": -49.7727
    },
    {
        "nome": "São Francisco do Brejão",
        "latitude": -5.12584,
        "longitude": -47.389
    },
    {
        "nome": "São Francisco do Conde",
        "latitude": -12.6183,
        "longitude": -38.6786
    },
    {
        "nome": "São Francisco do Glória",
        "latitude": -20.7923,
        "longitude": -42.2673
    },
    {
        "nome": "São Francisco do Guaporé",
        "latitude": -12.052,
        "longitude": -63.568
    },
    {
        "nome": "São Francisco do Maranhão",
        "latitude": -6.25159,
        "longitude": -42.8668
    },
    {
        "nome": "São Francisco do Oeste",
        "latitude": -5.97472,
        "longitude": -38.1519
    },
    {
        "nome": "São Francisco do Pará",
        "latitude": -1.16963,
        "longitude": -47.7917
    },
    {
        "nome": "São Francisco do Piauí",
        "latitude": -7.2463,
        "longitude": -42.541
    },
    {
        "nome": "São Francisco do Sul",
        "latitude": -26.2579,
        "longitude": -48.6344
    },
    {
        "nome": "São Gabriel",
        "latitude": -30.3337,
        "longitude": -54.3217
    },
    {
        "nome": "São Gabriel",
        "latitude": -11.2175,
        "longitude": -41.8843
    },
    {
        "nome": "São Gabriel da Cachoeira",
        "latitude": -0.11909,
        "longitude": -67.084
    },
    {
        "nome": "São Gabriel da Palha",
        "latitude": -19.0182,
        "longitude": -40.5365
    },
    {
        "nome": "São Gabriel do Oeste",
        "latitude": -19.3889,
        "longitude": -54.5507
    },
    {
        "nome": "São Geraldo",
        "latitude": -20.9252,
        "longitude": -42.8364
    },
    {
        "nome": "São Geraldo da Piedade",
        "latitude": -18.8411,
        "longitude": -42.2867
    },
    {
        "nome": "São Geraldo do Araguaia",
        "latitude": -6.39471,
        "longitude": -48.5592
    },
    {
        "nome": "São Geraldo do Baixio",
        "latitude": -18.9097,
        "longitude": -41.363
    },
    {
        "nome": "São Gonçalo",
        "latitude": -22.8268,
        "longitude": -43.0634
    },
    {
        "nome": "São Gonçalo do Abaeté",
        "latitude": -18.3315,
        "longitude": -45.8265
    },
    {
        "nome": "São Gonçalo do Amarante",
        "latitude": -5.79068,
        "longitude": -35.3257
    },
    {
        "nome": "São Gonçalo do Amarante",
        "latitude": -3.60515,
        "longitude": -38.9726
    },
    {
        "nome": "São Gonçalo do Gurguéia",
        "latitude": -10.0319,
        "longitude": -45.3092
    },
    {
        "nome": "São Gonçalo do Pará",
        "latitude": -19.9822,
        "longitude": -44.8593
    },
    {
        "nome": "São Gonçalo do Piauí",
        "latitude": -5.99393,
        "longitude": -42.7095
    },
    {
        "nome": "São Gonçalo do Rio Abaixo",
        "latitude": -19.8221,
        "longitude": -43.366
    },
    {
        "nome": "São Gonçalo do Rio Preto",
        "latitude": -18.0025,
        "longitude": -43.3854
    },
    {
        "nome": "São Gonçalo do Sapucaí",
        "latitude": -21.8932,
        "longitude": -45.5893
    },
    {
        "nome": "São Gonçalo dos Campos",
        "latitude": -12.4331,
        "longitude": -38.9663
    },
    {
        "nome": "São Gotardo",
        "latitude": -19.3087,
        "longitude": -46.0465
    },
    {
        "nome": "São Jerônimo",
        "latitude": -29.9716,
        "longitude": -51.7251
    },
    {
        "nome": "São Jerônimo da Serra",
        "latitude": -23.7218,
        "longitude": -50.7475
    },
    {
        "nome": "São João",
        "latitude": -25.8214,
        "longitude": -52.7252
    },
    {
        "nome": "São João",
        "latitude": -8.87576,
        "longitude": -36.3653
    },
    {
        "nome": "São João Batista",
        "latitude": -2.95398,
        "longitude": -44.7953
    },
    {
        "nome": "São João Batista",
        "latitude": -27.2772,
        "longitude": -48.8474
    },
    {
        "nome": "São João Batista do Glória",
        "latitude": -20.635,
        "longitude": -46.508
    },
    {
        "nome": "São João d'Aliança",
        "latitude": -14.7048,
        "longitude": -47.5228
    },
    {
        "nome": "São João da Baliza",
        "latitude": 0.951659,
        "longitude": -59.9133
    },
    {
        "nome": "São João da Barra",
        "latitude": -21.638,
        "longitude": -41.0446
    },
    {
        "nome": "São João da Boa Vista",
        "latitude": -21.9707,
        "longitude": -46.7944
    },
    {
        "nome": "São João da Canabrava",
        "latitude": -6.81203,
        "longitude": -41.3415
    },
    {
        "nome": "São João da Fronteira",
        "latitude": -3.95497,
        "longitude": -41.2569
    },
    {
        "nome": "São João da Lagoa",
        "latitude": -16.8455,
        "longitude": -44.3507
    },
    {
        "nome": "São João da Mata",
        "latitude": -21.928,
        "longitude": -45.9297
    },
    {
        "nome": "São João da Paraúna",
        "latitude": -16.8126,
        "longitude": -50.4092
    },
    {
        "nome": "São João da Ponta",
        "latitude": -0.857885,
        "longitude": -47.918
    },
    {
        "nome": "São João da Ponte",
        "latitude": -15.9271,
        "longitude": -44.0096
    },
    {
        "nome": "São João da Serra",
        "latitude": -5.51081,
        "longitude": -41.8923
    },
    {
        "nome": "São João da Urtiga",
        "latitude": -27.8195,
        "longitude": -51.8257
    },
    {
        "nome": "São João da Varjota",
        "latitude": -6.94082,
        "longitude": -41.8889
    },
    {
        "nome": "São João das Duas Pontes",
        "latitude": -20.3879,
        "longitude": -50.3792
    },
    {
        "nome": "São João das Missões",
        "latitude": -14.8859,
        "longitude": -44.0922
    },
    {
        "nome": "São João de Iracema",
        "latitude": -20.5111,
        "longitude": -50.3561
    },
    {
        "nome": "São João de Meriti",
        "latitude": -22.8058,
        "longitude": -43.3729
    },
    {
        "nome": "São João de Pirabas",
        "latitude": -0.780222,
        "longitude": -47.181
    },
    {
        "nome": "São João del Rei",
        "latitude": -21.1311,
        "longitude": -44.2526
    },
    {
        "nome": "São João do Araguaia",
        "latitude": -5.36334,
        "longitude": -48.7926
    },
    {
        "nome": "São João do Arraial",
        "latitude": -3.8186,
        "longitude": -42.4459
    },
    {
        "nome": "São João do Caiuá",
        "latitude": -22.8535,
        "longitude": -52.3411
    },
    {
        "nome": "São João do Cariri",
        "latitude": -7.38168,
        "longitude": -36.5345
    },
    {
        "nome": "São João do Carú",
        "latitude": -3.5503,
        "longitude": -46.2507
    },
    {
        "nome": "São João do Itaperiú",
        "latitude": -26.6213,
        "longitude": -48.7683
    },
    {
        "nome": "São João do Ivaí",
        "latitude": -23.9833,
        "longitude": -51.8215
    },
    {
        "nome": "São João do Jaguaribe",
        "latitude": -5.27516,
        "longitude": -38.2694
    },
    {
        "nome": "São João do Manhuaçu",
        "latitude": -20.3933,
        "longitude": -42.1533
    },
    {
        "nome": "São João do Manteninha",
        "latitude": -18.723,
        "longitude": -41.1628
    },
    {
        "nome": "São João do Oeste",
        "latitude": -27.0984,
        "longitude": -53.5977
    },
    {
        "nome": "São João do Oriente",
        "latitude": -19.3384,
        "longitude": -42.1575
    },
    {
        "nome": "São João do Pacuí",
        "latitude": -16.5373,
        "longitude": -44.5134
    },
    {
        "nome": "São João do Paraíso",
        "latitude": -15.3168,
        "longitude": -42.0213
    },
    {
        "nome": "São João do Paraíso",
        "latitude": -6.45634,
        "longitude": -47.0594
    },
    {
        "nome": "São João do Pau d'Alho",
        "latitude": -21.2662,
        "longitude": -51.6672
    },
    {
        "nome": "São João do Piauí",
        "latitude": -8.35466,
        "longitude": -42.2559
    },
    {
        "nome": "São João do Polêsine",
        "latitude": -29.6194,
        "longitude": -53.4439
    },
    {
        "nome": "São João do Rio do Peixe",
        "latitude": -6.72195,
        "longitude": -38.4468
    },
    {
        "nome": "São João do Sabugi",
        "latitude": -6.71387,
        "longitude": -37.2027
    },
    {
        "nome": "São João do Soter",
        "latitude": -5.10821,
        "longitude": -43.8163
    },
    {
        "nome": "São João do Sul",
        "latitude": -29.2154,
        "longitude": -49.8094
    },
    {
        "nome": "São João do Tigre",
        "latitude": -8.07703,
        "longitude": -36.8547
    },
    {
        "nome": "São João do Triunfo",
        "latitude": -25.683,
        "longitude": -50.2949
    },
    {
        "nome": "São João dos Patos",
        "latitude": -6.4934,
        "longitude": -43.7036
    },
    {
        "nome": "São João Evangelista",
        "latitude": -18.548,
        "longitude": -42.7655
    },
    {
        "nome": "São João Nepomuceno",
        "latitude": -21.5381,
        "longitude": -43.0069
    },
    {
        "nome": "São Joaquim",
        "latitude": -28.2887,
        "longitude": -49.9457
    },
    {
        "nome": "São Joaquim da Barra",
        "latitude": -20.5812,
        "longitude": -47.8593
    },
    {
        "nome": "São Joaquim de Bicas",
        "latitude": -20.048,
        "longitude": -44.2749
    },
    {
        "nome": "São Joaquim do Monte",
        "latitude": -8.43196,
        "longitude": -35.8035
    },
    {
        "nome": "São Jorge",
        "latitude": -28.4984,
        "longitude": -51.7064
    },
    {
        "nome": "São Jorge d'Oeste",
        "latitude": -25.7085,
        "longitude": -52.9204
    },
    {
        "nome": "São Jorge do Ivaí",
        "latitude": -23.4336,
        "longitude": -52.2929
    },
    {
        "nome": "São Jorge do Patrocínio",
        "latitude": -23.7647,
        "longitude": -53.8823
    },
    {
        "nome": "São José",
        "latitude": -27.6136,
        "longitude": -48.6366
    },
    {
        "nome": "São José da Barra",
        "latitude": -20.7178,
        "longitude": -46.313
    },
    {
        "nome": "São José da Bela Vista",
        "latitude": -20.5935,
        "longitude": -47.6424
    },
    {
        "nome": "São José da Boa Vista",
        "latitude": -23.9122,
        "longitude": -49.6577
    },
    {
        "nome": "São José da Coroa Grande",
        "latitude": -8.88937,
        "longitude": -35.1515
    },
    {
        "nome": "São José da Lagoa Tapada",
        "latitude": -6.93646,
        "longitude": -38.1622
    },
    {
        "nome": "São José da Laje",
        "latitude": -9.01278,
        "longitude": -36.0515
    },
    {
        "nome": "São José da Lapa",
        "latitude": -19.6971,
        "longitude": -43.9586
    },
    {
        "nome": "São José da Safira",
        "latitude": -18.3243,
        "longitude": -42.1431
    },
    {
        "nome": "São José da Tapera",
        "latitude": -9.55768,
        "longitude": -37.3831
    },
    {
        "nome": "São José da Varginha",
        "latitude": -19.7006,
        "longitude": -44.556
    },
    {
        "nome": "São José da Vitória",
        "latitude": -15.0787,
        "longitude": -39.3437
    },
    {
        "nome": "São José das Missões",
        "latitude": -27.7789,
        "longitude": -53.1226
    },
    {
        "nome": "São José das Palmeiras",
        "latitude": -24.8369,
        "longitude": -54.0572
    },
    {
        "nome": "São José de Caiana",
        "latitude": -7.24636,
        "longitude": -38.2989
    },
    {
        "nome": "São José de Espinharas",
        "latitude": -6.83974,
        "longitude": -37.3214
    },
    {
        "nome": "São José de Mipibu",
        "latitude": -6.0773,
        "longitude": -35.2417
    },
    {
        "nome": "São José de Piranhas",
        "latitude": -7.1187,
        "longitude": -38.502
    },
    {
        "nome": "São José de Princesa",
        "latitude": -7.73633,
        "longitude": -38.0894
    },
    {
        "nome": "São José de Ribamar",
        "latitude": -2.54704,
        "longitude": -44.0597
    },
    {
        "nome": "São José de Ubá",
        "latitude": -21.3661,
        "longitude": -41.9511
    },
    {
        "nome": "São José do Alegre",
        "latitude": -22.3243,
        "longitude": -45.5258
    },
    {
        "nome": "São José do Barreiro",
        "latitude": -22.6414,
        "longitude": -44.5774
    },
    {
        "nome": "São José do Belmonte",
        "latitude": -7.85723,
        "longitude": -38.7577
    },
    {
        "nome": "São José do Bonfim",
        "latitude": -7.1607,
        "longitude": -37.3036
    },
    {
        "nome": "São José do Brejo do Cruz",
        "latitude": -6.21054,
        "longitude": -37.3601
    },
    {
        "nome": "São José do Calçado",
        "latitude": -21.0274,
        "longitude": -41.6636
    },
    {
        "nome": "São José do Campestre",
        "latitude": -6.31087,
        "longitude": -35.7067
    },
    {
        "nome": "São José do Cedro",
        "latitude": -26.4561,
        "longitude": -53.4955
    },
    {
        "nome": "São José do Cerrito",
        "latitude": -27.6602,
        "longitude": -50.5733
    },
    {
        "nome": "São José do Divino",
        "latitude": -3.81411,
        "longitude": -41.8308
    },
    {
        "nome": "São José do Divino",
        "latitude": -18.4793,
        "longitude": -41.3907
    },
    {
        "nome": "São José do Egito",
        "latitude": -7.46945,
        "longitude": -37.274
    },
    {
        "nome": "São José do Goiabal",
        "latitude": -19.9214,
        "longitude": -42.7035
    },
    {
        "nome": "São José do Herval",
        "latitude": -29.052,
        "longitude": -52.295
    },
    {
        "nome": "São José do Hortêncio",
        "latitude": -29.528,
        "longitude": -51.245
    },
    {
        "nome": "São José do Inhacorá",
        "latitude": -27.7251,
        "longitude": -54.1275
    },
    {
        "nome": "São José do Jacuípe",
        "latitude": -11.4137,
        "longitude": -39.8669
    },
    {
        "nome": "São José do Jacuri",
        "latitude": -18.281,
        "longitude": -42.6729
    },
    {
        "nome": "São José do Mantimento",
        "latitude": -20.0058,
        "longitude": -41.7486
    },
    {
        "nome": "São José do Norte",
        "latitude": -32.0151,
        "longitude": -52.0331
    },
    {
        "nome": "São José do Ouro",
        "latitude": -27.7707,
        "longitude": -51.5966
    },
    {
        "nome": "São José do Peixe",
        "latitude": -7.48554,
        "longitude": -42.5672
    },
    {
        "nome": "São José do Piauí",
        "latitude": -6.87194,
        "longitude": -41.4731
    },
    {
        "nome": "São José do Povo",
        "latitude": -16.4549,
        "longitude": -54.2487
    },
    {
        "nome": "São José do Rio Claro",
        "latitude": -13.4398,
        "longitude": -56.7218
    },
    {
        "nome": "São José do Rio Pardo",
        "latitude": -21.5953,
        "longitude": -46.8873
    },
    {
        "nome": "São José do Rio Preto",
        "latitude": -20.8113,
        "longitude": -49.3758
    },
    {
        "nome": "São José do Sabugi",
        "latitude": -6.76295,
        "longitude": -36.7972
    },
    {
        "nome": "São José do Seridó",
        "latitude": -6.44002,
        "longitude": -36.8746
    },
    {
        "nome": "São José do Sul",
        "latitude": -29.5448,
        "longitude": -51.4821
    },
    {
        "nome": "São José do Vale do Rio Preto",
        "latitude": -22.1525,
        "longitude": -42.9327
    },
    {
        "nome": "São José do Xingu",
        "latitude": -10.7982,
        "longitude": -52.7486
    },
    {
        "nome": "São José dos Ausentes",
        "latitude": -28.7476,
        "longitude": -50.0677
    },
    {
        "nome": "São José dos Basílios",
        "latitude": -5.05493,
        "longitude": -44.5809
    },
    {
        "nome": "São José dos Campos",
        "latitude": -23.1896,
        "longitude": -45.8841
    },
    {
        "nome": "São José dos Cordeiros",
        "latitude": -7.38775,
        "longitude": -36.8085
    },
    {
        "nome": "São José dos Pinhais",
        "latitude": -25.5313,
        "longitude": -49.2031
    },
    {
        "nome": "São José dos Quatro Marcos",
        "latitude": -15.6276,
        "longitude": -58.1772
    },
    {
        "nome": "São José dos Ramos",
        "latitude": -7.25238,
        "longitude": -35.3725
    },
    {
        "nome": "São Julião",
        "latitude": -7.08391,
        "longitude": -40.8246
    },
    {
        "nome": "São Leopoldo",
        "latitude": -29.7545,
        "longitude": -51.1498
    },
    {
        "nome": "São Lourenço",
        "latitude": -22.1166,
        "longitude": -45.0506
    },
    {
        "nome": "São Lourenço da Mata",
        "latitude": -8.00684,
        "longitude": -35.0124
    },
    {
        "nome": "São Lourenço da Serra",
        "latitude": -23.8491,
        "longitude": -46.9432
    },
    {
        "nome": "São Lourenço do Oeste",
        "latitude": -26.3557,
        "longitude": -52.8498
    },
    {
        "nome": "São Lourenço do Piauí",
        "latitude": -9.16463,
        "longitude": -42.5496
    },
    {
        "nome": "São Lourenço do Sul",
        "latitude": -31.3564,
        "longitude": -51.9715
    },
    {
        "nome": "São Ludgero",
        "latitude": -28.3144,
        "longitude": -49.1806
    },
    {
        "nome": "São Luís",
        "latitude": -2.53874,
        "longitude": -44.2825
    },
    {
        "nome": "São Luís de Montes Belos",
        "latitude": -16.5211,
        "longitude": -50.3726
    },
    {
        "nome": "São Luís do Curu",
        "latitude": -3.66976,
        "longitude": -39.2391
    },
    {
        "nome": "São Luis do Piauí",
        "latitude": -6.81936,
        "longitude": -41.3175
    },
    {
        "nome": "São Luís do Quitunde",
        "latitude": -9.31816,
        "longitude": -35.5606
    },
    {
        "nome": "São Luís Gonzaga do Maranhão",
        "latitude": -4.38541,
        "longitude": -44.6654
    },
    {
        "nome": "São Luiz",
        "latitude": 1.01019,
        "longitude": -60.0419
    },
    {
        "nome": "São Luiz do Norte",
        "latitude": -14.8608,
        "longitude": -49.3285
    },
    {
        "nome": "São Luiz do Paraitinga",
        "latitude": -23.222,
        "longitude": -45.3109
    },
    {
        "nome": "São Luiz Gonzaga",
        "latitude": -28.412,
        "longitude": -54.9559
    },
    {
        "nome": "São Mamede",
        "latitude": -6.92386,
        "longitude": -37.0954
    },
    {
        "nome": "São Manoel do Paraná",
        "latitude": -23.3941,
        "longitude": -52.6454
    },
    {
        "nome": "São Manuel",
        "latitude": -22.7321,
        "longitude": -48.5723
    },
    {
        "nome": "São Marcos",
        "latitude": -28.9677,
        "longitude": -51.0696
    },
    {
        "nome": "São Martinho",
        "latitude": -28.1609,
        "longitude": -48.9867
    },
    {
        "nome": "São Martinho",
        "latitude": -27.7112,
        "longitude": -53.9699
    },
    {
        "nome": "São Martinho da Serra",
        "latitude": -29.5397,
        "longitude": -53.859
    },
    {
        "nome": "São Mateus",
        "latitude": -18.7214,
        "longitude": -39.8579
    },
    {
        "nome": "São Mateus do Maranhão",
        "latitude": -4.03736,
        "longitude": -44.4707
    },
    {
        "nome": "São Mateus do Sul",
        "latitude": -25.8677,
        "longitude": -50.384
    },
    {
        "nome": "São Miguel",
        "latitude": -6.20283,
        "longitude": -38.4947
    },
    {
        "nome": "São Miguel Arcanjo",
        "latitude": -23.8782,
        "longitude": -47.9935
    },
    {
        "nome": "São Miguel da Baixa Grande",
        "latitude": -5.85646,
        "longitude": -42.1934
    },
    {
        "nome": "São Miguel da Boa Vista",
        "latitude": -26.687,
        "longitude": -53.2511
    },
    {
        "nome": "São Miguel das Matas",
        "latitude": -13.0434,
        "longitude": -39.4578
    },
    {
        "nome": "São Miguel das Missões",
        "latitude": -28.556,
        "longitude": -54.5559
    },
    {
        "nome": "São Miguel de Taipu",
        "latitude": -7.24764,
        "longitude": -35.2016
    },
    {
        "nome": "São Miguel do Aleixo",
        "latitude": -10.3847,
        "longitude": -37.3836
    },
    {
        "nome": "São Miguel do Anta",
        "latitude": -20.7067,
        "longitude": -42.7174
    },
    {
        "nome": "São Miguel do Araguaia",
        "latitude": -13.2731,
        "longitude": -50.1634
    },
    {
        "nome": "São Miguel do Fidalgo",
        "latitude": -7.59713,
        "longitude": -42.3676
    },
    {
        "nome": "São Miguel do Gostoso",
        "latitude": -5.12302,
        "longitude": -35.6354
    },
    {
        "nome": "São Miguel do Guamá",
        "latitude": -1.61307,
        "longitude": -47.4784
    },
    {
        "nome": "São Miguel do Guaporé",
        "latitude": -11.6953,
        "longitude": -62.7192
    },
    {
        "nome": "São Miguel do Iguaçu",
        "latitude": -25.3492,
        "longitude": -54.2405
    },
    {
        "nome": "São Miguel do Oeste",
        "latitude": -26.7242,
        "longitude": -53.5163
    },
    {
        "nome": "São Miguel do Passa Quatro",
        "latitude": -17.0582,
        "longitude": -48.662
    },
    {
        "nome": "São Miguel do Tapuio",
        "latitude": -5.49729,
        "longitude": -41.3165
    },
    {
        "nome": "São Miguel do Tocantins",
        "latitude": -5.56305,
        "longitude": -47.5743
    },
    {
        "nome": "São Miguel dos Campos",
        "latitude": -9.78301,
        "longitude": -36.0971
    },
    {
        "nome": "São Miguel dos Milagres",
        "latitude": -9.26493,
        "longitude": -35.3763
    },
    {
        "nome": "São Nicolau",
        "latitude": -28.1834,
        "longitude": -55.2654
    },
    {
        "nome": "São Patrício",
        "latitude": -15.35,
        "longitude": -49.818
    },
    {
        "nome": "São Paulo",
        "latitude": -23.5329,
        "longitude": -46.6395
    },
    {
        "nome": "São Paulo das Missões",
        "latitude": -28.0195,
        "longitude": -54.9404
    },
    {
        "nome": "São Paulo de Olivença",
        "latitude": -3.47292,
        "longitude": -68.9646
    },
    {
        "nome": "São Paulo do Potengi",
        "latitude": -5.8994,
        "longitude": -35.7642
    },
    {
        "nome": "São Pedro",
        "latitude": -5.90559,
        "longitude": -35.6317
    },
    {
        "nome": "São Pedro",
        "latitude": -22.5483,
        "longitude": -47.9096
    },
    {
        "nome": "São Pedro da Água Branca",
        "latitude": -5.08472,
        "longitude": -48.4291
    },
    {
        "nome": "São Pedro da Aldeia",
        "latitude": -22.8429,
        "longitude": -42.1026
    },
    {
        "nome": "São Pedro da Cipa",
        "latitude": -16.0109,
        "longitude": -54.9176
    },
    {
        "nome": "São Pedro da Serra",
        "latitude": -29.4193,
        "longitude": -51.5134
    },
    {
        "nome": "São Pedro da União",
        "latitude": -21.131,
        "longitude": -46.6123
    },
    {
        "nome": "São Pedro das Missões",
        "latitude": -27.7706,
        "longitude": -53.2513
    },
    {
        "nome": "São Pedro de Alcântara",
        "latitude": -27.5665,
        "longitude": -48.8048
    },
    {
        "nome": "São Pedro do Butiá",
        "latitude": -28.1243,
        "longitude": -54.8926
    },
    {
        "nome": "São Pedro do Iguaçu",
        "latitude": -24.9373,
        "longitude": -53.8521
    },
    {
        "nome": "São Pedro do Ivaí",
        "latitude": -23.8634,
        "longitude": -51.8568
    },
    {
        "nome": "São Pedro do Paraná",
        "latitude": -22.8239,
        "longitude": -53.2241
    },
    {
        "nome": "São Pedro do Piauí",
        "latitude": -5.92078,
        "longitude": -42.7192
    },
    {
        "nome": "São Pedro do Suaçuí",
        "latitude": -18.3609,
        "longitude": -42.5981
    },
    {
        "nome": "São Pedro do Sul",
        "latitude": -29.6202,
        "longitude": -54.1855
    },
    {
        "nome": "São Pedro do Turvo",
        "latitude": -22.7453,
        "longitude": -49.7428
    },
    {
        "nome": "São Pedro dos Crentes",
        "latitude": -6.82389,
        "longitude": -46.5319
    },
    {
        "nome": "São Pedro dos Ferros",
        "latitude": -20.1732,
        "longitude": -42.5251
    },
    {
        "nome": "São Rafael",
        "latitude": -5.79791,
        "longitude": -36.8778
    },
    {
        "nome": "São Raimundo das Mangabeiras",
        "latitude": -7.02183,
        "longitude": -45.4809
    },
    {
        "nome": "São Raimundo do Doca Bezerra",
        "latitude": -5.11053,
        "longitude": -45.0696
    },
    {
        "nome": "São Raimundo Nonato",
        "latitude": -9.01241,
        "longitude": -42.6987
    },
    {
        "nome": "São Roberto",
        "latitude": -5.0231,
        "longitude": -45.001
    },
    {
        "nome": "São Romão",
        "latitude": -16.3641,
        "longitude": -45.0749
    },
    {
        "nome": "São Roque",
        "latitude": -23.5226,
        "longitude": -47.1357
    },
    {
        "nome": "São Roque de Minas",
        "latitude": -20.249,
        "longitude": -46.3639
    },
    {
        "nome": "São Roque do Canaã",
        "latitude": -19.7411,
        "longitude": -40.6526
    },
    {
        "nome": "São Salvador do Tocantins",
        "latitude": -12.7458,
        "longitude": -48.2352
    },
    {
        "nome": "São Sebastião",
        "latitude": -23.7951,
        "longitude": -45.4143
    },
    {
        "nome": "São Sebastião",
        "latitude": -9.93043,
        "longitude": -36.559
    },
    {
        "nome": "São Sebastião da Amoreira",
        "latitude": -23.4656,
        "longitude": -50.7625
    },
    {
        "nome": "São Sebastião da Bela Vista",
        "latitude": -22.1583,
        "longitude": -45.7546
    },
    {
        "nome": "São Sebastião da Boa Vista",
        "latitude": -1.71597,
        "longitude": -49.5249
    },
    {
        "nome": "São Sebastião da Grama",
        "latitude": -21.7041,
        "longitude": -46.8208
    },
    {
        "nome": "São Sebastião da Vargem Alegre",
        "latitude": -19.7477,
        "longitude": -43.3679
    },
    {
        "nome": "São Sebastião de Lagoa de Roça",
        "latitude": -7.11034,
        "longitude": -35.8678
    },
    {
        "nome": "São Sebastião do Alto",
        "latitude": -21.9578,
        "longitude": -42.1328
    },
    {
        "nome": "São Sebastião do Anta",
        "latitude": -19.5064,
        "longitude": -41.985
    },
    {
        "nome": "São Sebastião do Caí",
        "latitude": -29.5885,
        "longitude": -51.3749
    },
    {
        "nome": "São Sebastião do Maranhão",
        "latitude": -18.0873,
        "longitude": -42.5659
    },
    {
        "nome": "São Sebastião do Oeste",
        "latitude": -20.2758,
        "longitude": -45.0063
    },
    {
        "nome": "São Sebastião do Paraíso",
        "latitude": -20.9167,
        "longitude": -46.9837
    },
    {
        "nome": "São Sebastião do Passé",
        "latitude": -12.5123,
        "longitude": -38.4905
    },
    {
        "nome": "São Sebastião do Rio Preto",
        "latitude": -19.2959,
        "longitude": -43.1757
    },
    {
        "nome": "São Sebastião do Rio Verde",
        "latitude": -22.2183,
        "longitude": -44.9761
    },
    {
        "nome": "São Sebastião do Tocantins",
        "latitude": -5.26131,
        "longitude": -48.2021
    },
    {
        "nome": "São Sebastião do Uatumã",
        "latitude": -2.55915,
        "longitude": -57.8731
    },
    {
        "nome": "São Sebastião do Umbuzeiro",
        "latitude": -8.15289,
        "longitude": -37.0138
    },
    {
        "nome": "São Sepé",
        "latitude": -30.1643,
        "longitude": -53.5603
    },
    {
        "nome": "São Simão",
        "latitude": -21.4732,
        "longitude": -47.5518
    },
    {
        "nome": "São Simão",
        "latitude": -18.996,
        "longitude": -50.547
    },
    {
        "nome": "São Thomé das Letras",
        "latitude": -21.7218,
        "longitude": -44.9849
    },
    {
        "nome": "São Tiago",
        "latitude": -20.9075,
        "longitude": -44.5098
    },
    {
        "nome": "São Tomás de Aquino",
        "latitude": -20.7791,
        "longitude": -47.0962
    },
    {
        "nome": "São Tomé",
        "latitude": -23.5349,
        "longitude": -52.5901
    },
    {
        "nome": "São Tomé",
        "latitude": -5.96404,
        "longitude": -36.0798
    },
    {
        "nome": "São Valentim",
        "latitude": -27.5583,
        "longitude": -52.5237
    },
    {
        "nome": "São Valentim do Sul",
        "latitude": -29.0451,
        "longitude": -51.7684
    },
    {
        "nome": "São Valério",
        "latitude": -11.9743,
        "longitude": -48.2353
    },
    {
        "nome": "São Valério do Sul",
        "latitude": -27.7906,
        "longitude": -53.9368
    },
    {
        "nome": "São Vendelino",
        "latitude": -29.3729,
        "longitude": -51.3675
    },
    {
        "nome": "São Vicente",
        "latitude": -23.9574,
        "longitude": -46.3883
    },
    {
        "nome": "São Vicente",
        "latitude": -6.21893,
        "longitude": -36.6827
    },
    {
        "nome": "São Vicente de Minas",
        "latitude": -21.7042,
        "longitude": -44.4431
    },
    {
        "nome": "São Vicente do Seridó",
        "latitude": -6.85426,
        "longitude": -36.4122
    },
    {
        "nome": "São Vicente do Sul",
        "latitude": -29.6882,
        "longitude": -54.6826
    },
    {
        "nome": "São Vicente Ferrer",
        "latitude": -7.58969,
        "longitude": -35.4808
    },
    {
        "nome": "São Vicente Ferrer",
        "latitude": -2.89487,
        "longitude": -44.8681
    },
    {
        "nome": "Sapé",
        "latitude": -7.09359,
        "longitude": -35.228
    },
    {
        "nome": "Sapeaçu",
        "latitude": -12.7208,
        "longitude": -39.1824
    },
    {
        "nome": "Sapezal",
        "latitude": -12.9892,
        "longitude": -58.7645
    },
    {
        "nome": "Sapiranga",
        "latitude": -29.6349,
        "longitude": -51.0064
    },
    {
        "nome": "Sapopema",
        "latitude": -23.9078,
        "longitude": -50.5801
    },
    {
        "nome": "Sapucaí-Mirim",
        "latitude": -22.7409,
        "longitude": -45.738
    },
    {
        "nome": "Sapucaia",
        "latitude": -6.94018,
        "longitude": -49.6834
    },
    {
        "nome": "Sapucaia",
        "latitude": -21.9949,
        "longitude": -42.9142
    },
    {
        "nome": "Sapucaia do Sul",
        "latitude": -29.8276,
        "longitude": -51.145
    },
    {
        "nome": "Saquarema",
        "latitude": -22.9292,
        "longitude": -42.5099
    },
    {
        "nome": "Sarandi",
        "latitude": -23.4441,
        "longitude": -51.876
    },
    {
        "nome": "Sarandi",
        "latitude": -27.942,
        "longitude": -52.9231
    },
    {
        "nome": "Sarapuí",
        "latitude": -23.6397,
        "longitude": -47.8249
    },
    {
        "nome": "Sardoá",
        "latitude": -18.7828,
        "longitude": -42.3629
    },
    {
        "nome": "Sarutaiá",
        "latitude": -23.2721,
        "longitude": -49.4763
    },
    {
        "nome": "Sarzedo",
        "latitude": -20.0367,
        "longitude": -44.1446
    },
    {
        "nome": "Sátiro Dias",
        "latitude": -11.5929,
        "longitude": -38.5938
    },
    {
        "nome": "Satuba",
        "latitude": -9.56911,
        "longitude": -35.8227
    },
    {
        "nome": "Satubinha",
        "latitude": -4.04913,
        "longitude": -45.2457
    },
    {
        "nome": "Saubara",
        "latitude": -12.7387,
        "longitude": -38.7625
    },
    {
        "nome": "Saudade do Iguaçu",
        "latitude": -25.6917,
        "longitude": -52.6184
    },
    {
        "nome": "Saudades",
        "latitude": -26.9317,
        "longitude": -53.0021
    },
    {
        "nome": "Saúde",
        "latitude": -10.9428,
        "longitude": -40.4155
    },
    {
        "nome": "Schroeder",
        "latitude": -26.4116,
        "longitude": -49.074
    },
    {
        "nome": "Seabra",
        "latitude": -12.4169,
        "longitude": -41.7722
    },
    {
        "nome": "Seara",
        "latitude": -27.1564,
        "longitude": -52.299
    },
    {
        "nome": "Sebastianópolis do Sul",
        "latitude": -20.6523,
        "longitude": -49.925
    },
    {
        "nome": "Sebastião Barros",
        "latitude": -10.817,
        "longitude": -44.8337
    },
    {
        "nome": "Sebastião Laranjeiras",
        "latitude": -14.571,
        "longitude": -42.9434
    },
    {
        "nome": "Sebastião Leal",
        "latitude": -7.56803,
        "longitude": -44.06
    },
    {
        "nome": "Seberi",
        "latitude": -27.4829,
        "longitude": -53.4026
    },
    {
        "nome": "Sede Nova",
        "latitude": -27.6367,
        "longitude": -53.9493
    },
    {
        "nome": "Segredo",
        "latitude": -29.3523,
        "longitude": -52.9767
    },
    {
        "nome": "Selbach",
        "latitude": -28.6294,
        "longitude": -52.9498
    },
    {
        "nome": "Selvíria",
        "latitude": -20.3637,
        "longitude": -51.4192
    },
    {
        "nome": "Sem-Peixe",
        "latitude": -20.1008,
        "longitude": -42.8483
    },
    {
        "nome": "Sena Madureira",
        "latitude": -9.06596,
        "longitude": -68.6571
    },
    {
        "nome": "Senador Alexandre Costa",
        "latitude": -5.25096,
        "longitude": -44.0533
    },
    {
        "nome": "Senador Amaral",
        "latitude": -22.5869,
        "longitude": -46.1763
    },
    {
        "nome": "Senador Canedo",
        "latitude": -16.7084,
        "longitude": -49.0914
    },
    {
        "nome": "Senador Cortes",
        "latitude": -21.7986,
        "longitude": -42.9424
    },
    {
        "nome": "Senador Elói de Souza",
        "latitude": -6.03334,
        "longitude": -35.6978
    },
    {
        "nome": "Senador Firmino",
        "latitude": -20.9158,
        "longitude": -43.0904
    },
    {
        "nome": "Senador Georgino Avelino",
        "latitude": -6.1576,
        "longitude": -35.1299
    },
    {
        "nome": "Senador Guiomard",
        "latitude": -10.1497,
        "longitude": -67.7362
    },
    {
        "nome": "Senador José Bento",
        "latitude": -22.1633,
        "longitude": -46.1792
    },
    {
        "nome": "Senador José Porfírio",
        "latitude": -4.31242,
        "longitude": -51.5764
    },
    {
        "nome": "Senador La Rocque",
        "latitude": -5.4461,
        "longitude": -47.2959
    },
    {
        "nome": "Senador Modestino Gonçalves",
        "latitude": -17.9465,
        "longitude": -43.2172
    },
    {
        "nome": "Senador Pompeu",
        "latitude": -5.58244,
        "longitude": -39.3704
    },
    {
        "nome": "Senador Rui Palmeira",
        "latitude": -9.46986,
        "longitude": -37.4576
    },
    {
        "nome": "Senador Sá",
        "latitude": -3.35305,
        "longitude": -40.4662
    },
    {
        "nome": "Senador Salgado Filho",
        "latitude": -28.025,
        "longitude": -54.5507
    },
    {
        "nome": "Sengés",
        "latitude": -24.1129,
        "longitude": -49.4616
    },
    {
        "nome": "Senhor do Bonfim",
        "latitude": -10.4594,
        "longitude": -40.1865
    },
    {
        "nome": "Senhora de Oliveira",
        "latitude": -20.7972,
        "longitude": -43.3394
    },
    {
        "nome": "Senhora do Porto",
        "latitude": -18.8909,
        "longitude": -43.0799
    },
    {
        "nome": "Senhora dos Remédios",
        "latitude": -21.0351,
        "longitude": -43.5812
    },
    {
        "nome": "Sentinela do Sul",
        "latitude": -30.6107,
        "longitude": -51.5862
    },
    {
        "nome": "Sento Sé",
        "latitude": -9.74138,
        "longitude": -41.8786
    },
    {
        "nome": "Serafina Corrêa",
        "latitude": -28.7126,
        "longitude": -51.9352
    },
    {
        "nome": "Sericita",
        "latitude": -20.4748,
        "longitude": -42.4828
    },
    {
        "nome": "Seringueiras",
        "latitude": -11.8055,
        "longitude": -63.0182
    },
    {
        "nome": "Sério",
        "latitude": -29.3904,
        "longitude": -52.2685
    },
    {
        "nome": "Seritinga",
        "latitude": -21.9134,
        "longitude": -44.518
    },
    {
        "nome": "Seropédica",
        "latitude": -22.7526,
        "longitude": -43.7155
    },
    {
        "nome": "Serra",
        "latitude": -20.121,
        "longitude": -40.3074
    },
    {
        "nome": "Serra Alta",
        "latitude": -26.7229,
        "longitude": -53.0409
    },
    {
        "nome": "Serra Azul",
        "latitude": -21.3074,
        "longitude": -47.5602
    },
    {
        "nome": "Serra Azul de Minas",
        "latitude": -18.3602,
        "longitude": -43.1675
    },
    {
        "nome": "Serra Branca",
        "latitude": -7.48034,
        "longitude": -36.666
    },
    {
        "nome": "Serra Caiada",
        "latitude": -6.10478,
        "longitude": -35.7113
    },
    {
        "nome": "Serra da Raiz",
        "latitude": -6.68527,
        "longitude": -35.4379
    },
    {
        "nome": "Serra da Saudade",
        "latitude": -19.4447,
        "longitude": -45.795
    },
    {
        "nome": "Serra de São Bento",
        "latitude": -6.41762,
        "longitude": -35.7033
    },
    {
        "nome": "Serra do Mel",
        "latitude": -5.17725,
        "longitude": -37.0242
    },
    {
        "nome": "Serra do Navio",
        "latitude": 0.901357,
        "longitude": -52.0036
    },
    {
        "nome": "Serra do Ramalho",
        "latitude": -13.5659,
        "longitude": -43.5929
    },
    {
        "nome": "Serra do Salitre",
        "latitude": -19.1083,
        "longitude": -46.6961
    },
    {
        "nome": "Serra dos Aimorés",
        "latitude": -17.7872,
        "longitude": -40.2453
    },
    {
        "nome": "Serra Dourada",
        "latitude": -12.759,
        "longitude": -43.9504
    },
    {
        "nome": "Serra Grande",
        "latitude": -7.20957,
        "longitude": -38.3647
    },
    {
        "nome": "Serra Negra",
        "latitude": -22.6139,
        "longitude": -46.7033
    },
    {
        "nome": "Serra Negra do Norte",
        "latitude": -6.66031,
        "longitude": -37.3996
    },
    {
        "nome": "Serra Nova Dourada",
        "latitude": -12.0896,
        "longitude": -51.4025
    },
    {
        "nome": "Serra Preta",
        "latitude": -12.156,
        "longitude": -39.3305
    },
    {
        "nome": "Serra Redonda",
        "latitude": -7.18622,
        "longitude": -35.6842
    },
    {
        "nome": "Serra Talhada",
        "latitude": -7.98178,
        "longitude": -38.289
    },
    {
        "nome": "Serrana",
        "latitude": -21.2043,
        "longitude": -47.5952
    },
    {
        "nome": "Serrania",
        "latitude": -21.5441,
        "longitude": -46.0417
    },
    {
        "nome": "Serrano do Maranhão",
        "latitude": -1.85229,
        "longitude": -45.1207
    },
    {
        "nome": "Serranópolis",
        "latitude": -18.3067,
        "longitude": -51.9586
    },
    {
        "nome": "Serranópolis de Minas",
        "latitude": -15.8176,
        "longitude": -42.8732
    },
    {
        "nome": "Serranópolis do Iguaçu",
        "latitude": -25.3799,
        "longitude": -54.0518
    },
    {
        "nome": "Serranos",
        "latitude": -21.8857,
        "longitude": -44.5125
    },
    {
        "nome": "Serraria",
        "latitude": -6.81569,
        "longitude": -35.6282
    },
    {
        "nome": "Serrinha",
        "latitude": -6.28181,
        "longitude": -35.5
    },
    {
        "nome": "Serrinha",
        "latitude": -11.6584,
        "longitude": -39.01
    },
    {
        "nome": "Serrinha dos Pintos",
        "latitude": -6.11087,
        "longitude": -37.9548
    },
    {
        "nome": "Serrita",
        "latitude": -7.94041,
        "longitude": -39.2951
    },
    {
        "nome": "Serro",
        "latitude": -18.5991,
        "longitude": -43.3744
    },
    {
        "nome": "Serrolândia",
        "latitude": -11.4085,
        "longitude": -40.2983
    },
    {
        "nome": "Sertaneja",
        "latitude": -23.0361,
        "longitude": -50.8317
    },
    {
        "nome": "Sertânia",
        "latitude": -8.06847,
        "longitude": -37.2684
    },
    {
        "nome": "Sertanópolis",
        "latitude": -23.0571,
        "longitude": -51.0399
    },
    {
        "nome": "Sertão",
        "latitude": -27.9798,
        "longitude": -52.2588
    },
    {
        "nome": "Sertão Santana",
        "latitude": -30.4562,
        "longitude": -51.6017
    },
    {
        "nome": "Sertãozinho",
        "latitude": -21.1316,
        "longitude": -47.9875
    },
    {
        "nome": "Sertãozinho",
        "latitude": -6.75127,
        "longitude": -35.4372
    },
    {
        "nome": "Sete Barras",
        "latitude": -24.382,
        "longitude": -47.9279
    },
    {
        "nome": "Sete de Setembro",
        "latitude": -28.1362,
        "longitude": -54.4637
    },
    {
        "nome": "Sete Lagoas",
        "latitude": -19.4569,
        "longitude": -44.2413
    },
    {
        "nome": "Sete Quedas",
        "latitude": -23.9705,
        "longitude": -55.0398
    },
    {
        "nome": "Setubinha",
        "latitude": -17.6002,
        "longitude": -42.1587
    },
    {
        "nome": "Severiano de Almeida",
        "latitude": -27.4362,
        "longitude": -52.1217
    },
    {
        "nome": "Severiano Melo",
        "latitude": -5.77666,
        "longitude": -37.957
    },
    {
        "nome": "Severínia",
        "latitude": -20.8108,
        "longitude": -48.8054
    },
    {
        "nome": "Siderópolis",
        "latitude": -28.5955,
        "longitude": -49.4314
    },
    {
        "nome": "Sidrolândia",
        "latitude": -20.9302,
        "longitude": -54.9692
    },
    {
        "nome": "Sigefredo Pacheco",
        "latitude": -4.91665,
        "longitude": -41.7311
    },
    {
        "nome": "Silva Jardim",
        "latitude": -22.6574,
        "longitude": -42.3961
    },
    {
        "nome": "Silvânia",
        "latitude": -16.66,
        "longitude": -48.6083
    },
    {
        "nome": "Silvanópolis",
        "latitude": -11.1471,
        "longitude": -48.1694
    },
    {
        "nome": "Silveira Martins",
        "latitude": -29.6467,
        "longitude": -53.591
    },
    {
        "nome": "Silveirânia",
        "latitude": -21.1615,
        "longitude": -43.2128
    },
    {
        "nome": "Silveiras",
        "latitude": -22.6638,
        "longitude": -44.8522
    },
    {
        "nome": "Silves",
        "latitude": -2.81748,
        "longitude": -58.248
    },
    {
        "nome": "Silvianópolis",
        "latitude": -22.0274,
        "longitude": -45.8385
    },
    {
        "nome": "Simão Dias",
        "latitude": -10.7387,
        "longitude": -37.8097
    },
    {
        "nome": "Simão Pereira",
        "latitude": -21.964,
        "longitude": -43.3088
    },
    {
        "nome": "Simões",
        "latitude": -7.59109,
        "longitude": -40.8137
    },
    {
        "nome": "Simões Filho",
        "latitude": -12.7866,
        "longitude": -38.4029
    },
    {
        "nome": "Simolândia",
        "latitude": -14.4644,
        "longitude": -46.4847
    },
    {
        "nome": "Simonésia",
        "latitude": -20.1341,
        "longitude": -42.0091
    },
    {
        "nome": "Simplício Mendes",
        "latitude": -7.85294,
        "longitude": -41.9075
    },
    {
        "nome": "Sinimbu",
        "latitude": -29.5357,
        "longitude": -52.5304
    },
    {
        "nome": "Sinop",
        "latitude": -11.8604,
        "longitude": -55.5091
    },
    {
        "nome": "Siqueira Campos",
        "latitude": -23.6875,
        "longitude": -49.8304
    },
    {
        "nome": "Sirinhaém",
        "latitude": -8.58778,
        "longitude": -35.1126
    },
    {
        "nome": "Siriri",
        "latitude": -10.5965,
        "longitude": -37.1131
    },
    {
        "nome": "Sítio d'Abadia",
        "latitude": -14.7992,
        "longitude": -46.2506
    },
    {
        "nome": "Sítio do Mato",
        "latitude": -13.0801,
        "longitude": -43.4689
    },
    {
        "nome": "Sítio do Quinto",
        "latitude": -10.3545,
        "longitude": -38.2213
    },
    {
        "nome": "Sítio Novo",
        "latitude": -5.87601,
        "longitude": -46.7033
    },
    {
        "nome": "Sítio Novo",
        "latitude": -6.11132,
        "longitude": -35.909
    },
    {
        "nome": "Sítio Novo do Tocantins",
        "latitude": -5.6012,
        "longitude": -47.6381
    },
    {
        "nome": "Sobradinho",
        "latitude": -9.45024,
        "longitude": -40.8145
    },
    {
        "nome": "Sobradinho",
        "latitude": -29.4194,
        "longitude": -53.0326
    },
    {
        "nome": "Sobrado",
        "latitude": -7.14429,
        "longitude": -35.2357
    },
    {
        "nome": "Sobral",
        "latitude": -3.68913,
        "longitude": -40.3482
    },
    {
        "nome": "Sobrália",
        "latitude": -19.2345,
        "longitude": -42.0998
    },
    {
        "nome": "Socorro",
        "latitude": -22.5903,
        "longitude": -46.5251
    },
    {
        "nome": "Socorro do Piauí",
        "latitude": -7.86773,
        "longitude": -42.4922
    },
    {
        "nome": "Solânea",
        "latitude": -6.75161,
        "longitude": -35.6636
    },
    {
        "nome": "Soledade",
        "latitude": -7.05829,
        "longitude": -36.3668
    },
    {
        "nome": "Soledade",
        "latitude": -28.8306,
        "longitude": -52.5131
    },
    {
        "nome": "Soledade de Minas",
        "latitude": -22.0554,
        "longitude": -45.0464
    },
    {
        "nome": "Solidão",
        "latitude": -7.59472,
        "longitude": -37.6445
    },
    {
        "nome": "Solonópole",
        "latitude": -5.71894,
        "longitude": -39.0107
    },
    {
        "nome": "Sombrio",
        "latitude": -29.108,
        "longitude": -49.6328
    },
    {
        "nome": "Sonora",
        "latitude": -17.5698,
        "longitude": -54.7551
    },
    {
        "nome": "Sooretama",
        "latitude": -19.1897,
        "longitude": -40.0974
    },
    {
        "nome": "Sorocaba",
        "latitude": -23.4969,
        "longitude": -47.4451
    },
    {
        "nome": "Sorriso",
        "latitude": -12.5425,
        "longitude": -55.7211
    },
    {
        "nome": "Sossêgo",
        "latitude": -6.77067,
        "longitude": -36.2538
    },
    {
        "nome": "Soure",
        "latitude": -0.73032,
        "longitude": -48.5015
    },
    {
        "nome": "Sousa",
        "latitude": -6.75148,
        "longitude": -38.2311
    },
    {
        "nome": "Souto Soares",
        "latitude": -12.088,
        "longitude": -41.6427
    },
    {
        "nome": "Sucupira",
        "latitude": -11.993,
        "longitude": -48.9685
    },
    {
        "nome": "Sucupira do Norte",
        "latitude": -6.47839,
        "longitude": -44.1919
    },
    {
        "nome": "Sucupira do Riachão",
        "latitude": -6.40858,
        "longitude": -43.5455
    },
    {
        "nome": "Sud Mennucci",
        "latitude": -20.6872,
        "longitude": -50.9238
    },
    {
        "nome": "Sul Brasil",
        "latitude": -26.7351,
        "longitude": -52.964
    },
    {
        "nome": "Sulina",
        "latitude": -25.7066,
        "longitude": -52.7299
    },
    {
        "nome": "Sumaré",
        "latitude": -22.8204,
        "longitude": -47.2728
    },
    {
        "nome": "Sumé",
        "latitude": -7.66206,
        "longitude": -36.884
    },
    {
        "nome": "Sumidouro",
        "latitude": -22.0485,
        "longitude": -42.6761
    },
    {
        "nome": "Surubim",
        "latitude": -7.84746,
        "longitude": -35.7481
    },
    {
        "nome": "Sussuapara",
        "latitude": -7.03687,
        "longitude": -41.3767
    },
    {
        "nome": "Suzanápolis",
        "latitude": -20.4981,
        "longitude": -51.0268
    },
    {
        "nome": "Suzano",
        "latitude": -23.5448,
        "longitude": -46.3112
    },
    {
        "nome": "Tabaí",
        "latitude": -29.643,
        "longitude": -51.6823
    },
    {
        "nome": "Tabaporã",
        "latitude": -11.3007,
        "longitude": -56.8312
    },
    {
        "nome": "Tabapuã",
        "latitude": -20.9602,
        "longitude": -49.0307
    },
    {
        "nome": "Tabatinga",
        "latitude": -21.7239,
        "longitude": -48.6896
    },
    {
        "nome": "Tabatinga",
        "latitude": -4.2416,
        "longitude": -69.9383
    },
    {
        "nome": "Tabira",
        "latitude": -7.58366,
        "longitude": -37.5377
    },
    {
        "nome": "Taboão da Serra",
        "latitude": -23.6019,
        "longitude": -46.7526
    },
    {
        "nome": "Tabocas do Brejo Velho",
        "latitude": -12.7026,
        "longitude": -44.0075
    },
    {
        "nome": "Taboleiro Grande",
        "latitude": -5.91948,
        "longitude": -38.0367
    },
    {
        "nome": "Tabuleiro",
        "latitude": -21.3632,
        "longitude": -43.2381
    },
    {
        "nome": "Tabuleiro do Norte",
        "latitude": -5.24353,
        "longitude": -38.1282
    },
    {
        "nome": "Tacaimbó",
        "latitude": -8.30867,
        "longitude": -36.3
    },
    {
        "nome": "Tacaratu",
        "latitude": -9.09798,
        "longitude": -38.1504
    },
    {
        "nome": "Taciba",
        "latitude": -22.3866,
        "longitude": -51.2882
    },
    {
        "nome": "Tacima",
        "latitude": -6.48759,
        "longitude": -35.6367
    },
    {
        "nome": "Tacuru",
        "latitude": -23.636,
        "longitude": -55.0141
    },
    {
        "nome": "Taguaí",
        "latitude": -23.4452,
        "longitude": -49.4024
    },
    {
        "nome": "Taguatinga",
        "latitude": -12.4026,
        "longitude": -46.437
    },
    {
        "nome": "Taiaçu",
        "latitude": -21.1431,
        "longitude": -48.5112
    },
    {
        "nome": "Tailândia",
        "latitude": -2.94584,
        "longitude": -48.9489
    },
    {
        "nome": "Taió",
        "latitude": -27.121,
        "longitude": -49.9942
    },
    {
        "nome": "Taiobeiras",
        "latitude": -15.8106,
        "longitude": -42.2259
    },
    {
        "nome": "Taipas do Tocantins",
        "latitude": -12.1873,
        "longitude": -46.9797
    },
    {
        "nome": "Taipu",
        "latitude": -5.63058,
        "longitude": -35.5918
    },
    {
        "nome": "Taiúva",
        "latitude": -21.1223,
        "longitude": -48.4528
    },
    {
        "nome": "Talismã",
        "latitude": -12.7949,
        "longitude": -49.0896
    },
    {
        "nome": "Tamandaré",
        "latitude": -8.75665,
        "longitude": -35.1033
    },
    {
        "nome": "Tamarana",
        "latitude": -23.7204,
        "longitude": -51.0991
    },
    {
        "nome": "Tambaú",
        "latitude": -21.7029,
        "longitude": -47.2703
    },
    {
        "nome": "Tamboara",
        "latitude": -23.2036,
        "longitude": -52.4743
    },
    {
        "nome": "Tamboril",
        "latitude": -4.83136,
        "longitude": -40.3196
    },
    {
        "nome": "Tamboril do Piauí",
        "latitude": -8.40937,
        "longitude": -42.9211
    },
    {
        "nome": "Tanabi",
        "latitude": -20.6228,
        "longitude": -49.6563
    },
    {
        "nome": "Tangará",
        "latitude": -6.19649,
        "longitude": -35.7989
    },
    {
        "nome": "Tangará",
        "latitude": -27.0996,
        "longitude": -51.2473
    },
    {
        "nome": "Tangará da Serra",
        "latitude": -14.6229,
        "longitude": -57.4933
    },
    {
        "nome": "Tanguá",
        "latitude": -22.7423,
        "longitude": -42.7202
    },
    {
        "nome": "Tanhaçu",
        "latitude": -14.0197,
        "longitude": -41.2473
    },
    {
        "nome": "Tanque d'Arca",
        "latitude": -9.53379,
        "longitude": -36.4366
    },
    {
        "nome": "Tanque do Piauí",
        "latitude": -6.59787,
        "longitude": -42.2795
    },
    {
        "nome": "Tanque Novo",
        "latitude": -13.5485,
        "longitude": -42.4934
    },
    {
        "nome": "Tanquinho",
        "latitude": -11.968,
        "longitude": -39.1033
    },
    {
        "nome": "Taparuba",
        "latitude": -19.7621,
        "longitude": -41.608
    },
    {
        "nome": "Tapauá",
        "latitude": -5.62085,
        "longitude": -63.1808
    },
    {
        "nome": "Tapejara",
        "latitude": -23.7315,
        "longitude": -52.8735
    },
    {
        "nome": "Tapejara",
        "latitude": -28.0652,
        "longitude": -52.0097
    },
    {
        "nome": "Tapera",
        "latitude": -28.6277,
        "longitude": -52.8613
    },
    {
        "nome": "Taperoá",
        "latitude": -13.5321,
        "longitude": -39.1009
    },
    {
        "nome": "Taperoá",
        "latitude": -7.20629,
        "longitude": -36.8245
    },
    {
        "nome": "Tapes",
        "latitude": -30.6683,
        "longitude": -51.3991
    },
    {
        "nome": "Tapira",
        "latitude": -23.3193,
        "longitude": -53.0684
    },
    {
        "nome": "Tapira",
        "latitude": -19.9166,
        "longitude": -46.8264
    },
    {
        "nome": "Tapiraí",
        "latitude": -19.8936,
        "longitude": -46.0221
    },
    {
        "nome": "Tapiraí",
        "latitude": -23.9612,
        "longitude": -47.5062
    },
    {
        "nome": "Tapiramutá",
        "latitude": -11.8475,
        "longitude": -40.7927
    },
    {
        "nome": "Tapiratiba",
        "latitude": -21.4713,
        "longitude": -46.7448
    },
    {
        "nome": "Tapurah",
        "latitude": -12.695,
        "longitude": -56.5178
    },
    {
        "nome": "Taquara",
        "latitude": -29.6505,
        "longitude": -50.7753
    },
    {
        "nome": "Taquaraçu de Minas",
        "latitude": -19.6652,
        "longitude": -43.6922
    },
    {
        "nome": "Taquaral",
        "latitude": -21.0737,
        "longitude": -48.4126
    },
    {
        "nome": "Taquaral de Goiás",
        "latitude": -16.0521,
        "longitude": -49.6039
    },
    {
        "nome": "Taquarana",
        "latitude": -9.64529,
        "longitude": -36.4928
    },
    {
        "nome": "Taquari",
        "latitude": -29.7943,
        "longitude": -51.8653
    },
    {
        "nome": "Taquaritinga",
        "latitude": -21.4049,
        "longitude": -48.5103
    },
    {
        "nome": "Taquaritinga do Norte",
        "latitude": -7.89446,
        "longitude": -36.0423
    },
    {
        "nome": "Taquarituba",
        "latitude": -23.5307,
        "longitude": -49.241
    },
    {
        "nome": "Taquarivaí",
        "latitude": -23.9211,
        "longitude": -48.6948
    },
    {
        "nome": "Taquaruçu do Sul",
        "latitude": -27.4005,
        "longitude": -53.4702
    },
    {
        "nome": "Taquarussu",
        "latitude": -22.4898,
        "longitude": -53.3519
    },
    {
        "nome": "Tarabai",
        "latitude": -22.3016,
        "longitude": -51.5621
    },
    {
        "nome": "Tarauacá",
        "latitude": -8.15697,
        "longitude": -70.7722
    },
    {
        "nome": "Tarrafas",
        "latitude": -6.67838,
        "longitude": -39.753
    },
    {
        "nome": "Tartarugalzinho",
        "latitude": 1.50652,
        "longitude": -50.9087
    },
    {
        "nome": "Tarumã",
        "latitude": -22.7429,
        "longitude": -50.5786
    },
    {
        "nome": "Tarumirim",
        "latitude": -19.2835,
        "longitude": -42.0097
    },
    {
        "nome": "Tasso Fragoso",
        "latitude": -8.4662,
        "longitude": -45.7536
    },
    {
        "nome": "Tatuí",
        "latitude": -23.3487,
        "longitude": -47.8461
    },
    {
        "nome": "Tauá",
        "latitude": -5.98585,
        "longitude": -40.2968
    },
    {
        "nome": "Taubaté",
        "latitude": -23.0104,
        "longitude": -45.5593
    },
    {
        "nome": "Tavares",
        "latitude": -31.2843,
        "longitude": -51.088
    },
    {
        "nome": "Tavares",
        "latitude": -7.62697,
        "longitude": -37.8712
    },
    {
        "nome": "Tefé",
        "latitude": -3.36822,
        "longitude": -64.7193
    },
    {
        "nome": "Teixeira",
        "latitude": -7.22104,
        "longitude": -37.2525
    },
    {
        "nome": "Teixeira de Freitas",
        "latitude": -17.5399,
        "longitude": -39.74
    },
    {
        "nome": "Teixeira Soares",
        "latitude": -25.3701,
        "longitude": -50.4571
    },
    {
        "nome": "Teixeiras",
        "latitude": -20.6561,
        "longitude": -42.8564
    },
    {
        "nome": "Teixeirópolis",
        "latitude": -10.9056,
        "longitude": -62.242
    },
    {
        "nome": "Tejuçuoca",
        "latitude": -3.98831,
        "longitude": -39.5799
    },
    {
        "nome": "Tejupá",
        "latitude": -23.3425,
        "longitude": -49.3722
    },
    {
        "nome": "Telêmaco Borba",
        "latitude": -24.3245,
        "longitude": -50.6176
    },
    {
        "nome": "Telha",
        "latitude": -10.2064,
        "longitude": -36.8818
    },
    {
        "nome": "Tenente Ananias",
        "latitude": -6.45823,
        "longitude": -38.182
    },
    {
        "nome": "Tenente Laurentino Cruz",
        "latitude": -6.1378,
        "longitude": -36.7135
    },
    {
        "nome": "Tenente Portela",
        "latitude": -27.3711,
        "longitude": -53.7585
    },
    {
        "nome": "Tenório",
        "latitude": -6.93855,
        "longitude": -36.6273
    },
    {
        "nome": "Teodoro Sampaio",
        "latitude": -12.295,
        "longitude": -38.6347
    },
    {
        "nome": "Teodoro Sampaio",
        "latitude": -22.5299,
        "longitude": -52.1682
    },
    {
        "nome": "Teofilândia",
        "latitude": -11.4827,
        "longitude": -38.9913
    },
    {
        "nome": "Teófilo Otoni",
        "latitude": -17.8595,
        "longitude": -41.5087
    },
    {
        "nome": "Teolândia",
        "latitude": -13.5896,
        "longitude": -39.484
    },
    {
        "nome": "Teotônio Vilela",
        "latitude": -9.91656,
        "longitude": -36.3492
    },
    {
        "nome": "Terenos",
        "latitude": -20.4378,
        "longitude": -54.8647
    },
    {
        "nome": "Teresina",
        "latitude": -5.09194,
        "longitude": -42.8034
    },
    {
        "nome": "Teresina de Goiás",
        "latitude": -13.7801,
        "longitude": -47.2659
    },
    {
        "nome": "Teresópolis",
        "latitude": -22.4165,
        "longitude": -42.9752
    },
    {
        "nome": "Terezinha",
        "latitude": -9.05621,
        "longitude": -36.6272
    },
    {
        "nome": "Terezópolis de Goiás",
        "latitude": -16.3945,
        "longitude": -49.0797
    },
    {
        "nome": "Terra Alta",
        "latitude": -1.02963,
        "longitude": -47.9004
    },
    {
        "nome": "Terra Boa",
        "latitude": -23.7683,
        "longitude": -52.447
    },
    {
        "nome": "Terra de Areia",
        "latitude": -29.5782,
        "longitude": -50.0644
    },
    {
        "nome": "Terra Nova",
        "latitude": -12.3888,
        "longitude": -38.6238
    },
    {
        "nome": "Terra Nova",
        "latitude": -8.22244,
        "longitude": -39.3825
    },
    {
        "nome": "Terra Nova do Norte",
        "latitude": -10.517,
        "longitude": -55.231
    },
    {
        "nome": "Terra Rica",
        "latitude": -22.7111,
        "longitude": -52.6188
    },
    {
        "nome": "Terra Roxa",
        "latitude": -24.1575,
        "longitude": -54.0988
    },
    {
        "nome": "Terra Roxa",
        "latitude": -20.787,
        "longitude": -48.3314
    },
    {
        "nome": "Terra Santa",
        "latitude": -2.10443,
        "longitude": -56.4877
    },
    {
        "nome": "Tesouro",
        "latitude": -16.0809,
        "longitude": -53.559
    },
    {
        "nome": "Teutônia",
        "latitude": -29.4482,
        "longitude": -51.8044
    },
    {
        "nome": "Theobroma",
        "latitude": -10.2483,
        "longitude": -62.3538
    },
    {
        "nome": "Tianguá",
        "latitude": -3.72965,
        "longitude": -40.9923
    },
    {
        "nome": "Tibagi",
        "latitude": -24.5153,
        "longitude": -50.4176
    },
    {
        "nome": "Tibau",
        "latitude": -4.83729,
        "longitude": -37.2554
    },
    {
        "nome": "Tibau do Sul",
        "latitude": -6.19176,
        "longitude": -35.0866
    },
    {
        "nome": "Tietê",
        "latitude": -23.1101,
        "longitude": -47.7164
    },
    {
        "nome": "Tigrinhos",
        "latitude": -26.6876,
        "longitude": -53.1545
    },
    {
        "nome": "Tijucas",
        "latitude": -27.2354,
        "longitude": -48.6322
    },
    {
        "nome": "Tijucas do Sul",
        "latitude": -25.9311,
        "longitude": -49.195
    },
    {
        "nome": "Timbaúba",
        "latitude": -7.50484,
        "longitude": -35.3119
    },
    {
        "nome": "Timbaúba dos Batistas",
        "latitude": -6.45768,
        "longitude": -37.2745
    },
    {
        "nome": "Timbé do Sul",
        "latitude": -28.8287,
        "longitude": -49.842
    },
    {
        "nome": "Timbiras",
        "latitude": -4.25597,
        "longitude": -43.932
    },
    {
        "nome": "Timbó",
        "latitude": -26.8246,
        "longitude": -49.269
    },
    {
        "nome": "Timbó Grande",
        "latitude": -26.6127,
        "longitude": -50.6607
    },
    {
        "nome": "Timburi",
        "latitude": -23.2057,
        "longitude": -49.6096
    },
    {
        "nome": "Timon",
        "latitude": -5.09769,
        "longitude": -42.8329
    },
    {
        "nome": "Timóteo",
        "latitude": -19.5811,
        "longitude": -42.6471
    },
    {
        "nome": "Tio Hugo",
        "latitude": -28.5712,
        "longitude": -52.5955
    },
    {
        "nome": "Tiradentes",
        "latitude": -21.1102,
        "longitude": -44.1744
    },
    {
        "nome": "Tiradentes do Sul",
        "latitude": -27.4022,
        "longitude": -54.0814
    },
    {
        "nome": "Tiros",
        "latitude": -19.0037,
        "longitude": -45.9626
    },
    {
        "nome": "Tobias Barreto",
        "latitude": -11.1798,
        "longitude": -37.9995
    },
    {
        "nome": "Tocantínia",
        "latitude": -9.5632,
        "longitude": -48.3741
    },
    {
        "nome": "Tocantinópolis",
        "latitude": -6.32447,
        "longitude": -47.4224
    },
    {
        "nome": "Tocantins",
        "latitude": -21.1774,
        "longitude": -43.0127
    },
    {
        "nome": "Tocos do Moji",
        "latitude": -22.3698,
        "longitude": -46.0971
    },
    {
        "nome": "Toledo",
        "latitude": -22.7421,
        "longitude": -46.3728
    },
    {
        "nome": "Toledo",
        "latitude": -24.7246,
        "longitude": -53.7412
    },
    {
        "nome": "Tomar do Geru",
        "latitude": -11.3694,
        "longitude": -37.8433
    },
    {
        "nome": "Tomazina",
        "latitude": -23.7796,
        "longitude": -49.9499
    },
    {
        "nome": "Tombos",
        "latitude": -20.9086,
        "longitude": -42.0228
    },
    {
        "nome": "Tomé-Açu",
        "latitude": -2.41302,
        "longitude": -48.1415
    },
    {
        "nome": "Tonantins",
        "latitude": -2.86582,
        "longitude": -67.7919
    },
    {
        "nome": "Toritama",
        "latitude": -8.00955,
        "longitude": -36.0637
    },
    {
        "nome": "Torixoréu",
        "latitude": -16.2006,
        "longitude": -52.5571
    },
    {
        "nome": "Toropi",
        "latitude": -29.4782,
        "longitude": -54.2244
    },
    {
        "nome": "Torre de Pedra",
        "latitude": -23.2462,
        "longitude": -48.1955
    },
    {
        "nome": "Torres",
        "latitude": -29.3334,
        "longitude": -49.7333
    },
    {
        "nome": "Torrinha",
        "latitude": -22.4237,
        "longitude": -48.1731
    },
    {
        "nome": "Touros",
        "latitude": -5.20182,
        "longitude": -35.4621
    },
    {
        "nome": "Trabiju",
        "latitude": -22.0388,
        "longitude": -48.3342
    },
    {
        "nome": "Tracuateua",
        "latitude": -1.07653,
        "longitude": -46.9031
    },
    {
        "nome": "Tracunhaém",
        "latitude": -7.80228,
        "longitude": -35.2314
    },
    {
        "nome": "Traipu",
        "latitude": -9.96262,
        "longitude": -37.0071
    },
    {
        "nome": "Trairão",
        "latitude": -4.57347,
        "longitude": -55.9429
    },
    {
        "nome": "Trairi",
        "latitude": -3.26932,
        "longitude": -39.2681
    },
    {
        "nome": "Trajano de Moraes",
        "latitude": -22.0638,
        "longitude": -42.0643
    },
    {
        "nome": "Tramandaí",
        "latitude": -29.9841,
        "longitude": -50.1322
    },
    {
        "nome": "Travesseiro",
        "latitude": -29.2977,
        "longitude": -52.0532
    },
    {
        "nome": "Tremedal",
        "latitude": -14.9736,
        "longitude": -41.4142
    },
    {
        "nome": "Tremembé",
        "latitude": -22.9571,
        "longitude": -45.5475
    },
    {
        "nome": "Três Arroios",
        "latitude": -27.5003,
        "longitude": -52.1448
    },
    {
        "nome": "Três Barras",
        "latitude": -26.1056,
        "longitude": -50.3197
    },
    {
        "nome": "Três Barras do Paraná",
        "latitude": -25.4185,
        "longitude": -53.1833
    },
    {
        "nome": "Três Cachoeiras",
        "latitude": -29.4487,
        "longitude": -49.9275
    },
    {
        "nome": "Três Corações",
        "latitude": -21.6921,
        "longitude": -45.2511
    },
    {
        "nome": "Três Coroas",
        "latitude": -29.5137,
        "longitude": -50.7739
    },
    {
        "nome": "Três de Maio",
        "latitude": -27.78,
        "longitude": -54.2357
    },
    {
        "nome": "Três Forquilhas",
        "latitude": -29.5384,
        "longitude": -50.0708
    },
    {
        "nome": "Três Fronteiras",
        "latitude": -20.2344,
        "longitude": -50.8905
    },
    {
        "nome": "Três Lagoas",
        "latitude": -20.7849,
        "longitude": -51.7007
    },
    {
        "nome": "Três Marias",
        "latitude": -18.2048,
        "longitude": -45.2473
    },
    {
        "nome": "Três Palmeiras",
        "latitude": -27.6139,
        "longitude": -52.8437
    },
    {
        "nome": "Três Passos",
        "latitude": -27.4555,
        "longitude": -53.9296
    },
    {
        "nome": "Três Pontas",
        "latitude": -21.3694,
        "longitude": -45.5109
    },
    {
        "nome": "Três Ranchos",
        "latitude": -18.3539,
        "longitude": -47.776
    },
    {
        "nome": "Três Rios",
        "latitude": -22.1165,
        "longitude": -43.2185
    },
    {
        "nome": "Treviso",
        "latitude": -28.5097,
        "longitude": -49.4634
    },
    {
        "nome": "Treze de Maio",
        "latitude": -28.5537,
        "longitude": -49.1565
    },
    {
        "nome": "Treze Tílias",
        "latitude": -27.0026,
        "longitude": -51.4084
    },
    {
        "nome": "Trindade",
        "latitude": -16.6517,
        "longitude": -49.4927
    },
    {
        "nome": "Trindade",
        "latitude": -7.759,
        "longitude": -40.2647
    },
    {
        "nome": "Trindade do Sul",
        "latitude": -27.5239,
        "longitude": -52.8956
    },
    {
        "nome": "Triunfo",
        "latitude": -29.9291,
        "longitude": -51.7075
    },
    {
        "nome": "Triunfo",
        "latitude": -6.5713,
        "longitude": -38.5986
    },
    {
        "nome": "Triunfo",
        "latitude": -7.83272,
        "longitude": -38.0978
    },
    {
        "nome": "Triunfo Potiguar",
        "latitude": -5.85408,
        "longitude": -37.1786
    },
    {
        "nome": "Trizidela do Vale",
        "latitude": -4.538,
        "longitude": -44.628
    },
    {
        "nome": "Trombas",
        "latitude": -13.5079,
        "longitude": -48.7417
    },
    {
        "nome": "Trombudo Central",
        "latitude": -27.3033,
        "longitude": -49.793
    },
    {
        "nome": "Tubarão",
        "latitude": -28.4713,
        "longitude": -49.0144
    },
    {
        "nome": "Tucano",
        "latitude": -10.9584,
        "longitude": -38.7894
    },
    {
        "nome": "Tucumã",
        "latitude": -6.74687,
        "longitude": -51.1626
    },
    {
        "nome": "Tucunduva",
        "latitude": -27.6573,
        "longitude": -54.4439
    },
    {
        "nome": "Tucuruí",
        "latitude": -3.7657,
        "longitude": -49.6773
    },
    {
        "nome": "Tufilândia",
        "latitude": -3.67355,
        "longitude": -45.6238
    },
    {
        "nome": "Tuiuti",
        "latitude": -22.8193,
        "longitude": -46.6937
    },
    {
        "nome": "Tumiritinga",
        "latitude": -18.9844,
        "longitude": -41.6527
    },
    {
        "nome": "Tunápolis",
        "latitude": -26.9681,
        "longitude": -53.6417
    },
    {
        "nome": "Tunas",
        "latitude": -29.1039,
        "longitude": -52.9538
    },
    {
        "nome": "Tunas do Paraná",
        "latitude": -24.9731,
        "longitude": -49.0879
    },
    {
        "nome": "Tuneiras do Oeste",
        "latitude": -23.8648,
        "longitude": -52.8769
    },
    {
        "nome": "Tuntum",
        "latitude": -5.25476,
        "longitude": -44.6444
    },
    {
        "nome": "Tupã",
        "latitude": -21.9335,
        "longitude": -50.5191
    },
    {
        "nome": "Tupaciguara",
        "latitude": -18.5866,
        "longitude": -48.6985
    },
    {
        "nome": "Tupanatinga",
        "latitude": -8.74798,
        "longitude": -37.3445
    },
    {
        "nome": "Tupanci do Sul",
        "latitude": -27.9241,
        "longitude": -51.5383
    },
    {
        "nome": "Tupanciretã",
        "latitude": -29.0858,
        "longitude": -53.8445
    },
    {
        "nome": "Tupandi",
        "latitude": -29.4772,
        "longitude": -51.4174
    },
    {
        "nome": "Tuparendi",
        "latitude": -27.7598,
        "longitude": -54.4814
    },
    {
        "nome": "Tuparetama",
        "latitude": -7.6003,
        "longitude": -37.3165
    },
    {
        "nome": "Tupãssi",
        "latitude": -24.5879,
        "longitude": -53.5105
    },
    {
        "nome": "Tupi Paulista",
        "latitude": -21.3825,
        "longitude": -51.575
    },
    {
        "nome": "Tupirama",
        "latitude": -8.97168,
        "longitude": -48.1883
    },
    {
        "nome": "Tupiratins",
        "latitude": -8.39388,
        "longitude": -48.1277
    },
    {
        "nome": "Turiaçu",
        "latitude": -1.65893,
        "longitude": -45.3798
    },
    {
        "nome": "Turilândia",
        "latitude": -2.21638,
        "longitude": -45.3044
    },
    {
        "nome": "Turiúba",
        "latitude": -20.9428,
        "longitude": -50.1135
    },
    {
        "nome": "Turmalina",
        "latitude": -20.0486,
        "longitude": -50.4792
    },
    {
        "nome": "Turmalina",
        "latitude": -17.2828,
        "longitude": -42.7285
    },
    {
        "nome": "Turuçu",
        "latitude": -31.4173,
        "longitude": -52.1706
    },
    {
        "nome": "Tururu",
        "latitude": -3.58413,
        "longitude": -39.4297
    },
    {
        "nome": "Turvânia",
        "latitude": -16.6125,
        "longitude": -50.1369
    },
    {
        "nome": "Turvelândia",
        "latitude": -17.8502,
        "longitude": -50.3024
    },
    {
        "nome": "Turvo",
        "latitude": -25.0437,
        "longitude": -51.5282
    },
    {
        "nome": "Turvo",
        "latitude": -28.9272,
        "longitude": -49.6831
    },
    {
        "nome": "Turvolândia",
        "latitude": -21.8733,
        "longitude": -45.7859
    },
    {
        "nome": "Tutóia",
        "latitude": -2.76141,
        "longitude": -42.2755
    },
    {
        "nome": "Uarini",
        "latitude": -2.99609,
        "longitude": -65.1133
    },
    {
        "nome": "Uauá",
        "latitude": -9.83325,
        "longitude": -39.4794
    },
    {
        "nome": "Ubá",
        "latitude": -21.1204,
        "longitude": -42.9359
    },
    {
        "nome": "Ubaí",
        "latitude": -16.2885,
        "longitude": -44.7783
    },
    {
        "nome": "Ubaíra",
        "latitude": -13.2714,
        "longitude": -39.666
    },
    {
        "nome": "Ubaitaba",
        "latitude": -14.303,
        "longitude": -39.3222
    },
    {
        "nome": "Ubajara",
        "latitude": -3.85448,
        "longitude": -40.9204
    },
    {
        "nome": "Ubaporanga",
        "latitude": -19.6351,
        "longitude": -42.1059
    },
    {
        "nome": "Ubarana",
        "latitude": -21.165,
        "longitude": -49.7198
    },
    {
        "nome": "Ubatã",
        "latitude": -14.2063,
        "longitude": -39.5207
    },
    {
        "nome": "Ubatuba",
        "latitude": -23.4332,
        "longitude": -45.0834
    },
    {
        "nome": "Uberaba",
        "latitude": -19.7472,
        "longitude": -47.9381
    },
    {
        "nome": "Uberlândia",
        "latitude": -18.9141,
        "longitude": -48.2749
    },
    {
        "nome": "Ubirajara",
        "latitude": -22.5272,
        "longitude": -49.6613
    },
    {
        "nome": "Ubiratã",
        "latitude": -24.5393,
        "longitude": -52.9865
    },
    {
        "nome": "Ubiretama",
        "latitude": -28.0404,
        "longitude": -54.686
    },
    {
        "nome": "Uchoa",
        "latitude": -20.9511,
        "longitude": -49.1713
    },
    {
        "nome": "Uibaí",
        "latitude": -11.3394,
        "longitude": -42.1354
    },
    {
        "nome": "Uiramutã",
        "latitude": 4.60314,
        "longitude": -60.1815
    },
    {
        "nome": "Uirapuru",
        "latitude": -14.2835,
        "longitude": -49.9201
    },
    {
        "nome": "Uiraúna",
        "latitude": -6.51504,
        "longitude": -38.4128
    },
    {
        "nome": "Ulianópolis",
        "latitude": -3.75007,
        "longitude": -47.4892
    },
    {
        "nome": "Umari",
        "latitude": -6.63893,
        "longitude": -38.7008
    },
    {
        "nome": "Umarizal",
        "latitude": -5.98238,
        "longitude": -37.818
    },
    {
        "nome": "Umbaúba",
        "latitude": -11.3809,
        "longitude": -37.6623
    },
    {
        "nome": "Umburanas",
        "latitude": -10.7339,
        "longitude": -41.3234
    },
    {
        "nome": "Umburatiba",
        "latitude": -17.2548,
        "longitude": -40.5779
    },
    {
        "nome": "Umbuzeiro",
        "latitude": -7.69199,
        "longitude": -35.6582
    },
    {
        "nome": "Umirim",
        "latitude": -3.67654,
        "longitude": -39.3465
    },
    {
        "nome": "Umuarama",
        "latitude": -23.7656,
        "longitude": -53.3201
    },
    {
        "nome": "Una",
        "latitude": -15.2791,
        "longitude": -39.0765
    },
    {
        "nome": "Unaí",
        "latitude": -16.3592,
        "longitude": -46.9022
    },
    {
        "nome": "União",
        "latitude": -4.58571,
        "longitude": -42.8583
    },
    {
        "nome": "União da Serra",
        "latitude": -28.7833,
        "longitude": -52.0238
    },
    {
        "nome": "União da Vitória",
        "latitude": -26.2273,
        "longitude": -51.0873
    },
    {
        "nome": "União de Minas",
        "latitude": -19.5299,
        "longitude": -50.338
    },
    {
        "nome": "União do Oeste",
        "latitude": -26.762,
        "longitude": -52.8541
    },
    {
        "nome": "União do Sul",
        "latitude": -11.5308,
        "longitude": -54.3616
    },
    {
        "nome": "União dos Palmares",
        "latitude": -9.15921,
        "longitude": -36.0223
    },
    {
        "nome": "União Paulista",
        "latitude": -20.8862,
        "longitude": -49.9025
    },
    {
        "nome": "Uniflor",
        "latitude": -23.0868,
        "longitude": -52.1573
    },
    {
        "nome": "Unistalda",
        "latitude": -29.04,
        "longitude": -55.1517
    },
    {
        "nome": "Upanema",
        "latitude": -5.63761,
        "longitude": -37.2635
    },
    {
        "nome": "Uraí",
        "latitude": -23.2,
        "longitude": -50.7939
    },
    {
        "nome": "Urandi",
        "latitude": -14.7678,
        "longitude": -42.6498
    },
    {
        "nome": "Urânia",
        "latitude": -20.2455,
        "longitude": -50.6455
    },
    {
        "nome": "Urbano Santos",
        "latitude": -3.20642,
        "longitude": -43.3878
    },
    {
        "nome": "Uru",
        "latitude": -21.7866,
        "longitude": -49.2848
    },
    {
        "nome": "Uruaçu",
        "latitude": -14.5238,
        "longitude": -49.1396
    },
    {
        "nome": "Uruana",
        "latitude": -15.4993,
        "longitude": -49.6861
    },
    {
        "nome": "Uruana de Minas",
        "latitude": -16.0634,
        "longitude": -46.2443
    },
    {
        "nome": "Uruará",
        "latitude": -3.71519,
        "longitude": -53.7396
    },
    {
        "nome": "Urubici",
        "latitude": -28.0157,
        "longitude": -49.5925
    },
    {
        "nome": "Uruburetama",
        "latitude": -3.62316,
        "longitude": -39.5107
    },
    {
        "nome": "Urucânia",
        "latitude": -20.3521,
        "longitude": -42.737
    },
    {
        "nome": "Urucará",
        "latitude": -2.52936,
        "longitude": -57.7538
    },
    {
        "nome": "Uruçuca",
        "latitude": -14.5963,
        "longitude": -39.2851
    },
    {
        "nome": "Uruçuí",
        "latitude": -7.23944,
        "longitude": -44.5577
    },
    {
        "nome": "Urucuia",
        "latitude": -16.1244,
        "longitude": -45.7352
    },
    {
        "nome": "Urucurituba",
        "latitude": -3.12841,
        "longitude": -58.1496
    },
    {
        "nome": "Uruguaiana",
        "latitude": -29.7614,
        "longitude": -57.0853
    },
    {
        "nome": "Uruoca",
        "latitude": -3.30819,
        "longitude": -40.5628
    },
    {
        "nome": "Urupá",
        "latitude": -11.1261,
        "longitude": -62.3639
    },
    {
        "nome": "Urupema",
        "latitude": -27.9557,
        "longitude": -49.8729
    },
    {
        "nome": "Urupês",
        "latitude": -21.2032,
        "longitude": -49.2931
    },
    {
        "nome": "Urussanga",
        "latitude": -28.518,
        "longitude": -49.3238
    },
    {
        "nome": "Urutaí",
        "latitude": -17.4651,
        "longitude": -48.2015
    },
    {
        "nome": "Utinga",
        "latitude": -12.0783,
        "longitude": -41.0954
    },
    {
        "nome": "Vacaria",
        "latitude": -28.5079,
        "longitude": -50.9418
    },
    {
        "nome": "Vale de São Domingos",
        "latitude": -15.286,
        "longitude": -59.0683
    },
    {
        "nome": "Vale do Anari",
        "latitude": -9.86215,
        "longitude": -62.1876
    },
    {
        "nome": "Vale do Paraíso",
        "latitude": -10.4465,
        "longitude": -62.1352
    },
    {
        "nome": "Vale do Sol",
        "latitude": -29.5967,
        "longitude": -52.6839
    },
    {
        "nome": "Vale Real",
        "latitude": -29.3919,
        "longitude": -51.2559
    },
    {
        "nome": "Vale Verde",
        "latitude": -29.7864,
        "longitude": -52.1857
    },
    {
        "nome": "Valença",
        "latitude": -13.3669,
        "longitude": -39.073
    },
    {
        "nome": "Valença",
        "latitude": -22.2445,
        "longitude": -43.7129
    },
    {
        "nome": "Valença do Piauí",
        "latitude": -6.40301,
        "longitude": -41.7375
    },
    {
        "nome": "Valente",
        "latitude": -11.4062,
        "longitude": -39.457
    },
    {
        "nome": "Valentim Gentil",
        "latitude": -20.4217,
        "longitude": -50.0889
    },
    {
        "nome": "Valinhos",
        "latitude": -22.9698,
        "longitude": -46.9974
    },
    {
        "nome": "Valparaíso",
        "latitude": -21.2229,
        "longitude": -50.8699
    },
    {
        "nome": "Valparaíso de Goiás",
        "latitude": -16.0651,
        "longitude": -47.9757
    },
    {
        "nome": "Vanini",
        "latitude": -28.4758,
        "longitude": -51.8447
    },
    {
        "nome": "Vargeão",
        "latitude": -26.8621,
        "longitude": -52.1549
    },
    {
        "nome": "Vargem",
        "latitude": -27.4867,
        "longitude": -50.9724
    },
    {
        "nome": "Vargem",
        "latitude": -22.887,
        "longitude": -46.4124
    },
    {
        "nome": "Vargem Alegre",
        "latitude": -19.5988,
        "longitude": -42.2949
    },
    {
        "nome": "Vargem Alta",
        "latitude": -20.669,
        "longitude": -41.0179
    },
    {
        "nome": "Vargem Bonita",
        "latitude": -20.3333,
        "longitude": -46.3688
    },
    {
        "nome": "Vargem Bonita",
        "latitude": -27.0055,
        "longitude": -51.7402
    },
    {
        "nome": "Vargem Grande",
        "latitude": -3.53639,
        "longitude": -43.917
    },
    {
        "nome": "Vargem Grande do Rio Pardo",
        "latitude": -15.3987,
        "longitude": -42.3085
    },
    {
        "nome": "Vargem Grande do Sul",
        "latitude": -21.8322,
        "longitude": -46.8913
    },
    {
        "nome": "Vargem Grande Paulista",
        "latitude": -23.5993,
        "longitude": -47.022
    },
    {
        "nome": "Varginha",
        "latitude": -21.5556,
        "longitude": -45.4364
    },
    {
        "nome": "Varjão",
        "latitude": -17.0471,
        "longitude": -49.6312
    },
    {
        "nome": "Varjão de Minas",
        "latitude": -18.3741,
        "longitude": -46.0313
    },
    {
        "nome": "Varjota",
        "latitude": -4.19387,
        "longitude": -40.4741
    },
    {
        "nome": "Varre-Sai",
        "latitude": -20.9276,
        "longitude": -41.8701
    },
    {
        "nome": "Várzea",
        "latitude": -6.34641,
        "longitude": -35.3732
    },
    {
        "nome": "Várzea",
        "latitude": -6.76189,
        "longitude": -36.9913
    },
    {
        "nome": "Várzea Alegre",
        "latitude": -6.78264,
        "longitude": -39.2942
    },
    {
        "nome": "Várzea Branca",
        "latitude": -9.238,
        "longitude": -42.9692
    },
    {
        "nome": "Várzea da Palma",
        "latitude": -17.5944,
        "longitude": -44.7226
    },
    {
        "nome": "Várzea da Roça",
        "latitude": -11.6005,
        "longitude": -40.1328
    },
    {
        "nome": "Várzea do Poço",
        "latitude": -11.5273,
        "longitude": -40.3149
    },
    {
        "nome": "Várzea Grande",
        "latitude": -6.54899,
        "longitude": -42.248
    },
    {
        "nome": "Várzea Grande",
        "latitude": -15.6458,
        "longitude": -56.1322
    },
    {
        "nome": "Várzea Nova",
        "latitude": -11.2557,
        "longitude": -40.9432
    },
    {
        "nome": "Várzea Paulista",
        "latitude": -23.2136,
        "longitude": -46.8234
    },
    {
        "nome": "Varzedo",
        "latitude": -12.9672,
        "longitude": -39.3919
    },
    {
        "nome": "Varzelândia",
        "latitude": -15.6992,
        "longitude": -44.0278
    },
    {
        "nome": "Vassouras",
        "latitude": -22.4059,
        "longitude": -43.6686
    },
    {
        "nome": "Vazante",
        "latitude": -17.9827,
        "longitude": -46.9088
    },
    {
        "nome": "Venâncio Aires",
        "latitude": -29.6143,
        "longitude": -52.1932
    },
    {
        "nome": "Venda Nova do Imigrante",
        "latitude": -20.327,
        "longitude": -41.1355
    },
    {
        "nome": "Venha-Ver",
        "latitude": -6.32016,
        "longitude": -38.4896
    },
    {
        "nome": "Ventania",
        "latitude": -24.2458,
        "longitude": -50.2376
    },
    {
        "nome": "Venturosa",
        "latitude": -8.57885,
        "longitude": -36.8742
    },
    {
        "nome": "Vera",
        "latitude": -12.3017,
        "longitude": -55.3045
    },
    {
        "nome": "Vera Cruz",
        "latitude": -6.04399,
        "longitude": -35.428
    },
    {
        "nome": "Vera Cruz",
        "latitude": -12.9568,
        "longitude": -38.6153
    },
    {
        "nome": "Vera Cruz",
        "latitude": -29.7184,
        "longitude": -52.5152
    },
    {
        "nome": "Vera Cruz",
        "latitude": -22.2183,
        "longitude": -49.8207
    },
    {
        "nome": "Vera Cruz do Oeste",
        "latitude": -25.0577,
        "longitude": -53.8771
    },
    {
        "nome": "Vera Mendes",
        "latitude": -7.59748,
        "longitude": -41.4673
    },
    {
        "nome": "Veranópolis",
        "latitude": -28.9312,
        "longitude": -51.5516
    },
    {
        "nome": "Verdejante",
        "latitude": -7.92235,
        "longitude": -38.9701
    },
    {
        "nome": "Verdelândia",
        "latitude": -15.5845,
        "longitude": -43.6121
    },
    {
        "nome": "Verê",
        "latitude": -25.8772,
        "longitude": -52.9051
    },
    {
        "nome": "Vereda",
        "latitude": -17.2183,
        "longitude": -40.0974
    },
    {
        "nome": "Veredinha",
        "latitude": -17.3974,
        "longitude": -42.7307
    },
    {
        "nome": "Veríssimo",
        "latitude": -19.6657,
        "longitude": -48.3118
    },
    {
        "nome": "Vermelho Novo",
        "latitude": -20.0406,
        "longitude": -42.2688
    },
    {
        "nome": "Vertente do Lério",
        "latitude": -7.77084,
        "longitude": -35.8491
    },
    {
        "nome": "Vertentes",
        "latitude": -7.90158,
        "longitude": -35.9681
    },
    {
        "nome": "Vespasiano",
        "latitude": -19.6883,
        "longitude": -43.9239
    },
    {
        "nome": "Vespasiano Corrêa",
        "latitude": -29.0655,
        "longitude": -51.8625
    },
    {
        "nome": "Viadutos",
        "latitude": -27.5716,
        "longitude": -52.0211
    },
    {
        "nome": "Viamão",
        "latitude": -30.0819,
        "longitude": -51.0194
    },
    {
        "nome": "Viana",
        "latitude": -20.3825,
        "longitude": -40.4933
    },
    {
        "nome": "Viana",
        "latitude": -3.20451,
        "longitude": -44.9912
    },
    {
        "nome": "Vianópolis",
        "latitude": -16.7405,
        "longitude": -48.5159
    },
    {
        "nome": "Vicência",
        "latitude": -7.65655,
        "longitude": -35.3139
    },
    {
        "nome": "Vicente Dutra",
        "latitude": -27.1607,
        "longitude": -53.4022
    },
    {
        "nome": "Vicentina",
        "latitude": -22.4098,
        "longitude": -54.4415
    },
    {
        "nome": "Vicentinópolis",
        "latitude": -17.7322,
        "longitude": -49.8047
    },
    {
        "nome": "Viçosa",
        "latitude": -5.98253,
        "longitude": -37.9462
    },
    {
        "nome": "Viçosa",
        "latitude": -9.36763,
        "longitude": -36.2431
    },
    {
        "nome": "Viçosa",
        "latitude": -20.7559,
        "longitude": -42.8742
    },
    {
        "nome": "Viçosa do Ceará",
        "latitude": -3.5667,
        "longitude": -41.0916
    },
    {
        "nome": "Victor Graeff",
        "latitude": -28.5632,
        "longitude": -52.7495
    },
    {
        "nome": "Vidal Ramos",
        "latitude": -27.3886,
        "longitude": -49.3593
    },
    {
        "nome": "Videira",
        "latitude": -27.0086,
        "longitude": -51.1543
    },
    {
        "nome": "Vieiras",
        "latitude": -20.867,
        "longitude": -42.2401
    },
    {
        "nome": "Vieirópolis",
        "latitude": -6.50684,
        "longitude": -38.2567
    },
    {
        "nome": "Vigia",
        "latitude": -0.861194,
        "longitude": -48.1386
    },
    {
        "nome": "Vila Bela da Santíssima Trindade",
        "latitude": -15.0068,
        "longitude": -59.9504
    },
    {
        "nome": "Vila Boa",
        "latitude": -15.0387,
        "longitude": -47.052
    },
    {
        "nome": "Vila Flor",
        "latitude": -6.31287,
        "longitude": -35.067
    },
    {
        "nome": "Vila Flores",
        "latitude": -28.8598,
        "longitude": -51.5504
    },
    {
        "nome": "Vila Lângaro",
        "latitude": -28.1062,
        "longitude": -52.1438
    },
    {
        "nome": "Vila Maria",
        "latitude": -28.5359,
        "longitude": -52.1486
    },
    {
        "nome": "Vila Nova do Piauí",
        "latitude": -7.13272,
        "longitude": -40.9345
    },
    {
        "nome": "Vila Nova do Sul",
        "latitude": -30.3461,
        "longitude": -53.876
    },
    {
        "nome": "Vila Nova dos Martírios",
        "latitude": -5.18889,
        "longitude": -48.1336
    },
    {
        "nome": "Vila Pavão",
        "latitude": -18.6091,
        "longitude": -40.609
    },
    {
        "nome": "Vila Propício",
        "latitude": -15.4542,
        "longitude": -48.8819
    },
    {
        "nome": "Vila Rica",
        "latitude": -10.0137,
        "longitude": -51.1186
    },
    {
        "nome": "Vila Valério",
        "latitude": -18.9958,
        "longitude": -40.3849
    },
    {
        "nome": "Vila Velha",
        "latitude": -20.3417,
        "longitude": -40.2875
    },
    {
        "nome": "Vilhena",
        "latitude": -12.7502,
        "longitude": -60.1488
    },
    {
        "nome": "Vinhedo",
        "latitude": -23.0302,
        "longitude": -46.9833
    },
    {
        "nome": "Viradouro",
        "latitude": -20.8734,
        "longitude": -48.293
    },
    {
        "nome": "Virgem da Lapa",
        "latitude": -16.807,
        "longitude": -42.3431
    },
    {
        "nome": "Virgínia",
        "latitude": -22.3264,
        "longitude": -45.0965
    },
    {
        "nome": "Virginópolis",
        "latitude": -18.8154,
        "longitude": -42.7015
    },
    {
        "nome": "Virgolândia",
        "latitude": -18.4738,
        "longitude": -42.3067
    },
    {
        "nome": "Virmond",
        "latitude": -25.3829,
        "longitude": -52.1987
    },
    {
        "nome": "Visconde do Rio Branco",
        "latitude": -21.0127,
        "longitude": -42.8361
    },
    {
        "nome": "Viseu",
        "latitude": -1.19124,
        "longitude": -46.1399
    },
    {
        "nome": "Vista Alegre",
        "latitude": -27.3686,
        "longitude": -53.4919
    },
    {
        "nome": "Vista Alegre do Alto",
        "latitude": -21.1692,
        "longitude": -48.6284
    },
    {
        "nome": "Vista Alegre do Prata",
        "latitude": -28.8052,
        "longitude": -51.7947
    },
    {
        "nome": "Vista Gaúcha",
        "latitude": -27.2902,
        "longitude": -53.6974
    },
    {
        "nome": "Vista Serrana",
        "latitude": -6.7303,
        "longitude": -37.5704
    },
    {
        "nome": "Vitor Meireles",
        "latitude": -26.8782,
        "longitude": -49.8328
    },
    {
        "nome": "Vitória",
        "latitude": -20.3155,
        "longitude": -40.3128
    },
    {
        "nome": "Vitória Brasil",
        "latitude": -20.1956,
        "longitude": -50.4875
    },
    {
        "nome": "Vitória da Conquista",
        "latitude": -14.8615,
        "longitude": -40.8442
    },
    {
        "nome": "Vitória das Missões",
        "latitude": -28.3516,
        "longitude": -54.504
    },
    {
        "nome": "Vitória de Santo Antão",
        "latitude": -8.12819,
        "longitude": -35.2976
    },
    {
        "nome": "Vitória do Jari",
        "latitude": -0.938,
        "longitude": -52.424
    },
    {
        "nome": "Vitória do Mearim",
        "latitude": -3.45125,
        "longitude": -44.8643
    },
    {
        "nome": "Vitória do Xingu",
        "latitude": -2.87922,
        "longitude": -52.0088
    },
    {
        "nome": "Vitorino",
        "latitude": -26.2683,
        "longitude": -52.7843
    },
    {
        "nome": "Vitorino Freire",
        "latitude": -4.28184,
        "longitude": -45.2505
    },
    {
        "nome": "Volta Grande",
        "latitude": -21.7671,
        "longitude": -42.5375
    },
    {
        "nome": "Volta Redonda",
        "latitude": -22.5202,
        "longitude": -44.0996
    },
    {
        "nome": "Votorantim",
        "latitude": -23.5446,
        "longitude": -47.4388
    },
    {
        "nome": "Votuporanga",
        "latitude": -20.4237,
        "longitude": -49.9781
    },
    {
        "nome": "Wagner",
        "latitude": -12.2819,
        "longitude": -41.1715
    },
    {
        "nome": "Wall Ferraz",
        "latitude": -7.23151,
        "longitude": -41.905
    },
    {
        "nome": "Wanderlândia",
        "latitude": -6.85274,
        "longitude": -47.9601
    },
    {
        "nome": "Wanderley",
        "latitude": -12.1144,
        "longitude": -43.8958
    },
    {
        "nome": "Wenceslau Braz",
        "latitude": -22.5368,
        "longitude": -45.3626
    },
    {
        "nome": "Wenceslau Braz",
        "latitude": -23.8742,
        "longitude": -49.8032
    },
    {
        "nome": "Wenceslau Guimarães",
        "latitude": -13.6908,
        "longitude": -39.4762
    },
    {
        "nome": "Westfália",
        "latitude": -29.4263,
        "longitude": -51.7645
    },
    {
        "nome": "Witmarsum",
        "latitude": -26.9275,
        "longitude": -49.7947
    },
    {
        "nome": "Xambioá",
        "latitude": -6.4141,
        "longitude": -48.532
    },
    {
        "nome": "Xambrê",
        "latitude": -23.7364,
        "longitude": -53.4884
    },
    {
        "nome": "Xangri-lá",
        "latitude": -29.8065,
        "longitude": -50.0519
    },
    {
        "nome": "Xanxerê",
        "latitude": -26.8747,
        "longitude": -52.4036
    },
    {
        "nome": "Xapuri",
        "latitude": -10.6516,
        "longitude": -68.4969
    },
    {
        "nome": "Xavantina",
        "latitude": -27.0667,
        "longitude": -52.343
    },
    {
        "nome": "Xaxim",
        "latitude": -26.9596,
        "longitude": -52.5374
    },
    {
        "nome": "Xexéu",
        "latitude": -8.8046,
        "longitude": -35.6212
    },
    {
        "nome": "Xinguara",
        "latitude": -7.0983,
        "longitude": -49.9437
    },
    {
        "nome": "Xique-Xique",
        "latitude": -10.823,
        "longitude": -42.7245
    },
    {
        "nome": "Zabelê",
        "latitude": -8.07901,
        "longitude": -37.1057
    },
    {
        "nome": "Zacarias",
        "latitude": -21.0506,
        "longitude": -50.0552
    },
    {
        "nome": "Zé Doca",
        "latitude": -3.27014,
        "longitude": -45.6553
    },
    {
        "nome": "Zortéa",
        "latitude": -27.4521,
        "longitude": -51.552
    }
]


export const LocationReducer: Reducer<{nome: string, latitude:number, longitude: number}[]> = (state = INITIAL_STATE, action) => {
  return state
}