import * as S from './buttonBack.styles'
import { HTMLAttributes } from 'react'

interface ButtonBackProps extends HTMLAttributes<HTMLDivElement> {
  click?: () => void
  title: string
  subTitle?: string
}

export const ButtonBack = ({
  click,
  title,
  subTitle,
  ...props
}: ButtonBackProps) => {
  return (
    <S.Container onClick={click} {...props}>
      <S.IconBack />
      <div>
        <S.Title>{title}</S.Title>
        <S.SubTitle>{subTitle}</S.SubTitle>
      </div>
    </S.Container>
  )
}
