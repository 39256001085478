/* eslint-disable no-unused-vars */
export const ModalActions = {
  SET_MODAL_STATE: '@modal/SET_MODAL_STATE',
  CLEAR_MODAL_STATE: '@modal/CLEAR_MODAL_STATE'
}

export type ModalState = {
  open: boolean
  message: string
  variant: 'error' | 'success'
  description?: string
  buttonText?: string
  click?: () => void
}
