import { action } from 'typesafe-actions'
import { PurchaseOrder, PurchasesActions } from './types'

export const purchasesOrderCustomerRequest = () =>
  action(PurchasesActions.PURCHASE_ORDER_CUSTOMER_REQUEST)

export const purchasesOrderCustomerSuccess = (data: {
  items: PurchaseOrder[]
  total: number
}) => action(PurchasesActions.PURCHASE_ORDER_CUSTOMER_SUCCESS, data)

export const setPurchaseOrderCustomer = (id: string) =>
  action(PurchasesActions.SET_PURCHASE_ORDER_CUSTOMER, id)

export const viewMorePurchaseOrderRequest = () =>
  action(PurchasesActions.VIEW_MORE_PURCHASE_ORDER_REQUEST)

export const viewMorePurchaseOrderFailure = () =>
  action(PurchasesActions.VIEW_MORE_PURCHASE_ORDER_FAILURE)

export const viewMorePurchaseOrderSuccess = (data: {
  items: PurchaseOrder[]
  total: number
}) => action(PurchasesActions.VIEW_MORE_PURCHASE_ORDER_SUCCESS, data)

export const clearItemsPerPage = () =>
  action(PurchasesActions.CLEAR_ITEMS_PER_PAGE)

export const purchasesInitialStateAction = () =>
  action(PurchasesActions.INITIAL_STATE)

export const getPurchaseOrderByIdRequestAction = (purchaseId: string) =>
  action(PurchasesActions.GET_PURCHASE_ORDER_BY_ID_REQUEST, purchaseId)

export const getPurchaseOrderByIdSuccessAction = (
  purchaseOrder: PurchaseOrder
) => action(PurchasesActions.GET_PURCHASE_ORDER_BY_ID_SUCCESS, purchaseOrder)
