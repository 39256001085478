import { publicApi } from '../../config/api'
import { AxiosResponse } from 'axios'
import { EventItem } from '../../../redux/Purchases/types'

export type ListEventResponse = {
  items: {
    id: string
    name: string
    description: string
    dates: string[]
    eventItem: EventItem[]
    cashBackPercent: number | null
    address: {
      id: string
      country: string
      state: string
      city: string
      street: string
      number: number
      zipCode: string
      latitude?: string
      longitude?: string
      complement?: string
      createdAt: string
      updatedAt?: string
      creatorId?: string
      customerId?: string
      eventId: string
      seatMapId?: string
    }
    banners: { mobileUrl: string; tabletUrl: string; desktopUrl: string }[]
    createdAt: string
    updatedAt?: string
  }[]
  total: number
}

export const listEventsService = async (location?: string) => {
  const { data }: AxiosResponse<ListEventResponse> = await publicApi.get(
    location ? `/api/events/filters/search?city=${location}` : '/api/events'
  )

  return data
}
