import axios from 'axios'
import store from '../../redux/store'
import { decode } from 'jsonwebtoken'
import moment from 'moment'
import history from '../../routes/services/history'
import { logoutAction, refreshTokenAction } from '../../redux/User/actions'
import { refreshTokenService } from '../services/token/refreshTokenService'

export const apiUrl = process.env.REACT_APP_API_URL

export const publicApi = axios.create({
  baseURL: apiUrl,
  headers: {
    'Cache-Control': 's-maxage=0',
    Pragma: 'no-cache',
    Expires: '0'
  }
})

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Cache-Control': 's-maxage=0',
    Pragma: 'no-cache',
    Expires: '0'
  }
})

api.interceptors.request.use(async (config) => {
  const { dispatch, getState } = store
  const { accessToken, refreshToken } = getState().UserReducer

  if (
    accessToken != null &&
    refreshToken != null &&
    accessToken !== '' &&
    refreshToken !== ''
  ) {
    const decodedAccessToken = decode(accessToken)
    const decodedRefreshToken = decode(refreshToken)

    if (
      decodedAccessToken !== null &&
      typeof decodedAccessToken !== 'string' &&
      decodedRefreshToken !== null &&
      typeof decodedRefreshToken !== 'string'
    ) {
      if (moment().isAfter(moment(Number(decodedAccessToken.exp) * 1000))) {
        if (moment().isAfter(moment(Number(decodedRefreshToken.exp) * 1000))) {
          dispatch(logoutAction())
          history.push('/')
          throw new axios.Cancel('Operation canceled by the user.')
        } else {
          try {
            const data = await refreshTokenService(refreshToken)
            dispatch(
              refreshTokenAction({
                accessToken: data.accessToken,
                refreshToken: data.refreshToken
              })
            )
            const headers = { ...config.headers }

            headers.Authorization = `Bearer ${data.accessToken}`

            return { ...config, headers }
          } catch (e) {
            dispatch(logoutAction())
            throw new axios.Cancel('Operation canceled by the user.')
          }
        }
      } else {
        const headers = { ...config.headers }

        if (accessToken) {
          headers.Authorization = `Bearer ${accessToken}`
        }
        return { ...config, headers }
      }
    }
  } else {
    const headers = { ...config.headers }
    return { ...config, headers }
  }
})

api.interceptors.response.use(
  async (response) => {
    return response
  },
  async (error) => {
    const { dispatch } = store
    if (error?.response?.status === 401) {
      dispatch(logoutAction())
      return Promise.reject(error)
    } else return Promise.reject(error)
  }
)

export default api
