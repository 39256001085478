import * as Yup from 'yup'

export const paymentSchema = Yup.object().shape({
  creditCardOwner: Yup.string().required('Titular do cartão é obrigatório.'),
  creditCardNumber: Yup.string()
    .required('Número do cartão é obrigatório.')
    .test('validateNumber', 'Número de cartão inválido', (value) => {
      if (value && value.replace(/[._ ]/g, '').length >= 13) {
        return true
      } else return false
    }),
  creditCardValidity: Yup.string()
    .required('Validade do cartão é obrigatória.')
    .test('validateValidity', 'Insira uma data válida', (values) => {
      if (values?.replace(/[_/]/g, '').length === 4) {
        return true
      } else return false
    }),
  creditCardCVV: Yup.string().required('CVV é obrigatório.')
})
