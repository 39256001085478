import { all, call, delay, put, takeLatest } from 'redux-saga/effects'
import { ContentActions, Course, Events, Banners } from './types'
import {
  ListEventResponse,
  listEventsService
} from '../../api/services/Events/listEvents'
import {
  listCoursesSuccessAction,
  listEventsSuccessAction,
  listBannersSuccessAction
} from './actions'
import { clearLoading, setLoading } from '../Loading/actions'
import { listCoursesService } from '../../api/services/courses/listCoursesService'
import { PayloadAction } from 'typesafe-actions'
import { filterEventService } from '../../api/services/Events/filterEventService'
import { listAllBannersService } from '../../api/services/listAllBannersService'

export function* listEventsRequest(action: PayloadAction<'', string>) {
  try {
    yield put(setLoading())
    const data: Events = yield call(listEventsService, action.payload)
    const banners: Banners = yield call(listAllBannersService)
    yield put(listBannersSuccessAction(banners))
    yield delay(2000)
    yield put(clearLoading())
    yield put(listEventsSuccessAction({ events: data }))
  } catch (e) {
    yield put(clearLoading())
  }
}

export function* listEventsRequestByCategory(
  action: PayloadAction<'', string>
) {
  try {
    yield put(setLoading())
    const data: ListEventResponse = yield call(filterEventService, {
      name: action.payload
    })
    yield put(listEventsSuccessAction({ events: data }))
    yield delay(1000)
    yield put(clearLoading())
  } catch (e) {
    yield put(clearLoading())
  }
}

export function* listCourses() {
  try {
    yield put(setLoading())
    const data: Course[] = yield call(listCoursesService)
    yield put(listCoursesSuccessAction(data))
    yield delay(3000)
    yield put(clearLoading())
  } catch (e) {
    yield put(clearLoading())
  }
}

export const ContentsSaga = all([
  takeLatest(ContentActions.listAllEventsRequest, listEventsRequest),
  takeLatest(ContentActions.listCoursesRequest, listCourses),
  takeLatest(
    ContentActions.filterEventsByCategoryRequest,
    listEventsRequestByCategory
  )
])
