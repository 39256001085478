import { Button, Heading, Hidden, Paragraph } from 'applaus-ui-kit'
import { Dispatch, SetStateAction, useState } from 'react'
import { BottomNavigation } from '../../../components/bottomNavigation/BottomNavigation'
import * as S from './Search.styles'
import { DateRange, Range } from 'react-date-range'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { ptBR } from 'date-fns/locale'
import { MultiRangeSlider } from '../../../components/range-slider/RangeSlider'
import { ButtonBack } from '../../../components/buttonBack/buttonBack'
import { OrderModal } from './components/orderModal/OrderModal'
import { ListEventResponse } from '../../../api/services/Events/listEvents'
import { MobileTicketCard } from '../../shared/MobileTicketCard/Ticket'
import { AppBar } from '../../../components/appBar/AppBar'
import { EventCard } from '../../shared/EventCard/EventCard'
import history from '../../../routes/services/history'
import { useQuery } from '../../../hooks/useQuery'
import { Container as GridContainer } from '../../../components/grid/GridSystem'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import moment from 'moment'

type Props = {
  eventsFound: ListEventResponse | undefined
  products: { isOpen: boolean; events: boolean; course: boolean }
  categoryMobile: string
  setCategoryMobile: Dispatch<SetStateAction<string>>
  setProducts: Dispatch<
    SetStateAction<{
      isOpen: boolean
      events: boolean
      course: boolean
    }>
  >
  date: {
    isOpen: boolean
    date: undefined | Date[]
  }
  setDate: Dispatch<
    SetStateAction<{
      isOpen: boolean
      date: undefined | Date[]
    }>
  >

  price: {
    isOpen?: boolean | undefined
    cashBack?: string | undefined
    price: {
      minValue: number | undefined
      maxValue: number | undefined
    }
  }
  setPrice: Dispatch<
    SetStateAction<{
      isOpen?: boolean | undefined
      cashBack?: string | undefined
      price: {
        minValue: number | undefined
        maxValue: number | undefined
      }
    }>
  >
  orderByState: {
    isOpen: boolean
    options: string[]
    actualOption: string
  }

  setOrderByState: Dispatch<
    SetStateAction<{
      isOpen: boolean
      options: string[]
      actualOption: string
    }>
  >
}

export const SearchPage = ({
  date,
  setDate,
  categoryMobile,
  setCategoryMobile,
  products,
  setProducts,
  price,
  setPrice,
  eventsFound,
  orderByState,
  setOrderByState
}: Props) => {
  const searchQuery = useQuery()

  const [searchEventName, setSearchEventName] = useState(
    searchQuery.get('name')
  )
  const [showFilters, setShowFilters] = useState(false)
  const [showOrderModal, setShowOrderModal] = useState(false)

  const [state, setState] = useState<Range[]>([
    {
      startDate: (date.date && date.date[0]) ?? undefined,
      endDate: (date.date && date.date[1]) ?? undefined,
      key: 'selection'
    }
  ])

  const handleCloseModal = () => {
    setShowOrderModal(false)
  }

  const handleClickCard = (id: string) => {
    history.push(`/event/${id}`)
  }

  const [dateState, setDateState] = useState<Date[] | undefined>(undefined)
  const [categoryState, setCategoryState] = useState(
    categoryMobile === '' ? 'Todas' : categoryMobile
  )
  const [priceState, setPriceState] = useState<{
    isOpen?: boolean
    cashBack?: string | undefined
    price: {
      minValue: number
      maxValue: number
    }
  }>({
    isOpen: false,
    cashBack: undefined,
    price: {
      minValue: 0,
      maxValue: 1000
    }
  })
  // const categoryOptions = [
  //   'Todas',
  //   'Shows',
  //   'Esporte',
  //   'Teatro',
  //   'Eventos online',
  //   'Palestras'
  // ]

  const handleAplyFilters = () => {
    setCategoryMobile(categoryState === 'Todas' ? '' : categoryState)
    setDate((prev) => ({
      ...prev,
      date: dateState
    }))
    setPrice((prev) => ({
      ...prev,
      price: priceState.price,
      cashBack: priceState.cashBack
    }))
  }

  const handleCleanFilters = () => {
    history.push('/search?name=')
    setCategoryMobile('')
    setOrderByState({
      isOpen: false,
      options: ['Relevância', 'Data', 'Localização'],
      actualOption: 'Relevância'
    })
    setProducts({ isOpen: false, events: true, course: false })
    setDate({ date: undefined, isOpen: false })
    setPrice({
      isOpen: false,
      cashBack: undefined,
      price: {
        minValue: 0,
        maxValue: 1000
      }
    })
    setPriceState({
      isOpen: false,
      cashBack: undefined,
      price: {
        minValue: 0,
        maxValue: 1000
      }
    })
  }

  return (
    <>
      <Hidden mobile>
        <AppBar />
      </Hidden>

      <GridContainer>
        <S.Container modalIsOpen={showOrderModal}>
          {showFilters ? (
            <S.FilterModal>
              <S.ModalContent>
                <Hidden mobile>
                  <S.CloseIcon onClick={() => setShowFilters(false)} />
                </Hidden>

                <Hidden mobile>
                  <Heading variant="h5" className="title">
                    Filtros
                  </Heading>
                </Hidden>

                <Hidden tablet>
                  <ButtonBack
                    title="Filtros"
                    click={() => setShowFilters(false)}
                  />
                </Hidden>
                <Hidden mobile>
                  <S.Divider />
                </Hidden>
                <S.Category>
                  <Paragraph variant="regular" type="bold">
                    Categoria
                  </Paragraph>
                  <div className="buttonsCategory">
                    {/* {categoryOptions.map((categoryOption, index) => (
                      <S.StyledButton
                        active={categoryOption === categoryState}
                        variant="contained"
                        color="primary"
                        size="small"
                        fullWidth={false}
                        key={index}
                        onClick={() => setCategoryState(categoryOption)}>
                        {categoryOption}
                      </S.StyledButton>
                    ))} */}
                    <S.CategoryRow1>
                      <S.StyledButton
                        active={categoryState === 'Todas'}
                        onClick={() => setCategoryState('Todas')}>
                        Todas
                      </S.StyledButton>
                      <S.StyledButton
                        active={categoryState === 'Shows'}
                        onClick={() => setCategoryState('Shows')}>
                        {'Shows'}
                      </S.StyledButton>
                      <S.StyledButton
                        active={categoryState === 'Esporte'}
                        onClick={() => setCategoryState('Esporte')}>
                        {'Esporte'}
                      </S.StyledButton>
                    </S.CategoryRow1>

                    <S.CategoryRow2>
                      <S.StyledButton
                        active={categoryState === 'Teatro'}
                        onClick={() => setCategoryState('Teatro')}>
                        {'Teatro'}
                      </S.StyledButton>
                      <S.StyledButton
                        active={categoryState === 'Eventos online'}
                        onClick={() => setCategoryState('Eventos online')}>
                        {'Eventos online'}
                      </S.StyledButton>
                    </S.CategoryRow2>

                    <S.CategoryRow3>
                      <S.StyledButton
                        className="buttonsRow3"
                        active={categoryState === 'Palestras'}
                        onClick={() => setCategoryState('Palestras')}>
                        {'Palestras'}
                      </S.StyledButton>
                    </S.CategoryRow3>
                  </div>
                </S.Category>

                <Hidden mobile>
                  <S.Divider />
                </Hidden>
                <S.Date>
                  <Paragraph variant="regular" type="bold">
                    Data
                  </Paragraph>
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => {
                      setState([item.selection])
                      if (item.selection.startDate && item.selection.endDate) {
                        setDateState([
                          item.selection.startDate,
                          item.selection.endDate
                        ])
                      }
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                    locale={ptBR}
                    weekdayDisplayFormat="EEEEEE"
                    rangeColors={['#B88BCF']}
                    color="#B88BCF"
                  />
                </S.Date>
                <Hidden mobile>
                  <S.Divider />
                </Hidden>
                <S.Pricing>
                  <Paragraph variant="regular" type="bold">
                    Preço
                  </Paragraph>
                  <MultiRangeSlider
                    min={0}
                    max={1000}
                    minValue={priceState.price.minValue || 0}
                    maxValue={priceState.price.maxValue || 0}
                    setPrice={setPriceState}
                  />
                  <S.OptionsBox>
                    <S.Options
                      onClick={() =>
                        setPriceState({
                          price: { maxValue: 1000, minValue: 0 }
                        })
                      }
                      isActive={
                        priceState.price.minValue === 0 &&
                        priceState.price.maxValue === 1000
                      }>
                      <Paragraph variant="regular">Todos</Paragraph>
                    </S.Options>
                    <S.Options
                      isActive={
                        priceState.price.minValue === 0 &&
                        priceState.price.maxValue === 0
                      }
                      onClick={() =>
                        setPriceState({
                          price: { maxValue: 0, minValue: 0 }
                        })
                      }>
                      <Paragraph variant="regular">Grátis</Paragraph>
                    </S.Options>
                    <S.Options
                      isActive={priceState.cashBack === 'true'}
                      onClick={() =>
                        setPriceState((prev) => ({ ...prev, cashBack: 'true' }))
                      }>
                      <Paragraph variant="regular">Cashback</Paragraph>
                    </S.Options>
                    <S.Options
                      isActive={
                        !!(
                          priceState.price.minValue &&
                          priceState.price.maxValue > 0
                        )
                      }>
                      <Paragraph variant="regular">Pagos</Paragraph>
                    </S.Options>
                  </S.OptionsBox>
                </S.Pricing>
                <div className="actionsBox">
                  {price.price.minValue !== undefined ||
                  price.cashBack !== undefined ||
                  date.date ||
                  categoryMobile !== '' ||
                  orderByState.actualOption === 'Data' ||
                  orderByState.actualOption === 'Localização' ||
                  (searchQuery.get('name') &&
                    searchQuery.get('name') !== '') ? (
                    <Button
                      color="primary"
                      size="small"
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        handleCleanFilters()
                        setShowFilters(false)
                      }}>
                      Limpar filtros
                    </Button>
                  ) : null}
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      handleAplyFilters()
                      setShowFilters(false)
                    }}>
                    Aplicar Filtros
                  </Button>
                </div>
              </S.ModalContent>
            </S.FilterModal>
          ) : (
            <Container style={{ padding: 0 }}>
              <Row>
                <Col xs={12} md={12}>
                  <S.InputWrapper>
                    <S.StyledInput
                      placeholder="Procure eventos, cursos, livros..."
                      onChange={(e) => {
                        setSearchEventName(e.target.value)
                      }}
                      onKeyPress={(e) => {
                        e.key === 'Enter' &&
                          history.push(`/search?name=${searchEventName}`)
                      }}
                      value={searchEventName ?? ''}
                    />
                    <S.SearchIcon onClick={() => {}} />
                  </S.InputWrapper>
                </Col>
              </Row>
              <Hidden mobile>
                <Row>
                  <Col xs={6} md={6}>
                    {eventsFound && eventsFound.items.length > 0 ? (
                      <S.EventsQuantityBox>
                        <Paragraph variant="large" type="bold">
                          {eventsFound?.items.length}
                        </Paragraph>
                        <Paragraph variant="large" type="bold">
                          EVENTOS ENCONTRADOS
                        </Paragraph>
                      </S.EventsQuantityBox>
                    ) : undefined}
                  </Col>
                </Row>
              </Hidden>
              <S.FiltersButtonRow>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  fullWidth={false}
                  onClick={() => setShowFilters(true)}
                  className="btn-filter">
                  Filtros
                  <S.FilterIcon />
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  fullWidth={false}
                  onClick={() => setShowOrderModal(true)}>
                  Ordernar
                </Button>
              </S.FiltersButtonRow>

              <S.CardsEvent>
                {eventsFound?.items
                  .filter((event) => {
                    if (
                      event.eventItem.find((item) =>
                        moment().isBefore(item.finalSales)
                      )
                    )
                      return true
                    else return false
                  })
                  .map((e) => (
                    <>
                      <Hidden tablet>
                        <MobileTicketCard
                          click={() => handleClickCard(e.id)}
                          titleEvent={e.name}
                          locationEvent={e.address.city}
                          dateEvent={e.eventItem.flatMap(({ dates }) =>
                            dates.flatMap((date) => date)
                          )}
                          image={e.banners[0].mobileUrl}
                        />
                      </Hidden>
                      <Hidden mobile className="tablet-view-events">
                        <EventCard key={e.id} {...e} showInfo />
                      </Hidden>
                    </>
                  ))}
              </S.CardsEvent>
            </Container>
          )}
        </S.Container>
        <OrderModal
          isOpen={showOrderModal}
          closeModal={handleCloseModal}
          orderByState={orderByState}
          setOrderByState={setOrderByState}
        />
        <Hidden tablet>
          <div style={{ height: '65px' }} />
          <BottomNavigation active="search" />
        </Hidden>
      </GridContainer>
    </>
  )
}
