import { AxiosResponse } from 'axios'
import { PurchaseOrder } from '../../../redux/Purchases/types'
import api from '../../config/api'

export type CashbackHistoryResponse = {
  cashBackHistory: {
    id: string
    customerId: string
    cashBackValueCents: number
    balanceType: string
    createdAt: string
    purchaseOrder: PurchaseOrder
  }[]
  pendingCashBack: {
    id: string
    customerId: string
    cashBackValueCents: number
    createdAt: string
    updatedAt: string
    purchaseOrderId: string
    purchaseOrder: PurchaseOrder
  }[]
}

export type CashbackHistoryResponseFormated = {
  id: string
  customerId: string
  cashBackValueCents: number
  balanceType: string
  createdAt: string
  purchaseOrder: PurchaseOrder
}[]

export const getCashbackHistoryService = async () => {
  const { data }: AxiosResponse<CashbackHistoryResponse> = await api.get(
    '/api/customers/cashback/balance'
  )

  const formatData = [
    ...data.cashBackHistory,
    ...data.pendingCashBack.map((item) => ({
      ...item,
      balanceType: 'BALANCE_PENDING'
    }))
  ]

  return formatData
}
