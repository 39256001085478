import { ReactNode } from 'react'
import { AppBar } from '../../appBar/AppBar'
import { Footer } from '../../footer/Footer'
import { Container } from '../../grid/GridSystem'

type Props = {
  hasFooter: boolean
  hasHeader: boolean
  headerType?: 'normal' | 'search'
  children: ReactNode
}

export const GeneralLayout = ({
  hasFooter = true,
  hasHeader = true,
  headerType,
  children
}: Props) => {
  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {hasHeader ? <AppBar hideSearch={headerType === 'normal'} /> : undefined}
      <Container>{children}</Container>
      {hasFooter ? <Footer /> : undefined}
    </div>
  )
}
