import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/rootReducer'
import * as S from './Home.styles'

import { Slick, SliderSettings } from '../../../components/slick/Slick'
import { BannerCard } from './Components/BannerCard/BannerCard'
import { CategoryCard } from './Components/CategoryCard/CategoryCard'
import { BottomNavigation } from '../../../components/bottomNavigation/BottomNavigation'
import { Hidden } from 'applaus-ui-kit'
import { AppBar } from '../../../components/appBar/AppBar'

import { CashBackEvents } from './Components/CashBackEvents/CashBackEvents'
import { Courses } from './Components/CoursesSection/CoursesSections'
import { useEffect } from 'react'
import { listEventsRequestAction } from '../../../redux/Content/actions'
import { clearEvent } from '../../../redux/Event/actions'
import { Footer } from '../../../components/footer/Footer'
import { addLocationAction } from '../../../redux/User/actions'
import { motion } from 'framer-motion'
import { Loading } from '../../../components/loading/Loading'

const bannerSettings: SliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true
}

export const Home = () => {
  const { ContentsReducer, LocationReducer, LoadingReducer } = useSelector(
    (state: ReduxState) => state
  )

  const { banners, categories } = ContentsReducer
  const dispatch = useDispatch()

  function calculateDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0
    } else {
      const radlat1 = (Math.PI * lat1) / 180
      const radlat2 = (Math.PI * lat2) / 180
      const theta = lon1 - lon2
      const radtheta = (Math.PI * theta) / 180
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      if (dist > 1) {
        dist = 1
      }
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515

      return dist
    }
  }

  useEffect(() => {
    if (LocationReducer.length > 0) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          const calcDistance = LocationReducer.map((city) =>
            calculateDistance(
              coords.latitude,
              coords.longitude,
              city.latitude,
              city.longitude
            )
          )

          const menor = Math.min.apply(null, calcDistance)
          dispatch(
            addLocationAction(LocationReducer[calcDistance.indexOf(menor)].nome)
          )
          dispatch(
            listEventsRequestAction(
              LocationReducer[calcDistance.indexOf(menor)].nome
            )
          )
        },
        (error: any) => {
          if (error.code === 1) {
            dispatch(listEventsRequestAction())
            dispatch(clearEvent())
          }
        }
      )
    }
  }, [])

  return (
    <>
      {!ContentsReducer.isLoading ? (
        <motion.div
          initial={{ opacity: 0 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          animate={{ opacity: 1 }}>
          <S.Container>
            <Hidden mobile>
              <AppBar />
            </Hidden>
            <>
              <S.BannerWrapper>
                <Slick settings={bannerSettings}>
                  {banners.map((banner) => (
                    <BannerCard key={banner.id} {...banner} />
                  ))}
                </Slick>
              </S.BannerWrapper>
              <S.CategoryWrapper>
                {categories.map((category, index) => (
                  <CategoryCard
                    key={category.id}
                    index={index + 1}
                    {...category}
                  />
                ))}
              </S.CategoryWrapper>
              <CashBackEvents />
              <Courses />
            </>
            <Hidden desktop tablet>
              <div style={{ height: '65px' }} />
              <BottomNavigation active="home" />
            </Hidden>
            <Footer />
          </S.Container>
        </motion.div>
      ) : (
        <Loading
          isLoading={ContentsReducer.isLoading && !LoadingReducer.isLoading}
          blur={false}
          disableBackground={LoadingReducer.isLoading}
        />
      )}
    </>
  )
}
