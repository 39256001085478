import { OrderProcess as OrderProcessMobile } from './Mobile/OrderProcess'
import { OrderProcess as OrderProcessDesktop } from './Desktop/OrderProcess'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { Redirect } from 'react-router-dom'

export const OrderProcess = () => {
  const { isMobile, isDesktop, isTablet } = useWindowSize()

  if (isDesktop || isTablet) {
    return <OrderProcessDesktop />
  } else if (isMobile) {
    return <OrderProcessMobile />
  } else return <Redirect to="/checkout" />
}
