import { Paragraph, RadioButton } from 'applaus-ui-kit'
import * as S from './ButtonCard.styles'
import { formatPrice } from '../../utils/formatPrice'

type ButtonCardProps = {
  title: string
  type: 'creditCard' | 'bankSlip' | 'pix' | 'cashback'
  radioButton?: boolean
  click?: () => void
  isChecked?: boolean
  cashBackValue?: number
}

export const ButtonCard = ({
  title,
  type,
  radioButton,
  click,
  isChecked,
  cashBackValue
}: ButtonCardProps) => {
  return (
    <S.Container onClick={click}>
      {radioButton ? (
        <RadioButton name="card" onClick={click} readOnly checked={isChecked} />
      ) : null}

      {type === 'creditCard' ? (
        <S.CreditCardIcon />
      ) : type === 'bankSlip' ? (
        <S.BankSlipIcon />
      ) : type === 'pix' ? (
        <S.PixIcon />
      ) : type === 'cashback' ? (
        <S.CashBackIcon />
      ) : null}

      <Paragraph variant="large" type="semiBold" className="name">
        {title}
      </Paragraph>
      {cashBackValue && (
        <Paragraph variant="regular" className="cashbackPrice">
          {formatPrice(cashBackValue)}
        </Paragraph>
      )}
    </S.Container>
  )
}
