import { ReactNode } from 'react'
import history from '../../../routes/services/history'
import * as S from './ProfileTemplate.styles'
import logo from '../../../assets/logo.png'
import { Hidden, Paragraph } from 'applaus-ui-kit'
import { useDispatch } from 'react-redux'
import { logoutAction } from '../../../redux/User/actions'
import { purchasesOrderCustomerRequest } from '../../../redux/Purchases/actions'

type Props = {
  children: ReactNode
  active:
    | 'purchases'
    | 'account'
    | 'updatePassword'
    | 'purchaseHistory'
    | 'cashbackHistory'
}

export const ProfileTemplate = ({ children, active }: Props) => {
  const dispatch = useDispatch()

  return (
    <S.Container>
      <Hidden mobile tablet>
        <S.Wrapper>
          <S.Logo src={logo} alt="applaus" onClick={() => history.push('/')} />
          <S.MenuButtonsBox>
            <Hidden mobile>
              <S.MenuButton active={false} onClick={() => history.push('/')}>
                <Hidden desktop>
                  <S.ChevronRightIcon />
                </Hidden>
                <Hidden mobile>
                  <div className="content">
                    <S.HomeIcon />
                    <Paragraph variant="regular" type="normal" className="text">
                      Home
                    </Paragraph>
                  </div>
                </Hidden>
              </S.MenuButton>
            </Hidden>

            <Hidden mobile>
              <S.MenuButton
                active={active === 'purchases'}
                onClick={() => {
                  history.push('/purchases')
                  dispatch(purchasesOrderCustomerRequest())
                }}>
                <div className="content">
                  <S.TicketIcon />
                  <Paragraph variant="regular" type="normal" className="text">
                    Meus Eventos
                  </Paragraph>
                </div>

                <Hidden desktop>
                  <S.ChevronRightIcon />
                </Hidden>
              </S.MenuButton>
            </Hidden>

            <S.MenuButton
              active={active === 'account'}
              onClick={() => history.push('/profile')}>
              <div className="content">
                <S.DataIcon />
                <Paragraph variant="regular" type="normal" className="text">
                  Minha Conta
                </Paragraph>
              </div>

              <Hidden desktop>
                <S.ChevronRightIcon />
              </Hidden>
            </S.MenuButton>

            <S.MenuButton
              active={active === 'purchaseHistory'}
              onClick={() => history.push('/purchaseHistory')}>
              <div className="content">
                <S.FolderIcon />
                <Paragraph variant="regular" type="normal" className="text">
                  Histórico de compra
                </Paragraph>
              </div>
              <Hidden desktop>
                <S.ChevronRightIcon />
              </Hidden>
            </S.MenuButton>

            <S.MenuButton
              active={active === 'cashbackHistory'}
              onClick={() => history.push('/cashbackHistory')}>
              <div className="content">
                <S.CashbackIcon />
                <Paragraph variant="regular" type="normal" className="text">
                  Cashback
                </Paragraph>
              </div>
              <Hidden desktop>
                <S.ChevronRightIcon />
              </Hidden>
            </S.MenuButton>

            <S.MenuButton
              active={false}
              onClick={() => dispatch(logoutAction())}>
              <div className="content">
                <S.SignoutIcon />
                <Paragraph variant="regular" type="normal" className="text">
                  Sair
                </Paragraph>
              </div>
              <Hidden desktop>
                <S.ChevronRightIcon />
              </Hidden>
            </S.MenuButton>
          </S.MenuButtonsBox>
        </S.Wrapper>
      </Hidden>
      {children}
    </S.Container>
  )
}
