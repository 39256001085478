import { AxiosResponse } from 'axios'
import { Course } from '../../../redux/Content/types'
import { publicApi } from '../../config/api'

export type ListcoursesResponse = {
  items: Course[]
}

export const listCoursesService = async () => {
  const { data }: AxiosResponse<ListcoursesResponse> = await publicApi.get(
    'api/courses'
  )

  return data.items
}
