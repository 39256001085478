import * as S from './Unauthenticated.styles'
import BackButton from '../../../../../components/backButton/BackButton'
import { Button, Paragraph } from 'applaus-ui-kit'
import { useHistory } from 'react-router-dom'

export const UnauthenticatedTemplate = () => {
  const history = useHistory()
  return (
    <S.Container>
      <BackButton title="Perfil" onClick={history.goBack} />
      <Paragraph variant="regular" type="normal" className="text">
        Faça login ou cadastre-se para ter acesso às suas informações pessoais
      </Paragraph>
      <S.ButtonBox>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          fullWidth
          onClick={() => {
            window.location.href = process.env.REACT_APP_AUTH_WEB_URL ?? ''
          }}>
          Entrar
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => {
            window.location.href =
              `${process.env.REACT_APP_AUTH_WEB_URL}/register` ?? ''
          }}
          fullWidth>
          Cadastrar
        </Button>
      </S.ButtonBox>
    </S.Container>
  )
}
