import produce from 'immer'
import { Reducer } from 'redux'
import { PurchaseOrder, PurchasesActions, PurchasesState } from './types'

const initialState: PurchasesState = {
  itemsPerPage: 10,
  purchasesState: {
    items: [],
    total: 0
  }
}

export const PurchasesReducer: Reducer<PurchasesState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PurchasesActions.PURCHASE_ORDER_CUSTOMER_SUCCESS:
      return produce(state, (draftState) => {
        draftState.purchasesState.items = action.payload.items
        draftState.purchasesState.total = action.payload.total
        draftState.itemsPerPage = 10
      })
    case PurchasesActions.SET_PURCHASE_ORDER_CUSTOMER:
      return produce(state, (draftState) => {
        const findPurchaseOrder = draftState.purchasesState.items.findIndex(
          (item) => item.id === action.payload
        )

        if (findPurchaseOrder > -1) {
          draftState.selectedPurchaseOrder =
            draftState.purchasesState.items[findPurchaseOrder]
        }
      })

    case PurchasesActions.GET_PURCHASE_ORDER_BY_ID_SUCCESS:
      return produce(state, (draftState) => {
        draftState.selectedPurchaseOrder = action.payload as PurchaseOrder
      })
    case PurchasesActions.VIEW_MORE_PURCHASE_ORDER_SUCCESS:
      return produce(state, (draftState) => {
        action.payload.items.map((item: PurchaseOrder) =>
          draftState.purchasesState.items.push(item)
        )
        draftState.itemsPerPage += 10
      })
    case PurchasesActions.CLEAR_ITEMS_PER_PAGE:
      return produce(state, (draftState) => {
        draftState.itemsPerPage = 10
      })
    default:
      return state
  }
}
