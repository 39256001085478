import produce from 'immer'
import { Reducer } from 'redux'
import { ContentActions, ContentState } from './types'

const INITIAL_STATE: ContentState = {
  isLoading: true,
  banners: [],
  categories: [
    { id: 'asdijqwdiqow87qw89d7', name: 'Shows' },
    { id: 'asdiqwdqwd98q7dw9qwd', name: 'Eventos Online' },
    { id: 'asd4q9w8dqw8d4q6w5dq4', name: 'Esportes' },
    { id: 'asdq4wdqw65d4q6w5d4qw', name: 'Palestras' },
    { id: 'asdqwdqwdqwdqwdqwdasda5454', name: 'Teatro' }
  ],
  courses: []
}

export const ContentsReducer: Reducer<ContentState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case ContentActions.listAllBannersSuccess:
      return produce(state, (draft) => {
        draft.banners = action.payload
      })
    case ContentActions.listAllEventSuccess:
      return produce(state, (draft) => {
        draft.events = action.payload.events
        draft.isLoading = false
      })
    case ContentActions.listCourseSuccess:
      return produce(state, (draft) => {
        draft.courses = action.payload
      })
    default:
      return state
  }
}
