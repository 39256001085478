import { ShowTicket as ShowTicketMobile } from './Mobile/ShowTicket'
import { ShowTicket as ShowTicketTablet } from './Tablet/ShowTicket'
import { Hidden } from 'applaus-ui-kit'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPurchaseOrderByIdRequestAction } from '../../../redux/Purchases/actions'
import { ReduxState } from '../../../redux/rootReducer'

export const Tickets = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { selectedPurchaseOrder } = useSelector(
    (state: ReduxState) => state.PurchasesReducer
  )

  useEffect(() => {
    dispatch(getPurchaseOrderByIdRequestAction(id))
  }, [])

  return (
    <>
      {selectedPurchaseOrder ? (
        <>
          <Hidden desktop tablet>
            <ShowTicketMobile />
          </Hidden>
          <Hidden mobile>
            <ShowTicketTablet />
          </Hidden>
        </>
      ) : undefined}
    </>
  )
}
