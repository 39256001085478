import styled, { css } from 'styled-components'

import { ReactComponent as LocationFile } from '../../../assets/icons/map-marker.svg'
import { ReactComponent as ClockFile } from '../../../assets/icons/clock.svg'
import { Hidden } from 'applaus-ui-kit'

export const ClockIcon = styled(ClockFile)`
  ${({ theme }) => css`
    fill: ${theme.color.base.nth1};
    width: 20px;
    max-height: 20px;
    @media (max-width: 767px) {
      max-width: 16px;
      max-height: 16px;
    }
  `}
`

export const LocationIcon = styled(LocationFile)`
  ${({ theme }) => css`
    fill: ${theme.color.base.nth1};
    width: 20px;
    height: 20px;
    @media (max-width: 767px) {
      max-width: 16px;
      max-height: 16px;
    }
  `}
`
export const Container = styled.div`
  ${({ theme }) => css`
    transition: 0.3s ease;
    display: flex;
    box-shadow: 0px 6.01942px 18.0583px rgba(0, 0, 0, 0.16);
    flex-direction: column;
    border-radius: 4px;
    cursor: pointer;
    .event-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      text-align: left;
    }
    .icon-text-box {
      display: flex;
      align-items: center;
      > p {
        margin-left: ${theme.spacing.inline.xxxs};
      }

      margin-bottom: ${theme.spacing.stack.nano};
    }
    @media (max-width: 767px) {
      .icon-text-box {
        > p {
          margin-left: 4px;
          white-space: nowrap;
          max-width: 80px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        margin-bottom: 0px;
      }

      .event-name {
        text-overflow: ellipsis;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
      }
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      .icon-text-box {
        > p {
          margin-left: ${theme.spacing.inline.small};
        }
      }
      .event-name {
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `}
`

export const EventImage = styled.img<{ hasInfo?: boolean }>`
  ${({ theme, hasInfo }) => css`
    @media (max-width: 767px) {
      width: 127px;
      height: 68px;
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      width: 195px;
      height: 106px;
    }

    @media (min-width: 1171px) {
      width: 310px;
      height: 170px;
    }

    border-radius: 4px;
  `}
`
export const EventIfonWrapper = styled.div`
  padding: 16px 16px 24px 16px;
  max-width: 310px;
  .event-info {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
  }

  @media (min-width: 768px) and (max-width: 1170px) {
    padding: 8px;
    max-width: 195px;
    .event-info {
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      text-align: left;
    }
  }
  @media (max-width: 767px) {
    padding: 8px;
    max-width: 127px;
    .event-info {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      text-align: left;
    }
  }
`

export const MobileImageWrapper = styled(Hidden)`
  height: 68px;
`

export const ImageWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
  `}
`

export const CashBackContent = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 8px;
    left: 8px;
    background: white;
    padding: 8px;
    border-radius: 16px;
    > p {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
      color: ${theme.color.brand.primary.nth1};
    }

    @media (max-width: 767px) {
      padding: 4px;
      > p {
        font-size: 9px;
        font-style: normal;
        font-weight: 600;
        line-height: 15px;
      }
    }
  `}
`
