import styled, { css } from 'styled-components'

import { ReactComponent as SearchFile } from '../../../assets/icons/search.svg'

import { ReactComponent as CloseFile } from '../../../assets/icons/times-square.svg'

import { ReactComponent as FilterFile } from '../../../assets/icons/filter.svg'

import { Row } from 'react-awesome-styled-grid'
// import { Button } from 'applaus-ui-kit'

export const FiltersButtonRow = styled(Row)`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.xxxs} 0;
  `}
`

export const FilterIcon = styled(FilterFile)`
  ${({ theme }) => css`
    fill: white;
    margin-left: ${theme.spacing.stack.small};
  `}
`

export const SearchIcon = styled(SearchFile)`
  ${({ theme }) => css`
    fill: #6c01a3;
    position: absolute;
    top: 50%;
    left: ${theme.spacing.stack.xxxs};
    transform: translateY(-50%);
  `}
`

export const CloseIcon = styled(CloseFile)`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.stack.xxs};
    right: ${theme.spacing.stack.xxs};
    cursor: pointer;
  `}
`

export const Container = styled.div<{ modalIsOpen: boolean }>`
  ${({ theme, modalIsOpen }) => css`
    pointer-events: ${modalIsOpen ? 'none' : 'all'};
    display: flex;
    flex-direction: column;
    .btn-filter {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: auto;
    }

    .actionsBox {
      display: flex;
      button:first-child {
        margin-right: ${theme.spacing.stack.xxxs};
      }
    }

    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.xxs} 0;
    }
  `}
`

export const FilterModal = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxs} 0;
    @media (min-width: 768px) {
      background: ${theme.color.modal.nth1};
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: ${theme.spacing.stack.xxxl};
      overflow: auto;
      display: flex;
    }
  `}
`

export const ModalContent = styled.div`
  ${({ theme }) => css`
    @media (min-width: 768px) {
      position: relative;
      overflow: auto;
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing.stack.lg} ${theme.spacing.stack.xxl};
      background: #f4f4f4;
      border-radius: ${theme.border.radius.medium};
      .title {
        text-align: center;
      }
    }
  `}
`

export const Divider = styled.div`
  border: 1px solid #dfdfdf;
  margin-top: 24px;
`

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin: ${theme.spacing.stack.small} 0;
  `}
`

export const StyledInput = styled.input`
  ${({ theme }) => css`
    width: 100%;
    height: ${theme.spacing.stack.md};
    border-radius: ${theme.border.radius.medium};
    outline: 0;
    border: ${theme.border.width.line} solid transparent;
    background: #f3f4f5;
    font-size: ${theme.font.size.sm};
    padding-left: ${theme.spacing.stack.md};
    &:focus {
      border: ${theme.border.width.line} solid #8601c9;
      background: #ffffff;
    }
    &::placeholder {
      font-size: ${theme.font.size.sm};
      color: #39383a;
      padding-left: ${theme.spacing.stack.small};
    }
    &:not(:placeholder-shown) {
      border: ${theme.border.width.line} solid #8601c9;
      background: #ffffff;
    }
  `}
`

export const EventsQuantityBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacing.inline.xs};
    & > p:first-child {
      color: #6c01a3;
      margin-right: ${theme.spacing.inline.small};
    }
  `}
`

export const Products = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.xxxs};
    margin: ${theme.spacing.stack.xxs} 0;
    > div {
      display: flex;
      margin: ${theme.spacing.stack.small} 0;
      > p {
        margin-left: ${theme.spacing.inline.small};
      }
    }
  `}
`

export const Category = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.stack.xxs};

    .buttonsCategory {
      margin-top: ${theme.spacing.stack.xxs};
    }
  `}
`

export const CategoryRow1 = styled.div`
  display: grid;
  grid-template-columns: 26% 31% 36%;
  grid-template-rows: 1fr;
  margin-bottom: 8px;

  justify-content: space-between;
`

export const CategoryRow2 = styled.div`
  display: grid;

  justify-content: space-between;
  margin-bottom: 8px;

  grid-template-columns: repeat(2, 48%);
  grid-template-rows: 1fr;
`

export const CategoryRow3 = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(1, 100%);
  grid-template-rows: 1fr;
`

export const StyledButton = styled.div<{ active: boolean }>`
  ${({ theme, active }) => css`
    background: ${active ? theme.color.brand.primary.nth1 : '#DFDFDF'};
    color: ${active ? theme.color.neutral.nth4 : theme.color.base.nth2};
    padding: 8px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    display: flex;
  `}
`

export const Date = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.stack.xxs};
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.xxxs};
    .rdrDateDisplayItem input {
      font-size: ${theme.spacing.stack.xxxs};
    }
    .rdrMonthAndYearPickers select {
      font-size: ${theme.spacing.stack.xxxs};
    }
    .rdrStartEdge {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
      background-color: purple;
    }
    .rdrEndEdge {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      background-color: purple;
    }
    .rdrDays {
      > button {
        padding: ${theme.spacing.stack.xxxs};
        > span {
          border-radius: 0;
        }
      }
    }
    .rdrMonth {
      width: 100%;
      border-radius: 0;
    }
    .rdrDayNumber {
      > span {
        padding: ${theme.spacing.stack.small};
        font-size: ${theme.spacing.stack.xxxs};
      }
    }

    @media (max-width: 400px) {
      .rdrMonthAndYearPickers {
        width: 150px;
      }
    }
  `}
`

export const Pricing = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.xxxs};
    margin: ${theme.spacing.stack.xxs} 0;
  `}
`

export const OptionsBox = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.xxs} 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${theme.spacing.stack.small};
  `}
`

export const Options = styled.button<{ isActive?: boolean }>`
  ${({ theme, isActive }) => css`
    width: 100%;
    outline: none;
    border: none;
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};
    height: max-content;
    text-align: center;
    border-radius: ${theme.border.radius.light};
    background: ${isActive ? '#8601c9' : '#dfdfdf'};
    transition: 0.7s ease;
    position: relative;
    font-weight: bold;
    white-space: nowrap;
    > p {
      color: ${isActive ? 'white' : '#39383a'};
    }

    &:hover {
      background: #8601c9;
      > p {
        color: white;
        transition: 0.7s ease;
      }
    }
  `}
`

export const CardsEvent = styled.div`
  ${({ theme }) => css`
    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-bottom: 24px;
    }
  `}
`
