import { Paragraph } from 'applaus-ui-kit'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import * as S from './DateFilter.styles'

import { DateRange, Range } from 'react-date-range'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import moment from 'moment'
import { ptBR } from 'date-fns/locale'
import { useOnClickOutside } from '../../../../../hooks/useOnClickOutside'

type Props = {
  dateState: {
    isOpen: boolean
    date: undefined | Date[]
  }
  setDate: Dispatch<
    SetStateAction<{
      isOpen: boolean
      date: undefined | Date[]
    }>
  >
}

export const DateFilter = ({ dateState, setDate }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setDate((prev) => ({ ...prev, isOpen: false })))

  const [state, setState] = useState<Range[]>([
    {
      startDate: moment().toDate(),
      endDate: undefined,
      key: 'selection'
    }
  ])

  useEffect(() => {
    if (ref !== null) {
      const listener = (event: any) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        setDate((prev) => ({ ...prev, isOpen: false }))
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }
  }, [ref, dateState])

  return (
    <S.Dates ref={ref}>
      <S.Button
        onClick={() => setDate((prev) => ({ ...prev, isOpen: !prev.isOpen }))}>
        <Paragraph variant="small" type="semiBold">
          Data
        </Paragraph>
        <S.ChevronDownIcon />
      </S.Button>
      <S.DatesDropdown isOpen={dateState.isOpen}>
        <DateRange
          editableDateInputs={true}
          onChange={(item) => {
            setState([item.selection])
            if (item.selection.startDate && item.selection.endDate) {
              setDate((prev) => ({
                ...prev,
                date: [item.selection.startDate!, item.selection.endDate!]
              }))
            }
          }}
          moveRangeOnFirstSelection={false}
          ranges={state}
          locale={ptBR}
          weekdayDisplayFormat="EEEEEE"
          rangeColors={['#B88BCF']}
          color="#B88BCF"
        />
      </S.DatesDropdown>
    </S.Dates>
  )
}
