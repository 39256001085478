import * as S from './TabulatedEvent.styles'
import { Paragraph } from 'applaus-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from 'redux/rootReducer'
import { formatPrice } from 'utils/formatPrice'
import {
  addTicketAction,
  prevStepAction,
  removeTicket
} from 'redux/Event/actions'
import { useWindowSize } from 'hooks/useWindowSize'
import { useState } from 'react'
import { ButtonBack } from 'components/buttonBack/buttonBack'

export const TabulatedEvent = () => {
  const { isMobile } = useWindowSize()
  const dispatch = useDispatch()
  const { selectedEvent, tickets } = useSelector(
    (state: ReduxState) => state.EventReducer
  )

  const [showTooltip, setShowTooltip] = useState({ id: '', isOpen: false })

  const handleMouseEnter = (isSoldOf: boolean, id: string) => {
    if (isSoldOf) {
      setShowTooltip({
        id,
        isOpen: true
      })
    }
  }

  const onMouseLeave = () => {
    setShowTooltip({
      isOpen: false,
      id: ''
    })
  }

  return (
    <S.Container>
      {isMobile && (
        <div className="backbuttonWrapper">
          <ButtonBack
            title="Voltar"
            onClick={() => dispatch(prevStepAction('chooseTicket'))}
          />
        </div>
      )}
      <h4 className="tabulated-title">Ingressos</h4>
      <S.TicketSection>
        {selectedEvent &&
          selectedEvent.tickets
            .filter(
              ({ ticketsSold, ticketQuantity }) => ticketQuantity > ticketsSold
            )
            .map((ticket) => (
              <S.TicketCard key={ticket.id}>
                <div>
                  <Paragraph variant="large" type="semiBold">
                    {ticket.name}
                  </Paragraph>
                  <Paragraph variant="small" type="normal">
                    {formatPrice(ticket.totalPrice)}
                  </Paragraph>
                </div>
                <S.SelectTickets>
                  <button
                    className="remove-ticket-btn"
                    disabled={
                      tickets.filter(
                        (customerTicket) => customerTicket.id === ticket.id
                      ).length === 0
                    }
                    onClick={() => dispatch(removeTicket(ticket.id))}>
                    -
                  </button>
                  <Paragraph variant="small" type="semiBold">
                    {
                      tickets.filter(
                        (customerTicket) => customerTicket.id === ticket.id
                      ).length
                    }
                  </Paragraph>
                  <S.AddButton
                    isDisabled={
                      tickets.length >= selectedEvent.maxCustomerTickets ||
                      ticket.ticketQuantity - ticket.ticketsSold <=
                        tickets.filter(
                          (customerTicket) => customerTicket.id === ticket.id
                        ).length
                    }
                    onMouseLeave={() => onMouseLeave()}
                    onClick={() => {
                      tickets.length < selectedEvent.maxCustomerTickets &&
                        ticket.ticketQuantity - ticket.ticketsSold >
                          tickets.filter(
                            (customerTicket) => customerTicket.id === ticket.id
                          ).length &&
                        dispatch(addTicketAction(ticket.id))
                      handleMouseEnter(
                        tickets.filter(
                          (customerTicket) => customerTicket.id === ticket.id
                        ).length === selectedEvent.maxCustomerTickets,
                        ticket.id
                      )
                    }}>
                    +
                  </S.AddButton>
                </S.SelectTickets>
                <S.Tooltip
                  visible={
                    showTooltip.isOpen &&
                    tickets.filter(
                      (customerTicket) => customerTicket.id === ticket.id
                    ).length === selectedEvent.maxCustomerTickets &&
                    showTooltip.id === ticket.id
                  }>
                  <S.AlertIcon />
                  <Paragraph variant="small">
                    O limite de ingressos para esse evento foi atingido.
                  </Paragraph>
                </S.Tooltip>
              </S.TicketCard>
            ))}
      </S.TicketSection>
    </S.Container>
  )
}
