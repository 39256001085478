import { Hidden } from 'applaus-ui-kit'
import { Banner } from '../../../../../redux/Content/types'

import * as S from './BannerCard.styles'

export const BannerCard = ({ desktopUrl, mobileUrl, tabletUrl }: Banner) => {
  return (
    <div>
      <Hidden mobile tablet>
        <S.Image src={desktopUrl} />
      </Hidden>
      <Hidden desktop mobile>
        <S.Image src={tabletUrl} />
      </Hidden>
      <Hidden desktop tablet>
        <S.Image src={mobileUrl} />
      </Hidden>
    </div>
  )
}
