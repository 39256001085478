import * as S from './RangeSlider.styles'

import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef
} from 'react'
import { Paragraph } from 'applaus-ui-kit'
import { formatPrice } from '../../utils/formatPrice'

interface MultiRangeSliderProps {
  setPrice: Dispatch<
    SetStateAction<{
      isOpen?: boolean
      price: {
        minValue: number
        maxValue: number
      }
    }>
  >
  minValue: number
  maxValue: number
  min: number
  max: number
}

export const MultiRangeSlider = ({
  setPrice,
  minValue,
  maxValue,
  min,
  max
}: MultiRangeSliderProps) => {
  const minValRef = useRef<HTMLInputElement>(null)
  const maxValRef = useRef<HTMLInputElement>(null)
  const range = useRef<HTMLDivElement>(null)

  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [minValue, maxValue]
  )

  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minValue)
      const maxPercent = getPercent(maxValue)

      if (range.current) {
        range.current.style.left = `${minPercent}%`
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [minValue, getPercent])

  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value)
      const maxPercent = getPercent(maxValue)

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [maxValue, getPercent])

  return (
    <S.Container>
      <S.Input1
        type="range"
        min={min}
        max={max}
        value={minValue}
        ref={minValRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.min(+event.target.value, maxValue - 1)
          setPrice((prev) => ({
            ...prev,
            price: { ...prev.price, minValue: value }
          }))
          event.target.value = value.toString()
        }}
        active={minValue > maxValue - 100}
      />
      <S.Input2
        type="range"
        min={min}
        max={max}
        value={maxValue}
        ref={maxValRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.max(+event.target.value, minValue + 1)
          setPrice((prev) => ({
            ...prev,
            price: { ...prev.price, maxValue: value }
          }))
          event.target.value = value.toString()
        }}
      />

      <S.Slider>
        <S.SliderTrack />
        <S.SliderRange ref={range} />
        <div className="left-value">
          <Paragraph variant="small" type="semiBold">
            {formatPrice(minValue)}
          </Paragraph>
        </div>
        <div className="right-value">
          <Paragraph variant="small" type="semiBold">
            {formatPrice(maxValue)}
          </Paragraph>
        </div>
      </S.Slider>
    </S.Container>
  )
}
