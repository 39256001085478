import * as S from './UpdatePassword.styles'
import BackButton from '../../../components/backButton/BackButton'
import { Input, Button, Paragraph } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { updatePasswordService } from '../../../api/services/profile/updatePasswordService'
import { UpdatePasswordValidateSchema } from './validation/updatePasswordValidateSchema'
import history from '../../../routes/services/history'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setModalState } from '../../../redux/Modal/Actions/actionsModal'
import { clearLoading, setLoading } from '../../../redux/Loading/actions'

type Props = {
  open: boolean
  click?: () => void
}

export const UpdatePassword = ({ click }: Props) => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: UpdatePasswordValidateSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        dispatch(setLoading())
        await updatePasswordService({
          currentPassword: values.currentPassword,
          password: values.newPassword
        })
        dispatch(
          setModalState({
            message: 'Senha alterada com sucesso',
            open: true,
            variant: 'success',
            buttonText: 'Ok'
          })
        )
        dispatch(clearLoading())
      } catch (e) {
        dispatch(clearLoading())
        if (axios.isAxiosError(e) && e.response?.data) {
          const { message } = e.response?.data as { message: string }

          switch (message) {
            case 'password verification failed':
              dispatch(
                setModalState({
                  message: 'Senha atual inválida',
                  description:
                    'Tente novamente, caso não lembre sua senha atual selecione a opção esqueci minha senha',
                  open: true,
                  variant: 'error',
                  buttonText: 'Fechar'
                })
              )
              break
            default:
              break
          }
        }
      }
      resetForm()
    }
  })

  return (
    <S.Container open={true}>
      <S.Form onSubmit={formik.handleSubmit}>
        <BackButton
          title=""
          onClick={() => {
            click ? click() : history.goBack()
            formik.resetForm()
          }}
        />
        <S.Title>Editar Senha</S.Title>
        <Input
          name="currentPassword"
          placeholder="Digite sua senha atual"
          onChange={formik.handleChange}
          value={formik.values.currentPassword || ''}
          error={
            formik.touched.currentPassword &&
            Boolean(formik.errors.currentPassword)
          }
          autoComplete="off"
          texterror={formik.errors.currentPassword}
          type="password"
          fullWidth
        />
        <Paragraph
          variant="regular"
          type="semiBold"
          className="forgotPassword"
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/forgotPassword`
          }}>
          Esqueceu a senha?
        </Paragraph>
        <Input
          name="newPassword"
          placeholder="Digite nova senha"
          onChange={formik.handleChange}
          value={formik.values.newPassword || ''}
          error={
            formik.touched.currentPassword &&
            Boolean(formik.errors.currentPassword)
          }
          texterror={formik.errors.currentPassword}
          type="password"
          fullWidth
        />
        <Input
          name="confirmNewPassword"
          placeholder="Confirme a nova senha"
          onChange={formik.handleChange}
          value={formik.values.confirmNewPassword || ''}
          error={
            formik.touched.confirmNewPassword &&
            Boolean(formik.errors.confirmNewPassword)
          }
          texterror={formik.errors.confirmNewPassword}
          type="password"
          fullWidth
        />
        <S.PasswordRules>
          <Paragraph variant="small">*Pelo menos 8 Caracteres</Paragraph>
          <Paragraph variant="small">*No mínimo 1 letra e 1 número</Paragraph>
        </S.PasswordRules>
        <div className="buttonBox">
          <Button
            variant="contained"
            color="primary"
            size="medium"
            fullWidth
            type="submit">
            Alterar senha
          </Button>
        </div>
      </S.Form>
    </S.Container>
  )
}
