import styled, { css } from 'styled-components'

import { ReactComponent as CreditCard } from '../../assets/icons/credit-card.svg'
import { ReactComponent as BankSlip } from '../../assets/icons/bank-slip.svg'
import { ReactComponent as Pix } from '../../assets/icons/pix.svg'
import { ReactComponent as CashBackFile } from '../../assets/icons/cashback.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    background: #f4f4f4;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 14px ${theme.spacing.stack.xxxs} 14px ${theme.spacing.stack.xs};
    column-gap: ${theme.spacing.stack.xxs};
    .name {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      text-align: left;
      white-space: nowrap;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    @media (min-width: 768px) and (max-width: 1170px) {
      padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xs};
    }

    @media (min-width: 1171px) {
      padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xs};
    }

    @media (max-width: 767px) {
      padding: 8px ${theme.spacing.stack.xxxs};
      .name {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        white-space: nowrap;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .cashbackPrice {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: ${theme.color.brand.primary.nth1};
    }
  `}
`

export const CreditCardIcon = styled(CreditCard)`
  min-width: 24px;
  min-height: 24px;
  max-width: 32px;
  max-height: 32px;
`

export const BankSlipIcon = styled(BankSlip)``

export const PixIcon = styled(Pix)`
  min-width: 24px;
  min-height: 24px;
  max-width: 32px;
  max-height: 32px;
`

export const CashBackIcon = styled(CashBackFile)`
  ${({ theme }) => css`
    min-width: 24px;
    min-height: 24px;
    max-width: 32px;
    max-height: 32px;
    fill: ${theme.color.base.nth1};
  `}
`
