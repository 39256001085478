import { Switch, Route, Redirect } from 'react-router-dom'
import { Home } from '../pages/shared/Home/Home'
import { Profile } from '../pages/desktop/Profile/Profile'
import { PrivateRoute } from './PrivateRoutes'
import { UpdatePassword } from '../pages/shared/UpdatePassword/UpdatePassword'
import { Checkout } from '../pages/shared/Checkout/Checkout'
import { Event } from '../pages/shared/Event/Event'
import { Course } from '../pages/shared/Course/Course'
import { Purchase } from 'pages/shared/Purchase/Purchase'
import { MobileCheckout } from '../pages/mobile/MobileCheckout/MobileCheckout'
import { Search } from '../pages/shared/Search/Search'
import { PurchaseHistory } from '../pages/desktop/PurchaseHistory/PurchaseHistory'
import { MobileProfile } from '../pages/mobile/Profile/Profile'
import { useWindowSize } from '../hooks/useWindowSize'
import { CashbackHistory } from '../pages/desktop/CashbackHistory/CashbackHistory'
import { Purchases } from '../pages/desktop/Purchases/Purchases'
import { Tickets } from '../pages/shared/Tickets/ShowTicket'

const Routes = () => {
  const { isMobile } = useWindowSize()

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/event/:id" component={Event} />
      <Route path="/course/:id?" component={Course} />
      <Route path="/search" component={Search} />
      <Route path="/mobileCheckout" component={MobileCheckout} />
      <Route path="/profileTab" component={MobileProfile} />
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute
        path="/updatePassword"
        component={isMobile ? UpdatePassword : () => <Redirect to="/profile" />}
      />
      <PrivateRoute exact path="/checkout" component={Checkout} />
      <PrivateRoute path="/purchases" component={Purchases} />
      <PrivateRoute path="/purchase/:id?" component={Purchase} />
      <PrivateRoute path="/tickets/:id?" component={Tickets} />
      <PrivateRoute path="/purchaseHistory" component={PurchaseHistory} />
      <PrivateRoute path="/cashbackHistory" component={CashbackHistory} />
    </Switch>
  )
}

export default Routes
