/* eslint-disable no-unused-vars */

import { SocialLinks } from '../../api/services/Events/listOneEventService'

export enum EventsAction {
  loadEventRequest = '@event/LOAD_EVENT_REQUEST',
  loadEventSuccess = '@event/LOAD_EVENT_SUCCESS',
  chooseTicket = '@event/CHOOSE_TICKET',
  selectEvent = '@event/SELECT_EVENT',
  profileEvent = '@event/PROFILE_EVENT',
  selectTickets = '@event/SELECT_TICKET',
  addTicketInfo = '@event/ADD_TICKET_INFO',
  purchaseSummary = '@event/PURCHASE_SUMARY',
  prevStep = '@event/PREV_STEP',
  addTicket = '@event/ADD_TICKET',
  removeTicket = '@event/REMOVE_TICKET',
  clearSelectedEvent = '@event/CLEAR_SELECTED_EVENT',
  clearEvent = '@event/CLEAR_EVENT'
}

export type Pixels = {
  facebookpixel: string
  googleanalyticspixel: string
  googletagmanagerpixel: string
}

export type Ticket = {
  id: string
  name: string
  description: string
  priceCents: number
  discountCents: number
  totalPrice: number
  type: string
  category: string
  eventItemsId: string[]
  rule?: string
  customField?: {}
  ticketsSold: number
  ticketQuantity: number
}

export type CustomerTicket = {
  id: string
  name: string
  totalPrice: number
  eventItemId: string
  category: string
  labels?: {
    own: string
    parent: string
    section: string
    displayedLabel: string
  }
  seatKey?: string
  ticketInfo?: {
    name: string
    value: string
  }[]
}

type Address = {
  name: string
  number: number
  street: string
  state: string
  city: string
  zipCode: string
}

export type Banners = {
  cardUrl: string
  desktopUrl: string
  mobileUrl: string
  tabletUrl: string
}

export type EventItems = {
  id: string
  title: string
  description: string
  dates: string[]
  maxCustomerTickets: number
  eventKey?: string
  customField?: {}
  salesFrom: string
  finalSales: string
  ticketInfo?: {
    value: string
    isRequired: boolean
  }[]
  isAvailable: boolean
  tickets: Ticket[]
  createdAt: string
  updatedAt?: string
}

export type Event = {
  id: string
  status: 'CANCELED' | 'OPEN' | 'CLOSED' | 'DRAFT' | 'SOLD_OUT'
  name: string
  cashBackPercent: number | null
  eventKey?: string
  description: string
  address: Address
  ageGroup: string
  banners: Banners[]
  eventItems: EventItems[]
  type: 'MAPPED' | 'TABULATED'
  seatMapId?: string
  isPublic: boolean
  salesFrom: string
  finalSales: string
  maxInstallments: number
  socialLinks: SocialLinks | null
  pixels: Pixels
}

export type EventState = {
  step: 'home' | 'showEvent' | 'chooseTicket' | 'purchaseSummary'
  eventData?: Event
  timeCounter?: string
  tickets: CustomerTicket[]
  selectedEvent?: EventItems
}
