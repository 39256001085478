import styled, { css } from 'styled-components'

import { ReactComponent as CashBackFile } from '../../assets/icons/cashback.svg'
import { ReactComponent as PixFile } from '../../assets/icons/pix.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
  `}
`

export const BrandIcon = styled.img``

export const CashbackIconDiv = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.inline.small};
    height: ${theme.spacing.stack.xs};
    width: ${theme.spacing.stack.xs};
  `}
`

export const CashbackIcon = styled(CashBackFile)`
  ${({ theme }) => css`
    fill: ${theme.color.brand.primary.nth2};
    height: ${theme.spacing.stack.xs};
    width: ${theme.spacing.stack.xs};
  `}
`

export const PixIcon = styled(PixFile)`
  ${({ theme }) => css`
    height: ${theme.spacing.stack.xs};
    width: ${theme.spacing.stack.xs};
  `}
`

export const Brand = styled.p`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.stack.nano};
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: ${theme.spacing.stack.xxs};

    @media (max-width: 1277px) {
      display: none;
    }
  `}
`

export const LastNumbers = styled.p`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.stack.small};
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: ${theme.spacing.stack.xxs};
  `}
`
