import { CustomerTicket } from '../../../../../redux/Event/types'
import { formatPrice } from '../../../../../utils/formatPrice'
import { TicketCard } from '../../../../../components/ticket/Ticket'
import * as S from './PurchaseSummary.styles'
import { calculateTotalPrice } from 'utils/calculateTotalPrice'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/rootReducer'
import { removeCouponAction } from '../../../../../redux/Checkout/actions'

type PurchaseSummaryProps = {
  titleTicket: string
  eventDate: string
  eventAddress: string
  tickets: CustomerTicket[]
}

export const PurchaseSummary = ({
  titleTicket,
  eventAddress,
  eventDate,
  tickets
}: PurchaseSummaryProps) => {
  const { coupon } = useSelector((state: ReduxState) => state.CheckoutReducer)

  const totalPrice = useMemo(
    () => formatPrice(calculateTotalPrice(tickets, coupon)),
    [tickets, coupon]
  )

  const dispatch = useDispatch()

  return (
    <S.Container>
      <S.TitleSummary>RESUMO DA COMPRA</S.TitleSummary>

      <S.ContentInfo>
        <S.TitleInfo>{titleTicket}</S.TitleInfo>
        <S.DataBox>
          <S.IconData />
          <S.TitleData>{eventDate}</S.TitleData>
        </S.DataBox>
        <S.LocationBox>
          <S.IconLocation />
          <S.TitleLocation>{eventAddress}</S.TitleLocation>
        </S.LocationBox>
      </S.ContentInfo>

      <S.TicketsBox>
        {tickets.map((ticket, index) => (
          <TicketCard key={ticket.id + index} {...ticket} />
        ))}
      </S.TicketsBox>

      {coupon && (
        <S.CouponCard>
          <S.RemoveIcon
            onClick={() =>
              dispatch(removeCouponAction(coupon.customerCouponId))
            }
          />
          <div>
            <p className="coupon-title">Cupom de desconto</p>
            <p className="coupon-code">{coupon.name}</p>
          </div>
          <p className="coupon-discount">
            {coupon.valueType === 'CENTS'
              ? formatPrice(coupon.value / 100)
              : `- ${coupon.value} %`}
          </p>
        </S.CouponCard>
      )}

      <S.Total>
        <S.TextTotal>Total: {totalPrice}</S.TextTotal>
      </S.Total>
    </S.Container>
  )
}
