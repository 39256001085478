import styled, { css } from 'styled-components'

import { ReactComponent as ChevronDownFile } from '../../../../../assets/icons/chevron-down-filled.svg'
import { ReactComponent as MapMarkerFile } from '../../../../../assets/icons/map-marker.svg'
import { ReactComponent as ArrowsFile } from '../../../../../assets/icons/arrows.svg'
import { ReactComponent as CalendarFile } from '../../../../../assets/icons/calendar.svg'

export const MapMarkerIcon = styled(MapMarkerFile)`
  fill: #39383a;
`
export const ArrowsIcon = styled(ArrowsFile)`
  fill: #39383a;
`
export const CalendarIcon = styled(CalendarFile)`
  fill: #39383a;
`

export const ChevronDownIcon = styled(ChevronDownFile)`
  stroke: #6c01a3;
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  right: 8px;
`

export const Container = styled.div`
  position: relative;
`

export const Button = styled.button`
  ${({ theme }) => css`
    min-width: 150px;
    outline: none;
    border: none;
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxs};
    height: max-content;
    margin: 0 ${theme.spacing.stack.small};
    border-radius: ${theme.border.radius.light};
    background: #f4f4f4;
    transition: 0.7s ease;
    position: relative;
    > p {
      color: #39383a;
      margin-right: ${theme.spacing.stack.small};
    }
    &:hover {
      background: #8601c9;
      > p {
        transition: 0.3s ease;
        color: white;
      }
      > svg {
        transition: 0.3s ease;
        transform: translateY(-40%);
        stroke: white;
      }
    }
  `}
`

export const OrderByDropdown = styled.div<{ isOpen: boolean }>`
  ${({ theme, isOpen }) => css`
    opacity: ${isOpen ? 1 : 0};
    pointer-events: ${isOpen ? 'all' : 'none'};
    transition: 0.3s ease;
    position: absolute;
    top: 98%;
    z-index: 888;
    left: 0;
    width: max-content;
    margin: 0 ${theme.spacing.stack.small};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing.stack.small};
    background: white;
  `}
`

export const Options = styled.div<{ isActive?: boolean }>`
  ${({ theme, isActive }) => css`
    display: flex;
    cursor: pointer;
    width: 100%;
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};
    > p {
      margin-left: ${theme.spacing.stack.small};
    }
    transition: 0.3s ease;
    &:hover {
      background: #f4f4f4;
      > p {
        color: #8601c9;
      }
      > svg {
        fill: #8601c9;
      }
    }
  `}
`
