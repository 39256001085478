import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/rootReducer'
import { UnauthenticatedTemplate } from './templates/UnauthenticatedTemplate/Unauthenticated'
import { AuthenticatedProfile } from './templates/AuthenticatedTemplate/AuthenticatedTemplate'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { Redirect } from 'react-router-dom'

export const MobileProfile = () => {
  const { accessToken } = useSelector((state: ReduxState) => state.UserReducer)

  const { isTablet, isDesktop } = useWindowSize()

  if (isTablet || isDesktop) {
    return <Redirect to="/profile" />
  } else {
    return accessToken && accessToken !== '' ? (
      <AuthenticatedProfile />
    ) : (
      <UnauthenticatedTemplate />
    )
  }
}
