import styled, { css } from 'styled-components'

import { ReactComponent as HomeComponent } from '../../assets/icons/home.svg'
import { ReactComponent as SearchComponent } from '../../assets/icons/search.svg'
import { ReactComponent as TicketComponent } from '../../assets/icons/coupon.svg'
import { ReactComponent as ProfileComponent } from '../../assets/icons/profile.svg'

export const Container = styled.nav`
  ${({ theme }) => css`
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 65px;
    background: #f4f4f4;
    display: flex;
    z-index: 700;
    > ul {
      list-style: none;
      margin-top: auto;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
    }
  `}
`
export const Item = styled.li<{ active: number }>`
  ${({ active, theme }) => css`
    padding: 0 ${theme.spacing.stack.small};
    margin-top: auto;
    color: white;
    border-bottom: 4px solid ${active ? '#8601C9' : 'transparent'};
    ${active &&
    css`
      > svg {
        fill: #8601c9;
      }
    `}
  `}
`

export const HomeIcon = styled(HomeComponent)`
  ${({ theme }) => css`
    fill: #808080;
    margin-bottom: ${theme.spacing.stack.small};
  `}
`

export const SearchIcon = styled(SearchComponent)`
  ${({ theme }) => css`
    fill: #808080;
    margin-bottom: ${theme.spacing.stack.small};
  `}
`

export const LikeIcon = styled(TicketComponent)`
  ${({ theme }) => css`
    fill: #808080;
    margin-bottom: ${theme.spacing.stack.small};
  `}
`

export const ProfileIcon = styled(ProfileComponent)`
  ${({ theme }) => css`
    fill: #808080;
    margin-bottom: ${theme.spacing.stack.small};
  `}
`
