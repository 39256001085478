import { Pixels } from 'redux/Event/types'
import { publicApi } from '../../config/api'
import { AxiosResponse } from 'axios'
import { Banners, Ticket } from '../../../redux/Event/types'

export type SocialLinks = {
  facebookLink: string
  youtubeLink: string
  instagramLink: string
  tiktokLink: string
}

export type Tickets = {
  id: string
  name: string
  description: string
  priceCents: number
  discountCents: number
  type: string
  category: string
  rule: string
  createdAt: string
  updatedAt?: string
  customField?: string
}

export type EventItems = {
  id: string
  title: string
  description: string
  dates: string[]
  maxCustomerTickets: number
  eventKey?: string
  customField?: {}
  salesFrom: string
  finalSales: string
  ticketInfo?: {
    value: string
    isRequired: boolean
  }[]
  tickets: Ticket[]
  createdAt: string
  updatedAt?: string
}

export type EventResponse = {
  status: 'CANCELED' | 'OPEN' | 'CLOSED' | 'DRAFT' | 'SOLD_OUT'
  isPublic: boolean
  id: string
  name: string
  cashBackPercent: number | null
  description: string
  banners: Banners[]
  ageGroup: string
  createdAt: string
  updatedAt?: string
  creatorId?: string
  address: {
    id: string
    name: string
    country: string
    state: string
    city: string
    street: string
    number: number
    zipCode: string
    latitude?: string
    longitude?: string
    complement?: string
    createdAt: string
    updatedAt?: string
    creatorId?: string
    customerId?: string
    eventId: string
    seatMapId?: string
  }
  type: 'MAPPED' | 'TABULATED'
  eventItems: EventItems[]
  salesStartsIn?: string
  maxInstallments: number
  socialLinks: SocialLinks | null
  pixels: Pixels
}

export const listOneEventService = async (eventId: string) => {
  const { data }: AxiosResponse<EventResponse> = await publicApi.get(
    `/api/events/${eventId}`
  )

  return data
}
