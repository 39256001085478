import * as S from './AddCoupon.styles'
import { ChangeEvent, useState } from 'react'
import { Button, Input } from 'applaus-ui-kit'

type Props = {
  handleRedeemCoupon: (value: string) => void
}

export const AddCoupon = ({ handleRedeemCoupon }: Props) => {
  const [showCouponInput, setShowCouponInput] = useState(false)
  const [couponValue, setCouponValue] = useState('')

  const handleShowCouponInput = () => {
    setShowCouponInput((prevState) => !prevState)
  }

  const handleCouponInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCouponValue(event.target.value.toUpperCase())
  }

  const handleSubmit = () => {
    handleRedeemCoupon(couponValue)
    handleShowCouponInput()
    setCouponValue('')
  }

  return (
    <S.Container>
      {showCouponInput ? (
        <S.InputWrapper>
          <Input
            label="Digite o código do cupom"
            value={couponValue}
            onChange={handleCouponInputChange}
            fullWidth
          />{' '}
          <div>
            <Button
              variant="contained"
              size="small"
              fullWidth={false}
              color="primary"
              onClick={handleSubmit}>
              Aplicar
            </Button>
          </div>
        </S.InputWrapper>
      ) : (
        <S.ExpandCouponText onClick={handleShowCouponInput}>
          Possui um cupom de desconto?
        </S.ExpandCouponText>
      )}
    </S.Container>
  )
}
