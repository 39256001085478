import styled, { css } from 'styled-components'

import { ReactComponent as DownloadFile } from 'assets/icons/download.svg'

export const DownloadIcon = styled(DownloadFile)``

export const DownloadButton = styled.button`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.xs} 0;
    outline: 0;
    border: 1px solid ${theme.color.brand.primary.nth1};
    background: none;
    display: flex;
    align-items: center;
    gap: ${theme.spacing.inline.small};
    width: 100%;
    justify-content: center;
    border-radius: ${theme.border.radius.large};
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;

    color: ${theme.color.brand.primary.nth1};
    padding: ${theme.spacing.stack.small} 0;
    transition: 0.3s ease;
    &:hover {
      transform: scale(1.02);
    }
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    min-width: 320px;
    .divider {
      border: 1px dashed #adadad;
      margin: ${theme.spacing.stack.xxxs};
      opacity: 0.9;
    }
  `}
`

export const ContentImage = styled.div`
  ${({ theme }) => css`
    .buttonBack {
      position: absolute;
      margin: ${theme.spacing.stack.xxs};
    }
  `}
`

export const CoverUrl = styled.img`
  width: 100%;
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    position: absolute;
    top: 80%;
  `}
`

export const ContentTickets = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background: #ffff;
    border-radius: ${theme.border.radius.big} ${theme.border.radius.big} 0 0;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs} 0
      ${theme.spacing.stack.xxs};
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    color: #8601c9;
    font-weight: ${theme.font.weight.bold};
  `}
`

export const TicketBox = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.border.radius.medium};
    border: 2px solid #dfdfdf;
    margin-top: ${theme.spacing.stack.xxxs};

    .circleTop {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;
      margin: -22px auto 10px auto;
      border-top: 2px solid white;
      border-left: 2px solid white;
      border-right: 2px solid #dfdfdf;
      border-bottom: 2px solid #dfdfdf;
      transform: rotate(45deg);
    }

    .circleBottom {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;
      margin: 10px auto -22px auto;
      border-top: 2px solid #dfdfdf;
      border-left: 2px solid #dfdfdf;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(45deg);
    }

    .titleEventBox {
      display: flex;
    }

    .ticketDetached {
      color: #ff6565;
      font-size: ${theme.font.size.sm};
      font-weight: ${theme.font.weight.bold};
      margin-left: ${theme.spacing.inline.nano};
    }
  `}
`

export const Ticket = styled.div`
  ${({ theme }) => css`
    border-top: 1px dashed #dfdfdf;
    border-bottom: 1px dashed #dfdfdf;
    padding: ${theme.spacing.stack.xxxs};
  `}
`

export const TitleEvent = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.bold};
    line-height: ${theme.spacing.stack.xxs};
    margin-bottom: ${theme.spacing.stack.small};
    word-break: break-all;
  `}
`

export const AddressEvent = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xs};
    font-weight: ${theme.font.weight.regular};
    line-height: ${theme.spacing.stack.xxxs};
    margin-bottom: ${theme.spacing.stack.small};
  `}
`

export const Date = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxxs};
    margin-bottom: ${theme.spacing.stack.small};
  `}
`

export const TicketInfoBox = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.small};
    > div:nth-child(1) {
      margin-right: auto;
    }
  `}
`

export const TicketInfo = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.xxs};
    .contentInfoBox {
      display: flex;
      h1 {
        margin-right: ${theme.spacing.stack.nano};
        font-size: ${theme.font.size.xxs};
      }

      p {
        font-size: ${theme.font.size.xxs};
      }
    }
  `}
`

export const PurchaseData = styled.div`
  ${({ theme }) => css`
    .purchaseDate {
      display: flex;
      h1 {
        font-size: ${theme.font.size.xxs};
      }
      p {
        margin-left: ${theme.spacing.stack.small};
        font-size: ${theme.font.size.xxs};
      }
    }
    .contentPurchaseData {
      display: flex;
      h1 {
        margin-right: ${theme.spacing.stack.nano};
        font-size: ${theme.font.size.xxs};
      }

      p {
        font-size: ${theme.font.size.xxs};
      }
    }
  `}
`

export const InfoCustomer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.small};

    .contentInfoCustomer {
      display: flex;
      h1 {
        margin-right: ${theme.spacing.stack.nano};
        font-size: ${theme.font.size.xxs};
      }

      p {
        font-size: ${theme.font.size.xxs};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100px;
      }
    }
  `}
`

export const QrCode = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxxs};
    border: 2px solid #dfdfdf;
    border-radius: ${theme.border.radius.light};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${theme.spacing.stack.nano};

    .contentTicket {
      font-size: ${theme.font.size.xxs};
      font-weight: ${theme.font.weight.semiBold};
    }

    .idQrCode {
      font-size: ${theme.font.size.xxs};
      line-height: ${theme.spacing.stack.xxs};
      color: #000000;
      font-weight: ${theme.font.weight.semiBold};
    }

    .circleTop {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;
      margin: -38px auto 10px auto;
      border-top: 2px solid white;
      border-left: 2px solid white;
      border-right: 2px solid #dfdfdf;
      border-bottom: 2px solid #dfdfdf;
      transform: rotate(45deg);
    }

    .circleBottom {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;
      margin: 10px auto -38px auto;
      border-top: 2px solid #dfdfdf;
      border-left: 2px solid #dfdfdf;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(45deg);
    }
  `}
`
