import styled, { css } from 'styled-components'

import { ReactComponent as IconLeft } from '../../../../../assets/icons/chevron-left.svg'
import { ReactComponent as IconRigth } from '../../../../../assets/icons/chevron-right.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.xxxs} 0;
  `}
`

export const EventWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 1366px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      padding-left: ${theme.spacing.stack.xxs};
    }
    @media (min-width: 1171px) {
      padding: 0 0 0 ${theme.spacing.stack.xxxl};
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      padding-left: ${theme.spacing.stack.xs};
    }
    .title {
      font-size: 27px;
      font-style: normal;
      font-weight: 600;
      line-height: 41px;
      text-align: left;
      position: relative;
      margin-bottom: ${theme.spacing.stack.xs};
    }

    .title:after {
      position: absolute;
      content: '';
      height: 8px;
      background: ${theme.color.brand.primary.nth1};
      width: 100px;
      top: 100%;
      left: 0;
    }

    #card-cashback {
      div:first-child {
        margin-left: 0;
      }
    }

    @media (max-width: 767px) {
      .title {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        text-align: left;
        margin-bottom: 0;
      }

      .title:after {
        position: absolute;
        content: '';
        height: 2px;
        background: ${theme.color.brand.primary.nth1};
        width: 25px;
        top: 100%;
        left: 0;
      }
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
        text-align: left;
      }

      .title:after {
        position: absolute;
        content: '';
        height: 5px;
        background: ${theme.color.brand.primary.nth1};
        width: 60px;
        top: 100%;
        left: 0;
      }
    }
  `}
`

export const ScrollBox = styled.div`
  display: flex;
  position: relative;
`

export const ButtonLeft = styled.div`
  ${({ theme }) => css`
    width: 120px;
    z-index: 99999;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9) 27.08%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    height: 100%;
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
    :hover {
      opacity: 0.8;
    }
  `}
`

export const ArrowLeft = styled(IconLeft)`
  ${({ theme }) => css`
    fill: ${theme.color.brand.primary.nth1};
    width: 32px;
    height: 32px;
    z-index: 100;
    position: absolute;
    left: 40px;
  `}
`

export const ArrowRight = styled(IconRigth)`
  ${({ theme }) => css`
    fill: ${theme.color.brand.primary.nth1};
    width: 32px;
    height: 32px;
    z-index: 100;
    position: absolute;
    right: 40px;
  `}
`

export const ButtonRight = styled.div`
  ${({ theme }) => css`
    width: 120px;
    z-index: 99999;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.9) 27.08%,
      rgba(255, 255, 255, 0) 100%
    );
    padding: ${theme.spacing.inline.xxxs};
    position: absolute;
    right: 0;
    height: 100%;
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
    :hover {
      opacity: 0.8;
    }
  `}
`

export const EventBox = styled.section`
  ${({ theme }) => css`
    padding: 16px 16px 16px 0;
    display: flex;
    overflow: auto;
    scroll-behavior: smooth;
    gap: ${theme.spacing.inline.small};
    > div {
      margin-right: ${theme.spacing.inline.small};
    }
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  `}
`
