import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from 'redux/rootReducer'
import { EventProfile } from './EventProfile/Mobile/EventProfile'
import { ChooseTicket } from './ChooseTicket'
import { PurchaseSummary } from './PurchaseSummary/Mobile/PurchaseSummary'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { loadEventRequestAction } from 'redux/Event/actions'
import { DesktopEventProfile } from './EventProfile/Desktop/EventProfile'
import { DesktopPurchaseSummary } from './PurchaseSummary/Desktop/DesktopPurchaseSummary'
import { useWindowSize } from 'hooks/useWindowSize'
import { removeCouponAction } from 'redux/Checkout/actions'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'

export const Event = () => {
  const { EventReducer, CheckoutReducer } = useSelector(
    (state: ReduxState) => state
  )

  const { step, eventData } = EventReducer

  const dispatch = useDispatch()

  const { id }: { id: string } = useParams()

  const { isDesktop, isMobile, isTablet } = useWindowSize()

  useEffect(() => {
    dispatch(loadEventRequestAction(id))
    dispatch(removeCouponAction(CheckoutReducer.coupon?.customerCouponId ?? ''))
  }, [])

  useEffect(() => {
    if (eventData?.pixels) {
      if (localStorage.getItem(eventData.id) === null) {
        ReactPixel.init(eventData.pixels.facebookpixel, undefined, {
          autoConfig: true,
          debug: true
        })
        ReactPixel.pageView()

        const tagManagerArgs = {
          gtmId: eventData.pixels.googletagmanagerpixel
        }

        TagManager.initialize(tagManagerArgs)
        localStorage.setItem(eventData.id, 'true')
      }
    }
  }, [eventData?.pixels])

  return (
    <>
      {isDesktop || isTablet ? (
        step === 'showEvent' ? (
          <DesktopEventProfile />
        ) : step === 'purchaseSummary' ? (
          <DesktopPurchaseSummary />
        ) : (
          <DesktopEventProfile />
        )
      ) : isMobile ? (
        step === 'showEvent' ? (
          <EventProfile />
        ) : step === 'chooseTicket' ? (
          <ChooseTicket />
        ) : (
          <PurchaseSummary />
        )
      ) : undefined}
    </>
  )
}
