import { Hidden, theme } from 'applaus-ui-kit'
import moment from 'moment'
import { CashbackHistoryResponseFormated } from '../../../../../api/services/cashbackHistory/getCashbackHistory'
import { formatPrice } from '../../../../../utils/formatPrice'
import * as S from './Table.styles'

export type TableProps = {
  cashbackHistory?: CashbackHistoryResponseFormated
}

export const Table = ({ cashbackHistory }: TableProps) => {
  return (
    <S.Container>
      <S.Header>
        <Hidden mobile>
          <p>Nome</p>
        </Hidden>
        <Hidden tablet desktop>
          <p>Evento</p>
        </Hidden>

        <p>Data</p>

        <Hidden mobile>
          <p>Status</p>
        </Hidden>

        <p>Valor</p>
      </S.Header>
      <S.ContentRows>
        {cashbackHistory
          ?.sort((a, b) => {
            if (moment(a.createdAt) > moment(b.createdAt)) {
              return -1
            } else return 1
          })
          .map((item, index) => (
            <S.Row key={index}>
              <div className="name-wrapper">
                <p className="event-name">
                  {item.purchaseOrder.customerTickets[0].eventItem.event.name}
                </p>
              </div>

              <Hidden desktop tablet>
                <p className="spacingRight">
                  {item.purchaseOrder.customerTickets[0].eventItem.event.name}
                </p>
              </Hidden>

              <p className="spacingRight">
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </p>

              <Hidden mobile>
                <S.PaymentStatus
                  status={
                    item.balanceType === 'BALANCE_ENTRY'
                      ? 'APPROVED'
                      : item.balanceType === 'BALANCE_PENDING'
                      ? 'PROCESSING'
                      : item.balanceType === 'BALANCE_OUT'
                      ? 'APPROVED'
                      : 'FAILED'
                  }>
                  {item.balanceType === 'BALANCE_ENTRY'
                    ? 'Confirmado'
                    : item.balanceType === 'BALANCE_PENDING'
                    ? 'Pendente'
                    : item.balanceType === 'BALANCE_OUT'
                    ? 'Confirmado'
                    : 'Recusado'}
                </S.PaymentStatus>
              </Hidden>
              <p
                className="spacingLeft"
                style={{
                  color:
                    item.balanceType === 'BALANCE_OUT'
                      ? theme.color.alert.error.nth1
                      : item.balanceType === 'BALANCE_PENDING'
                      ? theme.color.alert.warning.nth1
                      : theme.color.alert.success.nth2
                }}>
                {item.balanceType === 'BALANCE_OUT' ? '- ' : null}
                {formatPrice(item.cashBackValueCents / 100)}
              </p>
            </S.Row>
          ))}
      </S.ContentRows>
    </S.Container>
  )
}
