import { AppBar } from 'components/appBar/AppBar'
import { ButtonBack } from 'components/buttonBack/buttonBack'
import * as S from './ShowRequest.styles'
import { Button, Heading, Hidden, Paragraph } from 'applaus-ui-kit'
import { useSelector } from 'react-redux'
import { useWindowSize } from 'hooks/useWindowSize'
import { motion } from 'framer-motion'
import { ReduxState } from 'redux/rootReducer'
import moment from 'moment'
import { formatPrice } from 'utils/formatPrice'
import { BrandCreditCard } from 'components/brandCreditCard/BrandCreditCard'
import history from 'routes/services/history'
import { useParams } from 'react-router-dom'
import { ProfileTemplate } from 'components/templates/ProfileTemplate/ProfileTemplate'
import { downloadPurchasePDF } from 'api/services/purchases/downloadPurchasePDF'

export const ShowPurchaseDesktop = () => {
  const { isDesktop } = useWindowSize()
  const { id } = useParams<{ id: string }>()

  const { selectedPurchaseOrder } = useSelector(
    (state: ReduxState) => state.PurchasesReducer
  )

  return (
    <motion.div animate={{ opacity: [0, 1], transition: { duration: 0.5 } }}>
      <ProfileTemplate active="purchases">
        <S.Container>
          <Hidden desktop>
            <AppBar />
          </Hidden>

          <S.ContentRequest>
            <Hidden tablet>
              <S.TitleRequest>Meus eventos</S.TitleRequest>
            </Hidden>
            <ButtonBack
              title={
                'Pedido: ' + selectedPurchaseOrder?.orderId.toLocaleUpperCase()
              }
              subTitle={
                'Comprado em ' +
                moment(
                  selectedPurchaseOrder?.updatedAt ||
                    selectedPurchaseOrder?.createdAt
                ).format('DD/MM/YYYY') +
                ' às ' +
                moment(
                  selectedPurchaseOrder?.updatedAt ||
                    selectedPurchaseOrder?.createdAt
                ).format('LT')
              }
              click={() => history.push('/purchases')}
            />

            <S.Wrapper>
              {isDesktop ? (
                <S.CoverUrl
                  src={
                    selectedPurchaseOrder?.eventItem.event.banners.desktopUrl
                  }
                />
              ) : (
                <S.CoverUrl
                  src={selectedPurchaseOrder?.eventItem.event.banners.tabletUrl}
                />
              )}

              <S.InfoRequest>
                <S.Title>{selectedPurchaseOrder?.eventItem.event.name}</S.Title>

                <S.InfoBox>
                  <S.DateBox>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <S.IconDate />
                      <S.TextDate>
                        {selectedPurchaseOrder!.eventItem.dates.length > 1
                          ? moment(
                              selectedPurchaseOrder!.eventItem.dates[0]
                            ).format('DD/MM/YYYY') +
                            ' - ' +
                            moment(
                              selectedPurchaseOrder!.eventItem.dates[
                                selectedPurchaseOrder!.eventItem.dates.length -
                                  1
                              ]
                            ).format('DD/MM/YYYY')
                          : moment(
                              selectedPurchaseOrder!.eventItem.dates[0]
                            ).format('DD/MM/YYYY')}
                      </S.TextDate>
                    </div>
                  </S.DateBox>
                  <S.LocationBox>
                    <S.IconLocation />
                    <div>
                      <S.TextLocation>
                        {selectedPurchaseOrder!.eventItem.event.address.street}
                      </S.TextLocation>
                      <S.SubTextLocation>
                        {selectedPurchaseOrder!.eventItem.event.address.city}
                      </S.SubTextLocation>
                    </div>
                  </S.LocationBox>
                </S.InfoBox>

                <S.PaymentStatus>
                  <S.TextStatus status={selectedPurchaseOrder!.status}>
                    Pagamento:{' '}
                    <p className="approvedText">
                      {selectedPurchaseOrder?.status === 'WAITING'
                        ? 'EM ANÁLISE'
                        : 'APROVADO'}
                    </p>
                  </S.TextStatus>
                  <S.PaymentData>
                    Realizado em{' '}
                    {moment(
                      selectedPurchaseOrder?.updatedAt ||
                        selectedPurchaseOrder?.createdAt
                    ).format('DD/MM/YYYY') +
                      ' às ' +
                      moment(
                        selectedPurchaseOrder?.updatedAt ||
                          selectedPurchaseOrder?.createdAt
                      ).format('LT')}
                  </S.PaymentData>
                </S.PaymentStatus>

                {selectedPurchaseOrder?.payment.cashBackDiscountCents ===
                  null ||
                selectedPurchaseOrder?.payment.cashBackDiscountCents === 0 ? (
                  <S.PaymentValue>
                    {selectedPurchaseOrder!.payment.totalPrice > 0 ? (
                      <S.TextValue>
                        {formatPrice(
                          selectedPurchaseOrder!.payment.totalPrice / 100
                        )}
                      </S.TextValue>
                    ) : null}
                    <BrandCreditCard
                      brandIcon={
                        selectedPurchaseOrder!.payment.paymentMethod === 'PIX'
                          ? selectedPurchaseOrder!.payment.paymentMethod
                          : selectedPurchaseOrder!.payment.brand
                      }
                      paymentMethod={
                        selectedPurchaseOrder!.payment.paymentMethod
                      }
                      lastNumbers={
                        selectedPurchaseOrder!.payment.lastCardNumbers
                      }
                      final
                    />
                  </S.PaymentValue>
                ) : (
                  <div style={{ marginTop: '16px' }} />
                )}

                {selectedPurchaseOrder?.payment.cashBackDiscountCents !==
                  null &&
                selectedPurchaseOrder?.payment.cashBackDiscountCents! > 0 ? (
                  <S.CashBackPaid>
                    <div className="cashbackBox">
                      <Paragraph variant="small" className="bold">
                        {formatPrice(
                          selectedPurchaseOrder?.payment
                            .cashBackDiscountCents! / 100
                        )}
                      </Paragraph>

                      <div>
                        <S.IconCashback />
                        <Paragraph variant="small">Cashback</Paragraph>
                      </div>
                    </div>
                    <div className="paymentBox">
                      <Paragraph variant="small" className="bold">
                        {formatPrice(
                          (selectedPurchaseOrder?.payment.totalPrice! -
                            selectedPurchaseOrder?.payment
                              .cashBackDiscountCents!) /
                            100
                        )}
                      </Paragraph>

                      <div>
                        <BrandCreditCard
                          brandIcon={
                            selectedPurchaseOrder!.payment.paymentMethod ===
                            'PIX'
                              ? selectedPurchaseOrder!.payment.paymentMethod
                              : selectedPurchaseOrder!.payment.brand
                          }
                          brand={
                            selectedPurchaseOrder!.payment.paymentMethod ===
                            'PIX'
                              ? selectedPurchaseOrder!.payment.brand
                              : selectedPurchaseOrder!.payment.brand
                          }
                          paymentMethod={
                            selectedPurchaseOrder!.payment.paymentMethod
                          }
                          lastNumbers={
                            selectedPurchaseOrder!.payment.lastCardNumbers
                          }
                          final
                        />
                      </div>
                    </div>

                    <div className="divider" />

                    <Heading variant="h5">
                      Total:{' '}
                      {formatPrice(
                        selectedPurchaseOrder?.payment.totalPrice! / 100
                      )}
                    </Heading>
                  </S.CashBackPaid>
                ) : null}

                {selectedPurchaseOrder?.payment.cashBackDiscountCents! ? (
                  <S.CashBackReceived>
                    <Paragraph variant="large" className="textCashback">
                      Você ganhou!
                    </Paragraph>
                    <Paragraph variant="regular">
                      {formatPrice(
                        (selectedPurchaseOrder?.eventItem.event
                          .cashBackPercent! *
                          selectedPurchaseOrder?.payment.totalPrice!) /
                          10000
                      )}{' '}
                      de cashback com essa compra
                    </Paragraph>
                  </S.CashBackReceived>
                ) : null}

                {selectedPurchaseOrder?.status === 'SUCCESS' ? (
                  <>
                    <Button
                      fullWidth
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => history.push(`/tickets/${id}`)}>
                      Ver ingressos
                    </Button>
                    <S.DownloadButton onClick={() => downloadPurchasePDF(id)}>
                      Baixar ingresso <S.DownloadIcon />
                    </S.DownloadButton>
                  </>
                ) : null}
              </S.InfoRequest>
            </S.Wrapper>
          </S.ContentRequest>
        </S.Container>
      </ProfileTemplate>
    </motion.div>
  )
}
