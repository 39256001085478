import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding: 0 80px;
  display: flex;
`

export const EventsFoundText = styled.div`
  ${({ theme }) => css`
    .events-quantity {
      text-align: left;
      font-size: ${theme.font.size.md};
      font-style: normal;
      font-weight: 700;
      line-height: 41px;
      color: ${theme.color.brand.primary.nth1};
      margin-right: 1rem;
    }
  `}
`

export const FilterWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: ${theme.spacing.stack.xxs};
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`

export const FilterOptions = styled.div`
  width: max-content;
  display: inline-flex;
  justify-content: space-between;
  align-items: baseline;
`

export const OrderWrapper = styled.div`
  display: flex;
  width: max-content;
  justify-content: space-between;
  align-items: center;
`

export const CardEvents = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    overflow: auto;
    margin-bottom: 24px;
    padding: 8px;
  `}
`
export const customStyles = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    width: 200,
    margin: '0 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 16px',
    background: '#f4f4f4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '4px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  dropdownIndicator: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    svg: {
      fill: '#8601C9'
    }
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}
