import * as S from './CategoryCard.styles'
import Category1 from '../../../../../assets/mock/category1.png'
import Category2 from '../../../../../assets/mock/category2.png'
import Category3 from '../../../../../assets/mock/category3.png'
import Category4 from '../../../../../assets/mock/category4.png'
import Category5 from '../../../../../assets/mock/category5.png'
import Category6 from '../../../../../assets/mock/category6.png'

import history from '../../../../../routes/services/history'

type Props = {
  id: string
  name: string
  index: number
}

export const CategoryCard = ({ id, name, index }: Props) => {
  return (
    <S.Container onClick={() => history.push(`/search?category=${name}`)}>
      <img
        className="image"
        src={
          index === 1
            ? Category1
            : index === 2
            ? Category2
            : index === 3
            ? Category3
            : index === 4
            ? Category4
            : index === 5
            ? Category6
            : Category5
        }
      />
      <S.CategoryName>{name}</S.CategoryName>
    </S.Container>
  )
}
