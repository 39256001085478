import styled, { css } from 'styled-components'

import { ReactComponent as Clock } from '../../../../../assets/icons/clock2.svg'
import { ReactComponent as Location } from '../../../../../assets/icons/map-marker2.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    .gridContainer {
      @media (min-width: 768px) and (max-width: 1170px) {
        padding: ${theme.spacing.stack.md} ${theme.spacing.stack.xxl};
        height: 100%;
      }
      @media (min-width: 1171px) {
        margin: 0;
      }

      .ticketBox {
        @media (min-width: 1171px) {
          height: 100%;
        }
      }
    }

    @media (min-width: 1171px) {
      align-items: center;
    }

    .coverUrl {
      @media (min-width: 1171px) {
        height: 100%;
        border-radius: ${theme.border.radius.medium};
      }
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    div:first-child {
      margin-right: ${theme.spacing.stack.xxxs};
    }
    p:first-child {
      align-self: center;
      margin-bottom: ${theme.spacing.stack.sm};
    }
  `}
`

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    line-height: ${theme.spacing.stack.sm};
    font-weight: ${theme.font.weight.bold};
    color: ${theme.color.base.nth1};
    margin-top: ${theme.spacing.stack.sm};
    margin-bottom: ${theme.spacing.stack.sm};
    text-align: center;
  `}
`

export const CoverUrl = styled.img`
  ${({ theme }) => css`
    width: 100%;
    min-height: 60vh;
    max-height: 60vh;
    border-radius: ${theme.border.radius.medium};
  `}
`

export const ShowTicket = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.md} ${theme.spacing.stack.xl}
      ${theme.spacing.stack.lg} ${theme.spacing.stack.xl};
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: ${theme.border.radius.medium};
    min-height: 60vh;
    max-height: 60vh;
    .buttonBox {
      margin-top: ${theme.spacing.stack.xs};
    }
  `}
`

export const EventName = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.size.xl};
    line-height: ${theme.spacing.stack.lg};
    margin-bottom: ${theme.spacing.stack.xxxs};
  `}
`

export const DataBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.stack.xxxs};
    flex-wrap: wrap;
  `}
`

export const IconData = styled(Clock)`
  ${({ theme }) => css`
    width: ${theme.spacing.inline.sm};
    height: ${theme.spacing.inline.sm};
    margin-right: ${theme.spacing.stack.xxs};
  `}
`

export const TitleData = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    margin-right: ${theme.spacing.stack.small};
  `}
`

export const LocationBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: ${theme.spacing.stack.xxs};
  `}
`

export const IconLocation = styled(Location)`
  ${({ theme }) => css`
    width: ${theme.spacing.inline.sm};
    height: ${theme.spacing.inline.sm};
  `}
`

export const TitleLocation = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
  `}
`

export const PurpleTitleLocation = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.semiBold};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    color: #8601c9;
  `}
`
