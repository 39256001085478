import { Hidden } from 'applaus-ui-kit'
import { formatPrice } from '../../../../../utils/formatPrice'
import * as S from './CourseCard.styles'

import history from '../../../../../routes/services/history'
import { Course } from '../../../../../redux/Content/types'

export const CourseCard = ({
  name,
  mobileCoverUrl,
  desktopCoverUrl,
  priceCents,
  discountCents,
  id
}: Course) => {
  return (
    <S.Container onClick={() => history.push(`/course/${id}`)}>
      <Hidden mobile tablet>
        <S.CourseImage src={desktopCoverUrl} />
      </Hidden>
      <Hidden desktop>
        <S.CourseImage src={mobileCoverUrl} />
      </Hidden>
      <S.CourseInfo>
        <S.CourseName>{name}</S.CourseName>
        <S.CoursePrice>
          {formatPrice((priceCents - discountCents) / 100)}
        </S.CoursePrice>
      </S.CourseInfo>
    </S.Container>
  )
}
