import * as Yup from 'yup'
import { messageHelper } from '../../../../utils/messageHelper'
import { phoneValidate } from '../../../../utils/phoneValidate'
import cpfValidate from '../../../../utils/cpfValidator'
import { AnyObject } from 'yup/lib/types'

export const EditProfileSchema = Yup.object().shape(
  {
    firstName: Yup.string()
      .min(2, messageHelper.yup.error.shortName)
      .max(55, messageHelper.yup.error.longName)
      .required('Nome é obrigatório'),
    lastName: Yup.string()
      .min(2, messageHelper.yup.error.shortLastName)
      .max(55, messageHelper.yup.error.longLastName)
      .required('Sobrenome é obrigatório'),
    email: Yup.string()
      .email(messageHelper.yup.error.emailIsInvalid)
      .required(messageHelper.yup.error.emailIsMandatory),
    document: Yup.string().test(
      'document validation',
      messageHelper.yup.error.documentIsInvalid,
      (value) => {
        if (value) {
          return cpfValidate(value)
        } else return false
      }
    ),
    phone: Yup.string().test(
      'phone validation',
      messageHelper.yup.error.phoneIsInvalid,
      (value) => {
        if (value) {
          return phoneValidate(value)
        } else return false
      }
    ),
    number: Yup.string()
      .nullable()
      .when('number', {
        is: (value: string) => value === '0',
        then: (
          rule: Yup.StringSchema<
            string | null | undefined,
            AnyObject,
            string | null | undefined
          >
        ) =>
          rule.test('validateNumber', 'Número não pode ser 0', (value) => {
            if (value && value === '0') {
              return false
            } else return true
          })
      }),
    zipCode: Yup.string()
      .nullable()
      .when('zipCode', {
        is: (value: string) => value?.length,
        then: (
          rule: Yup.StringSchema<
            string | null | undefined,
            AnyObject,
            string | null | undefined
          >
        ) =>
          rule.test('validateZipCode', 'Digite um CEP válido', (value) => {
            if (value && value.replace(/[^0-9]/g, '').trim().length === 8) {
              return true
            } else return false
          })
      }),
    street: Yup.string()
      .nullable()
      .when('street', {
        is: (value: string) => value?.length,
        then: (
          rule: Yup.StringSchema<
            string | null | undefined,
            AnyObject,
            string | null | undefined
          >
        ) => rule.min(2, 'Digite um endereço válido').max(255)
      }),
    city: Yup.string()
      .required(messageHelper.yup.error.cityIsMandatory)
      .min(2, messageHelper.yup.error.cityIsInvalid)
      .max(55),
    state: Yup.string()
      .required(messageHelper.yup.error.stateIsMandatory)
      .min(2, messageHelper.yup.error.stateIsInvalid)
      .max(55)
  },
  [
    ['number', 'number'],
    ['zipCode', 'zipCode'],
    ['street', 'street']
  ]
)
