import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Route, RouteProps } from 'react-router-dom'
import { ReduxState } from '../redux/rootReducer'
import { useCookies } from 'react-cookie'
import { motion } from 'framer-motion'

export type PrivateRouteProps = {
  component: ReactNode
} & RouteProps

export const PrivateRoute = ({ component, ...rest }: PrivateRouteProps) => {
  const [getCookie] = useCookies(['applaus-auth-customer'])

  const { UserReducer } = useSelector((state: ReduxState) => state)

  if (!getCookie['applaus-auth-customer'] && UserReducer.email === '') {
    window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/url?name=${window.location.href}`
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}>
      <Route {...rest} component={component} />
    </motion.div>
  )
}
