import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.stack.xs};
    object-fit: fill;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    > img {
      width: auto;
    }
    @media (max-width: 767px) {
      margin: 0 auto;
      > img {
        width: 127px;
        height: 110px;
      }
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      margin: 0 auto;
      > img {
        width: 158px;
        height: 75px;
      }
    }
  `}
`

export const CategoryName = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.bold};
    text-align: center;
    line-height: 18px;

    white-space: nowrap;
    color: ${theme.color.base.nth2};

    @media (max-width: 767px) {
      font-size: 9px;
      font-style: normal;
      font-weight: 600;
      line-height: 15px;
      text-align: center;
    }
  `}
`
