import styled, { css } from 'styled-components'

export const EventPricing = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.spacing.stack.md} ${theme.spacing.stack.xxxl};
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: ${theme.border.radius.large};
    > button {
      margin-top: ${theme.spacing.stack.xxs};
      background: ${theme.color.alert.success.nth1};
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: ${theme.spacing.stack.md};
    }
    .opening-event-text {
      text-align: center;
      font-size: ${theme.font.size.md};
      font-weight: ${theme.font.weight.semiBold};
      line-height: ${theme.font.size.xl};
      color: ${theme.color.base.nth2};
    }
    .opening-date {
      margin-top: ${theme.spacing.stack.xxs};
      text-align: center;
      font-size: ${theme.font.size.md};
      font-weight: ${theme.font.weight.bold};
      line-height: ${theme.font.size.xl};
      color: ${theme.color.base.nth2};
    }
    .event-closed {
      padding: ${theme.spacing.stack.xs} 0;
    }
    .event-closed-text {
      text-align: center;
      font-size: ${theme.font.size.md};
      font-weight: ${theme.font.weight.semiBold};
      line-height: ${theme.font.size.xl};
      color: ${theme.color.base.nth2};
    }
    .sold-out {
      padding: 32px 0;
    }

    .sold-out-text {
      text-align: center;
      font-size: ${theme.font.size.md};
      font-weight: ${theme.font.weight.semiBold};
      line-height: ${theme.font.size.xl};
      color: ${theme.color.alert.error.nth1};
    }

    .textPrice {
      margin-top: ${theme.spacing.stack.small};
      font-weight: ${theme.font.weight.bold};
    }

    .textCashBack {
      margin-top: ${theme.spacing.stack.small};
      font-weight: ${theme.font.weight.semiBold};
      color: ${theme.color.brand.primary.nth1};
    }
  `}
`
