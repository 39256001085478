import * as S from './Purchases.styles'
import TicketImage from 'assets/icons/coupon.svg'
import {
  setPurchaseOrderCustomer,
  viewMorePurchaseOrderRequest
} from '../../../redux/Purchases/actions'
import { ThemeProvider } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import { ReduxState } from '../../../redux/rootReducer'
import { Hidden, Paragraph } from 'applaus-ui-kit'
import { AppBar } from '../../../components/appBar/AppBar'
import { MobileTicketCard } from '../MobileTicketCard/Ticket'
import { BottomNavigation } from '../../../components/bottomNavigation/BottomNavigation'
import history from '../../../routes/services/history'

const customConf: any = {
  mediaQuery: 'only screen',
  columns: {
    xs: 4,
    sm: 8,
    md: 8,
    lg: 12,
    xl: 12
  },
  gutterWidth: {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 2
  },
  paddingWidth: {
    xs: 1,
    sm: 1,
    md: 0,
    lg: 0,
    xl: 0
  },
  container: {
    xs: 'full', // 'full' = max-width: 100%
    sm: 'full', // 'full' = max-width: 100%
    md: 'full', // 'full' = max-width: 100%
    lg: 90, // max-width: 1440px
    xl: 90 // max-width: 1440px
  },
  breakpoints: {
    xs: 1,
    sm: 48, // 768px
    md: 64, // 1024px
    lg: 90, // 1440px
    xl: 120 // 1920px
  }
}

export const Purchases = () => {
  const dispatch = useDispatch()
  const [eventSearch, setEventSearch] = useState('')

  const { purchasesState } = useSelector(
    (state: ReduxState) => state.PurchasesReducer
  )

  const events = useMemo(
    () =>
      purchasesState.items.filter(({ eventItem }) =>
        eventItem.event.name
          .toLowerCase()
          .includes(eventSearch.toLocaleLowerCase())
      ),
    [eventSearch, purchasesState]
  )

  return (
    // @ts-ignorem
    <ThemeProvider theme={{ awesomegrid: customConf }}>
      <Hidden mobile tablet>
        <S.Container>
          <S.Title>Meus Eventos</S.Title>

          {purchasesState.items.length === 0 ? (
            <S.ContentNoTicket>
              <S.TicketImage src={TicketImage} />
              <S.TitleNoTicket>
                Você ainda não possui nenhum ingresso
              </S.TitleNoTicket>
            </S.ContentNoTicket>
          ) : (
            <>
              <S.InputWrapper>
                <S.SearchIcon />
                <S.Input
                  placeholder="Buscar pelo nome do evento"
                  onChange={(e) => setEventSearch(e.target.value)}
                />
              </S.InputWrapper>

              <S.CardEvent>
                {events.map((purchase, index) => (
                  <img
                    key={index}
                    style={{
                      width: '100%',
                      borderRadius: '12px',
                      cursor: 'pointer'
                    }}
                    src={purchase.eventItem.event.banners.desktopUrl}
                    onClick={() => {
                      dispatch(setPurchaseOrderCustomer(purchase.id))
                      history.push(`purchase/${purchase.id}`)
                    }}
                  />
                ))}
              </S.CardEvent>
            </>
          )}

          {purchasesState.items.length > 0 &&
          purchasesState.items.length < purchasesState.total ? (
            <S.SeeMore onClick={() => dispatch(viewMorePurchaseOrderRequest())}>
              Ver mais
            </S.SeeMore>
          ) : null}
        </S.Container>
      </Hidden>

      <Hidden desktop>
        <S.Container>
          <Hidden mobile>
            <AppBar />
          </Hidden>

          <S.Wrapper>
            <Hidden tablet>
              <div className="titleBox">
                <Paragraph variant="large" type="bold">
                  Meus pedidos
                </Paragraph>
              </div>
            </Hidden>

            <Hidden mobile>
              <S.Title>MEUS PEDIDOS</S.Title>
            </Hidden>

            <S.TotalRequest>
              <S.TextRequest>Pedidos</S.TextRequest>

              <S.TextTotalRequest>
                {purchasesState.items.length}
              </S.TextTotalRequest>
            </S.TotalRequest>

            {purchasesState.items.length === 0 ? (
              <S.ContentNoTicket>
                <S.TicketImage src={TicketImage} />
                <S.TitleNoTicket>
                  Você ainda não possui nenhum ingresso
                </S.TitleNoTicket>
              </S.ContentNoTicket>
            ) : null}

            <S.Cards>
              {purchasesState.items.map((purchase, index) => (
                <div key={purchase.id}>
                  <Hidden tablet>
                    <MobileTicketCard
                      image={purchase.eventItem.event.banners.mobileUrl}
                      dateEvent={purchase.eventItem.dates.map((date) => date)}
                      locationEvent={purchase.eventItem.event.address.street}
                      titleEvent={purchase.eventItem.event.name}
                      click={() => {
                        dispatch(setPurchaseOrderCustomer(purchase.id))
                        history.push(`purchase/${purchase.id}`)
                      }}
                    />
                  </Hidden>
                  <Hidden mobile>
                    <img
                      src={purchase.eventItem.event.banners.tabletUrl}
                      onClick={() => {
                        dispatch(setPurchaseOrderCustomer(purchase.id))
                        history.push(`purchase/${purchase.id}`)
                      }}
                    />
                  </Hidden>
                </div>
              ))}
            </S.Cards>

            {purchasesState.items.length > 0 &&
            purchasesState.items.length < purchasesState.total ? (
              <S.SeeMore
                onClick={() => dispatch(viewMorePurchaseOrderRequest())}>
                Ver mais
              </S.SeeMore>
            ) : null}
          </S.Wrapper>
        </S.Container>
        <Hidden tablet>
          <BottomNavigation active="purchases" />
        </Hidden>
      </Hidden>
    </ThemeProvider>
  )
}
