import * as S from './EditProfile.styles'
import BackButton from '../../../components/backButton/BackButton'
import {
  Hidden,
  Input,
  Button,
  Heading,
  InputMask,
  Paragraph
} from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { EditProfileSchema } from './validation/EditProfileSchema'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/rootReducer'
import { editUserRequestAction } from '../../../redux/User/actions'
import { useHistory } from 'react-router-dom'
import { getAddressService } from '../../../api/services/cep/getAddressService'
import { ChangeEvent, useEffect, useState } from 'react'
import { UpdatepasswordModal } from './UpdatePasswordModal/UpdatePasswordModal'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { Column, Container, Row } from '../../../components/grid/GridSystem'
import Select, { components, GroupBase, SingleValueProps } from 'react-select'
import location from '../../../components/locationModal/location.json'
import { AppBar } from '../../../components/appBar/AppBar'

type Props = {
  open: boolean
  click?: () => void
}

type OptionType = {
  value: string
  label: string
}

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    // eslint-disable-next-line no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line no-unused-vars
    Group extends GroupBase<Option>
  > {}
}

function SingleValue({
  children,
  ...props
}: SingleValueProps<OptionType, false>) {
  return <components.SingleValue {...props}>{children}</components.SingleValue>
}

export const EditProfile = ({ click }: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const UserState = useSelector((state: ReduxState) => state.UserReducer)
  const { isMobile, isTablet } = useWindowSize()

  const [updatePasswordModal, setUpdatePasswordModal] = useState(false)

  const formik = useFormik({
    initialValues: {
      firstName: UserState.firstName,
      lastName: UserState.lastName,
      email: UserState.email,
      document: UserState.document,
      phone: UserState.phone,
      zipCode: UserState.address?.zipCode,
      street: UserState.address?.street,
      state: UserState.address?.state,
      country: UserState.address?.country,
      city: UserState.address?.city,
      number:
        UserState.address?.number !== null
          ? String(UserState.address?.number)
          : '',
      complement: UserState.address?.complement
    },
    enableReinitialize: true,
    validationSchema: EditProfileSchema,
    onSubmit: async (values) => {
      dispatch(editUserRequestAction({ ...values, address: { ...values } }))
    }
  })

  const [state] = useState(
    location.estados.map((state) => ({ value: state.sigla, label: state.nome }))
  )

  const [cities, setCities] = useState<{ value: string; label: string }[]>(
    location.estados
      .find(({ sigla }) => sigla === formik.values.state)
      ?.cidades.map((city) => ({ value: city, label: city })) ?? []
  )

  const handleCep = async (e: ChangeEvent<HTMLInputElement>) => {
    const cep = e.target.value.replace(/[^0-9]/g, '')
    try {
      if (cep.length === 8) {
        const { complemento, uf, logradouro, localidade } =
          await getAddressService(e.target.value)
        if (uf) {
          await formik.setFieldValue('state', uf)
          await formik.setFieldValue('city', localidade)
          await formik.setFieldValue('complement', complemento)
          await formik.setFieldValue('city', localidade)
          await formik.setFieldValue('street', logradouro)
          await formik.setFieldValue('country', 'BR')
        }
      }
    } catch (e) {}
  }

  const handleOpenUpdatePasswordModal = () => {
    setUpdatePasswordModal(true)
  }

  const handleCloseUpdatePasswordModal = () => {
    setUpdatePasswordModal(false)
  }

  useEffect(() => {
    if (formik.values.state !== '') {
      const stateIndex = location.estados.findIndex(
        (state) => state.sigla === formik.values.state
      )
      if (stateIndex !== -1) {
        setCities(
          location.estados[stateIndex].cidades.map((city) => ({
            value: city,
            label: city
          }))
        )
      }
    }
  }, [formik.values.state])

  const { isDesktop } = useWindowSize()
  return (
    <S.Container open={true}>
      <Hidden desktop mobile>
        <AppBar />
      </Hidden>
      <S.Form onSubmit={formik.handleSubmit}>
        <Container>
          <Hidden tablet desktop>
            <BackButton
              title=""
              onClick={() => {
                click
                  ? click()
                  : isMobile
                  ? history.push('profileTab')
                  : history.goBack()
                formik.resetForm()
              }}
            />
          </Hidden>
          <Row>
            <Heading variant="h1" className="title">
              Meus Dados
            </Heading>
          </Row>
          <Row gutter={isMobile || isTablet ? 0 : 2}>
            <Column mobile={4} tablet={8} desktop={8}>
              <Input
                name="firstName"
                label="*Nome"
                onChange={formik.handleChange}
                value={formik.values.firstName || ''}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                texterror={formik.errors.firstName}
                fullWidth
              />
            </Column>
            <Column mobile={4} tablet={8} desktop={4}>
              <Input
                name="lastName"
                label="*Sobrenome"
                onChange={formik.handleChange}
                value={formik.values.lastName || ''}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                texterror={formik.errors.lastName}
                fullWidth
              />
            </Column>
          </Row>
          <Row gutter={isMobile || isTablet ? 0 : 2}>
            <Column mobile={4} tablet={8} desktop={4}>
              <InputMask
                className="phone"
                mask={'(99) 9 9999-9999'}
                label="*Telefone"
                name="phone"
                placeholder="Digite seu telefone"
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                texterror={formik.errors.phone}
                onChange={formik.handleChange('phone')}
                value={formik.values.phone || ''}
                fullWidth={!isDesktop}
              />
            </Column>
            <Column mobile={4} tablet={8} desktop={8}>
              <Input
                name="email"
                label="*Email"
                onChange={formik.handleChange}
                value={formik.values.email || ''}
                error={formik.touched.email && Boolean(formik.errors.email)}
                texterror={formik.errors.email}
                fullWidth
              />
            </Column>
          </Row>
          <Row>
            <Column mobile={4} tablet={8} desktop={4}>
              <InputMask
                mask={'999.999.999-99'}
                label="*CPF"
                name="cpf"
                placeholder="CPF"
                error={
                  formik.touched.document && Boolean(formik.errors.document)
                }
                texterror={formik.errors.document}
                onChange={formik.handleChange('document')}
                value={formik.values.document || ''}
                readOnly
                fullWidth={true}
              />
            </Column>
          </Row>
          <Row>
            <Hidden mobile>
              <S.UpdatePasswordBox onClick={handleOpenUpdatePasswordModal}>
                <S.LockIcon />
                <Paragraph variant="regular" type="semiBold">
                  Alterar senha
                </Paragraph>
              </S.UpdatePasswordBox>
            </Hidden>
          </Row>
          <Row>
            <Column mobile={4} tablet={8} desktop={12}>
              <Heading variant="h1" className="title">
                Endereço
              </Heading>
            </Column>
          </Row>
          <Row gutter={isMobile || isTablet ? 0 : 2}>
            <Column mobile={4} tablet={8} desktop={4}>
              <InputMask
                mask={'99999-999'}
                name="zipCode"
                label="*CEP"
                value={formik.values.zipCode || ''}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                texterror={formik.errors.zipCode}
                onChange={(e) => {
                  handleCep(e)
                  formik.setFieldValue('zipCode', e.target.value)
                }}
                fullWidth
              />
            </Column>
            <Column mobile={4} tablet={8} desktop={8}>
              <Input
                name="street"
                label="*Endereço"
                onChange={formik.handleChange}
                value={formik.values.street || ''}
                error={formik.touched.street && Boolean(formik.errors.street)}
                texterror={formik.errors.street}
                fullWidth
              />
            </Column>
          </Row>
          <Row gutter={isMobile || isTablet ? 0 : 2}>
            <Column mobile={4} tablet={8} desktop={4}>
              <S.Label>*Estado</S.Label>
              <Select<OptionType>
                id="state"
                value={
                  state.find(
                    (option) => option.value === formik.values.state
                  ) ?? {
                    value: '',
                    label: ''
                  }
                }
                onChange={(selectValue) => {
                  if (selectValue && selectValue.value) {
                    formik.setFieldValue('state', selectValue.value)
                    formik.setFieldValue('city', '')
                  }
                }}
                placeholder="Estado"
                styles={S.customStyles}
                options={state}
                components={{ IndicatorSeparator: () => null, SingleValue }}
                noOptionsMessage={() => 'Estado não encontrado'}
              />
            </Column>
            <Column mobile={4} tablet={8} desktop={8}>
              <S.Label>*Cidade</S.Label>
              <Select<OptionType>
                value={
                  cities.find(
                    (option) => option.value === formik.values.city
                  ) ?? {
                    value: '',
                    label: ''
                  }
                }
                onChange={(selectValue) => {
                  if (selectValue && selectValue.value) {
                    formik.setFieldValue('city', selectValue.value)
                  }
                }}
                placeholder="Cidade"
                styles={S.customStyles}
                options={cities}
                components={{ IndicatorSeparator: () => null, SingleValue }}
                noOptionsMessage={() => 'Cidade não encontrada'}
              />
            </Column>
          </Row>

          <Row gutter={isMobile || isTablet ? 0 : 2}>
            <Column mobile={4} tablet={8} desktop={4}>
              <Input
                name="number"
                label="*Número"
                onChange={formik.handleChange}
                value={formik.values.number || ''}
                error={formik.touched.number && Boolean(formik.errors.number)}
                texterror={formik.errors.number}
                fullWidth
              />
            </Column>
            <Column mobile={4} tablet={8} desktop={8}>
              <Input
                name="complement"
                label="Complemento"
                onChange={formik.handleChange}
                value={formik.values.complement || ''}
                error={
                  formik.touched.complement && Boolean(formik.errors.complement)
                }
                texterror={formik.errors.complement}
                fullWidth
              />
            </Column>
          </Row>
          <Row>
            <Column mobile={4} tablet={8} desktop={12}>
              <S.ButtonWrapper>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  fullWidth={false}
                  type="submit">
                  Alterar dados
                </Button>
              </S.ButtonWrapper>
            </Column>
          </Row>
        </Container>
      </S.Form>
      <Hidden mobile>
        <UpdatepasswordModal
          isOpen={updatePasswordModal}
          closeModal={handleCloseUpdatePasswordModal}
        />
      </Hidden>
    </S.Container>
  )
}
