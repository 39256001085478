import * as S from './TabletAppBar.styles'

import logo from '../../../assets/logo.png'
import { Paragraph } from 'applaus-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/rootReducer'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { logoutAction } from '../../../redux/User/actions'
import { clearCheckoutState } from '../../../redux/Checkout/actions'
import { clearEvent } from '../../../redux/Event/actions'

export const TabletAppBar = () => {
  const history = useHistory()
  const { firstName, lastName, email, accessToken } = useSelector(
    (state: ReduxState) => state.UserReducer
  )
  const dispatch = useDispatch()

  const [openDrawer, setOpenDrawer] = useState(false)

  const buttonClick = (
    route:
      | ''
      | 'profile'
      | 'address'
      | 'updatePassword'
      | 'purchases'
      | 'purchaseHistory'
      | 'cashbackHistory'
  ) => {
    history.push(`/${route}`)
    setOpenDrawer(false)
  }

  return (
    <>
      <S.Container>
        {accessToken !== '' ? (
          <S.MenuIcon onClick={() => setOpenDrawer(true)} />
        ) : (
          <S.UserIcon
            onClick={() => {
              window.location.href = process.env.REACT_APP_AUTH_WEB_URL ?? ''
            }}
          />
        )}

        <S.Logo
          src={logo}
          alt="applaus"
          onClick={() => {
            dispatch(clearCheckoutState())
            dispatch(clearEvent())
            history.push('/')
          }}
        />
        <S.SearchIcon onClick={() => history.push('/search')} />
      </S.Container>
      <AnimatePresence>
        {openDrawer && (
          <S.DrawerContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <S.DrawerContent
              initial={{ width: 0 }}
              animate={{ width: 'max-content' }}
              transition={{ duration: 0.3 }}>
              <S.IconBox animate={{ rotate: 270 }}>
                <S.DrawerMenuIcon onClick={() => setOpenDrawer(false)} />
              </S.IconBox>
              <S.UserInfoBox
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                <S.FilledProfileIcon />
                <Paragraph variant="large" type="bold">
                  {firstName} {lastName}
                </Paragraph>
                <Paragraph
                  variant="regular"
                  type="normal"
                  className="emailText">
                  {email}
                </Paragraph>
              </S.UserInfoBox>
              <S.ButtonBox
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}>
                {firstName !== '' ? (
                  <>
                    <S.Button onClick={() => buttonClick('')}>
                      <S.HomeIcon />
                      <Paragraph variant="large" type="normal">
                        Início
                      </Paragraph>
                    </S.Button>

                    <S.Button onClick={() => buttonClick('purchases')}>
                      <S.TicketIcon />
                      <Paragraph variant="large" type="normal">
                        Meus Pedidos
                      </Paragraph>
                    </S.Button>

                    <S.Button onClick={() => buttonClick('profile')}>
                      <S.DataIcon />
                      <Paragraph variant="large" type="normal">
                        Meus Dados
                      </Paragraph>
                    </S.Button>

                    <S.Button onClick={() => buttonClick('purchaseHistory')}>
                      <S.FolderIcon />
                      <Paragraph variant="large" type="normal">
                        Histórico de compra
                      </Paragraph>
                    </S.Button>

                    <S.Button onClick={() => buttonClick('cashbackHistory')}>
                      <S.CashbackIcon />
                      <Paragraph variant="large" type="normal">
                        Cashback
                      </Paragraph>
                    </S.Button>

                    <S.Button onClick={() => dispatch(logoutAction())}>
                      <S.LogoutIcon />
                      <Paragraph variant="large" type="normal">
                        Sair
                      </Paragraph>
                    </S.Button>
                  </>
                ) : (
                  <>
                    <S.Button>
                      <S.UserIcon />
                      <Paragraph
                        variant="large"
                        type="normal"
                        onClick={() => {
                          window.location.href =
                            `${process.env.REACT_APP_AUTH_WEB_URL}` ?? ''
                        }}>
                        Fazer login
                      </Paragraph>
                    </S.Button>
                    <S.Button>
                      <S.UserIcon />
                      <Paragraph
                        variant="large"
                        type="normal"
                        onClick={() => {
                          window.location.href =
                            `${process.env.REACT_APP_AUTH_WEB_URL}register` ??
                            ''
                        }}>
                        Cadastrar-se
                      </Paragraph>
                    </S.Button>
                  </>
                )}
              </S.ButtonBox>
            </S.DrawerContent>
          </S.DrawerContainer>
        )}
      </AnimatePresence>
      <div style={{ height: '40px' }} />
    </>
  )
}
