import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    position: relative;
    margin-bottom: ${theme.spacing.stack.small};
  `}
`

export const Input1 = styled.input<{ active: boolean }>`
  ${({ active }) => css`
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
    z-index: ${active ? 3 : 5};

    &::-webkit-slider-thumb {
      -webkit-tap-highlight-color: transparent;
      -webkit-appearance: none;
      background: #6c01a3;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      height: 12px;
      width: 12px;
      margin-top: 4px;
      pointer-events: all;
      position: relative;
    }

    &::-moz-range-thumb {
      background: #6c01a3;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      height: 12px;
      width: 12px;
      margin-top: 4px;
      pointer-events: all;
      position: relative;
    }
  `}
`

export const Input2 = styled.input`
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  z-index: 4;
  &::-webkit-slider-thumb {
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    background: #6c01a3;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  &::-moz-range-thumb {
    background: #6c01a3;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
`

export const Slider = styled.div`
  position: relative;
  width: 100%;
  .left-value,
  .right-value {
    color: #39383a;
    font-size: 12px;
    margin-top: 20px;
    position: absolute;
  }

  .right-value {
    right: -4px;
  }
`

export const SliderTrack = styled.div`
  position: absolute;
  border-radius: 3px;
  height: 5px;
  background-color: #ebf0f6;
  width: 100%;
  z-index: 1;
`

export const SliderRange = styled.div`
  position: absolute;
  border-radius: 3px;
  height: 5px;
  background-color: #b88bcf;
  z-index: 2;
`
