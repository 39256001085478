import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
  `}
`

export const CategoryWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    overflow: auto;
    overflow-y: hidden;
    padding: ${theme.spacing.stack.xxs};
    @media (min-width: 1171px) {
      padding: 0;
      display: flex;
      justify-content: center;
    }
  `}
`

export const BannerWrapper = styled.div`
  ${({ theme }) => css`
    align-self: center;
    max-width: 1366px;
    @media (max-width: 767px) {
    }
    margin: 0 auto;
    margin-bottom: ${theme.spacing.stack.xxs};

    width: 100%;
    .slick-track,
    .slick-list {
      position: relative;
    }
    .slick-dots {
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
      list-style: none;
      display: flex !important;
      align-items: center;
      justify-content: center;
      li {
        background: white;
        border: 2px solid rgb(221, 224, 228);
        width: 12px;
        height: 12px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 ${theme.spacing.stack.small};
        cursor: pointer;
        &.slick-active {
          background: #8601c9;
          border: 2px solid #8601c9;
        }
      }
      button {
        opacity: 0;
        width: ${theme.spacing.stack.small};
        height: ${theme.spacing.stack.small};
        cursor: pointer;
      }
      overflow-x: scroll;
      white-space: nowrap;
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .slick-prev {
      display: block;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: ${theme.spacing.stack.md};
      transform: translate(0, -50%);
      z-index: 20;
      @media (min-width: 768px) and (max-width: 1169px) {
        left: ${theme.spacing.stack.xxs};
      }
    }
    .slick-next {
      display: block;
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: ${theme.spacing.stack.md};
      transform: translate(0, -50%);
      z-index: 20;
      @media (min-width: 768px) and (max-width: 1169px) {
        right: ${theme.spacing.stack.xxs};
      }
    }
  `}
`

export const EventTitle = styled.h1`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: ${theme.font.weight.bold};
    line-height: 30px;
    color: #39383a;
  `}
`
