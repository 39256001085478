import { ShowPurchaseDesktop } from './Tablet/ShowRequest'
import { ShowRequest as ShowRequestMobile } from './Mobile/ShowRequest'
import { Hidden } from 'applaus-ui-kit'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPurchaseOrderByIdRequestAction } from '../../../redux/Purchases/actions'
import { ReduxState } from '../../../redux/rootReducer'

export const Purchase = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { selectedPurchaseOrder } = useSelector(
    (state: ReduxState) => state.PurchasesReducer
  )

  console.log(id)

  useEffect(() => {
    dispatch(getPurchaseOrderByIdRequestAction(id))
  }, [])

  return (
    <>
      {selectedPurchaseOrder ? (
        <>
          <Hidden desktop tablet>
            <ShowRequestMobile />
          </Hidden>
          <Hidden mobile>
            <ShowPurchaseDesktop />
          </Hidden>
        </>
      ) : undefined}
    </>
  )
}
