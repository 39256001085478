export const TicketActions = {
  addTicket: '@tickets/ADD_TICKET',
  removeTicket: '@tickets/REMOVE_TICKET',
  clearTicket: '@tickets/CLEAR_TICKETS'
}

export type Ticket = {
  objectId: string
  ticketType: string
  price: number
  own: string
  parent: string
}

export type TicketState = {
  state: Ticket[]
}
