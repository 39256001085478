import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/rootReducer'
import { PaymentMethod } from './PaymentMethod/PaymentMethod'
import { OrderProcess } from './OrderProcess/OrderProcess'
import { CreditCard } from './CreditCard/CreditCard'
import { AddressDesktop } from './Address/Desktop/AddressDesktop'
import { Pix } from './Pix/Pix'
import { useEffect } from 'react'

export const Checkout = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { step } = useSelector(
    (state: ReduxState) => state.CheckoutReducer.data
  )

  const { address, email } = useSelector(
    (state: ReduxState) => state.UserReducer
  )

  switch (step) {
    case 'pix':
      return <Pix />
    case 'creditCard':
      return <CreditCard />
    case 'defaultCheckout':
      if (
        email !== '' &&
        (address?.number === null ||
          address?.zipCode === null ||
          address?.street === null)
      ) {
        return <AddressDesktop />
      } else return <PaymentMethod />
    case 'paymentSuccess':
      return <OrderProcess />
    default:
      return <PaymentMethod />
  }
}
