import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.inline.xxs};
  `}
`

export const TicketBox = styled.section`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    filter: drop-shadow(0px -1px 2px rgba(0, 0, 0, 0.2));
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    align-items: center;
    > button {
      background: ${theme.color.alert.success.nth1};
    }
  `}
`

export const TotalPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h5:last-of-type {
    color: #8601c9;
  }
`
