import * as S from './AuthenticatedTemplate.styles'
import { Heading, Paragraph } from 'applaus-ui-kit'

import { useSelector, useDispatch } from 'react-redux'
import { ReduxState } from '../../../../../redux/rootReducer'
import { logoutAction } from '../../../../../redux/User/actions'
import history from '../../../../../routes/services/history'
import { BottomNavigation } from '../../../../../components/bottomNavigation/BottomNavigation'
import { formatPrice } from '../../../../../utils/formatPrice'

export const AuthenticatedProfile = () => {
  const { firstName, lastName, email, cashBackBalanceCents } = useSelector(
    (state: ReduxState) => state.UserReducer
  )
  const dispatch = useDispatch()

  return (
    <S.Container>
      <S.Header>
        <S.Title>
          <Heading variant="h1">PERFIL</Heading>
          <S.SignoutIcon onClick={() => dispatch(logoutAction())} />
        </S.Title>
        <S.UserBox>
          <div>
            <Paragraph variant="large" type="bold" className="userInfo">
              {firstName} {lastName}
            </Paragraph>
            <Paragraph variant="large">{email}</Paragraph>
          </div>
          {cashBackBalanceCents !== undefined ? (
            <S.CashBackWrapper>
              <S.CashBackIcon />
              <p>{formatPrice(cashBackBalanceCents / 100)}</p>
            </S.CashBackWrapper>
          ) : undefined}
        </S.UserBox>
      </S.Header>

      <S.MenuButtonsBox>
        <S.MenuButton active={true} onClick={() => history.push('/profile')}>
          <div className="content">
            <S.DataIcon />
            <Paragraph variant="regular" type="normal" className="text">
              Minha Conta
            </Paragraph>
          </div>

          <S.ChevronRightIcon />
        </S.MenuButton>

        <S.MenuButton
          active={true}
          onClick={() => history.push('/updatePassword')}>
          <div className="content">
            <S.SettingsIcon />
            <Paragraph variant="regular" type="normal" className="text">
              Alterar Senha
            </Paragraph>
          </div>
          <S.ChevronRightIcon />
        </S.MenuButton>
        <S.MenuButton
          active={true}
          onClick={() =>
            window.open(process.env.REACT_APP_AUTH_WEB_URL + '/terms', '_blank')
          }>
          <div className="content">
            <S.TermsIcon />
            <Paragraph variant="regular" type="normal" className="text">
              Termos e condições
            </Paragraph>
          </div>

          <S.ChevronRightIcon />
        </S.MenuButton>

        <S.MenuButton
          active={true}
          onClick={() => history.push('/purchaseHistory')}>
          <div className="content">
            <S.FolderIcon />
            <Paragraph variant="regular" type="normal" className="text">
              Histórico de compra
            </Paragraph>
          </div>

          <S.ChevronRightIcon />
        </S.MenuButton>
      </S.MenuButtonsBox>
      <BottomNavigation active="profile" />
    </S.Container>
  )
}
