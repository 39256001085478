import { AxiosResponse } from 'axios'
import api from '../../config/api'

type TicketPurchase = {
  ticketId: string
  eventItemId: string
  seatKey?: string
  holdToken?: string
  ticketInfo?: {
    name: string
    value: string
  }[]
}

export type TicketPurchaseRequest = {
  paymentMethod: {
    type: 'PIX' | 'CREDIT_CARD' | 'FREE' | 'CASH_BACK'
    token?: string
    installments?: number | null
    cashBackValueCents: number | null
    customerCouponId?: string
  }
  tickets: TicketPurchase[]
}

export type TicketPurchaseResponse = {
  id: string
  status: string
  customerId: string
  invoiceId: string
  pixQrCode: string
  pixQrCodeText: string
  failedCode: {
    code: string
    error: string
  }
  customField: string
  createdAt: string
  updatedAt: string
  creatorId: string
}

export const ticketPurchase = async (dataTicket: TicketPurchaseRequest) => {
  dataTicket.tickets = dataTicket.tickets.map((ticket) => ({
    ...ticket,
    ticketInfo: ticket.ticketInfo?.reduce(
      (d, e) => ({ ...d, [e.name]: e.value }),
      {}
    )
  })) as TicketPurchase[]
  const { data, status }: AxiosResponse<TicketPurchaseResponse> =
    await api.post('/api/purchase/tickets', dataTicket)

  return { data, status }
}
