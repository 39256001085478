import * as S from './EventProfile.styles'

import 'moment/locale/pt-br'
import { Heading, Paragraph, Button } from 'applaus-ui-kit'
import { formatPrice } from '../../../../../utils/formatPrice'
import { useDispatch, useSelector } from 'react-redux'
import {
  chooseTicket,
  prevStepAction
} from '../../../../../redux/Event/actions'
import { ReduxState } from '../../../../../redux/rootReducer'
import { min } from 'lodash'
import { DateInfo } from '../Components/DateInfo/DateInfo'
import { LocationIframe } from '../../../LocationIframe/LocationIframe'
import moment from 'moment'
import { ReactElement, useMemo, useState } from 'react'

type SocialLink = 'instagram' | 'facebook' | 'youtube' | 'tiktok'

export const EventProfile = () => {
  const dispacth = useDispatch()
  const { eventData } = useSelector((state: ReduxState) => state.EventReducer)

  const handleSocialMediaClick = (link: SocialLink) => {
    switch (link) {
      case 'facebook':
        window.open('https://www.facebook.com/', '_blank')
        break
      case 'instagram':
        window.open('https://www.instagram.com/', '_blank')
        break
      case 'tiktok':
        window.open('https://www.tiktok.com/', '_blank')
        break
      case 'youtube':
        window.open('https://www.youtube.com/', '_blank')
        break
    }
  }

  const renderSocialLinks = useMemo(() => {
    const socialLinks: ReactElement[] = []
    if (eventData && eventData.socialLinks) {
      if (eventData.socialLinks.tiktokLink !== '') {
        socialLinks.push(
          <S.TiktokIcon onClick={() => handleSocialMediaClick('tiktok')} />
        )
      }

      if (eventData.socialLinks.facebookLink !== '') {
        socialLinks.push(
          <S.FacebookIcon onClick={() => handleSocialMediaClick('facebook')} />
        )
      }

      if (eventData.socialLinks.instagramLink !== '') {
        socialLinks.push(
          <S.InstagramIcon
            onClick={() => handleSocialMediaClick('instagram')}
          />
        )
      }

      if (eventData.socialLinks.youtubeLink !== '') {
        socialLinks.push(
          <S.YoutubeIcon onClick={() => handleSocialMediaClick('youtube')} />
        )
      }
    }
    return socialLinks
  }, [eventData?.socialLinks])

  const [isLinkShared, setIsLinkShared] = useState(false)

  return (
    <S.Container>
      {eventData === undefined ? null : (
        <>
          <S.EventDataBox>
            <S.FilledBackIcon
              onClick={() => dispacth(prevStepAction('home'))}
            />

            <S.CoverUrl
              src={eventData.banners[0].mobileUrl}
              alt={eventData.name}
            />
            <S.EventData>
              <Heading variant="h4" className="textInfo">
                {eventData.name}
              </Heading>
              <DateInfo
                dates={eventData.eventItems.flatMap(({ dates }) => dates)}
              />
              <S.LocationInfo>
                <S.LocationIcon />
                <div>
                  <Heading variant="h5" className="textInfo">
                    {eventData.address.name}
                  </Heading>
                  <Paragraph variant="small" type="normal" className="textInfo">
                    {eventData.address.city} - {eventData.address.state},{' '}
                    {eventData.address.zipCode}
                  </Paragraph>
                </div>
              </S.LocationInfo>
            </S.EventData>
          </S.EventDataBox>

          <S.EventInfo>
            <S.AgeBox>
              {eventData.ageGroup === 'CLASS_L' ? (
                <>
                  <S.AgeFreeIcon />
                  <Paragraph variant="small" type="normal">
                    Livre para todas as idades
                  </Paragraph>
                </>
              ) : eventData.ageGroup === 'CLASS_10' ? (
                <>
                  <S.Age10Icon />
                  <Paragraph variant="small" type="normal">
                    Não recomendado para menores de 10 anos
                  </Paragraph>
                </>
              ) : eventData.ageGroup === 'CLASS_12' ? (
                <>
                  <S.Age12Icon />
                  <Paragraph variant="small" type="normal">
                    Não recomendado para menores de 12 anos
                  </Paragraph>
                </>
              ) : eventData.ageGroup === 'CLASS_14' ? (
                <>
                  <S.Age14Icon />
                  <Paragraph variant="small" type="normal">
                    Não recomendado para menores de 14 anos
                  </Paragraph>
                </>
              ) : eventData.ageGroup === 'CLASS_16' ? (
                <>
                  <S.Age16Icon />
                  <Paragraph variant="small" type="normal">
                    Não recomendado para menores de 16 anos
                  </Paragraph>
                </>
              ) : eventData.ageGroup === 'CLASS_18' ? (
                <>
                  <S.Age12Icon />
                  <Paragraph variant="small" type="normal">
                    Não recomendado para menores de 18 anos
                  </Paragraph>
                </>
              ) : null}
            </S.AgeBox>
            <S.EventDetails>
              <div className="header-wrapper">
                <Heading variant="h5">Detalhes</Heading>
                <div className="buttons-wrapper">{renderSocialLinks}</div>
              </div>
            </S.EventDetails>
            <S.Description
              dangerouslySetInnerHTML={{ __html: eventData.description }}
            />
            <S.ShareButton
              onClick={() => {
                setIsLinkShared(true)
                navigator.clipboard.writeText(window.location.href)
              }}>
              {isLinkShared ? 'Copiado !' : 'Compartilhar'}
              <S.ShareIcon />
            </S.ShareButton>
            <Heading className="heading" variant="h5">
              Localização
            </Heading>
            <LocationIframe
              number={eventData.address.number}
              street={eventData.address.street}
              zipCode={eventData.address.zipCode}
            />

            <div style={{ height: '60px' }} />
            <S.TicketBox>
              <>
                {eventData.status === 'CANCELED' ? (
                  <Paragraph
                    variant="regular"
                    type="semiBold"
                    className="sold-off-text">
                    Cancelado
                  </Paragraph>
                ) : eventData.status === 'CLOSED' ? (
                  <Paragraph
                    variant="regular"
                    type="semiBold"
                    className="waiting-open-text">
                    Fechado
                  </Paragraph>
                ) : eventData.status === 'SOLD_OUT' ? (
                  <Paragraph
                    variant="regular"
                    type="semiBold"
                    className="sold-off-text">
                    Esgotado
                  </Paragraph>
                ) : moment().isAfter(moment(eventData.finalSales)) ? (
                  <Paragraph
                    variant="regular"
                    type="semiBold"
                    className="sold-off-text">
                    Vendas encerradas
                  </Paragraph>
                ) : moment().isBefore(moment(eventData.salesFrom)) ? (
                  <>
                    <Paragraph
                      variant="regular"
                      type="semiBold"
                      className="waiting-open-text">
                      Esperando abrir
                    </Paragraph>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth={false}
                      type="button"
                      size="small"
                      style={{ whiteSpace: 'nowrap' }}
                      disabled
                      onClick={() => dispacth(chooseTicket())}>
                      {moment(eventData.salesFrom).format('DD/MM [às] HH:mm')}
                    </Button>
                  </>
                ) : (
                  <>
                    <S.PricingInfo>
                      <Paragraph variant="small" type="semiBold">
                        valores a partir de
                      </Paragraph>
                      <Paragraph variant="small" type="semiBold">
                        {formatPrice(
                          min(
                            eventData.eventItems.flatMap(({ tickets }) =>
                              tickets.map((ticket) => ticket.totalPrice)
                            )
                          ) ?? 0
                        )}
                      </Paragraph>
                      <Paragraph variant="small" type="semiBold">
                        {eventData.cashBackPercent! > 0 &&
                        eventData.cashBackPercent !== null
                          ? eventData.cashBackPercent + '% cashback'
                          : null}
                      </Paragraph>
                    </S.PricingInfo>

                    <Button
                      className="button"
                      color="primary"
                      variant="contained"
                      fullWidth={false}
                      type="button"
                      size="small"
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={() => dispacth(chooseTicket())}>
                      Ver Ingressos
                    </Button>
                  </>
                )}
              </>
            </S.TicketBox>
          </S.EventInfo>
        </>
      )}
    </S.Container>
  )
}
