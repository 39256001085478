import * as S from './Course.styles'

import mobileCourseBanner from '../../../assets/mock/courseMobile.png'
import desktopCourseBanner from '../../../assets/mock/courseDesktop.png'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { AppBar } from '../../../components/appBar/AppBar'
import { Heading, Paragraph, Button, Hidden } from 'applaus-ui-kit'
import { CustomAccordion } from './Components/Accordion/Accordion'
import { formatPrice } from '../../../utils/formatPrice'
import { Accordion } from '../../../components/Accordion/Accordion'
import history from '../../../routes/services/history'
import { useEffect, useRef, useState } from 'react'
import { Course as CourseType } from '../../../redux/Content/types'
import { useParams } from 'react-router-dom'
import { listOneCourseService } from '../../../api/services/courses/listOnecourseService'
import { timeConvert } from '../../../utils/timeConverter'

const MockCourse = {
  modules: [
    {
      title: 'Introdução',
      lessons: [
        {
          title: 'O que você vai aprender no curso',
          duration: 600
        },
        {
          title: 'Estrutura e projetos do curso',
          duration: 600
        },
        {
          title: 'Quem serão seus instrutores?',
          duration: 600
        }
      ]
    },
    {
      title: 'Primeiros Passos',
      lessons: [
        {
          title: 'O que você vai aprender no curso',
          duration: 600
        },
        {
          title: 'Estrutura e projetos do curso',
          duration: 600
        },
        {
          title: 'Quem serão seus instrutores?',
          duration: 600
        }
      ]
    },
    {
      title: 'Conceitos e Impactos',
      lessons: [
        {
          title: 'O que você vai aprender no curso',
          duration: 600
        },
        {
          title: 'Estrutura e projetos do curso',
          duration: 600
        },
        {
          title: 'Quem serão seus instrutores?',
          duration: 600
        }
      ]
    },
    {
      title: 'Comunicação Interpessoal',
      lessons: [
        {
          title: 'O que você vai aprender no curso',
          duration: 600
        },
        {
          title: 'Estrutura e projetos do curso',
          duration: 600
        },
        {
          title: 'Quem serão seus instrutores?',
          duration: 600
        }
      ]
    }
  ]
}

type CourseState = Omit<CourseType, 'level'> & { level: string }

export const Course = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { isMobile, isDesktop, isTablet } = useWindowSize()
  const [courseData, setCourseData] = useState<CourseState | undefined>(
    undefined
  )

  const { id }: { id?: string } = useParams()

  useEffect(() => {
    if (containerRef !== null && containerRef.current) {
      containerRef.current.scrollIntoView({
        block: 'start'
      })
    }
    const fetchCourse = async () => {
      try {
        if (id) {
          const data = await listOneCourseService(id)
          setCourseData({
            ...data,
            level:
              data.level === 'ADVANCED'
                ? 'Avançado'
                : data.level === 'BEGGINER'
                ? 'Iniciante'
                : 'Intermediário'
          })
        } else {
          history.push('/')
        }
      } catch (error) {
        return history.push('/')
      }
    }
    fetchCourse()
  }, [])

  return (
    <S.Container ref={containerRef}>
      {isDesktop || isTablet ? <AppBar /> : undefined}
      {courseData ? (
        <>
          <Hidden tablet desktop>
            <S.PushBackBox onClick={() => history.goBack()}>
              <S.BackIcon />
            </S.PushBackBox>
          </Hidden>
          <S.Wrapper>
            <div style={{ position: 'relative' }}>
              <S.Banner
                src={isMobile ? mobileCourseBanner : desktopCourseBanner}
                alt="banner"
              />
              <Hidden mobile>
                <S.PushBackBox onClick={() => history.goBack()}>
                  <S.BackIcon />
                </S.PushBackBox>
              </Hidden>
            </div>

            <S.CourseInfo>
              <S.CourseInfoBox>
                <div>
                  <Heading variant="h3" className="title">
                    {courseData.name}
                  </Heading>
                  <Paragraph variant="regular" className="description">
                    {courseData.description}
                  </Paragraph>
                  <S.CourseDetailsBox>
                    <S.CourseDetails>
                      <S.Levelicon />
                      <div>
                        <Paragraph variant="small">Nível</Paragraph>
                        <Paragraph variant="small" type="bold">
                          {courseData.level}
                        </Paragraph>
                      </div>
                    </S.CourseDetails>
                    <S.CourseDetails>
                      <S.ClockIcon />
                      <div>
                        <Paragraph variant="small">Duração</Paragraph>
                        <Paragraph variant="small" type="bold">
                          {timeConvert(
                            courseData.modules
                              .flatMap(({ lessons }) =>
                                lessons.flatMap(({ duration }) => duration)
                              )
                              .reduce((acc, actual) => acc + actual, 0)
                          )}
                        </Paragraph>
                      </div>
                    </S.CourseDetails>
                    <S.CourseDetails>
                      <S.FolderIcon />
                      <div>
                        <Paragraph variant="small">Módulos</Paragraph>
                        <Paragraph variant="small" type="bold">
                          {courseData.modules.length} módulos
                        </Paragraph>
                      </div>
                    </S.CourseDetails>
                    <S.CourseDetails>
                      <S.LessonIcon />
                      <div>
                        <Paragraph variant="small">Aulas</Paragraph>
                        <Paragraph variant="small" type="bold">
                          {MockCourse.modules
                            .map(({ lessons }) => lessons.length)
                            .reduce((acc, actual) => acc + actual, 0)}{' '}
                          aulas
                        </Paragraph>
                      </div>
                    </S.CourseDetails>
                  </S.CourseDetailsBox>
                </div>
                <Hidden mobile>
                  <S.PriceCard>
                    <Paragraph variant="large">
                      Faça sua inscrição por
                    </Paragraph>
                    <Paragraph
                      variant="large"
                      type="bold"
                      className="subscribe-price">
                      {formatPrice(
                        (courseData.priceCents - courseData?.discountCents) /
                          100
                      )}
                    </Paragraph>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth={true}
                      type="button"
                      size="large"
                      className="subscribe-button"
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={() => {}}>
                      Inscreva-se
                    </Button>
                  </S.PriceCard>
                </Hidden>
              </S.CourseInfoBox>
              <Heading variant="h3" className="subtitle">
                Programa do curso
              </Heading>
              {courseData.modules.map((module, index) => (
                <CustomAccordion key={index} {...module} index={index} />
              ))}
              <S.CreatorSection>
                <Heading variant="h3" className="subtitle">
                  Criador
                </Heading>
                <S.CreatorInfo>
                  <S.CircleProfileIcon />
                  <div className="infoBox">
                    <Paragraph
                      variant="large"
                      type="semiBold">{`${courseData.creatorInfo.firstName} ${courseData.creatorInfo.lastName}`}</Paragraph>
                    <Paragraph variant="regular">
                      {courseData.creatorInfo.position}
                    </Paragraph>
                  </div>
                </S.CreatorInfo>
                <Paragraph className="creatorDescription" variant="regular">
                  {courseData.creatorInfo.description}
                </Paragraph>
              </S.CreatorSection>
              <S.FaqSection>
                <Heading variant="h3" className="subtitle">
                  Perguntas frequentes
                </Heading>
                {courseData.faq &&
                  courseData.faq.map((faq, index) => (
                    <Accordion
                      key={index}
                      title={faq.title}
                      child={faq.answer}
                    />
                  ))}
              </S.FaqSection>
            </S.CourseInfo>
          </S.Wrapper>
          <S.TicketBox>
            <S.PricingInfo>
              <Paragraph variant="small" type="semiBold">
                Valor:
              </Paragraph>
              <Paragraph variant="small" type="semiBold">
                {formatPrice(courseData.priceCents / 100)}
              </Paragraph>
            </S.PricingInfo>
            <Button
              color="primary"
              variant="contained"
              fullWidth={false}
              type="button"
              size="large"
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => {}}>
              Inscreva-se
            </Button>
          </S.TicketBox>
        </>
      ) : undefined}
    </S.Container>
  )
}
