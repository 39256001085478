import styled, { css } from 'styled-components'

export const Container = styled.div<{ open: boolean }>`
  ${({ theme }) => css`
    display: flex;
    height: 100%;
    flex-direction: column;

    @media (max-width: 767px) {
      height: 100vh;
      padding: ${theme.spacing.stack.xxs};
    }
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`

export const Form = styled.form`
  ${({ theme }) => css`
    .forgotPassword {
      color: ${theme.color.brand.primary.nth3};
      cursor: pointer;
      transition: 0.3s ease;
      font-size: 9px;
      font-style: normal;
      font-weight: 600;
      line-height: 15px;
      margin-bottom: ${theme.spacing.stack.xs};
    }
    .forgotPassword:hover {
      color: ${theme.color.brand.primary.nth1};
    }
    @media (max-width: 767px) {
      display: flex;
      flex: 1;
      height: 100%;
      flex-direction: column;
      .forgotPassword {
        width: max-content;
      }
      .buttonBox {
        margin-top: auto;
      }
    }

    @media (min-width: 1171px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      min-width: 420px;
      max-width: 420px;
      margin: 0 auto;
      .buttonBox {
        margin-left: auto;
      }
    }
  `}
`

export const PasswordRules = styled.div`
  ${({ theme }) => css`
    > p {
      color: ${theme.color.brand.primary.nth1};
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
    }
  `}
`
