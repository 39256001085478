import { all } from 'redux-saga/effects'
import { ContentsSaga } from './Content/saga'
import { UserSaga } from './User/sagas'
import { EventSaga } from './Event/saga'
import { PurchaseSaga } from './Purchases/saga'
import { CheckoutSaga } from './Checkout/saga'

export default function* rootSaga(): any {
  return yield all([
    ContentsSaga,
    UserSaga,
    EventSaga,
    PurchaseSaga,
    CheckoutSaga
  ])
}
