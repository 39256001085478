import * as S from './InputCopy.styles'
import { InputHTMLAttributes, useRef } from 'react'
import { CopyIcon } from '../../assets/icons/copy'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  fullWidth?: boolean
  type?: string
  error?: boolean
  texterror?: string
  id?: string
}

export const InputCopy = ({
  name,
  type,
  label,
  id,
  fullWidth,
  ...props
}: InputProps) => {
  const inputRef = useRef<any>()

  const handleCopyText = () => {
    inputRef.current.select()

    document.execCommand('copy')
  }

  return (
    <S.Container fullWidth={fullWidth ?? false}>
      <S.Label>{label}</S.Label>
      <S.Wrapper>
        <S.Input ref={inputRef} name={name} id={id} {...props} />
        <S.IconSvg onClick={handleCopyText}>
          <CopyIcon />
        </S.IconSvg>
      </S.Wrapper>
    </S.Container>
  )
}
