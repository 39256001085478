import { Paragraph } from 'applaus-ui-kit'
import { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import * as S from './OrderBy.styles'

type Props = {
  orderByState: {
    isOpen: boolean
    options: string[]
    actualOption: string
  }
  setOrderByState: Dispatch<
    SetStateAction<{
      isOpen: boolean
      options: string[]
      actualOption: string
    }>
  >
}

export const OrderByfilter = ({ orderByState, setOrderByState }: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref !== null) {
      const listener = (event: any) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        setOrderByState((prev) => ({ ...prev, isOpen: false }))
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }
  }, [ref, orderByState])

  return (
    <S.Container ref={ref}>
      <S.Button
        onClick={() =>
          setOrderByState((prev) => ({ ...prev, isOpen: !prev.isOpen }))
        }>
        <Paragraph variant="small" type="semiBold">
          {orderByState.actualOption}
        </Paragraph>
        <S.ChevronDownIcon />
      </S.Button>
      <S.OrderByDropdown isOpen={orderByState.isOpen}>
        {orderByState.options.map((option) => (
          <S.Options
            key={option}
            onClick={() =>
              setOrderByState((prev) => ({
                ...prev,
                actualOption: option,
                isOpen: !prev.isOpen
              }))
            }>
            {(option.includes('Data') && <S.CalendarIcon />) ||
              (option.includes('Localização') && <S.MapMarkerIcon />) ||
              (option.includes('Relevância') && <S.ArrowsIcon />)}
            <Paragraph variant="small" type="normal">
              {option}
            </Paragraph>
          </S.Options>
        ))}
      </S.OrderByDropdown>
    </S.Container>
  )
}
