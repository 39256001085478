import { action } from 'typesafe-actions'
import { CheckoutActions, CheckoutState, Coupon } from './types'

export const setCheckoutState = (data: CheckoutState) =>
  action(CheckoutActions.SET_CHECKOUT_STATE, data)

export const clearCheckoutState = () =>
  action(CheckoutActions.CLEAR_CHECKOUT_STATE)

export const defaultCheckout = () => action(CheckoutActions.DEFAULT_CHECKOUT)

export const creditCard = () => action(CheckoutActions.CREDIT_CARD)

export const bankSlip = () => action(CheckoutActions.BANK_SLIP)

export const pix = () => action(CheckoutActions.PIX)

export const orderProcess = (paymentId: string) =>
  action(CheckoutActions.PAYMENT_SUCCESS, paymentId)

export const paymentFailure = () => action(CheckoutActions.PAYMENT_FAILURE)

export const purchaseStatus = (status: string) =>
  action(CheckoutActions.PURCHASE_STATUS, status)

export const redeemCouponRequestAction = (couponCode: string) =>
  action(CheckoutActions.REDEEM_COUPON_REQUEST, couponCode)

export const redeemCouponSuccessAction = (coupon: Coupon) =>
  action(CheckoutActions.REDEEM_COUPON_SUCCESS, coupon)

export const removeCouponAction = (couponId: string) =>
  action(CheckoutActions.REMOVE_COUPON, couponId)
