import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: max-content;
    margin-right: ${theme.spacing.inline.small};
    cursor: pointer;
    transition: 0.6s ease;
  `}
`

export const CourseImage = styled.img`
  ${({ theme }) => css`
    @media (max-width: 767px) {
      width: 127px;
      height: 145px;
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      width: 248px;
      height: 265px;
    }
    @media (min-width: 1171px) {
      width: 200px;
      height: 224px;
    }
    border-radius: ${theme.border.radius.medium};
  `}
`

export const CourseInfo = styled.div`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    column-gap: ${theme.spacing.inline.small};
    justify-content: space-between;
  `}
`

export const CourseName = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-weight: ${theme.font.weight.bold};
  `}
`
export const CoursePrice = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xs};
    color: #8601c9;
    font-weight: ${theme.font.weight.bold};
  `}
`
